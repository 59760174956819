import React, { useEffect, useState } from "react";
import Images from "assets/images";
import CSSLoader from "components/cssLoader/CSSLoader";
import DoctorCard from "components/doctorCard/DoctorCard";
import DateChip from "components/slotChip/DateChip";
import TimeSlotChip from "components/slotChip/TimeSlotChip";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import { cosmetic_steps } from "helpers/enum";
import { useCosmeticConsultationFlow } from "hooks/useConsultationFlow";
import useModal from "hooks/useModal";
import useVisitStepChange from "hooks/useVisitStepChange";
import moment from "moment";
import { useDispatch } from "react-redux";
import { cosmeticConsultationReducers } from "reducers/cosmetic/cosmeticConsultationReducer";
import CosmeticSelectProvider from "./CosmeticSelectProvider";
import PCProviderProfileDetails from "components/pcProviderProfileDetails/PCProviderProfileDetails";
import scrollToBottom from "helpers/scrollToBottom";
import { getBookingSlotsWithProvidersAction } from "api/actions/VisitActions";
import { useApiQuery } from "hooks/useApiQuery";
import useUpdateCosmeticConsultation from "hooks/consultation/useUpdateCosmeticConsultation";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";

function CosmeticAppointment() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);

  const dispatch = useDispatch();
  const { setShowModal } = useModal();
  const { steps, incompleteData } = useCosmeticConsultationFlow();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore = steps[cosmetic_steps.select_slot].value;

  const { updateConsultation } = useUpdateCosmeticConsultation();

  const {
    loading: gettingBookingSlots,
    payload: slotsResponse,
    query: getBookingSlot,
  } = useApiQuery(
    getBookingSlotsWithProvidersAction({
      caseId: incompleteData?.id,
    }),
    false
  );

  useEffect(() => {
    getBookingSlot();
  }, [getBookingSlot]);

  useEffect(() => {
    if (
      currentScreenValueFromStore &&
      Object.keys(currentScreenValueFromStore)?.length &&
      slotsResponse
    ) {
      setSelectedDate(currentScreenValueFromStore?.selectedDate);
      const findSlotFromPayload = slotsResponse?.[
        currentScreenValueFromStore?.selectedDate
      ]?.find(
        (el) => el?.slot_id === currentScreenValueFromStore?.slotInfo?.slot_id
      );
      if (
        findSlotFromPayload?.booked === false ||
        findSlotFromPayload?.hasOwnProperty("booked") === false
      ) {
        setSelectedSlot(currentScreenValueFromStore?.slotInfo);
        setSelectedProvider(currentScreenValueFromStore?.selectedProvider);
      }
    }
  }, [currentScreenValueFromStore, slotsResponse]);

  useEffect(() => {
    if (selectedSlot?.slot_id && selectedProvider) {
      dispatch(
        cosmeticConsultationReducers.select_slot({
          isValid: true,
        })
      );
      scrollToBottom();
    } else {
      dispatch(
        cosmeticConsultationReducers.select_slot({
          isValid: false,
        })
      );
    }
  }, [dispatch, selectedSlot, selectedProvider]);

  function onRightButtonClick(_, nextAnimation) {
    updateConsultation({
      payload: {
        case: {
          cosmetic_provider_id: selectedProvider?.id,
          cosmetic_steps: incompleteData?.cosmetic_steps,
          meta: {
            ...incompleteData?.meta,
            booking_info: {
              selectedDate,
              slotInfo: selectedSlot,
              selectedProvider,
            },
          },
        },
      },
      change_current_screen: true,
      screen_to_complete: cosmetic_steps.select_slot,
      callBack: () => {
        dispatch(
          cosmeticConsultationReducers.select_slot({
            value: {
              selectedDate,
              slotInfo: selectedSlot,
              selectedProvider,
            },
          })
        );
        nextAnimation();
      },
    });
  }

  return (
    <StepScreenContainer align="start">
      <MedicalAssistant
        content={
          <p>
            Thank you. Please select a date and time that you are available for
            a consultation.
          </p>
        }
      />
      <Card>
        {gettingBookingSlots ? (
          <div className={"h-40 flex items-center justify-center"}>
            <CSSLoader className={"w-16 h-16"} color={"orange"} />
          </div>
        ) : (
          <div>
            {slotsResponse && Object.keys(slotsResponse)?.length ? (
              <>
                <label className="text-2xl font-bold text-opacity-50 text-indigo">
                  Select Day
                </label>
                <Spacer height={15} />
                <div className="overflow-auto">
                  <div className="flex pb-10">
                    {Object.keys(slotsResponse)
                      ?.sort((a, b) => {
                        return new Date(a) - new Date(b);
                      })
                      ?.map((el, idx) => {
                        return (
                          <DateChip
                            key={idx}
                            numberOfSlots={slotsResponse?.[el]?.length}
                            onClick={() => {
                              setSelectedProvider(null);
                              setSelectedSlot(null);
                              setSelectedDate(el);
                            }}
                            selected={el === selectedDate}
                            isLast={idx === Object.keys(slotsResponse)?.length}
                            date={
                              moment().format("YYYY-MM-DD") === el
                                ? "Today"
                                : moment()
                                    .add(1, "days")
                                    .format("YYYY-MM-DD") === el
                                ? "Tomorrow"
                                : moment(el, "YYYY-MM-DD").format("DD MMM")
                            }
                            day={moment(el).format("ddd")}
                          />
                        );
                      })}
                  </div>
                </div>
              </>
            ) : (
              <h2 className="text-2xl font-semibold text-center text-opacity-50 text-indigo">
                Oops! No slots available. Please try again later.
              </h2>
            )}
            {selectedDate ? (
              <div>
                <Spacer height={15} />
                <label className="text-2xl font-bold text-opacity-50 text-indigo">
                  Select Time
                </label>
                {slotsResponse?.[selectedDate]?.length ? (
                  <div>
                    <Spacer height={15} />
                    {slotsResponse?.[selectedDate]?.length ? (
                      <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
                        {slotsResponse?.[selectedDate]?.map((el, idx) => {
                          return (
                            <TimeSlotChip
                              key={idx}
                              onClick={() => {
                                setSelectedSlot(el);
                                setSelectedProvider(el?.providers?.[0]);
                              }}
                              disabled={el?.booked}
                              selected={selectedSlot?.slot_id === el?.slot_id}
                              isLast={
                                idx === slotsResponse?.[selectedDate]?.length
                              }
                              slot={`${moment(el?.start_time, "hh:mma").format(
                                "h:mm A"
                              )} - ${moment(el?.end_time, "hh:mma").format(
                                "h:mm A"
                              )}`}
                            />
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <p className="pt-4 text-xl font-normal">
                    Oops! No slots available. Please try selecting other dates.
                  </p>
                )}
              </div>
            ) : null}
          </div>
        )}
      </Card>
      {selectedSlot ? (
        <div>
          <MedicalAssistant
            content={
              <p>
                Your pre-selected consultant is{" "}
                <span className="font-black">{selectedProvider?.name}</span>, or
                you may select a different provider.
              </p>
            }
          />
          <Spacer height={26} />
          <Card>
            <div className="relative p-6 border border-borderColor rounded-3xl">
              <DoctorCard
                license={selectedProvider?.license}
                isActive={selectedProvider?.active}
                name={selectedProvider?.name}
                imageUrl={selectedProvider?.avatar}
                speciality={selectedProvider?.speciality ?? ""}
                viewProfile={true}
                onViewProfileClick={() =>
                  setShowModal({
                    state: true,
                    children: (_, onClose) => (
                      <PCProviderProfileDetails
                        details={selectedProvider}
                        caseId={incompleteData?.id}
                        onClose={onClose}
                      />
                    ),
                  })
                }
              />
            </div>
            {selectedSlot?.providers.length > 1 ? (
              <div className="flex items-center justify-center flex-1 mt-7">
                <div
                  onClick={() => {
                    setShowModal({
                      state: true,
                      children: (_, onClose) => (
                        <CosmeticSelectProvider
                          caseId={incompleteData?.id}
                          selectedProvider={selectedProvider}
                          availableProviders={selectedSlot?.providers}
                          onSelectProvider={setSelectedProvider}
                          onClose={onClose}
                        />
                      ),
                    });
                  }}
                  className="flex items-center justify-center cursor-pointer"
                >
                  <p className="pr-6 text-2xl font-medium">Change Provider</p>
                  <img className="w-8" src={Images.arrowRight} alt={"go"} />
                </div>
              </div>
            ) : null}
          </Card>
        </div>
      ) : null}
    </StepScreenContainer>
  );
}

export default CosmeticAppointment;
