import CSSLoader from "components/cssLoader/CSSLoader";
import Spacer from "components/Spacer/Spacer";
import React from "react";

function Alert({
  heading,
  message,
  containerClassName,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonClassName,
  secondaryButtonClassName,
  primaryButtonLoading,
}) {
  return (
    <div className="bg-transparent flex items-center justify-center h-screen">
      <div className={`bg-white p-8 w-11/12 max-w-2xl ${containerClassName}`}>
        <h1 className="text-3xl font-bold">{heading ?? "Alert"}</h1>
        <p className="text-2xl font-medium pt-4 leading-9">{message}</p>
        <Spacer height={30} />
        <div className="flex items-center justify-end">
          <button
            className={`${secondaryButtonClassName} text-xl font-bold p-4 uppercase bg-opacity-10 rounded text-indigo min-w-32`}
            onClick={onSecondaryButtonClick}
          >
            {secondaryButtonText ?? "Cancel"}
          </button>
          <Spacer width={16} />
          <button
            className={`${primaryButtonClassName} flex items-center justify-center text-xl font-bold p-4 uppercase bg-indigo bg-opacity-20 rounded text-indigo min-w-32`}
            onClick={onPrimaryButtonClick}
          >
            {primaryButtonText ?? "Ok"}
            {primaryButtonLoading ? (
              <CSSLoader className="w-6 h-6 ml-2" color="indigo" />
            ) : null}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Alert;
