import classNames from "classnames";
import React from "react";

function StepScreenContainer({ children, align, className }) {
  return (
    <div
      className={classNames(
        "overflow-x-hidden p-6 md:p-12 min-h-full flex flex-col",
        `justify-${align ? align : "center"}`,
        "bg-gradient-to-b from-blue-300 to-blue-500 shadow-lg",
        className
      )}
    >
      <div
        id="step_screen_container"
        className={classNames(
          "transform translate-x-1/2 opacity-0 wrapper flex flex-col space-y-12"
        )}
      >
        {children}
      </div>
    </div>
  );
}

export default StepScreenContainer;
