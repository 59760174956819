import React from "react";
import Images from "assets/images";
import Header from "components/header/Header";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";

function CaseImageUploadInfo({ onClose, handleChange, title, image, info }) {
  return (
    <div className="h-full bg-veryLightBlue">
      <Header
        hideBackBtn
        title={title}
        rightActionView={
          <img
            onClick={onClose}
            className="cursor-pointer w-10"
            src={Images.closeIcon}
            alt={"close_icons"}
          />
        }
      />
      <div className="h-eliminateHeaderWithFooter wrapper bg-blue">
        <div className="px-16 pt-16">
          <MedicalAssistant content={<p>{info}</p>} />
        </div>
        <div className="relative h-5/6 flex justify-center">
          <img className="absolute md:h-124%" src={image} alt="case_photo" />
        </div>
      </div>
      <input
        id="case-image-input"
        className="hidden"
        type="file"
        accept="image/png, image/jpg, image/jpeg"
        onChange={(e) => {
          onClose();
          handleChange(e);
        }}
      />

      <div className="fixed bottom-0 w-full bg-veryLightBlue">
        <div className={`wrapper bg-white`}>
          <div className="px-5 py-6 ">
            <label
              htmlFor="case-image-input"
              className={`flex items-center justify-center font-bold text-3xl p-8 w-full focus:outline-none focus:ring-2 bg-orange white rounded-6/5 cursor-pointer text-white`}
            >
              {"Add Picture"}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseImageUploadInfo;
