import { incompletVisitAction } from "api/actions/VisitActions";
import { useEffect } from "react";
import { useParameterizedQuery } from "react-fetching-library";
import { useDispatch } from "react-redux";
import { cosmeticConsultationReducers } from "reducers/cosmetic/cosmeticConsultationReducer";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { pedVisitFlowReducers } from "reducers/ped/pedVisitFlowReducer";
import { primaryCareVisitFlowReducers } from "reducers/primary_care/primaryCareVisitFlowReducer";

function useIncompleteData() {
  const dispatch = useDispatch();
  const {
    payload: incompleteData,
    query: fetchIncompleteData,
    loading: fetchingIncompleteData,
  } = useParameterizedQuery(incompletVisitAction);

  useEffect(() => {
    if (incompleteData && Object.keys(incompleteData)?.length) {
      if (incompleteData?.speciality === "dermatology") {
        dispatch(
          dermaVisitFlowReducers.incompleteData({
            value: incompleteData,
          })
        );
      } else if (incompleteData?.speciality === "primary_care") {
        dispatch(
          primaryCareVisitFlowReducers.incompleteData({
            value: incompleteData,
          })
        );
      } else if (incompleteData?.speciality === "cosmetic") {
        dispatch(
          cosmeticConsultationReducers.incompleteData({
            value: incompleteData,
          })
        );
      } else if (incompleteData?.speciality === "pediatric") {
        dispatch(
          pedVisitFlowReducers.incompleteData({
            value: incompleteData,
          })
        );
      }
    } else {
      cosmeticConsultationReducers.reset();
    }
  }, [incompleteData, dispatch]);

  return {
    fetchIncompleteData,
    incompleteData,
    fetchingIncompleteData,
  };
}

export default useIncompleteData;
