import { createSlice } from "@reduxjs/toolkit";
import { primaryCareVisitFlowInitialState } from "./primaryCareVisitFlowInitialState";
import { primaryCareAdditionalDetailsReducer } from "./steps/primaryCareAdditionalDetailsReducer";
import { primaryCareBasicInfoReducer } from "./steps/primaryCareBasicInfoReducer";
import { primaryCareMainStepsReducer } from "./steps/primaryCareMainStepsReducer";
import { primaryCareSelectSlotReducer } from "./steps/primaryCareSelectSlotReducer";
import { primaryCareVisitDetailsReducer } from "./steps/primaryCareVisitDetailsReducer";

const initialState = primaryCareVisitFlowInitialState;

const primaryCareVisitFlowSlice = createSlice({
  name: "primaryCareVisitFlow",
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    currentScreen(state, action) {
      const { value } = action.payload;
      state["currentScreen"] = value;
    },
    incompleteData(state, action) {
      const { value } = action.payload;
      state["incompleteData"] = value;
    },
    patientsList(state, action) {
      const { value } = action.payload;
      state["patientsList"] = value;
    },
    completedProgress(state, action) {
      const { value } = action.payload;
      if (value && value > state.completedProgress) {
        state["completedProgress"] = value;
      }
    },
    setPaymentMethod(state, action) {
      const { value } = action.payload;
      if (value) {
        state["paymentMethod"] = value;
      }
    },
    ...primaryCareMainStepsReducer,
    ...primaryCareBasicInfoReducer,
    ...primaryCareVisitDetailsReducer,
    ...primaryCareSelectSlotReducer,
    ...primaryCareAdditionalDetailsReducer,
  },
});

export const primaryCareVisitFlowReducers = primaryCareVisitFlowSlice.actions;

export default primaryCareVisitFlowSlice.reducer;
