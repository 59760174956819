import React from "react";
import CashOption from "./CashOption";
import InsuranceOption from "./InsuranceOption";
import PCInsuranceOption from "./PCInsuranceOption";
import { trackEvent } from "helpers/analytics";

function ChoosePaymentMethod({
  acceptInsurance = false,
  speciality,
  currentPatient,
  cashPriceResponse,
  insuranceDetails,
  onClickAddInsurance,
  onClickUpdateInsurance,
  selected,
  onSelect,
  isInsuranceExpired,
  checkingInsuranceInNetwork,
  insuranceInNetwork,
}) {
  const payment_data = [
    {
      price: insuranceDetails?.final_price,
      type: "insurance",
      component: () =>
        speciality === 2 ? (
          acceptInsurance || selected === "insurance" ? (
            <PCInsuranceOption
              speciality={speciality}
              currentPatient={currentPatient}
              isInsuranceExpired={isInsuranceExpired}
              isActive={selected === "insurance"}
              onClick={() => onSelect("insurance")}
              selectCash={() => onSelect("cash")}
              onClickAddInsurance={onClickAddInsurance}
              onClickUpdateInsurance={onClickUpdateInsurance}
              insurance_details={insuranceDetails}
              checkingInsuranceInNetwork={checkingInsuranceInNetwork}
              insuranceInNetwork={insuranceInNetwork}
            />
          ) : (
            <div />
          )
        ) : acceptInsurance || selected === "insurance" ? (
          <InsuranceOption
            speciality={speciality}
            currentPatient={currentPatient}
            isInsuranceExpired={isInsuranceExpired}
            isActive={selected === "insurance"}
            onClick={() => {
              onSelect("insurance");
              trackEvent("switch_to_insurance");
            }}
            selectCash={() => onSelect("cash")}
            onClickAddInsurance={onClickAddInsurance}
            onClickUpdateInsurance={onClickUpdateInsurance}
            insurance_details={insuranceDetails}
            checkingInsuranceInNetwork={checkingInsuranceInNetwork}
            insuranceInNetwork={insuranceInNetwork}
          />
        ) : null,
    },
    {
      price: cashPriceResponse?.cash_price,
      type: "cash",
      component: () => (
        <CashOption
          isActive={selected === "cash"}
          onClick={() => {
            onSelect("cash");
            trackEvent("switch_self_pay");
          }}
          price={cashPriceResponse?.cash_price}
        />
      ),
    },
  ];
  const sortedOptions = payment_data?.sort((a, b) => {
    return a?.price - b?.price;
  });
  return (
    <div>
      {sortedOptions?.map((el, idx) => {
        return (
          <div className="my-7" key={idx}>
            {el?.component()}
          </div>
        );
      })}
    </div>
  );
}

export default ChoosePaymentMethod;
