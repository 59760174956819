import React, { useEffect, useRef, useState } from "react";
import MultiLineInput from "components/inputs/MultiLineInput";
import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import VisitSubQuestionSlideUpContainer from "components/visitSubQuestionSlideUp/VisitSubQuestionSlideUpContainer";
import { Form, Formik } from "formik";
import { cosmetic_steps } from "helpers/enum";
import { PriorTreatmentsSchema } from "helpers/validationSchema";
import { useCosmeticConsultationFlow } from "hooks/useConsultationFlow";
import useVisitStepChange from "hooks/useVisitStepChange";
import { useDispatch } from "react-redux";
import { cosmeticConsultationReducers } from "reducers/cosmetic/cosmeticConsultationReducer";
import scrollToBottom from "helpers/scrollToBottom";
import useUpdateCosmeticConsultation from "hooks/consultation/useUpdateCosmeticConsultation";
import { useHistory, useRouteMatch } from "react-router";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { useCurrentUser } from "hooks/useCurrentUser";

function CosmeticPrevTreatmentFeedback() {
  const formRef = useRef();
  const history = useHistory();
  const { url } = useRouteMatch();

  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [
    previous_diagnosis_value_from_api,
    set_previous_diagnosis_value_from_api,
  ] = useState("");

  const { userDetails } = useCurrentUser();
  const { steps, incompleteData } = useCosmeticConsultationFlow();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore =
    steps[cosmetic_steps.previous_treatment].value;

  const { updateConsultation } = useUpdateCosmeticConsultation();

  useEffect(() => {
    if (currentScreenValueFromStore?.length) {
      if (currentScreenValueFromStore === "None") {
        setSelected("No");
      } else {
        setSelected("Yes");
        set_previous_diagnosis_value_from_api(currentScreenValueFromStore);
      }
    }
  }, [currentScreenValueFromStore]);

  useEffect(() => {
    if (selected) {
      if (selected === "No") {
        dispatch(
          cosmeticConsultationReducers.previous_treatment({
            isValid: true,
          })
        );
      } else {
        scrollToBottom();
        if (formRef?.current?.isValid) {
          dispatch(
            cosmeticConsultationReducers.previous_treatment({
              isValid: true,
            })
          );
        }
      }
    } else {
      dispatch(
        cosmeticConsultationReducers.previous_treatment({
          isValid: false,
        })
      );
    }
  }, [dispatch, selected]);

  function updateConsultationApi(previous_diagnosis_value) {
    updateConsultation({
      payload: {
        case: {
          id: incompleteData?.id,
          previously_treated:
            previous_diagnosis_value === "None" ? false : true,
          previous_diagnosis: previous_diagnosis_value,
          cosmetic_steps: incompleteData?.cosmetic_steps,
        },
      },
      change_current_screen: false,
      screen_to_complete: cosmetic_steps.previous_treatment,
      callBack: () => {
        dispatch(
          cosmeticConsultationReducers.previous_treatment({
            value: previous_diagnosis_value,
          })
        );
        history.push(`${url}/terms_and_conditions`);
      },
    });
  }

  function onRightButtonClick() {
    if (selected === "Yes") {
      if (formRef?.current) {
        formRef?.current?.handleSubmit();
      }
    } else {
      updateConsultationApi("None");
    }
  }

  function onFormSubmit(values) {
    updateConsultationApi(values?.prior_treatments);
  }

  return (
    <StepScreenContainer align="start">
      <MedicalAssistant
        content={
          <p>
            {userDetails?.first_name}, one last question: Have you ever had any
            cosmetic work done before?
          </p>
        }
      />
      <Card>
        <RadioButtonCard
          options={["Yes", "No"]}
          onSelect={setSelected}
          selected={selected}
        />
      </Card>
      {selected === "Yes" ? (
        <VisitSubQuestionSlideUpContainer>
          <Spacer height={40} />
          <Card>
            <Formik
              innerRef={formRef}
              onSubmit={onFormSubmit}
              initialValues={{
                prior_treatments: previous_diagnosis_value_from_api ?? "",
              }}
              validationSchema={PriorTreatmentsSchema}
            >
              {() => (
                <Form>
                  <MultiLineInput
                    label={"List prior treatments for your condition"}
                    id={"prior_treatments"}
                    name={"prior_treatments"}
                  />
                </Form>
              )}
            </Formik>
          </Card>
        </VisitSubQuestionSlideUpContainer>
      ) : null}
    </StepScreenContainer>
  );
}

export default CosmeticPrevTreatmentFeedback;
