import React, { forwardRef } from "react";
import { useField } from "formik";
import DatePicker from "react-date-picker";
// import { isMobile } from "react-device-detect";
import moment from "moment";

import "./Input.scss";
import classNames from "classnames";
import { Icon } from "stories/Icons";

const BirthInput = forwardRef((props, ref) => {
  const [field, meta] = useField(props);
  const {
    label,
    name,
    max,
    isOpen,
    open,
    close,
    onChange,
    onBlur,
    className,
    required,
    info,
    disabled = false,
  } = props;
  const formattedValue = field?.value?.replace(/\//g, "-");

  const handleChange = (date) => {
    let event = {
      target: {
        name: props?.name,
        value: date ? moment(date).format("YYYY-MM-DD") : "",
      },
    };
    props?.onChange(event);
  };

  const getValue = () => {
    return field?.value?.length
      ? moment(field?.value, "YYYY-MM-DD").toDate()
      : null;
  };

  const handleCalendarOpen = () => {
    open();
    field?.onBlur({ target: { name: name } });
  };

  const handleCalendarClose = () => {
    close();
    props?.onBlur();
  };
  const minDate = new Date(1925, 0, 1);

  return (
    <div className="flex flex-col custom_input">
      <label
        className={`font-bold text-2xl text-indigo text-opacity-50 pb-2 ${
          required && "required-field"
        }`}
      >
        {label}
      </label>
      {false ? (
        <input
          ref={ref}
          className={classNames("text-left", className)}
          type="date"
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={formattedValue}
          min="1900-01-01"
          max="2030-12-31"
        />
      ) : (
        <DatePicker
          name={name}
          isOpen={isOpen}
          disabled={disabled}
          onCalendarOpen={handleCalendarOpen}
          onCalendarClose={handleCalendarClose}
          className={classNames(
            "date-picker-class",
            meta.touched && meta.error && "invalid"
          )}
          calendarClassName="date-picker-calendar"
          value={getValue()}
          format={"MM/dd/y"}
          openCalendarOnFocus={false}
          yearPlaceholder={"YYYY"}
          monthPlaceholder={"MM"}
          dayPlaceholder={"DD"}
          calendarIcon={<Icon name={"calender"} />}
          minDate={minDate}
          maxDate={new Date(max)}
          onChange={handleChange}
          role="datepicker"
          aria-label="Select a date"
          aria-expanded={isOpen}
        />
      )}
      {info && !(meta.touched && meta.error) ? (
        <p className="mt-2 text-base font-bold text-secondaryBlue-500">
          {info}
        </p>
      ) : null}
      {meta.touched && meta.error ? (
        <p className="mt-2 text-base font-bold text-red">{meta.error}</p>
      ) : null}
    </div>
  );
});

export default BirthInput;
