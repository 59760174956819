import PharmacyCard from "components/pharmacyCard/PharmacyCard";
import React from "react";

const PrescriptionMedicineTabContent = ({ visitDetails }) => {
  return (
    <div className="mx-12">
      <table className="table w-full bg-white border border-collapse table-auto border-black-500">
        <thead>
          <tr>
            <th className="p-2 text-2xl font-bold text-left border border-black-500 md:p-4 min-w-25r">
              Name of Medication
            </th>
            <th className="p-2 text-2xl font-bold text-left border border-black-500 md:p-4">
              Instruction
            </th>
          </tr>
        </thead>
        <tbody>
          {visitDetails?.diagnosis?.medications?.map((el, index) => {
            return (
              <tr key={index}>
                <td className="p-2 text-2xl font-medium border border-black-500 md:p-4">
                  {el?.name}
                </td>
                <td className="p-2 text-2xl font-medium border border-black-500 md:p-4">
                  {el?.detail}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {visitDetails?.pharmacy_address !== "None" ? (
        <div className="mt-5">
          <div className="text-2xl font-medium opacity-50">
            The above presciptions have been made available for pick up at
          </div>
          <div className="p-6 mt-5 bg-white border border-borderColor rounded-xl">
            <PharmacyCard
              removeEdit
              pharmacyAddess={visitDetails?.pharmacy_address}
            />
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default PrescriptionMedicineTabContent;
