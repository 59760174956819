import React from "react";
import CSSLoader from "../cssLoader/CSSLoader";
function Button({
  label,
  loading,
  iconComponent,
  className,
  outlined,
  buttonColor,
  loaderColor,
  textSize = "text-3xl",
  ...rest
}) {
  return (
    <button
      className={`flex items-center justify-center font-bold p-8 w-full focus:outline-none focus:ring-2 bg-${
        outlined ? "transparent" : buttonColor ? buttonColor : "orange"
      } text-${outlined ? "orange" : "white"} rounded-lg ${
        rest?.disabled || loading
          ? "opacity-60 cursor-not-allowed"
          : "cursor-pointer"
      } ${textSize} ${className} ${outlined ? "border border-orange" : ""}`}
      {...rest}
    >
      {label}
      {loading ? (
        <CSSLoader className={"ml-3 w-8 h-8"} color={loaderColor} />
      ) : iconComponent ? (
        iconComponent
      ) : null}
    </button>
  );
}

export default Button;
