import React from "react";
import Images from "assets/images";
import { step_status } from "helpers/enum";

function StepSubHeader({ data, status, onClick }) {
  return (
    <div
      onClick={() =>
        status === step_status.upcoming ? null : onClick(data?.key)
      }
      className={`relative bg-opacity-5 border rounded-1/4 mb-10 flex items-center justify-between pr-6 pl-12 py-8 cursor-pointer ${
        status === step_status.completed
          ? "border-green bg-green"
          : "border-orange bg-orange"
      } ${
        status === step_status.upcoming
          ? "opacity-25 cursor-not-allowed"
          : "opacity-100"
      }`}
    >
      <h3 className="text-2xl font-medium">{data?.name}</h3>
      <img className="w-6" src={Images.arrowRight} alt="arrow_right" />
      {status === step_status.completed ? (
        <img
          className="w-10 absolute -left-5"
          src={Images.checkedGreenFilled}
          alt="completed"
        />
      ) : null}
    </div>
  );
}

export default StepSubHeader;
