import React from "react";
import { NavLink, Route } from "react-router-dom";
import Images from "assets/images";
import { useCurrentUser } from "hooks/useCurrentUser";
import classNames from "classnames";
import { Icon } from "stories/Icons";
const routes = [
  {
    to: "/home",
    icon: Images.bottom_tab.home,
    name: "home",
    activeIcon: Images.bottom_tab.homeActive,
    label: "Home",
  },
  {
    to: "/home/my-visits",
    icon: Images.bottom_tab.myVisits,
    name: "myVisits",
    activeIcon: Images.bottom_tab.myVisitsActive,
    label: "My Visits",
  },
  {
    to: "/home/referrals",
    icon: Images.bottom_tab.referrals,
    name: "referral",
    activeIcon: Images.bottom_tab.referralsActive,
    label: "Referrals",
  },
  {
    to: "/home/profile",
    icon: Images.bottom_tab.profile,
    name: "myProfile",
    activeIcon: Images.bottom_tab.profileActive,
    label: "My Profile",
  },
];

const GradientButton = ({ label, active, name }) => (
  <div
    className={classNames(
      "flex items-center gap-4 justify-start px-4 py-4 mx-6 rounded-md  from-blue-300 to-blue-500 text-2xl",
      active
        ? "bg-gradient-to-b text-white font-bold"
        : "bg-transparent text-grey-500 font-medium"
    )}
  >
    <Icon name={name} className={classNames("w-8 h-8")} />
    <p>{label}</p>
  </div>
);
function BottomTab() {
  const { visitMessageCount } = useCurrentUser();
  return (
    <>
      <div className="fixed top-0 left-0 z-30 hidden h-screen pt-10 bg-white shadow-xl lg:block w-sidebar">
        <NavLink className="no-underline" to={"/home"}>
          <img
            src={Images.logoHorizontalNaming}
            className="object-contain mx-auto my-0 cursor-pointer h-14"
            alt="logo"
            // onClick
          />
        </NavLink>

        {/* Sidebar content */}
        <ul className="py-10 space-y-5 ">
          {routes.map(({ to, name, label }) => (
            <li key={label}>
              <Route
                children={({ location }) => {
                  const isActive =
                    location.pathname === to ||
                    (to !== "/home" && location.pathname.startsWith(to));
                  return (
                    <NavLink className="no-underline" to={to} exact>
                      <GradientButton
                        name={name}
                        active={isActive}
                        label={label}
                      ></GradientButton>
                    </NavLink>
                  );
                }}
              />
            </li>
          ))}
        </ul>
      </div>
      {/* Mobile Responsive */}
      <nav className="fixed bottom-0 z-10 w-full bg-white lg:hidden h-bottomTabBarHeight">
        <div className="flex flex-col justify-center h-full shadow-d-xl">
          <ul className="flex justify-around list-none">
            {routes.map(({ to, name, label }) => (
              <li key={label}>
                <Route
                  children={({ location }) => {
                    const isActive =
                      location.pathname === to ||
                      (to !== "/home" && location.pathname.startsWith(to));
                    return (
                      <NavLink
                        className="flex flex-col items-center justify-center no-underline duration-300 transform scale-90 group"
                        to={to}
                        activeClassName={
                          isActive ? "text-blue scale-100" : "text-grey-500"
                        }
                      >
                        <div className="relative w-8 h-8 md:w-9 md:h-9">
                          <Icon
                            name={name}
                            className={classNames(
                              "w-9 h-9",
                              isActive ? "font-bold" : "text-grey-500"
                            )}
                          />
                          {to === "/home/my-visits" &&
                            visitMessageCount > 0 && (
                              <span
                                className={`absolute -top-3 left-5 w-6 h-6 -mt-0.5 -mr-1 rounded-full bg-red text-white font-bold w-${
                                  visitMessageCount > 99 ? "10" : "8"
                                } h-${
                                  visitMessageCount > 99 ? "10" : "8"
                                } flex items-center justify-center -right-${
                                  visitMessageCount > 99 ? "6" : "4"
                                } -top-${visitMessageCount > 99 ? "6" : "4"}`}
                              >
                                {visitMessageCount}
                              </span>
                            )}
                        </div>
                        <p
                          className={`text-xl mt-3 ${
                            isActive
                              ? "font-bold "
                              : "font-medium text-cloudyBlue"
                          } md:text-2xl`}
                        >
                          {label}
                        </p>
                      </NavLink>
                    );
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </>
  );
}

export default BottomTab;
