import React, { useState } from "react";

import DoctorCard from "components/doctorCard/DoctorCard";
import Header from "components/header/Header";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Spacer from "components/Spacer/Spacer";

import Images from "assets/images";
import { useCurrentUser } from "hooks/useCurrentUser";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import { useEffect } from "react";

function ProvidersList({
  providers,
  selectedProvider,
  onProviderChoose,
  onClose,
}) {
  const { userDetails } = useCurrentUser();
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (selectedProvider) {
      setSelected(selectedProvider);
    }
  }, [selectedProvider]);

  return (
    <div className="bg-veryLightBlue md:bg-gradient-to-b from-blue-300 to-blue-500">
      <Header
        bgColor="bg-white"
        title={"Choose Your Doctor"}
        hideBackBtn
        rightActionView={
          <img
            onClick={onClose}
            className="w-10 cursor-pointer"
            src={Images.closeIcon}
            alt={"close_icons"}
          />
        }
      />
      <div className="px-0 py-8 pb-64 overflow-y-auto wrapper h-eliminateHeader bg-blue md:bg-transparent">
        <MedicalAssistant
          xOffset={23}
          content={
            <p>
              {userDetails?.first_name}, let's find the perfect{" "}
              {providers?.[0]?.speciality.includes("Dermatologist")
                ? "Dermatologist"
                : "Primary Care Physician"}{" "}
              for you. Please select from the list below.
            </p>
          }
        />
        <Spacer height={25} />
        {providers?.map((provider, index) => {
          return (
            <div
              key={index}
              className={`cursor-pointer flex items-center justify-between mb-6 p-6 mx-10 rounded-4xl transition bg-white ${
                selected?.id === provider?.id
                  ? "border-orange border-2"
                  : "border-borderColor border"
              }`}
              onClick={() => setSelected(provider)}
            >
              <DoctorCard
                imageUrl={provider?.avatar}
                name={provider?.name}
                speciality={provider?.speciality}
                isActive={true}
                license={provider?.license}
              />
              {selected?.id === provider?.id ? (
                <>
                  <Spacer width={8} />
                  <img
                    className="w-8"
                    src={Images.checkedOrange}
                    alt="checked"
                  />
                </>
              ) : null}
            </div>
          );
        })}
      </div>
      <BottomFixedButton
        label="Continue"
        bgColor="bg-white"
        onClick={() => onProviderChoose(selected)}
        disabled={!selected}
      />
    </div>
  );
}

export default ProvidersList;
