import React, { useEffect, useState } from "react";
import Images from "assets/images";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import DoctorCard from "components/doctorCard/DoctorCard";
import Header from "components/header/Header";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Placeholder from "components/placeholders/Placeholder";
import Spacer from "components/Spacer/Spacer";
import { useCurrentUser } from "hooks/useCurrentUser";
import PCProviderProfileDetails from "components/pcProviderProfileDetails/PCProviderProfileDetails";

function CosmeticSelectProvider({
  caseId,
  onClose,
  availableProviders,
  onSelectProvider,
  selectedProvider,
}) {
  const { userDetails } = useCurrentUser();
  const [selected, setSelected] = useState(null);
  const [viewProfile, setViewProfile] = useState(null);

  useEffect(() => {
    if (selectedProvider) {
      setSelected(selectedProvider);
    }
  }, [selectedProvider, setSelected, viewProfile]);

  if (viewProfile) {
    return (
      <PCProviderProfileDetails
        details={viewProfile}
        caseId={caseId}
        onClose={() => setViewProfile(null)}
      />
    );
  } else {
    return (
      <div className="h-screen bg-veryLightBlue">
        <Header
          hideBackBtn
          title="Choose Your Consultant"
          rightActionView={
            <img
              onClick={onClose}
              className="cursor-pointer w-10"
              src={Images.closeIcon}
              alt={"close_icon"}
            />
          }
        />
        <div
          className={`wrapper h-${
            availableProviders?.length === 0
              ? "eliminateHeader"
              : "eliminateHeaderWithFooter"
          } overflow-y-auto bg-white`}
        >
          {availableProviders?.length === 0 ? (
            <div className="h-4/5">
              <Placeholder
                heading={`We are working hard to find a 
        Cosmetic Consultant who is licensed to provide services in ${userDetails?.city}.`}
                paragraph={
                  "We will notify you when we have a provider available to service in your zipcode"
                }
                placeholderImage={Images.searchDoctor}
              />
            </div>
          ) : (
            <div className="px-12">
              <Spacer height={12} />
              <MedicalAssistant
                content={
                  <p>
                    {userDetails?.first_name}, let’s find another board
                    certified doctor for you.
                  </p>
                }
              />
              <Spacer height={25} />
              {availableProviders?.length &&
                availableProviders?.map((provider, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`relative cursor-pointer flex items-center justify-between mb-6 p-6 rounded-4xl transition ${
                        selected?.id === provider?.id
                          ? "border-orange border-2"
                          : "border-borderColor border"
                      }`}
                      onClick={() => setSelected(provider)}
                    >
                      <DoctorCard
                        license={provider?.license}
                        isActive={provider?.active}
                        name={provider?.name}
                        imageUrl={provider?.avatar}
                        speciality={provider?.speciality ?? ""}
                        viewProfile={true}
                        onViewProfileClick={() => {
                          setViewProfile(provider);
                        }}
                      />

                      {selected?.id === provider?.id ? (
                        <>
                          <Spacer width={8} />
                          <img
                            className="w-8"
                            src={Images.checkedOrange}
                            alt="checked"
                          />
                        </>
                      ) : null}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        {availableProviders?.length === 0 ? null : (
          <BottomFixedButton
            disabled={selected ? false : true}
            loading={false}
            label="Select Provider"
            onClick={() => {
              onSelectProvider(selected);
              onClose();
            }}
          />
        )}
      </div>
    );
  }
}

export default CosmeticSelectProvider;
