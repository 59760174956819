import React, { useCallback, useEffect, useState } from "react";
import { getCheckoutDetails } from "api/actions/VisitActions";
import { speciality } from "helpers/enum";
import { useApiQuery } from "hooks/useApiQuery";
import { usePedVisitFlow } from "hooks/useVisitFlow";
import useModal from "hooks/useModal";
import InsuranceUpdateModal from "../../common/InsuranceUpdateModal";
import CardPayments from "components/payment/CardPayments";
import useVisitStepChange from "hooks/useVisitStepChange";
import PromoCode from "../../common/PromoCode";
import SelectPaymentMethod from "../../common/SelectPaymentMethod";
import useToastify from "hooks/useToastify";
import Button from "components/buttons/Button";
import CSSLoader from "components/cssLoader/CSSLoader";
import usePatients from "hooks/visit/usePatients";
import { MdWarningAmber } from "react-icons/md";
import classNames from "classnames";

function PaymentDetails({
  incompleteData,
  updateVisit,
  updatingVisit,
  setActiveIndex,
  checkingInsuranceInNetwork,
  insuranceInNetwork,
}) {
  const { showAlert } = useToastify();
  const { setShowModal } = useModal();
  const { loading } = useVisitStepChange();
  const { patientsList, paymentMethod } = usePedVisitFlow();
  const { getCurrentPatient } = usePatients();

  const [cards, setCards] = useState([]);
  const [allowPromoCode, setAllowPromoCode] = useState(true);
  const [promoCode, setPromoCode] = useState("");
  const [promoAmount, setPromoAmount] = useState(0);
  const [isPromoCodeApplied, setIsPromoCodeApplied] = useState(false);
  const [checkingCoupon, setCheckingCoupon] = useState(false);
  const [addingCard, setAddingCard] = useState(false);
  const [isCheckingPrice, setIsCheckingPrice] = useState(false);
  const [confirmPaymentMethod, setConfirmPaymentMethod] = useState(
    paymentMethod
  );
  const [cashPrice, setCashPrice] = useState(null);
  const [insuranceBreakDown, setInsuranceBreakDown] = useState(null);

  const {
    loading: isGettingCheckoutDetails,
    query: callGetCheckoutDetails,
    payload: checkoutDetails,
  } = useApiQuery(
    getCheckoutDetails(incompleteData?.id, speciality.ped),
    false
  );

  useEffect(() => {
    callGetCheckoutDetails();
  }, [callGetCheckoutDetails]);

  useEffect(() => {
    if (checkoutDetails) {
      if (checkoutDetails?.insurance) {
        setConfirmPaymentMethod("insurance");
      }
    }
  }, [checkoutDetails, setConfirmPaymentMethod]);

  const getFinalPrice = useCallback(() => {
    if (confirmPaymentMethod === "insurance") {
      return 249;
    } else if (confirmPaymentMethod === "cash") {
      if (promoAmount) {
        return Number(cashPrice) - Number(promoAmount);
      } else {
        return Number(cashPrice);
      }
    } else {
      return null;
    }
  }, [cashPrice, confirmPaymentMethod, promoAmount]);

  useEffect(() => {
    if (confirmPaymentMethod) {
      if (confirmPaymentMethod === "insurance") {
        setPromoAmount(0);
        setPromoCode("");
        setAllowPromoCode(false);
        setIsPromoCodeApplied(false);
      } else if (confirmPaymentMethod === "cash") {
        setAllowPromoCode(true);
      }
    } else {
      setPromoAmount(0);
      setPromoCode("");
      setAllowPromoCode(false);
      setIsPromoCodeApplied(false);
    }
  }, [confirmPaymentMethod, getFinalPrice]);

  async function updateVisitApi() {
    updateVisit({
      payload: {
        case: {
          meta: {
            ...incompleteData?.meta,
            coupon_code:
              confirmPaymentMethod === "cash"
                ? isPromoCodeApplied
                  ? promoCode
                  : null
                : null,
            submitted_payment_info: true,
          },
          allergies: incompleteData?.allergies_json,
          medications:
            typeof incompleteData?.medications[0] === "string"
              ? incompleteData?.medications.map((item) => ({
                  name: item,
                }))
              : incompleteData?.medications,
          coupon_code:
            confirmPaymentMethod === "cash"
              ? isPromoCodeApplied
                ? promoCode
                : null
              : null,
          payment_method:
            confirmPaymentMethod === "cash"
              ? 0
              : confirmPaymentMethod === "insurance"
              ? 1
              : null,
        },
      },
      change_current_screen: false,
      screen_to_complete: null,
      callBack: () => {
        showAlert("Payment details added successfully", "success");
        setActiveIndex(null);
      },
    });
  }

  const brightPayInsurance =
    !getCurrentPatient(incompleteData?.patient_id, patientsList)?.insurance
      ?.payment_required && confirmPaymentMethod === "insurance";

  const currentInsurance = getCurrentPatient(
    incompleteData?.patient_id,
    patientsList
  );
  const insuranceSelected =
    currentInsurance?.insurance.insurance_company === "NAPHCARE INC"
      ? "NaphCare"
      : currentInsurance?.insurance.insurance_company;

  function isButtonValid() {
    if (brightPayInsurance) {
      if (
        currentInsurance?.insurance.insurance_company === "NAPHCARE INC" &&
        !incompleteData?.insurance_authorisation?.auth_id
      ) {
        return false;
      } else return true;
    }
    if (incompleteData?.accepted_tnc) {
      if (
        confirmPaymentMethod === "insurance" &&
        getCurrentPatient(incompleteData?.patient_id, patientsList)?.insurance
          ?.auth_id_required &&
        !incompleteData?.insurance_authorisation?.auth_id
      ) {
        return false;
      } else if (
        isCheckingPrice ||
        !confirmPaymentMethod ||
        checkingCoupon ||
        checkingInsuranceInNetwork
      ) {
        return false;
      } else {
        if (
          typeof getFinalPrice() === "number" &&
          getFinalPrice() === 0 &&
          cards?.length
        ) {
          return true;
        } else if (
          typeof getFinalPrice() === "number" &&
          getFinalPrice() > 0 &&
          cards?.length
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  return (
    <div className="px-12 h-eliminateHeaderWithFooter bg-white overflow-y-auto wrapper rounded-b-4xl">
      {isGettingCheckoutDetails || loading ? (
        <div className="absolute top-0 left-0 bg-alternativeWhite bg-opacity-80 flex items-center justify-center">
          <CSSLoader className="w-12 h-12" color="orange" />
          <p className="text-3xl font-bold">
            {loading
              ? "Submitting your visit. Do not press the refresh (or) go back button"
              : "Please wait"}
          </p>
        </div>
      ) : (
        <>
          <div className="pt-8">
            <SelectPaymentMethod
              checkoutDetails={checkoutDetails}
              cashPrice={cashPrice}
              setCashPrice={setCashPrice}
              setIsCheckingPrice={setIsCheckingPrice}
              insuranceBreakDown={insuranceBreakDown}
              setInsuranceBreakDown={setInsuranceBreakDown}
              paymentMethod={confirmPaymentMethod}
              setPaymentMethod={setConfirmPaymentMethod}
              onUpdatePress={(insurance, currentPatient, callBack) => {
                setShowModal({
                  state: true,
                  children: (_, onClose) => (
                    <InsuranceUpdateModal
                      onClose={onClose}
                      insuranceDetails={insurance}
                      currentPatient={currentPatient}
                      currentSpeciality={speciality.ped}
                    />
                  ),
                  data: { preventRender: true },
                });
              }}
              currentSpeciality={speciality.ped}
              patientsList={patientsList}
              incompleteData={incompleteData}
              checkingInsuranceInNetwork={checkingInsuranceInNetwork}
              insuranceInNetwork={insuranceInNetwork}
            />
          </div>
          <div className="pt-8">
            {brightPayInsurance && (
              <p className="text-yellow-500 text-2xl font-medium mb-3 ml-1 flex items-center self-center gap-3">
                <MdWarningAmber size={14} />
                Payment method is not required at this time for{" "}
                {insuranceSelected}
              </p>
            )}
            <div
              className={classNames(
                brightPayInsurance && "px-6 mb-5",
                "md:rounded-xl relative"
              )}
            >
              {brightPayInsurance && (
                <div className="absolute inset-0 md:rounded-xl z-10 bg-gray-200 opacity-50 cursor-not-allowed" />
              )}
              <CardPayments
                amount={Math.max(getFinalPrice()).toFixed(2)}
                setAddingCard={setAddingCard}
                cards={cards}
                setCards={setCards}
                caseId={incompleteData?.id}
              />
            </div>
          </div>
          {allowPromoCode && Number(cashPrice) > 0 ? (
            <div className="pb-8">
              <PromoCode
                promoAmount={promoAmount}
                amount={cashPrice}
                promoCode={promoCode}
                setPromoCode={setPromoCode}
                setPromoAmount={setPromoAmount}
                isPromoCodeApplied={isPromoCodeApplied}
                setIsPromoCodeApplied={setIsPromoCodeApplied}
                setCheckingCoupon={setCheckingCoupon}
                speciality={speciality.ped}
              />
            </div>
          ) : null}
          <Button
            className="mb-8"
            onClick={updateVisitApi}
            loading={
              loading ||
              addingCard ||
              updatingVisit ||
              checkingInsuranceInNetwork
            }
            label="Next"
            disabled={isButtonValid() ? false : true}
          />
        </>
      )}
    </div>
  );
}

export default PaymentDetails;
