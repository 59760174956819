import Images from "assets/images";
import Chip from "components/chip/Chip";
import Header from "components/header/Header";
import SearchInput from "components/inputs/SearchInput";
import Spacer from "components/Spacer/Spacer";
import React, { useEffect, useState } from "react";
import { bodyParts } from "./bodyParts";

function SelectAffectedPartModal({ onClose, selected, setSelected }) {
  const [selectedLocal, setSelectedLocal] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (selected?.length) {
      setSelectedLocal(selected);
    }
  }, [selected]);

  return (
    <div className="bg-veryLightBlue h-full">
      <Header
        title={"Select Body Part"}
        leftActionView={
          <img
            onClick={onClose}
            className="w-8 cursor-pointer"
            src={Images.closeIcon}
            alt={"close_icons"}
          />
        }
        rightActionView={
          <p
            onClick={() => {
              setSelected(selectedLocal);
              onClose();
            }}
            className="text-2xl text-orange font-bold cursor-pointer"
          >
            Done
          </p>
        }
      />
      <div className="wrapper">
        <div className="border-b border-borderColor bg-white">
          <div className="py-6 px-12">
            <SearchInput
              value={searchText}
              onChange={(event) => setSearchText(event?.target?.value)}
            />
            {searchText?.trim()?.length ? (
              <>
                <Spacer height={20} />
                <h3
                  onClick={() => {
                    if (searchText?.trim()?.length) {
                      setSelectedLocal((prev) => [...prev, searchText]);
                      setSearchText("");
                    }
                  }}
                  className={"text-2xl font-bold pl-2 cursor-pointer"}
                >
                  Add "{searchText}"
                </h3>
              </>
            ) : null}
          </div>
        </div>
        {selectedLocal?.length ? (
          <div className="border-b border-borderColor bg-white">
            <div className="pt-6 px-12">
              <h2 className="text-xl font-black tracking-widest">
                {selectedLocal?.length} SELECTED
              </h2>
              <div className="overflow-x-auto">
                <div className="flex pt-8 pb-10">
                  {selectedLocal?.map((el, index) => (
                    <Chip
                      text={el}
                      key={index}
                      onClear={(el) => {
                        setSelectedLocal((prev) =>
                          prev?.filter((elem) => elem !== el)
                        );
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="px-0 py-8 pb-64 h-eliminateHeaderWithChipContainer bg-alternativeWhite">
          <div className="px-12">
            {bodyParts?.map((part, index) => {
              if (
                selectedLocal?.find((el) => el === part) ||
                (searchText?.length &&
                  part?.toLowerCase()?.includes(searchText?.toLowerCase()) ===
                    false)
              ) {
                return null;
              } else {
                return (
                  <div
                    onClick={() => {
                      setSelectedLocal((prev) => [...prev, part]);
                      setSearchText("");
                    }}
                    key={index}
                    className="bg-white border border-borderColor rounded-1.6 p-8 px-12 mb-5 cursor-pointer"
                  >
                    <p className="text-2xl font-bold">{part}</p>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectAffectedPartModal;
