import React, { useState } from "react";
import CheckBoxCard from "components/checkBoxCard/CheckBoxCard";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import { femaleMedicalHistoryData } from "./medicalHistoryData";
import { useDispatch } from "react-redux";
import useVisitStepChange from "hooks/useVisitStepChange";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import { dermatology_sub_steps } from "helpers/enum";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { useEffect } from "react";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";

function FemaleMedicalHistory() {
  const dispatch = useDispatch();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const [selected, setSelected] = useState([]);
  const { incompleteData, subSteps } = useDermatologyVisitFlow();
  const currentScreenValueFromStore =
    subSteps[dermatology_sub_steps.female_medical_history].value;
  const { updateVisit } = useUpdateDermatologyVisit();

  useEffect(() => {
    if (currentScreenValueFromStore?.length) {
      const result = currentScreenValueFromStore?.filter((el) => {
        return femaleMedicalHistoryData.some((elem) => {
          return el === elem;
        });
      });
      setSelected(result);
    }
  }, [currentScreenValueFromStore]);

  useEffect(() => {
    if (selected?.length) {
      dispatch(
        dermaVisitFlowReducers.female_medical_history({
          isValid: true,
        })
      );
    } else {
      dispatch(
        dermaVisitFlowReducers.female_medical_history({
          isValid: false,
        })
      );
    }
  }, [dispatch, selected]);

  function onRightButtonClick(_, nextAnimation) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          fhistory: selected,
          ghistory:
            subSteps?.[dermatology_sub_steps.medical_history]?.value ?? [],
          dermatology_steps: incompleteData?.dermatology_steps,
        },
      },
      change_current_screen: true,
      screen_to_complete: dermatology_sub_steps.medical_history,
      callBack: () => {
        dispatch(
          dermaVisitFlowReducers.female_medical_history({
            value: selected,
          })
        );
        nextAnimation();
      },
    });
  }

  function handleOnSelect(el) {
    if (el === "None") {
      setSelected([el]);
    } else {
      let final = [...selected];

      if (final?.includes("None")) {
        final = final.filter((elem) => elem !== "None");
      }
      if (final?.includes(el)) {
        setSelected(final.filter((elem) => elem !== el));
      } else {
        setSelected([...final, el]);
      }
    }
  }

  return (
    <StepScreenContainer>
      <MedicalAssistant
        content={<p>Do any of the below items apply to you?</p>}
      />
      <Spacer height={40} />
      <Card>
        <CheckBoxCard
          options={femaleMedicalHistoryData}
          selected={selected}
          onSelect={handleOnSelect}
        />
      </Card>
    </StepScreenContainer>
  );
}

export default FemaleMedicalHistory;
