import {
  dermatology_main_steps,
  dermatology_sub_steps,
  step_status,
} from "helpers/enum";
import { setNextScreenToUpcoming } from "helpers/setDermatologyStepStatus";

export const dermaVisitDetailsReducer = {
  select_condition(state, action) {
    const { status, value, isValid } = action.payload;
    if (status) {
      state.subSteps[dermatology_sub_steps.select_condition].status = status;
      if (status === step_status.completed) {
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: dermatology_sub_steps.select_affected_part,
        });
      }
    }
    if (value) {
      state.subSteps[dermatology_sub_steps.select_condition].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[dermatology_sub_steps.select_condition].isValid = isValid;
    }
  },
  select_affected_part(state, action) {
    const { status, value, isValid } = action.payload;
    if (status) {
      state.subSteps[
        dermatology_sub_steps.select_affected_part
      ].status = status;
      if (status === step_status.completed) {
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: dermatology_sub_steps.condition_details,
        });
      }
    }
    if (value) {
      state.subSteps[dermatology_sub_steps.select_affected_part].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[
        dermatology_sub_steps.select_affected_part
      ].isValid = isValid;
    }
  },
  condition_details(state, action) {
    const { status, value, isValid } = action.payload;
    if (status) {
      state.subSteps[dermatology_sub_steps.condition_details].status = status;
      if (status === step_status.completed) {
        state.mainSteps[dermatology_main_steps.visit_details].status =
          step_status.completed;
        if (
          state.mainSteps[dermatology_main_steps.photos].status ===
          step_status.upcoming
        ) {
          state.mainSteps[dermatology_main_steps.photos].status =
            step_status.current;
        }
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: dermatology_sub_steps.upload_photos,
        });
      }
    }
    if (value) {
      state.subSteps[dermatology_sub_steps.condition_details].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[dermatology_sub_steps.condition_details].isValid = isValid;
    }
  },
};
