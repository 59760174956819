import { useContext } from "react";
import { ModalContext } from "providers/ModalContext";
import { useEffect } from "react";

function useModal() {
  const context = useContext(ModalContext);

  useEffect(() => {
    return () => {
      if (context?.showModal?.state && !context?.showModal?.data?.preventRender) {
        context?.setShowModal({
          state: false,
          children: null,
          data: null,
        });
      }
    };
  }, [context]);

  if (context === undefined) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
}

export default useModal;
