import TextWithBulletPoint from "../../TextWithBulletPoint";

export const getMaritalStatusView = (payload) => {
  if (payload?.marital_status?.length) {
    return (
      <div className="p-8 bg-alternativeWhite border border-borderColor mt-8 rounded-xl">
        <TextWithBulletPoint
          text={`Marital status : ${payload?.marital_status}`}
        />
      </div>
    );
  } else {
    return null;
  }
};
