import React, { useEffect, useRef } from "react";
import { Form, Formik } from "formik";
import useHealthProfile from "hooks/useHealthProfile";
import { useHistory, useParams } from "react-router-dom";
import { HealthProfileMedicalHistorySchema } from "helpers/validationSchema";
import CSSLoader from "components/cssLoader/CSSLoader";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Spacer from "components/Spacer/Spacer";
import HealthProfileQuestion from "../HealthProfileQuestion";
import HealthProfileRadio from "../HealthProfileRadio";
import HPMedicalHistoryType from "./HPMedicalHistoryType";
import useToastify from "hooks/useToastify";
import FormikErrorFocus from "formik-error-focus";
import SimpleHeader from "components/header/SimpleHeader";

const YESNO = ["Yes", "No"];

function HPMedicalHistory() {
  const formRef = useRef();

  const { showAlert } = useToastify();
  const history = useHistory();
  const { patient_id } = useParams();
  const {
    healthPayload,
    postHealthProfile,
    postingHealthProfile,
  } = useHealthProfile();

  useEffect(() => {
    if (healthPayload) {
      formRef?.current?.setValues({
        isAdvancedDirective:
          healthPayload?.health_profile?.additional_details
            ?.advanced_directive ?? "",
        isColonoscopy:
          healthPayload?.health_profile?.additional_details?.colonos_copy ?? "",
        colonoscopyDate:
          healthPayload?.health_profile?.additional_details
            ?.colonos_copy_date ?? "",
        current_medical_history: healthPayload?.health_profile
          ?.medical_history_current?.length
          ? healthPayload?.health_profile?.medical_history_current
          : [],
        past_medical_history: healthPayload?.health_profile
          ?.medical_history_past?.length
          ? healthPayload?.health_profile?.medical_history_past
          : [],
        never_medical_history: healthPayload?.health_profile
          ?.medical_history_never?.length
          ? healthPayload?.health_profile?.medical_history_never
          : [],
      });
    }
  }, [healthPayload]);

  function completed() {
    history.goBack();
  }

  function onSave(values) {
    if (
      values?.medical_history_current?.length ||
      values?.past_medical_history?.length ||
      values?.never_medical_history?.length
    ) {
      let payload = {
        additional_details: {
          advanced_directive: values?.isAdvancedDirective,
          colonos_copy: values?.isColonoscopy,
          colonos_copy_date: values?.colonoscopyDate,
        },
        medical_history_current: values?.current_medical_history,
        medical_history_past: values?.past_medical_history,
        medical_history_never: values?.never_medical_history,
      };
      postHealthProfile({ patientId: patient_id, payload, completed });
    } else {
      showAlert("Please add your medical history", "error");
    }
  }

  return (
    <div className="h-screen bg-veryLightBlue">
      <Formik
        innerRef={formRef}
        onSubmit={onSave}
        validationSchema={HealthProfileMedicalHistorySchema}
        initialValues={{
          isAdvancedDirective: null,
          isColonoscopy: "",
          colonoscopyDate: "",
          current_medical_history: [],
          past_medical_history: [],
          never_medical_history: [],
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className="h-full">
            <SimpleHeader
              showBackButton
              title="Medical History"
              rightActionView={
                <button
                  disabled={postingHealthProfile}
                  type={"submit"}
                  className="text-2xl font-bold text-orange"
                >
                  {postingHealthProfile ? (
                    <CSSLoader className="w-8 h-8" color="orange" />
                  ) : (
                    "Save"
                  )}
                </button>
              }
            />
            <div className="px-12 py-6 overflow-y-auto bg-white h-eliminateHeaderWithBottomTab md:h-eliminateHeader ">
              <MedicalAssistant
                content={<p>Please tell us about your medical history.</p>}
              />
              <Spacer height={26} />
              <HealthProfileQuestion
                text={"Please select from below:"}
                className="mb-12"
              />
              <HPMedicalHistoryType
                setFieldValue={setFieldValue}
                current_medical_history={values?.current_medical_history}
                past_medical_history={values?.past_medical_history}
                never_medical_history={values?.never_medical_history}
                isColonoscopy={values?.isColonoscopy}
                colonoscopyDate={values?.colonoscopyDate}
              />

              {/* Advanced Directive */}
              <div className="pt-6">
                <HealthProfileQuestion text={"Have an Advanced Directive?"} />
                <div className="flex items-center mt-8">
                  {YESNO?.map((option, optionIndex) => {
                    return (
                      <div className="flex flex-1" key={optionIndex}>
                        <HealthProfileRadio
                          text={option}
                          onClick={() =>
                            setFieldValue("isAdvancedDirective", option)
                          }
                          selected={
                            values?.isAdvancedDirective === option
                              ? true
                              : false
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <FormikErrorFocus align={"bottom"} ease={"linear"} duration={500} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default HPMedicalHistory;
