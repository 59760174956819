import React, { useEffect, useCallback, useState } from "react";
import VisitStepAccordion from "components/visitStepAccordion/VisitStepAccordion";
import Header from "components/header/Header";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import { dermaStepsData } from "./dermaStepsData";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import usePatients from "hooks/visit/usePatients";
import useIncompleteData from "hooks/visit/useIncompleteData";
import LoaderComponent from "components/loader/LoaderComponent";
import usePopulateDermatologyData from "hooks/visit/usePopulateDermatologyData";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import useModal from "hooks/useModal";
import Alert from "components/alerts/Alert";
import { useApiMutation } from "hooks/useApiMutation";
import { discardVisit } from "api/actions/VisitActions";
import { dermatology_main_steps, speciality, step_status } from "helpers/enum";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import useDataFromUserDetails from "hooks/useDataFromUserDetails";
import { setUserDetails } from "reducers/currentUserReducer";
import { useCurrentUser } from "hooks/useCurrentUser";
import BasicDetailsForm from "../common/BasicDetailsForm";
import { trackDropOffRate, trackPageView } from "helpers/analytics";
import NewProviderWithPercentage from "components/providerWithPercentage/NewProviderWithPercentage";

function DermaVisitSteps() {
  const dispatch = useDispatch();
  const { setShowModal } = useModal();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { mainSteps, subSteps } = useDermatologyVisitFlow();

  const {
    getPatients,
    patientsList,
    fetchingPatients,
    getCurrentPatient,
  } = usePatients();
  const { userDetails } = useCurrentUser();
  const {
    dermaDoctorDetails,
    isInactiveDermaProviderLicense,
    isInactiveDermaProvider,
  } = useDataFromUserDetails();
  const {
    fetchIncompleteData,
    fetchingIncompleteData,
    incompleteData,
  } = useIncompleteData();
  const { mutate: callDiscardVisit, loading: discardingVisit } = useApiMutation(
    discardVisit
  );

  const currentPatient = getCurrentPatient(
    incompleteData?.patient_id,
    patientsList
  );

  const [showBasicDetails, setShowBasicDetails] = useState(false);

  useEffect(() => {
    if (currentPatient) {
      setShowBasicDetails(!(currentPatient?.height && currentPatient?.weight));
    }
  }, [currentPatient]);

  useEffect(() => {
    if (userDetails?.id) {
      trackPageView("dermatology-visit", userDetails.id);
      trackDropOffRate("dermatology-visit", userDetails.id);
    }
  }, [userDetails]);

  function openBasicDetailsModal() {
    setShowModal({
      state: true,
      children: (_, onClose) => (
        <BasicDetailsForm
          onClose={() => {
            onClose();
            window.location.reload();
          }}
          currentPatient={currentPatient}
          patientType={
            currentPatient?.relationship_with_account === "pappa"
              ? "young"
              : "old"
          }
          currentSpeciality={speciality.dermatology}
        />
      ),
      data: null,
    });
  }

  const isAllStepsCompleted =
    Object.keys(dermatology_main_steps).filter(
      (el) => mainSteps?.[el]?.status !== step_status.completed
    )?.length === 0;

  const confirmDeleteVisit = useCallback(async () => {
    try {
      const response = await callDiscardVisit(incompleteData?.id);
      if (response?.error === false) {
        dispatch(dermaVisitFlowReducers.reset());
        dispatch(
          setUserDetails({
            userDetails: { ...userDetails, incomplete_visit_id: null },
          })
        );
        history.goBack();
      }
    } catch (error) {}
  }, [callDiscardVisit, dispatch, history, incompleteData?.id, userDetails]);

  useEffect(() => {
    getPatients();
    fetchIncompleteData(speciality.dermatology);
  }, [getPatients, fetchIncompleteData]);

  useEffect(() => {
    if (
      incompleteData &&
      !fetchingIncompleteData &&
      !discardingVisit &&
      (isInactiveDermaProviderLicense || isInactiveDermaProvider)
    ) {
      setShowModal({
        state: true,
        children: (_, onClose) => (
          <Alert
            onPrimaryButtonClick={() => {
              onClose();
              history.push("/visit/medical-intake/select-provider");
            }}
            onSecondaryButtonClick={() => {
              onClose();
              history.replace({
                pathname: "/home",
              });
            }}
            secondaryButtonText="Cancel"
            primaryButtonText="Switch Dermatologist"
            heading="Couldn't proceed!"
            message="Your dermatology visit intake is already in progress, to continue submitting the visit, please switch to an active dermatology provider."
          />
        ),
      });
    }
  }, [
    incompleteData,
    discardingVisit,
    fetchingIncompleteData,
    confirmDeleteVisit,
    history,
    isInactiveDermaProvider,
    isInactiveDermaProviderLicense,
    setShowModal,
  ]);

  usePopulateDermatologyData({
    incompleteData,
    patientsList,
  });

  if (
    fetchingIncompleteData ||
    fetchingPatients ||
    discardingVisit ||
    !incompleteData ||
    !patientsList
  ) {
    return (
      <LoaderComponent
        showLoader={{
          state: true,
        }}
      />
    );
  }

  function deleteVisit() {
    setShowModal({
      state: true,
      children: (_, onClose) => (
        <Alert
          heading="Delete visit"
          message={"Are you sure you want to delete your visit?"}
          primaryButtonText="Confirm"
          onSecondaryButtonClick={onClose}
          onPrimaryButtonClick={() => {
            onClose();
            confirmDeleteVisit();
          }}
        />
      ),
      data: null,
    });
  }

  function getMAText() {
    if (isAllStepsCompleted) {
      return "Thank you for completing the medical intake. Feel free to review/change anything below before continuing";
    } else {
      return "Please provide us with the following details. Make sure you tell us about the issue as much as possible.";
    }
  }

  return (
    <div className="h-screen bg-veryLightBlue">
      <Header
        title={
          isAllStepsCompleted ? `New Visit #${incompleteData?.id}` : "New Visit"
        }
        rightActionView={
          incompleteData && Object.keys(incompleteData)?.length ? (
            <p
              onClick={deleteVisit}
              className="px-6 py-4 text-xl font-medium rounded-md cursor-pointer text-red bg-red bg-opacity-10"
            >
              Delete
            </p>
          ) : null
        }
      />
      <div
        className={`overflow-y-auto ${
          isAllStepsCompleted
            ? "h-eliminateHeaderWithFooter"
            : "h-eliminateHeader"
        } wrapper bg-white`}
      >
        <div className="py-8">
          <MedicalAssistant xOffset={30} content={<p>{getMAText()}</p>} />
        </div>
        <NewProviderWithPercentage
          className={"border-b border-t"}
          doctorDetails={dermaDoctorDetails}
          percentage={incompleteData?.meta?.progress_percentage ?? 0}
        />
        <div className="min-h-full">
          <VisitStepAccordion
            data={dermaStepsData(mainSteps, subSteps)}
            onClickSubStep={(key) => {
              dispatch(
                dermaVisitFlowReducers.currentScreen({
                  value: key,
                })
              );
              history.push(`${url}/new-visit`);
            }}
          />
        </div>
      </div>
      {isAllStepsCompleted ? (
        <BottomFixedButton
          label="Next"
          onClick={() => {
            if (showBasicDetails) {
              openBasicDetailsModal();
            } else {
              history?.push("/visit/dermatology/terms_and_conditions");
            }
          }}
        />
      ) : null}
    </div>
  );
}

export default DermaVisitSteps;
