import React, { useEffect, useCallback, useState } from "react";
import { myVisitsAction } from "api/actions/VisitActions";
import { useApiQuery } from "hooks/useApiQuery";
import Placeholder from "components/placeholders/Placeholder";
import Images from "assets/images";
import LoaderComponent from "components/loader/LoaderComponent";
import { useCurrentUser } from "hooks/useCurrentUser";
import { setUserDetails } from "reducers/currentUserReducer";
import { useDispatch } from "react-redux";
import useModal from "hooks/useModal";
import constants from "helpers/constants";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { currentUserAction } from "api/actions/UserActions";
import NotificationBar from "../waitingRoom/components/NotificationBar";
import VisitTabs from "./VisitTabs";
import { trackEvent } from "helpers/analytics";

function MyVisits() {
  const dispatch = useDispatch();
  const { setShowModal } = useModal();
  const [selectedTab, setSelectedTab] = useState(0);
  const { userDetails } = useCurrentUser();

  const { payload: userDetailsFromApi, loading: isFetchingUser } = useApiQuery(
    currentUserAction
  );

  const {
    status: visitListStatusCode,
    payload: visits,
    loading: fetchingVisits,
  } = useApiQuery(myVisitsAction, true);

  useEffect(() => {
    if (userDetailsFromApi) {
      dispatch(setUserDetails({ userDetails: userDetailsFromApi }));
    }
  }, [dispatch, userDetailsFromApi]);

  useEffect(() => {
    const storedTabIndex = sessionStorage.getItem("selectedTabIndex");
    if (storedTabIndex !== null) {
      setSelectedTab(parseInt(storedTabIndex));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("selectedTabIndex", selectedTab.toString());
  }, [selectedTab]);

  const setTab = (e) => {
    trackEvent(`my_visits_tab_${e}`);
    setSelectedTab(e);
  };

  // clearing localStorage for price revesion
  useEffect(() => {
    localStorage.removeItem("last_revision");
    localStorage.removeItem("last_revision_data");
  }, []);

  const ServerMaintainance = useCallback(() => {
    return (
      <div className="bg-white">
        <div className="h-screen bg-white">
          <Placeholder
            buttonLoading={fetchingVisits}
            onPressButton={() => window.location.reload()}
            buttonText="Refresh"
            placeholderImageClassName={"w-4/12"}
            placeholderImage={Images.teaCup}
            heading="Something’s not right!"
            paragraph="We are sorry for the inconvinence caused. We are working on the issue
Please try after sometime or check your internet connection."
          />
        </div>
      </div>
    );
  }, [fetchingVisits]);

  useEffect(() => {
    if (
      visitListStatusCode &&
      constants.SERVER_ERROR_CODES?.includes(visitListStatusCode)
    ) {
      setShowModal({
        state: true,
        children: ServerMaintainance,
      });
    }
  }, [visitListStatusCode, setShowModal, ServerMaintainance]);

  if (fetchingVisits || isFetchingUser) {
    return (
      <LoaderComponent
        showLoader={{ state: true, message: "Fetching your visits" }}
      />
    );
  }
  const ResponsiveView = () => {
    return (
      <div className="h-full overflow-y-auto bg-backgroundWhite">
        <NotificationBar
          leftActionView={
            <div>
              <img
                src={Images.logoHorizontalNaming}
                className="object-contain w-56 my-0 md:hidden"
                alt="logo"
              />
              <h2 className="hidden text-4xl font-bold text-black md:block">
                Patient Portal
              </h2>
            </div>
          }
        />
        <div className="flex flex-col w-full p-4 mb-40 space-y-12 md:p-10 lg:max-w-homeScreen">
          <VisitTabs
            cases={visits}
            userDetails={userDetails}
            selectedTab={selectedTab}
            setSelectedTab={setTab}
          />
        </div>
      </div>
    );
  };
  return (
    <div className="w-full h-full min-h-full xl:h-auto bg-backgroundWhite">
      <ResponsiveView />
    </div>
  );
}

export default MyVisits;
