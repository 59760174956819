import * as Yup from "yup";
import moment from "moment";
import emojiRegex from "emoji-regex";

export const excludeImageContainer = ["BrightPay", "NAPHCARE INC"];

//Regexs

const zipCodeRegex =
  /(?!00[02-5]|099|213|269|34[358]|353|419|42[89]|51[789]|529|53[36]|552|5[67]8|5[78]9|621|6[348]2|6[46]3|659|69[4-9]|7[034]2|709|715|771|81[789]|8[3469]9|8[4568]8|8[6-9]6|8[68]7|9[02]9|987)\d{5}/;
const nameRegex = /^[a-zA-Z\s.]+$/;
const emojiRegexValue = emojiRegex();

//methods

const testEmoji = (data) => {
  if (data?.match(emojiRegexValue)) {
    return false;
  } else {
    return true;
  }
};

const isAdult = (momentDate) => {
  const years = moment().diff(momentDate, "years", false);
  const days = moment().diff(momentDate.add(years, "years"), "days", false);
  if (years > 18) {
    return true;
  } else if (years === 18 && days > -1) {
    return true;
  } else {
    return false;
  }
};

const isBefore = (momentDate) => {
  return momentDate.isBefore(moment());
};

//Common Schemas

const emailValidation = Yup.string()
  .test("", "Emojis are not allowed", testEmoji)
  .email("Please enter a valid email address")
  .required("Email is required");

const passwordValidation = (fieldName) =>
  Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .min(8, `${fieldName} must be atleast 8 characters`)
    .max(20, `${fieldName} cannot exceed 20 characters`)
    .required(`${fieldName} is required`);

const mobileNumberValidation = Yup.string()
  .test("", "Emojis are not allowed", testEmoji)
  .length(11, "Enter a valid phone number")
  .required("Phone Number is required");

const otpValidation = Yup.string()
  .test("", "Emojis are not allowed", testEmoji)
  .length(4, "Enter a valid OTP")
  .required("OTP is required");

const zipcodeSchema = Yup.string()
  .test("", "Emojis are not allowed", testEmoji)
  .matches(zipCodeRegex, "Enter valid Zip Code")
  .max(5, "Enter valid Zip Code")
  .required("Zip Code is required");

//Schemas

const ForgotPasswordSchema = Yup.object().shape({
  email: emailValidation,
});

const ResetPasswordSchema = Yup.object().shape({
  new_password: passwordValidation("Password"),
  confirm_password: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .test(
      "passwords-match",
      "Password and confirm password do not match",
      function (value) {
        return this.parent.new_password === value;
      }
    )
    .required("Password confirmation is required"),
});

const PhoneInputSchema = Yup.object().shape({
  mobile_number: mobileNumberValidation,
});
const OTPInputSchema = Yup.object().shape({
  otp_input: otpValidation,
});
const LoginSchema = Yup.object().shape({
  email: emailValidation,
  password: passwordValidation("Password"),
});

const SignupSchema = Yup.object().shape({
  first_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .trim()
    .matches(nameRegex, "Enter a valid first name")
    .required("First Name is required"),
  last_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .trim()
    .matches(nameRegex, "Enter a valid last name")
    .required("Last Name is required"),
  email: emailValidation,
  mobile_number: mobileNumberValidation,
  zip_code: zipcodeSchema,
  type: Yup.string().test("", "Emojis are not allowed", testEmoji),
  provider: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .when("type", {
      is: (type) => type === "referral",
      then: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .required("Provider is required."),
      otherwise: Yup.string().test("", "Emojis are not allowed", testEmoji),
    }),
  password: passwordValidation("Password"),
  confirm_password: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .test(
      "passwords-match",
      "Password and confirm password do not match",
      function (value) {
        return this.parent.password === value;
      }
    )
    .required("Password confirmation is required"),
});

const SignupFirstStep = Yup.object().shape({
  email: emailValidation,
  password: passwordValidation("Password"),
  // confirm_password: Yup.string()
  //   .test("", "Emojis are not allowed", testEmoji)
  //   .test(
  //     "passwords-match",
  //     "Password and confirm password do not match",
  //     function (value) {
  //       return this.parent.password === value;
  //     }
  //   )
  //   .required("Password confirmation is required"),
});

const SignupSecondStep = Yup.object().shape({
  first_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .trim()
    .matches(nameRegex, "Enter a valid first name")
    .required("First Name is required"),
  last_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .trim()
    .matches(nameRegex, "Enter a valid last name")
    .required("Last Name is required"),
  mobile_number: mobileNumberValidation,
  email: emailValidation,
  zip_code: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .max(5, "Enter valid Zip Code")
    .required("Zip Code is required"),
  type: Yup.string().test("", "Emojis are not allowed", testEmoji),
  provider: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .when("type", {
      is: (type) => type === "referral",
      then: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .required("Provider is required."),
      otherwise: Yup.string().test("", "Emojis are not allowed", testEmoji),
    }),
});

const addressSchema = Yup.object().shape({
  address_1: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Address line 1 is required"),
  address_2: Yup.string().test("", "Emojis are not allowed", testEmoji),
  city: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("City is required"),
  zipcode: zipcodeSchema,
});

const InsuranceDetailsSchema = Yup.object().shape({
  insurance_provider: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Insurance carrier is required"),
  other_insurance_company: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .when("insurance_provider", {
      is: (insurance_provider) =>
        insurance_provider === "Other, I do not see my plan listed",
      then: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .required("Enter insurance provider name"),
      otherwise: Yup.string().test("", "Emojis are not allowed", testEmoji),
    }),
  member_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .trim()
    .matches(nameRegex, "Enter a valid member name")
    .required("Member name is required"),
  relationship: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required(),
  member_id: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .trim()
    .required("Member Id is required"),
  member_dob: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Member Dob is required")
    .test("member_dob", "Invalid date.", (value) => {
      return isBefore(moment(value, "YYYY-MM-DD"));
    }),
  insurance_card_images: Yup.array().when("insurance_provider", {
    is: (insurance_provider) =>
      !excludeImageContainer.includes(insurance_provider),
    then: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string().test("", "Emojis are not allowed", testEmoji),
          uri: Yup.string()
            .test("", "Emojis are not allowed", testEmoji)
            .required("Front and Back image is required"),
        })
      )
      .min(2, "Front and Back image is required")
      .required(),
    otherwise: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().test("", "Emojis are not allowed", testEmoji),
        uri: Yup.string().test("", "Emojis are not allowed", testEmoji),
      })
    ),
  }),
});

const ProfileSchema = Yup.object().shape({
  profile_pic: Yup.object().shape({
    uri: Yup.string().test("", "Emojis are not allowed", testEmoji),
    fil: Yup.string().test("", "Emojis are not allowed", testEmoji),
    data: Yup.string().test("", "Emojis are not allowed", testEmoji),
  }),
  email: emailValidation,
  phone: mobileNumberValidation,
  dob: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Date of Birth is required")
    .test("dob", "Invalid date.", (value) => {
      return isBefore(moment(value, "YYYY-MM-DD"));
    })
    .nullable()
    .test("dob", "Age must be greater than or equal to 18.", (value) => {
      return isAdult(moment(value, "YYYY-MM-DD"));
    }),
  address_1: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Address1 is required"),
  address_2: Yup.string().test("", "Emojis are not allowed", testEmoji),
  city: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("City is required"),
  first_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .matches(nameRegex, "Enter a valid first name")
    .required("First Name is required"),
  last_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .matches(nameRegex, "Enter a valid last name")
    .required("Last Name is required"),
  zipcode: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .length(5)
    .required("Zipcode is required"),
  gender: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .oneOf(["Male", "Female"])
    .required("Gender at Birth is required"),
  heightFeet: Yup.number()
    .min(1, "Enter a valid height")
    .max(9, "Enter a valid height")
    .required("Height is required"),
  heightInches: Yup.number()
    .min(0, "Enter a valid height")
    .max(11, "Enter a valid height")
    .required("Height is required"),
  weight: Yup.number()
    .min(1, "Enter a valid weight.")
    .max(999, "Enter a valid weight.")
    .required("Weight is required."),
});

const PCBasicDetails = Yup.object().shape({
  dob: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Date of Birth is required")
    .test("dob", "Invalid date.", (value) => {
      return isBefore(moment(value, "YYYY-MM-DD"));
    })
    .nullable()
    .test("dob", "Age must be greater than or equal to 18.", (value) => {
      return isAdult(moment(value, "YYYY-MM-DD"));
    }),
  address_1: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Address1 is required"),
  address_2: Yup.string().test("", "Emojis are not allowed", testEmoji),
  city: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("City is required"),
  first_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .matches(nameRegex, "Enter a valid first name")
    .required("First Name is required"),
  last_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .matches(nameRegex, "Enter a valid last name")
    .required("Last Name is required"),
  zipcode: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .length(5)
    .required("Zipcode is required"),
  gender: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .oneOf(["Male", "Female"])
    .required("Gender at Birth is required"),
  heightFeet: Yup.number()
    .min(1, "Enter a valid height")
    .max(9, "Enter a valid height")
    .required("Height is required"),
  heightInches: Yup.number()
    .min(0, "Enter a valid height")
    .max(11, "Enter a valid height")
    .required("Height is required"),
  weight: Yup.number()
    .min(1, "Enter a valid weight.")
    .max(999, "Enter a valid weight.")
    .required("Weight is required."),
});

const ChildProfileSchema = Yup.object().shape({
  dob: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Date of Birth is required")
    .test("dob", "Invalid date.", (value) => {
      return isBefore(moment(value, "YYYY-MM-DD"));
    })
    .test("dob", "Age must be less than 18.", (value) => {
      return isAdult(moment(value, "YYYY-MM-DD")) === true ? false : true;
    }),
  address_1: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Address1 is required"),
  address_2: Yup.string().test("", "Emojis are not allowed", testEmoji),
  city: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("City is required"),
  first_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .matches(nameRegex, "Enter a valid first name")
    .required("First Name is required"),
  last_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .matches(nameRegex, "Enter a valid last name")
    .required("Last Name is required"),
  zipcode: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .length(5)
    .required("Zipcode is required"),
  gender: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .oneOf(["Male", "Female"])
    .required("Gender at Birth is required"),
  heightFeet: Yup.number()
    .min(1, "Enter a valid height")
    .max(9, "Enter a valid height")
    .required("Height is required"),
  heightInches: Yup.number()
    .min(0, "Enter a valid height")
    .max(11, "Enter a valid height")
    .required("Height is required"),
  weight: Yup.number()
    .min(1, "Enter a valid weight.")
    .max(999, "Enter a valid weight.")
    .required("Weight is required."),
});

const AddChildSchemaDerm = Yup.object().shape({
  first_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .trim()
    .matches(nameRegex, "Enter a valid first name")
    .required("First name is required"),
  last_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .trim()
    .matches(nameRegex, "Enter a valid last name")
    .required("Last name is required"),
});
const AddChildSchema = Yup.object().shape({
  first_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .trim()
    .matches(nameRegex, "Enter a valid first name")
    .required("First name is required"),
  last_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .trim()
    .matches(nameRegex, "Enter a valid first name")
    .required("Last name is required"),
  dob: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Date of Birth is required")
    .test("dob", "Invalid date.", (value) => {
      return isBefore(moment(value, "YYYY-MM-DD"));
    })
    .test("dob", "Age must be less than 18.", (value) => {
      return isAdult(moment(value, "YYYY-MM-DD")) === true ? false : true;
    }),
  gender: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Gender at Birth is required"),
  heightFeet: Yup.number()
    .min(1, "Enter a valid height")
    .max(9, "Enter a valid height")
    .required("Height is required"),
  heightInches: Yup.number()
    .min(0, "Enter a valid height")
    .max(11, "Enter a valid height")
    .required("Height is required"),
  weight: Yup.number()
    .min(1, "Enter a valid weight.")
    .max(999, "Enter a valid weight.")
    .required("Weight is required."),
});

const MapZipcodeValidation = Yup.object().shape({
  zipcode: zipcodeSchema,
});

const ChangePasswordSchema = Yup.object().shape({
  old_password: Yup.string(),
  new_password: passwordValidation("New password"),
  confirm_new_password: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .oneOf([Yup.ref("new_password"), null], "Password does not match")
    .required("Confirm Password is required"),
});
const AddSomeoneElseSchemaDerm = Yup.object().shape({
  first_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .matches(nameRegex, "Enter a valid first name")
    .required("First name is required"),
  last_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .matches(nameRegex, "Enter a valid last name")
    .required("Last name is required"),
  zip_code: zipcodeSchema,
});
const AddSomeoneElseSchema = Yup.object().shape({
  first_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .matches(nameRegex, "Enter a valid first name")
    .required("First name is required"),
  last_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .matches(nameRegex, "Enter a valid first name")
    .required("Last name is required"),
  street_address_1: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Address line 1 is required"),
  street_address_2: Yup.string().test("", "Emojis are not allowed", testEmoji),
  city: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("City is required"),
  zip_code: zipcodeSchema,
  dob: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Date of Birth is required")
    .test("dob", "Invalid date.", (value) => {
      return isBefore(moment(value, "YYYY-MM-DD"));
    })
    .test("dob", "Age must be greater than or equal to 18.", (value) => {
      return isAdult(moment(value, "YYYY-MM-DD"));
    }),
  gender: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Gender at Birth is required"),
  heightFeet: Yup.number()
    .min(1, "Enter a valid height")
    .max(9, "Enter a valid height")
    .required("Height is required"),
  heightInches: Yup.number()
    .min(0, "Enter a valid height")
    .max(11, "Enter a valid height")
    .required("Height is required"),
  weight: Yup.number()
    .min(1, "Enter a valid weight.")
    .max(999, "Enter a valid weight.")
    .required("Weight is required."),
});

// CR~ Confirm residency
const updateBasicInfoCRSchema = Yup.object().shape({
  street_address_1: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Address line 1 is required"),
  street_address_2: Yup.string().test("", "Emojis are not allowed", testEmoji),
  city: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("City is required"),
  zip_code: zipcodeSchema,
  type: Yup.string().test("", "Emojis are not allowed", testEmoji),
});
//PB ~ Patient Bio
const updateBasicInfoPBSchema = Yup.object().shape({
  dob: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Date of Birth is required")
    .test("dob", "Invalid date.", (value) => {
      return isBefore(moment(value, "YYYY-MM-DD"));
    })
    .when("type", {
      is: (type) => type === "young",
      then: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .test("dob", "Age must be less than 18.", (value) => {
          return isAdult(moment(value, "YYYY-MM-DD")) ? false : true;
        }),
      otherwise: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .test("dob", "Age must be greater than or equal to 18.", (value) => {
          return isAdult(moment(value, "YYYY-MM-DD"));
        }),
    }),
  gender: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Gender at Birth is required"),
  heightFeet: Yup.number()
    .min(1, "Enter a valid height")
    .max(9, "Enter a valid height")
    .required("Height is required"),
  heightInches: Yup.number()
    .min(0, "Enter a valid height")
    .max(11, "Enter a valid height")
    .required("Height is required"),
  weight: Yup.number()
    .min(1, "Enter a valid weight.")
    .max(999, "Enter a valid weight.")
    .required("Weight is required."),
});
const updateBasicInfoSchema = Yup.object().shape({
  street_address_1: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Address line 1 is required"),
  street_address_2: Yup.string().test("", "Emojis are not allowed", testEmoji),
  city: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("City is required"),
  zip_code: zipcodeSchema,
  type: Yup.string().test("", "Emojis are not allowed", testEmoji),
  dob: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Date of Birth is required")
    .test("dob", "Invalid date.", (value) => {
      return isBefore(moment(value, "YYYY-MM-DD"));
    })
    .when("type", {
      is: (type) => type === "young",
      then: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .test("dob", "Age must be less than 18.", (value) => {
          return isAdult(moment(value, "YYYY-MM-DD")) ? false : true;
        }),
      otherwise: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .test("dob", "Age must be greater than or equal to 18.", (value) => {
          return isAdult(moment(value, "YYYY-MM-DD"));
        }),
    }),
  gender: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Gender at Birth is required"),
  heightFeet: Yup.number()
    .min(1, "Enter a valid height")
    .max(9, "Enter a valid height")
    .required("Height is required"),
  heightInches: Yup.number()
    .min(0, "Enter a valid height")
    .max(11, "Enter a valid height")
    .required("Height is required"),
  weight: Yup.number()
    .min(1, "Enter a valid weight.")
    .max(999, "Enter a valid weight.")
    .required("Weight is required."),
});

const CosmeticBasicDetailsSchema = Yup.object().shape({
  city: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("City is required"),
  dob: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Date of Birth is required")
    .test("dob", "Invalid date.", (value) => {
      return isBefore(moment(value, "YYYY-MM-DD"));
    })
    .test("dob", "Age must be greater than or equal to 18.", (value) => {
      return isAdult(moment(value, "YYYY-MM-DD"));
    }),
  gender: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Gender at Birth is required"),
});

const TreatmentPlanSchema = Yup.object().shape({
  pcp_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .matches(nameRegex, "Enter a valid pcp name")
    .required("PCP Name is required"),
  pcp_fax: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .length(11, "Enter a valid fax")
    .required("PCP Fax is required"),
});

const OtherConditionSchema = Yup.object().shape({
  other_condition: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Please enter the reason for your visit"),
});

const SymptomStatusSchema = Yup.object().shape({
  worse: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Please tell us what makes it worse."),
  better: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Please tell us what makes it better."),
});

const PriorTreatmentsSchema = Yup.object().shape({
  prior_treatments: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("This is a required field"),
});

const SkinCareProductsSchema = Yup.object().shape({
  skin_care_products: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("This is a required field"),
});

const OtherFeedbacksSchema = Yup.object().shape({
  other_feedbacks: Yup.string().test("", "Emojis are not allowed", testEmoji),
});

const AdditionalDetailsSchema = Yup.object().shape({
  additional_details: Yup.string().test(
    "",
    "Emojis are not allowed",
    testEmoji
  ),
});

const OtherSymptomsSchema = Yup.object().shape({
  other_symptom: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Symptoms are required"),
});

const conditionDetailsSchema = Yup.object().shape({
  status: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Status is required")
    .nullable(),
  count: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Days count is required")
    .nullable(),
  day: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Count type is required")
    .nullable(),
  severity: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Severity is required")
    .nullable(),
});

const surgeriesSchema = Yup.object().shape({
  text: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("This is a required field"),
  date: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Date is required"),
});

const ContactUsSchema = Yup.object().shape({
  subject: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Subject is required"),
  message: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Message is required"),
});

const UploadPhotosSchema = Yup.object().shape({
  close_up_photo_1: Yup.object().shape({
    uri: Yup.string()
      .test("", "Emojis are not allowed", testEmoji)
      .required("Please add a close up photo."),
  }),
  far_away_photo_1: Yup.object().shape({
    uri: Yup.string()
      .test("", "Emojis are not allowed", testEmoji)
      .required("Please add a far away photo."),
  }),
  close_up_photo_2: Yup.object().shape({
    uri: Yup.string().test("", "Emojis are not allowed", testEmoji),
  }),
  far_away_photo_2: Yup.object().shape({
    uri: Yup.string().test("", "Emojis are not allowed", testEmoji),
  }),
  close_up_photo_3: Yup.object().shape({
    uri: Yup.string().test("", "Emojis are not allowed", testEmoji),
  }),
  far_away_photo_3: Yup.object().shape({
    uri: Yup.string().test("", "Emojis are not allowed", testEmoji),
  }),
});

const HealthProfileMedicalHistorySchema = Yup.object().shape({
  isAdvancedDirective: Yup.string().test(
    "",
    "Emojis are not allowed",
    testEmoji
  ),
  isColonoscopy: Yup.string().test("", "Emojis are not allowed", testEmoji),
  colonoscopyDate: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .when("isColonoscopy", {
      is: (isColonoscopy) => isColonoscopy === "Yes",
      then: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .required("Colonoscopy Date is required.")
        .test("colonoscopyDate", "Invalid date.", (value) => {
          return isBefore(moment(value, "YYYY-MM-DD"));
        }),
      otherwise: Yup.string().test("", "Emojis are not allowed", testEmoji),
    }),
});

const HealthProfileAllergiesSchema = Yup.object().shape({
  isAllergic: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("This is a required field."),
  drug_or_environmental: Yup.mixed()
    .when(
      [
        "isAllergic",
        "drug_medication_allergies",
        "environmental_food_allergies",
      ],
      {
        is: (
          isAllergic,
          drug_medication_allergies,
          environmental_food_allergies
        ) => {
          if (
            isAllergic === "No" ||
            (isAllergic === "Yes" &&
              drug_medication_allergies?.some(
                (el) => el?.drug_name?.length && el?.reaction?.length
              )) ||
            (isAllergic === "Yes" &&
              environmental_food_allergies?.some(
                (el) => el?.allergic_to?.length && el?.reaction?.length
              ))
          ) {
            return true;
          } else {
            return false;
          }
        },
        then: Yup.string().test("", "Emojis are not allowed", testEmoji),
        otherwise: Yup.string()
          .test("", "Emojis are not allowed", testEmoji)
          .required("Please add any allergies you may have."),
      }
    )
    .nullable(),
  drug_medication_allergies: Yup.mixed().when("isAllergic", {
    is: (isAllergic) => {
      return isAllergic === "Yes";
    },
    then: Yup.array().of(
      Yup.object().shape({
        drug_name: Yup.string()
          .test("", "Emojis are not allowed", testEmoji)
          .required("Drug name is required"),
        reaction: Yup.string()
          .test("", "Emojis are not allowed", testEmoji)
          .required("Reaction is required"),
      })
    ),
    otherwise: Yup.array(),
  }),
  environmental_food_allergies: Yup.mixed().when("isAllergic", {
    is: (isAllergic) => {
      return isAllergic === "yes";
    },
    then: Yup.array().of(
      Yup.object().shape({
        allergic_to: Yup.string()
          .test("", "Emojis are not allowed", testEmoji)
          .required("Allergic to is required"),
        reaction: Yup.string()
          .test("", "Emojis are not allowed", testEmoji)
          .required("Reaction is required"),
      })
    ),
    otherwise: Yup.array(),
  }),
});

const HealthProfileFamilyHistorySchema = Yup.object().shape({
  is_adopted: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("This is a required field"),
  cancer_type: Yup.string().test("", "Emojis are not allowed", testEmoji),
  other_disease: Yup.string().test("", "Emojis are not allowed", testEmoji),
  data: Yup.array().of(
    Yup.object().shape({
      disease: Yup.string().test("", "Emojis are not allowed", testEmoji),
      members_affected: Yup.array().of(
        Yup.string().test("", "Emojis are not allowed", testEmoji)
      ),
    })
  ),
});

const HealthProfileMedicationsSchema = Yup.object().shape({
  on_medication: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("This is a required field"),
  medications: Yup.mixed().when("on_medication", {
    is: (on_medication) => {
      return on_medication === "Yes";
    },

    then: Yup.array()
      .of(
        Yup.object().shape({
          drug_name: Yup.string()
            .test("", "Emojis are not allowed", testEmoji)
            .required("Drug name is required"),
          dose: Yup.string()
            .test("", "Emojis are not allowed", testEmoji)
            .required("Dose is required"),
          frequency: Yup.string()
            .test("", "Emojis are not allowed", testEmoji)
            .required("Frequency is required"),
          per: Yup.string()
            .test("", "Emojis are not allowed", testEmoji)
            .required("Per is required"),
          form: Yup.string()
            .test("", "Emojis are not allowed", testEmoji)
            .required("Form is required"),
          other_form: Yup.string()
            .test("", "Emojis are not allowed", testEmoji)
            .when("form", {
              is: (form) => form === "Other",
              then: Yup.string()
                .test("", "Emojis are not allowed", testEmoji)
                .required("This is a required field"),
              otherwise: Yup.string().test(
                "",
                "Emojis are not allowed",
                testEmoji
              ),
            }),
        })
      )
      .min(1, "Please add your medication.")
      .required("Please add your medication."),

    otherwise: Yup.array().of(
      Yup.object().shape({
        drug_name: Yup.string().test("", "Emojis are not allowed", testEmoji),
        dose: Yup.string().test("", "Emojis are not allowed", testEmoji),
        frequency: Yup.string().test("", "Emojis are not allowed", testEmoji),
        per: Yup.string().test("", "Emojis are not allowed", testEmoji),
        form: Yup.string().test("", "Emojis are not allowed", testEmoji),
        other_form: Yup.string().test("", "Emojis are not allowed", testEmoji),
      })
    ),
  }),
});

const SurgeriesHospitalisationSchema = Yup.object().shape({
  surgery_or_reason_for_hopitalisation: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("This is a required field"),
  date: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("This is a required field")
    .test("date", "Invalid date.", (value) => {
      return isBefore(moment(value, "YYYY-MM-DD"));
    }),
});

const HealthProfileSurgeriesHospitalisationSchema = Yup.object().shape({
  hospitalized_before: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("This is a required field."),
  surgeriesHospitalisations: Yup.array().when("hospitalized_before", {
    is: (hospitalized_before) =>
      hospitalized_before !== undefined && hospitalized_before === "Yes",
    then: Yup.array()
      .of(SurgeriesHospitalisationSchema)
      .min("1", "Add your surgeries or hospitalisations"),
    otherwise: Yup.array(),
  }),
});

const HealthProfileFemaleHistorySchema = Yup.object().shape({
  first_day_of_lmp: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .test("dob", "Invalid date.", (value) => {
      return !value?.length || isBefore(moment(value, "YYYY-MM-DD"));
    }),
  menopause: Yup.string().test("", "Emojis are not allowed", testEmoji),
  menopause_age: Yup.number()
    .when("menopause", {
      is: (menopause) => menopause !== undefined && menopause?.length,
      then: Yup.number().required("This is a required field"),
    })
    .min(1, "Please enter a valid number"),
  menstrual_problem: Yup.string().test("", "Emojis are not allowed", testEmoji),
  period: Yup.number()
    .when("menstrual_problem", {
      is: (menstrual_problem) =>
        menstrual_problem !== undefined && menstrual_problem === "Yes",
      then: Yup.number().required("This is a required field"),
    })
    .min(1, "Please enter a valid number"),
  pap_date: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .test("dob", "Invalid date.", (value) => {
      return !value?.length || isBefore(moment(value, "YYYY-MM-DD"));
    }),
  abnormal_pap: Yup.string().test("", "Emojis are not allowed", testEmoji),
  abnormal_pap_date: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .when("abnormal_pap", {
      is: (abnormal_pap) =>
        abnormal_pap !== undefined && abnormal_pap === "Yes",
      then: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .required("This is a required field"),
    })
    .test("dob", "Invalid date.", (value) => {
      return !value?.length || isBefore(moment(value, "YYYY-MM-DD"));
    }),
  no_of_pregnancies: Yup.number().min(1, "Please enter a valid number"),
  no_of_births: Yup.number().min(1, "Please enter a valid number"),
  no_of_miscarriages: Yup.number().min(1, "Please enter a valid number"),
  mammogram_date: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .test("dob", "Invalid date.", (value) => {
      return !value?.length || isBefore(moment(value, "YYYY-MM-DD"));
    }),
  other: Yup.array(),
  other_value: Yup.string().test("", "Emojis are not allowed", testEmoji),
});

const HealthProfileFemaleHistorySchemaForAdult = Yup.object().shape(
  {
    first_day_of_lmp: Yup.mixed()
      .when("menopause", {
        is: (menopause) => {
          return menopause !== undefined && menopause?.length;
        },
        then: Yup.string().test("", "Emojis are not allowed", testEmoji),
        otherwise: Yup.string()
          .test("", "Emojis are not allowed", testEmoji)
          .required("This is a required field"),
      })
      .test("dob", "Invalid date.", (value) => {
        return !value?.length || isBefore(moment(value, "YYYY-MM-DD"));
      }),
    menopause: Yup.mixed().when("first_day_of_lmp", {
      is: (first_day_of_lmp) => {
        return first_day_of_lmp !== undefined && first_day_of_lmp?.length;
      },
      then: Yup.string().test("", "Emojis are not allowed", testEmoji),
      otherwise: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .required("This is a required field"),
    }),
    menopause_age: Yup.number()
      .when("menopause", {
        is: (menopause) => menopause !== undefined && menopause?.length,
        then: Yup.number().required("This is a required field"),
      })
      .min(1, "Please enter a valid number"),
    menstrual_problem: Yup.string()
      .test("", "Emojis are not allowed", testEmoji)
      .required("This is a required field"),
    period: Yup.number()
      .when("menstrual_problem", {
        is: (menstrual_problem) =>
          menstrual_problem !== undefined && menstrual_problem === "Yes",
        then: Yup.number().required("This is a required field"),
      })
      .min(1, "Please enter a valid number"),
    pap_date: Yup.string()
      .test("", "Emojis are not allowed", testEmoji)
      .test("dob", "Invalid date.", (value) => {
        return !value?.length || isBefore(moment(value, "YYYY-MM-DD"));
      }),
    abnormal_pap: Yup.string()
      .test("", "Emojis are not allowed", testEmoji)
      .required("This is a required field"),
    abnormal_pap_date: Yup.string()
      .test("", "Emojis are not allowed", testEmoji)
      .when("abnormal_pap", {
        is: (abnormal_pap) =>
          abnormal_pap !== undefined && abnormal_pap === "Yes",
        then: Yup.string()
          .test("", "Emojis are not allowed", testEmoji)
          .required("This is a required field"),
      })
      .test("dob", "Invalid date.", (value) => {
        return !value?.length || isBefore(moment(value, "YYYY-MM-DD"));
      }),
    no_of_pregnancies: Yup.number()
      .min(0, "Please enter a valid number")
      .required("This is a required field"),
    no_of_births: Yup.number()
      .min(0, "Please enter a valid number")
      .required("This is a required field"),
    no_of_miscarriages: Yup.number()
      .min(0, "Please enter a valid number")
      .required("This is a required field"),
    mammogram_date: Yup.string()
      .test("", "Emojis are not allowed", testEmoji)
      .test("dob", "Invalid date.", (value) => {
        return !value?.length || isBefore(moment(value, "YYYY-MM-DD"));
      })
      .required("This is a required field"),
    other: Yup.array(),
    other_value: Yup.string().test("", "Emojis are not allowed", testEmoji),
  },
  ["menopause", "first_day_of_lmp"]
);

const HealthProfileALcoholUseValidationSchema = Yup.object().shape({
  alcohol_usage: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("This is a required field"),
  kind: Yup.array().when("alcohol_usage", {
    is: (alcohol_usage) => alcohol_usage && alcohol_usage !== "Never",
    then: Yup.array()
      .of(
        Yup.string()
          .test("", "Emojis are not allowed", testEmoji)
          .required("This is a required field")
      )
      .min(1, "This is a required field"),
  }),
  no_of_drinks: Yup.number().when("alcohol_usage", {
    is: (alcohol_usage) => alcohol_usage && alcohol_usage !== "Never",
    then: Yup.number()
      .required("This is a required field")
      .min(1, "Enter a valid number"),
  }),
  frequency: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .when("alcohol_usage", {
      is: (alcohol_usage) => alcohol_usage && alcohol_usage !== "Never",
      then: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .required("This is a required field"),
    }),
});

const HealthProfileTobaccoUseValidationSchema = Yup.object().shape({
  tobacco_usage: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("This is a required field."),
  no_of_years: Yup.number().when("tobacco_usage", {
    is: (tobacco_usage) =>
      !tobacco_usage ||
      tobacco_usage === "Yes" ||
      tobacco_usage === "Previously",
    then: Yup.number().required("This is a required field"),
  }),
  quit_on: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .when("tobacco_usage", {
      is: (tobacco_usage) => !tobacco_usage || tobacco_usage === "Previously",
      then: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .required("This is a required field"),
    }),
  tobacco_form: Yup.array().when("tobacco_usage", {
    is: (tobacco_usage) =>
      !tobacco_usage ||
      tobacco_usage === "Yes" ||
      tobacco_usage === "Previously",
    then: Yup.array()
      .min(1, "This is a required field")
      .required("This is a required field"),
  }),
  no_of_tobacco: Yup.number().when("tobacco_usage", {
    is: (tobacco_usage) =>
      !tobacco_usage ||
      tobacco_usage === "Yes" ||
      tobacco_usage === "Previously",
    then: Yup.number()
      .min(1, "This is a required field")
      .required("This is a required field"),
  }),
  frequency: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .when("tobacco_usage", {
      is: (tobacco_usage) =>
        !tobacco_usage ||
        tobacco_usage === "Yes" ||
        tobacco_usage === "Previously",
      then: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .required("This is a required field"),
    }),
  household_smoke: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("This is a required field"),
  second_hand_smoke: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("This is a required field"),
});

const HealthProfileExerciseValidationSchema = Yup.object().shape({
  exercise_regularly: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("This is a required field"),
  type_of_execise: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .when("exercise_regularly", {
      is: (exercise_regularly) => exercise_regularly === "Yes",
      then: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .required("This is a required field"),
    }),
  number_of_minutes: Yup.number().when("exercise_regularly", {
    is: (exercise_regularly) => exercise_regularly === "Yes",
    then: Yup.number()
      .required("This is a required field")
      .min(1, "Enter a valid number"),
  }),
  frequency: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .when("exercise_regularly", {
      is: (exercise_regularly) => exercise_regularly === "Yes",
      then: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .required("This is a required field"),
    }),
});

const HealthProfileRecreationalDrugsValidationSchema = Yup.object().shape({
  drug_usage: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("This is a required field"),
  drug_type: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .when("drug_usage", {
      is: (drug_usage) => !drug_usage || drug_usage !== "Never",
      then: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .required("This is a required field"),
    }),
  no_of_drugs: Yup.number().when("drug_usage", {
    is: (drug_usage) => !drug_usage || drug_usage !== "Never",
    then: Yup.number()
      .required("This is a required field")
      .min(1, "Enter a valid number"),
  }),
  frequency: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .when("drug_usage", {
      is: (drug_usage) => !drug_usage || drug_usage !== "Never",
      then: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .required("This is a required field"),
    }),
});

const HealthProfileSexualActivitySchema = Yup.object().shape({
  activity: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("This is a required field"),
  partner: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .when("activity", {
      is: (activity) => !activity || activity !== "Inactive",
      then: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .required("This is a required field"),
    }),
  safe_sex_practice: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .when("activity", {
      is: (activity) => !activity || activity !== "Inactive",
      then: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .required("This is a required field"),
    }),
  birth_control_type: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .when("activity", {
      is: (activity) => !activity || activity !== "Inactive",
      then: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .required("This is a required field"),
    }),
});

const HealthProfileCaffeineUsageSchema = Yup.object().shape({
  caffeine_consume: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("This is a required field"),
  no_of_caffeine: Yup.number().when("caffeine_consume", {
    is: (caffeine_consume) => !caffeine_consume || caffeine_consume !== "No",
    then: Yup.number()
      .required("This is a required field")
      .min(1, "Enter a valid number"),
  }),
  frequency: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .when("caffeine_consume", {
      is: (caffeine_consume) => !caffeine_consume || caffeine_consume !== "No",
      then: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .required("This is a required field"),
    }),
});

const HealthProfileSpecialDietSchema = Yup.object().shape({
  follow_special_diet: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("This is a required field"),
  kind_of_special_diet: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .when("follow_special_diet", {
      is: (follow_special_diet) =>
        !follow_special_diet || follow_special_diet !== "No",
      then: Yup.string()
        .test("", "Emojis are not allowed", testEmoji)
        .required("This is a required field"),
    }),
});

const HealthProfileVaccinationSchema = Yup.object().shape({
  tdOrTdap: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .test("tdOrTdap", "Invalid date.", (value) => {
      return !value?.length || isBefore(moment(value, "YYYY-MM-DD"));
    }),
  flu: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .test("flu", "Invalid date.", (value) => {
      return !value?.length || isBefore(moment(value, "YYYY-MM-DD"));
    }),
  ppd: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .test("ppd", "Invalid date.", (value) => {
      return !value?.length || isBefore(moment(value, "YYYY-MM-DD"));
    }),
  mmr: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .test("mmr", "Invalid date.", (value) => {
      return !value?.length || isBefore(moment(value, "YYYY-MM-DD"));
    }),
  pneumococcal: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .test("pneumococcal", "Invalid date.", (value) => {
      return !value?.length || isBefore(moment(value, "YYYY-MM-DD"));
    }),
  hpv: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .test("hpv", "Invalid date.", (value) => {
      return !value?.length || isBefore(moment(value, "YYYY-MM-DD"));
    }),
});

const pendingPaymentsSchema = Yup.object().shape({
  first_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .trim()
    .matches(nameRegex, "Enter a valid first name")
    .required("First Name is required"),
  last_name: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .trim()
    .matches(nameRegex, "Enter a valid last name")
    .required("Last Name is required"),
  address_1: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("Address line 1 is required"),
  address_2: Yup.string().test("", "Emojis are not allowed", testEmoji),
  city: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("City is required"),
  state: Yup.string()
    .test("", "Emojis are not allowed", testEmoji)
    .required("State is required"),
  zipcode: zipcodeSchema,
  email: emailValidation,
  mobile_number: mobileNumberValidation,
});

export {
  LoginSchema,
  PhoneInputSchema,
  OTPInputSchema,
  ForgotPasswordSchema,
  ResetPasswordSchema,
  SignupSchema,
  InsuranceDetailsSchema,
  ProfileSchema,
  PCBasicDetails,
  ChildProfileSchema,
  AddChildSchema,
  AddChildSchemaDerm,
  MapZipcodeValidation,
  ChangePasswordSchema,
  AddSomeoneElseSchema,
  AddSomeoneElseSchemaDerm,
  TreatmentPlanSchema,
  OtherConditionSchema,
  SymptomStatusSchema,
  PriorTreatmentsSchema,
  SkinCareProductsSchema,
  OtherFeedbacksSchema,
  updateBasicInfoCRSchema,
  updateBasicInfoPBSchema,
  updateBasicInfoSchema,
  CosmeticBasicDetailsSchema,
  conditionDetailsSchema,
  OtherSymptomsSchema,
  surgeriesSchema,
  ContactUsSchema,
  UploadPhotosSchema,
  HealthProfileMedicalHistorySchema,
  HealthProfileFamilyHistorySchema,
  HealthProfileAllergiesSchema,
  HealthProfileMedicationsSchema,
  HealthProfileSurgeriesHospitalisationSchema,
  SurgeriesHospitalisationSchema,
  HealthProfileFemaleHistorySchema,
  HealthProfileFemaleHistorySchemaForAdult,
  HealthProfileALcoholUseValidationSchema,
  HealthProfileTobaccoUseValidationSchema,
  HealthProfileExerciseValidationSchema,
  HealthProfileRecreationalDrugsValidationSchema,
  HealthProfileSexualActivitySchema,
  HealthProfileCaffeineUsageSchema,
  HealthProfileSpecialDietSchema,
  HealthProfileVaccinationSchema,
  AdditionalDetailsSchema,
  isAdult,
  SignupFirstStep,
  SignupSecondStep,
  emailValidation,
  addressSchema,
  pendingPaymentsSchema,
};
