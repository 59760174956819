import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import useHealthProfile from "hooks/useHealthProfile";
import { Form, Formik } from "formik";
import CSSLoader from "components/cssLoader/CSSLoader";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import HealthProfileRadio from "../../HealthProfileRadio";
import HealthProfileQuestion from "../../HealthProfileQuestion";
import Spacer from "components/Spacer/Spacer";
import Input from "components/inputs/Input";
import { HealthProfileCaffeineUsageSchema } from "helpers/validationSchema";
import FormikErrorFocus from "formik-error-focus";
import SimpleHeader from "components/header/SimpleHeader";

const YES_NO = ["Yes", "No"];
const FREQUENCY = ["Day", "Week", "Month", "Year"];

function CaffeineUse() {
  const formRef = useRef();
  const history = useHistory();
  const patient_id = history?.location?.pathname?.match(/\d/g)?.join("");

  const {
    healthPayload,
    postHealthProfile,
    postingHealthProfile,
  } = useHealthProfile();

  useEffect(() => {
    if (
      healthPayload?.health_profile &&
      healthPayload?.health_profile?.caffeine_usage
    ) {
      formRef?.current?.setValues({
        caffeine_consume:
          healthPayload?.health_profile?.caffeine_usage?.drinks_caffeine ?? "",
        frequency:
          healthPayload?.health_profile?.caffeine_usage?.frequency ?? "",
        no_of_caffeine:
          healthPayload?.health_profile?.caffeine_usage?.no_of_drinks ?? "",
      });
    }
  }, [healthPayload]);

  function completed() {
    history.goBack();
  }

  function onSave(values) {
    let payload = {
      caffeine_usage: {
        drinks_caffeine: values?.caffeine_consume,
        frequency: values?.frequency,
        no_of_drinks: values?.no_of_caffeine,
      },
    };
    postHealthProfile({ patientId: patient_id, payload, completed });
  }
  return (
    <div className="h-screen bg-veryLightBlue">
      <Formik
        innerRef={formRef}
        onSubmit={onSave}
        initialValues={{
          caffeine_consume: "",
          no_of_caffeine: "",
          frequency: "",
        }}
        validationSchema={HealthProfileCaffeineUsageSchema}
      >
        {({ values, submitCount, errors, setFieldValue, setValues }) => (
          <Form className="h-full">
            <SimpleHeader
              showBackButton
              title="Caffeine Use"
              rightActionView={
                <button
                  disabled={postingHealthProfile}
                  type={"submit"}
                  className="text-2xl font-bold text-orange"
                >
                  {postingHealthProfile ? (
                    <CSSLoader className="w-8 h-8" color="orange" />
                  ) : (
                    "Save"
                  )}
                </button>
              }
            />
            <div className="p-12 overflow-y-auto bg-white h-eliminateHeaderWithBottomTab md:h-eliminateHeader">
              <MedicalAssistant
                content={<p>Please tell us about your caffeine usage.</p>}
              />
              <Spacer height={26} />
              <HealthProfileQuestion text={"Do you consume caffeine?"} />
              <div className={`flex items-center mt-8 flex-wrap`}>
                {YES_NO?.map((option, idx) => {
                  return (
                    <div className="flex flex-1 mb-8 mr-6" key={idx}>
                      <HealthProfileRadio
                        text={option}
                        selected={values?.caffeine_consume === option}
                        onClick={() => {
                          if (option === "Inactive") {
                            setValues({
                              caffeine_consume: option,
                              partner: "",
                              safe_sex_practice: "",
                              birth_control_type: "",
                            });
                          } else {
                            setFieldValue("caffeine_consume", option);
                          }
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              {submitCount && errors?.caffeine_consume ? (
                <p className="mt-4 text-base font-bold text-red">
                  {errors?.caffeine_consume}
                </p>
              ) : null}
              {values?.caffeine_consume?.length &&
              values?.caffeine_consume === "Yes" ? (
                <>
                  <Spacer height={26} />
                  <Input
                    labelstyles={{
                      fontWeight: "500",
                      textOpacity: 1,
                      color: "rgba(16, 19, 71, 1)",
                    }}
                    type="number"
                    label="How much caffeine?"
                    id="no_of_caffeine"
                    name="no_of_caffeine"
                    min="1"
                  />
                  <Spacer height={26} />
                  <HealthProfileQuestion text={"Frequency (per)"} />
                  <div className={`flex items-center mt-8 flex-wrap`}>
                    {FREQUENCY?.map((frequency, idx) => {
                      return (
                        <div className="flex flex-1 mb-8 mr-6" key={idx}>
                          <HealthProfileRadio
                            text={frequency}
                            selected={values?.frequency === frequency}
                            onClick={() => {
                              setFieldValue("frequency", frequency);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                  {submitCount && errors?.frequency ? (
                    <p className="mt-4 text-base font-bold text-red">
                      {errors?.frequency}
                    </p>
                  ) : null}
                </>
              ) : null}
            </div>
            <FormikErrorFocus align={"bottom"} ease={"linear"} duration={500} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CaffeineUse;
