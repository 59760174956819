import TextWithBulletPoint from "../../TextWithBulletPoint";

export const getAlcoholUseView = (payload) => {
  const values = {
    drinks_alcohol: payload?.alcohol_usage?.drinks_alcohol?.length
      ? payload?.alcohol_usage?.drinks_alcohol
      : null,
    frequency: payload?.alcohol_usage?.frequency?.length
      ? payload?.alcohol_usage?.frequency
      : null,
    kind_of_alcohol: payload?.alcohol_usage?.kind_of_alcohol?.length
      ? payload?.alcohol_usage?.kind_of_alcohol
      : null,
    no_of_drinks:
      typeof payload?.alcohol_usage?.no_of_drinks === "number"
        ? payload?.alcohol_usage?.no_of_drinks
        : null,
  };
  if (Object.keys(values)?.some((el) => values?.[el] !== null)) {
    return (
      <div className="p-8 bg-alternativeWhite border border-borderColor mt-8 rounded-xl">
        {values?.drinks_alcohol ? (
          <TextWithBulletPoint
            text={`Alcohol usage : ${values?.drinks_alcohol}`}
          />
        ) : null}
        {values?.kind_of_alcohol ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Kind of alcohol : ${values?.kind_of_alcohol?.join(", ")}`}
          />
        ) : null}
        {values?.no_of_drinks ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Number of drinks : ${values?.no_of_drinks}`}
          />
        ) : null}
        {values?.frequency ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Per : ${values?.frequency}`}
          />
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};
