import Images from "assets/images";
import CSSLoader from "components/cssLoader/CSSLoader";
import React from "react";

function SearchInput({ onChange, loading, value, onKeyPress }) {
  return (
    <div className="flex items-center justify-between bg-alternativeWhite h-16 px-6 rounded-1/4">
      <input
        onKeyPress={onKeyPress}
        value={value}
        placeholder="Type something.."
        onChange={onChange}
        className="bg-transparent border-none focus:outline-none h-full w-full text-2xl font-medium"
      />
      {loading ? (
        <CSSLoader className={"w-8 h-8"} color={"orange"} />
      ) : (
        <img src={Images.searchIcon} alt="search" className="w-8 h-8" />
      )}
    </div>
  );
}

export default SearchInput;
