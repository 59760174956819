import React, { createContext, useReducer, useState } from "react";

export const SignupContext = createContext({});

function SignupProvider({ children }) {
  const [initialFormValues, setInitialFormValues] = useState({
    step: 1,
    showAllFields: false,
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    zip_code: "",
    password: "",
    confirm_password: "",
    type: "normal",
    provider: "",
    login_via: 1,
  });

  const formReducer = (state, action) => {
    switch (action.type) {
      case "SHOW_PASSWORD":
        return {
          ...state,
          showAllFields: true,
        };
      case "UPDATE_EMAIL":
        return {
          ...state,
          email: action.payload.email,
        };
      case "FIRST_STEP":
        return {
          ...state,
          step: state.step === 1 ? 2 : 4,
          email: action.payload.email ?? "",
          password: action.payload.password ?? null,
          confirm_password: action.payload.password ?? null,
          mobile_number: action.payload.mobile_number ?? "",
          first_name: action.payload.first_name ?? "",
          last_name: action.payload.last_name ?? "",
          login_via: action.payload.login_via ?? undefined,
        };
      case "SECOND_STEP":
        return {
          ...state,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          mobile_number: action.payload.mobile_number,
          email: action.payload.email ?? "",
          provider: action.payload.provider ?? null,
          zip_code: action.payload.zip_code,
        };
      case "PREV_STEP":
        return {
          ...state,
          step: 3,
        };
      default:
        return state;
    }
  };

  const [formValues, formDispatch] = useReducer(formReducer, initialFormValues);

  const handleInitialValuesUpdate = (updatedValues) => {
    setInitialFormValues((prevValues) => ({
      ...prevValues,
      ...updatedValues,
    }));
  };

  return (
    <SignupContext.Provider
      value={{ formValues, formDispatch, handleInitialValuesUpdate }}
    >
      {children}
    </SignupContext.Provider>
  );
}

export default SignupProvider;
