import Images from "assets/images";
import Spacer from "components/Spacer/Spacer";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

const OTCSuccess = () => {
  const history = useHistory();
  const location = useLocation();

  const takeMeHome = () => {
    history.replace("/home");
  };

  return (
    <div className="h-screen bg-veryLightBlue">
      <div className="wrapper h-full overflow-hidden bg-white">
        <div className="h-full flex flex-col justify-center items-center">
          <img
            src={Images.filledCheckedLight}
            alt="success"
            className="w-36 h-36"
          />
          <Spacer height={42} />
          <h3 className="font-bold text-4xl text-indigo">Thank You!</h3>
          <Spacer height={12} />
          <p className="font-semibold text-3xl text-indigo">
            The payment was successful
          </p>
          <Spacer height={32} />
          <p className="w-full px-8 font-light text-3xl text-indigo text-center md:w-3/4 md:px-0">
            {location?.state?.patientName}, your order is confirmed. Once we
            ship your order, we will add the FedEX tracking number to your
            account.
          </p>
          <Spacer height={32} />
          <button
            onClick={takeMeHome}
            className="px-10 py-2 bg-orange rounded-full font-bold text-2xl text-white"
          >
            Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default OTCSuccess;
