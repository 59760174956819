import { useContext } from "react";
import { FullPageLoaderContext } from "providers/FullPageLoaderContext";

export default function useFullPageLoader() {
  const context = useContext(FullPageLoaderContext);
  if (context === undefined) {
    throw new Error(
      "useFullPageLoader must be used within a FullPageLoaderProvider"
    );
  }
  return context;
}
