import React, { useState } from "react";
import { postVisitSurveyAction } from "api/actions/VisitActions";
import Images from "assets/images";
import Spacer from "components/Spacer/Spacer";
import { useApiMutation } from "hooks/useApiMutation";
import useToastify from "hooks/useToastify";
import CSSLoader from "components/cssLoader/CSSLoader";

function RateProviderStrip({
  providerName,
  providerImage,
  survey_token,
  selectedStarRating,
  setSelectedStarRating,
}) {
  const [hoverState, setHoverState] = useState(null);
  const [submittedFeedback, setSubmittedFeedback] = useState(null);
  const { showAlert } = useToastify();
  const { mutate: postVisitSurvey, loading: postingSurvey } = useApiMutation(
    postVisitSurveyAction
  );

  async function submitSurvey() {
    try {
      const response = await postVisitSurvey({
        feedback: {
          recommend_rating: selectedStarRating * 2,
        },
        token: survey_token,
      });
      if (
        response?.error ||
        response?.payload?.error ||
        response?.payload?.error_msg
      ) {
        showAlert(
          response?.payload?.error ||
            response?.payload?.error_msg ||
            "Something went wrong.",
          "error"
        );
      } else {
        setSubmittedFeedback(true);
      }
    } catch (error) {
      showAlert(error?.message ?? "Something went wrong.", "error");
    }
  }

  function getStars() {
    return Array(5)
      .fill("")
      .map((_, index) => {
        if (
          (typeof selectedStarRating === "number" &&
            selectedStarRating > index) ||
          (typeof hoverState === "number" && hoverState > index)
        ) {
          return Images.starFilled;
        } else {
          return Images.starUnfilled;
        }
      });
  }

  return (
    <div className="bg-white flex items-center justify-start">
      {!submittedFeedback ? (
        <div className="p-6 flex flex-col items-center justify-center bg-alternativeWhite w-full">
          <p className="text-xl font-light text-center">
            How would you rate your experience with {providerName}?
          </p>
          <div className="flex mt-8 justify-evenly items-center md:justify-start">
            <div className="relative bg-white border-solid border-1/5 border-blue flex items-center justify-center w-28 h-28 rounded-3xl overflow-hidden">
              <img
                className="absolute object-cover rounded-3xl w-11/12 h-11/12"
                src={providerImage}
                alt="provider"
              />
            </div>
            <div className="ml-4 md:ml-6">
              <div className="flex">
                {getStars().map((star, index) => {
                  return (
                    <img
                      onMouseEnter={() => setHoverState(index + 1)}
                      onMouseLeave={() => setHoverState(null)}
                      key={index}
                      onClick={() => setSelectedStarRating(index + 1)}
                      src={star}
                      className={`object-contain h-8 w-8 md:h-10 md:w-10 cursor-pointer mr-2 md:${
                        index + 1 <= hoverState &&
                        selectedStarRating < hoverState
                          ? "opacity-50"
                          : "opacity-100"
                      }`}
                      alt="rating"
                    />
                  );
                })}
              </div>
              {selectedStarRating > 3 ? (
                <p className="text-center font-bold text-xl mt-4">Great!</p>
              ) : null}
            </div>
          </div>
          <Spacer height={20} />
          <button
            disabled={postingSurvey || !selectedStarRating}
            onClick={submitSurvey}
            className={`bg-orange text-white font-medium py-3 px-6 rounded text-xl flex items-center ${
              postingSurvey || !selectedStarRating
                ? "opacity-60 cursor-not-allowed"
                : ""
            }`}
          >
            {postingSurvey ? <CSSLoader className="w-8" /> : null}
            {postingSurvey ? "Please wait" : "Submit Feedback"}
          </button>
        </div>
      ) : (
        <div className="wrapper p-6 flex items-center flex-col">
          <img
            className="w-16 h-16"
            src={selectedStarRating < 3 ? Images.sad : Images.checkedLightGreen}
            alt="expression"
          />
          <p className="text-xl font-light leading-8 mt-6 text-center">
            {selectedStarRating < 3
              ? `We take your feedback very seriously and will check with the provider on what went wrong and how we can improve our quality of service the next time you give us an opportunity to serve you.`
              : `Thank you for sharing your feedback.`}
          </p>
        </div>
      )}
    </div>
  );
}

export default RateProviderStrip;
