import React from "react";
import Images from "assets/images";
import Spacer from "components/Spacer/Spacer";
import { Form, Formik } from "formik";
import { ResetPasswordSchema } from "helpers/validationSchema";
import Button from "components/buttons/Button";
import Header from "components/header/Header";
import useToastify from "hooks/useToastify";
import { useApiMutation } from "hooks/useApiMutation";
import { resetPassword } from "api/actions/AuthActions";
import { useHistory, useParams } from "react-router-dom";
import FormikErrorFocus from "formik-error-focus";
import Password from "components/inputs/Password";

function ResetPassword() {
  const { token } = useParams();
  const { mutate: callResetPassword, loading } = useApiMutation(resetPassword);

  const { showAlert } = useToastify();
  const history = useHistory();
  function checkGoBack() {
    let currentUrl = window.location.href;
    setTimeout(() => {
      if (currentUrl === window.location.href) {
        history.replace("/");
      }
    }, 500);
  }
  async function onFormSubmit(values) {
    try {
      const result = await callResetPassword({
        payload: {
          password: values?.new_password,
          confirm_password: values?.confirm_password,
        },
        token,
      });
      if (result.error || result?.payload?.status === false) {
        errorHandler(
          result?.payload?.message ?? "Something went wrong, Please try again."
        );
      } else {
        successHandler(result);
      }
    } catch (err) {
      errorHandler(err);
    }
  }

  function successHandler(result) {
    showAlert(result.payload.message, "success");
    history.push("/login");
  }

  function errorHandler(message) {
    showAlert(message, "error");
  }

  return (
    <div className="bg-veryLightBlue h-screen flex flex-col md:justify-center md:bg-gradient-to-b from-blue-300 to-blue-500">
      <div className="w-full hidden">
        <Header title="Reset Password" />
      </div>
      <div className="login-card py-0 pb-32 md:pb-0 md:pt-8 md:px-12 bg-blue md:bg-white flex-1 md:flex-initial overflow-y-auto md:rounded-xl">
        <div className="py-8 flex flex-col items-center justify-center bg-white md:py-0">
          <img
            className="object-contain h-44 w-44 "
            src={Images.logoVerticalNaming}
            alt="logo"
          />
          <p className="pt-4 md:pt-6 text-4xl md:text-5xl font-medium leading-8 md:mt-4 md:font-bold flex">
            Reset Password
          </p>
          <p className="px-4 font-medium text-2xl text-center pt-6 text-grey-700 md:hidden">
            Please enter a new password that is at least 8 characters in length.
          </p>
        </div>
        <p
          style={{ lineHeight: "2.8rem" }}
          className="hidden font-medium text-3xl md:text-2xl text-center pt-6 text-themeBlack md:block"
        >
          Please enter a new password that is at least 8 characters in length.
        </p>
        <Spacer height={27} />
        <div className="bg-white mx-4 p-6 pb-0 md:mx-0 md:p-0 rounded-t-3xl rounded-b-none md:rounded-b-3xl">
          <Formik
            initialValues={{
              new_password: "",
              confirm_password: "",
            }}
            validationSchema={ResetPasswordSchema}
            onSubmit={onFormSubmit}
          >
            {({ dirty, isValid }) => (
              <Form className="required-label">
                <Password
                  name="new_password"
                  label="New Password"
                  id="new_password"
                  type="password"
                  placeholder="Minimum 8 characters"
                />
                <Spacer height={32} />
                <Password
                  name="confirm_password"
                  label="Confirm Password"
                  id="confirm_password"
                  placeholder="Minimum 8 characters"
                  type="password"
                />
                <Spacer height={24} />
                <Button
                  label="Reset Password"
                  loading={loading}
                  disabled={loading || !(dirty && isValid)}
                  className="h-18"
                />
                <FormikErrorFocus
                  align={"bottom"}
                  ease={"linear"}
                  duration={500}
                />
              </Form>
            )}
          </Formik>
        </div>
        <div className="bg-white flex justify-center rounded-b-3xl rounded-t-none md:rounded-b-none py-10 mx-4 my-0 md:py-0 md:mx-0 md:my-10 md:bg-transparent">
          <div
            className="flex space-x-4  cursor-pointer w-max"
            onClick={() => checkGoBack()}
          >
            <img
              className="w-6 object-contain"
              src={Images.arrowBack}
              alt="back"
            />
            <p className="font-medium text-2xl text-black">Back to login</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
