import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useCurrentUser } from "hooks/useCurrentUser";

function ProtectVisitRoute({ children, ...rest }) {
  const { currentSpeciality } = useCurrentUser();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        currentSpeciality ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/home",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default ProtectVisitRoute;
