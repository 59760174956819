import TextWithBulletPoint from "../../TextWithBulletPoint";

export const getSpecialDietView = (payload) => {
  const values = {
    on_diet: payload?.special_diet?.on_diet?.length
      ? payload?.special_diet?.on_diet
      : null,
    diet_type: payload?.special_diet?.diet_type?.length
      ? payload?.special_diet?.diet_type
      : null,
  };

  if (Object.keys(values)?.some((el) => values?.[el] !== null)) {
    return (
      <div className="p-8 bg-alternativeWhite border border-borderColor mt-8 rounded-xl">
        {values?.on_diet ? (
          <TextWithBulletPoint text={`On Diet : ${values?.on_diet}`} />
        ) : null}
        {values?.diet_type ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Type of diet : ${values?.diet_type}`}
          />
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};
