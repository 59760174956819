import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import SearchInputWithChipCard from "components/searchInputWithChip/SearchInputWithChipCard";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import VisitSubQuestionSlideUpContainer from "components/visitSubQuestionSlideUp/VisitSubQuestionSlideUpContainer";
import { dermatology_sub_steps } from "helpers/enum";
import scrollToBottom from "helpers/scrollToBottom";
import useModal from "hooks/useModal";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import useVisitStepChange from "hooks/useVisitStepChange";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import HistoryDebounceSearchModal from "./HistoryDebounceSearchModal";

function AllergiesForDerm() {
  const { setShowModal } = useModal();
  const [selected, setSelected] = useState(null);
  const [selectedAllergies, setSelectedAllergies] = useState([]);

  const dispatch = useDispatch();
  const { incompleteData, subSteps } = useDermatologyVisitFlow();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore = useMemo(() => {
    if (subSteps[dermatology_sub_steps.allergies].value) {
      return [
        // Filter out health profile allergies from case allergies
        // to persist link_to key when re-submitting
        ...subSteps[dermatology_sub_steps.allergies].value
          .filter(
            (el) =>
              !incompleteData.health_profile_allergies.some(
                (allergy) => allergy.name === el.name
              )
          )
          .map((el) => (typeof el === "string" ? { name: el } : el)),
        ...incompleteData.health_profile_allergies,
      ];
    }

    return incompleteData.health_profile_allergies;
  }, [incompleteData.health_profile_allergies, subSteps]);

  const { updateVisit, updatingVisit } = useUpdateDermatologyVisit();

  useEffect(() => {
    if (currentScreenValueFromStore?.length) {
      if (
        currentScreenValueFromStore?.some((el) => el?.name === "None") &&
        currentScreenValueFromStore?.length === 1
      ) {
        setSelected("No");
        setSelectedAllergies([]);
      } else {
        setSelected("Yes");
        setSelectedAllergies(
          currentScreenValueFromStore.filter(
            (el) => el?.name !== "None" && el?.name !== null
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selected === "No") {
      dispatch(
        dermaVisitFlowReducers.allergies({
          isValid: true,
        })
      );
    } else if (selected === "Yes") {
      scrollToBottom();
      if (selectedAllergies?.length) {
        dispatch(
          dermaVisitFlowReducers.allergies({
            isValid: true,
          })
        );
      } else {
        dispatch(
          dermaVisitFlowReducers.allergies({
            isValid: false,
          })
        );
      }
    }
  }, [dispatch, selected, selectedAllergies]);

  function onRightButtonClick(_, nextAnimation) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          allergies:
            selected === "Yes" ? selectedAllergies : [{ name: "None" }],
          dermatology_steps: incompleteData?.dermatology_steps,
        },
      },
      change_current_screen: true,
      screen_to_complete: dermatology_sub_steps.allergies,
      callBack: () => {
        dispatch(
          dermaVisitFlowReducers.allergies({
            value:
              selected === "Yes"
                ? selectedAllergies?.map((el) => {
                    if (el.link_to) {
                      return {
                        ...el,
                        link_to: el?.link_to,
                      };
                    }
                    return el;
                  })
                : ["None"],
          })
        );
        nextAnimation();
      },
    });
  }

  return (
    <StepScreenContainer align="start">
      <MedicalAssistant
        content={
          <p>
            Do you have any allergies?
            <br />
            <br />
            Please list any reactions to medications, food, insects, etc.
          </p>
        }
      />
      <Card>
        <RadioButtonCard
          options={["Yes", "No"]}
          selected={selected}
          onSelect={setSelected}
        />
      </Card>
      {selected === "Yes" ? (
        <VisitSubQuestionSlideUpContainer>
          <Spacer height={40} />
          <SearchInputWithChipCard
            inputLabel={"Search Allergy"}
            onClickInput={() => {
              if (updatingVisit) {
                return;
              } else {
                setShowModal({
                  state: true,
                  children: (_, onClose) => {
                    return (
                      <HistoryDebounceSearchModal
                        keyToSearch={"allergies"}
                        title={"Select Allergies"}
                        onClose={onClose}
                        selected={selectedAllergies}
                        setSelected={setSelectedAllergies}
                      />
                    );
                  },
                  data: null,
                });
              }
            }}
            selected={selectedAllergies}
            setSelected={setSelectedAllergies}
          />
        </VisitSubQuestionSlideUpContainer>
      ) : null}
    </StepScreenContainer>
  );
}

export default AllergiesForDerm;
