import React from "react";
import moment from "moment";
import useToastify from "hooks/useToastify";
import { useApiMutation } from "hooks/useApiMutation";
import { refillRequestAction } from "api/actions/VisitActions";
import CSSLoader from "components/cssLoader/CSSLoader";
import classNames from "classnames";

function RefillRequest({
  id,
  visitDetails,
  refillRequested,
  setRefillRequested,
  refillRequestSentAt,
  diagnosisSentAt,
  className,
}) {
  const diagnosed_at = moment(
    visitDetails?.diagnosis?.diagnosed_at ?? diagnosisSentAt
  );
  const days_after_diagnosis = moment().diff(diagnosed_at, "days");
  const refillRequestSentAtDays = moment(refillRequestSentAt);
  const refillRequestSentAtDays2 = moment().diff(
    refillRequestSentAtDays,
    "days"
  );

  const { showAlert } = useToastify();
  const { mutate: requestRefill, loading: requestingRefill } = useApiMutation(
    refillRequestAction
  );
  async function onRefillClick() {
    if (
      refillRequestSentAtDays2 < 7 ||
      requestingRefill ||
      refillRequested ||
      days_after_diagnosis < 7
    ) {
      // setShowConfirm(true);
      const date = refillRequestSentAt
        ? moment(refillRequestSentAt)?.format("MMM DD, YYYY")
        : moment()?.format("MMM DD, YYYY");
      if (days_after_diagnosis < 7) {
        showAlert(
          "Refill requests may be submitted at least 7 days after being seen by your doctor",
          "error",
          5000
        );
      } else if (refillRequestSentAtDays2 < 7) {
        showAlert(
          `Last refill request sent on ${date}` ?? "Something went wrong",
          "error",
          5000
        );
      } else if (refillRequested) {
        showAlert(
          `Last refill request sent on ${moment()?.format("MMM DD, YYYY")}`,
          "error",
          5000
        );
      }
    } else {
      try {
        const result = await requestRefill(visitDetails?.id ?? id);
        if (result && result?.error === false) {
          showAlert("Refill requested successfully.", "success");
          setRefillRequested(true);
        } else {
          showAlert(
            result.error ||
              result.payload.error ||
              result.payload.error_msg ||
              "Something went wrong",
            "error"
          );
          setRefillRequested(false);
        }
      } catch (error) {
        showAlert(error?.message ?? "Something went wrong", "error");
      }
    }
  }

  return (
    <div>
      <button
        className={classNames(
          "px-2 py-3 bg-opacity-100 text-white rounded-md flex items-center justify-center self-start md:px-5 md:py-3",
          className
        )}
        onClick={onRefillClick}
      >
        {requestingRefill ? (
          <CSSLoader color={"orange"} className="w-8" />
        ) : null}
        <p className="font-bold md:text-xl ">Refill Request</p>
      </button>
    </div>
  );
}

export default RefillRequest;
