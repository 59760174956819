import { useSelector } from "react-redux";

export function useDermatologyVisitFlow() {
  return useSelector((state) => {
    return state.dermaVisitFlow;
  });
}

export function usePrimaryCareVisitFlow() {
  return useSelector((state) => {
    return state.primaryCareVisitFlow;
  });
}

export function usePedVisitFlow() {
  return useSelector((state) => {
    return state.pedVisitFlow;
  });
}