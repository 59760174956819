import React from "react";
import Input from "components/inputs/Input";
import Spacer from "components/Spacer/Spacer";
import useModal from "hooks/useModal";
import HealthProfileHistoryDebounceModal from "../HealthProfileHistoryDebounceModal";
import { input_types } from "helpers/enum";

function AddHealthProfileForMedications({
  medication_forms,
  medications,
  setFieldValue,
}) {
  const { setShowModal } = useModal();
  return (
    <div>
      <h3 className="text-3xl font-medium mt-14">Drug / Medication Allergy</h3>
      {medications?.map((el, idx) => {
        return (
          <div
            key={idx}
            className="pt-7 pb-9 px-9 bg-alternativeWhite rounded-2xl mt-10"
          >
            <Input
              onFocus={() => {
                setShowModal({
                  state: true,
                  children: (_, onClose) => (
                    <HealthProfileHistoryDebounceModal
                      keyToSearch={"medications"}
                      title="Search Medications"
                      onClose={onClose}
                      selected={el?.drug_name}
                      onSelect={(val) =>
                        setFieldValue(`medications[${idx}].drug_name`, val)
                      }
                    />
                  ),
                });
              }}
              label="Drug Name"
              id={`medications[${idx}].drug_name`}
              name={`medications[${idx}].drug_name`}
            />
            <Spacer height={23} />
            <Input
              label="Dose"
              id={`medications[${idx}].dose`}
              name={`medications[${idx}].dose`}
            />
            <Spacer height={23} />
            <Input
              type={input_types.DROPDOWN}
              default={"Select a value"}
              options={["Once", "Twice", "Thrice"]}
              label="Frequency"
              id={`medications[${idx}].frequency`}
              name={`medications[${idx}].frequency`}
            />
            <Spacer height={23} />
            <Input
              type={input_types.DROPDOWN}
              default={"Select a value"}
              options={["Day", "Week", "Month"]}
              label="Per"
              id={`medications[${idx}].per`}
              name={`medications[${idx}].per`}
            />
            <Spacer height={23} />
            <Input
              type={input_types.DROPDOWN}
              default={"Select a value"}
              options={medication_forms}
              label="Form"
              id={`medications[${idx}].form`}
              name={`medications[${idx}].form`}
            />
            {el?.form === "Other" ? (
              <>
                <Spacer height={23} />
                <Input
                  label="Other form"
                  id={`medications[${idx}].other_form`}
                  name={`medications[${idx}].other_form`}
                />
              </>
            ) : null}
            <p
              onClick={() => {
                setFieldValue(
                  "medications",
                  medications?.filter((_, index) => index !== idx)
                );
              }}
              className="text-red text-center font-light text-2xl underline mt-12 cursor-pointer"
            >
              Remove
            </p>
          </div>
        );
      })}
      <p
        onClick={() => {
          setFieldValue("medications", [
            ...medications,
            {
              drug_name: "",
              dose: "",
              frequency: "",
              per: "",
              form: "",
              other_form: "",
            },
          ]);
        }}
        className="text-2xl font-light underline text-orange mt-14 cursor-pointer"
      >
        Add Medications
      </p>
    </div>
  );
}

export default AddHealthProfileForMedications;
