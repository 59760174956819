import React from "react";
import Images from "assets/images";

import "./MedicalAssistant.scss";
import classNames from "classnames";

function MedicalAssistant({
  content,
  xOffset,
  className,
  title = "SkyMD Medical Team",
  titleClassName = "text-2xl font-bold md:text-3xl text-secondaryBlue-600",
  contentBg = "bg-white",
}) {
  return (
    <div
      className={classNames("flex items-center ma_container", className)}
      style={xOffset && { margin: `0 ${xOffset / 10}rem` }}
    >
      <img
        className={`ma_image rounded-full h-24 w-24 md:h-28 md:w-28`}
        src={Images.medicalAssistant}
        alt="medical_assistant"
      />
      <div
        className={classNames(
          "flex flex-col justify-center relative text-grey-900 ml-4 p-4 w-full space-y-6 border border-borderColor rounded-2xl text_container",
          contentBg
        )}
      >
        {title && <h3 className={titleClassName}>{title}</h3>}
        {content}
        <div className="absolute w-6 h-6 transform rotate-45 bg-white border-b border-l -left-3 border-borderColor" />
      </div>
    </div>
  );
}

export default MedicalAssistant;
