import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Header from "components/header/Header";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Spacer from "components/Spacer/Spacer";
import { acceptRevisedInsuranceAction } from "api/actions/VisitActions";
import { useApiQuery } from "hooks/useApiQuery";
import useToastify from "hooks/useToastify";
import CSSLoader from "components/cssLoader/CSSLoader";
import Images from "assets/images";

const AcceptRevisedQuote = () => {
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { state } = location;

  const { showAlert } = useToastify();

  const { loading, query, error, payload } = useApiQuery(
    acceptRevisedInsuranceAction(
      state?.revisedInsuranceDetails?.price_change_confimation_token
    ),
    false
  );

  const acceptRevisedQuote = async () => {
    try {
      const data = await query();
      // console.log({ error, payload, data }, "yoo");
      if (data?.error === false && error === false) {
        localStorage.removeItem("last_revision");
        localStorage.removeItem("last_revision_data");
        setRedirect(true);
        history.push("/home/my-visits");
        showAlert(
          payload?.message || "Accepted revised payment details",
          "success"
        );
      } else {
        setRedirect(data?.error);
        showAlert(
          data?.payload.message || payload?.message || "Something went wrong",
          "error"
        );
      }
    } catch (err) {
      setRedirect(true);
      showAlert(payload?.message || "Something went wrong", "error");
    }
  };

  return (
    <div className="h-screen bg-veryLightBlue">
      {state?.revisedInsuranceDetails?.price_change_type ===
      "unsupported_insurance" ? (
        <>
          <Header title="Final Price Estimate" />
          <div className="h-eliminateHeader bg-white overflow-y-auto wrapper">
            <Spacer height={30} />
            <MedicalAssistant
              xOffset={23}
              content={
                <p>
                  We have established self-pay options that allow patients with
                  unsupported insurance to receive the care they need.
                  <br />
                  <br />
                  By opting for self-pay, you can still benefit from the
                  expertise. If you would like to proceed, please select the
                  orange button below{" "}
                  <q>
                    <span style={{ color: "#337AB7" }}>
                      I AGREE TO PAY AMOUNT
                    </span>
                  </q>
                  .
                  <br />
                  <br />
                  Your provider {state?.providerName} is on standby until then.
                </p>
              }
            />
            <Spacer height={27} />
            <table className="table-fixed p-8 mx-8 mb-10 md:w-3/4 md:mx-auto">
              <thead>
                <tr>
                  <th className="px-8 py-8 bg-lightGrey font-semibold text-2xl text-indigo text-left">
                    Insurance Coverage
                  </th>
                  <th className="px-8 py-8 bg-secondaryBlue-500 font-semibold text-2xl text-white text-left">
                    Pricing Charge
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-b border-grey-600 px-8 py-8 bg-gray-altTwoH font-bold text-2xl text-indigo">
                    SkyMD Visit Care Charge
                  </td>
                  <td className="border-b border-grey-600 px-8 py-8 bg-secondaryBlue-100 font-bold text-2xl text-indigo">
                    ${state?.practiceInsurance?.skymd_visit_charge}
                  </td>
                </tr>
                <tr>
                  <td className="border-b border-grey-600 px-8 py-8 bg-gray-altTwoH font-bold text-2xl text-indigo">
                    Unsupported Insurance - Self-pay
                  </td>
                  <td className="border-b border-grey-600 px-8 py-8 bg-secondaryBlue-100 font-bold text-2xl text-indigo">
                    ${state?.revisedInsuranceDetails?.cash_price}
                  </td>
                </tr>
                <tr>
                  <td className="border-b border-grey-600 px-8 py-8 bg-gray-altTwoH font-bold text-2xl text-indigo">
                    Due Today
                  </td>
                  <td className="border-b border-grey-600 px-8 py-8 bg-secondaryBlue-100 font-bold text-2xl text-indigo">
                    ${state?.revisedInsuranceDetails?.cash_price}
                  </td>
                </tr>
                <tr>
                  <td className="px-8 py-8 bg-gray-altTwoH font-bold text-2xl text-secondaryBlue-500">
                    Amount Saved
                  </td>
                  <td className="px-8 py-8 bg-secondaryBlue-100 font-bold text-2xl text-secondaryBlue-500">
                    ${state?.practiceInsurance?.amount_saved}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="flex justify-center">
              {!redirect ? (
                <button
                  disabled={loading}
                  onClick={acceptRevisedQuote}
                  className={`px-14 py-6 mt-8 mb-16 rounded-lg ${
                    loading ? "bg-lightOrange" : "bg-orange"
                  } font-bold text-3xl text-white uppercase`}
                >
                  {loading ? (
                    <div class="flex items-center">
                      <CSSLoader className="w-8 h-8 mr-4" color="white" />I
                      Agree To Pay Amount
                    </div>
                  ) : (
                    "I Agree To Pay Amount"
                  )}
                </button>
              ) : (
                <button
                  onClick={() => {
                    setRedirect(false);
                    history.push(`/home/profile/payments`);
                  }}
                  className={`px-14 py-6 mt-8 mb-16 rounded-lg ${
                    loading ? "bg-lightOrange" : "bg-orange"
                  } font-bold text-3xl text-white uppercase`}
                >
                  Enter Payment details
                </button>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <Header title="Final Price Estimate" />
          <div className="h-eliminateHeader bg-white overflow-y-auto wrapper">
            <Spacer height={30} />
            <MedicalAssistant
              xOffset={23}
              content={
                <p>
                  We have reviewed your insurance coverage to determine if SkyMD
                  is participating in or out of network with your insurance
                  carrier.
                  <br />
                  <br />
                  You can review the pricing details provided below. If you
                  would like to proceed, please select the orange button below{" "}
                  <q>
                    <span style={{ color: "#337AB7" }}>
                      I AGREE TO PAY AMOUNT
                    </span>
                  </q>
                  .
                  <br />
                  <br />
                  Your provider {state?.providerName} is on standby until then.
                </p>
              }
            />
            <Spacer height={27} />
            <table className="table-fixed p-8 mx-auto mb-10 md:w-3/4">
              <thead>
                <tr>
                  <th className="px-8 py-8 bg-lightGrey font-semibold text-2xl text-indigo text-left">
                    Insurance Coverage
                  </th>
                  <th className="px-8 py-8 bg-secondaryBlue-500 font-semibold text-2xl text-white text-left">
                    Pricing Charge
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-b border-grey-600 px-8 py-8 bg-gray-altTwoH font-bold text-2xl text-indigo">
                    Copay<span className="text-errorRed">*</span>
                  </td>
                  <td className="border-b border-grey-600 px-8 py-8 bg-secondaryBlue-100 font-bold text-2xl text-indigo">
                    ${state?.revisedInsuranceDetails?.revised_copay}
                  </td>
                </tr>
                <tr>
                  <td className="border-b border-grey-600 px-8 py-8 bg-gray-altTwoH font-bold text-2xl text-indigo">
                    Applied to co-insurance
                    <span className="text-errorRed">*</span>
                  </td>
                  <td className="border-b border-grey-600 px-8 py-8 bg-secondaryBlue-100 font-bold text-2xl text-indigo">
                    $
                    {state?.revisedInsuranceDetails?.revised_coinsurance_amount}
                  </td>
                </tr>
                <tr>
                  <td className="border-b border-grey-600 px-8 py-8 bg-gray-altTwoH font-bold text-2xl text-indigo">
                    Applied to your deductible
                    <span className="text-errorRed">*</span>
                  </td>
                  <td className="border-b border-grey-600 px-8 py-8 bg-secondaryBlue-100 font-bold text-2xl text-indigo">
                    ${state?.revisedInsuranceDetails?.revised_deductible}
                  </td>
                </tr>
                <tr>
                  <td className="px-8 py-8 bg-gray-altTwoH font-bold text-2xl text-indigo">
                    Due Today
                  </td>
                  <td className="px-8 py-8 bg-secondaryBlue-100 font-bold text-2xl text-indigo">
                    ${state?.revisedInsuranceDetails?.revised_final_price}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="flex justify-between items-start mx-9 mb-11 px-8 py-6 rounded-md bg-cosmYellow-darkBg">
              <img src={Images.triangleWarning} alt="disclaimer" />
              <p className="ml-4 font-light text-2xl">
                <span className="font-semibold">Disclaimer</span>
                <span className="text-errorRed">*</span>: Depending on your
                insurance, co-pays and co-insurances also apply. We make an
                effort to verify if we are in-network with your insurance,
                however you should call your insurance carrier directly as the
                surest way to know if SkyMD is in-network.
              </p>
            </div>

            <div className="flex justify-center">
              <button
                disabled={loading}
                onClick={acceptRevisedQuote}
                className={`px-14 py-6 mt-8 mb-16 rounded-lg ${
                  loading ? "bg-lightOrange" : "bg-orange"
                } font-bold text-3xl text-white uppercase`}
              >
                {loading ? (
                  <div class="flex items-center">
                    <CSSLoader className="w-8 h-8 mr-4" color="white" />I Agree
                    To Pay Amount
                  </div>
                ) : (
                  "I Agree To Pay Amount"
                )}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AcceptRevisedQuote;
