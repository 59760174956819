import Images from "assets/images";
import classNames from "classnames";
import CSSLoader from "components/cssLoader/CSSLoader";
import React, { useState } from "react";
import { Icon } from "stories/Icons";

function CircularImageComponent({
  url,
  online = true,
  size,
  videoMeet,
  className,
}) {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [error, setError] = useState(false);
  function getSize(size) {
    switch (size) {
      case "small":
        return "w-32 h-32 md:w-9r md:h-9r max-w-9r max-h-9r";
      case "fit":
        return "w-1/10 h-1/10 max-w-1/10 max-h-1/10";
      case "medium":
        return "w-32 h-32 max-w-9r max-h-9r md:w-14.8 md:h-14.8 md:max-w-14.8 md:max-h-14.8";
      case "large":
        return "w-16.2 h-16.2 max-w-16.2 max-h-16.2";
      case "responsive":
        return "w-32 h-32 max-w-9r max-h-9r md:w-16.2 md:h-16.2 md:max-w-16.2 md:max-h-16.2";
      default:
        return "w-16.2 h-16.2 max-w-16.2 max-h-16.2";
    }
  }
  function getTopPosition(size) {
    switch (size) {
      case "small":
        return "right-2 top-2 w-6 h-6";
      case "fit":
        return "top-2 right-4 w-7 h-7";
      case "medium":
        return "top-2 right-2 w-6 h-6 md:top-5 md:right-5 md:w-8 md:h-8";
      case "large":
        return "top-5 right-5 w-8 h-8";
      case "responsive":
        return "right-2 top-2 w-5 h-5 border border-white md:top-5 md:right-5 md:w-8 md:h-8";
      default:
        return "top-5 right-5 w-8 h-8";
    }
  }
  function getBottomPosition(size) {
    switch (size) {
      case "small":
        return "right-0 bottom-0 w-14 h-14";
      case "fit":
        return "right-0 bottom-0 w-14 h-14";
      case "medium":
        return "right-2 bottom-1 w-14 h-14";
      case "large":
        return "right-3 bottom-2 w-14 h-14";
      case "responsive":
        return "right-0 bottom-0 w-14 h-14 md:right-3 md:bottom-2 md:w-14 md:h-14";
      default:
        return "right-2 bottom-1 w-14 h-14";
    }
  }
  return (
    <div
      className={classNames(
        "relative flex items-center justify-center bg-white ",
        getSize(size),
        className
      )}
    >
      <img
        className="object-cover w-full h-full border rounded-full"
        src={error ? Images.UserPlaceholder : url}
        alt="avatar"
        onLoad={() => setIsImageLoaded(true)}
        onError={() => {
          setIsImageLoaded(true);
          setError(true);
        }}
      />
      {online && (
        <div
          className={classNames(
            "absolute  border border-white rounded-full  bg-successGreen",
            getTopPosition(size)
          )}
        />
      )}
      {videoMeet && (
        <Icon
          name={"video"}
          className={classNames("absolute", getBottomPosition(size))}
        />
      )}
      {isImageLoaded ? null : (
        <CSSLoader className="absolute w-8 h-8" color="orange" />
      )}
    </div>
  );
}

export default CircularImageComponent;
