import React, { useEffect, useRef, useState } from "react";
import { updateConversation } from "api/actions/UserActions";
import Images from "assets/images";
import CSSLoader from "components/cssLoader/CSSLoader";
import { useApiMutation } from "hooks/useApiMutation";
import ContentEditable from "react-contenteditable";
import "./chataction.scss";

function EditMessageModal({ onClose, message, visitId, updateMessage }) {
  const inputRef = useRef();
  const [editedText, setEditedText] = useState("");

  const {
    mutate: callUpdateConversation,
    loading: updatingMessage,
  } = useApiMutation(updateConversation);

  useEffect(() => {
    if (message) {
      setEditedText(message?.raw_message);
      inputRef?.current?.focus();
    }
  }, [message]);

  const isButtonEnabled = editedText?.trim()?.length && !updatingMessage;

  async function onSend() {
    try {
      const formData = new FormData();
      formData.append("conversation", JSON.stringify({ message: editedText }));
      const result = await callUpdateConversation({
        caseId: visitId,
        payload: formData,
        conversationId: message?.message_id,
      });
      if (result && result?.error === false) {
        updateMessage(result?.payload?.message);
        onClose();
      }
    } catch (error) {}
  }

  return (
    <div className="h-screen bg-black bg-opacity-20">
      <div
        id="update_profile_container"
        className="flex items-center justify-center h-full overflow-y-auto wrapper"
      >
        <div className="flex flex-col items-center justify-center w-full px-10 bg-white rounded-lg pb-14 md:w-10/12">
          <div className="flex items-center justify-between w-full px-2 pt-7 pb-7">
            <p className="text-3xl font-medium">Edit Message</p>
            <img
              onClick={updatingMessage ? null : onClose}
              src={Images.closeIcon}
              alt="close"
              className="w-10 h-10 cursor-pointer"
            />
          </div>
          <div className="flex items-center w-full">
            <ContentEditable
              className="flex-1 max-w-full px-4 py-6 mr-8 text-2xl font-medium break-words outline-none content_editable bg-alternativeWhite rounded-xl"
              innerRef={inputRef}
              html={editedText}
              disabled={false}
              onChange={(e) => setEditedText(e?.target?.value)}
              tagName="span"
            />
            <div
              className={`${
                isButtonEnabled
                  ? "opacity-100 cursor-pointer"
                  : "opacity-50 cursor-not-allowed"
              }`}
              onClick={() => {
                if (isButtonEnabled) {
                  onSend();
                }
              }}
            >
              {updatingMessage ? (
                <CSSLoader color={"orange"} className="w-12 h-12" />
              ) : (
                <img src={Images.sendArrowIcon} alt="send" className={`w-12`} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditMessageModal;
