import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useCurrentUser } from "hooks/useCurrentUser";
import useDataFromUserDetails from "hooks/useDataFromUserDetails";

function MedicalIntakeRouteGuard({ children, ...rest }) {
  const { userToken } = useCurrentUser();
  const {
    hasNoDermaProvider,
    isInactiveDermaProvider,
    isInactiveDermaProviderLicense,
    hasNoPcProvider,
  } = useDataFromUserDetails();
  console.log(hasNoPcProvider);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        userToken &&
        (hasNoDermaProvider ||
          isInactiveDermaProvider ||
          isInactiveDermaProviderLicense ||
          hasNoPcProvider) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/home",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default MedicalIntakeRouteGuard;
