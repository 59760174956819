import {
  dermatology_main_steps,
  dermatology_sub_steps,
  step_status,
} from "helpers/enum";
import { setNextScreenToUpcoming } from "helpers/setDermatologyStepStatus";

export const dermaMedicalHistoryReducer = {
  medical_history(state, action) {
    const { status, value, isValid, nextScreen } = action.payload;
    if (status) {
      state.subSteps[dermatology_sub_steps.medical_history].status = status;
      if (status === step_status.completed) {
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: dermatology_sub_steps.allergies,
        });
      }
    }
    if (value) {
      state.subSteps[dermatology_sub_steps.medical_history].value = value;
    }
    if (nextScreen) {
      state.subSteps[
        dermatology_sub_steps.medical_history
      ].nextScreen = nextScreen;
    }
    if (isValid !== undefined) {
      state.subSteps[dermatology_sub_steps.medical_history].isValid = isValid;
    }
  },
  female_medical_history(state, action) {
    const { status, value, isValid } = action.payload;
    if (status) {
      state.subSteps[
        dermatology_sub_steps.female_medical_history
      ].status = status;
    }
    if (value) {
      state.subSteps[
        dermatology_sub_steps.female_medical_history
      ].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[
        dermatology_sub_steps.female_medical_history
      ].isValid = isValid;
    }
  },
  allergies(state, action) {
    const { status, value, isValid, prevScreen, formValues } = action.payload;
    if (status) {
      state.subSteps[dermatology_sub_steps.allergies].status = status;
      if (status === step_status.completed) {
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: dermatology_sub_steps.medications,
        });
      }
    }
    if (value) {
      state.subSteps[dermatology_sub_steps.allergies].value = value;
    }
    if (formValues !== undefined) {
      state.subSteps[dermatology_sub_steps.allergies].formValues = formValues;
    }
    if (prevScreen) {
      state.subSteps[dermatology_sub_steps.allergies].prevScreen = prevScreen;
    }
    if (isValid !== undefined) {
      state.subSteps[dermatology_sub_steps.allergies].isValid = isValid;
    }
  },
  medications(state, action) {
    const { status, value, isValid, formValues } = action.payload;
    if (status) {
      state.subSteps[dermatology_sub_steps.medications].status = status;
      if (status === step_status.completed) {
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: dermatology_sub_steps.skin_care_products,
        });
      }
    }
    if (value) {
      state.subSteps[dermatology_sub_steps.medications].value = value;
    }
    if (formValues !== undefined) {
      state.subSteps[dermatology_sub_steps.medications].formValues = formValues;
    }
    if (isValid !== undefined) {
      state.subSteps[dermatology_sub_steps.medications].isValid = isValid;
    }
  },
  skin_care_products(state, action) {
    const { status, value, isValid, formValues } = action.payload;
    if (status) {
      state.subSteps[dermatology_sub_steps.skin_care_products].status = status;
      if (status === step_status.completed) {
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: dermatology_sub_steps.select_pharmacy,
        });
      }
    }
    if (value) {
      state.subSteps[dermatology_sub_steps.skin_care_products].value = value;
    }
    if (formValues !== undefined) {
      state.subSteps[
        dermatology_sub_steps.skin_care_products
      ].formValues = formValues;
    }
    if (isValid !== undefined) {
      state.subSteps[
        dermatology_sub_steps.skin_care_products
      ].isValid = isValid;
    }
  },
  select_pharmacy(state, action) {
    const { status, value, isValid } = action.payload;
    if (status) {
      state.subSteps[dermatology_sub_steps.select_pharmacy].status = status;
      if (status === step_status.completed) {
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: dermatology_sub_steps.other_feedbacks,
        });
      }
    }
    if (value) {
      state.subSteps[dermatology_sub_steps.select_pharmacy].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[dermatology_sub_steps.select_pharmacy].isValid = isValid;
    }
  },
  other_feedbacks(state, action) {
    const { status, value, isValid } = action.payload;
    if (status) {
      state.subSteps[dermatology_sub_steps.other_feedbacks].status = status;
      if (status === step_status.completed) {
        if (
          state.mainSteps[dermatology_main_steps.main_medical_history]
            .status === step_status.current
        ) {
          state.mainSteps[dermatology_main_steps.main_medical_history].status =
            step_status.completed;
        }
      }
    }
    if (value) {
      state.subSteps[dermatology_sub_steps.other_feedbacks].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[dermatology_sub_steps.other_feedbacks].isValid = isValid;
    }
  },
};
