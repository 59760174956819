import React from "react";
import Placeholder from "components/placeholders/Placeholder";
import Images from "assets/images";
import { useHistory } from "react-router-dom";
import SimpleHeader from "components/header/SimpleHeader";

function PageNotFound() {
  const history = useHistory();
  return (
    <div className="h-screen bg-veryLightBlue">
      <SimpleHeader showBackButton wrapper />
      <div className="bg-white h-eliminateHeader">
        <Placeholder
          placeholderImage={Images.teaCup}
          placeholderImageClassName={"w-4/12"}
          heading="404 Page Not Found."
          paragraph="The page you are looking for doesn't exist. Go back and choose a new path or"
          buttonText="Go To Homepage"
          onPressButton={() => history.push("/")}
        />
      </div>
    </div>
  );
}

export default PageNotFound;
