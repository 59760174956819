import React from "react";
import { useCosmeticConsultationFlow } from "hooks/useConsultationFlow";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import VisitSummaryContent from "components/visitSummaryAccordion/VisitSummaryContent";
import { cosmeticConsultationReducers } from "reducers/cosmetic/cosmeticConsultationReducer";
import { cosmetic_steps } from "helpers/enum";
import VisitSummaryContentHeading from "components/visitSummaryAccordion/VisitSummaryContentHeading";
import VisitSummaryContentParagraph from "components/visitSummaryAccordion/VisitSummaryContentParagraph";

function CosmeticMedicalHistorySummary({ data, readOnly }) {
  const { incompleteData } = useCosmeticConsultationFlow();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <VisitSummaryContent
      readOnly={readOnly}
      onEditClick={() => {
        dispatch(
          cosmeticConsultationReducers.currentScreen({
            value: cosmetic_steps.allergies,
          })
        );
        history.push("/consultation/cosmetic");
      }}
    >
      <VisitSummaryContentHeading text="Allergies" />
      <VisitSummaryContentParagraph
        text={
          data?.allergies
            ? data?.allergies?.join(", ")
            : incompleteData?.allergies?.join(", ")
        }
      />
    </VisitSummaryContent>
  );
}

export default CosmeticMedicalHistorySummary;
