import React, { useEffect, useState } from "react";

import { requestReceiptAction } from "api/actions/VisitActions";
import Images from "assets/images";
import { useApiMutation } from "hooks/useApiMutation";
import useToastify from "hooks/useToastify";
import { useHistory } from "react-router-dom";
import CSSLoader from "components/cssLoader/CSSLoader";

function ReceiptView({ visitDetails: details }) {
  const { showAlert } = useToastify();
  const history = useHistory();
  const [visitDetails, setVisitDetails] = useState(null);

  useEffect(() => {
    if (details && !details?.error) {
      setVisitDetails(details);
    }
  }, [details]);

  const { mutate: requestReceipt, loading: isLoadingReceipt } = useApiMutation(
    requestReceiptAction
  );

  async function requestReceiptForVisit() {
    try {
      const result = await requestReceipt(details?.id);
      if (result && result?.status === 200) {
        showAlert("Request for receipt has been sent", "success");
        setVisitDetails({ ...details, receipt_generating: true });
      } else {
        showAlert("Failed to request receipt", "error");
      }
    } catch (error) {
      showAlert("Failed to request receipt", "error");
    }
  }

  if (visitDetails?.receipt) {
    return (
      <div className="flex flex-1 items-center p-6">
        <img
          className="w-16 h-16"
          src={Images.checkedGreenFilled}
          alt="checked"
        />
        <div className="pl-9 w-full flex justify-between items-center">
          <a
            href={visitDetails?.receipt?.url}
            target="_blank"
            rel="noreferrer"
            className="font-medium text-2xl underline cursor-pointer"
          >
            Copy of your receipt
          </a>
          <button
            className="font-medium text-2xl underline"
            onClick={() => {
              history.push(`/visit-details/${visitDetails?.id}/visit-summary`, {
                visitDetails: visitDetails,
              });
            }}
          >
            Visit Summary
          </button>
        </div>
      </div>
    );
  } else if (visitDetails?.receipt_generating) {
    return (
      <div className="flex-1 p-6">
        <div className="w-full flex justify-between items-center">
          <p className="font-light text-2xl">
            Thank you. Your receipt is being generated. You can download it here
            once it is available.
          </p>
          <button
            className="font-medium text-2xl underline"
            onClick={() => {
              history.push(`/visit-details/${visitDetails?.id}/visit-summary`, {
                visitDetails: visitDetails,
              });
            }}
          >
            Visit Summary
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-1 items-center p-6">
        <img
          className="w-16 h-16"
          src={Images.exclaimationIcon}
          alt="checked"
        />
        <div className="pl-9 w-full flex justify-between items-center">
          <button
            disabled={isLoadingReceipt ? "true" : ""}
            className={`px-2 py-3 bg-orange ${
              isLoadingReceipt
                ? "bg-opacity-60 cursor-not-allowed"
                : "bg-opacity-100 cursor-pointer"
            } rounded flex items-center justify-center self-start md:px-5 md:py-3`}
            onClick={requestReceiptForVisit}
          >
            {isLoadingReceipt ? (
              <CSSLoader color={"white"} className="w-8" />
            ) : null}
            <p className="text-xl font-bold text-white">Request Receipt</p>
          </button>
          <button
            className="font-medium text-2xl underline"
            onClick={() => {
              history.push(`/visit-details/${visitDetails?.id}/visit-summary`, {
                visitDetails: visitDetails,
              });
            }}
          >
            Visit Summary
          </button>
        </div>
      </div>
    );
  }
}

export default ReceiptView;
