import { useCallback } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function useToastify() {
  const showAlert = useCallback((message, type, duration) => {
    const isMessageString = typeof message;
    switch (type) {
      case "success":
        toast.success(isMessageString ? message : "Success.", {
          autoClose: duration ?? 3000,
        });
        break;
      case "error":
        toast.error(isMessageString ? message : "Something went wrong.", {
          autoClose: duration ?? 3000,
        });
        break;
      case "warning":
        toast.warn(isMessageString ? message : "Warning.", {
          autoClose: duration ?? 3000,
        });
        break;
      case "info":
        toast.info(message, {
          autoClose: duration ?? 3000,
        });
        break;
      default:
        toast(message, {
          autoClose: duration ?? 3000,
        });
        break;
    }
  }, []);

  return { showAlert };
}

export default useToastify;
