import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import SearchInputWithChipCard from "components/searchInputWithChip/SearchInputWithChipCard";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import VisitSubQuestionSlideUpContainer from "components/visitSubQuestionSlideUp/VisitSubQuestionSlideUpContainer";
import { dermatology_sub_steps } from "helpers/enum";
import scrollToBottom from "helpers/scrollToBottom";
import useModal from "hooks/useModal";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import useVisitStepChange from "hooks/useVisitStepChange";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import HistoryDebounceSearchModal from "./HistoryDebounceSearchModal";

function Medications() {
  const { setShowModal } = useModal();
  const [selected, setSelected] = useState(null);
  const [selectedMedications, setSelectedMedications] = useState([]);

  const dispatch = useDispatch();
  const { incompleteData, subSteps } = useDermatologyVisitFlow();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore =
    subSteps[dermatology_sub_steps.medications].value;

  const { updateVisit, updatingVisit } = useUpdateDermatologyVisit();

  useEffect(() => {
    if (currentScreenValueFromStore?.length) {
      if (
        currentScreenValueFromStore?.includes("None") &&
        currentScreenValueFromStore?.length === 1
      ) {
        setSelected("No");
        setSelectedMedications([]);
      } else {
        setSelected("Yes");
        setSelectedMedications(currentScreenValueFromStore);
      }
    }
  }, [currentScreenValueFromStore]);

  useEffect(() => {
    if (selected === "No") {
      dispatch(
        dermaVisitFlowReducers.medications({
          isValid: true,
        })
      );
    } else if (selected === "Yes") {
      scrollToBottom();
      if (selectedMedications?.length) {
        dispatch(
          dermaVisitFlowReducers.medications({
            isValid: true,
          })
        );
      } else {
        dispatch(
          dermaVisitFlowReducers.medications({
            isValid: false,
          })
        );
      }
    }
  }, [dispatch, selected, selectedMedications]);

  function onRightButtonClick(_, nextAnimation) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          medications:
            selected === "Yes"
              ? selectedMedications?.map((el) => {
                  if (el?.DispensableDrugId) {
                    return {
                      name: el?.name,
                      DispensableDrugId: el?.DispensableDrugId,
                      RoutedDoseFormDrugId: el?.RoutedDoseFormDrugId,
                      NDC: el?.NDC,
                      RxCUI: el?.RxCUI || null,
                      Strength: el?.Strength || null,
                      IsObsolete: el?.IsObsolete,
                    };
                  }

                  return {
                    name: el?.name,
                  };
                })
              : [{ name: "None" }],
          dermatology_steps: incompleteData?.dermatology_steps,
        },
      },
      change_current_screen: true,
      screen_to_complete: dermatology_sub_steps.medications,
      callBack: () => {
        dispatch(
          dermaVisitFlowReducers.medications({
            value: selected === "Yes" ? selectedMedications : ["None"],
          })
        );
        nextAnimation();
      },
    });
  }
  return (
    <StepScreenContainer align="start">
      <MedicalAssistant
        content={
          <p>Are you taking any prescribed or over-the-counter medications?</p>
        }
      />
      <Card>
        <RadioButtonCard
          loading={updatingVisit}
          options={["Yes", "No"]}
          selected={selected}
          onSelect={setSelected}
        />
      </Card>
      {selected === "Yes" ? (
        <VisitSubQuestionSlideUpContainer>
          <Spacer height={40} />
          <SearchInputWithChipCard
            inputLabel={"Search Medication"}
            onClickInput={() =>
              setShowModal({
                state: true,
                children: (_, onClose) => {
                  return (
                    <HistoryDebounceSearchModal
                      keyToSearch={"medications"}
                      title={"Select Medications"}
                      onClose={onClose}
                      selected={selectedMedications}
                      setSelected={setSelectedMedications}
                    />
                  );
                },
                data: null,
              })
            }
            selected={selectedMedications}
            setSelected={setSelectedMedications}
          />
        </VisitSubQuestionSlideUpContainer>
      ) : null}
    </StepScreenContainer>
  );
}

export default Medications;
