import React, { useEffect, useRef, useState } from "react";
import CheckBoxCard from "components/checkBoxCard/CheckBoxCard";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import { allSymptomsData } from "./symptomsData";
import VisitSubQuestionSlideUpContainer from "components/visitSubQuestionSlideUp/VisitSubQuestionSlideUpContainer";
import { Form, Formik } from "formik";
import Input from "components/inputs/Input";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import scrollToBottom from "helpers/scrollToBottom";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import useVisitStepChange from "hooks/useVisitStepChange";
import { dermatology_sub_steps } from "helpers/enum";
import { OtherSymptomsSchema } from "helpers/validationSchema";
import FormikErrorFocus from "formik-error-focus";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";

function SymptomsForDerm() {
  const formRef = useRef();
  const nextAnimationRef = useRef();

  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [otherValue, setOtherValue] = useState("");
  const { incompleteData, subSteps } = useDermatologyVisitFlow();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore =
    subSteps[dermatology_sub_steps.symptoms].value;

  const { updateVisit } = useUpdateDermatologyVisit();

  useEffect(() => {
    if (currentScreenValueFromStore) {
      let formedSymptoms = [];
      const symptomsResult = currentScreenValueFromStore.filter((el) => {
        return allSymptomsData?.find((elem) => {
          return el === elem;
        });
      });
      const otherResult = currentScreenValueFromStore.find((el) => {
        return !allSymptomsData?.find((elem) => {
          return el === elem;
        });
      });
      formedSymptoms = symptomsResult;
      if (otherResult) {
        formedSymptoms.push("Other");
        setOtherValue(otherResult);
        scrollToBottom();
      }
      setSelected(formedSymptoms);
    }
  }, [currentScreenValueFromStore]);

  useEffect(() => {
    if (selected?.length) {
      dispatch(
        dermaVisitFlowReducers.symptoms({
          isValid: true,
        })
      );
    } else {
      dispatch(
        dermaVisitFlowReducers.symptoms({
          isValid: false,
        })
      );
    }
  }, [dispatch, selected]);

  function updateSymptomsApi(symptomData) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          symptoms: symptomData,
          dermatology_steps: incompleteData?.dermatology_steps,
        },
      },
      change_current_screen: true,
      screen_to_complete: dermatology_sub_steps.symptoms,
      callBack: () => {
        dispatch(
          dermaVisitFlowReducers.symptoms({
            value: symptomData,
          })
        );
        nextAnimationRef?.current();
      },
    });
  }

  function onRightButtonClick(_, nextAnimation) {
    nextAnimationRef.current = nextAnimation;
    if (selected?.includes("Other")) {
      formRef?.current?.handleSubmit();
    } else {
      updateSymptomsApi(selected);
    }
  }

  function onFormSubmit(values) {
    updateSymptomsApi([
      ...selected?.filter((el) => el !== "Other"),
      values?.other_symptom,
    ]);
  }

  function handleOnSelect(el) {
    if (el === "Other") {
      scrollToBottom();
    }
    if (el === "None") {
      setSelected([el]);
    } else {
      let final = [...selected];

      if (final?.includes("None")) {
        final = final.filter((elem) => elem !== "None");
      }
      if (final?.includes(el)) {
        setSelected(final.filter((elem) => elem !== el));
      } else {
        setSelected([...final, el]);
      }
    }
  }

  return (
    <StepScreenContainer>
      <MedicalAssistant
        content={<p>Please select all the symptoms that apply.</p>}
      />
      <Card>
        <CheckBoxCard
          options={allSymptomsData}
          selected={selected}
          onSelect={handleOnSelect}
        />
      </Card>
      {selected?.includes("Other") ? (
        <VisitSubQuestionSlideUpContainer>
          <Spacer height={40} />
          <Card>
            <Formik
              onSubmit={onFormSubmit}
              innerRef={formRef}
              validationSchema={OtherSymptomsSchema}
              initialValues={{
                other_symptom: otherValue ?? "",
              }}
            >
              {() => (
                <Form>
                  <Input
                    id={"other_symptom"}
                    name={"other_symptom"}
                    label="Other symptom"
                  />
                  <FormikErrorFocus
                    align={"bottom"}
                    ease={"linear"}
                    duration={500}
                  />
                </Form>
              )}
            </Formik>
          </Card>
        </VisitSubQuestionSlideUpContainer>
      ) : null}
    </StepScreenContainer>
  );
}

export default SymptomsForDerm;
