import Images from "assets/images";
import React, { useRef } from "react";
import { isDesktop } from "react-device-detect";
import useTextCompleteVisitToMobile from "hooks/useTextCompleteVisitToMobile";
import { trackEvent } from "helpers/analytics";

function ConfirmIdentityImagePicker({ handleChange, imageUrl }) {
  const { alertPatient } = useTextCompleteVisitToMobile();
  const imagePickerRef = useRef(null);

  const alertDesktopPatient = () => {
    trackEvent("add_image_identity_con");
    if (isDesktop) {
      alertPatient({
        message:
          "It looks like you're on a device that does not have camera access. You will be asked to upload your photo ID now. Do you want to use your smartphone to take photo? We'll text you a link to complete your visit on your smartphone.",
        heading: "Use your smartphone to take photos?",
        openImagePicker: () => imagePickerRef.current.click(),
      });
    } else {
      imagePickerRef.current.click();
    }
  };

  return (
    <>
      <input
        ref={imagePickerRef}
        id="confirm_identity_image_picker"
        type="file"
        accept="image/png, image/jpg, image/jpeg"
        onChange={handleChange}
        className="hidden"
      />
      <label
        htmlFor="confirm_identity_image_picker"
        className="relative flex items-center justify-center bg-white border-2 cursor-pointer w-60 h-60 rounded-3xl border-orange"
      >
        <button className="w-full h-full" onClick={alertDesktopPatient}>
          {imageUrl?.length ? (
            <img
              className="object-contain w-60 h-60 rounded-3xl"
              src={imageUrl}
              alt="identity_image"
            />
          ) : null}
          {imageUrl?.length ? null : (
            <img
              className="absolute w-16 h-16 -mt-8 -ml-8 top-1/2 left-1/2"
              src={Images.addIcon}
              alt="add_image"
            />
          )}
          {imageUrl?.length ? (
            <img
              className="absolute w-16 h-16 shadow-2xl -bottom-3 -right-3"
              src={Images.editIcon}
              alt="add_image"
            />
          ) : null}
        </button>
      </label>
    </>
  );
}

export default ConfirmIdentityImagePicker;
