import { cosmetic_steps } from "helpers/enum";
import CosmeticSelectCondition from "./CosmeticSelectCondition";
import CosmeticAllergies from "./CosmeticAllergies";
import CosmeticAppointment from "./CosmeticAppointment";
import CosmeticPrevTreatmentFeedback from "./CosmeticPrevTreatmentFeedback";
import CosmeticUploadPhotos from "./CosmeticUploadPhotos";

export const getCosmeticStepScreen = (currentScreen) => {
  switch (currentScreen) {
    case cosmetic_steps.select_condition:
      return <CosmeticSelectCondition />;
    case cosmetic_steps.upload_photos:
      return <CosmeticUploadPhotos />;
    case cosmetic_steps.allergies:
      return <CosmeticAllergies />;
    case cosmetic_steps.select_slot:
      return <CosmeticAppointment />;
    case cosmetic_steps.previous_treatment:
      return <CosmeticPrevTreatmentFeedback />;
    default:
      break;
  }
};
