import React from "react";

function DateChip({
  day,
  date,
  isLast,
  selected,
  onClick,
  numberOfSlots = null,
}) {
  return (
    <div
      onClick={onClick}
      className={`transition duration-300 relative border rounded-lg flex flex-col items-center justify-center py-4 min-w-1/10 ${
        !isLast ? "mr-1" : ""
      } cursor-pointer ${
        selected ? "bg-orange text-white border-orange" : "border-borderColor"
      }`}
    >
      <p className={`font-black text-2xl uppercase`}>{day}</p>
      <p className={`font-medium`}>{date}</p>
      {numberOfSlots ? (
        <p className={`font-bold ${selected ? "text-white" : "text-orange"}`}>
          Available Slots: {numberOfSlots}
        </p>
      ) : (
        <div />
      )}
      {selected ? (
        <div className="absolute w-6 h-6 bg-white -bottom-4 transform rotate-45" />
      ) : null}
    </div>
  );
}

export default DateChip;
