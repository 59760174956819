import { GetServiceName } from "helpers/servicesSteps";
import ProgressStepperCard from "./Progress";
import { useHistory } from "react-router-dom";
import IncompleteProgressStepper from "./IncompleteProgressStepper";
import { useDispatch } from "react-redux";
import {
  currentSpeciality,
  updateVisitMessagePayload,
} from "reducers/currentUserReducer";
import { getRouteBySpecialty } from "helpers/functions";
import { Icon } from "stories/Icons";
import classNames from "classnames";
import EvalutatedSummary from "./EvaluatedSummaryCard";
import RefillRequest from "./otcFlow/RefillRequest";
import { useEffect, useState } from "react";
import EvaluatedAccordion from "./EvaluatedAccordion";
import VisitAccordion from "./VisitAccordion";
import { useApiQuery } from "hooks/useApiQuery";
import {
  getRevisedInsuranceAction,
  requestReceiptAction,
  visitDetailsAction,
} from "api/actions/VisitActions";
import { useApiMutation } from "hooks/useApiMutation";
import useToastify from "hooks/useToastify";
import CSSLoader from "components/cssLoader/CSSLoader";
import ButtonDropDown from "stories/ButtonDropdown";
const GetVisitStatus = (speciality, status, details) => {
  const nextStepName = (() => {
    // Find the last item with status: true
    const lastValidDetail = details
      ?.slice()
      ?.reverse()
      ?.find((detail) => detail.status === true);

    if (!lastValidDetail) {
      return null;
    }

    // Get the index of the last valid detail
    const index = details?.indexOf(lastValidDetail);

    // Get the next item in the array
    const nextDetail = details[index + 1] || null;

    // Return the step_name of the next item
    return nextDetail ? nextDetail.step_name : null;
  })();

  //nextStepName refers to present in progress Step
  switch (status) {
    case "pending":
      if (nextStepName === "Doctor reviewed") {
        return `${GetServiceName(
          speciality
        )} Status: MA reviewed, Doctor reviewing`;
      } else if (nextStepName === "Treatment plan ready") {
        return `${GetServiceName(
          speciality
        )} Status: Diagnosis complete, Treatment plan in-progress`;
      } else {
        return `${GetServiceName(
          speciality
        )} Status: Visit sumbitted, MA reviewing`;
      }
    case "evaluated":
      return `${GetServiceName(speciality)} Status: Evaluated`;
    case "rejected":
      return `${GetServiceName(speciality)} Status: Rejected`;
    case "incomplete":
      return `${GetServiceName(speciality)} Status: Partially Completed`;
    case 10: //Price Revision //Pending
      return `${GetServiceName(
        speciality
      )} Status: Standby. Price revision, please verify`;
    default:
      return `${GetServiceName(
        speciality
      )} Status: Visit sumbitted, MA reviewing`;
  }
};
const GetCardProgress = (
  status,
  speciality,
  progress,
  practiceUserDetails,
  id
) => {
  switch (status) {
    case "pending":
      return <ProgressStepperCard progress={progress} id={id} />;
    case "evaluated":
      return <EvalutatedSummary practiceUserDetails={practiceUserDetails} />;
    case "rejected":
      return <div />;
    case "incomplete":
      return (
        <IncompleteProgressStepper
          steps={progress}
          speciality={speciality}
          customWidth={false}
          id={id}
        />
      );
    default:
      return <div />;
  }
};

const OnGoingVisitsCard = ({
  id,
  title = "Derma Visit",
  speciality,
  status,
  time,
  progress,
  messageCount,
  messages,
  canMessage,
  practiceUserDetails,
  refillRequestSentAt,
  diagnosisSentAt,
  receipt,
  currentStatus,
  receiptGenerating,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [refillRequested, setRefillRequested] = useState(false);
  const {
    payload: revisedInsuranceDetails,
    query: getRevisedInsuranceDetails,
  } = useApiQuery(getRevisedInsuranceAction(id), false);

  useEffect(() => {
    if (currentStatus === 10) {
      getRevisedInsuranceDetails();
    }
  }, [currentStatus, getRevisedInsuranceDetails]);

  const {
    loading: fetchingVisitDetails,
    payload: visitDetails,
    query: queryVisitDetails,
  } = useApiQuery(visitDetailsAction(id), false);
  function redirectIncompleteVisits(spec) {
    const speciality = {
      dermatology: 1,
      primary_care: 2,
      cosmetic: 3,
      ped: 4,
      pediatric: 4,
    };
    const route = getRouteBySpecialty(spec);
    dispatch(
      currentSpeciality({
        currentSpeciality: speciality[spec],
      })
    );
    return history.push(route);
  }

  function onMessageClick() {
    if (messages?.length > 0) {
      dispatch(
        updateVisitMessagePayload({
          can_message: canMessage,
          unread_provider_messages: messageCount,
          messages: messages,
        })
      );
      history.push(`/chat/${id}`, {
        speciality: speciality,
      });
    }
  }

  function onRevisedPaymentClick() {
    const specialityRoute = {
      dermatology: "dermatology",
      primary_care: "primary-care",
      cosmetic: null,
      ped: "pediatrics",
      pediatric: "pediatrics",
    };

    const data = {
      practiceInsurance: revisedInsuranceDetails?.practice_insurance,
      revisedInsuranceDetails:
        revisedInsuranceDetails?.revised_insurance_line_items,
      providerName: visitDetails?.provider_name,
    };

    const redirectPath = `/visit/${specialityRoute[speciality]}/accept-price-revision`;

    localStorage.setItem("last_revision", redirectPath);
    localStorage.setItem("last_revision_data", JSON.stringify(data));

    history.push(redirectPath, data);
  }
  const isRevised =
    currentStatus === 10 &&
    !revisedInsuranceDetails?.revised_insurance_line_items
      ?.price_change_confirmed_at;

  const MSGButton = ({ showBorder = true, smallIcon }) => {
    //Small Icon will be used for Rejected Status in Mobile
    return (
      messages?.length > 0 &&
      (smallIcon ? (
        <button
          className={classNames(
            "w-full relative md:w-min flex items-center p-3 rounded-full focus:ring-2 focus:ring-secondaryBlue-500",
            showBorder && "border",
            canMessage
              ? "text-secondaryBlue-500 border-secondaryBlue-500"
              : "text-grey-600 "
          )}
          onClick={() => onMessageClick()}
        >
          <Icon name={"message"} />
          {messageCount > 0 && (
            <div
              className={`absolute top-0 right-0 w-6 h-6 -mt-2 -mr-2  rounded-full bg-errorRed text-white font-bold text-sm items-center justify-center flex`}
            >
              {messageCount}
            </div>
          )}
        </button>
      ) : (
        <button
          className={classNames(
            "w-full md:w-min flex items-center px-8 py-4 md:py-3 space-x-2 text-xl font-bold  rounded-md hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-secondaryBlue-500 focus:border-transparent",
            showBorder && "border",
            canMessage
              ? "text-secondaryBlue-500 border-secondaryBlue-500"
              : "text-grey-600 "
          )}
          onClick={() => onMessageClick()}
        >
          <Icon name={"message"} />
          <div>Message</div>
          {messageCount > 0 && (
            <div
              className={`rounded-full w-6 h-6 text-sm flex items-center justify-center bg-errorRed text-white `}
            >
              {messageCount}
            </div>
          )}
        </button>
      ))
    );
  };
  const RevisedPayButton = ({ showBorder = true }) => {
    return (
      <button
        className={classNames(
          "w-full px-2 py-4 bg-white space-x-2 border-orange text-orange hover:bg-orange hover:text-white bg-opacity-100 rounded-md flex items-center justify-center self-start md:px-5 md:py-3",
          showBorder && "border"
        )}
        onClick={onRevisedPaymentClick}
      >
        <Icon name={"info"} />
        <p className="text-xl font-bold">Revised Payment Details</p>
      </button>
    );
  };

  const dropDownProps = {
    icon: (
      <div className="relative">
        <Icon name="dropdown" />
        {(messageCount > 0 || isRevised) && (
          <div className="absolute top-0 right-0 w-5 h-5 -mt-0.5 -mr-1 rounded-full bg-errorRed" />
        )}
      </div>
    ),
    childComponent: (
      <div>
        <div className="flex flex-col bg-white">
          <MSGButton showBorder={false} />
          {messageCount > 0 && <div className="w-full h-0.5 bg-gray-300" />}
          {isRevised && <RevisedPayButton showBorder={false} />}
        </div>
      </div>
    ),
    childClass: "w-20r",
  };

  return (
    <div className="flex-col font-medium bg-white rounded-xl" key={id}>
      <div className="flex-col pb-6 mx-4 space-y-4 font-medium md:mx-0 md:pl-4">
        <div className="flex items-center justify-between">
          <h3 className="pr-4 text-2xl font-bold md:text-3xl">{title}</h3>
          {(messages?.length > 0 ||
            isRevised ||
            status === "evaluated" ||
            status === "incomplete") && (
            <div className="md:pr-4">
              <div className="hidden space-x-6 md:flex">
                {status === "incomplete" && (
                  <button
                    onClick={() => redirectIncompleteVisits(speciality)}
                    className="px-8 py-3 text-xl font-semibold text-center no-underline border rounded-lg text-orange hover:text-white hover:bg-orange border-orange"
                  >
                    Proceed with consultation
                  </button>
                )}
                <MSGButton />
                {isRevised && <RevisedPayButton />}
                {status === "evaluated" && (
                  <RefillRequest
                    id={id}
                    refillRequested={refillRequested}
                    setRefillRequested={setRefillRequested}
                    refillRequestSentAt={refillRequestSentAt}
                    diagnosisSentAt={diagnosisSentAt}
                    className={"bg-white border border-orange !text-orange"}
                  />
                )}
              </div>
              {status !== "incomplete" && (
                <div className="space-x-6 md:hidden">
                  {!isRevised && messages?.length > 0 ? (
                    <MSGButton smallIcon="true" />
                  ) : (
                    <ButtonDropDown {...dropDownProps} />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {time && (
          <div>
            <p className="pr-4 text-2xl md:mt-2 text-themeBlack">{time}</p>
          </div>
        )}
        <div>
          <p
            onClick={() =>
              status === "rejected"
                ? history.push(`/visit-details/${id}`)
                : null
            }
            className={`text-2xl pr-4 font-bold md:text-2.5xl ${
              status === "evaluated"
                ? "text-successGreen"
                : status === "rejected" || currentStatus === 10
                ? "text-errorRed cursor-pointer"
                : "text-orange"
            }`}
          >
            {GetVisitStatus(
              speciality,
              currentStatus === 10 ? 10 : status,
              progress?.details
            )}
          </p>
        </div>
        {GetCardProgress(status, speciality, progress, practiceUserDetails, id)}
        {status === "evaluated" && (
          <Receipt
            id={id}
            receipt={receipt}
            receiptGenerating={receiptGenerating}
          />
        )}
      </div>
      {(status === "pending" || status === "evaluated") && (
        <div className="border-t">
          {status === "evaluated" && (
            <EvaluatedAccordion
              speciality={speciality}
              details={visitDetails}
              loading={fetchingVisitDetails}
              queryDetails={queryVisitDetails}
            />
          )}
          <VisitAccordion
            speciality={speciality}
            details={visitDetails}
            loading={fetchingVisitDetails}
            queryDetails={queryVisitDetails}
          />
        </div>
      )}
      {(status === "incomplete" || status === "evaluated") && (
        <div className="w-full md:hidden p-2.5">
          {status === "incomplete" && (
            <button
              onClick={() => redirectIncompleteVisits(speciality)}
              className="w-full h-full px-8 py-4 text-2.5xl md:text-xl font-semibold text-center no-underline border rounded-lg text-orange hover:text-white hover:bg-orange border-orange"
            >
              Proceed with consultation
            </button>
          )}
          {status === "evaluated" && (
            <RefillRequest
              id={id}
              refillRequested={refillRequested}
              setRefillRequested={setRefillRequested}
              refillRequestSentAt={refillRequestSentAt}
              diagnosisSentAt={diagnosisSentAt}
              className={
                "w-full py-4 text-2.5xl bg-white border border-orange !text-orange"
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

const Receipt = ({ id, receipt, receiptGenerating }) => {
  const [generating, setGenerating] = useState(receiptGenerating ?? false);
  const { showAlert } = useToastify();
  const { mutate: requestReceipt, loading: isLoadingReceipt } =
    useApiMutation(requestReceiptAction);
  async function requestReceiptForVisit() {
    try {
      const result = await requestReceipt(id);
      if (result && result?.status === 200) {
        showAlert("Request for receipt has been sent", "success");
        setGenerating(true);
      } else {
        showAlert("Failed to request receipt", "error");
      }
    } catch (error) {
      showAlert("Failed to request receipt", "error");
    }
  }
  if (receipt) {
    return (
      <div className="py-1 text-2.5xl font-medium ">
        <a
          href={receipt?.url}
          target="_blank"
          rel="noreferrer"
          className="underline cursor-pointer text-secondaryBlue-500"
        >
          Copy of your receipt
        </a>
      </div>
    );
  } else if (generating) {
    return (
      <div className="py-1 text-xl md:text-2.5xl font-medium ">
        <p className="text-themeBlack">
          Thank you. Your receipt is being generated. You can download it here
          once it is available
        </p>
      </div>
    );
  } else {
    return (
      <div className="py-1 text-2.5xl font-medium ">
        <button
          disabled={isLoadingReceipt ? "true" : ""}
          className={`px-2 py-3 border hover:bg-orange hover:text-white rounded-md border-orange text-orange ${
            isLoadingReceipt
              ? "bg-opacity-60 cursor-not-allowed"
              : "bg-opacity-100 cursor-pointer"
          } rounded flex items-center justify-center self-start md:px-5 md:py-3`}
          onClick={requestReceiptForVisit}
        >
          {isLoadingReceipt ? (
            <CSSLoader color={"white"} className="w-8" />
          ) : null}
          <p className="text-xl font-bold">Request Receipt</p>
        </button>
      </div>
    );
  }
};
export default OnGoingVisitsCard;
