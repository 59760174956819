import React from "react";
import { usePrimaryCareVisitFlow } from "hooks/useVisitFlow";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import VisitSummaryContent from "components/visitSummaryAccordion/VisitSummaryContent";
import { primaryCareVisitFlowReducers } from "reducers/primary_care/primaryCareVisitFlowReducer";
import { primary_care_sub_steps } from "helpers/enum";
import VisitSummaryContentHeading from "components/visitSummaryAccordion/VisitSummaryContentHeading";
import VisitSummaryContentParagraph from "components/visitSummaryAccordion/VisitSummaryContentParagraph";
import Spacer from "components/Spacer/Spacer";

function AdditionalInfoPCSummary({ readOnly, data }) {
  const { incompleteData } = usePrimaryCareVisitFlow();
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <VisitSummaryContent
      readOnly={readOnly}
      onEditClick={() => {
        dispatch(
          primaryCareVisitFlowReducers.currentScreen({
            value: primary_care_sub_steps.additional_info,
          })
        );
        history.push("/visit/primary-care/new-visit");
      }}
    >
      {data?.primary_care_symptoms?.length ||
      incompleteData?.primary_care_symptoms?.length ? (
        <>
          <VisitSummaryContentHeading text="Symptoms" />
          {data?.primary_care_symptoms
            ? data?.primary_care_symptoms?.map((el, index) => {
                return (
                  <VisitSummaryContentParagraph
                    key={index}
                    text={`${el?.category} : ${el?.symptoms
                      ?.map((elem) => elem)
                      ?.join(", ")}`}
                  />
                );
              })
            : incompleteData?.primary_care_symptoms?.map((el, index) => {
                return (
                  <VisitSummaryContentParagraph
                    key={index}
                    text={`${el?.category} : ${el?.symptoms
                      ?.map((elem) => elem)
                      ?.join(", ")}`}
                  />
                );
              })}
          <Spacer height={20} />
        </>
      ) : null}
      <VisitSummaryContentHeading text="Additional Details" />
      <VisitSummaryContentParagraph
        text={
          data?.additional_info?.length ||
          incompleteData?.additional_info?.length
            ? data?.additional_info?.length
              ? data?.additional_info
              : incompleteData?.additional_info
            : "-None-"
        }
      />
      {data?.additional_info_recording?.length ||
      incompleteData?.additional_info_recording?.length ? (
        <>
          <Spacer height={20} />
          <VisitSummaryContentHeading text="Recorded Audio" />
          <Spacer height={20} />
          <audio
            controls
            src={
              data?.additional_info_recording?.length
                ? data?.additional_info_recording
                : incompleteData?.additional_info_recording
            }
            controlsList="nodownload"
          />
        </>
      ) : null}
    </VisitSummaryContent>
  );
}

export default AdditionalInfoPCSummary;
