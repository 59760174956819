import Button from "components/buttons/Button";
import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import SearchInputWithChipCard from "components/searchInputWithChip/SearchInputWithChipCard";
import Spacer from "components/Spacer/Spacer";
import { ped_sub_steps } from "helpers/enum";
import useModal from "hooks/useModal";
import useToastify from "hooks/useToastify";
import React from "react";
import HistoryDebounceSearchModal from "../../dermatology/medicalHistory/HistoryDebounceSearchModal";

function Medications({
  incompleteData,
  updateVisit,
  updatingVisit,
  setActiveIndex,
  selected,
  setSelected,
  selectedMedications,
  setSelectedMedications,
}) {
  const { showAlert } = useToastify();
  const { setShowModal } = useModal();

  function onRightButtonClick(_, nextAnimation) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          allergies: incompleteData?.allergies_json,
          medications:
            selected === "None"
              ? []
              : selectedMedications?.map((el) => {
                  if (el?.DispensableDrugId) {
                    return {
                      name: el?.name,
                      DispensableDrugId: el?.DispensableDrugId,
                      RoutedDoseFormDrugId: el?.RoutedDoseFormDrugId,
                      NDC: el?.NDC,
                      RxCUI: el?.RxCUI || null,
                      Strength: el?.Strength || null,
                      IsObsolete: el?.IsObsolete,
                    };
                  }

                  return {
                    name: el?.name,
                  };
                }),
          pediatric_second_phase_steps: {
            ...incompleteData?.pediatric_second_phase_steps,
            [ped_sub_steps.medications]: "completed",
          },
        },
      },
      change_current_screen: false,
      screen_to_complete: null,
      callBack: () => {
        showAlert("Progress Saved Successfully", "success");
        setActiveIndex(9);
      },
    });
  }
  return (
    <div className="bg-white shadow-card rounded-b-4xl p-10 relative overflow-x-hidden">
      <Spacer height={20} />
      <RadioButtonCard
        loading={updatingVisit}
        options={["None"]}
        selected={selected}
        onSelect={setSelected}
      />
      <Spacer height={20} />
      <SearchInputWithChipCard
        inputLabel={"Search Medication"}
        onClickInput={() => {
          setSelected(null);
          setShowModal({
            state: true,
            children: (_, onClose) => {
              return (
                <HistoryDebounceSearchModal
                  keyToSearch={"medications"}
                  title={"Select Medications"}
                  onClose={onClose}
                  selected={selectedMedications}
                  setSelected={setSelectedMedications}
                />
              );
            },
            data: null,
          });
        }}
        selected={selectedMedications}
        setSelected={setSelectedMedications}
      />
      <Button
        className="mt-10 py-4"
        disabled={selected === null ? selectedMedications.length === 0 : false}
        loading={updatingVisit}
        label="Next"
        onClick={onRightButtonClick}
      />
    </div>
  );
}

export default Medications;
