import Images from "assets/images";
import { health_profile_status } from "helpers/enum";
import React from "react";

const ListItemWrapper = ({ itemName, status, onClick, children }) => (
  <div
    onClick={onClick}
    className="py-8 border-b border-borderColor cursor-pointer"
  >
    <div className="flex items-center justify-between">
      <div>
        <p className="text-3xl font-medium">{itemName}</p>
        {status ? (
          <p
            className={`pt-2 text-xl font-light ${
              status === health_profile_status.completed
                ? "text-green"
                : "text-orange"
            }`}
          >
            {status}
          </p>
        ) : null}
      </div>
      <img className="w-8" src={Images.arrowRight} alt="right_arrow" />
    </div>
    {children}
  </div>
);

export default ListItemWrapper;
