import { dermatology_main_steps } from "helpers/enum";

export const dermaMainStepsReducer = {
  basic_info(state, action) {
    const { currentScreen, status } = action?.payload;
    if (status) {
      state.mainSteps[dermatology_main_steps.basic_info].status = status;
    }
    if (currentScreen) {
      state.mainSteps[
        dermatology_main_steps.basic_info
      ].currentScreen = currentScreen;
    }
  },
  main_symptoms(state, action) {
    const { status } = action?.payload;
    state.mainSteps[dermatology_main_steps.main_symptoms].status = status;
  },
  visit_details(state, action) {
    const { status } = action?.payload;
    state.mainSteps[dermatology_main_steps.visit_details].status = status;
  },
  photos(state, action) {
    const { status } = action?.payload;
    state.mainSteps[dermatology_main_steps.photos].status = status;
  },
  main_medical_history(state, action) {
    const { status } = action?.payload;
    state.mainSteps[
      dermatology_main_steps.main_medical_history
    ].status = status;
  },
};
