import SubHeading from "../SubHeading";
import TextWithBulletPoint from "../TextWithBulletPoint";

export const getAllergiesView = (payload) => {
  const values = {
    drug_allergies: payload?.drug_allergies?.length
      ? payload?.drug_allergies
      : null,
    other_allergies: payload?.other_allergies?.length
      ? payload?.other_allergies
      : null,
  };
  if (Object.keys(values)?.some((el) => values?.[el] !== null)) {
    return (
      <div className="p-8 bg-alternativeWhite border border-borderColor mt-8 rounded-xl">
        {values?.drug_allergies ? (
          <>
            <SubHeading text={"DRUG / MEDICATION ALLERGY"} className="mb-4" />
            {values?.drug_allergies?.map((el, idx) => {
              return (
                <div className="mb-4" key={idx}>
                  <TextWithBulletPoint
                    text={`${el?.drug_name}, ${el?.reaction}`}
                  />
                </div>
              );
            })}
          </>
        ) : null}
        {values?.other_allergies ? (
          <>
            <SubHeading
              text={"ENVIRONMENTAL / FOOD ALLERGY"}
              className="mb-4"
            />
            {values?.other_allergies?.map((el, idx) => {
              return (
                <div className="mb-4" key={idx}>
                  <TextWithBulletPoint
                    text={`${el?.allergic_to}, ${el?.reaction}`}
                  />
                </div>
              );
            })}
          </>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};
