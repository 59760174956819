import { useHistory } from "react-router-dom";
import { updateCase } from "api/actions/VisitActions";
import { dermatology_sub_steps, speciality, step_status } from "helpers/enum";
import { setDermatologyStepStatus } from "helpers/setDermatologyStepStatus";
import { useApiMutation } from "hooks/useApiMutation";
import useToastify from "hooks/useToastify";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import useVisitStepChange from "hooks/useVisitStepChange";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import useIncompleteData from "./useIncompleteData";

function useUpdateDermatologyVisit() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showAlert } = useToastify();
  const { setLoading } = useVisitStepChange();
  const {
    currentScreen,
    subSteps,
    incompleteData,
    completedProgress,
  } = useDermatologyVisitFlow();
  const { mutate: callUpdateCase, loading: updatingVisit } = useApiMutation(
    updateCase
  );
  const { fetchIncompleteData, fetchingIncompleteData } = useIncompleteData();

  useEffect(() => {
    if (updatingVisit || fetchingIncompleteData) {
      setLoading(true);
    } else if (!fetchingIncompleteData && !updatingVisit) {
      setLoading(false);
    }
  }, [updatingVisit, setLoading, fetchingIncompleteData]);

  const constant_keys = {
    affected_body_parts:
      subSteps?.[dermatology_sub_steps.select_affected_part]?.value ?? [],
    allergies: subSteps?.[dermatology_sub_steps.allergies]?.value
      ? typeof subSteps?.[dermatology_sub_steps.allergies]?.value[0] ===
        "string"
        ? subSteps?.[dermatology_sub_steps.allergies]?.value?.map((allergy) => {
            return { name: allergy };
          })
        : subSteps?.[dermatology_sub_steps.allergies]?.value?.map((allergy) => {
            return allergy;
          })
      : subSteps?.[dermatology_sub_steps.allergies]?.value?.map((allergy) => {
          return { name: allergy };
        }),
    medications: subSteps?.[dermatology_sub_steps.medications]?.value
      ? typeof subSteps?.[dermatology_sub_steps.medications]?.value[0] ===
        "string"
        ? subSteps?.[dermatology_sub_steps.medications]?.value?.map((med) => {
            return { name: med };
          })
        : subSteps?.[dermatology_sub_steps.medications]?.value?.map((med) => {
            return med;
          })
      : subSteps?.[dermatology_sub_steps.medications]?.value?.map((med) => {
          return { name: med };
        }),
    pharmacy_details:
      subSteps?.[dermatology_sub_steps.select_pharmacy]?.value ?? {},
    skin_care_products:
      subSteps?.[dermatology_sub_steps.skin_care_products]?.value ?? [],
  };

  function addProgress(payload, screen_to_complete) {
    let result_payload;
    if (
      screen_to_complete &&
      subSteps[screen_to_complete]?.progress &&
      completedProgress <= subSteps[screen_to_complete]?.progress
    ) {
      result_payload = {
        ...payload,
        case: {
          ...constant_keys,
          ...payload.case,
          meta: {
            ...incompleteData.meta,
            ...payload.case.meta,
            progress_percentage: subSteps[screen_to_complete]?.progress,
          },
        },
      };
    } else {
      result_payload = {
        ...payload,
        case: {
          ...constant_keys,
          ...payload.case,
          meta: {
            ...incompleteData.meta,
            ...payload.case.meta,
          },
        },
      };
    }
    if (screen_to_complete) {
      return setDermatologyStepStatus({
        result_payload: result_payload,
        screen_to_complete,
        current_screen_state: subSteps?.[currentScreen],
      });
    } else {
      return result_payload;
    }
  }

  async function updateVisit({
    payload,
    change_current_screen,
    screen_to_complete,
    callBack,
  }) {
    try {
      const result = await callUpdateCase({
        caseId: incompleteData?.id,
        payload: addProgress(payload, screen_to_complete),
      });
      if (result && result.error === false) {
        const incompleteDataResponse = await fetchIncompleteData(
          speciality.dermatology
        );
        if (incompleteDataResponse) {
          if (screen_to_complete) {
            dispatch(
              dermaVisitFlowReducers?.[screen_to_complete]({
                status: step_status.completed,
              })
            );
            if (subSteps?.[screen_to_complete]?.progress) {
              dispatch(
                dermaVisitFlowReducers.completedProgress({
                  value: subSteps[screen_to_complete]?.progress,
                })
              );
            }
          }
          callBack(incompleteDataResponse?.payload);
          if (change_current_screen) {
            setTimeout(() => {
              dispatch(
                dermaVisitFlowReducers.currentScreen({
                  value: subSteps?.[currentScreen]?.nextScreen,
                })
              );
            }, 300);
          }
        }
      } else {
        showAlert(
          result?.payload?.error_message ?? "Something went wrong",
          "error"
        );

        if (
          result.payload.error_message ===
          "Your visit was deleted, please start a new one."
        ) {
          history.replace("/home");
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return { updateVisit, updatingVisit };
}

export default useUpdateDermatologyVisit;
