import PharmacyCard from "components/pharmacyCard/PharmacyCard";
import React from "react";

function PharmacyListView({ pharmacyList, onClickPharmacy }) {
  return (
    <div className="px-12 min-h-full">
      <p className="py-8 text-2xl font-light">Tap on a pharmacy to Select</p>
      {pharmacyList?.map((pharmacy, index) => {
        return (
          <div key={index} className="mb-16">
            <PharmacyCard
              onClickPharmacy={() => onClickPharmacy(pharmacy)}
              removeEdit
              pharmacyAddess={pharmacy?.address}
            />
          </div>
        );
      })}
    </div>
  );
}

export default PharmacyListView;
