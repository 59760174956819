import Images from "assets/images";
import React from "react";

function SettingsItem({ iconSource, itemName, onItemClick }) {
  return (
    <div
      className="flex items-center justify-between py-9 cursor-pointer"
      onClick={onItemClick}
    >
      <div className="flex items-center">
        <div className="w-20 h-20 bg-alternativeWhite rounded-2xl flex items-center justify-center">
          <img src={iconSource} alt="icon" className="w-7" />
        </div>
        <p
          className={`text-3xl font-medium pl-9 ${
            itemName?.toLowerCase() === "logout" ? "text-red" : ""
          }`}
        >
          {itemName}
        </p>
      </div>
      {itemName?.toLowerCase() === "logout" ? null : (
        <img src={Images.arrowRight} alt="right" className="w-8" />
      )}
    </div>
  );
}

export default SettingsItem;
