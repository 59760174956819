import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  getPresignedPhotoUrlAction,
  updatePhotoIdAction,
  uploadPhotoIdToS3Action,
} from "api/actions/ImageUploadActions";
import { useApiMutation } from "hooks/useApiMutation";
import usePatients from "hooks/visit/usePatients";

export const ImageUploadContext = createContext({});

function ImageUploadProvider({ children }) {
  const [isUploading, setIsUploading] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(null);

  const {
    mutate: getPresignedUrl,
    loading: gettingPreSignedUrl,
  } = useApiMutation(getPresignedPhotoUrlAction);

  const {
    mutate: uploadPhotoIdToS3,
    loading: uploadingPhotoIdToS3,
  } = useApiMutation(uploadPhotoIdToS3Action);

  const { mutate: updatePhotoId, loading: updatingPhotoId } = useApiMutation(
    updatePhotoIdAction
  );

  const { getPatients } = usePatients();

  useEffect(() => {
    if (gettingPreSignedUrl || uploadingPhotoIdToS3 || updatingPhotoId) {
      setIsUploading(true);
    } else {
      setIsUploading(false);
    }
  }, [
    gettingPreSignedUrl,
    uploadingPhotoIdToS3,
    updatingPhotoId,
    setIsUploading,
  ]);

  function uniqueid() {
    var idstr = String.fromCharCode(Math.floor(Math.random() * 25 + 65));
    do {
      var ascicode = Math.floor(Math.random() * 42 + 48);
      if (ascicode < 58 || ascicode > 64) {
        idstr += String.fromCharCode(ascicode);
      }
    } while (idstr.length < 32);

    return idstr;
  }

  const uploadImage = useCallback(
    async (result) => {
      let isSuccess = false;

      try {
        const presignedResult = await getPresignedUrl({
          payload: {
            photo_id: "default" + uniqueid(),
            file_extension: result?.file?.type,
          },
        });

        if (presignedResult && result?.file) {
          const uploadPhotoIdToS3Result = await uploadPhotoIdToS3({
            endpoint: presignedResult?.payload?.result,
            payload: result?.file,
          });

          if (uploadPhotoIdToS3Result.error) {
            isSuccess = false;
          }

          if (!uploadPhotoIdToS3Result.error) {
            const updatePhotoIdResponse = await updatePhotoId({
              payload: {
                s3_photo_id: presignedResult?.payload?.file_name,
              },
            });

            if (
              updatePhotoIdResponse &&
              updatePhotoIdResponse?.error === false
            ) {
              setPhotoUrl(updatePhotoIdResponse?.payload?.photo_id);
              getPatients();
              isSuccess = true;
            }
          }
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }

      return isSuccess;
    },
    [getPatients, getPresignedUrl, updatePhotoId, uploadPhotoIdToS3]
  );

  const value = useMemo(
    () => ({
      photoUrl,
      uploadImage,
      isUploading,
    }),
    [photoUrl, uploadImage, isUploading]
  );

  return (
    <ImageUploadContext.Provider value={value}>
      {children}
    </ImageUploadContext.Provider>
  );
}

export default ImageUploadProvider;
