import { dermatology_main_steps, dermatology_sub_steps } from "helpers/enum";

export const dermaStepsData = (mainSteps, subSteps) => [
  {
    main_step: {
      name: "Basic Details",
      status: mainSteps?.[dermatology_main_steps.basic_info]?.status,
    },
    sub_steps: [
      {
        name: "Select Patient",
        status: subSteps?.[dermatology_sub_steps.select_patient]?.status,
        key: dermatology_sub_steps.select_patient,
      },
      {
        name: "Confirm Residence",
        status: subSteps?.[dermatology_sub_steps.confirm_residence]?.status,
        key: dermatology_sub_steps.confirm_residence,
      },
      {
        name: "Patient Bio",
        status: subSteps?.[dermatology_sub_steps.patient_bio]?.status,
        key: dermatology_sub_steps.patient_bio,
      },
      {
        name: "Identity Confirmation",
        status: subSteps?.[dermatology_sub_steps.identity_confirmation]?.status,
        key: dermatology_sub_steps.identity_confirmation,
      },
    ],
  },
  {
    main_step: {
      name: "Visit Details",
      status: mainSteps?.[dermatology_main_steps.visit_details]?.status,
    },
    sub_steps: [
      {
        name: "Select Condition",
        status: subSteps?.[dermatology_sub_steps.select_condition]?.status,
        key: dermatology_sub_steps.select_condition,
      },
      {
        name: "Select Affected Part",
        status: subSteps?.[dermatology_sub_steps.select_affected_part]?.status,
        key: dermatology_sub_steps.select_affected_part,
      },
      {
        name: "Condition Details",
        status: subSteps?.[dermatology_sub_steps.condition_details]?.status,
        key: dermatology_sub_steps.condition_details,
      },
    ],
  },
  {
    main_step: {
      name: "Photos",
      status: mainSteps?.[dermatology_main_steps.photos]?.status,
    },
    sub_steps: [
      {
        name: "Upload Photos",
        status: subSteps?.[dermatology_sub_steps.upload_photos]?.status,
        key: dermatology_sub_steps.upload_photos,
      },
    ],
  },
  {
    main_step: {
      name: "Symptoms",
      status: mainSteps?.[dermatology_main_steps.main_symptoms]?.status,
    },
    sub_steps: [
      {
        name: "Symptoms",
        status: subSteps?.[dermatology_sub_steps.symptoms]?.status,
        key: dermatology_sub_steps.symptoms,
      },
      {
        name: "More About Symptoms",
        status: subSteps?.[dermatology_sub_steps.more_about_symptoms]?.status,
        key: dermatology_sub_steps.more_about_symptoms,
      },
    ],
  },
  {
    main_step: {
      name: "Medical History",
      status: mainSteps?.[dermatology_main_steps.main_medical_history]?.status,
    },
    sub_steps: [
      {
        name: "Medical History",
        status: subSteps?.[dermatology_sub_steps.medical_history]?.status,
        key: dermatology_sub_steps.medical_history,
      },
      {
        name: "Allergies",
        status: subSteps?.[dermatology_sub_steps.allergies]?.status,
        key: dermatology_sub_steps.allergies,
      },
      {
        name: "Medications",
        status: subSteps?.[dermatology_sub_steps.medications]?.status,
        key: dermatology_sub_steps.medications,
      },
      {
        name: "Skin Care Products",
        status: subSteps?.[dermatology_sub_steps.skin_care_products]?.status,
        key: dermatology_sub_steps.skin_care_products,
      },
      {
        name: "Select Pharmacy",
        status: subSteps?.[dermatology_sub_steps.select_pharmacy]?.status,
        key: dermatology_sub_steps.select_pharmacy,
      },
    ],
  },
];
