import React from "react";
import { usePrimaryCareVisitFlow } from "hooks/useVisitFlow";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import VisitSummaryContent from "components/visitSummaryAccordion/VisitSummaryContent";
import { primaryCareVisitFlowReducers } from "reducers/primary_care/primaryCareVisitFlowReducer";
import { primary_care_sub_steps } from "helpers/enum";
import VisitSummaryContentHeading from "components/visitSummaryAccordion/VisitSummaryContentHeading";
import VisitSummaryContentParagraph from "components/visitSummaryAccordion/VisitSummaryContentParagraph";

function VisitDetailsForPCSummary({ readOnly, data }) {
  const { incompleteData } = usePrimaryCareVisitFlow();
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <VisitSummaryContent
      readOnly={readOnly}
      onEditClick={() => {
        dispatch(
          primaryCareVisitFlowReducers.currentScreen({
            value: primary_care_sub_steps.select_condition,
          })
        );
        history.push("/visit/primary-care/new-visit");
      }}
    >
      <VisitSummaryContentHeading text="Visit Condition" />
      <VisitSummaryContentParagraph
        text={data?.skin_issue ?? incompleteData?.skin_issue}
      />
    </VisitSummaryContent>
  );
}

export default VisitDetailsForPCSummary;
