import ApiConfig from "api/config";

const getBrainTreeTokenAction = {
  method: "GET",
  endpoint: ApiConfig.urls.payment.getBtToken,
};

const getBrainTreeInfoAction = {
  method: "GET",
  endpoint: ApiConfig.urls.payment.getBtInfo,
};

const createBrainTreeCustomerAction = (payload) => ({
  method: "POST",
  endpoint: ApiConfig.urls.payment.createBrainTeeCustomer,
  body: payload,
});

const getBrainTreeTokenAltAction = (tID) => ({
  method: "GET",
  endpoint: ApiConfig.urls.payment.getBtTokenAlt(tID),
  config: { skipAuth: true },
});

const getBrainTreeInfoAltAction = (tID) => ({
  method: "GET",
  endpoint: ApiConfig.urls.payment.getBtInfoAlt(tID),
  config: { skipAuth: true },
});

const getTransactionInfoAction = (tID) => ({
  method: "GET",
  endpoint: ApiConfig.urls.payment.getTransactionInfo(tID),
  config: { skipAuth: true },
});

const createBillingAddressAction = ({ tID, payload }) => ({
  method: "POST",
  endpoint: ApiConfig.urls.payment.createBillingAddress(tID),
  body: payload,
  config: { skipAuth: true },
});

const chargePatientAction = ({ tID, payload }) => ({
  method: "POST",
  endpoint: ApiConfig.urls.payment.chargePatient(tID),
  body: payload,
  config: { skipAuth: true },
});

export {
  getBrainTreeTokenAction,
  createBrainTreeCustomerAction,
  getBrainTreeInfoAction,
  getBrainTreeTokenAltAction,
  getBrainTreeInfoAltAction,
  getTransactionInfoAction,
  createBillingAddressAction,
  chargePatientAction,
};
