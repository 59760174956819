import {
  ped_main_steps,
  ped_sub_steps,
  step_status,
} from "helpers/enum";
import { setNextScreenToUpcoming } from "helpers/setPedStepStatus";

export const pedBasicInfoReducer = {
  confirm_residence(state, action) {
    const { value, isValid, status } = action.payload;
    if (value) {
      state.subSteps[ped_sub_steps.confirm_residence].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[
        ped_sub_steps.confirm_residence
      ].isValid = isValid;
    }
    if (status) {
      state.subSteps[ped_sub_steps.confirm_residence].status = status;
      if (status === step_status.completed) {
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: ped_sub_steps.identity_confirmation,
        });
      }
    }
  },
  identity_confirmation(state, action) {
    const { value, formValues, isValid, status } = action.payload;
    if (value) {
      state.subSteps[
        ped_sub_steps.identity_confirmation
      ].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[
        ped_sub_steps.identity_confirmation
      ].isValid = isValid;
    }
    if (formValues) {
      state.subSteps[
        ped_sub_steps.identity_confirmation
      ].formValues = formValues;
    }
    if (status) {
      state.subSteps[
        ped_sub_steps.identity_confirmation
      ].status = status;
      if (status === step_status.completed) {
        state.mainSteps[ped_main_steps.basic_info].status =
          step_status.completed;
        if (
          state.mainSteps[ped_main_steps.additional_details].status ===
          step_status.upcoming
        ) {
          state.mainSteps[ped_main_steps.additional_details].status =
            step_status.current;
        }
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: ped_sub_steps.select_pharmacy,
        });
      }
    }
  },
};
