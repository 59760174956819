import React, { useEffect, useState } from "react";

import Images from "assets/images";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "hooks/useCurrentUser";
import Header from "components/header/Header";
import Button from "components/buttons/Button";
import Spacer from "components/Spacer/Spacer";
import { useDispatch } from "react-redux";
import { updateInsuranceAvailability } from "reducers/currentUserReducer";

function IsMedicalInsuranceAvailable() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userDetails, isInsuranceAvailable } = useCurrentUser();
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (typeof isInsuranceAvailable === "boolean") {
      if (isInsuranceAvailable) {
        setSelected("Yes");
      } else {
        setSelected("No");
      }
    }
  }, [isInsuranceAvailable]);

  return (
    <div className="h-screen bg-veryLightBlue">
      <Header
        customView={
          <img
            className="self-center w-44"
            src={Images.logoHorizontalNaming}
            alt="logo"
          />
        }
      />
      <div className="overflow-y-auto wrapper h-eliminateHeader bg-blue">
        <div className="flex flex-col px-9">
          <Spacer height={20} />
          <MedicalAssistant
            content={
              <>
                <p>
                  {userDetails?.first_name}, we accept a lot of different health
                  insurances to keep your out-of-pocket costs lower.
                </p>
              </>
            }
          />
          <Spacer height={35} />
          <div className="p-12 bg-white rounded-3xl">
            <h2 className="mb-8 text-3xl font-bold leading-10 text-center">
              Would you like to add your health insurance to your profile?
            </h2>
            <RadioButtonCard
              options={["Yes", "No"]}
              infoTexts={[
                "Please have your membership card handy.",
                "You can always add it later.",
              ]}
              onSelect={setSelected}
              selected={selected}
            />
            <Spacer height={30} />
            <Button
              disabled={!selected}
              label="Continue"
              onClick={() => {
                if (selected === "Yes") {
                  dispatch(
                    updateInsuranceAvailability({
                      isInsuranceAvailable: null,
                    })
                  );
                  history.push("/visit/medical-intake/insurance-details");
                } else if (selected === "No") {
                  dispatch(
                    updateInsuranceAvailability({
                      isInsuranceAvailable: false,
                    })
                  );
                  history.push("/visit/medical-intake/select-provider");
                }
              }}
            />
          </div>
          <Spacer height={50} />
        </div>
      </div>
    </div>
  );
}

export default IsMedicalInsuranceAvailable;
