import ApiConfig from "api/config";

const loginAction = (formValues) => ({
  method: "POST",
  endpoint: ApiConfig.urls.auth.login,
  body: formValues,
  config: { skipAuth: true },
});
const phoneAction = (formValues) => ({
  method: "POST",
  endpoint: ApiConfig.urls.auth.generateOTP,
  body: formValues,
  config: { skipAuth: true },
});
const resendOTPAction = (formValues) => ({
  method: "POST",
  endpoint: ApiConfig.urls.auth.regenerateOTP,
  body: formValues,
  config: { skipAuth: true },
});
const verifyOTPAction = (formValues) => ({
  method: "POST",
  endpoint: ApiConfig.urls.auth.verifyOTP,
  body: formValues,
  config: { skipAuth: true },
});
const verifyCaptchaAction = (formValues) => ({
  method: "POST",
  endpoint: ApiConfig.urls.auth.verifyCaptcha,
  body: formValues,
  config: { skipAuth: true },
});
const googleAuthAction = (formValues) => ({
  method: "POST",
  endpoint: ApiConfig.urls.auth.googleAuth,
  body: formValues,
  config: { skipAuth: true },
});

const emailVerify = (email) => ({
  method: "GET",
  endpoint: ApiConfig.urls.auth.checkEmail(email),
  config: { skipAuth: true },
});
const phoneValidate = (phone) => ({
  method: "GET",
  endpoint: ApiConfig.urls.auth.validatePhone(phone),
  config: { skipAuth: true },
});

const signupAction = (formValues) => ({
  method: "POST",
  endpoint: ApiConfig.urls.auth.signup,
  body: formValues,
  config: { skipAuth: true },
});

const forgotPassword = (formValues) => ({
  method: "POST",
  endpoint: ApiConfig.urls.auth.forgotPassword,
  body: formValues,
  config: { skipAuth: true },
});

const resetPassword = ({ payload, token }) => ({
  method: "POST",
  endpoint: ApiConfig.urls.auth.resetPassword(token),
  body: payload,
  config: { skipAuth: true },
});

const changePasswordAction = (formValues) => ({
  method: "POST",
  endpoint: ApiConfig.urls.auth.changePassword,
  body: formValues,
});

const partialSignup = (formValues) => ({
  method: "POST",
  endpoint: ApiConfig.urls.auth.partialSignup,
  body: formValues,
  config: { skipAuth: true },
});

const directLoginAction = (payload) => ({
  method: "POST",
  endpoint: ApiConfig.urls.auth.directLogin,
  body: payload,
  config: { skipAuth: true },
});

export {
  loginAction,
  verifyOTPAction,
  phoneAction,
  resendOTPAction,
  verifyCaptchaAction,
  googleAuthAction,
  signupAction,
  emailVerify,
  phoneValidate,
  forgotPassword,
  resetPassword,
  changePasswordAction,
  partialSignup,
  directLoginAction,
};
