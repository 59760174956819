import {
  primary_care_main_steps,
  primary_care_sub_steps,
  step_status,
} from "helpers/enum";
import { setNextScreenToUpcoming } from "helpers/setPrimaryCareStepStatus";

export const primaryCareSelectSlotReducer = {
  select_slot(state, action) {
    const { status, value, isValid } = action.payload;
    if (status) {
      state.subSteps[primary_care_sub_steps.select_slot].status = status;
      if (status === step_status.completed) {
        state.mainSteps[primary_care_main_steps.main_select_slot].status =
          step_status.completed;
        if (
          state.mainSteps[primary_care_main_steps.basic_info].status ===
          step_status.upcoming
        ) {
          state.mainSteps[primary_care_main_steps.basic_info].status =
            step_status.current;
        }
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: primary_care_sub_steps.confirm_residence,
        });
      }
    }
    if (value !== undefined) {
      state.subSteps[primary_care_sub_steps.select_slot].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[primary_care_sub_steps.select_slot].isValid = isValid;
    }
  },
};
