import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import InsuranceAndProviderBlock from "../medicalIntake/InsuranceAndProviderBlock";
import InsuranceDetails from "../medicalIntake/insuranceDetails/InsuranceDetails";
import IsMedicalInsuranceAvailable from "../medicalIntake/IsMedicalInsuranceAvailable/IsMedicalInsuranceAvailable";
import MedicalIntakeRouteGuard from "../../../../routes/MedicalIntakeRouteGuard";
import SelectedProviders from "../medicalIntake/selectedProviders/SelectedProviders";
import ProtectVisitRoute from "./common/ProtectVisitRoute";
import VisitSubmitted from "./common/VisitSubmitted";
import DermatologyCheckout from "./dermatology/checkout/DermatologyCheckout";
import DermatologyVisitTerms from "./dermatology/DermatologyVisitTerms";
import DermaVisitSteps from "./dermatology/DermaVisitSteps";
import DermatologyVisitPricing from "./dermatology/pricing/DermatologyVisitPricing";
import DermAcceptRevisedQuote from "./dermatology/DermAcceptRevisedQuote";
import PrimaryCareVisitPricing from "./primaryCare/pricing/PrimaryCareVisitPricing";
import SelectVisitType from "./SelectVisitType";
import DermaSubStepScreen from "./subStepScreen/DermaSubStepScreen";
import PrimaryCareCompleteVisitDetails from "./primaryCare/PrimaryCareCompleteVisitDetails";
import AcceptRevisedQuote from "./primaryCare/phaseTwoSections/AcceptRevisedQuote";
import PedVisitPricing from "./ped/pricing/PedVisitPricing";
import PedCompleteVisitDetails from "./ped/PedCompleteVisitDetails";
import PedAcceptRevisedQuote from "./ped/phaseTwoSections/AcceptRevisedQuote";

function VisitFlowRoute() {
  let { url } = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from={url} to={`${url}/type`} />
      <Route path={`${url}/type`}>
        <SelectVisitType />
      </Route>

      {/* upload inurance and select provider */}
      <MedicalIntakeRouteGuard exact path={`${url}/medical-intake`}>
        <InsuranceAndProviderBlock />
      </MedicalIntakeRouteGuard>
      <MedicalIntakeRouteGuard
        path={`${url}/medical-intake/is-insurance-available`}
      >
        <IsMedicalInsuranceAvailable />
      </MedicalIntakeRouteGuard>
      <MedicalIntakeRouteGuard path={`${url}/medical-intake/insurance-details`}>
        <InsuranceDetails />
      </MedicalIntakeRouteGuard>
      <MedicalIntakeRouteGuard path={`${url}/medical-intake/select-provider`}>
        <SelectedProviders />
      </MedicalIntakeRouteGuard>

      {/* visit flow  */}
      <ProtectVisitRoute exact path={`${url}/dermatology`}>
        <DermaVisitSteps />
      </ProtectVisitRoute>
      <ProtectVisitRoute exact path={`${url}/dermatology/new-visit`}>
        <DermaSubStepScreen />
      </ProtectVisitRoute>
      <ProtectVisitRoute exact path={`${url}/dermatology/pricing`}>
        <DermatologyVisitPricing />
      </ProtectVisitRoute>
      <ProtectVisitRoute exact path={`${url}/dermatology/checkout`}>
        <DermatologyCheckout />
      </ProtectVisitRoute>
      <Route exact path={`${url}/dermatology/accept-price-revision`}>
        <DermAcceptRevisedQuote />
      </Route>
      <ProtectVisitRoute exact path={`${url}/primary-care/pricing`}>
        <PrimaryCareVisitPricing />
      </ProtectVisitRoute>
      <ProtectVisitRoute
        exact
        path={`${url}/primary-care/complete-visit-details`}
      >
        <PrimaryCareCompleteVisitDetails />
      </ProtectVisitRoute>
      <Route exact path={`${url}/primary-care/accept-price-revision`}>
        <AcceptRevisedQuote />
      </Route>
      <ProtectVisitRoute exact path={`${url}/pediatrics/pricing`}>
        <PedVisitPricing />
      </ProtectVisitRoute>
      <ProtectVisitRoute
        exact
        path={`${url}/pediatrics/complete-visit-details`}
      >
        <PedCompleteVisitDetails />
      </ProtectVisitRoute>
      <Route exact path={`${url}/pediatrics/accept-price-revision`}>
        <PedAcceptRevisedQuote />
      </Route>
      <ProtectVisitRoute exact path={`${url}/dermatology/terms_and_conditions`}>
        <DermatologyVisitTerms />
      </ProtectVisitRoute>
      <Route exact path={`${url}/submitted`}>
        <VisitSubmitted />
      </Route>
      <Redirect to="/404" />
    </Switch>
  );
}

export default VisitFlowRoute;
