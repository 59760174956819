import BottomTab from "components/bottomTab/BottomTab";
import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import MyVisits from "./myVisits/MyVisits";
import ChangePassword from "./profile/ChangePassword";
import ContactUs from "./profile/ContactUs";
import HealthProfile from "./profile/healthProfile/HealthProfile";
import Payments from "./profile/Payments";
import Profile from "./profile/Profile";
import ProfileSettings from "./profile/ProfileSettings";
import Referrals from "./referrals/Referrals";
import WaitingRoom from "./waitingRoom/WaitingRoom";

function HomePage() {
  let { url } = useRouteMatch();
  return (
    <>
      <div className="w-full h-full lg:pl-sidebar">
        <Switch>
          <Route path={url} exact={true}>
            <WaitingRoom />
          </Route>
          <Route exact path={`${url}/my-visits`}>
            <MyVisits />
          </Route>
          <Route path={`${url}/referrals`}>
            <Referrals />
          </Route>
          <Route exact={true} path={`${url}/profile`}>
            <Profile />
          </Route>
          <Route exact path={`${url}/profile/settings`}>
            <ProfileSettings />
          </Route>
          <Route path={`${url}/profile/settings/contact-us`}>
            <ContactUs />
          </Route>
          <Route path={`${url}/profile/settings/change-password`}>
            <ChangePassword />
          </Route>
          <Route path={`${url}/profile/health-profile`}>
            <HealthProfile />
          </Route>
          <Route path={`${url}/profile/payments`}>
            <Payments />
          </Route>
          <Redirect to="/404" />
        </Switch>
      </div>
      <BottomTab />
    </>
  );
}

export default HomePage;
