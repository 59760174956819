import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

import "./App.scss";
import { getApiClient } from "api/apiClient";
import { ClientContextProvider } from "react-fetching-library";
import AppPaths from "routes/appPaths";
import { useDispatch } from "react-redux";
import { useCurrentUser } from "hooks/useCurrentUser";
import { ToastContainer } from "react-toastify";
import FullPageLoader from "components/loader/FullPageLoader";
import FullPageLoaderProvider from "providers/FullPageLoaderContext";
import ModalComponent from "components/modal/ModalComponent";
import ModalProvider from "providers/ModalContext";
import VisitStepChangeProvider from "providers/VisitStepChangeProvider";
import ImageUploadProvider from "providers/ImageUploadProvider";
import HealthProfileProvider from "providers/HealthProfileProvider";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from "api/config";
import SignupProvider from "providers/SignupProvider";
import PendingPaymentsProvider from "providers/PendingPaymentsProvider";
function App() {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  // Google Analytics
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);

  // Google Tag Manager
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  });

  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
  return (
    <ClientContextProvider
      client={getApiClient(currentUser?.userToken, dispatch)}
    >
      <FullPageLoaderProvider>
        <VisitStepChangeProvider>
          <ModalProvider>
            <SignupProvider>
              <PendingPaymentsProvider>
                <ImageUploadProvider>
                  <HealthProfileProvider>
                    <AppPaths />
                    <ToastContainer
                      position="top-right"
                      autoClose={3000}
                      hideProgressBar={true}
                      newestOnTop={false}
                      closeOnClick={false}
                      rtl={false}
                      pauseOnFocusLoss
                      pauseOnHover
                      style={{ padding: 16, width: "36rem" }}
                    />
                  </HealthProfileProvider>
                </ImageUploadProvider>
              </PendingPaymentsProvider>
            </SignupProvider>
            <ModalComponent />
            <FullPageLoader />
          </ModalProvider>
        </VisitStepChangeProvider>
      </FullPageLoaderProvider>
    </ClientContextProvider>
  );
}

export default App;
