import React from "react";

const DiagnosisTabContent = ({ visitDetails }) => {
  return (
    <div className="mx-12">
      <div>
        <p className="font-bold text-2xl">DIAGNOSIS</p>
        <div className="mt-6 p-6 w-full bg-white rounded-xl">
          <div className="text-2xl font-medium opacity-50">
            {visitDetails?.diagnosis?.diagnosis}
          </div>
          <div
            className="text-3xl font-medium pt-5"
            dangerouslySetInnerHTML={{
              __html: visitDetails?.diagnosis?.diagnosis_detail_html,
            }}
          />
        </div>
      </div>
      <div className="pt-6">
        <p className="font-bold text-2xl">OTHER INSTRUCTIONS</p>
        <div className="mt-6 p-6 w-full bg-white rounded-xl">
          <div
            className="text-3xl font-medium"
            dangerouslySetInnerHTML={{
              __html: visitDetails?.diagnosis?.otherInstructionsHtml,
            }}
          />
        </div>
      </div>
      <div className="pt-6">
        <p className="font-bold text-2xl">FOLLOW UP</p>
        <div className="mt-6 p-6 w-full bg-white rounded-xl">
          <div className="text-3xl font-medium">
            {visitDetails?.diagnosis?.followUpInstruction}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiagnosisTabContent;
