import React from "react";
import VisitSummaryContent from "components/visitSummaryAccordion/VisitSummaryContent";
import VisitSummaryContentHeading from "components/visitSummaryAccordion/VisitSummaryContentHeading";
import VisitSummaryContentParagraph from "components/visitSummaryAccordion/VisitSummaryContentParagraph";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import Spacer from "components/Spacer/Spacer";
import { capitalize } from "helpers/lodashPolyfills";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { dermatology_sub_steps } from "helpers/enum";

function VisitDetailsForDermSummary({ readOnly, data }) {
  const { incompleteData } = useDermatologyVisitFlow();
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <VisitSummaryContent
      readOnly={readOnly}
      onEditClick={() => {
        dispatch(
          dermaVisitFlowReducers.currentScreen({
            value: dermatology_sub_steps.select_condition,
          })
        );
        history.push("/visit/dermatology/new-visit");
      }}
    >
      <VisitSummaryContentHeading text="Visit Condition" />
      <VisitSummaryContentParagraph
        text={data?.skin_issue ?? incompleteData?.skin_issue}
      />
      <Spacer height={20} />
      <VisitSummaryContentHeading text="Affected Part(s)" />
      <VisitSummaryContentParagraph
        text={
          data?.affected_body_parts
            ? data?.affected_body_parts?.join(", ")
            : incompleteData?.affected_body_parts?.join(", ")
        }
      />
      <Spacer height={20} />
      <VisitSummaryContentHeading text="Condition Details" />
      <Spacer height={20} />
      <VisitSummaryContentHeading text="Status" />
      <VisitSummaryContentParagraph
        text={capitalize(
          data?.skin_issue_status ?? incompleteData?.skin_issue_status
        )}
      />
      <Spacer height={20} />
      <VisitSummaryContentHeading text="Days" />
      <VisitSummaryContentParagraph
        text={
          data?.skin_issue_duration
            ? data?.skin_issue_duration?.split(" ")?.[0] +
              " " +
              data?.skin_issue_duration?.split(" ")?.[1] +
              "(s)"
            : incompleteData?.skin_issue_duration?.split(" ")?.[0] +
              " " +
              incompleteData?.skin_issue_duration?.split(" ")?.[1] +
              "(s)"
        }
      />
      <Spacer height={20} />
      <VisitSummaryContentHeading text="Severity" />
      <VisitSummaryContentParagraph
        text={capitalize(
          data?.skin_issue_severity ?? incompleteData?.skin_issue_severity
        )}
      />
    </VisitSummaryContent>
  );
}

export default VisitDetailsForDermSummary;
