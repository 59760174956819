import React from "react";
import { changePasswordAction } from "api/actions/AuthActions";
import Button from "components/buttons/Button";
import Spacer from "components/Spacer/Spacer";
import { Form, Formik } from "formik";
import { useApiMutation } from "hooks/useApiMutation";
import useToastify from "hooks/useToastify";
import { useHistory } from "react-router-dom";
import { ChangePasswordSchema } from "helpers/validationSchema";
import { userLogoutSessionAction } from "api/actions/UserActions";
import { useApiQuery } from "hooks/useApiQuery";
import LoaderComponent from "components/loader/LoaderComponent";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { primaryCareVisitFlowReducers } from "reducers/primary_care/primaryCareVisitFlowReducer";
import { logoutUser } from "reducers/currentUserReducer";
import FormikErrorFocus from "formik-error-focus";
import { cosmeticConsultationReducers } from "reducers/cosmetic/cosmeticConsultationReducer";
import { pedVisitFlowReducers } from "reducers/ped/pedVisitFlowReducer";
import SimpleHeader from "components/header/SimpleHeader";
import Password from "components/inputs/Password";

function ChangePassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showAlert } = useToastify();
  const {
    mutate: callChangePassword,
    loading: isUpdatingPassword,
  } = useApiMutation(changePasswordAction);
  const { query: logoutSessionAPI, loading: loggingOut } = useApiQuery(
    userLogoutSessionAction,
    false
  );

  async function logout() {
    try {
      const logoutResonse = await logoutSessionAPI();
      if (logoutResonse?.error === false) {
        dispatch(dermaVisitFlowReducers.reset());
        dispatch(primaryCareVisitFlowReducers.reset());
        dispatch(cosmeticConsultationReducers.reset());
        dispatch(pedVisitFlowReducers.reset());
        dispatch(logoutUser());
        history.goBack();
      } else {
        showAlert("Something went wrong", "error");
      }
    } catch (error) {
      showAlert("Something went wrong", "error");
    }
  }

  async function onFormSubmit(values, actions) {
    try {
      const changePasswordResult = await callChangePassword({
        old_password: values?.old_password,
        new_password: values?.new_password,
      });

      if (changePasswordResult?.payload?.message?.includes("Old")) {
        actions.setFieldError(
          "old_password",
          changePasswordResult?.payload?.message
        );
      } else {
        logout();
        showAlert("Password Changed successfully", "success");
      }

      if (
        changePasswordResult?.error ||
        changePasswordResult?.payload?.error ||
        changePasswordResult?.payload?.error_msg
      ) {
        showAlert(
          changePasswordResult?.payload.error ||
            changePasswordResult?.payload.error_msg,
          "error"
        );
      }
    } catch (error) {
      showAlert(error, "error");
    }
  }

  if (loggingOut) {
    return (
      <LoaderComponent showLoader={{ state: true, message: "Logging out." }} />
    );
  }
  return (
    <div className="h-screen bg-backgroundWhite">
      <SimpleHeader
        onBackButtonClick={() => {
          history.goBack();
        }}
        title="Change Password"
      />
      <div className="flex flex-col overflow-y-auto bg-white md:bg-transparent h-eliminateHeader wrapper">
        <div className="flex flex-col md:mt-32">
          <div className="p-10 bg-white">
            <Formik
              validationSchema={ChangePasswordSchema}
              onSubmit={onFormSubmit}
              initialValues={{
                old_password: "",
                new_password: "",
                confirm_new_password: "",
              }}
            >
              {() => (
                <Form>
                  <Spacer height={32} />
                  <Password
                    name="old_password"
                    type="password"
                    label="Old Password"
                    id="old_password"
                  />
                  <Spacer height={32} />
                  <Password
                    name="new_password"
                    type="password"
                    label="New Password"
                    id="new_password"
                  />
                  <Spacer height={32} />
                  <Password
                    name="confirm_new_password"
                    type="password"
                    label="Confirm New Password"
                    id="confirm_new_password"
                  />
                  <Spacer height={32} />
                  <Button
                    type="submit"
                    label="Change Password"
                    loading={isUpdatingPassword}
                  />
                  <FormikErrorFocus
                    align={"bottom"}
                    ease={"linear"}
                    duration={500}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
