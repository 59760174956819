import classNames from "classnames";
import React, { memo, useRef, useLayoutEffect } from "react";
import usePrevious from "./usePrevious.hooks";

export function OTPInputFieldComponent(props) {
  const { focus, autoFocus, className, ...rest } = props;
  const inputRef = useRef(null);
  const prevFocus = usePrevious(!!focus);
  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return (
    <input
      type={"text"}
      className={classNames(
        "focus:border-theme-indigo-300 my-2 h-16 w-20 rounded border border-gray-200 text-center text-sm focus:ring-0",
        className
      )}
      ref={inputRef}
      {...rest}
    />
  );
}

export const OTPInputField = memo(OTPInputFieldComponent);
