import Images from "assets/images";
import Accordion from "components/accordion/Accordion";
import { getVisitDetails } from "helpers/functions";
import React from "react";
import { ContentCard } from "./MiscComponents";

function Header({ label, isActive }) {
  return (
    <div className={"flex items-center justify-between px-4 pb-6"}>
      <div className="flex items-center">
        <h2 className="text-3xl font-bold ">{label}</h2>
      </div>
      <div>
        <img
          src={Images.dropdown}
          alt="chev_down"
          className={`transition duration-500 ease-in-out w-10 h-10 transform ${
            isActive ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>
    </div>
  );
}
function SubHeader({ label, index }) {
  return (
    <div className={`flex items-center justify-between py-6`}>
      <div className="flex items-center">
        <h2 className="text-2xl font-medium ">
          {index + 1}. {label}
        </h2>
      </div>
    </div>
  );
}

function VisitAccordion({ speciality, loading, queryDetails, details }) {
  const accordionData = [
    {
      header: (props) => <Header {...props} label={"Visit Summary"} />,
      content: (
        <div className="space-y-4">
          <Accordion
            containerClassName="divide-y"
            data={getVisitDetails(speciality, details)?.map((e, i) => {
              return {
                header: (props) => (
                  <SubHeader {...props} label={e.label} index={i} />
                ),
                className: "hover:bg-gray-100 pl-4 my-2",
                content: <ContentCard content={e.content} type={e.key} />,
              };
            })}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <Accordion
        data={accordionData}
        loading={details === null ? true : loading}
        containerClassName={"pt-6"}
        onExpand={(a) => {
          if (!details) {
            queryDetails();
          }
        }}
      />
    </div>
  );
}
export default VisitAccordion;
