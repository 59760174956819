import React from "react";
import Accordion from "components/accordion/Accordion";
import StepHeader from "./StepHeader";
import StepSubHeader from "./StepSubHeader";
import { step_status } from "helpers/enum";

function VisitStepAccordion({ data, onClickSubStep }) {
  const accordionData = data?.length
    ? data?.map((el, index) => {
        return {
          header: (props) => (
            <StepHeader
              {...props}
              data={el?.main_step}
              index={index}
              status={el?.main_step?.status}
            />
          ),
          content: (
            <div className="px-20 py-6 border-b border-borderColor">
              {el?.sub_steps?.map((elem, idx) => (
                <StepSubHeader
                  key={idx}
                  data={elem}
                  status={elem?.status}
                  onClick={onClickSubStep}
                />
              ))}
            </div>
          ),
        };
      })
    : [];

  return (
    <Accordion
      data={accordionData}
      disabledIndicesFromData={data
        ?.map((el, idx) =>
          el?.main_step?.status === step_status.upcoming ? idx : ""
        )
        .filter(String)}
      activeIndexFromData={data?.findIndex(
        (el) => el?.main_step?.status === step_status.current
      )}
    />
  );
}

export default VisitStepAccordion;
