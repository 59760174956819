import { useState, useEffect } from "react";

export default function useDebounce(searchText, delay) {
  const [debouncedValue, setDebouncedValue] = useState(searchText);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchText?.length > 2) {
        setDebouncedValue(searchText);
      }
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [searchText, delay]);

  return debouncedValue;
}
