import useHealthProfile from "hooks/useHealthProfile";
import React, { useEffect } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import PrescriptionHistory from "../PrescriptionHistory";
import HPAllergies from "./allergies/HPAllergies";
import HPFamilyHistory from "./familyHistory/HPFamilyHistory";
import HPFemaleHealth from "./femaleHealth/HPFemaleHealth";
import HealthProfileList from "./HealthProfileList";
import HealthProfileView from "./HealthProfileView";
import HPMedicalHistory from "./medicalHistory/HPMedicalHistory";
import HPMedications from "./medications/HPMedications";
import HPSocialHistory from "./socialHistory/HPSocialHistory";
import SurgeriesHospitalisations from "./surgeriesHospitalisations/SurgeriesHospitalisations";

function HealthProfile() {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { getHealthProfile } = useHealthProfile();
  const isPatientIdAvailable = history?.location?.pathname

    ?.match(/\d/g)
    ?.join("");
  useEffect(() => {
    if (isPatientIdAvailable) {
      getHealthProfile(isPatientIdAvailable);
    }
  }, [isPatientIdAvailable, getHealthProfile]);
  return (
    <Switch>
      <Route exact path={`${url}/:patient_id`}>
        <HealthProfileView />
      </Route>
      <Route exact path={`${url}/:patient_id/prescription-history`}>
        <PrescriptionHistory />
      </Route>
      <Route exact path={`${url}/:patient_id/medical-history`}>
        <HPMedicalHistory />
      </Route>
      <Route exact path={`${url}/:patient_id/allergies`}>
        <HPAllergies />
      </Route>
      <Route exact path={`${url}/:patient_id/medications`}>
        <HPMedications />
      </Route>
      <Route exact path={`${url}/:patient_id/surgeries-hospitalisations`}>
        <SurgeriesHospitalisations />
      </Route>
      <Route exact path={`${url}/:patient_id/family-history`}>
        <HPFamilyHistory />
      </Route>
      <Route exact path={`${url}/:patient_id/female-health`}>
        <HPFemaleHealth />
      </Route>
      <Route path={`${url}/:patient_id/social-history`}>
        <HPSocialHistory />
      </Route>
      <Route path={`${url}`}>
        <HealthProfileList />
      </Route>
    </Switch>
  );
}

export default HealthProfile;
