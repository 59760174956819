const allSymptoms = [
  "Burning",
  "Itching",
  "Painful",
  "Redness",
  "Swelling",
  "Tender",
  "Bleeding",
  "Blistering",
  "Bruising",
  "Changing Color",
  "Changing Shape",
  "Comes and Goes",
  "Crusted",
  "Elevated",
  "Enlarging",
  "Flaring",
  "Inflamed",
  "Irritated",
  "Rough",
  "Scaly",
  "Spreading",
  "Stable",
  "Arthritis",
  "Dark Skin Spots",
  "Dry Skin",
  "Fever",
  "Flushing",
  "Loss of Skin Pigment",
  "Scarring",
  "Sores in Mouth",
];

const allSymptomsData = allSymptoms.sort();

allSymptomsData.push("Other");

export { allSymptomsData };

export const symptomsData = [
  "Burning",
  "Itching",
  "Painful",
  "Redness",
  "Swelling",
  "Tender",
  "Other",
  "None",
];

export const symptomDetailsData = [
  "Bleeding",
  "Blistering",
  "Bruising",
  "Changing Color",
  "Changing Shape",
  "Comes and Goes",
  "Crusted",
  "Elevated",
  "Enlarging",
  "Flaring",
  "Inflamed",
  "Irritated",
  "Rough",
  "Scaly",
  "Spreading",
  "Stable",
  "None",
];

export const associatedSymptomsData = [
  "Arthritis",
  "Dark Skin Spots",
  "Dry Skin",
  "Fever",
  "Flushing",
  "Loss of Skin Pigment",
  "Scarring",
  "Sores in Mouth",
  "None",
];
