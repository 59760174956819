import React, { useState } from "react";

import "./Input.scss";

import { useField } from "formik";
import Images from "assets/images";
import classNames from "classnames";

const Password = ({ type, label, customonchange, customonblur, ...rest }) => {
  const [field, meta] = useField(rest);
  const [showPass, setShowPass] = useState(false);

  return (
    <div className="flex flex-col custom_pass">
      <label
        className={`font-bold text-2xl text-indigo text-opacity-50`}
        style={rest?.labelstyles}
        htmlFor={rest?.id}
      >
        {label}
      </label>
      <div
        className={classNames(
          "custom_pass_field",
          meta.touched && meta.error && "invalid"
        )}
      >
        <input
          type={showPass ? "text" : "password"}
          {...field}
          {...rest}
          onBlur={(event) => {
            field?.onBlur(event);
            if (customonblur) {
              customonblur();
            }
          }}
        />
        <div
          className="w-10 h-10 cursor-pointer"
          onClick={() => setShowPass((prev) => !prev)}
        >
          <img
            style={{ objectFit: "contain" }}
            src={showPass ? Images.eyeClose : Images.eyeOpen}
            alt="show-password"
            className=" text-themeBlack"
          />
        </div>
      </div>
      {meta.touched && meta.error ? (
        <p className="mt-2 text-base font-bold text-red">{meta.error}</p>
      ) : null}
    </div>
  );
};

export default Password;
