import React from "react";
import Images from "assets/images";
import Input from "components/inputs/Input";
import Spacer from "components/Spacer/Spacer";
import Button from "components/buttons/Button";
import { phoneAction, phoneValidate } from "api/actions/AuthActions";
import { useApiMutation } from "hooks/useApiMutation";
import { Formik, Form } from "formik";
import { PhoneInputSchema } from "helpers/validationSchema";
import { useHistory, useParams } from "react-router-dom";
import useToastify from "hooks/useToastify";
import FormikErrorFocus from "formik-error-focus";
import { input_types } from "helpers/enum";

function PhoneInputPage() {
  const history = useHistory();
  const { showAlert } = useToastify();
  const { mutate: callGenerateOTPApi, loading } = useApiMutation(phoneAction);
  const {
    mutate: callPhoneValidate,
    loading: validatePhoneLoading,
  } = useApiMutation(phoneValidate);
  const { number } = useParams();
  async function validateSubmit(values, { setErrors }) {
    try {
      const result = await callPhoneValidate(
        values.mobile_number?.substring(1)
      );
      if (result.error && (result.payload.error || result.payload.message)) {
        showAlert(result.payload.error || result.payload.message, "error");
      } else {
        return generateOTP(values, setErrors);
      }
    } catch (err) {
      showAlert(err.message, "error");
    }
  }
  async function generateOTP(values, setErrors) {
    try {
      const result = await callGenerateOTPApi({
        mobile_number: values.mobile_number?.substring(1),
      });
      if (result.error && result.payload.message && result.status !== 422) {
        if (result.payload.error_code === "otp_limit_exceed") {
          setErrors({ mobile_number: "OTP limit exceeded for the day" });
        }
        errorHandler(result.payload.message);
      } else {
        showAlert(result.payload.message, "success");
        successHandler(values.mobile_number, result.payload.expires_in_sec);
      }
    } catch (err) {
      errorHandler(err);
    }
  }

  function successHandler(number, expiry) {
    // history.push(`/otp/${number}`);
    history.push({
      pathname: "/otp",
      state: { number: number, expires_in_sec: expiry },
    });
  }

  function errorHandler(message) {
    showAlert(message, "error");
  }
  function checkGoBack() {
    history.replace("/login");
    // let currentUrl = window.location.href;
    // history.goBack();
    // setTimeout(() => {
    //   if (currentUrl === window.location.href) {

    //   }
    // }, 500);
  }
  return (
    <div className="flex flex-col justify-center h-screen bg-veryLightBlue md:bg-gradient-to-b from-blue-300 to-blue-500">
      <div className="w-full h-full mx-auto overflow-y-auto md:h-auto md:max-w-2xl bg-blue md:bg-white md:p-10 login-card md:rounded-xl">
        <div className="bg-alternativeWhite md:bg-transparent">
          <div className="flex flex-col items-center justify-center px-0 py-10 md:py-0">
            <img
              className="object-contain h-44 w-44"
              src={Images.logoVerticalNaming}
              alt="logo"
            />
            <p className="mt-6 text-4xl font-medium leading-8 md:mt-8 md:font-bold">
              Enter Your Phone Number
            </p>
            <p className="px-10 pt-4 pb-0 text-3xl font-medium text-center text-themeBlack md:px-0 md:pb-8 md:text-xl">
              You will receive a 4 digit code for phone number verification
            </p>
          </div>
        </div>
        <Formik
          initialValues={{
            mobile_number: number,
          }}
          validateOnMount={true}
          validationSchema={PhoneInputSchema}
          onSubmit={validateSubmit}
        >
          {({ setFieldValue, setFieldTouched, isValid, values }) => (
            <Form className="px-12 py-12 pb-32 md:px-0 md:py-0">
              <div className="p-12 bg-white md:p-0 rounded-3xl required-label">
                <Input
                  name="mobile_number"
                  type={input_types.PHONE}
                  label="Phone Number"
                  id="mobile_number"
                  placeholder="(313)-456-7890"
                  value={values?.mobile_number}
                  onChange={(val) => {
                    setFieldValue("mobile_number", val);
                  }}
                  onBlur={() => setFieldTouched("mobile_number", true)}
                />

                <Spacer height={25} />
                <Button
                  type="submit"
                  label={"Request OTP"}
                  loading={loading || validatePhoneLoading}
                  disabled={loading || !isValid}
                  className={"h-18"}
                />

                <div className="flex justify-center mt-10">
                  <div
                    className="flex space-x-4 cursor-pointer w-max"
                    onClick={() => checkGoBack()}
                  >
                    <img
                      className="object-contain w-6"
                      src={Images.arrowBack}
                      alt="back"
                    />
                    <p className="text-2xl font-medium text-black">
                      Back to login
                    </p>
                  </div>
                </div>
              </div>
              <FormikErrorFocus
                align={"bottom"}
                ease={"linear"}
                duration={500}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default PhoneInputPage;
