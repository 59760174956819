import React, { useState, useMemo, useEffect } from "react";
import { patientVisittedVisitDetailsScreenAction } from "api/actions/VisitActions";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Spacer from "components/Spacer/Spacer";
import RateProviderStrip from "./RateProviderStrip";
import { useCurrentUser } from "hooks/useCurrentUser";
import { visit_status } from "helpers/enum";
import ReceiptView from "./ReceiptView";
import ImageComponent from "components/imageComponent/ImageComponent";
import moment from "moment";
import DiagnosisAccordion from "./DiagnosisAccordion";
import { useApiMutation } from "hooks/useApiMutation";
import CosmeticConsultationSummary from "../waitingRoom/consultation/cosmetic/checkout/CosmeticConsultationSummary";
import VisitDiagnosedDetails from "./otcFlow/VisitDiagnosedDetails";

function ConsultationDetails({ visitDetails }) {
  const { userDetails } = useCurrentUser();

  const [selectedStarRating, setSelectedStarRating] = useState(null);

  const { mutate: recordPatientVisitted } = useApiMutation(
    patientVisittedVisitDetailsScreenAction
  );

  const isDiagnosed = useMemo(() => {
    if (
      visitDetails?.diagnosis &&
      Object.keys(visitDetails?.diagnosis).length
    ) {
      return true;
    } else {
      return false;
    }
  }, [visitDetails]);

  useEffect(() => {
    if (visitDetails && !visitDetails?.error) {
      if (
        visitDetails?.status !== null &&
        (visitDetails?.status === visit_status.pending ||
          visit_status === visit_status.complete_awaiting_ma)
      ) {
        recordPatientVisitted(visitDetails?.id);
      }
    }
  }, [visitDetails, recordPatientVisitted]);

  function getMAText(details) {
    switch (details?.status) {
      case visit_status.pending:
        return "your appointment has been confirmed. We will send you the zoom link in 10 minutes before your consultation.";
      case visit_status.evaluated:
        return "your consultation is complete.";
      case visit_status.rejected:
        return "your consultation could not be evaluated. You will not be charged for this consultation.";
      default:
        return "here are your consultation details.";
    }
  }

  if (isDiagnosed) {
    return (
      <>
        <VisitDiagnosedDetails visitDetails={visitDetails} />
        <Spacer height={80} />
        {visitDetails?.diagnosis &&
        Object.keys(visitDetails?.diagnosis).length ? (
          <div className="wrapper flex bg-white sticky bottom-0 shadow-d-xl">
            <ReceiptView visitDetails={visitDetails} />
          </div>
        ) : null}
      </>
    );
  } else {
    return (
      <div className="h-screen bg-veryLightBlue overflow-hidden">
        <div className="overflow-y-auto wrapper pt-10 bg-white h-eliminateHeader relative">
          <div className="px-12">
            <MedicalAssistant
              content={
                <p>
                  {visitDetails?.patient_name?.replace(/\s+/g, " ")},{" "}
                  {getMAText(visitDetails)}
                </p>
              }
            />
          </div>
          {visitDetails?.zoom_meeting && (
            <div className="p-12">
              <p className="text-2xl font-light leading-9">
                {visitDetails?.provider_name} has invited you to join a secure
                video call. To join the call, please click on the button below.
              </p>
              <a
                href={visitDetails?.zoom_meeting?.join_url}
                className={
                  "inline-block font-bold text-2xl py-4 px-16 focus:ring-2 bg-orange text-white rounded-lg cursor-pointer no-underline mt-6"
                }
              >
                Join
              </a>
            </div>
          )}
          {visitDetails?.evaluation_feedback_given ? null : isDiagnosed ? (
            <div className="mt-10">
              <RateProviderStrip
                selectedStarRating={selectedStarRating}
                setSelectedStarRating={setSelectedStarRating}
                survey_token={visitDetails?.evaluation_feedback_token}
                providerName={visitDetails?.provider_name}
                providerImage={visitDetails?.provider_photo_url}
              />
            </div>
          ) : null}
          <div className="px-12">
            <h2 className="font-black text-xl tracking-wide mt-10">
              CONSULTATION SUMMARY
            </h2>
          </div>
          <Spacer height={23} />
          {visitDetails?.speciality === "cosmetic" && (
            <CosmeticConsultationSummary
              readOnly
              data={{
                ...visitDetails,
                patient_photo_id: userDetails?.photo_id,
              }}
            />
          )}
          {visitDetails?.status === visit_status.pending ? null : (
            <h2 className="font-black text-xl tracking-wide mt-10 uppercase px-12">
              Consultation Report
            </h2>
          )}
          <Spacer height={23} />
          <div className="bg-alternativeWhite">
            <div className="px-12">
              {visitDetails?.status === visit_status.pending ? null : (
                // <div className="py-6">
                //   <p className="font-medium text-2xl">
                //     Your appointment has been confirmed, we will send you the zoom
                //     link 10 minutes before your consultation.
                //   </p>
                // </div>
                <div>
                  <div className="flex items-center py-6">
                    <ImageComponent url={visitDetails?.provider_photo_url} />
                    <div className="pl-8">
                      <h3 className="text-3xl font-semibold">
                        {visitDetails?.provider_name}
                      </h3>
                      <p className="text-xl font-light mt-2">
                        reviewed this consultation on{" "}
                        {moment(visitDetails?.diagnosis?.diagnosed_at).format(
                          "DD MMM, YYYY"
                        )}
                      </p>
                    </div>
                  </div>
                  <DiagnosisAccordion visitDetails={visitDetails} />
                </div>
              )}
            </div>
          </div>
          <Spacer height={80} />
          {visitDetails?.diagnosis &&
          Object.keys(visitDetails?.diagnosis).length ? (
            <div className="wrapper flex bg-white sticky bottom-0 shadow-d-xl">
              <ReceiptView visitDetails={visitDetails} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ConsultationDetails;
