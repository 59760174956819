import React from "react";
import Images from "assets/images";
import Accordion from "components/accordion/Accordion";
import AvailableOnSkySection from "./AvailableOnSkySection";
import NotAvailableOnSkySection from "./NotAvailableOnSkySection";
import { useHistory } from "react-router-dom";
import { useApiMutation } from "hooks/useApiMutation";
import { placeOtcOrder } from "api/actions/VisitActions";
import useToastify from "hooks/useToastify";

const OTCTabContent = ({ visitDetails, shippingAddress, setShowForm }) => {
  const history = useHistory();

  const { showAlert } = useToastify();

  const changeShippingAddress = () => {
    setShowForm(true);
  };

  const {
    mutate: callPlaceOtcOrder,
    loading: placingOtcOrder,
  } = useApiMutation(placeOtcOrder);

  const availableMeds = visitDetails?.diagnosis?.otc_medications?.filter(
    (el) => el.available
  );
  const unavailableMeds = visitDetails?.diagnosis?.otc_medications?.filter(
    (el) => !el.available
  );

  const completeOrder = async () => {
    const payload = {
      visit_id: visitDetails?.id,
      shipping_address: `${shippingAddress?.line_1}${
        shippingAddress?.line_2 === "" ? "" : `, ${shippingAddress?.line_2}`
      }, ${shippingAddress?.city}, ${shippingAddress?.state}, ${
        shippingAddress?.zip
      }`,
    };

    const result = await callPlaceOtcOrder(payload);

    if (result && result.error === false) {
      history.push(`/visit-details/${visitDetails?.id}/success`, {
        patientName: visitDetails?.patient_first_name,
      });
      showAlert(
        result?.payload?.message ?? "Order placed successfully",
        "success"
      );
    } else {
      if (result.status === 402) {
        history.push(`/visit-details/${visitDetails?.id}/error`, {
          patientName: visitDetails?.patient_first_name,
          payload,
        });
        showAlert(result?.payload?.message ?? "Payment failed", "error");
      } else {
        showAlert(result?.payload?.message ?? "Something went wrong", "error");
      }
    }
  };

  const OTCAccordionHeader = ({ headerTitle, isActive, enabled }) => (
    <div
      className={`flex items-start py-10 px-10 border-t border-borderColor
    ${enabled ? "bg-white" : "bg-lightGrey cursor-not-allowed"}
    `}
    >
      <div>
        <img
          src={Images.dropdown}
          alt="chev_down"
          className={`transition duration-500 ease-in-out w-10 h-10 transform ${
            isActive ? "rotate-0" : "-rotate-90"
          }`}
        />
      </div>
      <h3 className="ml-4 text-3xl font-medium">{headerTitle}</h3>
    </div>
  );

  const OTCAccordionContent = ({ children }) => (
    <div className="px-10 pb-8">{children}</div>
  );

  const accordionData = [
    {
      header: ({ isActive }) => (
        <OTCAccordionHeader
          headerTitle={
            visitDetails?.diagnosis?.order
              ? "Shipped from SkyMD Warehouse"
              : "Shipping from SkyMD Warehouse"
          }
          isActive={isActive}
          enabled={visitDetails?.diagnosis?.otc_medications.length > 0}
        />
      ),
      content: (
        <OTCAccordionContent>
          <AvailableOnSkySection
            availableMeds={availableMeds}
            visitDetails={visitDetails}
            completeOrder={completeOrder}
            completingOrder={placingOtcOrder}
            shippingAddress={shippingAddress}
            changeShippingAddress={changeShippingAddress}
          />
        </OTCAccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <OTCAccordionHeader
          headerTitle="Pick Up at Your Local Store"
          isActive={isActive}
          enabled={true}
        />
      ),
      content: (
        <OTCAccordionContent>
          <NotAvailableOnSkySection unavailableMeds={unavailableMeds} />
        </OTCAccordionContent>
      ),
    },
  ];

  return (
    <>
      {availableMeds.length > 0 && unavailableMeds.length > 0 ? (
        <Accordion
          data={accordionData}
          activeIndexFromData={0}
          disabledIndicesFromData={[]}
          enableTransition={true}
        />
      ) : availableMeds.length > 0 ? (
        <div className="px-10 pb-8">
          <p className="px-2 font-light text-3xl md:px-4">
            {visitDetails?.diagnosis?.order
              ? "Shipped from SkyMD Warehouse"
              : "Shipping from SkyMD Warehouse"}
          </p>
          <AvailableOnSkySection
            availableMeds={availableMeds}
            visitDetails={visitDetails}
            completeOrder={completeOrder}
            completingOrder={placingOtcOrder}
            shippingAddress={shippingAddress}
            changeShippingAddress={changeShippingAddress}
          />
        </div>
      ) : (
        <div className="px-10 pb-8">
          <p className="px-2 font-light text-3xl md:px-4">
            Pick up at your local store
          </p>
          <NotAvailableOnSkySection unavailableMeds={unavailableMeds} />
        </div>
      )}
    </>
  );
};

export default OTCTabContent;
