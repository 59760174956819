import { useEffect, useState } from "react";
import classNames from "classnames";
const Timer = ({ timer, onTimeOut }) => {
  const [secondsLeft, setSecondsLeft] = useState(timer);

  useEffect(() => {
    if (secondsLeft > 0) {
      const timer = setTimeout(() => {
        setSecondsLeft(secondsLeft - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      onTimeOut();
    }
  }, [secondsLeft, onTimeOut]);

  const remainingSeconds = secondsLeft;

  return (
    <div>
      {remainingSeconds < 10 ? "0" : ""}
      {remainingSeconds} seconds
    </div>
  );
};

const TimerButton = ({
  timer = 60,
  showTimer = false,
  onClick,
  buttonLabel,
  className,
  disabledText = "Wait for ",
  activeText = "Request OTP",
}) => {
  const [showButton, setShowButton] = useState(!showTimer ?? false);
  const [clicks, setClicks] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [time, setTimer] = useState(timer);

  useEffect(() => {
    setTimer(timer);
  }, [timer]);

  async function _onClick() {
    setShowButton(false);
    setClicks(clicks + 1);
    if (clicks >= 2) {
      setIsDisabled(true);
    } else {
      await onClick();
    }
  }
  return (
    <div>
      <button
        disabled={isDisabled || !showButton}
        onClick={_onClick}
        className={classNames(
          "font-medium  ",
          isDisabled || !showButton
            ? "text-2xl text-black cursor-not-allowed"
            : "text-secondayOrange",
          className
        )}
      >
        <div>
          {isDisabled || !showButton ? (
            <div className="flex space-x-2 text-black">
              <p>{disabledText}</p>
              <Timer timer={time} onTimeOut={() => setShowButton(true)} />
            </div>
          ) : (
            <div className="flex space-x-2">
              <p className="text-black ">{activeText}</p>
              <span>{buttonLabel}</span>
            </div>
          )}
        </div>
      </button>
      {/* {isDisabled && (
        <p className="text-sm text-left text-errorRed ">
          You have exceeded the maximum limit, Please try again later.
        </p>
      )} */}
    </div>
  );
};
export default TimerButton;
