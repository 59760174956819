import Spacer from "components/Spacer/Spacer";
import React from "react";
import classnames from "classnames";
function RegularSelect({ options, label, selected, setSelected }) {
  return (
    <div>
      <label className="font-medium text-2xl">{label && label}</label>
      <Spacer height={15} />
      <div className={"flex flex-row space-x-4 space-y-0 "}>
        {options?.map((el, idx) => {
          return (
            <div
              onClick={() => setSelected(el)}
              key={idx}
              className={classnames(
                "flex items-center cursor-pointer border px-5 py-2 rounded-md",
                selected === el
                  ? "border-secondaryBlue-600 text-secondaryBlue-600"
                  : "border-grey-600"
              )}
            >
              <p className="text-xl font-bold">{el}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RegularSelect;
