import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import SearchInputWithChipCard from "components/searchInputWithChip/SearchInputWithChipCard";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import { dermatology_sub_steps } from "helpers/enum";
import useModal from "hooks/useModal";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import useVisitStepChange from "hooks/useVisitStepChange";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import SelectAffectedPartModal from "./SelectAffectedPartModal";

function SelectAffectedPart() {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(null);

  const { setShowModal } = useModal();
  const { incompleteData, subSteps } = useDermatologyVisitFlow();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore =
    subSteps[dermatology_sub_steps.select_affected_part].value;

  const { updateVisit, updatingVisit } = useUpdateDermatologyVisit();

  useEffect(() => {
    if (currentScreenValueFromStore?.length) {
      setSelected(currentScreenValueFromStore);
    }
  }, [currentScreenValueFromStore]);

  useEffect(() => {
    if (selected?.length) {
      dispatch(
        dermaVisitFlowReducers.select_affected_part({
          isValid: true,
        })
      );
    } else {
      dispatch(
        dermaVisitFlowReducers.select_affected_part({
          isValid: false,
        })
      );
    }
  }, [dispatch, selected]);

  function onRightButtonClick(_, nextAnimation) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          affected_body_parts: selected,
          dermatology_steps: incompleteData?.dermatology_steps,
        },
      },
      change_current_screen: true,
      screen_to_complete: dermatology_sub_steps.select_affected_part,
      callBack: () => {
        dispatch(
          dermaVisitFlowReducers.select_affected_part({
            value: selected,
          })
        );
        nextAnimation();
      },
    });
  }

  return (
    <StepScreenContainer align="start">
      <MedicalAssistant
        content={<p>And, which part(s) of your body are affected?</p>}
      />
      <SearchInputWithChipCard
        inputLabel={"Search"}
        onClickInput={() => {
          if (updatingVisit) {
            return;
          } else {
            setShowModal({
              state: true,
              children: (_, onClose) => (
                <SelectAffectedPartModal
                  onClose={onClose}
                  selected={selected}
                  setSelected={setSelected}
                />
              ),
              data: null,
            });
          }
        }}
        selected={selected}
        setSelected={setSelected}
      />
    </StepScreenContainer>
  );
}

export default SelectAffectedPart;
