import React, { useEffect, useRef, useState } from "react";
import FormikErrorFocus from "formik-error-focus";
import Header from "components/header/Header";
import { ErrorMessage, Form, Formik } from "formik";
import Input from "components/inputs/Input";
import Images from "assets/images";
import Spacer from "components/Spacer/Spacer";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import { updateBasicInfoSchema } from "helpers/validationSchema";
import { useCurrentUser } from "hooks/useCurrentUser";
import moment from "moment";
import { speciality } from "helpers/enum";
import useDataFromUserDetails from "hooks/useDataFromUserDetails";
import {
  getGeoDataFromZipcodeAction,
  updateBasicInfoAction,
} from "api/actions/UserActions";
import { useParameterizedQuery } from "react-fetching-library";
import { useApiMutation } from "hooks/useApiMutation";
import LoaderComponent from "components/loader/LoaderComponent";
import usePatients from "hooks/visit/usePatients";
import RegularRadioSelect from "components/radioButtonCard/RegularRadioSelect";
import {
  decimalRestriction,
  getFeetInInches,
  getInchesInFeet,
} from "helpers/metricConversion";
import BirthInput from "components/inputs/BirthInput";
import { trackEvent } from "helpers/analytics";
import { isEmpty } from "helpers/lodashPolyfills";
function BasicDetailsForm({
  onClose,
  currentPatient,
  patientType,
  currentSpeciality,
}) {
  const formRef = useRef();
  const { userId } = useCurrentUser();
  const { dermaDoctorDetails } = useDataFromUserDetails();

  const { query: getZipDetails, loading: gettingZipCode } =
    useParameterizedQuery(getGeoDataFromZipcodeAction);
  const {
    mutate: updateBasicIfo,
    payload: userPayload,
    loading: updatingBasicDetails,
  } = useApiMutation(updateBasicInfoAction);

  const { getPatients, patientsList, fetchingPatients } = usePatients();

  // DOB Confirm Changes
  const dobInputRef = useRef();
  const [showDate, setShowDate] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);

  // Weight Confirm Changes
  const [showWeight, setShowWeight] = useState("");
  const [showWeightConfirm, setShowWeightConfirm] = useState(false);

  // Height Confirm Changes
  const [showHeight, setShowHeight] = useState("");
  const [showHeightConfirm, setShowHeightConfirm] = useState(false);

  useEffect(() => {
    if (userPayload) {
      getPatients();
    }
  }, [userPayload, getPatients]);

  useEffect(() => {
    if (patientsList) {
      onClose();
    }
  }, [patientsList, onClose]);

  async function onFormSubmit(values) {
    let payload = {
      id: userId,
      user: {
        patients_attributes: [
          {
            ...currentPatient,
            city: values.city.split(",")[0],
            dob: moment(values.dob, "YYYY-MM-DD").format("YYYY/MM/DD"),
            gender: values.gender.toLowerCase(),
            street_add_1: values.street_address_1,
            street_add_2: values.street_address_2,
            zip: values.zip_code,
            height: getFeetInInches({
              feet: values.heightFeet,
              inches: values.heightInches,
            }),
            height_metric: 1,
            weight: values.weight ? Number(values?.weight) : null,
            weight_metric: 0,
          },
        ],
      },
    };
    try {
      const { payload: zipResult } = await getZipDetails(values.zip_code);
      if (zipResult?.geo_data) {
        if (currentSpeciality === speciality.dermatology) {
          if (
            dermaDoctorDetails?.licensed_states?.includes(
              zipResult?.geo_data?.state_code
            )
          ) {
            payload.user.patients_attributes[0].state =
              zipResult?.geo_data?.state_name;
            updateBasicIfo({ userId, payload });
          } else {
            alert(
              "The state of residence is outside of the coverage area of this provider. Please change your zipcode and try again."
            );
            return;
          }
        } else if (currentSpeciality === speciality.primary_care) {
          // if (
          //   pcDoctorDetails?.licensed_states?.includes(
          //     zipResult?.geo_data?.state_code
          //   )
          // ) {
          payload.user.patients_attributes[0].state =
            zipResult?.geo_data?.state_name;
          updateBasicIfo({ userId, payload });
          // } else {
          //   alert(
          //     "The state of residence is outside of the coverage area of this provider. Please change your zipcode and try again."
          //   );
          //   return;
          // }
        }
      } else {
        alert("Invalid zipcode.");
        return;
      }
    } catch (error) {
      alert("Something went wrong. Please try again later.");
    }
  }

  const initialCity = currentPatient?.city
    ? currentPatient?.state
      ? `${currentPatient?.city}, ${currentPatient?.state}`
      : currentPatient?.city
    : "";

  if (fetchingPatients) {
    return (
      <div className="h-screen">
        <LoaderComponent
          showLoader={{
            state: true,
          }}
        />
      </div>
    );
  }

  return (
    <div className="h-full bg-veryLightBlue">
      <Header
        title="Basic Details"
        hideBackBtn
        rightActionView={
          <img
            onClick={onClose}
            className="w-10 cursor-pointer"
            src={Images.closeIcon}
            alt={"close_icons"}
          />
        }
      />
      <div className="overflow-y-scroll wrapper h-eliminateHeaderWithFooter bg-blue">
        <div className="min-h-full px-10 py-8 pb-64">
          <MedicalAssistant
            content={
              <p>
                Please provide the below information for your medical chart with
                our practice.
              </p>
            }
          />
          <Formik
            innerRef={formRef}
            initialValues={{
              street_address_1: currentPatient?.street_add_1 ?? "",
              street_address_2: currentPatient?.street_add_2 ?? "",
              city: initialCity,
              type: patientType,
              zip_code: currentPatient?.zip ?? "",
              dob: currentPatient?.dob ?? "",
              gender: currentPatient?.gender
                ? currentPatient?.gender.charAt(0).toUpperCase() +
                  currentPatient?.gender.slice(1)
                : "",
              heightFeet: currentPatient?.height
                ? getInchesInFeet({ height: currentPatient?.height }).feet
                : "",
              heightInches: currentPatient?.height
                ? getInchesInFeet({ height: currentPatient?.height }).inches
                : "",
              weight: currentPatient?.weight ?? "",
            }}
            validationSchema={updateBasicInfoSchema}
            onSubmit={onFormSubmit}
          >
            {({ setFieldValue, setFieldTouched, values, dirty, errors }) => (
              <Form className="p-12 bg-white rounded-3xl mt-14">
                <Input
                  name="street_address_1"
                  label="Street Address Line 1"
                  id="street_address_1"
                  placeholder=""
                  maxLength="35"
                />
                <Spacer height={32} />
                <Input
                  name="street_address_2"
                  label="Street Address Line 2"
                  id="street_address_2"
                  placeholder=""
                  maxLength="35"
                />
                <Spacer height={32} />
                <div className="flex flex-col space-x-0 space-y-12 md:flex-row md:space-x-4 md:space-y-0">
                  <div className="flex-1">
                    <Input name="city" label="City" id="city" placeholder="" />
                  </div>
                  <div className="flex-1">
                    <Input
                      name="zip_code"
                      label="Zipcode"
                      id="zip_code"
                      placeholder="10001"
                      maxLength={5}
                    />
                  </div>
                </div>
                <Spacer height={32} />
                {/* DOB Confirm Changes */}
                <BirthInput
                  ref={dobInputRef}
                  name="dob"
                  label="Date of Birth"
                  id="dob"
                  max={moment().format("YYYY-MM-DD")}
                  isOpen={openCalendar}
                  open={() => setOpenCalendar(true)}
                  close={() => setOpenCalendar(false)}
                  onBlur={() => {
                    if (dirty) {
                      setFieldTouched("dob", true);
                      setShowConfirm(true);
                    }
                  }}
                  onChange={(event) => {
                    const date = event.target.value
                      ? moment(event.target.value).format("YYYY-MM-DD")
                      : "";
                    setFieldValue("dob", date);

                    if (date !== showDate) {
                      setShowDate(event.target.value ?? "");
                    }
                  }}
                />
                <Spacer height="32" />
                <div className="flex flex-wrap items-start">
                  <div className="flex items-end flex-1 space-x-4 md:flex-initial">
                    <div className="flex-1 md:flex-initial">
                      <Input
                        type="unit"
                        label="Height"
                        id="heightFeet"
                        name="heightFeet"
                        unit="ft"
                        min="1"
                        max="9"
                        customonblur={() => {
                          if (values?.heightFeet) {
                            setShowHeight(
                              `${decimalRestriction({
                                number: Number(
                                  values.heightInches !== undefined &&
                                    values.heightInches > 0
                                    ? `${parseInt(
                                        values?.heightFeet
                                      )}.${parseInt(values?.heightInches)}`
                                    : parseInt(values?.heightFeet)
                                ),
                              })} ft`
                            );
                            // setShowHeightConfirm(true);
                            setFieldValue(
                              "heightFeet",
                              parseInt(values?.heightFeet)
                            );
                          }
                        }}
                      />
                    </div>
                    <div className="flex-1 md:flex-initial">
                      <Input
                        type="unit"
                        id="heightInches"
                        name="heightInches"
                        unit="in"
                        min="0"
                        max="11"
                        onFocus={() => setShowHeightConfirm(false)}
                        customonblur={() => {
                          if (values.heightInches !== "") {
                            setShowHeight(
                              `${decimalRestriction({
                                number: Number(
                                  values.heightInches !== undefined &&
                                    values.heightInches > 0
                                    ? `${parseInt(
                                        values?.heightFeet
                                      )}.${parseInt(values?.heightInches)}`
                                    : parseInt(values?.heightFeet)
                                ),
                              })} ft`
                            );
                            // setShowHeightConfirm(true);
                            setFieldValue(
                              "heightInches",
                              parseInt(
                                values.heightInches !== undefined
                                  ? values.heightInches
                                  : "0"
                              )
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex-1 ml-4 md:flex-initial">
                    <Input
                      style={{ width: "6ch" }}
                      type="unit"
                      label="Weight"
                      id="weight"
                      name="weight"
                      min="1"
                      unit="lbs"
                      customonblur={() => {
                        if (values?.weight) {
                          setShowWeight(
                            `${decimalRestriction({
                              number: Number(values?.weight),
                            })} lbs`
                          );
                          // setShowWeightConfirm(true);
                          setFieldValue(
                            "weight",
                            decimalRestriction({
                              number: Number(values?.weight),
                            })
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="flex items-baseline justify-between ml-0 md:ml-4">
                    <div className="flex flex-col flex-1 space-y-2">
                      <RegularRadioSelect
                        options={["Male", "Female"]}
                        selected={values?.gender}
                        setSelected={(el) => {
                          setFieldValue("gender", el);
                          trackEvent(`gender_selected_${el?.toLowerCase()}`);
                        }}
                      />
                      <ErrorMessage
                        name="gender"
                        className="mt-2 text-base font-bold text-red"
                        component="p"
                      />
                      <FormikErrorFocus
                        align={"bottom"}
                        ease={"linear"}
                        duration={500}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <BottomFixedButton
        type="submit"
        label="Submit"
        onClick={() => {
          if (
            !isEmpty(formRef?.current?.errors) &&
            Object.keys(formRef?.current?.errors || {}).length > 0
          ) {
            trackEvent(
              `basic_details_error_${
                Object.keys(formRef?.current?.errors || {})[0]
              }`,
              {
                errors: { ...Object.keys(formRef?.current?.errors || {}) },
              }
            );
          } else {
            formRef?.current?.handleSubmit();
          }
        }}
        loading={gettingZipCode || updatingBasicDetails}
      />
      {/* DOB Confirm Changes */}
      {showConfirm ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-60">
          <div className="flex flex-col w-11/12 max-w-2xl p-8 space-y-8 bg-white">
            <p className="text-3xl font-bold">Confirm Date of Birth</p>
            <p className="text-2xl font-medium leading-9">
              You've entered {moment(showDate).format("MM-DD-YYYY")} as date of
              birth. Click CONFIRM to proceed or EDIT to change the date.
            </p>
            <div className="flex items-center justify-end space-x-4">
              <button
                className="p-4 text-xl font-bold uppercase rounded bg-opacity-10 text-indigo min-w-32"
                onClick={() => {
                  if (dobInputRef.current) {
                    dobInputRef.current.focus();
                  }
                  setOpenCalendar(true);
                  setShowConfirm(false);
                }}
              >
                Edit
              </button>
              <button
                className="flex items-center justify-center p-4 text-xl font-bold uppercase rounded bg-indigo bg-opacity-20 text-indigo min-w-32"
                onClick={() => {
                  setShowConfirm(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
      {/* Weight Confirm Changes */}
      {showWeightConfirm ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-60">
          <div className="flex flex-col w-11/12 max-w-2xl p-8 space-y-8 bg-white">
            <p className="text-3xl font-bold">Confirm Weight</p>
            <p className="text-2xl font-medium leading-9">
              You've entered <span className="font-bold">{showWeight}</span> as
              weight. Click CONFIRM to proceed or EDIT to change the weight.
            </p>
            <div className="flex items-center justify-end space-x-4">
              <button
                className="p-4 text-xl font-bold uppercase rounded bg-opacity-10 text-indigo min-w-32"
                onClick={() => {
                  setShowWeightConfirm(false);
                }}
              >
                Edit
              </button>
              <button
                className="flex items-center justify-center p-4 text-xl font-bold uppercase rounded bg-indigo bg-opacity-20 text-indigo min-w-32"
                onClick={() => {
                  setShowWeightConfirm(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
      {/* Height Confirm Changes */}
      {showHeightConfirm ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-60">
          <div className="flex flex-col w-11/12 max-w-2xl p-8 space-y-8 bg-white">
            <p className="text-3xl font-bold">Confirm Height</p>
            <p className="text-2xl font-medium leading-9">
              You've entered <span className="font-bold">{showHeight}</span> as
              height. Click CONFIRM to proceed or EDIT to change the height.
            </p>
            <div className="flex items-center justify-end space-x-4">
              <button
                className="p-4 text-xl font-bold uppercase rounded bg-opacity-10 text-indigo min-w-32"
                onClick={() => {
                  setShowHeightConfirm(false);
                }}
              >
                Edit
              </button>
              <button
                className="flex items-center justify-center p-4 text-xl font-bold uppercase rounded bg-indigo bg-opacity-20 text-indigo min-w-32"
                onClick={() => {
                  setShowHeightConfirm(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

export default BasicDetailsForm;
