import { createCase } from "api/actions/VisitActions";
import { CURRENT_SOURCE } from "helpers/currentSource";
import {
  ped_main_steps,
  ped_sub_steps,
  speciality,
  step_status,
} from "helpers/enum";
import { useApiMutation } from "hooks/useApiMutation";
import useToastify from "hooks/useToastify";
import { usePedVisitFlow } from "hooks/useVisitFlow";
import useVisitStepChange from "hooks/useVisitStepChange";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { pedVisitFlowReducers } from "reducers/ped/pedVisitFlowReducer";
import useIncompleteData from "./useIncompleteData";

function useCreatePedVisit() {
  const dispatch = useDispatch();
  const { showAlert } = useToastify();
  const { setLoading } = useVisitStepChange();
  const { currentScreen, subSteps } = usePedVisitFlow();
  const { mutate: callCreateCase, loading: creatingVisit } = useApiMutation(
    createCase
  );
  const { fetchIncompleteData, fetchingIncompleteData } = useIncompleteData();

  useEffect(() => {
    if (creatingVisit || fetchingIncompleteData) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [creatingVisit, setLoading, fetchingIncompleteData]);

  async function createNewVisit({
    patient_id,
    change_current_screen,
    screen_to_complete,
    callBack,
  }) {
    const payload = {
      case: {
        meta: {
          progress_percentage: 25,
        },
        speciality: speciality.ped,
        pediatric_steps: {
          main_steps: {
            [ped_main_steps.visit_details]: "current",
          },
          sub_steps: {
            [ped_sub_steps.select_patient]: "current",
          },
        },
        patient_id: patient_id,
        pharmacy_details: {},
        visit_start_source: CURRENT_SOURCE,
      },
    };
    try {
      const result = await callCreateCase(payload);
      if (result && result.error === false) {
        const incompleteDataResponse = await fetchIncompleteData(
          speciality.ped
        );
        if (incompleteDataResponse) {
          if (screen_to_complete) {
            dispatch(
              pedVisitFlowReducers?.[screen_to_complete]({
                status: step_status.completed,
              })
            );
          }
          callBack();
          if (change_current_screen) {
            dispatch(
              pedVisitFlowReducers.currentScreen({
                value: subSteps?.[currentScreen]?.nextScreen,
              })
            );
          }
        }
      } else {
        showAlert(
          result?.payload?.error_message ?? "Something went wrong",
          "error"
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return { createNewVisit, creatingVisit };
}

export default useCreatePedVisit;
