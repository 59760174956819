import TextWithBulletPoint from "../TextWithBulletPoint";

export const getMedicationsView = (payload) => {
  if (payload?.medications?.length) {
    return (
      <div className="p-8 bg-alternativeWhite border border-borderColor mt-8 rounded-xl">
        {payload?.medications?.map((el, idx) => {
          return (
            <div className="mb-4" key={idx}>
              <TextWithBulletPoint
                text={`${el?.drug_name}, ${el?.dose}, ${el?.frequency} per ${el?.per}, ${el?.form}`}
              />
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};
