import React, { createContext, useState, useMemo } from "react";

export const FullPageLoaderContext = createContext({});

function FullPageLoaderProvider({ children }) {
  const [showLoader, setShowLoader] = useState({
    state: false,
    message: null,
    type: null,
  });

  const value = useMemo(
    () => ({
      showLoader,
      setShowLoader,
    }),
    [showLoader, setShowLoader]
  );

  return (
    <FullPageLoaderContext.Provider value={value}>
      {children}
    </FullPageLoaderContext.Provider>
  );
}

export default FullPageLoaderProvider;
