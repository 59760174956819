import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Images from "assets/images";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import Header from "components/header/Header";
import useIncompleteData from "hooks/visit/useIncompleteData";
import LoaderComponent from "components/loader/LoaderComponent";
import { currentSpeciality } from "reducers/currentUserReducer";
import { useDispatch } from "react-redux";
import { speciality } from "helpers/enum";
import useDataFromUserDetails from "hooks/useDataFromUserDetails";
import { useMemo } from "react";
import {
  getInactiveMessage,
  getLicenseExpiredMessage,
  getServiceNotAvailableMessage,
} from "helpers/getProviderError";
import { useCurrentUser } from "hooks/useCurrentUser";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Spacer from "components/Spacer/Spacer";
import { useApiQuery } from "hooks/useApiQuery";
import { currentUserAction } from "api/actions/UserActions";

function SelectVisitType() {
  const [selected, setSelected] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { payload: userDetails } = useApiQuery(currentUserAction);

  const { currentSpeciality: currentSpecialityValue } = useCurrentUser();

  const {
    fetchIncompleteData,
    incompleteData,
    fetchingIncompleteData,
  } = useIncompleteData();
  const {
    isInactiveDermaProvider,
    isInactivePcProvider,
    isInactiveDermaProviderLicense,
    isInactivePcProviderLicense,
    isPcServiceAvailable,
    dermaDoctorDetails,
    pcDoctorDetails,
    hasNoDermaProvider,
    // hasNoPcProvider,
  } = useDataFromUserDetails();

  const visits = useMemo(() => {
    return [
      {
        name: "Family Practice: Primary Care",
        visit_for:
          "Cough, breathing or asthma symptoms, sore throat, painful joints, diabetes, anxiety, depression, headache, abdominal pain, urinary/bladder symptoms etc..",
        disabled:
          isInactivePcProvider ||
          isInactivePcProviderLicense ||
          !isPcServiceAvailable,
        error_message: isInactivePcProvider
          ? getInactiveMessage({
              provider_name: pcDoctorDetails?.provider_name,
            })
          : isInactivePcProviderLicense
          ? getLicenseExpiredMessage({
              provider_name: pcDoctorDetails?.provider_name,
            })
          : !isPcServiceAvailable
          ? getServiceNotAvailableMessage({
              specialityNumber: speciality.primary_care,
            })
          : null,
      },
      {
        name: "Dermatology",
        visit_for:
          "Anything skin related such as acne, rashes, psoriasis, eczema, suspicious lesions, itching etc..",
        disabled: isInactiveDermaProvider || isInactiveDermaProviderLicense,
        error_message: isInactiveDermaProvider
          ? getInactiveMessage({
              provider_name: dermaDoctorDetails?.provider_name,
            })
          : isInactiveDermaProviderLicense
          ? getLicenseExpiredMessage({
              provider_name: dermaDoctorDetails?.provider_name,
            })
          : null,
      },
    ];
  }, [
    isInactiveDermaProvider,
    isInactivePcProvider,
    isInactiveDermaProviderLicense,
    isInactivePcProviderLicense,
    isPcServiceAvailable,
    dermaDoctorDetails?.provider_name,
    pcDoctorDetails?.provider_name,
  ]);

  useEffect(() => {
    if (currentSpecialityValue === speciality.dermatology) {
      setSelected(visits?.[1]);
    } else if (currentSpecialityValue === speciality.primary_care) {
      setSelected(visits?.[0]);
    }
  }, [currentSpecialityValue, visits]);

  useEffect(() => {
    if (currentSpecialityValue) {
      fetchIncompleteData(currentSpecialityValue);
    }
  }, [fetchIncompleteData, currentSpecialityValue]);

  // useEffect(() => {
  //   if (incompleteData && Object.keys(incompleteData)?.length) {
  //     history.goBack();
  //   }
  // }, [incompleteData, history]);

  if (fetchingIncompleteData) {
    return (
      <LoaderComponent
        showLoader={{
          state: true,
        }}
      />
    );
  }

  return (
    <div className="h-screen bg-veryLightBlue">
      <Header
        title="Visit Type"
        onBackButtonClick={() => history.replace("/home")}
      />
      <div className="wrapper overflow-y-auto h-eliminateHeaderWithFooter bg-blue">
        <div
          className={`overflow-x-hidden p-10 min-h-full flex flex-col justify-start shadow-lg`}
        >
          <MedicalAssistant content={<p>Please select a visit type.</p>} />
          <Spacer height={40} />
          <div className="bg-white rounded-3xl p-6">
            {visits?.map((visit, index) => {
              return (
                <div
                  key={index}
                  className={`${
                    visit?.disabled
                      ? "opacity-50 cursor-not-allowed"
                      : "opacity-100"
                  } cursor-pointer border  rounded-3xl p-6 ${
                    index === 0 ? "mb-5" : ""
                  } ${
                    selected?.name === visit?.name
                      ? "border-orange"
                      : "border-borderColor"
                  }`}
                  onClick={() => (visit?.disabled ? null : setSelected(visit))}
                >
                  <div className="flex items-center">
                    <img
                      className="w-8 h-8 object-contain"
                      src={
                        selected?.name === visit?.name
                          ? Images.checkedOrange
                          : Images.uncheckedCircle
                      }
                      alt="radio"
                    />
                    <h3 className="font-bold text-2xl pl-4">{visit?.name}</h3>
                  </div>
                  {visit?.error_message ? (
                    <div className="mt-4 px-6 pb-2">
                      <p className="font-medium text-red text-xl">
                        {visit?.error_message}
                      </p>
                    </div>
                  ) : null}
                  <div className="bg-alternativeWhite p-6 mt-5 rounded-2xl">
                    {visit?.name === "Dermatology" &&
                    userDetails?.incomplete_dermatology_id ? (
                      <>
                        <p className="font-bold text-xl">
                          Continue your dermatology visit
                        </p>
                        <p className="text-xl font-light mt-2 leading-8">
                          You've filled{" "}
                          {userDetails?.incomplete_dermatology_meta
                            ?.progress_percentage ?? 0}
                          % of details till now
                        </p>
                      </>
                    ) : visit?.name === "Family Practice: Primary Care" &&
                      userDetails?.incomplete_primary_care_id ? (
                      <>
                        <p className="font-bold text-xl">
                          Continue your primary care visit
                        </p>
                        <p className="text-xl font-light mt-2 leading-8">
                          You've filled{" "}
                          {userDetails?.incomplete_primary_care_meta
                            ?.progress_percentage ?? 0}
                          % of details till now
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="font-bold text-xl">Visits for</p>
                        <p className="text-xl font-light mt-2 leading-8">
                          {visit?.visit_for}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <BottomFixedButton
        onClick={() => {
          if (selected?.name === "Dermatology") {
            dispatch(
              currentSpeciality({
                currentSpeciality: speciality.dermatology,
              })
            );
            if (hasNoDermaProvider) {
              history.push("/visit/medical-intake");
            } else {
              history.push("/visit/dermatology");
            }
          } else if (selected?.name === "Family Practice: Primary Care") {
            dispatch(
              currentSpeciality({
                currentSpeciality: speciality.primary_care,
              })
            );
            if (incompleteData && incompleteData.meta) {
              switch (incompleteData?.phase) {
                case "visits_started":
                  // Redirect for when continuing a new visit (first phase)
                  history.push("/visit/primary-care/new-visit");
                  break;
                case "first_phase":
                  // Redirect for when continuing a new visit (second phase)
                  history.push("/visit/primary-care/complete-visit-details");
                  break;
                default:
                  // Redirect for when continuing an old visit
                  history.push("/visit/primary-care");
              }
            } else {
              // Redirect for when starting a new visit
              history.push("/visit/primary-care/new-visit");
            }
          } else {
            return;
          }
        }}
        disabled={!selected}
        label="Proceed"
      />
    </div>
  );
}

export default SelectVisitType;
