import Images from "assets/images";
import CSSLoader from "components/cssLoader/CSSLoader";
import Spacer from "components/Spacer/Spacer";
import React from "react";

function PharmacyCard({
  onClickEdit,
  removeEdit,
  pharmacyAddess,
  onClickPharmacy,
  loading,
}) {
  return (
    <div
      className={`flex items-center justify-between ${
        onClickPharmacy ? "cursor-pointer" : "cursor-default"
      }`}
      onClick={onClickPharmacy ?? null}
    >
      <div
        className={`flex ${
          pharmacyAddess?.length && pharmacyAddess !== "None"
            ? ""
            : "items-center"
        }`}
      >
        <div
          onClick={removeEdit ? null : onClickEdit}
          className={`bg-gradient-to-r from-lightPurple  to-purple flex items-center justify-center min-w-6.9 min-h-6.9 w-24 h-24 rounded-1/4 ${
            removeEdit ? "" : "cursor-pointer"
          }`}
        >
          <img
            className="w-8"
            src={Images.addLocationIcon}
            alt="location_icon"
          />
        </div>
        <div className="px-6">
          {pharmacyAddess?.length && pharmacyAddess !== "None" ? (
            <>
              <h2 className="text-2xl font-bold">
                {pharmacyAddess?.split("\n")?.[0]}
              </h2>
              <Spacer height={7} />
              <p
                className="text-xl leading-8"
                dangerouslySetInnerHTML={{
                  __html: pharmacyAddess
                    ?.split("\n")
                    ?.filter((_, idx) => idx !== 0)
                    ?.join("\n"),
                }}
              ></p>
            </>
          ) : (
            <h2 className="text-2xl font-bold">Add pharmacy</h2>
          )}
        </div>
      </div>
      {removeEdit ? null : loading ? (
        <CSSLoader className="w-16" color="orange" />
      ) : (
        <img
          className="w-16 cursor-pointer"
          onClick={onClickEdit}
          src={Images.editIconInverted}
          alt={"edit"}
        />
      )}
    </div>
  );
}

export default PharmacyCard;
