import React from "react";
import ApiConfig from "api/config";
import useToastify from "hooks/useToastify";
import { useCurrentUser } from "hooks/useCurrentUser";
import Spacer from "components/Spacer/Spacer";
import SimpleHeader from "components/header/SimpleHeader";

function Referrals() {
  const { userDetails } = useCurrentUser();
  const { showAlert } = useToastify();

  const referralUrl = `${ApiConfig.baseUrl}r/${userDetails?.invite_code}`;

  const share = async () => {
    if (navigator.share) {
      await navigator.share({
        title: "Try out SkyMD - online medical care application",
        text: "Use my code below to get exclusive offers",
        url: referralUrl,
      });
    } else {
      await navigator.clipboard.writeText(referralUrl);
      showAlert("Referral link copied to clipboard", "info");
    }
  };

  return (
    <div className="flex flex-col w-full overflow-hidden bg-blue">
      <SimpleHeader title={"Referrals"} />
      <div className={"overflow-y-auto flex-1"}>
        <div className="relative flex flex-col items-center justify-center px-10 mb-32 md:mb-0 h-eliminateHeader ">
          <h1 className="text-4xl font-bold text-center text-white">
            Are you happy with SkyMD's service?
          </h1>
          <Spacer height={20} />
          <p className="text-2xl font-medium leading-8 text-center text-white">
            Spread the word by sharing your invite link with your family and
            friends!
          </p>
          <Spacer height={30} />
          <div
            onClick={share}
            className="relative flex items-center justify-center p-8 overflow-hidden cursor-pointer bg-orange rounded-2xl"
          >
            <p className="text-3xl font-bold leading-10 text-center text-white break-all">
              {referralUrl}
            </p>
            <div className="absolute w-6 h-6 bg-blue -left-3 rounded-2xl"></div>
            <div className="absolute w-6 h-6 bg-blue -right-3 rounded-2xl"></div>
          </div>
          <Spacer height={20} />
          <p className="text-2xl font-medium leading-10 text-white">
            Tap above to copy link.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Referrals;
