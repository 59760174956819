import React, { useEffect, useState } from "react";
import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import VisitSubQuestionSlideUpContainer from "components/visitSubQuestionSlideUp/VisitSubQuestionSlideUpContainer";
import { cosmetic_steps } from "helpers/enum";
import scrollToBottom from "helpers/scrollToBottom";
import useUpdateCosmeticConsultation from "hooks/consultation/useUpdateCosmeticConsultation";
import { useCosmeticConsultationFlow } from "hooks/useConsultationFlow";
import useVisitStepChange from "hooks/useVisitStepChange";
import { useDispatch } from "react-redux";
import { cosmeticConsultationReducers } from "reducers/cosmetic/cosmeticConsultationReducer";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import SearchInputAndItemList from "components/serachInputAndItemsList/SearchInputAndItemList";

function CosmeticAllergies() {
  const [selected, setSelected] = useState(null);
  const [selectedAllergies, setSelectedAllergies] = useState([]);

  const dispatch = useDispatch();
  const { steps, incompleteData } = useCosmeticConsultationFlow();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore = steps[cosmetic_steps.allergies].value;

  const { updateConsultation } = useUpdateCosmeticConsultation();

  useEffect(() => {
    if (currentScreenValueFromStore?.length) {
      if (
        currentScreenValueFromStore?.includes("None") &&
        currentScreenValueFromStore?.length === 1
      ) {
        setSelected("No");
        setSelectedAllergies([]);
      } else {
        setSelected("Yes");
        setSelectedAllergies(currentScreenValueFromStore);
      }
    }
  }, [currentScreenValueFromStore]);

  useEffect(() => {
    if (selected === "Yes") {
      scrollToBottom();
    }
  }, [selected]);

  useEffect(() => {
    if (selected === "No") {
      dispatch(
        cosmeticConsultationReducers.allergies({
          isValid: true,
        })
      );
    } else if (selected === "Yes") {
      if (selectedAllergies?.length) {
        dispatch(
          cosmeticConsultationReducers.allergies({
            isValid: true,
          })
        );
      } else {
        dispatch(
          cosmeticConsultationReducers.allergies({
            isValid: false,
          })
        );
      }
    }
  }, [dispatch, selected, selectedAllergies]);

  function onRightButtonClick(_, nextAnimation) {
    updateConsultation({
      payload: {
        case: {
          id: incompleteData?.id,
          allergies:
            selected === "Yes"
              ? selectedAllergies?.map((el) => ({ name: el }))
              : [{ name: "None" }],
          cosmetic_steps: incompleteData?.cosmetic_steps,
        },
      },
      change_current_screen: true,
      screen_to_complete: cosmetic_steps.allergies,
      callBack: () => {
        dispatch(
          cosmeticConsultationReducers.allergies({
            value: selected === "Yes" ? selectedAllergies : ["None"],
          })
        );
        nextAnimation();
      },
    });
  }

  return (
    <StepScreenContainer align="start">
      <MedicalAssistant
        content={
          <p>
            And, do you have any allergies?
            <br />
            <br />
            Please list any reactions to medications, food, insects, etc.
          </p>
        }
      />
      <Card>
        <RadioButtonCard
          options={["Yes", "No"]}
          selected={selected}
          onSelect={setSelected}
        />
      </Card>
      {selected === "Yes" ? (
        <VisitSubQuestionSlideUpContainer>
          <SearchInputAndItemList
            inputLabel={"Search Allergy"}
            keyToSearch={"allergies"}
            selected={selectedAllergies}
            setSelected={setSelectedAllergies}
          />
        </VisitSubQuestionSlideUpContainer>
      ) : null}
    </StepScreenContainer>
  );
}

export default CosmeticAllergies;
