import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import LoginPage from "pages/auth/LoginPage";
import SignupPage from "pages/auth/SignupPage";
import AuthGuard from "./authGuard";
import TokenAvailableGuard from "./guardIfTokenAvailable";
import HomePage from "pages/home/HomePage";
import ForgotPassword from "pages/auth/ForgotPassword";
import VisitFlowRoute from "pages/home/waitingRoom/visitFlow/VisitFlowRoute";
import ChangeProvider from "pages/home/waitingRoom/providersBlock/ChangeProvider";
import ChangeProviderGuard from "./changeProviderGuard";
import PageNotFound from "pages/home/PageNotFound";
import { useCurrentUser } from "hooks/useCurrentUser";
import { speciality } from "helpers/enum";
import VisitDetails from "pages/home/myVisits/VisitDetails";
import ChatScreen from "pages/home/myVisits/chat/ChatScreen";
import CosmeticConsultationRoutes from "pages/home/waitingRoom/consultation/cosmetic/CosmeticConsultationRoutes";
import ResetPassword from "pages/auth/ResetPassword";
import OTCSuccess from "pages/home/myVisits/otcFlow/OTCSuccess";
import OTCError from "pages/home/myVisits/otcFlow/OTCError";
import Payments from "pages/home/myVisits/otcFlow/Payments";
import VisitSummary from "pages/home/myVisits/otcFlow/VisitSummary";
import PhoneInputPage from "pages/auth/PhoneInput";
import OTPVerifyPage from "pages/auth/OTPVerifyPage";
import PendingPayments from "pages/home/PendingPayments";

function AppPaths() {
  const {
    userDetails,
    currentSpeciality: currentSpecialityValue,
  } = useCurrentUser();

  function getIncompleteVisitRoute() {
    if (currentSpecialityValue === speciality.dermatology) {
      return "/visit/dermatology";
    } else if (currentSpecialityValue === speciality.primary_care) {
      return "/visit/primary-care";
    } else {
      return "/visit";
    }
  }

  function getNewVisitRoute() {
    if (userDetails?.incomplete_visit_id) {
      return getIncompleteVisitRoute();
    } else {
      return "/visit";
    }
  }

  function getSignupDeeplink() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const { pid, puid, insuranceId, landing, source } = Object.fromEntries(
      urlSearchParams.entries()
    );
    if (landing && source && pid && puid && insuranceId) {
      return `/signup/${pid}/${puid}/${insuranceId}?landing=${landing}&source=${source}`;
    } else if (landing && source && pid && puid) {
      return `/signup/${pid}/${puid}?landing=${landing}&source=${source}`;
    } else if (landing && source) {
      return `/signup?landing=${landing}&source=${source}`;
    } else if (pid && puid && insuranceId) {
      return `/signup/${pid}/${puid}/${insuranceId}`;
    } else if (pid && puid) {
      return `/signup/${pid}/${puid}`;
    } else {
      return "/signup";
    }
  }

  function getResetPasswordDeeplink() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const { token } = Object.fromEntries(urlSearchParams.entries());
    if (token) {
      return `/reset-password/${token}`;
    } else {
      return "/reset-password";
    }
  }

  const handleOnActive = (event) => {
    if (userDetails) {
      window.location.replace("/home");
    }
  };

  const handleOnAction = (event) => {
    if (userDetails && getRemainingTime === 0) {
      window.location.replace("/home");
    }
  };

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * 60 * 8,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  return (
    <Router>
      <Switch>
        <Redirect exact from="/" to="/home" />

        {/* DEEP LINK URLS */}
        <Redirect from="/dl/home" to="/home" />
        <Redirect from="/dl/payments" to="/home/profile/payments" />
        <Redirect from="/dl/new-visit" to={getNewVisitRoute()} />
        <Redirect from="/dl/incomplete-visit" to={getIncompleteVisitRoute()} />
        <Redirect from="/dl/incomplete-consultation" to={"/consultation"} />
        <Redirect from="/dl/visits/:visit_id" to={"/visit-details/:visit_id"} />
        <Redirect from="/dl/chat/:visit_id" to={"/chat/:visit_id"} />
        <Redirect from="/dl/signup" to={getSignupDeeplink()} />
        <Redirect from="/dl/reset-password" to={getResetPasswordDeeplink()} />
        <Redirect
          from="/dl/health-profile/:patient_id"
          to={`/home/profile/health-profile/:patient_id`}
        />
        {/* END OF DEEP LINK URLS */}

        <TokenAvailableGuard exact={true} path="/phone">
          <PhoneInputPage />/
        </TokenAvailableGuard>
        <TokenAvailableGuard exact={true} path="/phone/:number">
          <PhoneInputPage />
        </TokenAvailableGuard>
        <TokenAvailableGuard path="/otp">
          <OTPVerifyPage />
        </TokenAvailableGuard>
        <TokenAvailableGuard exact={true} path="/signup">
          <SignupPage />
        </TokenAvailableGuard>
        <TokenAvailableGuard path="/signup/:pid/:puid/:insuranceId/:zipCode">
          <SignupPage />
        </TokenAvailableGuard>
        <TokenAvailableGuard path="/signup/:pid/:puid/:insuranceId">
          <SignupPage />
        </TokenAvailableGuard>
        <TokenAvailableGuard path="/signup/:pid/:puid">
          <SignupPage />
        </TokenAvailableGuard>
        <TokenAvailableGuard path="/signup/:slotId/">
          <SignupPage />
        </TokenAvailableGuard>
        <TokenAvailableGuard path="/login">
          <LoginPage />
        </TokenAvailableGuard>
        <TokenAvailableGuard path="/forgot-password">
          <ForgotPassword />
        </TokenAvailableGuard>
        <TokenAvailableGuard path="/reset-password/:token">
          <ResetPassword />
        </TokenAvailableGuard>
        <AuthGuard path="/home">
          <HomePage />
        </AuthGuard>
        <AuthGuard path="/visit">
          <VisitFlowRoute />
        </AuthGuard>
        <AuthGuard exact path="/visit-details/:visit_id">
          <VisitDetails />
        </AuthGuard>
        <AuthGuard exact path="/visit-details/:visit_id/visit-summary">
          <VisitSummary />
        </AuthGuard>

        {/* OTC Recommendations Routes */}
        <AuthGuard path="/visit-details/:visit_id/success">
          <OTCSuccess />
        </AuthGuard>
        <AuthGuard path="/visit-details/:visit_id/error">
          <OTCError />
        </AuthGuard>
        <AuthGuard path="/visit-details/:visit_id/payments">
          <Payments />
        </AuthGuard>

        <AuthGuard path="/chat/:visit_id">
          <ChatScreen />
        </AuthGuard>
        <AuthGuard path="/consultation">
          <CosmeticConsultationRoutes />
        </AuthGuard>
        <ChangeProviderGuard path="/change-provider/:speciality">
          <ChangeProvider />
        </ChangeProviderGuard>

        {/* Pending Payments Link */}
        <Route path="/pending-payments/:payment_id" component={PendingPayments} />

        {/* 404 Not Found */}
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
}

export default AppPaths;
