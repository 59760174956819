import React from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  availableProvidersAction,
  switchProviderAction,
} from "api/actions/UserActions";
import { useApiMutation } from "hooks/useApiMutation";
import { useApiQuery } from "hooks/useApiQuery";
import { useEffect } from "react";
import { speciality } from "helpers/enum";
import Images from "assets/images";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { useCurrentUser } from "hooks/useCurrentUser";
import LoaderComponent from "components/loader/LoaderComponent";
import useToastify from "hooks/useToastify";
import Placeholder from "components/placeholders/Placeholder";
import useModal from "hooks/useModal";
import PracticeUserCard from "../components/PracticeUserCard";
import ProviderProfileDetails from "../profile/ProviderProfile";
import SimpleHeader from "components/header/SimpleHeader";

function ChangeProvider() {
  const history = useHistory();
  const { speciality: specialityAsParam } = useParams();
  const { userDetails } = useCurrentUser();
  const { setShowModal } = useModal();
  const { showAlert } = useToastify();
  const {
    payload: providers,
    loading: isFetchingProviders,
    query: fetchProviders,
  } = useApiQuery(availableProvidersAction(specialityAsParam), false);
  const {
    mutate: selectProvider,
    loading: isSwitchingProvider,
  } = useApiMutation(switchProviderAction);

  useEffect(() => {
    if (
      Number(specialityAsParam) === speciality.dermatology ||
      Number(specialityAsParam) === speciality.primary_care
    ) {
      fetchProviders();
    }
  }, [specialityAsParam, fetchProviders]);

  if (isFetchingProviders) {
    return (
      <LoaderComponent
        showLoader={{
          state: true,
          message: "Finding providers for you, please wait.",
        }}
      />
    );
  }

  async function onSelectClick(provider) {
    try {
      const result = await selectProvider({
        providerId: provider?.id,
        payload: { status: false },
        speciality: specialityAsParam,
      });
      if (!result.error) {
        showAlert("Provider changed successfully.", "success");
        history.goBack();
      } else {
        showAlert(
          result?.payload?.error ||
            result?.payload?.error_msg ||
            "Something went wrong!",
          "error"
        );
      }
    } catch (error) {
      showAlert(error, "error");
    }
  }

  return (
    <div className="h-screen overflow-hidden bg-alternativeWhite">
      <SimpleHeader
        title="Update Provider"
        showBackButton={true}
        wrapper={true}
      />
      <div className={"h-eliminateHeader overflow-y-auto"}>
        <div className="wrapper">
          {providers?.length === 0 ? (
            <div className="h-4/5">
              <Placeholder
                heading={`We are working hard to find a 
            ${
              Number(specialityAsParam) === speciality.dermatology
                ? "Dermatologist"
                : "Family Practice: Primary Care Physician"
            } who is licensed to provide services in ${userDetails?.city}.`}
                paragraph={
                  "We will notify you when we have a provider available to service in your zipcode"
                }
                placeholderImage={Images.searchDoctor}
              />
            </div>
          ) : (
            <div className="p-6 space-y-10 md:pt-10 md:px-12">
              <MedicalAssistant
                title={null}
                contentBg="bg-white"
                content={
                  <span className="pb-6 text-2xl font-medium">
                    Hi {userDetails?.first_name}, let's find the perfect{" "}
                    {Number(specialityAsParam) === speciality.dermatology
                      ? "Dermatologist"
                      : "Primary Care Physician"}{" "}
                    for you. Please select from the list below.
                  </span>
                }
              />
              <div className="divide-y">
                {providers?.length &&
                  providers?.map((provider, idx) => {
                    return (
                      <PracticeUserCard
                        key={idx}
                        showNetwork={false}
                        className="px-4 py-8 bg-white"
                        picture={provider?.avatar}
                        name={provider?.name}
                        speciality={provider?.speciality}
                        showLicense={provider?.license}
                        licenseState={provider?.license?.state}
                        licenseNumber={provider?.license?.license}
                        licenseActive={provider?.license?.status}
                        videoMeet={provider?.speciality.includes("Cosmetic")}
                        size="fit"
                        ratingValue={provider?.review?.rating}
                        ratingUserCount={provider?.review?.ratings_count}
                        inNetwork={true}
                        latestReview={provider?.review?.latest_review}
                        viewProfile={() => {
                          setShowModal({
                            state: true,
                            children: (_, onClose) => (
                              <ProviderProfileDetails
                                details={provider}
                                // caseId={visit?.id}
                                onClose={onClose}
                              />
                            ),
                          });
                        }}
                        cta={
                          <div className="w-full md:w-min">
                            <button
                              className="w-full px-8 py-4 text-2xl font-semibold text-white border rounded-lg opacity-100 md:w-max bg-orange"
                              onClick={() => onSelectClick(provider)}
                              disabled={isSwitchingProvider}
                            >
                              Proceed with consultation
                            </button>
                          </div>
                        }
                      />
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChangeProvider;
