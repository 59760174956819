import React, { createContext, useReducer, useState } from "react";

export const PendingPaymentsContext = createContext({});

function PendingPaymentsProvider({ children }) {
  const [initialFormValues, setInitialFormValues] = useState({
    step: 1,
    first_name: "",
    last_name: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zipcode: "",
    country: "US",
    email: "",
    mobile_number: "",
  });

  const formReducer = (state, action) => {
    switch (action.type) {
      case "FIRST_STEP":
        return {
          ...state,
          step: state.step === 1 ? 2 : 4,
          first_name: action.payload.first_name ?? "",
          last_name: action.payload.last_name ?? "",
          address_1: action.payload.address_1 ?? "",
          address_2: action.payload.address_2 ?? "",
          city: action.payload.city ?? "",
          state: action.payload.state ?? "",
          zipcode: action.payload.zipcode ?? "",
          country: "US",
          email: action.payload.email ?? "",
          mobile_number: action.payload.mobile_number ?? "",
        };
      case "PREV_STEP":
        return {
          ...state,
          step: 3,
        };
      case "NOT_ALLOWED":
        return {
          ...state,
          step: 0,
        };
      case "INVALID":
        return {
          ...state,
          step: -1,
        };
      default:
        return state;
    }
  };

  const [formValues, formDispatch] = useReducer(formReducer, initialFormValues);

  const handleInitialValuesUpdate = (updatedValues) => {
    setInitialFormValues((prevValues) => ({
      ...prevValues,
      ...updatedValues,
    }));
  };

  return (
    <PendingPaymentsContext.Provider
      value={{ formValues, formDispatch, handleInitialValuesUpdate }}
    >
      {children}
    </PendingPaymentsContext.Provider>
  );
}

export default PendingPaymentsProvider;
