import NewInsuranceBlock from "components/addInsuranceBlock/AddInsuranceBlock";
import { useCurrentUser } from "hooks/useCurrentUser";
import useModal from "hooks/useModal";
import InsuranceUpdateModal from "pages/home/waitingRoom/visitFlow/common/InsuranceUpdateModal";
import React, { useEffect, useRef, useState } from "react";

function InsuranceOption({
  onClickAddInsurance,
  isInsuranceExpired,
  isActive,
  insurance_details,
  onClick,
  selectCash,
  onClickUpdateInsurance,
  disabled,
  loading,
  showConfirm = () => {},
  checkingInsuranceInNetwork,
  insuranceInNetwork,
  currentPatient,
  speciality,
}) {
  const { userDetails } = useCurrentUser();
  const [outOfNet, setOutOfNet] = useState(false);
  const firstTime = useRef(true);
  const { setShowModal } = useModal();

  useEffect(() => {
    if (checkingInsuranceInNetwork) {
      firstTime.current = true;
    }
  }, [checkingInsuranceInNetwork]);

  useEffect(() => {
    if (!checkingInsuranceInNetwork && firstTime.current) {
      firstTime.current = false;
      if (insuranceInNetwork?.in_network) {
        setOutOfNet(false);
        if (isActive) {
          onClick("insurance");
        }
      } else {
        setOutOfNet(true);
        selectCash();
      }
    }
  }, [
    isActive,
    checkingInsuranceInNetwork,
    insuranceInNetwork,
    selectCash,
    onClick,
  ]);

  function onSecondaryInsuranceAction() {
    setShowModal({
      state: true,
      children: (_, onClose) => (
        <InsuranceUpdateModal
          onClose={onClose}
          insuranceDetails={
            insurance_details?.secondary_insurance?.member_id
              ? insurance_details?.secondary_insurance
              : null
          }
          currentPatient={currentPatient}
          currentSpeciality={speciality}
          insuranceType={2}
          isSecondary={true}
        />
      ),
      data: null,
    });
  }

  if (insurance_details?.insurance_company) {
    return (
      <NewInsuranceBlock
        insuranceDetails={insurance_details}
        isExpired={isInsuranceExpired}
        isLoading={loading}
        isActive={isActive}
        disabled={
          isInsuranceExpired || insurance_details?.out_of_network || outOfNet
        }
        onInsuranceClick={() => {
          if (
            isInsuranceExpired ||
            disabled ||
            insurance_details?.out_of_network ||
            outOfNet
          ) {
            return null;
          } else {
            onClick("insurance");
            showConfirm();
          }
        }}
        onUpdateInsurance={onClickUpdateInsurance}
        disabledReason={`We unfortunately do not have an in-network provider in 
              ${userDetails?.state} that accepts your insurance. Please choose
              the self-pay option below to continue with your booking.`}
        onAddSecondaryInsurance={onSecondaryInsuranceAction}
        onUpdateSecondaryInsurance={onSecondaryInsuranceAction}
      />
    );
  } else {
    return (
      <NewInsuranceBlock
        isActive={isActive}
        onInsuranceClick={onClickAddInsurance}
      />
    );
  }
}

export default InsuranceOption;
