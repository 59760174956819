import React from "react";
import ApiConfig from "api/config";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import DoctorCard from "components/doctorCard/DoctorCard";
import Header from "components/header/Header";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Spacer from "components/Spacer/Spacer";
import { useCurrentUser } from "hooks/useCurrentUser";
import useToastify from "hooks/useToastify";
import { useHistory } from "react-router-dom";
import moment from "moment";

function ConsultationSubmitted() {
  const history = useHistory();
  const state = history?.location?.state;
  const { userDetails } = useCurrentUser();
  const { showAlert } = useToastify();
  const referralUrl = `${ApiConfig.baseUrl}r/${userDetails.invite_code}`;

  const share = async () => {
    if (navigator.share) {
      await navigator.share({
        title: "Try out SkyMD - online medical care application",
        text: "Use my code below to get exclusive offers",
        url: referralUrl,
      });
    } else {
      await navigator.clipboard.writeText(referralUrl);
      showAlert("Referral link copied to clipboard", "info");
    }
  };

  return (
    <div className="h-screen overflow-hidden bg-veryLightBlue">
      <Header hideBackBtn title="Consultation Submitted" />
      <div className="overflow-y-auto bg-white h-eliminateHeaderWithFooter wrapper">
        <div className="px-12 pt-8">
          <MedicalAssistant
            content={
              <p>
                Congrats, {userDetails?.first_name}! Your video appointment has
                been scheduled for{" "}
                {moment(state?.bookingInfo?.selectedDate, "YYYY-MM-DD").format(
                  "MMMM DD, YYYY"
                )}{" "}
                at{" "}
                {moment(
                  state?.bookingInfo?.slotInfo?.start_time,
                  "hh:mma"
                ).format("LT")}
                .
              </p>
            }
          />
          <Spacer height={20} />
          <h3 className="pb-4 text-2xl font-black tracking-wide">
            YOUR CONSULTATION WITH
          </h3>
          <div className="p-5 rounded-4xl border-1/4 border-borderColor">
            <DoctorCard
              imageUrl={state?.bookingInfo?.selectedProvider?.avatar}
              name={state?.bookingInfo?.selectedProvider?.name}
              speciality={state?.bookingInfo?.selectedProvider?.speciality}
              isActive={true}
              license={state?.bookingInfo?.selectedProvider?.license}
            />
          </div>
        </div>
        <Spacer height={20} />
        <div className="bg-alternativeWhite py-7">
          <div className="px-12">
            <p className="text-3xl font-medium leading-10 text-center">
              Your video appointment has been scheduled for{" "}
              <span className="font-bold">
                {moment(state?.bookingInfo?.selectedDate, "YYYY-MM-DD").format(
                  "MMMM DD, YYYY"
                )}
              </span>{" "}
              at{" "}
              <span className="font-bold">
                {moment(
                  state?.bookingInfo?.slotInfo?.start_time,
                  "hh:mma"
                ).format("LT")}
              </span>
              .
            </p>
          </div>
        </div>
        <Spacer height={20} />
        <div className="flex flex-col items-center px-12 py-8">
          <h2 className="text-3xl font-medium leading-10 text-center">
            Meanwhile do you mind letting your friends know about SkyMD?
          </h2>
          <Spacer height={30} />
          <div
            onClick={share}
            className="relative flex items-center justify-center p-8 cursor-pointer bg-blue rounded-2xl"
          >
            <p className="text-3xl font-bold leading-10 text-center text-white break-all">
              {referralUrl}
            </p>
            <div className="absolute w-6 h-6 bg-white -left-3 rounded-2xl"></div>
            <div className="absolute w-6 h-6 bg-white -right-3 rounded-2xl"></div>
          </div>
          <Spacer height={25} />
          <p className="text-2xl font-light leading-10">
            Tap above to copy link.
          </p>
        </div>
      </div>
      <BottomFixedButton
        onClick={() => {
          sessionStorage.setItem("selectedTabIndex", 1);
          history.push("/home/my-visits");
        }}
        label="Go to My Consultations"
      />
    </div>
  );
}

export default ConsultationSubmitted;
