import {
  primary_care_main_steps,
  primary_care_sub_steps,
  step_status,
} from "helpers/enum";

export const primaryCareVisitFlowInitialState = {
  currentScreen: primary_care_sub_steps.select_patient,
  incompleteData: null,
  patientsList: null,
  completedProgress: 0,
  paymentMethod: null,
  mainSteps: {
    [primary_care_main_steps.visit_details]: {
      status: step_status.current,
      currentScreen: null,
    },
    [primary_care_main_steps.main_select_slot]: {
      status: step_status.upcoming,
    },
    [primary_care_main_steps.basic_info]: {
      status: step_status.upcoming,
    },
    [primary_care_main_steps.additional_details]: {
      status: step_status.upcoming,
    },
  },
  subSteps: {
    [primary_care_sub_steps.select_patient]: {
      value: null,
      isValid: false,
      prevScreen: null,
      nextScreen: null,
      status: step_status.current,
      headerTitle: "Visit Details",
      progress: 10,
    },
    [primary_care_sub_steps.select_child]: {
      value: null,
      isValid: false,
      prevScreen: primary_care_sub_steps.select_patient,
      nextScreen: primary_care_sub_steps.select_condition,
      headerTitle: "Visit Details",
    },
    [primary_care_sub_steps.select_someone_else]: {
      value: null,
      isValid: false,
      prevScreen: primary_care_sub_steps.select_patient,
      nextScreen: primary_care_sub_steps.select_condition,
      headerTitle: "Visit Details",
    },
    [primary_care_sub_steps.select_condition]: {
      value: null,
      isValid: false,
      prevScreen: primary_care_sub_steps.select_patient,
      nextScreen: primary_care_sub_steps.select_slot,
      status: step_status.upcoming,
      headerTitle: "Visit Details",
      progress: 20,
    },
    [primary_care_sub_steps.select_slot]: {
      value: null,
      isValid: false,
      prevScreen: primary_care_sub_steps.select_condition,
      nextScreen: primary_care_sub_steps.confirm_residence,
      headerTitle: "Select Your Appointment Date and Time",
      status: step_status.upcoming,
      progress: 30,
    },
    [primary_care_sub_steps.confirm_residence]: {
      value: null,
      isValid: false,
      prevScreen: primary_care_sub_steps.select_slot,
      nextScreen: primary_care_sub_steps.identity_confirmation,
      status: step_status.upcoming,
      headerTitle: "Basic Info",
      progress: 40,
      rightButtonText: "Confirm",
    },
    [primary_care_sub_steps.identity_confirmation]: {
      value: null,
      isValid: false,
      prevScreen: primary_care_sub_steps.confirm_residence,
      nextScreen: primary_care_sub_steps.select_pharmacy,
      status: step_status.upcoming,
      headerTitle: "Confirm your identity",
      progress: 50,
    },
    [primary_care_sub_steps.select_pharmacy]: {
      value: null,
      isValid: false,
      status: step_status.upcoming,
      prevScreen: primary_care_sub_steps.identity_confirmation,
      nextScreen: primary_care_sub_steps.symptoms,
      headerTitle: "Select Pharmacy",
      progress: 80,
    },
    [primary_care_sub_steps.symptoms]: {
      value: null,
      isValid: false,
      status: step_status.upcoming,
      prevScreen: primary_care_sub_steps.select_pharmacy,
      nextScreen: primary_care_sub_steps.additional_info,
      headerTitle: "Select Symptoms",
      progress: 90,
    },
    [primary_care_sub_steps.additional_info]: {
      value: null,
      isValid: false,
      prevScreen: primary_care_sub_steps.symptoms,
      nextScreen: null,
      status: step_status.upcoming,
      headerTitle: "Additional Information",
      progress: 100,
    },
  },
};
