import React, { useEffect, useRef, useState } from "react";
import { Form, Formik, useFormikContext } from "formik";
import FormikErrorFocus from "formik-error-focus";
import { NavLink, useParams, useHistory } from "react-router-dom";

import {
  chargePatientAction,
  createBillingAddressAction,
  getTransactionInfoAction,
} from "api/actions/PaymentActions";
import { getGeoDataFromZipcodeNoAuthAction } from "api/actions/UserActions";
import Images from "assets/images";
import Button from "components/buttons/Button";
import Input from "components/inputs/Input";
import LoaderComponent from "components/loader/LoaderComponent";
import PendingCardPayments from "components/payment/PendingCardPayments";
import StepContainer from "components/stepContainer/StepContainer";
import Spacer from "components/Spacer/Spacer";
import { input_types } from "helpers/enum";
import { pendingPaymentsSchema } from "helpers/validationSchema";
import { useApiQuery } from "hooks/useApiQuery";
import { useApiMutation } from "hooks/useApiMutation";
import useToastify from "hooks/useToastify";
import { PendingPaymentsContext } from "providers/PendingPaymentsProvider";

function PendingPaymentsForm({
  creatingBillingAddress,
  formValues,
  formDispatch,
  transactionInfo,
}) {
  const {
    values,
    dirty,
    isValid,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    validateForm,
  } = useFormikContext();

  const buttonRef = useRef(null);

  // Zip Code Confirm Changes
  const [showZip, setShowZip] = useState("");

  const {
    loading: gettingZipDetails,
    payload: zipDetails,
    query: getZipcodeDetails,
  } = useApiQuery(getGeoDataFromZipcodeNoAuthAction(showZip), false);

  useEffect(() => {
    if (formValues?.zipcode.length > 0) {
      setShowZip(formValues?.zipcode);
    }

    if (transactionInfo?.direct_payment_billing_info) {
      formDispatch({ type: "PREV_STEP" });
      buttonRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showZip.length === 5) {
      getZipcodeDetails();
      validateForm();
    }
  }, [getZipcodeDetails, showZip, validateForm]);

  useEffect(() => {
    if (zipDetails?.geo_data) {
      setFieldValue("city", zipDetails?.geo_data?.city, false);
      setFieldError("city");
      setFieldValue("state", zipDetails?.geo_data?.state_code, false);
      setFieldError("state");
    }
  }, [setFieldError, setFieldValue, zipDetails?.geo_data]);

  useEffect(() => {
    if (transactionInfo?.direct_payment_billing_info) {
      setFieldValue(
        "first_name",
        transactionInfo?.direct_payment_billing_info?.first_name,
        false
      );
      setFieldError("first_name");
      setFieldValue(
        "last_name",
        transactionInfo?.direct_payment_billing_info?.last_name,
        false
      );
      setFieldError("last_name");
      setFieldValue(
        "address_1",
        transactionInfo?.direct_payment_billing_info?.add_line_1,
        false
      );
      setFieldError("address_1");
      setFieldValue(
        "address_2",
        transactionInfo?.direct_payment_billing_info?.add_line_2,
        false
      );
      setFieldError("address_2");
      setFieldValue(
        "zipcode",
        transactionInfo?.direct_payment_billing_info?.zip,
        false
      );
      setFieldError("zipcode");
      setShowZip(transactionInfo?.direct_payment_billing_info?.zip);
      setFieldValue(
        "email",
        transactionInfo?.direct_payment_billing_info?.email,
        false
      );
      setFieldError("email");
      setFieldValue(
        "mobile_number",
        transactionInfo?.direct_payment_billing_info?.phone,
        false
      );
      setFieldError("mobile_number");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionInfo]);

  return (
    <Form className="p-4 md:p-0 required-label" key="first-step">
      <div className="p-12 bg-white md:space-y-6 md:p-0 rounded-3xl">
        <h1 className="mb-3 text-4xl font-medium text-center text-black md:mb-0 md:hidden">
          Payment Page
        </h1>
        <div className="flex flex-col space-y-6">
          <Input
            name="total_amount"
            label="Total Amount"
            className="new-input cursor-not-allowed"
            value={`$ ${
              transactionInfo?.amount
                ? Number(transactionInfo?.amount).toFixed(2).toString()
                : ""
            }`}
            disabled
          />
          <Input
            name="account_number"
            label="Account # / Visit Number"
            className="new-input cursor-not-allowed"
            value={transactionInfo?.account_or_visit_id}
            disabled
          />
          <Input
            name="patient_name"
            label="Patient Name"
            className="new-input cursor-not-allowed"
            value={transactionInfo?.pt_name}
            disabled
          />
          <div className="flex items-center w-full mt-4 mb-4">
            <div className="w-full h-px bg-gray-300"></div>
            <div
              className="flex-1 mx-4 font-bold text-xl text-orange text-center"
              style={{ flex: "1 0 auto" }}
            >
              Billing Info
            </div>
            <div className="w-full h-px bg-gray-300"></div>
          </div>
          <Input
            name="first_name"
            label="First Name"
            id="first_name"
            className="new-input"
          />
          <Input
            name="last_name"
            label="Last Name"
            id="last_name"
            className="new-input"
          />
          <Input
            name="address_1"
            label="Address 1"
            id="address_1"
            className="new-input"
            maxLength="35"
          />
          <Input
            name="address_2"
            label="Address 2"
            id="address_2"
            className="new-input"
            maxLength="35"
          />
          <Input
            type={input_types.WITHLOADER}
            name="zipcode"
            label="Zip Code"
            id="zipcode"
            maxLength={5}
            className="new-input"
            onChange={(event) => {
              setFieldValue("zipcode", event.target.value);
              if (event.target.value.length === 5) {
                setShowZip(event.target.value);
              } else {
                setShowZip("");
              }
            }}
            customonblur={() => {
              if (values.zipcode.length === 5) {
                setShowZip(values.zipcode);
              } else {
                setShowZip("");
              }
            }}
            onFocus={() => {
              setShowZip("");
            }}
            loading={gettingZipDetails}
          />
          {!gettingZipDetails &&
          !zipDetails?.geo_data &&
          zipDetails?.status &&
          values?.zipcode?.length === 5 ? (
            <p
              style={{ margin: 0, marginTop: 4 }}
              className="text-lg font-medium text-errorRed"
            >
              Please enter a valid zip code
            </p>
          ) : null}
          <Input
            name="city"
            label="City"
            id="city"
            className="new-input"
            disabled={gettingZipDetails}
          />
          <Input
            name="state"
            label="State"
            id="state"
            className="new-input"
            disabled={gettingZipDetails}
          />
          <Input
            name="country"
            label="Country"
            className="new-input cursor-not-allowed"
            value="US"
            disabled={true}
          />
          <Input
            name="email"
            type="email"
            label="Email"
            id="email"
            className="new-input"
          />
          <Input
            name="mobile_number"
            type={input_types.PHONE}
            label="Mobile Number"
            id="mobile_number"
            className="new-input"
            value={values?.mobile_number}
            onChange={(val) => {
              setFieldValue("mobile_number", val);
            }}
            onBlur={() => setFieldTouched("mobile_number", true)}
          />
          <div
            ref={buttonRef}
            className="flex flex-col items-center justify-center space-y-4 "
          >
            <div className="w-full md:pt-8">
              <Button
                type="submit"
                label={"Continue"}
                loading={creatingBillingAddress}
                disabled={
                  gettingZipDetails ||
                  !zipDetails?.geo_data ||
                  !((dirty || formValues.step === 3) && isValid)
                }
                className="py-4 md:h-18"
              />
            </div>
          </div>
        </div>
        <FormikErrorFocus align={"bottom"} ease={"linear"} duration={500} />
      </div>
    </Form>
  );
}

function PendingPayments() {
  const formRef = useRef(null);
  const history = useHistory();
  const { showAlert } = useToastify();
  const { payment_id: paymentId } = useParams();

  const [cards, setCards] = useState([]);
  const [btnLoading, setBtnLoading] = useState(true);
  const [btTransactionId, setBtTransactionId] = useState(null);
  const [paymentNonce, setPaymentNonce] = useState(null);

  const { formValues, formDispatch } = React.useContext(PendingPaymentsContext);

  const {
    query: getTransactionInfo,
    loading: gettingTransactionInfo,
    payload: transactionInfo,
    error: transactionInfoError,
  } = useApiQuery(getTransactionInfoAction(paymentId), true);

  const {
    mutate: createBillingAddress,
    loading: creatingBillingAddress,
  } = useApiMutation(createBillingAddressAction);

  const { mutate: chargePatient, loading: chargingPatient } = useApiMutation(
    chargePatientAction
  );

  useEffect(() => {
    if (transactionInfoError) {
      formDispatch({ type: "INVALID" });
    }
  }, [formDispatch, transactionInfoError]);

  const billingAddressSubmission = async (values) => {
    try {
      const billingAddressResult = await createBillingAddress({
        tID: paymentId,
        payload: {
          billing: {
            first_name: values?.first_name,
            last_name: values?.last_name,
            add_line_1: values?.address_1,
            add_line_2: values?.address_2,
            city: values?.city,
            state: values?.state,
            zip: values?.zipcode,
            country: values?.country,
            email: values?.email,
            phone: values?.mobile_number,
          },
        },
      });
      if (billingAddressResult && billingAddressResult?.error === false) {
        formDispatch({ type: "FIRST_STEP", payload: values });
      } else {
        showAlert(
          billingAddressResult?.payload?.message ?? "Something went wrong",
          "error"
        );
      }
    } catch (error) {
      showAlert(error?.message ?? "Something went wrong", "error");
    }
  };

  const paymentSubmission = async () => {
    try {
      const chargePatientResult = await chargePatient({
        tID: paymentId,
        payload: {
          payment_method_nonce: paymentNonce,
        },
      });

      if (chargePatientResult && chargePatientResult?.error === false) {
        setBtTransactionId(
          chargePatientResult?.payload?.transaction?.bt_transaction_id
        );
        formDispatch({ type: "NOT_ALLOWED" });
      } else {
        if (chargePatientResult?.payload?.refresh) {
          showAlert(
            chargePatientResult?.payload?.message ?? "Something went wrong",
            "error"
          );
          window.location.reload();
        } else {
          showAlert(
            chargePatientResult?.payload?.message ?? "Something went wrong",
            "error"
          );
        }
      }
    } catch (error) {
      showAlert(error?.message ?? "Something went wrong", "error");
    }
  };

  if (gettingTransactionInfo) {
    return (
      <LoaderComponent
        showLoader={{ state: true, message: "Retrieving payment details" }}
      />
    );
  }

  return (
    <div className="flex flex-col justify-center h-full bg-veryLightBlue md:bg-gradient-to-b from-blue-300 to-blue-500">
      {formValues?.step <= 0 ? (
        <div className="wrapper h-full overflow-hidden bg-white">
          <div className="h-full flex flex-col justify-center items-center">
            <img
              src={
                formValues?.step === -1
                  ? Images.exclaimationIcon
                  : Images.filledCheckedLight
              }
              alt="success"
              className="w-36 h-36"
            />
            <Spacer height={42} />
            <h3 className="font-bold text-4xl text-indigo">
              {formValues?.step === -1 ? "Attention" : "Thank You!"}
            </h3>
            <Spacer height={12} />
            <p className="font-semibold text-3xl text-indigo">
              {formValues?.step === -1
                ? "This link has expired"
                : "Payment is successful"}
            </p>
            {formValues?.step === -1 ? null : (
              <>
                <Spacer height={12} />
                <p className="w-full px-8 font-light text-3xl text-indigo text-center md:w-3/4 md:px-0">
                  {btTransactionId} is your transaction ID
                </p>
              </>
            )}
            <Spacer height={32} />
            <button
              onClick={() => history.replace("/home")}
              className="px-10 py-2 bg-orange rounded-full font-bold text-2xl text-white"
            >
              Home
            </button>
          </div>
        </div>
      ) : (
        <div className="h-full overflow-y-auto md:h-auto bg-blue md:bg-white md:p-10 login-card md:rounded-xl">
          <div className="bg-alternativeWhite md:bg-transparent">
            <div className="flex items-center justify-between px-4 py-3 md:px-12 md:py-6 md:justify-center">
              <div className="w-12 h-12">
                {formValues?.step % 2 === 0 && (
                  <button
                    className="w-12 h-12 md:h-14 md:w-14 md:hidden"
                    onClick={() => {
                      getTransactionInfo();
                      formDispatch({ type: "PREV_STEP" });
                    }}
                  >
                    <img
                      className="object-contain w-full h-full"
                      src={Images.arrowBack}
                      alt="Go back"
                    />
                  </button>
                )}
              </div>
              <div className="flex flex-col items-center justify-center ">
                <NavLink className="no-underline" to={"/home"}>
                  <img
                    src={Images.logoVerticalNaming}
                    className="object-contain w-20 h-20 cursor-pointer md:h-44 md:w-44"
                    alt="logo"
                  />
                </NavLink>
                <p className="hidden pt-4 text-5xl font-medium leading-8 md:pt-6 md:mt-4 md:font-bold md:flex">
                  Payment Page
                </p>
              </div>
              <div className="w-12 h-12 md:h-14 md:w-14" />
            </div>
          </div>
          <StepContainer
            second={formValues?.step % 2 === 0 ? true : false}
            prev={() => {
              getTransactionInfo();
              formDispatch({ type: "PREV_STEP" });
            }}
            firstLabel="Patient Details"
            secondLabel="Payment Method"
          />
          {formValues?.step % 2 === 0 ? (
            <div className="mt-4 mx-4 px-6 py-6 bg-alternativeWhite rounded-lg md:mt-0 md:mx-0 md:px-0 md:py-0 md:bg-transparent md:rounded-none">
              <PendingCardPayments
                amount={transactionInfo?.amount}
                cards={cards}
                paymentId={paymentId}
                setBtnLoading={setBtnLoading}
                setCards={setCards}
                setPaymentNonce={setPaymentNonce}
              />
              <div className="flex flex-col items-center justify-center space-y-4">
                <div className="w-full md:pt-8">
                  <Button
                    type="button"
                    onClick={paymentSubmission}
                    label="Submit"
                    loading={chargingPatient}
                    disabled={btnLoading || cards.length === 0 || !paymentNonce}
                    className="py-4 md:h-18"
                  />
                </div>
              </div>
            </div>
          ) : (
            <Formik
              innerRef={formRef}
              initialValues={{
                first_name: formValues?.first_name,
                last_name: formValues?.last_name,
                address_1: formValues?.address_1,
                address_2: formValues?.address_2,
                zipcode: formValues?.zipcode,
                city: formValues?.city,
                state: formValues?.state,
                country: formValues?.country,
                email: formValues?.email,
                mobile_number: formValues?.mobile_number,
              }}
              validationSchema={pendingPaymentsSchema}
              validateOnMount={true}
              onSubmit={(values) => billingAddressSubmission(values)}
            >
              {() => (
                <PendingPaymentsForm
                  creatingBillingAddress={creatingBillingAddress}
                  formValues={formValues}
                  formDispatch={formDispatch}
                  transactionInfo={transactionInfo}
                />
              )}
            </Formik>
          )}
        </div>
      )}
    </div>
  );
}

export default PendingPayments;
