import { memo, useCallback, useState } from "react";
import classNames from "classnames";
import { OTPInputField } from "./OTPInputFIeld";

export function OTPInputComponent(props) {
  const { length, autoFocus, disabled, onChangeOTP, error } = props;
  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(Array(length).fill(""));

  const handleOTPChange = useCallback(
    (otp) => {
      const otpValue = otp.join("");
      onChangeOTP(otpValue); //(For OTP Number Callback  Use this function)
    },
    [onChangeOTP]
  );

  const getRightValue = useCallback((str) => {
    const changedValue = str;
    if (!changedValue) {
      return changedValue;
    }
    return Number(changedValue) >= 0 ? changedValue : "";
  }, []);

  const changeCodeAtFocus = useCallback(
    (str) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || "";
      setOTPValues(updatedOTPValues);
      handleOTPChange(updatedOTPValues);
    },
    [activeInput, handleOTPChange, otpValues]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const focusInput = (inputIndex) => {
    const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
    setActiveInput(selectedIndex);
  };
  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index) => () => {
      focusInput(index);
    },
    [focusInput]
  );
  const handleOnChange = useCallback(
    (e) => {
      const val = getRightValue(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getRightValue]
  );

  // Handle onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e) => {
      const pressedKey = e.key;

      switch (pressedKey) {
        case "Backspace":
        case "Delete": {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus("");
          } else {
            focusPrevInput();
          }
          break;
        }
        case "ArrowLeft": {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case "ArrowRight": {
          e.preventDefault();
          focusNextInput();
          break;
        }
        default: {
          if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
            e.preventDefault();
          }
          break;
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );

  const handleOnPaste = useCallback(
    (e) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData("text/plain")
        .trim()
        .slice(0, length - activeInput)
        .split("");
      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || val);
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setOTPValues(updatedOTPValues);
        handleOTPChange(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      }
    },
    [activeInput, getRightValue, length, otpValues, handleOTPChange]
  );

  return (
    <div className="grid items-center justify-between md:justify-center w-full grid-cols-4 gap-12 justify-items-center">
      {Array(length)
        .fill("")
        .map((_, index) => (
          <OTPInputField
            key={`SingleInput-${index}`}
            focus={activeInput === index}
            value={otpValues && otpValues[index]}
            autoFocus={autoFocus}
            onFocus={handleOnFocus(index)}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onBlur={onBlur}
            onPaste={handleOnPaste}
            className={classNames(
              "font-bold text-2xl text-black h-20 border focus:outline-none flex-1 rounded p-2.5 focus:ring-1 focus:ring-indigo-300 focus:border-indigo-300 ",
              error ? " border-errorRed" : " border-gray-300",
              activeInput === index &&
                "border-indigo-300 ring-1 ring-indigo-300 border-indigo-300"
            )}
            disabled={disabled}
          />
        ))}
    </div>
  );
}
export const OTPInput = memo(OTPInputComponent);
