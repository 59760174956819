import React, { useRef, useEffect } from "react";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import { Form, Formik } from "formik";
import { OtherFeedbacksSchema } from "helpers/validationSchema";
import MultiLineInput from "components/inputs/MultiLineInput";
import { useDispatch } from "react-redux";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import useVisitStepChange from "hooks/useVisitStepChange";
import { dermatology_sub_steps } from "helpers/enum";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import { useHistory } from "react-router-dom";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";

function OtherFeedbacks() {
  const formRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const { incompleteData, subSteps } = useDermatologyVisitFlow();
  const { rightButtonClickRef } = useVisitStepChange();
  const currentScreenValueFromStore =
    subSteps[dermatology_sub_steps.other_feedbacks].value;
  rightButtonClickRef.current = onRightButtonClick;

  const { updateVisit } = useUpdateDermatologyVisit();

  useEffect(() => {
    if (currentScreenValueFromStore) {
      formRef?.current?.setFieldValue(
        "other_feedbacks",
        currentScreenValueFromStore
      );
    }
  }, [currentScreenValueFromStore]);

  useEffect(() => {
    dispatch(
      dermaVisitFlowReducers.other_feedbacks({
        isValid: true,
      })
    );
  }, [dispatch]);

  function onFormSubmit(values) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          additional_info: values?.other_feedbacks,
          pcp_fax: null,
          pcp_name: null,
          dermatology_steps: incompleteData?.dermatology_steps,
        },
      },
      change_current_screen: false,
      screen_to_complete: null,
      callBack: () => {
        dispatch(
          dermaVisitFlowReducers.other_feedbacks({
            value: values?.other_feedbacks,
          })
        );
        history.goBack();
      },
    });
  }

  function onRightButtonClick(_, nextAnimation) {
    if (formRef?.current) {
      formRef?.current?.handleSubmit();
    }
  }

  return (
    <StepScreenContainer align={"start"}>
      <MedicalAssistant
        content={
          <p>
            Before we finish, is there anything else you would like us to know?
          </p>
        }
      />
      <Card>
        <Formik
          innerRef={formRef}
          onSubmit={onFormSubmit}
          initialValues={{
            other_feedbacks: "",
          }}
          validationSchema={OtherFeedbacksSchema}
        >
          {() => (
            <Form>
              <MultiLineInput
                label={"Write Details (Optional)"}
                id={"other_feedbacks"}
                name={"other_feedbacks"}
              />
            </Form>
          )}
        </Formik>
      </Card>
    </StepScreenContainer>
  );
}

export default OtherFeedbacks;
