import TextWithBulletPoint from "../../TextWithBulletPoint";
import moment from "moment";

export const getVaccinationsView = (payload) => {
  const getVaccineName = (key) => {
    switch (key) {
      case "tdOrTdap":
        return "Td / Tdap (Tetanus and Diphtheria)";
      case "flu":
        return "Influenza (Flu)";
      case "ppd":
        return "PPD (Tuberculosis)";
      case "mmr":
        return "MMR (Measles, Mumps and Rubella)";
      case "pneumococcal":
        return "Pneumococcal (PCV13, PPSV23)";
      case "hpv":
        return "HPV (Human Papillomavirus)";
      default:
        break;
    }
  };

  if (payload?.vaccines?.length && payload?.vaccines?.some((el) => el?.date)) {
    return (
      <div className="p-8 bg-alternativeWhite border border-borderColor mt-8 rounded-xl">
        {payload?.vaccines?.map((vaccine, index) => {
          if (vaccine?.date) {
            return (
              <TextWithBulletPoint
                key={index}
                className="mb-4"
                text={`${getVaccineName(vaccine?.title)} : ${moment(
                  vaccine?.date,
                  "YYYY-MM-DD"
                ).format("DD MMM, YYYY")}`}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  } else {
    return null;
  }
};
