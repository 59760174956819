function TextDivider({ text = "Or" }) {
  return (
    <div className="flex items-center justify-center w-full mt-4 mb-4">
      <div className="w-full border-t border-gray-300"></div>
      <div className="mx-4 font-semibold text-gray-500">{text}</div>
      <div className="w-full border-t border-gray-300"></div>
    </div>
  );
}
export default TextDivider;
