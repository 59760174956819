import Images from "assets/images";
import CSSLoader from "components/cssLoader/CSSLoader";
import React, { useState } from "react";

function ImageComponent({ url }) {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [error, setError] = useState(false);
  return (
    //Orignal Class
    // <div className="min-w-1/10 min-h-1/10 max-w-1/10 max-h-1/10 rounded-6/2 relative bg-white border-solid border-1/5 border-blue flex items-center justify-center">
    <div className="min-w-1/10 min-h-1/10  max-w-1/10 max-h-1/10 relative flex items-center justify-center">
      <img
        className="absolute w-3/32 h-3/32 bg-white rounded-xl object-cover"
        src={error ? Images.profilePlaceholder : url}
        alt="avatar"
        onLoad={() => setIsImageLoaded(true)}
        onError={() => {
          setIsImageLoaded(true);
          setError(true);
        }}
      />
      {isImageLoaded ? null : (
        <CSSLoader className="w-8 h-8 absolute" color="orange" />
      )}
    </div>
  );
}

export default ImageComponent;
