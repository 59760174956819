import React, { useRef } from "react";
import { Icon } from "stories/Icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "./testimonials.css";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import classNames from "classnames";
const testimonials = [
  {
    rating: 5,
    review:
      "I have been trying to schedule a dermatologist appointment for several months with each one in my area having appointment dates almost a year out, with this site, I was able to submit my concerns and I received a diagnosis and treatment plan in less than an hour. I was satisfied with my response and treatment plan and would definitely recommend this to a friend, in fact, I already have!",
    author: "Gloria, Tennessee",
    design: "California",
  },
  {
    rating: 5,
    review:
      "I got a response within an hour. The Dr listened to my concerns. Was very knowledgeable. Didn't just give me a pre types response but we were actually having a conversation. It's like an in office appt just virtual.",
    author: "Sandra",
    design: "texas",
  },
  {
    rating: 5,
    review:
      "Maria was on top of finding out what was going on with my hair loss that I was dealing with for about 4 years. In all about those four years I had at least 5 different dermatologist just guess on what they thought was happening. Maria was able to tell me right away. Thank you Maria I relieved. She's the best.",
    author: "Diana",
    design: "Utah",
  },
  {
    rating: 5,
    review:
      "The doctor was very nice and kind and really knew what prescription to send me for my melasma problem. Consultation is not as expensive as if you see a private dermatologist and they answer reply to your question quickly.",
    author: "Gloria",
    design: "Tennessee",
  },
  {
    rating: 5,
    review:
      "I received help faster than going to a doctor in person and it was way more informative. I didn’t feel like I was on a conveyor belt.",
    author: "Raeya",
    design: "Florida",
  },
  {
    rating: 5,
    review:
      "So much easier and faster than going to a dermatologist locally. The doctor responds really quickly and I think her diagnosis was accurate.",
    author: "Bridget",
    design: "California",
  },
  {
    rating: 5,
    review:
      "We have limited access to dermatology in my area. This was very easy. I've put off getting treatment due to access. Can't wait to get my treatment started. I also like I received other things that will help beside a RX.",
    author: "Elaine",
    design: "Hawaii",
  },
  {
    rating: 5,
    review: "Better quality of service than in in-person visit.",
    author: "Samuel",
    design: "Kansas",
  },
  {
    rating: 5,
    review:
      "I tried SkyMD® because there are no Dermatologists in my small town. The doctor quickly diagnosed my concerns and sent a skin treatment prescription to my pharmacy within 24 hours. It was fast, easy, and affordable. My messages were responded to very quickly and completely.",
    author: "Margot",
    design: "New York",
  },
  {
    rating: 5,
    review:
      "It has been a really easy process and I feel we get better care than in an office visit. Communication is better and that helps with the experience.",
    author: "Edward",
    design: "Oregon",
  },
  {
    rating: 5,
    review:
      "My doctor is awesome and very helpful. Will continue to use this service.",
    author: "Andrew",
    design: "Texas",
  },
  {
    rating: 5,
    review:
      "Because the doctor not only told me about one treatment, but he explained in detail every possible treatment. Also he respond to all my questions.",
    author: "Julian",
    design: "California",
  },
  {
    rating: 5,
    review: "No waiting rooms, immediate response, and excellent service.",
    author: "Simi",
    design: "New Jersey",
  },
  {
    rating: 5,
    review:
      "They were quick, efficient and able to help out with a common issue that I wait 9 months to see a dermatologist in person for!<",
    author: "Jessica",
    design: "Washington",
  },
];
function Testimonials() {
  const swiperRef = useRef(null);
  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <div className="pb-40 lg:pb-10">
      <h2 className="py-4 text-4xl font-bold">What Our Patients are Saying</h2>
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center ">
          <span className="flex mr-2">
            {[...Array(5)].map((_, index) => (
              <Icon key={`overall-ratings-${index}`} name="star" />
            ))}
          </span>
          <span className="text-2xl font-medium text-themeBlack">
            {"9873 5-star review"}
          </span>
        </div>
        <div className="flex space-x-6">
          <button onClick={handlePrev}>
            <Icon
              name={"arrow-left-small"}
              className="w-12 h-12 p-3 rotate-180 border rounded-full cursor-pointer border-orange text-orange hover:bg-orange hover:text-white"
            />
          </button>
          <button onClick={handleNext}>
            <Icon
              name={"arrow-right-small"}
              className="w-12 h-12 p-3 border rounded-full cursor-pointer border-orange text-orange hover:bg-orange hover:text-white"
            />
          </button>
        </div>
      </div>
      <div className="pt-10">
        <Swiper
          ref={swiperRef}
          slidesPerView={1}
          centeredSlides={false}
          slidesPerGroupSkip={1}
          grabCursor={true}
          loop={true}
          autoplay={{
            delay: 5000,
          }}
          keyboard={{
            enabled: true,
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          breakpoints={{
            420: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
          }}
          modules={[Autoplay]}
          className="border mySwiper"
        >
          <div className="pb-16 space-x-10 ">
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={`testimonial-${index}`}>
                <TestimonialCard
                  author={testimonial.author}
                  rating={testimonial.rating}
                  review={testimonial.review}
                  design={testimonial.design}
                />
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </div>
    </div>
  );
}

const TestimonialCard = ({ author, rating, review, design, className }) => {
  return (
    <div
      className={classNames(
        "p-4 lg:p-10 flex flex-col lg:space-y-4 rounded-lg text-2xl font-thin h-full w-full pr-8 lg:pr-24",
        className
      )}
    >
      <span className="flex mb-2 mr-2">
        {rating > 0 && (
          <>
            {[...Array(rating)].map((_, index) => (
              <Icon key={`rating-${author}-${index}`} name="star" />
            ))}
          </>
        )}
      </span>
      <p className="">{review}</p>
      <div className="mt-4 font-medium text-gray-700">
        <p className="font-bold">{author},</p>
        <p className="text-xl">{design}</p>
      </div>
    </div>
  );
};
SwiperCore.use([Navigation]);

export default Testimonials;
