import React from "react";
import "./VisitSubQuestionSlideUpContainer.scss";

function VisitSubQuestionSlideUpContainer({ children }) {
  return (
    <div className={`visit_sub_question_slide_up_container`}>{children}</div>
  );
}

export default VisitSubQuestionSlideUpContainer;
