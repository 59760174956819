import TextWithBulletPoint from "../../TextWithBulletPoint";

export const getCaffeineUseView = (payload) => {
  const values = {
    drinks_caffeine: payload?.caffeine_usage?.drinks_caffeine?.length
      ? payload?.caffeine_usage?.drinks_caffeine
      : null,
    no_of_drinks:
      typeof payload?.caffeine_usage?.no_of_drinks === "number"
        ? payload?.caffeine_usage?.no_of_drinks
        : null,
    frequency: payload?.caffeine_usage?.frequency?.length
      ? payload?.caffeine_usage?.frequency
      : null,
  };

  if (Object.keys(values)?.some((el) => values?.[el] !== null)) {
    return (
      <div className="p-8 bg-alternativeWhite border border-borderColor mt-8 rounded-xl">
        {values?.drinks_caffeine ? (
          <TextWithBulletPoint
            text={`Drinks caffeine : ${values?.drinks_caffeine}`}
          />
        ) : null}
        {values?.no_of_drinks ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Usage in number : ${values?.no_of_drinks}`}
          />
        ) : null}
        {values?.frequency ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Per : ${values?.frequency}`}
          />
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};
