import React from "react";
import moment from "moment";
import Images from "assets/images";
import Spacer from "components/Spacer/Spacer";

function RecordBlock({ onClickRecordPause, onClickStopRecord, status, timer }) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <div className="flex items-center">
          <p className="text-xl font-medium pr-2">Record</p>
          <button
            className="bg-red w-12 h-12 rounded-full flex items-center justify-center"
            onClick={onClickRecordPause}
            type="button"
          >
            {status === "recording" ? (
              <img alt={"pause"} src={Images.pause} className="w-6 h-6" />
            ) : (
              <div className="bg-white w-4 h-4 rounded-full" />
            )}
          </button>
        </div>
        <Spacer width={8} />
        <div className="flex items-center">
          <p className="text-xl font-medium pr-2">Stop</p>
          <button
            disabled={!status}
            onClick={() => {
              if (status) {
                onClickStopRecord();
              } else {
                return;
              }
            }}
            className={`bg-indigo w-12 h-12 rounded-full flex items-center justify-center ${
              !status ? "opacity-50 cursor-not-allowed" : "opacity-100"
            }`}
            type="button"
          >
            <div className="bg-white w-4 h-4 rounded-sm" />
          </button>
        </div>
      </div>
      <div>
        <h3 className="text-4xl font-bold">
          {moment.utc(timer * 1000).format("mm:ss")}
        </h3>
      </div>
    </div>
  );
}

export default RecordBlock;
