import React, { useEffect, useState } from "react";
import VisitSummaryAccordion from "components/visitSummaryAccordion/VisitSummaryAccordion";
import VisitSummaryHeader from "components/visitSummaryAccordion/VisitSummaryHeader";
import CosmeticConsultationDetailsSummary from "./cosmeticSummary/CosmeticConsultationDetailsSummary";
import CosmeticMedicalHistorySummary from "./cosmeticSummary/CosmeticMedicalHistorySummary";
import CosmeticAppointmentSlotSummary from "./cosmeticSummary/CosmeticAppointmentSlotSummary";
import CosmeticAdditionalDetailsSummary from "./cosmeticSummary/CosmeticAdditionalDetailsSummary";
import CosmeticPhotosSummary from "./cosmeticSummary/CosmeticPhotosSummary";
import Images from "assets/images";
import DOMPurify from "dompurify";

function CosmeticConsultationSummary({ readOnly, data }) {
  const [terms, setTerms] = useState([]);

  useEffect(() => {
    setTerms(data?.code_of_conduct);
  }, [data]);

  const accordionData = [
    {
      header: (props) => (
        <VisitSummaryHeader
          {...props}
          title={"Consultation Details"}
          readOnly={readOnly}
        />
      ),
      content: (
        <CosmeticConsultationDetailsSummary readOnly={readOnly} data={data} />
      ),
    },
    {
      header: (props) => <VisitSummaryHeader {...props} title={"Photos"} />,
      content: <CosmeticPhotosSummary readOnly={readOnly} data={data} />,
    },
    {
      header: (props) => (
        <VisitSummaryHeader {...props} title={"Medical History"} />
      ),
      content: (
        <CosmeticMedicalHistorySummary readOnly={readOnly} data={data} />
      ),
    },
    {
      header: (props) => (
        <VisitSummaryHeader {...props} title={"Appointment Time"} />
      ),
      content: (
        <CosmeticAppointmentSlotSummary readOnly={readOnly} data={data} />
      ),
    },
    {
      header: (props) => (
        <VisitSummaryHeader {...props} title={"Additional Details"} />
      ),
      content: (
        <CosmeticAdditionalDetailsSummary readOnly={readOnly} data={data} />
      ),
    },
    {
      header: (props) => (
        <VisitSummaryHeader {...props} title="SkyMD's Code Of Conduct" />
      ),
      content: (
        <div>
          {terms?.map((term, index) => {
            return (
              <div className="py-10" key={index}>
                <div className="flex">
                  <img
                    className="w-12 h-12"
                    src={Images.checkedOrange}
                    alt="checked"
                  />
                  <p
                    className="pl-5 text-2xl leading-8 font-light pt-1"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(term),
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ),
    },
  ];
  return (
    <div className="bg-alternativeWhite">
      <div className="wrapper px-12">
        {readOnly ? null : (
          <h2 className="uppercase font-black text-xl pt-8">
            Here is your Consultation Summary
          </h2>
        )}
        <VisitSummaryAccordion accordionData={accordionData} />
      </div>
    </div>
  );
}

export default CosmeticConsultationSummary;
