import React from "react";
import Images from "assets/images";
import Input from "components/inputs/Input";
import Spacer from "components/Spacer/Spacer";
import { Form, Formik } from "formik";
import { ForgotPasswordSchema } from "helpers/validationSchema";
import Button from "components/buttons/Button";
import Header from "components/header/Header";
import useToastify from "hooks/useToastify";
import { useApiMutation } from "hooks/useApiMutation";
import { forgotPassword } from "api/actions/AuthActions";
import FormikErrorFocus from "formik-error-focus";
import { useHistory } from "react-router-dom";

function ForgotPassword() {
  const { mutate: callForgotPassword, loading } = useApiMutation(
    forgotPassword
  );

  const { showAlert } = useToastify();
  const history = useHistory();

  function checkGoBack() {
    let currentUrl = window.location.href;
    history.goBack();
    setTimeout(() => {
      if (currentUrl === window.location.href) {
        history.replace("/home");
      }
    }, 500);
  }
  async function onFormSubmit(values) {
    try {
      const result = await callForgotPassword(values);
      if (result.error || result?.payload?.status === false) {
        errorHandler(result?.payload?.message);
      } else {
        successHandler(result);
      }
    } catch (err) {
      errorHandler(err);
    }
  }

  function successHandler(result) {
    showAlert(result.payload.message, "success");
  }

  function errorHandler(message) {
    showAlert(message, "error");
  }

  return (
    <div className="flex flex-col h-full bg-veryLightBlue md:justify-center md:bg-gradient-to-b from-blue-300 to-blue-500">
      <div className="hidden w-full">
        <Header title="Forgot Password" />
      </div>
      <div className="flex-1 py-0 pb-32 overflow-y-auto login-card md:pb-0 md:pt-8 md:px-12 bg-blue md:bg-white md:flex-initial md:rounded-xl">
        <div className="flex flex-col items-center justify-center py-8 bg-white md:py-0">
          <img
            className="object-contain h-44 w-44 "
            src={Images.logoVerticalNaming}
            alt="logo"
          />
          <p className="flex pt-4 text-4xl font-medium leading-8 md:pt-6 md:text-5xl md:mt-4 md:font-bold">
            Forgot Password
          </p>
          <p className="pt-6 text-2xl font-medium text-center text-grey-700 md:hidden">
            Enter the email address you signed up with
          </p>
        </div>
        <p
          style={{ lineHeight: "2.8rem" }}
          className="hidden pt-6 text-3xl font-medium text-center md:text-2xl text-themeBlack md:block"
        >
          Enter the email address you signed up with
        </p>
        <Spacer height={27} />
        <div className="p-6 pb-0 mx-4 bg-white rounded-b-none md:mx-0 md:p-0 rounded-t-3xl md:rounded-b-3xl">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={ForgotPasswordSchema}
            onSubmit={onFormSubmit}
          >
            {({ dirty, isValid }) => (
              <Form className="required-label">
                <Input
                  name="email"
                  type="email"
                  label="Email"
                  id="email"
                  placeholder="johndoe@xyz.com"
                />
                <Spacer height={24} />
                <Button
                  label="Submit"
                  loading={loading}
                  disabled={loading || !(dirty && isValid)}
                  className="h-18"
                />
                <FormikErrorFocus
                  align={"bottom"}
                  ease={"linear"}
                  duration={500}
                />
              </Form>
            )}
          </Formik>
        </div>
        <div className="flex justify-center py-10 mx-4 my-0 bg-white rounded-t-none rounded-b-3xl md:rounded-b-none md:py-0 md:mx-0 md:my-10 md:bg-transparent">
          <div
            className="flex space-x-4 cursor-pointer w-max"
            onClick={() => checkGoBack()}
          >
            <img
              className="object-contain w-6"
              src={Images.arrowBack}
              alt="back"
            />
            <p className="text-2xl font-medium text-black">Back to login</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
