import Images from "assets/images";
import Input from "components/inputs/Input";
import Spacer from "components/Spacer/Spacer";
import HealthProfileRadio from "../HealthProfileRadio";
import { FAMILY_HISTORY_DISEASES, FAMILY_MEMBERS } from "./familyHistoryData";

const AccordionHeader = ({ headerTitle, isActive }) => (
  <div className="flex items-start justify-between px-6 py-6 border-t border-borderColor">
    <h3 className="text-2xl font-medium">{headerTitle}</h3>
    <div>
      <img
        src={Images.dropdown}
        alt="chev_down"
        className={`transition duration-500 ease-in-out w-10 h-10 transform ${
          isActive ? "rotate-180" : "rotate-0"
        }`}
      />
    </div>
  </div>
);

function onMemberClick({
  setFieldValue,
  family_history,
  currentData,
  disease,
  member,
}) {
  if (family_history?.length) {
    if (currentData) {
      if (currentData?.members_affected?.includes(member)) {
        setFieldValue(
          "data",
          family_history?.map((elem) => {
            if (elem?.disease === disease) {
              return {
                disease,
                members_affected: currentData?.members_affected?.filter(
                  (element) => element !== member
                ),
              };
            } else {
              return elem;
            }
          })
        );
      } else {
        setFieldValue(
          "data",
          family_history?.map((elem) => {
            if (elem?.disease === disease) {
              return {
                disease,
                members_affected: [...currentData?.members_affected, member],
              };
            } else {
              return elem;
            }
          })
        );
      }
    } else {
      setFieldValue("data", [
        ...family_history,
        { disease, members_affected: [member] },
      ]);
    }
  } else {
    setFieldValue("data", [{ disease, members_affected: [member] }]);
  }
}

export const familyHistoryAccordionData = ({
  setFieldValue,
  family_history,
  cancer_type,
  other_disease,
}) => {
  return FAMILY_HISTORY_DISEASES.map((disease, index) => {
    const selectedLength = family_history?.find(
      (elem) => elem?.disease === disease
    )?.members_affected?.length;
    return {
      header: ({ isActive }) => {
        if (disease?.includes("Cancer")) {
          return (
            <AccordionHeader
              key={index}
              isActive={isActive}
              headerTitle={`${disease} (${
                cancer_type && cancer_type?.length
                  ? cancer_type
                  : "please specify the type"
              }) ${selectedLength ? `( ${selectedLength} selected )` : ""}`}
            />
          );
        } else if (disease?.includes("Other")) {
          return (
            <AccordionHeader
              key={index}
              isActive={isActive}
              headerTitle={`${disease} (${
                other_disease && other_disease?.length
                  ? other_disease
                  : "please specify"
              }) ${selectedLength ? `( ${selectedLength} selected )` : ""}`}
            />
          );
        } else {
          return (
            <AccordionHeader
              key={index}
              isActive={isActive}
              headerTitle={`${disease} ${
                selectedLength ? `( ${selectedLength} selected )` : ""
              }`}
            />
          );
        }
      },
      content: disease?.includes("Other") ? (
        <div className="px-6 py-2">
          <Input
            labelstyles={{
              fontWeight: "500",
              textOpacity: 1,
              color: "rgba(16, 19, 71, 1)",
            }}
            value={other_disease ?? ""}
            name="other_disease"
            key="other_disease"
            label="Other disease"
            onChange={(e) => {
              setFieldValue("other_disease", e?.target?.value);
              if (!e?.target?.value?.trim()?.length) {
                setFieldValue(
                  "data",
                  family_history?.filter((el) => el?.disease !== disease)
                );
              }
            }}
          />
          <Spacer height={26} />
          <div
            className={`bg-alternativeWhite flex flex-wrap opacity-${
              other_disease?.trim()?.length ? "100" : "50"
            } p-6`}
          >
            {FAMILY_MEMBERS?.map((member, idx) => {
              const currentData = family_history?.find(
                (elem) => elem?.disease === disease
              );
              const isSelected = currentData?.members_affected?.includes(
                member
              );
              return (
                <div className="mb-6 mr-6" key={idx}>
                  <HealthProfileRadio
                    disabled={other_disease?.trim()?.length ? false : true}
                    text={member}
                    selected={isSelected ? true : false}
                    onClick={() =>
                      onMemberClick({
                        setFieldValue,
                        family_history,
                        currentData,
                        disease,
                        member,
                      })
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : disease?.includes("Cancer") ? (
        <div className="px-6 py-2">
          <Input
            value={cancer_type ?? ""}
            name="cancer_type"
            key="cancer_type"
            label="Cancer Type"
            onChange={(e) => {
              setFieldValue("cancer_type", e?.target?.value);
              if (e?.target?.value?.trim()?.length) {
                setFieldValue(
                  "data",
                  family_history?.filter((el) => el?.disease !== disease)
                );
              }
            }}
          />
          <Spacer height={26} />
          <div
            className={`bg-alternativeWhite flex flex-wrap opacity-${
              cancer_type?.trim()?.length ? "100" : "50"
            } p-6`}
          >
            {FAMILY_MEMBERS?.map((member, idx) => {
              const currentData = family_history?.find(
                (elem) => elem?.disease === disease
              );
              const isSelected = currentData?.members_affected?.includes(
                member
              );
              return (
                <div className="mb-6 mr-6" key={idx}>
                  <HealthProfileRadio
                    disabled={cancer_type?.trim()?.length ? false : true}
                    text={member}
                    selected={isSelected ? true : false}
                    onClick={() =>
                      onMemberClick({
                        setFieldValue,
                        family_history,
                        currentData,
                        disease,
                        member,
                      })
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="flex flex-wrap p-6 bg-alternativeWhite">
          {FAMILY_MEMBERS?.map((member, idx) => {
            const currentData = family_history?.find(
              (elem) => elem?.disease === disease
            );
            const isSelected = currentData?.members_affected?.includes(member);
            return (
              <div className="mb-6 mr-6" key={idx}>
                <HealthProfileRadio
                  text={member}
                  selected={isSelected ? true : false}
                  onClick={() =>
                    onMemberClick({
                      setFieldValue,
                      family_history,
                      currentData,
                      disease,
                      member,
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      ),
    };
  });
};
