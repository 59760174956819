import React from "react";
import Images from "assets/images";
import { step_status } from "helpers/enum";
import moment from "moment";

function VerticalStepper({ data }) {
  return (
    <div>
      {data?.map((content, index) => {
        return (
          <div key={index} className="flex">
            <div className="flex flex-col items-center justify-center">
              <div
                className={`flex flex-1 ${index === 0 ? "" : "border"} ${
                  data?.[index - 1]?.status === step_status.completed
                    ? "border-orange"
                    : "border-borderColor"
                } `}
              />
              <div
                className={`w-8 h-8 rounded-full ${
                  content?.rejected
                    ? "border border-red"
                    : content?.status === step_status.current
                    ? "border border-orange"
                    : content?.status === step_status.upcoming
                    ? "border border-borderColor"
                    : ""
                } ${
                  content?.rejected ? "bg-red" : ""
                } flex items-center justify-center`}
              >
                {content?.status === step_status.completed ? (
                  <img
                    src={Images.checkedOrange}
                    alt="completed"
                    className="w-full h-full"
                  />
                ) : content?.rejected ? (
                  <img
                    src={Images.closeWhiteIcon}
                    alt="rejected"
                    className="w-1/2"
                  />
                ) : null}
              </div>
              <div
                className={`flex flex-1 ${
                  index === data?.length - 1 ? "" : "border"
                } ${
                  content?.status === step_status.completed
                    ? "border-orange"
                    : "border-borderColor"
                }`}
              />
            </div>
            <div
              className={`w-full ${
                content?.status === step_status.current ||
                (content?.status === step_status.completed &&
                  index === data?.length - 1)
                  ? "bg-alternativeWhite"
                  : "bg-white"
              } p-6 rounded-2xl ml-6`}
            >
              {content?.status === step_status.upcoming ? (
                <p className="pb-2 text-xl font-light">
                  {content?.subtitle ?? "Up Next"}
                </p>
              ) : null}
              <h3 className="text-2xl font-bold">{content?.heading}</h3>
              {content?.status === step_status.current ||
              content?.status === step_status.completed ? (
                <>
                  <p className="pt-2 text-xl font-light">
                    {content?.timeStamp?.length
                      ? moment(content?.timeStamp).format("DD MMM, YYYY")
                      : ""}
                  </p>
                  <p className="pt-3 text-2xl font-medium text-grey-700">
                    {content?.statusText}
                  </p>
                </>
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default VerticalStepper;
