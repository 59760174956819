import { checkCoupon } from "api/actions/VisitActions";
import PromoCodeInput from "components/promocodeInput/PromoCodeInput";
import Spacer from "components/Spacer/Spacer";
import { useApiQuery } from "hooks/useApiQuery";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

function PromoCode({
  amount,
  promoCode,
  promoAmount,
  setPromoCode,
  setPromoAmount,
  isPromoCodeApplied,
  setIsPromoCodeApplied,
  setCheckingCoupon,
  speciality,
}) {
  const [showPromoInput, setShowPromoInput] = useState(true); //Testing out keeping this thing enabled by default
  const [percentage, setPercentage] = useState(0);
  const [error, setError] = useState(null);

  const { query: callCheckCoupon, loading: isCheckingCoupon } = useApiQuery(
    checkCoupon({ couponCode: promoCode, speciality }),
    false
  );

  useEffect(() => {
    if (isCheckingCoupon) {
      setCheckingCoupon(true);
    } else {
      setCheckingCoupon(false);
    }
  }, [isCheckingCoupon, setCheckingCoupon]);

  const handleChange = (newVal) => {
    setError(null);
    setPromoCode(newVal);
  };

  const togglePromoCodeApply = async () => {
    if (!isPromoCodeApplied && !error) {
      try {
        const checked = await callCheckCoupon();
        if (checked && checked.error === false) {
          if (checked?.payload?.err === true) {
            setError(checked?.payload?.msg);
            setIsPromoCodeApplied(false);
          } else {
            setError(null);
            setPromoAmount(checked?.payload?.amount);
            setPercentage(
              (Number(checked?.payload?.amount) * 100) / Number(amount)
            );
            setIsPromoCodeApplied(true);
          }
        }
      } catch (error) {}
    } else {
      setError(null);
      setPromoCode("");
      setIsPromoCodeApplied(false);
      setPromoAmount(null);
    }
  };

  if (showPromoInput) {
    return (
      <div>
        <h3
          onClick={() => setShowPromoInput(true)}
          className={"text-2xl font-medium py-2 text-themeBlack"}
        >
          Coupon Code
        </h3>
        <PromoCodeInput
          disabled={isCheckingCoupon ? true : false}
          promocode={promoCode}
          loading={isCheckingCoupon}
          percentage={percentage ? percentage?.toFixed(0) : null}
          type={error ? "error" : isPromoCodeApplied ? "success" : null}
          value={promoCode}
          onChange={(e) => handleChange(e?.target?.value)}
          onClick={togglePromoCodeApply}
        />
        {promoAmount ? (
          <>
            <Spacer height="20" />
            <h3 className="uppercase font-black text-xl">Payment Summary</h3>
            <div className="flex flex-1 items-center justify-between text-2xl font-medium pt-8">
              <p>Visit Charge</p>
              <p>${amount.toFixed(2)}</p>
            </div>
            <div className="flex flex-1 items-center justify-between text-2xl font-medium pt-8 text-green">
              <p>Discount ({promoCode?.toUpperCase()})</p>
              <p>-${promoAmount.toFixed(2)}</p>
            </div>
            <hr className="mt-8 bg-borderColor" />
            <div className="flex flex-1 items-center justify-between text-2xl font-medium pt-8">
              <p>Payable Amount</p>
              <p>${Math.max(0, Number(amount - promoAmount)).toFixed(2)}</p>
            </div>
          </>
        ) : null}
      </div>
    );
  } else {
    return (
      <h2
        onClick={() => setShowPromoInput(true)}
        className={"font-medium text-2xl underline cursor-pointer py-4"}
      >
        Have a promo code?
      </h2>
    );
  }
}

export default PromoCode;
