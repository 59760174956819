import React, { useEffect, useState } from "react";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import Header from "components/header/Header";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Spacer from "components/Spacer/Spacer";
import { useCurrentUser } from "hooks/useCurrentUser";
import CashOption from "components/choosePaymentMethod/CashOption";
import { useDispatch } from "react-redux";
import { cosmeticConsultationReducers } from "reducers/cosmetic/cosmeticConsultationReducer";
import useCreateCosmeticConsultation from "hooks/consultation/useCreateCosmeticConsultation";
import { getCashPrice } from "api/actions/VisitActions";
import { speciality } from "helpers/enum";
import { useHistory } from "react-router";
import { useApiQuery } from "hooks/useApiQuery";
import useUpdateCosmeticConsultation from "hooks/consultation/useUpdateCosmeticConsultation";
import { useCosmeticConsultationFlow } from "hooks/useConsultationFlow";
import HelpButton from "../../visitFlow/common/HelpButton";
import { trackEvent } from "helpers/analytics";

function CosmeticPricing() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userDetails } = useCurrentUser();

  const [selected, setSelected] = useState(false);

  const {
    payload: cashPriceResponse,
    loading: fetchingCashPrice,
    query: fetchCashPrice,
  } = useApiQuery(
    getCashPrice(userDetails?.self_patient_id, speciality.cosmetic),
    false
  );

  const {
    createNewConsultation,
    creatingConsultation,
  } = useCreateCosmeticConsultation();

  const { updateConsultation } = useUpdateCosmeticConsultation();

  const { incompleteData } = useCosmeticConsultationFlow();

  useEffect(() => {
    if (incompleteData?.payment_method === "cash") {
      setSelected(true);
    }
  }, [incompleteData?.payment_method]);

  useEffect(() => {
    fetchCashPrice();
  }, [fetchCashPrice]);

  function acceptPricing() {
    function callBack() {
      dispatch(
        cosmeticConsultationReducers.setPaymentConfirmed({
          value: true,
        })
      );
      history.push("/consultation/cosmetic");
    }

    if (incompleteData && Object.keys(incompleteData)?.length) {
      updateConsultation({
        payload: {
          case: {
            id: incompleteData?.id,
            cosmetic_steps: incompleteData?.cosmetic_steps,
          },
        },
        change_current_screen: false,
        screen_to_complete: null,
        callBack,
      });
    } else {
      createNewConsultation({
        patient_id: userDetails?.self_patient_id,
        change_current_screen: false,
        screen_to_complete: null,
        callBack,
      });
    }
  }

  return (
    <div className="h-screen bg-veryLightBlue">
      <Header title="Pricing Estimate" />
      <div className="px-8 overflow-y-auto h-eliminateHeaderWithFooter wrapper bg-blue">
        <Spacer height={30} />
        <MedicalAssistant
          content={
            <p className="flex flex-col space-y-4 leading-tight">
              <span>
                {userDetails?.first_name}, let's review pricing options before
                we start.
              </span>
              <span>
                First, we do not charge you until after you've been seen by one
                of our doctors. Feel free to book an appointment at no up-front
                cost.
              </span>
              <span>
                Second, you can trust that we will let you know if you're
                in-network, or out-of-network, before your appointment.
              </span>
              <span>
                Third, we give our patients an estimate of any visit charges.
                Our staff will verify your insurance and benefits once we have
                your health insurance information.
              </span>
            </p>
          }
        />
        <div className="my-16">
          <CashOption
            loading={fetchingCashPrice}
            hideBreakDown
            isActive={selected}
            onClick={() => {
              trackEvent("select_payment_cash", {
                speciality: "cosmetic",
              });
              setSelected(!selected);
            }}
            price={cashPriceResponse?.cash_price}
          />
        </div>
      </div>
      <HelpButton bottomClass={"bottom-40"} />
      <BottomFixedButton
        containerBgColor={"lightBlue"}
        label="Accept"
        onClick={!selected ? null : acceptPricing}
        loading={creatingConsultation}
        disabled={!selected}
      />
    </div>
  );
}

export default CosmeticPricing;
