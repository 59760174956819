import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import useHealthProfile from "hooks/useHealthProfile";
import { Form, Formik } from "formik";
import CSSLoader from "components/cssLoader/CSSLoader";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Spacer from "components/Spacer/Spacer";
import HealthProfileQuestion from "../../HealthProfileQuestion";
import HealthProfileRadio from "../../HealthProfileRadio";
import Input from "components/inputs/Input";
import { HealthProfileTobaccoUseValidationSchema } from "helpers/validationSchema";
import FormikErrorFocus from "formik-error-focus";
import { input_types } from "helpers/enum";
import SimpleHeader from "components/header/SimpleHeader";

const TOBACCO_USE = ["Yes", "No", "Previously"];
const TOBACCO_FORM = ["Cigarettes", "Chew", "Pipe", "Cigar"];
const FREQUENCY = ["Day", "Week", "Month", "Year"];
const YESNO = ["Yes", "No"];

function TobaccoUse() {
  const formRef = useRef();
  const history = useHistory();
  const patient_id = history?.location?.pathname?.match(/\d/g)?.join("");

  const {
    healthPayload,
    postHealthProfile,
    postingHealthProfile,
  } = useHealthProfile();

  useEffect(() => {
    if (
      healthPayload?.health_profile &&
      healthPayload?.health_profile?.tobacco_usage
    ) {
      formRef?.current?.setValues({
        tobacco_usage:
          healthPayload?.health_profile?.tobacco_usage?.tobacco_usage ?? "",
        no_of_years:
          healthPayload?.health_profile?.tobacco_usage?.no_of_years ?? "",
        quit_on: healthPayload?.health_profile?.tobacco_usage?.quit_date ?? "",
        tobacco_form:
          healthPayload?.health_profile?.tobacco_usage?.form_of_tobacco ?? [],
        no_of_tobacco:
          healthPayload?.health_profile?.tobacco_usage?.no_of_tobacco ?? "",
        frequency:
          healthPayload?.health_profile?.tobacco_usage?.frequency ?? "",
        household_smoke:
          healthPayload?.health_profile?.tobacco_usage?.smokers_in_household ??
          "",
        second_hand_smoke:
          healthPayload?.health_profile?.tobacco_usage
            ?.exposed_to_second_hand_smoke ?? "",
      });
    }
  }, [healthPayload]);

  function completed() {
    history.goBack();
  }

  function onSave(values) {
    let payload = {
      tobacco_usage: {
        tobacco_usage: values?.tobacco_usage,
        smokers_in_household: values?.household_smoke,
        exposed_to_second_hand_smoke: values?.second_hand_smoke,
        no_of_years: values?.no_of_years,
        quit_date: values?.quit_on,
        form_of_tobacco: values?.tobacco_form,
        frequency: values?.frequency,
        no_of_tobacco: values?.no_of_tobacco,
      },
    };
    postHealthProfile({ patientId: patient_id, payload, completed });
  }

  return (
    <div className="h-screen bg-veryLightBlue">
      <Formik
        innerRef={formRef}
        onSubmit={onSave}
        initialValues={{
          tobacco_usage: "",
          no_of_years: "",
          quit_on: "",
          tobacco_form: [],
          no_of_tobacco: "",
          frequency: "",
          household_smoke: "",
          second_hand_smoke: "",
        }}
        validationSchema={HealthProfileTobaccoUseValidationSchema}
      >
        {({ values, submitCount, errors, setFieldValue, setValues }) => (
          <Form className="h-full">
            <SimpleHeader
              title="Tobacco Use"
              showBackButton
              rightActionView={
                <button
                  disabled={postingHealthProfile}
                  type={"submit"}
                  className="text-2xl font-bold text-orange"
                >
                  {postingHealthProfile ? (
                    <CSSLoader className="w-8 h-8" color="orange" />
                  ) : (
                    "Save"
                  )}
                </button>
              }
            />
            <div className="p-12 overflow-y-auto bg-white h-eliminateHeaderWithBottomTab md:h-eliminateHeader">
              <MedicalAssistant
                content={<p>Please tell us about any tobacco usage.</p>}
              />
              <Spacer height={26} />
              <HealthProfileQuestion text={"Do you currently use tobacco?"} />
              <div className={`flex items-center mt-8 flex-wrap`}>
                {TOBACCO_USE?.map((usage, idx) => {
                  return (
                    <div className="flex flex-1 mb-8 mr-6" key={idx}>
                      <HealthProfileRadio
                        text={usage}
                        selected={values?.tobacco_usage === usage}
                        onClick={() => {
                          if (usage === "No") {
                            setValues({
                              tobacco_usage: usage,
                              no_of_years: "",
                              quit_on: "",
                              tobacco_form: [],
                              no_of_tobacco: "",
                              frequency: "",
                            });
                          } else {
                            setFieldValue("tobacco_usage", usage);
                          }
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              {submitCount && errors?.tobacco_usage ? (
                <p className="mt-4 text-base font-bold text-red">
                  {errors?.tobacco_usage}
                </p>
              ) : null}
              {values?.tobacco_usage?.length &&
              values?.tobacco_usage !== "No" ? (
                <>
                  <Spacer height={26} />
                  <Input
                    labelstyles={{
                      fontWeight: "500",
                      textOpacity: 1,
                      color: "rgba(16, 19, 71, 1)",
                    }}
                    type="number"
                    label="How many years?"
                    id="no_of_years"
                    name="no_of_years"
                    min="1"
                  />
                  {values?.tobacco_usage === "Previously" ? (
                    <>
                      <Spacer height={26} />
                      <Input
                        labelstyles={{
                          fontWeight: "500",
                          textOpacity: 1,
                          color: "rgba(16, 19, 71, 1)",
                        }}
                        type={input_types.DATEPICKER}
                        label="When did you quit?"
                        id="quit_on"
                        name="quit_on"
                      />
                    </>
                  ) : null}
                  <Spacer height={26} />
                  <HealthProfileQuestion text={"What form of tobacco?"} />
                  <div className={`flex items-center mt-8 flex-wrap`}>
                    {TOBACCO_FORM?.map((form, idx) => {
                      return (
                        <div className="flex flex-1 mb-8 mr-6" key={idx}>
                          <HealthProfileRadio
                            text={form}
                            selected={values?.tobacco_form?.includes(form)}
                            onClick={() =>
                              values?.tobacco_form?.includes(form)
                                ? setFieldValue(
                                    "tobacco_form",
                                    values?.tobacco_form?.filter(
                                      (el) => el !== form
                                    )
                                  )
                                : setFieldValue("tobacco_form", [
                                    ...values?.tobacco_form,
                                    form,
                                  ])
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                  {submitCount && errors?.tobacco_form ? (
                    <p className="mt-4 text-base font-bold text-red">
                      {errors?.tobacco_form}
                    </p>
                  ) : null}
                  <Spacer height={26} />
                  <Input
                    labelstyles={{
                      fontWeight: "500",
                      textOpacity: 1,
                      color: "rgba(16, 19, 71, 1)",
                    }}
                    type="number"
                    label="Number"
                    id="no_of_tobacco"
                    name="no_of_tobacco"
                    min="1"
                  />
                  <Spacer height={26} />
                  <HealthProfileQuestion text={"Frequency"} />
                  <div className={`flex items-center mt-8 flex-wrap`}>
                    {FREQUENCY?.map((frequency, idx) => {
                      return (
                        <div className="flex flex-1 mb-8 mr-6" key={idx}>
                          <HealthProfileRadio
                            text={frequency}
                            selected={values?.frequency === frequency}
                            onClick={() =>
                              setFieldValue("frequency", frequency)
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                  {submitCount && errors?.frequency ? (
                    <p className="mt-4 text-base font-bold text-red">
                      {errors?.frequency}
                    </p>
                  ) : null}
                </>
              ) : null}
              <Spacer height={26} />
              <HealthProfileQuestion
                text={"Does anyone in your household smoke?"}
              />
              <div className={`flex items-center mt-8 flex-wrap`}>
                {YESNO?.map((option, idx) => {
                  return (
                    <div className="flex flex-1 mb-8 mr-6" key={idx}>
                      <HealthProfileRadio
                        text={option}
                        selected={values?.household_smoke === option}
                        onClick={() => setFieldValue("household_smoke", option)}
                      />
                    </div>
                  );
                })}
              </div>
              {submitCount && errors?.household_smoke ? (
                <p className="mt-4 text-base font-bold text-red">
                  {errors?.household_smoke}
                </p>
              ) : null}
              <Spacer height={26} />
              <HealthProfileQuestion
                text={"Are you exposed to second hand smoke?"}
              />
              <div className={`flex items-center mt-8 flex-wrap`}>
                {YESNO?.map((option, idx) => {
                  return (
                    <div className="flex flex-1 mb-8 mr-6" key={idx}>
                      <HealthProfileRadio
                        text={option}
                        selected={values?.second_hand_smoke === option}
                        onClick={() =>
                          setFieldValue("second_hand_smoke", option)
                        }
                      />
                    </div>
                  );
                })}
              </div>
              {submitCount && errors?.second_hand_smoke ? (
                <p className="mt-4 text-base font-bold text-red">
                  {errors?.second_hand_smoke}
                </p>
              ) : null}
              <Spacer height={100} />
            </div>
            <FormikErrorFocus align={"bottom"} ease={"linear"} duration={500} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default TobaccoUse;
