import React, { useEffect, useRef, useState } from "react";
import { Icon } from "stories/Icons";
import { v4 as uuidv4 } from "uuid";

const ImagePreview = ({
  imageFile,
  imageUrl,
  handleImageDelete,
  previewLabel,
}) => {
  return (
    <div className="relative flex items-start justify-between space-x-3">
      <img
        src={imageFile ? imageFile.previewUrl : imageUrl}
        alt={previewLabel}
        className="object-cover h-20 rounded-lg max-w-16"
      />

      <div className="flex flex-col items-center text-center">
        <span className="mt-2 text-lg font-bold text-gray-600">
          {previewLabel}
        </span>
        {imageFile && (
          <span className="text-sm text-gray-600">{`${(
            imageFile?.file?.size /
            (1024 * 1024)
          ).toFixed(2)} MB`}</span>
        )}
      </div>
      {imageFile && (
        <button
          className="pl-4"
          type="button"
          onClick={() => handleImageDelete()}
        >
          <Icon name="close" />
        </button>
      )}
    </div>
  );
};

const ImageUploader = ({
  id = "image",
  label = "Upload a photos or drag and drop (front)",
  onImageUpload = () => {},
  imageUrl,
  previewLabel,
  handleDelete,
}) => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const MAX_IMAGE_SIZE = 15 * 1024 * 1024;
  const [originalImageUrl, setOriginalImageUrl] = useState(imageUrl);
  const fileInputRef = useRef(null);

  function handleFileUpload(event) {
    const file = event.target.files[0];
    if (file?.size <= MAX_IMAGE_SIZE) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageObject = {
          id: uuidv4(),
          label: id,
          uri: file.name.toString(),
          file: file,
          data: reader.result,
          previewUrl: URL.createObjectURL(file),
        };
        setUploadedImage(imageObject);
        onImageUpload(imageObject);
      };
      reader.readAsDataURL(file);
    } else if (file?.size > MAX_IMAGE_SIZE) {
      alert("File size exceeds the maximum limit of 15 MB");
    }
  }

  const handleImageDelete = () => {
    handleDelete(id);
    setUploadedImage(null);
    fileInputRef.current.value = "";
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file?.size <= MAX_IMAGE_SIZE) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageObject = {
          id: uuidv4(),
          label: id,
          uri: file.name.toString(),
          file: file,
          data: reader.result,
          previewUrl: URL.createObjectURL(file),
        };
        setUploadedImage(imageObject);
        onImageUpload(imageObject);
      };
      reader.readAsDataURL(file);
    } else {
      alert("File size exceeds the maximum limit of 15 MB");
    }
  };

  useEffect(() => {
    setOriginalImageUrl(imageUrl);
  }, [imageUrl]);

  return (
    <div
      id={id}
      className="flex flex-col w-full cursor-pointer"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div
        className="flex flex-col items-center justify-center w-full mb-4 space-y-2 border-2 border-dashed rounded-md border-secondaryBlue-600 min-h-11 bg-secondaryBlue-100"
        onClick={() => {
          fileInputRef.current.click();
        }}
      >
        <Icon name="camera" />
        <div className="hidden">
          <input
            ref={fileInputRef}
            id={`${id}-file-upload`}
            type="file"
            accept="image/*"
            onChange={handleFileUpload}
          />
        </div>
        <label className="text-lg font-bold text-center text-gray-600 ">
          {label}
          <p className="font-normal text-black">{"PNG, JPG up to 15MB"}</p>
        </label>
      </div>
      {(uploadedImage || originalImageUrl) && (
        <div className="flex flex-wrap gap-4">
          <ImagePreview
            imageFile={uploadedImage}
            imageUrl={
              uploadedImage !== null
                ? uploadedImage.previewUrl
                : originalImageUrl
            }
            handleImageDelete={handleImageDelete}
            previewLabel={previewLabel}
          />
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
