import React from "react";
import Images from "assets/images";
import DoctorCard from "components/doctorCard/DoctorCard";
import Header from "components/header/Header";
import Spacer from "components/Spacer/Spacer";

function DoctorProfileDetails({ details, onClose }) {
  const about = details?.practice_user_info?.about;
  const conditonsTreated = details?.practice_user_info?.conditions_treated;
  const qualificationAndEducation =
    details?.practice_user_info?.qualifications_and_education;
  return (
    <div className="bg-veryLightBlue">
      <Header
        title={details?.name}
        hideBackBtn
        rightActionView={
          <img
            onClick={onClose}
            className="cursor-pointer w-10"
            src={Images.closeIcon}
            alt={"close_icons"}
          />
        }
      />
      <div className="overflow-y-auto pb-32 h-eliminateHeader wrapper bg-white">
        <div className="bg-alternativeWhite">
          <div className="py-8 px-12">
            <DoctorCard
              imageUrl={details?.avatar}
              name={details?.name}
              speciality={details?.speciality ?? ""}
              isActive={true}
              license={details?.license}
            />
            <Spacer height={22} />
            <div className="h-px bg-borderColor" />
            <Spacer height={15} />
            <div>
              <h2 className="font-bold text-2xl leading-9 hidden">
                {details?.practice?.name}
              </h2>
              <p className="font-light text-2xl leading-9 hidden">
                {" "}
                {details?.practice?.add_1.trim().length
                  ? details?.practice?.add_1 + ","
                  : ""}
                {details?.practice?.add_2.trim().length
                  ? details?.practice?.add_2 + ","
                  : ""}
              </p>
              <p className="font-light text-2xl leading-9 hidden">
                {details?.practice?.city} {details?.practice?.zip}
              </p>
              {details?.licensed_states?.length ? (
                <div className="flex">
                  <p className="font-medium text-2xl leading-9">
                    Licensed States:{" "}
                    <span className="font-light text-2xl leading-9">
                      {details?.licensed_states?.join(", ")}
                    </span>
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="px-12 bg-white">
          {about ? (
            <div className="pt-8 pb-8">
              <h3 className="font-black text-xl uppercase tracking-widest">
                ABOUT
              </h3>
              <p className="font-light text-2xl leading-9 mt-2">{about}</p>
            </div>
          ) : null}
          {conditonsTreated ? (
            <div className="pb-8">
              <h3 className="font-black text-xl uppercase tracking-widest">
                Conditions Treated
              </h3>
              <p className="font-light text-2xl leading-9 mt-2">
                {conditonsTreated}
              </p>
            </div>
          ) : null}
          {qualificationAndEducation ? (
            <div className="pb-8">
              <h3 className="font-black text-xl uppercase tracking-widest">
                Qualifications and Education
              </h3>
              <p
                className="font-light text-2xl leading-9 mt-2"
                dangerouslySetInnerHTML={{ __html: qualificationAndEducation }}
              ></p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default DoctorProfileDetails;
