import React from "react";
import VisitSummaryContent from "components/visitSummaryAccordion/VisitSummaryContent";
import VisitSummaryContentHeading from "components/visitSummaryAccordion/VisitSummaryContentHeading";
import Spacer from "components/Spacer/Spacer";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import ImageComponent from "components/imageComponent/ImageComponent";
import { capitalize } from "helpers/lodashPolyfills";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { dermatology_sub_steps } from "helpers/enum";

function PhotosSummary({ readOnly, data }) {
  const { incompleteData } = useDermatologyVisitFlow();
  const history = useHistory();
  const dispatch = useDispatch();
  const photos = {
    close_up_1:
      data?.thumbnail_close_up_photo_1_url ??
      incompleteData?.thumbnail_close_up_photo_1_url ??
      data?.detail_close_up_photo_1_url ??
      incompleteData?.detail_close_up_photo_1_url,
    far_away_1:
      data?.thumbnail_far_away_photo_1_url ??
      incompleteData?.thumbnail_far_away_photo_1_url ??
      data?.detail_far_away_photo_1_url ??
      incompleteData?.detail_far_away_photo_1_url,
    close_up_2:
      data?.thumbnail_close_up_photo_2_url ??
      incompleteData?.thumbnail_close_up_photo_2_url ??
      data?.detail_close_up_photo_2_url ??
      incompleteData?.detail_close_up_photo_2_url,
    far_away_2:
      data?.thumbnail_far_away_photo_2_url ??
      incompleteData?.thumbnail_far_away_photo_2_url ??
      data?.detail_far_away_photo_2_url ??
      incompleteData?.detail_far_away_photo_2_url,
    close_up_3:
      data?.thumbnail_close_up_photo_3_url ??
      incompleteData?.thumbnail_close_up_photo_3_url ??
      data?.detail_close_up_photo_3_url ??
      incompleteData?.detail_close_up_photo_3_url,
    far_away_3:
      data?.thumbnail_far_away_photo_3_url ??
      incompleteData?.thumbnail_far_away_photo_3_url ??
      data?.detail_far_away_photo_3_url ??
      incompleteData?.detail_far_away_photo_3_url,
  };
  return (
    <VisitSummaryContent
      readOnly={readOnly}
      onEditClick={() => {
        dispatch(
          dermaVisitFlowReducers.currentScreen({
            value: dermatology_sub_steps.upload_photos,
          })
        );
        history.push("/visit/dermatology/new-visit");
      }}
    >
      <VisitSummaryContentHeading text="Case Photos" />
      <div className="flex pt-8 flex-wrap">
        {Object.keys(photos)?.map((el, idx) => {
          if (photos?.[el] && photos?.[el]?.length) {
            return (
              <div key={idx} className="mr-4 mb-4 flex flex-col items-center">
                <ImageComponent key={idx} url={photos?.[el]} />
                <Spacer height={4} />
                <VisitSummaryContentHeading
                  text={capitalize(el)?.split("_")?.join(" ")}
                />
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </VisitSummaryContent>
  );
}

export default PhotosSummary;
