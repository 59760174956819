import React from "react";
import { useCosmeticConsultationFlow } from "hooks/useConsultationFlow";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import VisitSummaryContent from "components/visitSummaryAccordion/VisitSummaryContent";
import { cosmeticConsultationReducers } from "reducers/cosmetic/cosmeticConsultationReducer";
import { cosmetic_steps } from "helpers/enum";
import VisitSummaryContentHeading from "components/visitSummaryAccordion/VisitSummaryContentHeading";
import VisitSummaryContentParagraph from "components/visitSummaryAccordion/VisitSummaryContentParagraph";
import moment from "moment";

function CosmeticAppointmentSlotSummary({ data, readOnly }) {
  const { incompleteData } = useCosmeticConsultationFlow();
  const history = useHistory();
  const dispatch = useDispatch();

  const getMomentTime = (time) => moment(time, "hh:mm").format("LT");

  return (
    <VisitSummaryContent
      readOnly={readOnly}
      onEditClick={() => {
        dispatch(
          cosmeticConsultationReducers.currentScreen({
            value: cosmetic_steps.select_slot,
          })
        );
        history.push("/consultation/cosmetic");
      }}
    >
      <VisitSummaryContentHeading text={"Date & time"} />
      <VisitSummaryContentParagraph
        text={
          data?.meta?.booking_info?.selectedDate
            ? moment(
                data?.meta?.booking_info?.selectedDate,
                "YYYY-MM-DD"
              ).format("MMM DD, YYYY")
            : moment(
                incompleteData?.meta?.booking_info?.selectedDate,
                "YYYY-MM-DD"
              ).format("MMM DD, YYYY")
        }
      />
      <VisitSummaryContentParagraph
        text={
          data?.meta?.booking_info?.slotInfo?.start_time &&
          data?.meta?.booking_info?.slotInfo?.end_time
            ? getMomentTime(data?.meta?.booking_info?.slotInfo?.start_time) +
              " - " +
              getMomentTime(data?.meta?.booking_info?.slotInfo?.end_time)
            : incompleteData?.meta?.booking_info?.slotInfo?.start_time &&
              incompleteData?.meta?.booking_info?.slotInfo?.end_time
            ? getMomentTime(
                incompleteData?.meta?.booking_info?.slotInfo?.start_time
              ) +
              " - " +
              getMomentTime(
                incompleteData?.meta?.booking_info?.slotInfo?.end_time
              )
            : getMomentTime(
                incompleteData?.meta?.booking_info?.slotInfo?.start_time
              )
        }
      />
    </VisitSummaryContent>
  );
}

export default CosmeticAppointmentSlotSummary;
