import React, { useEffect, useRef } from "react";
import useHealthProfile from "hooks/useHealthProfile";
import { useHistory, useParams } from "react-router-dom";
import CSSLoader from "components/cssLoader/CSSLoader";
import Spacer from "components/Spacer/Spacer";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import HealthProfileQuestion from "../HealthProfileQuestion";
import HealthProfileRadio from "../HealthProfileRadio";
import { Form, Formik } from "formik";
import AddHealthProfileForMedications from "./AddHealthProfileForMedications";
import { HealthProfileMedicationsSchema } from "helpers/validationSchema";
import FormikErrorFocus from "formik-error-focus";
import SimpleHeader from "components/header/SimpleHeader";

const medication_forms = [
  "Tablet",
  "Ointment",
  "Capsule",
  "Gel",
  "Cream",
  "Other",
];

const YESNO = ["Yes", "No"];

function HPMedications() {
  const formRef = useRef();
  const history = useHistory();
  const { patient_id } = useParams();
  const {
    healthPayload,
    postHealthProfile,
    postingHealthProfile,
  } = useHealthProfile();

  useEffect(() => {
    if (healthPayload) {
      formRef?.current?.setValues({
        on_medication:
          healthPayload?.health_profile?.on_medication === "not_on_medication"
            ? "No"
            : healthPayload?.health_profile?.on_medication ===
              "is_on_medication"
            ? "Yes"
            : "",
        medications: healthPayload?.health_profile?.medications?.length
          ? healthPayload?.health_profile?.medications?.map((el) => {
              if (medication_forms.includes(el?.form) === false) {
                return {
                  ...el,
                  form: "Other",
                  other_form: el?.form,
                };
              } else {
                return el;
              }
            })
          : [],
      });
    }
  }, [healthPayload]);

  function completed() {
    history.goBack();
  }

  function onSave(values) {
    let payload = {};
    if (values?.on_medication === "Yes") {
      payload = {
        on_medication: 2,
        medications: values?.medications?.map((el) => {
          if (el?.form === "Other") {
            return {
              ...el,
              form: el?.other_form,
            };
          } else {
            return el;
          }
        }),
      };
    } else {
      payload = {
        on_medication: 1,
        medications: [],
      };
    }
    postHealthProfile({ patientId: patient_id, payload, completed });
  }
  return (
    <div className="h-screen bg-veryLightBlue">
      <Formik
        onSubmit={onSave}
        innerRef={formRef}
        initialValues={{
          on_medication: "",
          medications: [],
        }}
        validationSchema={HealthProfileMedicationsSchema}
      >
        {({ setFieldValue, values, setValues, errors }) => (
          <Form className="h-full">
            <SimpleHeader
              showBackButton
              title="Medications"
              rightActionView={
                <button
                  disabled={postingHealthProfile}
                  type={"submit"}
                  className="text-2xl font-bold text-orange"
                >
                  {postingHealthProfile ? (
                    <CSSLoader className="w-8 h-8" color="orange" />
                  ) : (
                    "Save"
                  )}
                </button>
              }
            />
            <div className="px-12 py-6 overflow-y-auto bg-white h-eliminateHeaderWithBottomTab md:h-eliminateHeader">
              <MedicalAssistant
                content={<p>Please list all your medications.</p>}
              />
              <Spacer height={26} />
              <HealthProfileQuestion
                text={
                  "Are you taking any prescription or over-the-counter medications?"
                }
              />
              <div className="flex items-center mt-8">
                {YESNO.map((option, optionIndex) => (
                  <div className="flex flex-1" key={optionIndex}>
                    <HealthProfileRadio
                      text={option}
                      selected={values?.on_medication === option ? true : false}
                      onClick={() => setFieldValue("on_medication", option)}
                    />
                  </div>
                ))}
              </div>
              {values?.on_medication === "Yes" ? (
                <div className="pb-12">
                  <AddHealthProfileForMedications
                    medication_forms={medication_forms}
                    setFieldValue={setFieldValue}
                    medications={values?.medications}
                  />
                </div>
              ) : null}
            </div>
            <FormikErrorFocus align={"bottom"} ease={"linear"} duration={500} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default HPMedications;
