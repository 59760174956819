import React, { useEffect, useRef } from "react";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import usePatients from "hooks/visit/usePatients";
import {
  getGeoDataFromZipcodeAction,
  updateBasicInfoAction,
} from "api/actions/UserActions";
import { useApiQuery } from "hooks/useApiQuery";
import useVisitStepChange from "hooks/useVisitStepChange";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { useDispatch } from "react-redux";
import { dermatology_sub_steps } from "helpers/enum";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { Form, Formik } from "formik";
import { getInchesInFeet } from "helpers/metricConversion";
import Input from "components/inputs/Input";
import { updateBasicInfoCRSchema } from "helpers/validationSchema";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useParameterizedQuery } from "react-fetching-library";
import useDataFromUserDetails from "hooks/useDataFromUserDetails";
import { useApiMutation } from "hooks/useApiMutation";

function ConfirmResidenceForDerm() {
  const dispatch = useDispatch();
  const formRef = useRef();
  const nextAnimationRef = useRef();
  const {
    patientsList,
    incompleteData,
    subSteps,
    currentScreen,
  } = useDermatologyVisitFlow();
  const { getCurrentPatient, getPatients, fetchingPatients } = usePatients();
  const { rightButtonClickRef, setLoading } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentPatient = getCurrentPatient(
    incompleteData?.patient_id,
    patientsList
  );
  const {
    loading: gettingZipDetails,
    payload: zipDetails,
    query: getZipcodeDetails,
  } = useApiQuery(getGeoDataFromZipcodeAction(currentPatient?.zip), false);
  const { userId } = useCurrentUser();
  const { query: getZipDetails } = useParameterizedQuery(
    getGeoDataFromZipcodeAction
  );
  const {
    mutate: updateBasicIfo,
    payload: userPayload,
    loading: updatingBasicDetails,
  } = useApiMutation(updateBasicInfoAction);

  const { updateVisit } = useUpdateDermatologyVisit();
  const { dermaDoctorDetails } = useDataFromUserDetails();

  const initialCity = currentPatient?.city
    ? currentPatient?.state
      ? `${currentPatient?.city}, ${currentPatient?.state}`
      : currentPatient?.city
    : "";
  useEffect(() => {
    if (subSteps?.select_patient?.value === "Me") {
      dispatch(
        dermaVisitFlowReducers.confirm_residence({
          prevScreen: dermatology_sub_steps.select_patient,
        })
      );
    } else if (subSteps?.select_patient?.value === "My Child") {
      dispatch(
        dermaVisitFlowReducers.confirm_residence({
          prevScreen: dermatology_sub_steps.select_child,
        })
      );
    } else if (subSteps?.select_patient?.value === "Someone else") {
      dispatch(
        dermaVisitFlowReducers.confirm_residence({
          prevScreen: dermatology_sub_steps.select_someone_else,
        })
      );
    }
  }, [dispatch, subSteps]);

  useEffect(() => {
    getZipcodeDetails();
  }, [getZipcodeDetails]);

  useEffect(() => {
    if (userPayload) {
      getPatients();
    }
  }, [userPayload, getPatients]);

  function dispatchValidation(isValid) {
    if (subSteps[currentScreen]?.isValid !== isValid) {
      dispatch(
        dermaVisitFlowReducers.confirm_residence({
          isValid: isValid,
        })
      );
    }
  }

  useEffect(() => {
    if (gettingZipDetails) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [gettingZipDetails, setLoading]);

  useEffect(() => {
    if (zipDetails?.geo_data) {
      dispatch(
        dermaVisitFlowReducers.confirm_residence({
          isValid: true,
        })
      );
    } else {
      dispatch(
        dermaVisitFlowReducers.confirm_residence({
          isValid: false,
        })
      );
    }
  }, [dispatch, zipDetails]);

  async function onRightButtonClick(_, nextAnimation) {
    nextAnimationRef.current = nextAnimation;
    // if (checkBasicDetailsAvailable({ patient: currentPatient })) {
    await formRef?.current?.handleSubmit(nextAnimation);
    // }
  }

  async function onFormSubmit(values) {
    setLoading(true);
    let payload = {
      id: userId,
      user: {
        patients_attributes: [
          {
            ...currentPatient,
            city: values.city.split(",")[0],
            street_add_1: values.street_address_1,
            street_add_2: values.street_address_2,
            zip: values.zip_code,
          },
        ],
      },
    };
    try {
      const { payload: zipResult } = await getZipDetails(values.zip_code);
      if (zipResult?.geo_data) {
        if (
          dermaDoctorDetails?.licensed_states?.includes(
            zipResult?.geo_data?.state_code
          )
        ) {
          payload.user.patients_attributes[0].state =
            zipResult?.geo_data?.state_name;
          await updateBasicIfo({ userId, payload });
          if (!updatingBasicDetails && !fetchingPatients) {
            updateVisit({
              payload: {
                case: {
                  id: incompleteData?.id,
                  state: zipDetails?.geo_data?.state_code,
                  dermatology_steps: incompleteData?.dermatology_steps,
                },
              },
              change_current_screen: true,
              screen_to_complete: dermatology_sub_steps.confirm_residence,
              callBack: () => {
                dispatch(
                  dermaVisitFlowReducers.confirm_residence({
                    value: zipDetails?.geo_data?.state_code,
                  })
                );
                nextAnimationRef?.current();
              },
            });
          }
          setLoading(false);
        } else {
          setLoading(false);
          alert(
            "The state of residence is outside of the coverage area of this provider. Please change your zipcode and try again."
          );
          return;
        }
      } else {
        setLoading(false);
        alert("Invalid zipcode.");
        return;
      }
    } catch (error) {
      setLoading(false);
      alert("Something went wrong. Please try again later.");
    }
  }
  return (
    <StepScreenContainer align="start" childClass={"wrapper"}>
      <MedicalAssistant
        content={
          <p>
            Thank you, {currentPatient?.first_name}. Let's make sure that our
            medical group is licensed to practice medicine where you live.
            Please confirm that you currently live in the location below.
            <br />
            <br />
            For students living away at school, please confirm the state of your
            school's campus.
          </p>
        }
      />
      <Formik
        innerRef={formRef}
        initialValues={{
          street_address_1: currentPatient?.street_add_1 ?? "",
          street_address_2: currentPatient?.street_add_2 ?? "",
          city: initialCity,
          type:
            currentPatient?.relationship_with_account === "pappa"
              ? "young"
              : "old",
          zip_code: currentPatient?.zip ?? "",
          dob: currentPatient?.dob ?? "",
          gender: currentPatient?.gender
            ? currentPatient?.gender.charAt(0).toUpperCase() +
              currentPatient?.gender.slice(1)
            : "",
          heightFeet: currentPatient?.height
            ? getInchesInFeet({ height: currentPatient?.height }).feet
            : "",
          heightInches: currentPatient?.height
            ? getInchesInFeet({ height: currentPatient?.height }).inches
            : "",
          weight: currentPatient?.weight ?? "",
        }}
        validationSchema={updateBasicInfoCRSchema}
        onSubmit={onFormSubmit}
      >
        {({ isValid }) => {
          setTimeout(() => dispatchValidation(isValid), 0);
          return (
            <Form className="flex flex-col p-8 mt-8 space-y-8 bg-white rounded-2xl">
              <Input
                name="street_address_1"
                label="Street Address Line 1"
                id="street_address_1"
                placeholder="123 Main St"
                maxLength={35}
                required
              />
              <Input
                name="street_address_2"
                label="Apt, suite, building (optional)"
                id="street_address_2"
                placeholder="Apt 4B"
                maxLength={35}
              />
              <div className="flex flex-col space-x-0 space-y-8 md:flex-row md:space-x-4 md:space-y-0">
                <div className="flex-1">
                  <Input
                    name="city"
                    label="City"
                    id="city"
                    required
                    placeholder="Los Angeles"
                  />
                </div>
                <div className="flex-1">
                  <Input
                    name="zip_code"
                    label="Zipcode"
                    required
                    id="zip_code"
                    placeholder="90001"
                    maxLength={5}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </StepScreenContainer>
  );
}

export default ConfirmResidenceForDerm;
