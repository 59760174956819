const constants = {
  TERMS_AND_CONDITIONS_URL: "https://www.skymd.com/tnc",
  PRIVACY_URL: "https://www.skymd.com/privacy",
  ABOUT_US_URL: "https://www.skymd.com/about",
  FAQ: "https://www.skymd.com/faq",
  SUPPORT_MAIL: "support@skymd.com",
  IOS_APP_URL:
    "https://apps.apple.com/us/app/skymd-24-7-telehealth/id963855994",
  ANDROID_APP_URL:
    "https://play.google.com/store/apps/details?id=com.skymd.online_doctor_consultation",
  SERVER_ERROR_CODES: [500, 501, 502, 503, 504],
};

export default constants;
