import React from "react";
import { usePrimaryCareVisitFlow } from "hooks/useVisitFlow";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import VisitSummaryContent from "components/visitSummaryAccordion/VisitSummaryContent";
import { primaryCareVisitFlowReducers } from "reducers/primary_care/primaryCareVisitFlowReducer";
import { primary_care_sub_steps } from "helpers/enum";
import VisitSummaryContentHeading from "components/visitSummaryAccordion/VisitSummaryContentHeading";
import VisitSummaryContentParagraph from "components/visitSummaryAccordion/VisitSummaryContentParagraph";
import moment from "moment";

function SlotSummary({ readOnly, data }) {
  const { incompleteData } = usePrimaryCareVisitFlow();
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <VisitSummaryContent
      readOnly={readOnly}
      onEditClick={() => {
        dispatch(
          primaryCareVisitFlowReducers.currentScreen({
            value: primary_care_sub_steps.select_slot,
          })
        );
        history.push("/visit/primary-care/new-visit");
      }}
    >
      <VisitSummaryContentHeading text={"Date & time"} />
      <VisitSummaryContentParagraph
        text={
          moment(data?.meta?.booking_info?.selectedDate).format("MM-DD-YYYY") ??
          moment(incompleteData?.meta?.booking_info?.selectedDate).format(
            "MM-DD-YYYY"
          )
        }
      />
      <VisitSummaryContentParagraph
        text={
          data?.meta?.booking_info?.slotInfo?.start_time &&
          data?.meta?.booking_info?.slotInfo?.end_time
            ? data?.meta?.booking_info?.slotInfo?.start_time +
              " - " +
              data?.meta?.booking_info?.slotInfo?.end_time
            : incompleteData?.meta?.booking_info?.slotInfo?.start_time &&
              incompleteData?.meta?.booking_info?.slotInfo?.end_time
            ? incompleteData?.meta?.booking_info?.slotInfo?.start_time +
              " - " +
              incompleteData?.meta?.booking_info?.slotInfo?.end_time
            : incompleteData?.meta?.booking_info?.slotInfo?.start_time
        }
      />
    </VisitSummaryContent>
  );
}

export default SlotSummary;
