import React from "react";
import Images from "assets/images";

function ConfirmIdentityImagePickerPed({
  alertDesktopPatient,
  handleChange,
  imagePickerRef,
  imageUrl,
}) {
  return (
    <>
      <input
        ref={imagePickerRef}
        id="confirm_identity_image_picker"
        type="file"
        accept="image/png, image/jpg, image/jpeg"
        onChange={handleChange}
        className="hidden"
      />
      <label
        htmlFor="confirm_identity_image_picker"
        className="relative w-60 h-60 bg-white rounded-3xl border-2 border-orange flex items-center justify-center cursor-pointer"
      >
        <button className="w-full h-full" onClick={alertDesktopPatient}>
          {imageUrl?.length ? (
            <img
              className="w-60 h-60 rounded-3xl object-contain"
              src={imageUrl}
              alt="identity_image"
            />
          ) : null}
          {imageUrl?.length ? null : (
            <img
              className="w-16 h-16 absolute top-1/2 left-1/2 -mt-8 -ml-8"
              src={Images.addIcon}
              alt="add_image"
            />
          )}
          {imageUrl?.length ? (
            <img
              className="w-16 h-16 absolute -bottom-3 -right-3 shadow-2xl"
              src={Images.editIcon}
              alt="add_image"
            />
          ) : null}
        </button>
      </label>
    </>
  );
}

export default ConfirmIdentityImagePickerPed;
