import React from "react";
import { useCurrentUser } from "hooks/useCurrentUser";
import { speciality } from "helpers/enum";
import SelectDermaProvider from "./SelectProviderBasedOnSpeciality/SelectDermaProvider";
import SelectPcProvider from "./SelectProviderBasedOnSpeciality/SelectPcProvider";

function SelectedProviders() {
  const { currentSpeciality } = useCurrentUser();
  if (currentSpeciality === speciality.dermatology) {
    return <SelectDermaProvider />;
  } else if (currentSpeciality === speciality.primary_care) {
    return <SelectPcProvider />;
  }
}

export default SelectedProviders;
