import React, { useState } from "react";
import Images from "assets/images";
import "./TimeslotReminder.scss";
import { useIdleTimer } from "react-idle-timer";
import { useHistory } from "react-router-dom";
import { speciality } from "helpers/enum";
import { currentSpeciality } from "reducers/currentUserReducer";
import { trackEvent } from "helpers/analytics";

function TimeslotReminder({ preselectedInfo, userDetails, dispatch }) {
  const history = useHistory();
  const [isOpened, setIsOpened] = useState(true);
  const [hideDialog, setHideDialog] = useState(false);

  useIdleTimer({
    timeout: 1000 * 60,
    startOnMount: true,
    events: ["mousedown", "keypress"],
    onIdle: () => {
      setHideDialog(false);
      setIsOpened(true);
    },
  });

  const printSpeciality = (info) => {
    switch (info?.timeslot?.provider_speciality) {
      case "primary_care":
        return "primary care";
      case "pediatric":
        return "pediatrics";
      default:
        break;
    }
  };

  return (
    <div className="timeslot-reminder-container">
      <div
        onClick={() => {
          setHideDialog(false);
          setIsOpened(true);
        }}
        className={`fixed z-50 mr-10 right-0 bottom-40 md:bottom-10 w-24 h-24 flex items-center justify-center rounded-full bg-orange cursor-pointer shadow-d-2xl ${
          isOpened ? "hide-timeslot-icon" : "show-timeslot-icon"
        }`}
      >
        <img
          id="hourglass"
          className="w-1/3 filter brightness-0 invert"
          src={Images.hourGlassIcon}
          alt="info"
        />
      </div>

      <div
        style={{ zIndex: 11 }}
        className={`${
          hideDialog ? "hidden" : ""
        } timeslot-dialog mr-10 fixed right-0 left 0 bottom-40 bg-white overflow-y-auto rounded-lg shadow-d-2xl ${
          isOpened ? "show-timeslot-dialog" : "hide-timeslot-dialog"
        }`}
      >
        <div className="flex items-center justify-between py-5 bg-darkOrangeGradient px-7">
          <div className="flex items-center">
            <img
              className="w-14 h-14"
              src={Images.medicalAssistant}
              alt="medical_assistant"
            />
            <p className="ml-4 text-2xl font-bold text-white">SkyMD Team</p>
          </div>
          <img
            onClick={() => {
              setIsOpened(false);
            }}
            src={Images.crossThinWhiteIcon}
            className="w-5 cursor-pointer opacity-90 right-4 top-4"
            alt="close"
          />
        </div>
        <div className="flex flex-col px-6 rounded py-7">
          {preselectedInfo
            ?.filter((info) => {
              if (
                (info?.timeslot?.provider_speciality === "primary_care" &&
                  userDetails?.incomplete_primary_care_id) ||
                (info?.timeslot?.provider_speciality === "pediatric" &&
                  userDetails?.incomplete_pediatric_id)
              ) {
                return false;
              }
              return true;
            })
            ?.map((info, index) =>
              info?.status === "unbooked" ? (
                <div
                  key={index}
                  className={`${index === 0 ? "" : "mt-8"} w-full`}
                >
                  <p className="text-2xl text-indigo">
                    As per your preferences, your{" "}
                    <span className="font-medium">{printSpeciality(info)}</span>{" "}
                    visit with{" "}
                    <span className="font-medium">
                      {info?.timeslot?.provider}
                    </span>{" "}
                    us is ready to begin.
                  </p>
                  <div className="flex justify-between mt-4">
                    <button
                      className="p-4 text-2xl font-bold text-white rounded-md bg-orange"
                      onClick={() => {
                        setHideDialog(false);
                        setIsOpened(false);
                      }}
                    >
                      Later
                    </button>
                    <button
                      className="p-4 text-2xl font-bold text-white rounded-md bg-orange"
                      onClick={() => {
                        setHideDialog(false);
                        setIsOpened(false);
                        switch (info?.timeslot?.provider_speciality) {
                          case "primary_care":
                            if (
                              userDetails?.is_primary_care_available &&
                              !userDetails?.incomplete_primary_care_id
                            ) {
                              dispatch(
                                currentSpeciality({
                                  currentSpeciality: speciality.primary_care,
                                })
                              );
                              history.push({
                                pathname:
                                  "/visit/primary-care/complete-visit-details",
                                state: {
                                  timeslotId: preselectedInfo[0]?.timeslot?.id,
                                },
                              });
                              trackEvent("start_primary_care_visit");
                            }
                            break;

                          case "pediatric":
                            if (
                              userDetails?.is_pediatric_available &&
                              !userDetails?.incomplete_pediatric_id
                            ) {
                              dispatch(
                                currentSpeciality({
                                  currentSpeciality: speciality.ped,
                                })
                              );
                              history.push({
                                pathname:
                                  "/visit/pediatrics/complete-visit-details",
                                state: {
                                  timeslotId: preselectedInfo[0]?.timeslot?.id,
                                },
                              });
                              trackEvent("start_pediatric_visit");
                            }
                            break;
                          default:
                            break;
                        }
                      }}
                    >
                      Begin
                    </button>
                  </div>
                </div>
              ) : (
                <div />
              )
            )}
        </div>
      </div>
    </div>
  );
}

export default TimeslotReminder;
