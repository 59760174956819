import React from "react";
import CSSLoader from "components/cssLoader/CSSLoader";

function BottomFixedStepButtons({
  onBackClick,
  onNextClick,
  backLoading,
  nextLoading,
  backDisabled,
  nextDisabled,
  currentScreen,
  hideBackButton,
  ...rest
}) {
  return (
    <div className="fixed bottom-0 w-full bg-white">
      <div className="bg-white">
        <div className="flex items-center justify-between p-6 wrapper-lg">
          {hideBackButton ? null : (
            <button
              type="button"
              className={`w-2/5 md:w-full max-w-none md:max-w-xs h-24 border border-orange hover:bg-orange hover:text-white transition-colors text-orange rounded-xl flex items-center justify-center ${
                backLoading
                  ? "opacity-50 cursor-not-allowed"
                  : "opacity-100 cursor-pointer"
              }`}
              onClick={backLoading || backDisabled ? null : onBackClick}
              disabled={backLoading ? true : backDisabled}
              {...rest}
            >
              <p className="text-3xl font-bold">Back</p>
            </button>
          )}
          <button
            type="button"
            className={`h-24 space-x-2.5 bg-orange transition-colors text-white  border border-orange  rounded-xl flex items-center justify-center ${
              nextDisabled || nextLoading
                ? "opacity-50 cursor-not-allowed"
                : "opacity-100 cursor-pointer"
            } ${
              hideBackButton
                ? "w-full wrapper"
                : "w-2/5 md:w-full max-w-none md:max-w-xs"
            }`}
            onClick={nextLoading || nextDisabled ? null : onNextClick}
            disabled={nextLoading ? true : nextDisabled}
            {...rest}
          >
            <p className="text-3xl font-bold ">{"Continue"}</p>
            {nextLoading ? <CSSLoader className="w-8 h-8" /> : <div />}
          </button>
        </div>
      </div>
    </div>
  );
}

export default BottomFixedStepButtons;
