import React, { useEffect } from "react";
import LoaderComponent from "components/loader/LoaderComponent";
import useIncompleteData from "hooks/visit/useIncompleteData";
import { useHistory, useRouteMatch } from "react-router";
import { cosmetic_steps, speciality, step_status } from "helpers/enum";
import usePopulateCosmeticInitialData from "hooks/consultation/usePopulateCosmeticInitialData";
import { useCurrentUser } from "hooks/useCurrentUser";

function CheckCosmeticFlow() {
  const history = useHistory();
  let { url } = useRouteMatch();

  const { userDetails } = useCurrentUser();
  const cosmetic_step_values = Object.values(cosmetic_steps);
  const basic_details_available =
    userDetails?.city?.length &&
    userDetails?.gender?.length &&
    userDetails?.dob?.length;

  const { fetchIncompleteData, incompleteData } = useIncompleteData();
  usePopulateCosmeticInitialData({ incompleteData: incompleteData });

  const incomplete_data_available =
    incompleteData && Object.keys(incompleteData)?.length;

  useEffect(() => {
    fetchIncompleteData(speciality.cosmetic);
  }, [fetchIncompleteData]);

  useEffect(() => {
    if (userDetails && incompleteData) {
      if (basic_details_available && incomplete_data_available) {
        if (incompleteData?.payment_method === "cash") {
          if (
            cosmetic_step_values?.every(
              (el) =>
                incompleteData?.cosmetic_steps?.[el] === step_status.completed
            )
          ) {
            if (incompleteData?.accepted_tnc) {
              history.replace(`${url}/cosmetic/checkout`);
            } else {
              history.replace(`${url}/cosmetic/terms_and_conditions`);
            }
          } else {
            history.replace(`${url}/cosmetic`);
          }
        }
      } else if (basic_details_available && !incomplete_data_available) {
        history.replace(`${url}/cosmetic/pricing`);
      } else {
        history.replace(`${url}/cosmetic/basic-details`);
      }
    }
  }, [
    url,
    history,
    userDetails,
    incompleteData,
    basic_details_available,
    cosmetic_step_values,
    incomplete_data_available,
  ]);

  return (
    <LoaderComponent showLoader={{ state: true, message: "Please wait" }} />
  );
}

export default CheckCosmeticFlow;
