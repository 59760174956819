import classNames from "classnames";
import "./tabs.css";

export default function ProgressStepperCard({ progress, showSteps = true }) {
  const progressPercentage = progress.percent;

  const stepWidth = `${100 / progress.details.length}%`;

  return (
    <div>
      <div className="flex items-center justify-between pr-4 mb-6">
        <h3 className="text-2xl font-bold">Progress</h3>
        <p className="text-xl font-bold text-orange">{progressPercentage}%</p>
      </div>

      <div className={classNames(showSteps ? "h-12" : "h-6")}>
        <div className="top-0 left-0 flex justify-between w-full h-full scroll ">
          {progress.details.map((step, index) => (
            <div
              key={index}
              className={classNames(
                "w-2 h-2 bg-gray-200 min-w-200",
                step.status && "bg-orange",
                index !== 0 && "ml-0.5"
              )}
              style={{ width: stepWidth }}
            >
              {showSteps && (
                <p
                  className={classNames(
                    "text-xl font-medium text-left mt-4",
                    step.status ? "text-black" : "text-grey-300"
                  )}
                >
                  {step.step_name}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
