import React, { useEffect, useState } from "react";
import VisitSummaryAccordion from "components/visitSummaryAccordion/VisitSummaryAccordion";
import VisitSummaryHeader from "components/visitSummaryAccordion/VisitSummaryHeader";
import BasicDetailsForPCSummary from "./BasicDetailsForPCSummary";
import VisitDetailsForPCSummary from "./VisitDetailsForPCSummary";
import SlotSummary from "./SlotSummary";
import AdditionalInfoPCSummary from "./AdditionalInfoPCSummary";
import DOMPurify from "dompurify";
import Images from "assets/images";

function PrimaryCareVisitSummary({ readOnly, data }) {
  const [terms, setTerms] = useState([]);

  useEffect(() => {
    setTerms(data?.code_of_conduct);
  }, [data]);

  const accordionData = [
    readOnly
      ? null
      : {
          header: (props) => (
            <VisitSummaryHeader {...props} title={"Visit Details"} />
          ),
          content: <VisitDetailsForPCSummary readOnly={readOnly} data={data} />,
        },
    {
      header: (props) => (
        <VisitSummaryHeader {...props} title={"Appointment Details"} />
      ),
      content: <SlotSummary readOnly={readOnly} data={data} />,
    },
    {
      header: (props) => (
        <VisitSummaryHeader {...props} title={"Basic Details"} />
      ),
      content: <BasicDetailsForPCSummary readOnly={readOnly} data={data} />,
    },
    {
      header: (props) => (
        <VisitSummaryHeader {...props} title={"Additional Info"} />
      ),
      content: <AdditionalInfoPCSummary readOnly={readOnly} data={data} />,
    },
    {
      header: (props) => (
        <VisitSummaryHeader {...props} title="SkyMD's Code Of Conduct" />
      ),
      content: (
        <div>
          {terms?.map((term, index) => {
            return (
              <div className="py-10" key={index}>
                <div className="flex">
                  <img
                    className="w-12 h-12"
                    src={Images.checkedOrange}
                    alt="checked"
                  />
                  <p
                    className="pl-5 text-2xl leading-8 font-light pt-1"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(term),
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ),
    },
  ];
  return (
    <div className="bg-alternativeWhite">
      <div className="px-12">
        {readOnly ? null : (
          <h2 className="uppercase font-black text-xl pt-8">
            Here is your Visit Summary
          </h2>
        )}
        <VisitSummaryAccordion accordionData={accordionData} />
      </div>
    </div>
  );
}

export default PrimaryCareVisitSummary;
