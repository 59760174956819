import React from "react";
import { getDermatologyStepScreen } from "../dermatology/getDermatologyStepScreen";
import VisitStepContainer from "components/visitStepContainer/VisitStepContainer";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import useDataFromUserDetails from "hooks/useDataFromUserDetails";

function DermaSubStepScreen() {
  const dispatch = useDispatch();
  const { currentScreen, subSteps, incompleteData } = useDermatologyVisitFlow();
  const { dermaDoctorDetails } = useDataFromUserDetails();
  return (
    <VisitStepContainer
      speciality="dermatology_visit"
      incompleteData={incompleteData}
      doctorDetails={dermaDoctorDetails}
      currentScreen={currentScreen}
      subSteps={subSteps}
      infoPopup={true}
      onClickBack={(backAnimation) => {
        backAnimation();
        setTimeout(() => {
          dispatch(
            dermaVisitFlowReducers.currentScreen({
              value: subSteps?.[currentScreen]?.prevScreen,
            })
          );
        }, 300);
      }}
    >
      {getDermatologyStepScreen(currentScreen)}
    </VisitStepContainer>
  );
}

export default DermaSubStepScreen;
