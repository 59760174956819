import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { updateCase } from "api/actions/VisitActions";
import { cosmetic_steps, speciality, step_status } from "helpers/enum";
import { setCosmeticStepStatus } from "helpers/setCosmeticStepStatus";
import { useApiMutation } from "hooks/useApiMutation";
import { useCosmeticConsultationFlow } from "hooks/useConsultationFlow";
import useToastify from "hooks/useToastify";
import useVisitStepChange from "hooks/useVisitStepChange";
import { useDispatch } from "react-redux";
import useIncompleteData from "hooks/visit/useIncompleteData";
import { cosmeticConsultationReducers } from "reducers/cosmetic/cosmeticConsultationReducer";

function useUpdateCosmeticConsultation() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showAlert } = useToastify();
  const { setLoading } = useVisitStepChange();
  const {
    currentScreen,
    steps,
    incompleteData,
    completedProgress,
  } = useCosmeticConsultationFlow();
  const {
    mutate: callUpdateCase,
    loading: updatingConsultation,
  } = useApiMutation(updateCase);
  const { fetchIncompleteData, fetchingIncompleteData } = useIncompleteData();

  useEffect(() => {
    if (updatingConsultation || fetchingIncompleteData) {
      setLoading(true);
    } else if (!fetchingIncompleteData && !updatingConsultation) {
      setLoading(false);
    }
  }, [updatingConsultation, setLoading, fetchingIncompleteData]);

  const constant_keys = {
    allergies: steps?.[cosmetic_steps.allergies]?.value?.map((el) => {
      return { name: el };
    }),
  };

  function addProgress(payload, screen_to_complete) {
    let result_payload;
    if (
      screen_to_complete &&
      steps[screen_to_complete]?.progress &&
      completedProgress <= steps[screen_to_complete]?.progress
    ) {
      result_payload = {
        ...payload,
        case: {
          ...constant_keys,
          ...payload.case,
          meta: {
            ...incompleteData.meta,
            ...payload.case.meta,
            progress_percentage: steps[screen_to_complete]?.progress,
          },
        },
      };
    } else {
      result_payload = {
        ...payload,
        case: {
          ...constant_keys,
          ...payload.case,
          meta: {
            ...incompleteData.meta,
            ...payload.case.meta,
          },
        },
      };
    }
    if (screen_to_complete) {
      return setCosmeticStepStatus({
        result_payload: result_payload,
        screen_to_complete,
        current_screen_state: steps?.[currentScreen],
      });
    } else {
      return result_payload;
    }
  }

  async function updateConsultation({
    payload,
    change_current_screen,
    screen_to_complete,
    callBack,
  }) {
    try {
      const result = await callUpdateCase({
        caseId: incompleteData?.id,
        payload: addProgress(payload, screen_to_complete),
      });
      if (result && result.error === false) {
        const incompleteDataResponse = await fetchIncompleteData(
          speciality.cosmetic
        );
        if (incompleteDataResponse) {
          if (screen_to_complete) {
            dispatch(
              cosmeticConsultationReducers?.[screen_to_complete]({
                status: step_status.completed,
              })
            );
            if (steps?.[screen_to_complete]?.progress) {
              dispatch(
                cosmeticConsultationReducers.completedProgress({
                  value: steps?.[screen_to_complete]?.progress,
                })
              );
            }
          }
          callBack(incompleteDataResponse?.payload);
          if (change_current_screen) {
            setTimeout(() => {
              dispatch(
                cosmeticConsultationReducers.currentScreen({
                  value: steps?.[currentScreen]?.nextScreen,
                })
              );
            }, 300);
          }
        }
      } else {
        showAlert(
          result?.payload?.error_message ?? "Something went wrong",
          "error"
        );

        if (
          result.payload.error_message ===
          "Your visit was deleted, please start a new one."
        ) {
          history.replace("/home");
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return { updateConsultation, updatingConsultation };
}

export default useUpdateCosmeticConsultation;
