import React, { useEffect, useRef } from "react";

import MultiLineInput from "components/inputs/MultiLineInput";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import useVisitStepChange from "hooks/useVisitStepChange";
import {
  dermatology_main_steps,
  dermatology_sub_steps,
  step_status,
} from "helpers/enum";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { checkAlreadyCompleted } from "helpers/setDermatologyStepStatus";
import { SymptomStatusSchema } from "helpers/validationSchema";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";

function MoreAboutSymptoms() {
  const formRef = useRef();
  const nextAnimationRef = useRef();

  const dispatch = useDispatch();
  const { incompleteData, subSteps } = useDermatologyVisitFlow();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore =
    subSteps[dermatology_sub_steps.more_about_symptoms].value;

  const { updateVisit } = useUpdateDermatologyVisit();

  useEffect(() => {
    dispatch(
      dermaVisitFlowReducers.more_about_symptoms({
        isValid: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (currentScreenValueFromStore) {
      formRef?.current?.setValues({
        worse: currentScreenValueFromStore?.worse,
        better: currentScreenValueFromStore?.better,
      });
    }
  }, [currentScreenValueFromStore]);

  function onRightButtonClick(_, nextAnimation) {
    nextAnimationRef.current = nextAnimation;
    if (formRef?.current) {
      formRef?.current?.handleSubmit();
    }
  }

  function onFormSubmit(values) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          worse: values?.worse,
          better: values?.better,
          dermatology_steps: {
            main_steps: {
              ...incompleteData?.dermatology_steps?.main_steps,
              [dermatology_main_steps.main_symptoms]: step_status.completed,
              [dermatology_main_steps.main_medical_history]: checkAlreadyCompleted(
                {
                  screen_name: dermatology_main_steps.main_medical_history,
                  dermatology_steps: incompleteData?.dermatology_steps,
                  step_placement: "main_steps",
                }
              ),
            },
            sub_steps: {
              ...incompleteData?.dermatology_steps?.sub_steps,
            },
          },
        },
      },
      change_current_screen: true,
      screen_to_complete: dermatology_sub_steps.more_about_symptoms,
      callBack: () => {
        dispatch(
          dermaVisitFlowReducers.more_about_symptoms({
            value: {
              worse: values?.worse,
              better: values?.better,
            },
          })
        );
        nextAnimationRef.current();
      },
    });
  }

  return (
    <StepScreenContainer align={"start"}>
      <MedicalAssistant
        content={<p>Last two questions about your symptoms.</p>}
      />
      <Card>
        <Formik
          innerRef={formRef}
          initialValues={{
            worse: "",
            better: "",
          }}
          onSubmit={onFormSubmit}
          validationSchema={SymptomStatusSchema}
        >
          {() => (
            <Form>
              <MultiLineInput
                label="What makes it worse?"
                id="worse"
                name="worse"
                placeholder="Start typing.."
              />
              <Spacer height={20} />
              <MultiLineInput
                label="What makes it better?"
                id="better"
                name="better"
                placeholder="Start typing.."
              />
            </Form>
          )}
        </Formik>
      </Card>
    </StepScreenContainer>
  );
}

export default MoreAboutSymptoms;
