import Spacer from "components/Spacer/Spacer";
import moment from "moment";
import SubHeading from "../SubHeading";
import TextWithBulletPoint from "../TextWithBulletPoint";

export const getMedicalHistoryView = (payload) => {
  const values = {
    medical_history_current: payload?.medical_history_current?.length
      ? payload?.medical_history_current
      : null,
    medical_history_past: payload?.medical_history_past?.length
      ? payload?.medical_history_past
      : null,
    medical_history_never: payload?.medical_history_never?.length
      ? payload?.medical_history_never
      : null,
    colonos_copy: payload?.additional_details?.colonos_copy?.length
      ? payload?.additional_details?.colonos_copy
      : null,
    colonos_copy_date: payload?.additional_details?.colonos_copy_date?.length
      ? payload?.additional_details?.colonos_copy_date
      : null,
    advanced_directive: payload?.additional_details?.advanced_directive?.length
      ? payload?.additional_details?.advanced_directive
      : null,
  };

  const getView = ({ heading, history }) => {
    return (
      <div className="mb-12">
        <SubHeading text={heading} className="mb-4" />
        {history?.map((el, idx) => {
          return (
            <div className="mb-4" key={idx}>
              <TextWithBulletPoint text={el?.category} />
              {el?.diseases?.map((elem, index) => {
                return (
                  <p className="pl-2 pt-2 font-light text-2xl" key={index}>
                    - {elem}
                  </p>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  if (Object.keys(values)?.some((el) => values?.[el] !== null)) {
    return (
      <div className="p-8 bg-alternativeWhite border border-borderColor mt-8 rounded-xl">
        {values?.medical_history_past
          ? getView({
              heading: "PAST",
              history: values?.medical_history_past,
            })
          : null}

        {values?.medical_history_current
          ? getView({
              heading: "CURRENT",
              history: values?.medical_history_current,
            })
          : null}

        {values?.medical_history_never
          ? getView({
              heading: "NEVER",
              history: values?.medical_history_never,
            })
          : null}

        <Spacer height={20} />

        {values?.colonos_copy ? (
          <TextWithBulletPoint
            className="mb-4"
            text={`Colonoscopy : ${values?.colonos_copy}`}
          />
        ) : null}
        {values?.colonos_copy_date ? (
          <TextWithBulletPoint
            className="mb-4"
            text={`Colonoscopy date : ${moment(
              values?.colonos_copy_date,
              "YYYY-MM-DD"
            ).format("DD MMM, YYYY")}`}
          />
        ) : null}
        {values?.advanced_directive ? (
          <TextWithBulletPoint
            className="mb-4"
            text={`Advanced directive : ${values?.advanced_directive}`}
          />
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};
