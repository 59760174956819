import { browserName, osName } from "react-device-detect";

let CURRENT_SOURCE;

if (
  typeof osName === "string" &&
  typeof browserName === "string" &&
  osName?.length &&
  browserName?.length
) {
  CURRENT_SOURCE = `${osName?.split(" ").join("_")}-${browserName
    ?.split(" ")
    .join("_")}-web`;
} else if (typeof osName === "string" && osName?.length) {
  CURRENT_SOURCE = `${osName?.split(" ").join("_")}-UNKNOWN-web`;
} else if (typeof browserName === "string" && browserName?.length) {
  CURRENT_SOURCE = `UNKNOWN-${browserName?.split(" ").join("_")}-web`;
}

export { CURRENT_SOURCE };
