import React from "react";
import Images from "assets/images";

function LoaderComponent({ showLoader }) {
  if (showLoader?.state) {
    return (
      <div
        className={`overflow-hidden absolute top-0 left-0 h-full w-full bg-veryLightBlue ${
          showLoader?.type === "transparent" ? "bg-opacity-60" : ""
        }`}
      >
        <div className="flex flex-col items-center justify-center w-full h-full p-10 bg-white">
          <h2 className="text-4xl font-medium text-center">
            {showLoader?.message ?? "Please wait"}
          </h2>
          <img className="w-40" src={Images.appLoader} alt={"Loading"} />
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default LoaderComponent;
