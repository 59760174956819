import React from "react";
import useFullPageLoader from "hooks/useFullPageLoader";
import LoaderComponent from "./LoaderComponent";

function FullPageLoader() {
  const { showLoader } = useFullPageLoader();

  return <LoaderComponent showLoader={showLoader} />;
}

export default FullPageLoader;
