import React, { createContext, useState, useMemo, useEffect } from "react";

export const ModalContext = createContext({});

function ModalProvider({ children }) {
  const [showModal, setShowModal] = useState({
    state: false,
    children: null,
    data: null,
  });

  useEffect(() => {
    if (showModal?.state) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showModal?.state]);

  const value = useMemo(
    () => ({
      showModal,
      setShowModal,
    }),
    [showModal, setShowModal]
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}

export default ModalProvider;
