const BASE_API_URL = `https://www.${process.env.REACT_APP_BASE_URL}/`;

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: "skymd-1be9e",
  appId: "1:995864575474:web:403ea9e4ad98d579ee7f6b",
  measurementId: "G-4JCHYBVQC9",
};

const ApiConfig = {
  baseUrl: BASE_API_URL,
  urls: {
    auth: {
      login: "api/session.json",
      signup: "api/accounts/one-step-signup.json",
      generateOTP: "auth/otp/generate",
      regenerateOTP: "auth/otp/regenerate",
      verifyOTP: "auth/otp/verify",
      verifyCaptcha: "auth/captcha/verify",
      googleAuth: "auth/google-auth/verify",
      checkEmail: (email) => `api/accounts/check-email.json?email=${email}`,
      validatePhone: (phone_number) =>
        `api/accounts/verify-phone-area.json?phone_number=${phone_number}`,
      forgotPassword: "api/accounts/password/reset.json",
      resetPassword: (token) =>
        `api/accounts/update-password.json?reset_password_token=${token}`,
      changePassword: "api/accounts/password/change.json",
      partialSignup: "api/incomplete-signups",
      directLogin: "api/session/direct-login.json",
    },
    user: {
      userDetails: "api/v3/accounts/current-user.json",
      healthProfile: (patientId) =>
        `api/patients/${patientId}/health-profile.json`,
      addInsurance: "api/accounts/",
      recommendedProvider: (speciality) =>
        `api/v3/accounts/recommend-provider.json?speciality=${speciality}`,
      availableProvider: (speciality) =>
        `api/v3/accounts/available-providers.json?speciality=${speciality}`,
      availableUpdateProviders: "api/available_providers.json",
      switchProvider: "api",
      getInsuranceProviders: "api/v3/state/",
      selectProvider: "api/v3/accounts/",
      profile: "api/v3/accounts/profile.json",
      updateProfile: "api/accounts/edit-profile.json",
      updateBasicInfo: "api/accounts/",
      getPharmacyList: "api/search/pharmacies",
      updatePharmacy: "api/accounts/pharmacy.json",
      addChild: "api/accounts/",
      sendConversation: "api/cases/",
      refreshConversation: (id) => `api/cases/${id}/conversations`,
      getPatients: "api/accounts/",
      serachMedicalCondition: "api/search/",
      sendMedicalCondition: "api/accounts/",
      version: "api/version",
      updateFirebaseToken: "api/accounts/firebase-token.json",
      logoutSession: "api/session.json",
      sendMail: "api/accounts/contact-support.json",
      subscribeToPCP: "api/accounts/subscribe-to-pcp",
      getGeoData: (zipcode) => `api/search/geo-data?zipcode=${zipcode}`,
      getProvidersFromPracticeId: (pid) => `practice/practice.json?id=${pid}`,
      postReview: "api/accounts/app-review",
      viewProviderProfile: (case_id, pid) =>
        `api/v3/case/${case_id}/provider/${pid}/profile.json`,
      viewProviderDetails: (pid) => `api/provider/${pid}`,
      checkInsuranceNetwork: (speciality, patientId) =>
        `api/v3/insurances/check-network.json?speciality=${speciality}&patient_id=${patientId}`,
    },
    visit: {
      myVisists: "api/cases",
      myVisits: "api/v3/cases",
      createVisit: "api/v3/cases/add.json",
      updateVisit: "api/v3/cases",
      createIdentity: "api/accounts/photo-id.json",
      checkInsurance: "api/accounts",
      coupon: "api/coupon/",
      linkInsurance: "api/accounts",
      unservicedStateNotify: (speciality) =>
        `api/accounts/unserviced-state-notify?speciality=${speciality}`,
      sendTextSmsForUploadPhoto: "api/text-complete-visit-on-app.json",
      cashPrice: "api/v3/accounts",
      getBookingSlots: (caseId) => `api/case/${caseId}/bookings.json`,
      getBookingSlotsWithProviders: (caseId) =>
        `api/v3/case/${caseId}/bookings-by-date.json`,
      bookingASlot: (caseId) => `api/case/${caseId}/bookings.json`,
      getPCSymptoms: (gender) =>
        `api/search/primary-care-symptoms.json?gender=${gender}`,
      discardVisit: (caseId) => `api/cases/${caseId}/discard`,
      visitConcerns: (speciality) =>
        `api/v3/search/visit_concerns?speciality=${speciality}`,
      uploadAudioRecord: (caseId) => `api/cases/${caseId}/audio-s3-url`,
      visitSurvey: (token) =>
        `survey/visit-evaluation/record-diagnosis-review-from-app/${token}`,
      refillRequest: (caseId) => `api/cases/${caseId}/refill`,
      patientVisittedVisitDetailsScreen: (caseId) =>
        `api/cases/${caseId}/patient_frenzy.json`,
      popupInfo: (currentScreen) => `api/screen-info?title=${currentScreen}`,
      getCosmeticConcerns: (caseId) =>
        `api/practices/${caseId}/cosmetic-conditions`,
      revisedInsurance: (caseId) =>
        `api/cases/${caseId}/revised-insurance.json`,
      acceptRevisedInsurance: (token) =>
        `visit_prices/confirm_revised_price.json?token=${token}`,
      changeShippingAddress: (account_id, patient_id) =>
        `api/accounts/${account_id}/patients/${patient_id}/update-shipping-address.json`,
      placeOtcOrder: "api/v1/carts/visit_order.json",
      retryOtcOrderPayment: "api/v1/carts/retry_payment.json",
      trackSummaryViews: (visitId) => `api/cases/${visitId}/viewed.json`,
      getTerms: (speciality) =>
        `api/v1/code_of_conducts.json?speciality=${speciality}`,
      getVisitSymptoms: (speciality, gender) =>
        `api/search/symptoms.json?speciality=${speciality}&gender=${gender}`,
      patientListDescriptions: (list_type) =>
        `api/v1/patient_list_descriptions.json?list_type=${list_type}`,
      incompleteVisits: "api/accounts/incomplete-visits.json",
      submittedVisits: "api/accounts/submitted-visits.json",
      preselectedInfo: "api/preselected_infos.json",
    },
    payment: {
      getBtToken: "api/accounts/bt-token.json",
      getBtInfo: "api/accounts/bt-info.json",
      createBrainTeeCustomer: "api/accounts/bt-customer.json",
      getBtTokenAlt: (tID) => `direct_payments/get_bt_token/${tID}.json`,
      getBtInfoAlt: (tID) => `direct_payments/get_bt_info/${tID}.json`,
      getTransactionInfo: (tID) =>
        `direct_payments/get_transaction/${tID}.json`,
      createBillingAddress: (tID) =>
        `direct_payments/create_billing_address/${tID}.json`,
      chargePatient: (tID) => `direct_payments/charge_the_patient/${tID}.json`,
    },
    imageUpload: {
      getPresignedPhotoUrl: "api/accounts/presigned-photo-url",
      getCaseImagesPresignedUrl: (caseId, imageType, extension) =>
        `api/cases/${caseId}/presigned-url.json?image_type=${imageType}&file_extension=${extension}`,
      uploadPhotoId: "api/accounts/s3-photo-id-url",
      imageUploadErrorReport: (patientId) => `api/report-error/${patientId}`,
      successImageTrack: (id) => `api/v3/cases/${id}/image_upload_status.json`,
    },
  },
};

export default ApiConfig;
