import {
  dermatology_main_steps,
  dermatology_sub_steps,
  step_status,
} from "helpers/enum";
import { setNextScreenToUpcoming } from "helpers/setDermatologyStepStatus";

export const dermaSymptomsReducer = {
  symptoms(state, action) {
    const { status, value, isValid } = action.payload;
    if (status) {
      state.subSteps[dermatology_sub_steps.symptoms].status = status;
      if (status === step_status.completed) {
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: dermatology_sub_steps.more_about_symptoms,
        });
      }
    }
    if (value) {
      state.subSteps[dermatology_sub_steps.symptoms].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[dermatology_sub_steps.symptoms].isValid = isValid;
    }
  },
  more_about_symptoms(state, action) {
    const { status, value, isValid } = action.payload;
    if (status) {
      state.subSteps[dermatology_sub_steps.more_about_symptoms].status = status;
      if (status === step_status.completed) {
        state.mainSteps[dermatology_main_steps.main_symptoms].status =
          step_status.completed;
        if (
          state.mainSteps[dermatology_main_steps.main_medical_history]
            .status === step_status.upcoming
        ) {
          state.mainSteps[dermatology_main_steps.main_medical_history].status =
            step_status.current;
        }
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: dermatology_sub_steps.medical_history,
        });
      }
    }
    if (value) {
      state.subSteps[dermatology_sub_steps.more_about_symptoms].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[
        dermatology_sub_steps.more_about_symptoms
      ].isValid = isValid;
    }
  },
};
