import classNames from "classnames";
import "./tabs.css";
import { useEffect } from "react";

// The purpose of this component is to show incomplete visit progress in My Visits
export default function IncompleteProgressStepper({
  showSteps = true,
  steps = [],
  id,
}) {
  useEffect(() => {
    const currentStepElement = document
      .getElementById(id)
      ?.querySelector(".current");
    if (currentStepElement) {
      const container = document.getElementById(id);
      const scrollLeft =
        currentStepElement.offsetLeft -
        container.clientWidth / 2 +
        currentStepElement.clientWidth / 2;
      container.scrollLeft = scrollLeft;
    }
  }, [steps, id]);

  let currentStep = -1;
  let totalSubsteps = 0;
  let completedSubsteps = 0;

  steps.forEach((step, index) => {
    if (step.status === "completed") {
      if (step.sub_steps) {
        totalSubsteps += step.sub_steps.length;
        completedSubsteps += step.sub_steps.length;
      }
    } else if (step.status === "current") {
      if (step.sub_steps) {
        totalSubsteps += step.sub_steps.length;
        completedSubsteps += step.sub_steps.filter(
          (substep) =>
            substep.status === "completed" || substep.status === "current"
        ).length;
      }
      currentStep = index;
    } else if (step.sub_steps) {
      totalSubsteps += step.sub_steps.length;
    }
  });

  const progressPercentage = Math.round(
    (completedSubsteps / totalSubsteps) * 100
  );

  const stepWidth = `${100 / steps.length}%`;

  return (
    <div>
      <div className="flex items-center justify-between pr-4 mb-6">
        <h3 className="text-2xl font-bold">Progress</h3>
        <p className="text-xl font-bold text-orange">{progressPercentage}%</p>
      </div>

      <div className={classNames("", showSteps ? "h-12" : "h-6")}>
        <div
          className="top-0 left-0 flex justify-between w-full h-full pr-4 scroll"
          id={id}
        >
          {steps.map((step, index) => {
            const stepStatus =
              index < currentStep
                ? "completed"
                : index === currentStep
                ? "current"
                : "incomplete";

            return (
              <div
                key={index}
                className={classNames(
                  "w-2 h-2 min-w-200",
                  stepStatus === "completed" && "bg-orange",
                  stepStatus === "current" && "bg-orange current", // Here you can change the color to define the current progress
                  index !== 0 && "ml-0.5",
                  "bg-gray-200"
                )}
                style={{ width: stepWidth }}
              >
                {showSteps && (
                  <p
                    className={classNames(
                      "text-xl font-medium text-left mt-4",
                      stepStatus === "completed" || stepStatus === "current"
                        ? "text-black"
                        : "text-grey-300"
                    )}
                  >
                    {step.step_name}
                  </p>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
