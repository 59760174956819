import Images from "assets/images";
import React from "react";

const Chip = ({ text, className, onClear }) => (
  <div
    className={`flex items-center justify-between border border-orange rounded-1.4 p-4 mr-8 min-w-max ${className}`}
  >
    <p className="text-xl font-bold pr-6">{text}</p>
    <img
      onClick={() => onClear(text)}
      alt="clear"
      src={Images.closeIconFilled}
      className="w-8 h-8 cursor-pointer flex"
    />
  </div>
);

export default Chip;
