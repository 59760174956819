import Images from "assets/images";
function Banner({ incompleteCase }) {
  return (
    <div className="relative">
      <img
        className="w-full h-auto lg:hidden"
        src={Images.WaitingRoomBannerMobile}
        alt="placeholder_image"
      />
      <img
        className="hidden w-full h-auto lg:block"
        src={Images.WaitingRoomBanner}
        alt="placeholder_image"
      />
      <div className="absolute top-0 flex flex-col items-start justify-start w-full h-full pt-8 space-y-2 md:space-y-10 max-w-7xl left-3 md:left-6 xl:pt-12">
        <h2 className="text-3xl font-bold text-white md:text-5xl">
          Welcome to SkyMD
        </h2>
        {incompleteCase ? (
          <p className="text-xl font-medium text-white max-w-1/3 lg:max-w-3/4 md:text-3xl">
            Kindly complete the registration process now so you don't miss out
            on the care you need.
          </p>
        ) : (
          <p className="text-xl font-medium text-white md:text-3xl max-w-1/3 lg:max-w-3/4">
            Your trusted health assistant to get quality healthcare at your
            fingertips,<span className="font-bold"> through all 365 days</span>.
            Book & track your doctor appointments, whenever & wherever you need.
          </p>
        )}
      </div>
    </div>
  );
}
export default Banner;
