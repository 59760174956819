import {
  ped_main_steps,
  ped_sub_steps,
  step_status,
} from "helpers/enum";
import { setNextScreenToUpcoming } from "helpers/setPedStepStatus";

export const pedAdditionalDetailsReducer = {
  select_pharmacy(state, action) {
    const { value, isValid, status } = action.payload;
    if (value) {
      state.subSteps[ped_sub_steps.select_pharmacy].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[ped_sub_steps.select_pharmacy].isValid = isValid;
    }
    if (status) {
      state.subSteps[ped_sub_steps.select_pharmacy].status = status;
      if (status === step_status.completed) {
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: ped_sub_steps.symptoms,
        });
      }
    }
  },
  symptoms(state, action) {
    const { status, value, isValid } = action.payload;
    if (status) {
      state.subSteps[ped_sub_steps.symptoms].status = status;
      if (status === step_status.completed) {
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: ped_sub_steps.additional_info,
        });
      }
    }
    if (value) {
      state.subSteps[ped_sub_steps.symptoms].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[ped_sub_steps.symptoms].isValid = isValid;
    }
  },
  additional_info(state, action) {
    const { status, value, isValid, recordUrl } = action.payload;
    if (status) {
      state.subSteps[ped_sub_steps.additional_info].status = status;
      if (status === step_status.completed) {
        state.mainSteps[ped_main_steps.additional_details].status =
          step_status.completed;
      }
    }
    if (value) {
      state.subSteps[ped_sub_steps.additional_info].value = value;
    }
    if (recordUrl !== undefined) {
      state.subSteps[
        ped_sub_steps.additional_info
      ].recordUrl = recordUrl;
    }
    if (isValid !== undefined) {
      state.subSteps[ped_sub_steps.additional_info].isValid = isValid;
    }
  },
};
