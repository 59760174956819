import React, { useEffect, useState } from "react";
import Images from "assets/images";
import Chip from "components/chip/Chip";
import Card from "components/visitCommon/Card";
import useDebounce from "hooks/useDebounce";
import { useApiQuery } from "hooks/useApiQuery";
import { searchMedicalConditionAction } from "api/actions/UserActions";
import CSSLoader from "components/cssLoader/CSSLoader";
import useToastify from "hooks/useToastify";

function SearchInputAndItemList({
  inputLabel,
  selected,
  setSelected,
  keyToSearch,
}) {
  const [searchText, setSearchText] = useState("");
  const debouncedSearchTerm = useDebounce(searchText, 500);

  const [dataFromApi, setDataFromApi] = useState([]);

  const { showAlert } = useToastify();

  const {
    loading,
    payload: result,
    query: callSearch,
    error: searchResultError,
  } = useApiQuery(
    searchMedicalConditionAction({
      condition: keyToSearch,
      searchText: debouncedSearchTerm,
      type: null,
    }),
    false
  );

  useEffect(() => {
    if (!searchText?.length) {
      setDataFromApi([]);
    }
  }, [searchText]);

  useEffect(() => {
    if (debouncedSearchTerm?.trim()?.length && keyToSearch) {
      callSearch();
    }
  }, [debouncedSearchTerm, callSearch, keyToSearch]);

  useEffect(() => {
    if (searchResultError) {
      showAlert("Something went wrong", "error");
      setDataFromApi([]);
    } else {
      if (result && result?.length) {
        setDataFromApi(result?.map((el) => el?.name));
      } else {
        setDataFromApi([]);
      }
    }
  }, [result, searchResultError, showAlert]);

  useEffect(() => {
    if (selected?.length) {
      setSelected(selected);
    }
  }, [selected, setSelected]);

  return (
    <Card>
      <div className="flex items-center justify-between border border-borderColor rounded-1.6 cursor-text overflow-hidden">
        <input
          className="h-full p-6 text-2xl font-bold placeholder-opacity-40 border-none outline-none flex-1"
          placeholder={inputLabel}
          onKeyPress={(e) => {
            if (e?.key === "Enter") {
              if (searchText?.trim()?.length) {
                setSelected((prev) => [...prev, searchText]);
              }
            }
          }}
          value={searchText}
          onChange={(event) => setSearchText(event?.target?.value)}
        />
        {!loading ? (
          <img
            className="w-8 h-8 mx-8 object-contain"
            src={Images.searchIcon}
            alt={"search"}
          />
        ) : (
          <CSSLoader className={"w-8 h-8"} color="orange" />
        )}
      </div>
      {selected?.length ? (
        <div className="mt-5 pt-8 px-12 border-t border-borderColor -mr-10 -ml-10">
          <div className="flex flex-wrap">
            {selected?.map((el, index) => (
              <Chip
                key={index}
                text={el}
                className="mb-8"
                onClear={() => {
                  setSelected((prev) => prev?.filter((elem) => elem !== el));
                }}
              />
            ))}
          </div>
        </div>
      ) : null}
      {searchText?.length ? (
        <div
          onClick={() => {
            setSelected((prev) => [...prev, searchText]);
            setSearchText("");
          }}
          className="bg-white border border-borderColor rounded-1.6 p-8 px-12 mt-5 cursor-pointer"
        >
          <p className="text-2xl font-bold">{`Add "${searchText}"`}</p>
        </div>
      ) : null}
      {dataFromApi?.length ? (
        <div className="px-0 py-8 bg-alternativeWhite mt-6 rounded-2xl">
          <div className="px-8">
            {dataFromApi?.length
              ? dataFromApi?.map((part, index) => {
                  if (selected?.find((el) => el === part)) {
                    return null;
                  } else {
                    return (
                      <div
                        onClick={() => {
                          setSelected((prev) => [...prev, part]);
                        }}
                        key={index}
                        className="bg-white border border-borderColor rounded-1.6 p-8 px-12 mb-5 cursor-pointer"
                      >
                        <p className="text-2xl font-bold">{part}</p>
                      </div>
                    );
                  }
                })
              : null}
          </div>
        </div>
      ) : null}
    </Card>
  );
}

export default SearchInputAndItemList;
