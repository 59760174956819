//For Reference only. We are not using this and will be deleted later
export const DermaSteps = [
  {
    name: "basic_info",
    label: "Basic Details",
    subStep: [],
  },
  {
    name: "visit_details",
    label: "Visit Details",
  },
  {
    name: "photos",
    label: "Photos",
  },
  {
    name: "main_symptoms",
    label: "Symptoms",
  },
  {
    name: "main_medical_history",
    label: "Medical History",
  },
  {
    name: "checkout",
    label: "Checkout",
  },
];
export const PCSteps = [
  {
    name: "basic_info",
    label: "Basic Details",
    subSteps: [
      {
        name: "select_patient",
        label: "Basic Information",
      },
      {
        name: "select_condition",
        label: "Basic Information",
      },
      {
        name: "select_slot",
        label: "Basic Information",
      },
      {
        name: "confirm_residence",
        label: "Basic Information",
      },
      {
        name: "identity_confirmation",
        label: "Basic Information",
      },
      {
        name: "select_pharmacy",
        label: "Preferred Pharmacy",
      },
    ],
  },

  {
    name: "main_symptoms",
    label: "Symptoms",
    subSteps: [
      {
        name: "symptoms",
        label: "Basic Information",
      },
    ],
  },
  {
    name: "medical_history",
    label: "Medical History",
    subSteps: [
      {
        name: "allergies",
        label: "Basic Information",
      },
      {
        name: "medications",
        label: "Basic Information",
      },
      {
        name: "additional_info",
        label: "Basic Information",
      },
    ],
  },
  {
    name: "checkout",
    label: "Checkout",
    subSteps: [
      {
        name: "payment",
        label: "Payment",
      },
    ],
  },
];

export const GetServiceName = (speciality) => {
  switch (speciality) {
    case "dermatology":
      return "Dermatology Visit";
    case "primary_care":
      return "Primary Care Visit";
    case "pediatric":
      return "Pediatric Care Visit";
    case "cosmetic":
      return "Cosmetic Consultation";

    default:
      return "Visit";
  }
};
