import CSSLoader from "components/cssLoader/CSSLoader";
import React, { useState } from "react";

function PromoCodeInput({
  value,
  onChange,
  percentage,
  type,
  promocode,
  loading,
  disabled,
  onClick,
}) {
  const [focused, setFocused] = useState(false);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <div
        className={`border rounded-xl w-full pl-3 flex ${
          focused
            ? "border-indigo"
            : type === "error"
            ? "border-red bg-red bg-opacity-5"
            : type === "success"
            ? "border-green bg-green bg-opacity-5"
            : "border-grey-600"
        } `}
      >
        {type === "success" || type === "error" ? (
          <div className={`flex flex-1 items-center mr-5`}>
            {type === "success" ? (
              <p className="font-medium text-xl leading-8">
                Promo code{" "}
                <span className="font-black uppercase">{promocode}</span>{" "}
                applied. You’ll get{" "}
                <span className="font-black">{percentage}%</span> Off on the
                Visit
              </p>
            ) : (
              <p className="font-medium text-xl leading-8">
                Sorry, the promo code
                <span className="font-black uppercase"> {promocode}</span> is
                not valid.
              </p>
            )}
          </div>
        ) : (
          <input
            value={value}
            disabled={loading ? true : disabled}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onChange={onChange}
            placeholder="Promo Code"
            className="flex flex-1 px-3 text-2xl font-bold mr-5 focus:outline-none"
          />
        )}
        <button
          type="submit"
          disabled={loading || !value?.trim()?.length ? true : disabled}
          className={`text-orange text-2xl font-bold px-6 h-20 self-center ${
            loading || disabled || !value?.trim()?.length
              ? "opacity-50 cursor-not-allowed"
              : "opacity-100"
          }`}
        >
          {loading ? (
            <CSSLoader color="white" className="w-6 h-6" />
          ) : type === "success" || type === "error" ? (
            "Remove"
          ) : (
            "Apply Coupon"
          )}
        </button>
      </div>
    </form>
  );
}

export default PromoCodeInput;
