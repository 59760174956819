import { useCallback, useEffect } from "react";
import { getPatientsAction } from "api/actions/UserActions";
import { useApiQuery } from "hooks/useApiQuery";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { speciality } from "helpers/enum";
import { primaryCareVisitFlowReducers } from "reducers/primary_care/primaryCareVisitFlowReducer";
import { pedVisitFlowReducers } from "reducers/ped/pedVisitFlowReducer";

function usePatients() {
  const dispatch = useDispatch();
  const { userId, currentSpeciality } = useCurrentUser();
  const {
    loading: fetchingPatients,
    payload: patientsList,
    query: getPatients,
  } = useApiQuery(getPatientsAction(userId), false);
  useEffect(() => {
    if (patientsList?.length) {
      if (currentSpeciality === speciality.dermatology) {
        dispatch(
          dermaVisitFlowReducers.patientsList({
            value: patientsList,
          })
        );
      } else if (currentSpeciality === speciality.primary_care) {
        dispatch(
          primaryCareVisitFlowReducers.patientsList({
            value: patientsList,
          })
        );
      } else if (currentSpeciality === speciality.ped) {
        dispatch(
          pedVisitFlowReducers.patientsList({
            value: patientsList,
          })
        );
      } else {
        return;
      }
    }
  }, [dispatch, patientsList, currentSpeciality]);

  function getAccountHolder(patients_list) {
    return patients_list?.find(
      (patient) => patient?.relationship_with_account === "myself"
    );
  }

  const getPatientById = useCallback((patient_id, patients_list) => {
    return patients_list?.find((patient) => patient?.id === patient_id);
  }, []);

  const getCurrentPatient = useCallback((patient_id, patients_list) => {
    return patients_list?.find((patient) => patient?.id === patient_id);
  }, []);

  return {
    getPatients,
    getAccountHolder,
    patientsList,
    fetchingPatients,
    getCurrentPatient,
    getPatientById,
  };
}

export default usePatients;
