import React from "react";

const NotAvailableOnSkySection = ({ unavailableMeds }) => {
  return (
    <>
      <table
        className="w-full table table-auto border-separate bg-transparent"
        style={{ borderSpacing: "0 8px" }}
      >
        <thead>
          <tr>
            <th className="p-2 font-bold text-left text-2xl md:p-4">
              Product and Instructions
            </th>
            <th className="p-2 font-bold text-left text-2xl text-right md:p-4">
              Qty.
            </th>
          </tr>
        </thead>
        <tbody>
          {unavailableMeds?.map((el, index) => {
            return (
              <tr key={index} className="bg-white">
                <td className="p-2 font-medium text-2xl md:p-4">
                  <div className="flex flex-col">
                    <p className="font-medium text-2xl text-indigo">
                      {el?.name}
                    </p>
                    <p className="mt-2 font-light text-xl text-indigo text-opacity-90">
                      {el?.instruction}
                    </p>
                  </div>
                </td>
                <td className="p-2 font-medium text-2xl text-right md:p-4">
                  {el?.quantity}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default NotAvailableOnSkySection;
