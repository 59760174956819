import React from "react";

import { googleAuthAction } from "api/actions/AuthActions";
import { useGoogleLogin } from "@react-oauth/google";

import { useApiMutation } from "hooks/useApiMutation";
import { useDispatch } from "react-redux";
import { signinUser } from "reducers/currentUserReducer";
import { useHistory } from "react-router-dom";
import useToastify from "hooks/useToastify";
import { setUserProp, trackEvent } from "helpers/analytics";
import { login_type } from "helpers/enum";
import { SignupContext } from "providers/SignupProvider";
import { Icon } from "stories/Icons";
import CSSLoader from "components/cssLoader/CSSLoader";
import classNames from "classnames";

function GoogleLogin({
  label = "Continue with Google",
  source = "login",
  ...rest
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showAlert } = useToastify();
  const { mutate: callGoogleAuthAPI, loading } = useApiMutation(
    googleAuthAction
  );
  const { formDispatch } = React.useContext(SignupContext);
  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const result = await callGoogleAuthAPI({
          access_token: codeResponse.access_token,
        });
        if (result.error || (result.payload.error && result.payload.message)) {
          errorHandler(result.payload.message);
        } else {
          if (result.payload.auth_token && result.payload.id) {
            successHandler(result);
          } else {
            showAlert(result.payload.message, "success");
            formDispatch({
              type: "FIRST_STEP",
              payload: {
                email: result.payload.email,
                first_name: result.payload.first_name,
                last_name: result.payload.last_name,
                login_via: login_type.GOOGLE,
              },
            });
            history.replace("/signup");
          }
        }
      } catch (err) {
        errorHandler(err);
      }
    },
    onError: (error) => errorHandler(error),
  });
  function successHandler(result) {
    dispatch(
      signinUser({
        userToken: result.payload.auth_token,
        userId: result.payload.id,
      })
    );
    setUserProp({ user_id: result.payload.id });
    history.replace("/home");
  }

  function errorHandler(message) {
    showAlert(message, "error");
  }

  return (
    <button
      className={classNames(
        "flex items-center justify-center w-full px-4 h-18 py-1.5 text-2.5xl font-medium text-black bg-white border border-grey-600 rounded-6/5 hover:bg-gray-100 focus:outline-none",
        loading ? "opacity-60 cursor-not-allowed" : "cursor-pointer"
      )}
      type="button"
      {...rest}
      onClick={() => {
        trackEvent(`google_auth_${source}`);
        login();
      }}
    >
      <Icon name={"google"} className="w-10 h-10" />
      <div className="mx-4"> {label}</div>
      {loading && <CSSLoader className={"w-8 h-8 text-gray-500"} />}
    </button>
  );
}

export default GoogleLogin;
