import React, { useState } from "react";
import Header from "components/header/Header";
import CardPayments from "components/payment/CardPayments";
import CSSLoader from "components/cssLoader/CSSLoader";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useApiMutation } from "hooks/useApiMutation";
import { retryOtcOrderPayment } from "api/actions/VisitActions";
import useToastify from "hooks/useToastify";

function Payments() {
  const history = useHistory();
  const location = useLocation();
  const { visit_id } = useParams();

  const { showAlert } = useToastify();

  const [updatingPayment, setUpdatingPayment] = useState(false);

  const {
    mutate: callRetryPayment,
    loading: callingRetryPayment,
  } = useApiMutation(retryOtcOrderPayment);

  const completeOrder = async () => {
    const payload = {
      visit_id: location?.state?.payload?.visit_id,
    };
    const result = await callRetryPayment(payload);

    if (result && result.error === false) {
      history.replace(`/visit-details/${visit_id}/success`, {
        patientName: location?.state?.patientName,
      });
      showAlert(
        result?.payload?.message ?? "Order placed successfully",
        "success"
      );
    } else {
      if (result.status === 402) {
        history.push(`/visit-details/${visit_id}/error`, {
          patientName: location?.state?.patientName,
          payload: location?.state?.payload,
        });
        showAlert(result?.payload?.message ?? "Payment failed", "error");
      } else {
        showAlert(result?.payload?.message ?? "Something went wrong", "error");
      }
    }
  };

  return (
    <div className="h-screen bg-veryLightBlue relative">
      <Header title="Payment Details" />
      <div className="h-eliminateHeaderWithBottomTab">
        <div className="wrapper px-10 h-full bg-white">
          <CardPayments setAddingCard={setUpdatingPayment} />
        </div>
      </div>
      <BottomFixedButton
        label="Order Now"
        loading={updatingPayment || callingRetryPayment}
        onClick={completeOrder}
      />
      {updatingPayment || callingRetryPayment ? (
        <div className="w-full h-full absolute left-0 top-0 flex items-center justify-center bg-alternativeWhite z-20 bg-opacity-50">
          <CSSLoader color={"orange"} className="w-12 h-12" />
        </div>
      ) : null}
    </div>
  );
}

export default Payments;
