import React from "react";

function TextWithBulletPoint({ text, className }) {
  return (
    <div className={`flex ${className}`}>
      <div className="w-4 h-4 rounded-full bg-orange relative top-2" />
      <p className="text-2xl font-medium pl-3">{text}</p>
    </div>
  );
}

export default TextWithBulletPoint;
