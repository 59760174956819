import useModal from "hooks/useModal";
import React, { useState } from "react";

import "./ModalComponent.scss";

function ModalComponent() {
  const { showModal, setShowModal } = useModal();
  const [reverseAnimation, setReverseAnimation] = useState(false);

  function onClose() {
    setReverseAnimation(true);
    setTimeout(() => {
      setShowModal({
        state: false,
        children: null,
        data: null,
      });
      setReverseAnimation(false);
    }, 300);
  }

  if (showModal?.state) {
    return (
      <div
        className={
          "modal_component h-full fixed top-0 w-full max-h-screen bg-black bg-opacity-60"
        }
      >
        <div
          className={`modal_component_children_container h-full w-full
          ${
            reverseAnimation ? "modal_component_children_container_reverse" : ""
          }`}
        >
          {showModal?.children(showModal?.data, onClose)}
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default ModalComponent;
