import ImageComponent from "components/imageComponent/ImageComponent";
import Spacer from "components/Spacer/Spacer";
import VisitSummaryContent from "components/visitSummaryAccordion/VisitSummaryContent";
import VisitSummaryContentHeading from "components/visitSummaryAccordion/VisitSummaryContentHeading";
import VisitSummaryContentParagraph from "components/visitSummaryAccordion/VisitSummaryContentParagraph";
import { dermatology_sub_steps } from "helpers/enum";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import usePatients from "hooks/visit/usePatients";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";

function BasicDetailsForDermSummary({ data, readOnly }) {
  const { incompleteData, patientsList } = useDermatologyVisitFlow();
  const { getPatientById } = usePatients();
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <VisitSummaryContent
      readOnly={readOnly}
      onEditClick={() => {
        dispatch(
          dermaVisitFlowReducers.currentScreen({
            value: dermatology_sub_steps.select_patient,
          })
        );
        history.push("/visit/dermatology/new-visit");
      }}
    >
      <VisitSummaryContentHeading text="Patient Name" />
      <VisitSummaryContentParagraph
        text={
          data?.patient_name ??
          getPatientById(incompleteData?.patient_id, patientsList)?.full_name
        }
      />
      <Spacer height={20} />
      <VisitSummaryContentHeading text="State of Residence" />
      <VisitSummaryContentParagraph
        text={
          data?.state ??
          getPatientById(incompleteData?.patient_id, patientsList)?.state
        }
      />
      <Spacer height={20} />
      <VisitSummaryContentHeading text="Patient Identity" />
      <div className="pt-5">
        <ImageComponent
          url={
            data?.patient_photo_id ??
            getPatientById(incompleteData?.patient_id, patientsList)?.insurance
              ?.id_proof
          }
        />
      </div>
    </VisitSummaryContent>
  );
}

export default BasicDetailsForDermSummary;
