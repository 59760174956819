import TextWithBulletPoint from "../TextWithBulletPoint";

export const getFamilyHistoryView = (payload) => {
  const values = {
    adopted:
      typeof payload?.adopted === "boolean"
        ? payload?.adopted === true
          ? "Yes"
          : "No"
        : null,
    family_history: payload?.family_history?.length
      ? payload?.family_history
      : null,
  };
  if (Object.keys(values)?.some((el) => values?.[el] !== null)) {
    return (
      <div className="p-8 bg-alternativeWhite border border-borderColor mt-8 rounded-xl">
        {values?.adopted ? (
          <TextWithBulletPoint text={`Adopted : ${values?.adopted}`} />
        ) : null}
        {values?.family_history
          ? values?.family_history?.map((el, idx) => {
              return (
                <div className="my-4" key={idx}>
                  <TextWithBulletPoint text={`${el?.disease}`} />
                  {el?.members_affected?.map((elem, index) => {
                    return (
                      <p className="pl-2 pt-2 font-light text-2xl" key={index}>
                        - {elem}
                      </p>
                    );
                  })}
                </div>
              );
            })
          : null}
      </div>
    );
  } else {
    return null;
  }
};
