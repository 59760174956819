import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { health_profile_sections } from "helpers/enum";
import useHealthProfile from "hooks/useHealthProfile";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getHealthProfileSectionStatus } from "../getHealthProfileSectionStatus";
import ListItemWrapper from "../ListItemWrapper";
import { getAlcoholUseView } from "./alcoholUse/getAlcoholUseView";
import { getCaffeineUseView } from "./caffeineUse/getCaffeineUseView";
import { getExerciseView } from "./exercise/getExerciseView";
import { getMaritalStatusView } from "./maritalStatus/getMaritalStatusView";
import { getRecreationalDrugsView } from "./recreationalDrugs/getRecreationalDrugsView";
import { getSexualActivityView } from "./sexualActivity/getSexualActivityView";
import { getSpecialDietView } from "./specialDiet/getSpecialDietView";
import { getTobaccoView } from "./tobaccoUse/getTobaccoView";
import { getVaccinationsView } from "./vaccinations/getVaccinationsView";
import SimpleHeader from "components/header/SimpleHeader";

function HPSocialHistoryView() {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { healthPayload } = useHealthProfile();
  return (
    <div className="h-screen bg-veryLightBlue">
      <SimpleHeader showBackButton title="Social History" />
      <div className="p-12 pr-20 overflow-y-auto bg-white h-eliminateHeaderWithBottomTab md:h-eliminateHeader">
        <MedicalAssistant
          content={<p>Please tell us about your social history.</p>}
        />
        <div>
          <ListItemWrapper
            itemName={health_profile_sections.marital_status}
            status={getHealthProfileSectionStatus({
              healthPayload,
              section: health_profile_sections?.marital_status,
            })}
            onClick={() => history.push(`${url}/marital-status`)}
          >
            {getMaritalStatusView(healthPayload?.health_profile)}
          </ListItemWrapper>
          <ListItemWrapper
            itemName={health_profile_sections.alcohol_use}
            status={getHealthProfileSectionStatus({
              healthPayload,
              section: health_profile_sections?.alcohol_use,
            })}
            onClick={() => history.push(`${url}/alcohol-use`)}
          >
            {getAlcoholUseView(healthPayload?.health_profile)}
          </ListItemWrapper>
          <ListItemWrapper
            itemName={health_profile_sections.tobacco_use}
            status={getHealthProfileSectionStatus({
              healthPayload,
              section: health_profile_sections?.tobacco_use,
            })}
            onClick={() => history.push(`${url}/tobacco-use`)}
          >
            {getTobaccoView(healthPayload?.health_profile)}
          </ListItemWrapper>
          <ListItemWrapper
            itemName={health_profile_sections.exercise}
            status={getHealthProfileSectionStatus({
              healthPayload,
              section: health_profile_sections?.exercise,
            })}
            onClick={() => history.push(`${url}/exercise`)}
          >
            {getExerciseView(healthPayload?.health_profile)}
          </ListItemWrapper>
          <ListItemWrapper
            itemName={health_profile_sections.recreational_drugs}
            status={getHealthProfileSectionStatus({
              healthPayload,
              section: health_profile_sections?.recreational_drugs,
            })}
            onClick={() => history.push(`${url}/recreational-drugs`)}
          >
            {getRecreationalDrugsView(healthPayload?.health_profile)}
          </ListItemWrapper>
          <ListItemWrapper
            itemName={health_profile_sections.sexual_activity}
            status={getHealthProfileSectionStatus({
              healthPayload,
              section: health_profile_sections?.sexual_activity,
            })}
            onClick={() => history.push(`${url}/sexual-activity`)}
          >
            {getSexualActivityView(healthPayload?.health_profile)}
          </ListItemWrapper>
          <ListItemWrapper
            itemName={health_profile_sections.caffeine_usage}
            status={getHealthProfileSectionStatus({
              healthPayload,
              section: health_profile_sections?.caffeine_usage,
            })}
            onClick={() => history.push(`${url}/caffeine-use`)}
          >
            {getCaffeineUseView(healthPayload?.health_profile)}
          </ListItemWrapper>
          <ListItemWrapper
            itemName={health_profile_sections.special_diet}
            status={getHealthProfileSectionStatus({
              healthPayload,
              section: health_profile_sections?.special_diet,
            })}
            onClick={() => history.push(`${url}/special-diet`)}
          >
            {getSpecialDietView(healthPayload?.health_profile)}
          </ListItemWrapper>
          <ListItemWrapper
            itemName={health_profile_sections.vaccinations + " (optional)"}
            onClick={() => history.push(`${url}/vaccinations`)}
          >
            {getVaccinationsView(healthPayload?.health_profile)}
          </ListItemWrapper>
        </div>
      </div>
    </div>
  );
}

export default HPSocialHistoryView;
