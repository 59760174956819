import ImageComponent from "components/imageComponent/ImageComponent";
import React from "react";

function DoctorCard({
  imageUrl,
  name,
  speciality,
  isActive,
  license,
  viewProfile,
  onViewProfileClick,
}) {
  const isLicenseAvailable = license && Object.keys(license)?.length;
  return (
    <div
      className={`flex ${
        isActive && isLicenseAvailable ? "opacity-100" : "opacity-50"
      }`}
    >
      <ImageComponent url={imageUrl} />
      <div className="pl-6">
        <h3 className="font-bold text-3xl mt-2">{name}</h3>
        <p className="font-medium text-2xl mt-2 opacity-40">{speciality}</p>
        {isLicenseAvailable ? (
          <p className="font-light text-xl mt-2 opacity-50">
            {license?.state} License : {license?.license}
          </p>
        ) : (
          <p className="font-light text-xl mt-2 opacity-50">
            Not licensed in your state
          </p>
        )}
        <p
          className={`text-base text-white font-black ${
            isActive ? "bg-green" : "bg-red"
          } inline-block mt-2 py-2 px-5 rounded-2xl`}
        >
          {isActive ? "ONLINE" : "OFFLINE"}
        </p>
        {viewProfile && onViewProfileClick ? (
          <p
            onClick={onViewProfileClick}
            className="pt-6 text-xl font-bold cursor-pointer text-orange hover:underline"
          >
            View Profile
          </p>
        ) : null}
      </div>
    </div>
  );
}

export default DoctorCard;
