import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  dermatology_main_steps,
  dermatology_sub_steps,
  step_status,
} from "helpers/enum";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import {
  femaleMedicalHistoryData,
  medicalHistoryData,
} from "pages/home/waitingRoom/visitFlow/dermatology/medicalHistory/medicalHistoryData";

function usePopulateDermatologyData({ incompleteData, patientsList }) {
  const dispatch = useDispatch();

  const updateMainSteps = useCallback(() => {
    const apiMainStepKey = incompleteData?.dermatology_steps?.main_steps;
    const mainStepsArray = [
      dermatology_main_steps.basic_info,
      dermatology_main_steps.visit_details,
      dermatology_main_steps.photos,
      dermatology_main_steps.main_symptoms,
      dermatology_main_steps.main_medical_history,
    ];
    mainStepsArray.forEach((el) => {
      if (
        apiMainStepKey &&
        apiMainStepKey?.hasOwnProperty(dermatology_main_steps?.[el])
      ) {
        dispatch(
          dermaVisitFlowReducers?.[dermatology_main_steps?.[el]]({
            status: apiMainStepKey?.[dermatology_main_steps?.[el]],
          })
        );
      }
    });
  }, [dispatch, incompleteData?.dermatology_steps?.main_steps]);

  const updateProgress = useCallback(() => {
    dispatch(
      dermaVisitFlowReducers.completedProgress({
        value: incompleteData?.meta?.progress_percentage,
      })
    );
  }, [dispatch, incompleteData?.meta?.progress_percentage]);

  const updatePaymentMethod = useCallback(() => {
    dispatch(
      dermaVisitFlowReducers.setPaymentMethod({
        value: incompleteData?.payment_method,
      })
    );
  }, [dispatch, incompleteData?.payment_method]);

  const updateBasicDetails = useCallback(() => {
    const patientId = incompleteData?.patient_id;
    let selectPatientStatus;

    const patientData = patientsList?.find(
      (patient) => patient?.id === patientId
    );

    if (patientData?.relationship_with_account === "myself") {
      selectPatientStatus = "Me";
    } else if (patientData?.relationship_with_account === "pappa") {
      selectPatientStatus = "My Child";
    } else {
      selectPatientStatus = "Someone else";
    }

    dispatch(
      dermaVisitFlowReducers.select_patient({
        value: selectPatientStatus,
        isValid: selectPatientStatus ? true : false,
        status:
          incompleteData?.dermatology_steps?.sub_steps?.[
            dermatology_sub_steps.select_patient
          ] ?? "upcoming",
      })
    );
    if (selectPatientStatus === "My Child") {
      dispatch(
        dermaVisitFlowReducers.select_child({
          value: patientData?.full_name,
        })
      );
    } else if (selectPatientStatus === "Someone else") {
      dispatch(
        dermaVisitFlowReducers.select_someone_else({
          value: patientData?.full_name,
        })
      );
    }
    dispatch(
      dermaVisitFlowReducers.confirm_residence({
        isValid: incompleteData?.state?.length ? true : false,
        status:
          incompleteData?.dermatology_steps?.sub_steps?.[
            dermatology_sub_steps.confirm_residence
          ] ?? "upcoming",
      })
    );
    dispatch(
      dermaVisitFlowReducers.patient_bio({
        isValid: patientData?.dob !== null,
        status:
          incompleteData?.dermatology_steps?.sub_steps?.[
            dermatology_sub_steps.patient_bio
          ] ?? "upcoming",
      })
    );
    dispatch(
      dermaVisitFlowReducers.identity_confirmation({
        value: { uri: patientsList?.[0]?.insurance?.id_proof ?? "" },
        isValid: patientsList?.[0]?.insurance?.id_proof ? true : false,
        status:
          incompleteData?.dermatology_steps?.sub_steps?.[
            dermatology_sub_steps.identity_confirmation
          ] ?? "upcoming",
      })
    );
  }, [
    dispatch,
    incompleteData?.dermatology_steps?.sub_steps,
    incompleteData?.patient_id,
    incompleteData?.state?.length,
    patientsList,
  ]);

  const updateVisitDetails = useCallback(() => {
    dispatch(
      dermaVisitFlowReducers.select_condition({
        value: incompleteData?.skin_issue,
        isValid: incompleteData?.skin_issue ? true : false,
        status:
          incompleteData?.dermatology_steps?.sub_steps?.[
            dermatology_sub_steps.select_condition
          ] ?? "upcoming",
      })
    );
    dispatch(
      dermaVisitFlowReducers.select_affected_part({
        value: incompleteData?.affected_body_parts,
        isValid: incompleteData?.affected_body_parts?.length ? true : false,
        status:
          incompleteData?.dermatology_steps?.sub_steps?.[
            dermatology_sub_steps.select_affected_part
          ] ?? "upcoming",
      })
    );
    dispatch(
      dermaVisitFlowReducers.condition_details({
        value: {
          skin_issue_status: incompleteData?.skin_issue_status,
          skin_issue_duration: incompleteData?.skin_issue_duration,
          skin_issue_severity: incompleteData?.skin_issue_severity,
        },
        isValid:
          incompleteData?.skin_issue_status?.length &&
          incompleteData?.skin_issue_duration?.length &&
          incompleteData?.skin_issue_severity?.length
            ? true
            : false,
        status:
          incompleteData?.dermatology_steps?.sub_steps?.[
            dermatology_sub_steps.condition_details
          ] ?? "upcoming",
      })
    );
  }, [
    dispatch,
    incompleteData?.dermatology_steps?.sub_steps,
    incompleteData?.skin_issue,
    incompleteData?.affected_body_parts,
    incompleteData?.skin_issue_status,
    incompleteData?.skin_issue_duration,
    incompleteData?.skin_issue_severity,
  ]);

  const updatePhotos = useCallback(() => {
    dispatch(
      dermaVisitFlowReducers.upload_photos({
        value: {
          close_up_photo_1: {
            uri: incompleteData?.detail_close_up_photo_1_url ?? "",
            data: "",
            file: "",
          },
          far_away_photo_1: {
            uri: incompleteData?.detail_far_away_photo_1_url ?? "",
            data: "",
            file: "",
          },
          close_up_photo_2: {
            uri: incompleteData?.detail_close_up_photo_2_url ?? "",
            data: "",
            file: "",
          },
          far_away_photo_2: {
            uri: incompleteData?.detail_far_away_photo_2_url ?? "",
            data: "",
            file: "",
          },
          close_up_photo_3: {
            uri: incompleteData?.detail_close_up_photo_3_url ?? "",
            data: "",
            file: "",
          },
          far_away_photo_3: {
            uri: incompleteData?.detail_far_away_photo_3_url ?? "",
            data: "",
            file: "",
          },
        },
        isValid:
          incompleteData?.detail_close_up_photo_1_url?.length &&
          incompleteData?.detail_far_away_photo_1_url?.length
            ? true
            : false,
        status:
          incompleteData?.dermatology_steps?.sub_steps?.[
            dermatology_sub_steps.upload_photos
          ] ?? "upcoming",
      })
    );
  }, [
    dispatch,
    incompleteData?.dermatology_steps?.sub_steps,
    incompleteData?.detail_close_up_photo_1_url,
    incompleteData?.detail_far_away_photo_1_url,
    incompleteData?.detail_close_up_photo_2_url,
    incompleteData?.detail_far_away_photo_2_url,
    incompleteData?.detail_close_up_photo_3_url,
    incompleteData?.detail_far_away_photo_3_url,
  ]);

  const updateSymptoms = useCallback(() => {
    dispatch(
      dermaVisitFlowReducers.symptoms({
        value: incompleteData?.symptoms,
        isValid: incompleteData?.symptoms?.length ? true : false,
        status:
          incompleteData?.dermatology_steps?.sub_steps?.[
            dermatology_sub_steps.symptoms
          ] ?? "upcoming",
      })
    );

    dispatch(
      dermaVisitFlowReducers.more_about_symptoms({
        value: {
          worse: incompleteData?.worse,
          better: incompleteData?.better,
        },
        isValid:
          incompleteData?.worse?.length && incompleteData?.better?.length
            ? true
            : false,
        status:
          incompleteData?.dermatology_steps?.sub_steps?.[
            dermatology_sub_steps.more_about_symptoms
          ] ?? "upcoming",
      })
    );
  }, [
    dispatch,
    incompleteData?.dermatology_steps?.sub_steps,
    incompleteData?.symptoms,
    incompleteData?.better,
    incompleteData?.worse,
  ]);

  const updateMedicalHistory = useCallback(() => {
    const male_medical_history = incompleteData?.history?.filter((el) => {
      return medicalHistoryData.some((elem) => {
        return el === elem;
      });
    });
    const female_medical_history = incompleteData?.history?.filter((el) => {
      return femaleMedicalHistoryData.some((elem) => {
        return el === elem;
      });
    });
    dispatch(
      dermaVisitFlowReducers.medical_history({
        value: male_medical_history?.length ? male_medical_history : ["None"],
        isValid: male_medical_history?.length ? true : false,
        status:
          incompleteData?.dermatology_steps?.sub_steps?.[
            dermatology_sub_steps.medical_history
          ] ?? "upcoming",
      })
    );
    dispatch(
      dermaVisitFlowReducers.female_medical_history({
        value: female_medical_history?.length
          ? female_medical_history
          : ["None"],
        isValid: female_medical_history?.length ? true : false,
      })
    );
    dispatch(
      dermaVisitFlowReducers.allergies({
        value: incompleteData?.allergies_json,
        isValid: incompleteData?.allergies_json?.length ? true : false,
        status:
          incompleteData?.dermatology_steps?.sub_steps?.[
            dermatology_sub_steps.allergies
          ] ?? "upcoming",
      })
    );
    dispatch(
      dermaVisitFlowReducers.medications({
        value: incompleteData?.medications,
        isValid: incompleteData?.medications?.length ? true : false,
        status:
          incompleteData?.dermatology_steps?.sub_steps?.[
            dermatology_sub_steps.medications
          ] ?? "upcoming",
      })
    );
    dispatch(
      dermaVisitFlowReducers.skin_care_products({
        value: incompleteData?.skin_care_products,
        isValid: incompleteData?.skin_care_products?.length ? true : false,
        status:
          incompleteData?.dermatology_steps?.sub_steps?.[
            dermatology_sub_steps.skin_care_products
          ] ?? "upcoming",
      })
    );
    dispatch(
      dermaVisitFlowReducers.select_pharmacy({
        value: incompleteData?.pharmacy_details,
        isValid: incompleteData?.pharmacy_details ? true : false,
        status:
          incompleteData?.dermatology_steps?.sub_steps?.[
            dermatology_sub_steps.select_pharmacy
          ] ?? "upcoming",
      })
    );
    dispatch(
      dermaVisitFlowReducers.other_feedbacks({
        value: incompleteData?.additional_info,
        status:
          incompleteData?.dermatology_steps?.sub_steps?.[
            dermatology_sub_steps.other_feedbacks
          ] ?? "upcoming",
      })
    );
  }, [
    dispatch,
    incompleteData?.dermatology_steps?.sub_steps,
    incompleteData?.history,
    incompleteData?.allergies_json,
    incompleteData?.medications,
    incompleteData?.skin_care_products,
    incompleteData?.pharmacy_details,
    incompleteData?.additional_info,
  ]);

  const updatePhotosOld = useCallback(() => {
    if (
      incompleteData?.detail_close_up_photo_1_url &&
      incompleteData?.detail_far_away_photo_1_url
    ) {
      dispatch(
        dermaVisitFlowReducers.upload_photos({
          value: {
            close_up_photo_1: {
              uri: incompleteData?.detail_close_up_photo_1_url ?? "",
              data: "",
              file: "",
            },
            far_away_photo_1: {
              uri: incompleteData?.detail_far_away_photo_1_url ?? "",
              data: "",
              file: "",
            },
            close_up_photo_2: {
              uri: incompleteData?.detail_close_up_photo_2_url ?? "",
              data: "",
              file: "",
            },
            far_away_photo_2: {
              uri: incompleteData?.detail_far_away_photo_2_url ?? "",
              data: "",
              file: "",
            },
            close_up_photo_3: {
              uri: incompleteData?.detail_close_up_photo_3_url ?? "",
              data: "",
              file: "",
            },
            far_away_photo_3: {
              uri: incompleteData?.detail_far_away_photo_3_url ?? "",
              data: "",
              file: "",
            },
          },
          isValid:
            incompleteData?.detail_close_up_photo_1_url?.length &&
            incompleteData?.detail_far_away_photo_1_url?.length
              ? true
              : false,
          status: step_status.completed,
        })
      );
      dispatch(
        dermaVisitFlowReducers.photos({
          status: step_status.completed,
        })
      );
    }
  }, [
    dispatch,
    incompleteData?.detail_close_up_photo_1_url,
    incompleteData?.detail_close_up_photo_2_url,
    incompleteData?.detail_close_up_photo_3_url,
    incompleteData?.detail_far_away_photo_1_url,
    incompleteData?.detail_far_away_photo_2_url,
    incompleteData?.detail_far_away_photo_3_url,
  ]);

  const updateSymptomsOld = useCallback(() => {
    const symptoms = incompleteData?.symptoms;
    const better = incompleteData?.better;
    const worse = incompleteData?.worse;

    if (symptoms?.length) {
      dispatch(
        dermaVisitFlowReducers.symptoms({
          value: incompleteData?.symptoms,
          isValid: incompleteData?.symptoms?.length ? true : false,
          status: step_status.completed,
        })
      );
    }

    if (better && worse) {
      dispatch(
        dermaVisitFlowReducers.more_about_symptoms({
          value: {
            worse: incompleteData?.worse,
            better: incompleteData?.better,
          },
          isValid:
            incompleteData?.worse?.length && incompleteData?.better?.length
              ? true
              : false,
          status: step_status.completed,
        })
      );
    }

    if (symptoms?.length && better && worse) {
      dispatch(
        dermaVisitFlowReducers.main_symptoms({
          status: step_status.completed,
        })
      );
    } else if (symptoms?.length || better || worse) {
      dispatch(
        dermaVisitFlowReducers.main_symptoms({
          status: step_status.current,
        })
      );
    }
  }, [
    dispatch,
    incompleteData?.better,
    incompleteData?.symptoms,
    incompleteData?.worse,
  ]);

  const updateVisitDetailsOld = useCallback(() => {
    const skin_issue = incompleteData?.skin_issue;
    const affected_body_parts = incompleteData?.affected_body_parts;
    const skin_issue_status = incompleteData?.skin_issue_status;
    const skin_issue_occurance_number = incompleteData?.skin_issue_duration?.split(
      " "
    )?.[0];
    const skin_issue_occurance_type = incompleteData?.skin_issue_duration?.split(
      " "
    )?.[1];
    const skin_issue_severity = incompleteData?.skin_issue_severity;

    if (skin_issue) {
      dispatch(
        dermaVisitFlowReducers.select_condition({
          value: incompleteData?.skin_issue,
          isValid: incompleteData?.skin_issue ? true : false,
          status: step_status.completed,
        })
      );
    }

    if (affected_body_parts?.length) {
      dispatch(
        dermaVisitFlowReducers.select_affected_part({
          value: incompleteData?.affected_body_parts,
          isValid: incompleteData?.affected_body_parts?.length ? true : false,
          status: step_status.completed,
        })
      );
    }

    if (
      skin_issue_status &&
      skin_issue_occurance_number &&
      skin_issue_occurance_type &&
      skin_issue_severity
    ) {
      dispatch(
        dermaVisitFlowReducers.condition_details({
          value: {
            skin_issue_status: incompleteData?.skin_issue_status,
            skin_issue_duration: incompleteData?.skin_issue_duration,
            skin_issue_severity: incompleteData?.skin_issue_severity,
          },
          isValid:
            incompleteData?.skin_issue_status?.length &&
            incompleteData?.skin_issue_duration?.length &&
            incompleteData?.skin_issue_severity?.length
              ? true
              : false,
          status: step_status.completed,
        })
      );
    }

    if (
      skin_issue &&
      affected_body_parts &&
      skin_issue_status &&
      skin_issue_occurance_number &&
      skin_issue_occurance_type &&
      skin_issue_severity
    ) {
      dispatch(
        dermaVisitFlowReducers.visit_details({
          status: step_status.completed,
        })
      );
    } else if (
      skin_issue ||
      affected_body_parts ||
      skin_issue_status ||
      skin_issue_occurance_number ||
      skin_issue_occurance_type ||
      skin_issue_severity
    ) {
      dispatch(
        dermaVisitFlowReducers.visit_details({
          status: step_status.current,
        })
      );
    }
  }, [
    dispatch,
    incompleteData?.affected_body_parts,
    incompleteData?.skin_issue,
    incompleteData?.skin_issue_duration,
    incompleteData?.skin_issue_severity,
    incompleteData?.skin_issue_status,
  ]);

  const updateMedicalHistoryOld = useCallback(() => {
    const history = incompleteData?.history;
    const allergies = incompleteData?.allergies_json;
    const medications = incompleteData?.medications;
    const skin_care_products = incompleteData?.skin_care_products;
    const pharmacy_details = incompleteData?.pharmacy_details;

    const male_medical_history = incompleteData?.history?.filter((el) => {
      return medicalHistoryData.some((elem) => {
        return el === elem;
      });
    });
    const female_medical_history = incompleteData?.history?.filter((el) => {
      return femaleMedicalHistoryData.some((elem) => {
        return el === elem;
      });
    });

    if (male_medical_history?.length || female_medical_history?.length) {
      dispatch(
        dermaVisitFlowReducers.medical_history({
          value: male_medical_history?.length ? male_medical_history : ["None"],
          isValid: male_medical_history?.length ? true : false,
          status: step_status.completed,
        })
      );
      dispatch(
        dermaVisitFlowReducers.female_medical_history({
          value: female_medical_history?.length
            ? female_medical_history
            : ["None"],
          isValid: female_medical_history?.length ? true : false,
        })
      );
    }

    if (allergies?.length) {
      dispatch(
        dermaVisitFlowReducers.allergies({
          value: incompleteData?.allergies_json,
          isValid: incompleteData?.allergies_json?.length ? true : false,
          status: step_status.completed,
        })
      );
    }
    if (medications?.length) {
      dispatch(
        dermaVisitFlowReducers.medications({
          value: incompleteData?.medications,
          isValid: incompleteData?.medications?.length ? true : false,
          status: step_status.completed,
        })
      );
    }
    if (skin_care_products?.length) {
      dispatch(
        dermaVisitFlowReducers.skin_care_products({
          value: incompleteData?.skin_care_products,
          isValid: incompleteData?.skin_care_products?.length ? true : false,
          status: step_status.completed,
        })
      );
    }
    if (pharmacy_details && Object.keys(pharmacy_details)?.length) {
      dispatch(
        dermaVisitFlowReducers.select_pharmacy({
          value: incompleteData?.pharmacy_details,
          isValid: incompleteData?.pharmacy_details ? true : false,
          status: step_status.completed,
        })
      );
    }

    if (incompleteData?.additional_info) {
      dispatch(
        dermaVisitFlowReducers.other_feedbacks({
          value: incompleteData?.additional_info,
          status: step_status.completed,
        })
      );
    }

    if (
      history?.length &&
      allergies?.length &&
      medications?.length &&
      skin_care_products?.length &&
      pharmacy_details &&
      Object.keys(pharmacy_details)?.length
    ) {
      dispatch(
        dermaVisitFlowReducers.main_medical_history({
          status: step_status.completed,
        })
      );
    } else if (
      history?.length &&
      (allergies?.length ||
        medications?.length ||
        skin_care_products?.length ||
        Object.keys(pharmacy_details)?.length)
    ) {
      dispatch(
        dermaVisitFlowReducers.main_medical_history({
          status: step_status.current,
        })
      );
    }
  }, [
    dispatch,
    incompleteData?.additional_info,
    incompleteData?.allergies_json,
    incompleteData?.history,
    incompleteData?.medications,
    incompleteData?.pharmacy_details,
    incompleteData?.skin_care_products,
  ]);

  const updateBasicInfoOld = useCallback(() => {
    const patientId = incompleteData?.patient_id;
    let selectPatientStatus;

    const patientData = patientsList?.find(
      (patient) => patient?.id === patientId
    );

    if (patientData?.relationship_with_account === "myself") {
      selectPatientStatus = "Me";
    } else if (patientData?.relationship_with_account === "pappa") {
      selectPatientStatus = "My Child";
    } else {
      selectPatientStatus = "Someone else";
    }

    if (selectPatientStatus) {
      dispatch(
        dermaVisitFlowReducers.select_patient({
          value: selectPatientStatus,
          isValid: selectPatientStatus ? true : false,
          status: step_status.completed,
        })
      );
    }

    if (selectPatientStatus === "My Child") {
      dispatch(
        dermaVisitFlowReducers.select_child({
          value: patientData?.full_name,
        })
      );
    } else if (selectPatientStatus === "Someone else") {
      dispatch(
        dermaVisitFlowReducers.select_someone_else({
          value: patientData?.full_name,
        })
      );
    }

    if (incompleteData?.state?.length) {
      dispatch(
        dermaVisitFlowReducers.confirm_residence({
          isValid: incompleteData?.state?.length ? true : false,
          status: step_status.completed,
        })
      );
    }
    if (patientsList?.[0]?.insurance?.id_proof?.length) {
      dispatch(
        dermaVisitFlowReducers.identity_confirmation({
          value: { uri: patientsList?.[0]?.insurance?.id_proof ?? "" },
          isValid: patientsList?.[0]?.insurance?.id_proof ? true : false,
          status: step_status.completed,
        })
      );
    }
  }, [
    dispatch,
    incompleteData?.patient_id,
    incompleteData?.state?.length,
    patientsList,
  ]);

  useEffect(() => {
    if (incompleteData && Object.keys(incompleteData)?.length && patientsList) {
      if (
        incompleteData?.dermatology_steps &&
        Object.keys(incompleteData?.dermatology_steps)?.length &&
        Object.keys(incompleteData?.dermatology_steps?.main_steps)?.length &&
        Object.keys(incompleteData?.dermatology_steps?.sub_steps)?.length
      ) {
        updateMainSteps();
        updatePaymentMethod();
        updateProgress();
        updateBasicDetails();
        updateVisitDetails();
        updatePhotos();
        updateSymptoms();
        updateMedicalHistory();
      } else {
        updatePaymentMethod();
        updateProgress();
        updateBasicInfoOld();
        updateVisitDetailsOld();
        updatePhotosOld();
        updateSymptomsOld();
        updateMedicalHistoryOld();
      }
    }
  }, [
    incompleteData,
    patientsList,
    updateMainSteps,
    updateBasicDetails,
    updateVisitDetails,
    updatePhotos,
    updateSymptoms,
    updateMedicalHistory,
    updatePaymentMethod,
    updateProgress,
    updateBasicInfoOld,
    updateVisitDetailsOld,
    updatePhotosOld,
    updateSymptomsOld,
    updateMedicalHistoryOld,
  ]);
}

export default usePopulateDermatologyData;
