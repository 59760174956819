import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import CosmeticBasicDetails from "./CosmeticBasicDetails";
import CosmeticFlow from "./cosmeticFlow/CosmeticFlow";
import CosmeticPricing from "./CosmeticPricing";
import CosmeticVisitTerms from "./CosmeticVisitTerms";
import CosmeticCheckout from "./checkout/CosmeticCheckout";
import CheckCosmeticFlow from "./cosmeticFlow/CheckCosmeticFlow";
import ConsultationSubmitted from "../ConsultationSubmitted";
import CosmeticGuard from "routes/cosmeticGuard";

function CosmeticConsultationRoutes() {
  let { url } = useRouteMatch();
  return (
    <Switch>
      <CosmeticGuard exact path={`${url}`}>
        <CheckCosmeticFlow />
      </CosmeticGuard>
      <CosmeticGuard exact path={`${url}/cosmetic/terms_and_conditions`}>
        <CosmeticVisitTerms />
      </CosmeticGuard>
      <CosmeticGuard path={`${url}/cosmetic/checkout`}>
        <CosmeticCheckout />
      </CosmeticGuard>
      <CosmeticGuard path={`${url}/cosmetic/basic-details`}>
        <CosmeticBasicDetails />
      </CosmeticGuard>
      <CosmeticGuard path={`${url}/cosmetic/pricing`}>
        <CosmeticPricing />
      </CosmeticGuard>
      <CosmeticGuard path={`${url}/cosmetic/submitted`}>
        <ConsultationSubmitted />
      </CosmeticGuard>
      <CosmeticGuard path={`${url}/cosmetic`}>
        <CosmeticFlow />
      </CosmeticGuard>
    </Switch>
  );
}

export default CosmeticConsultationRoutes;
