import PharmacyMapView from "components/mapView/PharmacyMapView";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import PharmacyCard from "components/pharmacyCard/PharmacyCard";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import {
  dermatology_main_steps,
  dermatology_sub_steps,
  step_status,
} from "helpers/enum";
import useModal from "hooks/useModal";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import useVisitStepChange from "hooks/useVisitStepChange";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { useApiMutation } from "hooks/useApiMutation";
import { postConsentAction } from "api/actions/VisitActions";

function SelectPharmacy() {
  const dispatch = useDispatch();
  const { setShowModal } = useModal();
  const [selectedPharmacy, setSelectedPharmacy] = useState(null);

  const { incompleteData, subSteps } = useDermatologyVisitFlow();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore =
    subSteps[dermatology_sub_steps.select_pharmacy].value;

  const { updateVisit, updatingVisit } = useUpdateDermatologyVisit();
  const { mutate: callPostConsent } = useApiMutation(postConsentAction);

  useEffect(() => {
    if (
      currentScreenValueFromStore &&
      Object.keys(currentScreenValueFromStore)?.length
    ) {
      setSelectedPharmacy(currentScreenValueFromStore);
    }
  }, [currentScreenValueFromStore]);

  useEffect(() => {
    if (selectedPharmacy) {
      dispatch(
        dermaVisitFlowReducers.select_pharmacy({
          isValid: true,
        })
      );
    } else {
      dispatch(
        dermaVisitFlowReducers.select_pharmacy({
          isValid: false,
        })
      );
    }
  }, [dispatch, selectedPharmacy]);

  function onRightButtonClick(_, nextAnimation) {
    callPostConsent(incompleteData?.id);
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          pharmacy_details: {
            pharmacy_id: selectedPharmacy?.pharmacy_id,
            address: selectedPharmacy?.address,
          },
          dermatology_steps: {
            main_steps: {
              ...incompleteData?.dermatology_steps?.main_steps,
              [dermatology_main_steps.main_medical_history]:
                step_status.completed,
            },
            sub_steps: {
              ...incompleteData?.dermatology_steps?.sub_steps,
            },
          },
        },
      },
      change_current_screen: true,
      screen_to_complete: dermatology_sub_steps.select_pharmacy,
      callBack: () => {
        dispatch(
          dermaVisitFlowReducers.select_pharmacy({
            value: {
              pharmacy_id: selectedPharmacy?.pharmacy_id,
              address: selectedPharmacy?.address,
            },
          })
        );
        nextAnimation();
      },
    });
  }

  return (
    <StepScreenContainer align={"start"}>
      <MedicalAssistant
        content={
          <p>
            What's your preferred pharmacy for getting prescription medicines to
            you?
          </p>
        }
      />
      <Card>
        <PharmacyCard
          pharmacyAddess={selectedPharmacy?.address}
          onClickEdit={() => {
            if (updatingVisit) {
              return null;
            } else {
              setShowModal({
                state: true,
                children: (_, onClose) => (
                  <PharmacyMapView
                    onClose={onClose}
                    onSelectPharmacy={(pharmacy) =>
                      setSelectedPharmacy(pharmacy)
                    }
                  />
                ),
                data: null,
              });
            }
          }}
        />
      </Card>
    </StepScreenContainer>
  );
}

export default SelectPharmacy;
