import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import Input from "components/inputs/Input";
import Button from "components/buttons/Button";
import FormikErrorFocus from "formik-error-focus";

import { SignupSecondStep } from "../../helpers/validationSchema";
import { input_types, login_type } from "helpers/enum";
import Spacer from "components/Spacer/Spacer";
import constants from "helpers/constants";
import { useApiQuery } from "hooks/useApiQuery";
import { getGeoDataFromZipcodeNoAuthAction } from "api/actions/UserActions";
import { useParams } from "react-router-dom";
import Images from "assets/images";

const CompleteProfileForm = ({
  formRef,
  formValues,
  formDispatch,
  loading,
  gettingProviders,
  providers,
  providerPayload,
  formSubmit,
  formErrorMsg,
  setFormErrorMsg,
}) => {
  const { slotId } = useParams();

  const [submitted, setSubmitted] = useState(false);

  // Zip Code Confirm Changes
  const [showZip, setShowZip] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);

  const {
    loading: gettingZipDetails,
    payload: zipDetails,
    query: getZipcodeDetails,
  } = useApiQuery(getGeoDataFromZipcodeNoAuthAction(showZip), false);

  useEffect(() => {
    if (formValues?.zip_code.length > 0) {
      setShowZip(formValues?.zip_code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (submitted) {
      formSubmit(formValues);
      setSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted]);

  useEffect(() => {
    if (showZip.length === 5) {
      getZipcodeDetails();
      setShowConfirm(true);
    }
  }, [getZipcodeDetails, showZip]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        mobile_number: formValues.mobile_number,
        zip_code: formValues.zip_code,
        type: formValues.type,
        provider: formValues.provider,
        email: formValues.email,
        login_via: formValues.login_via,
      }}
      validationSchema={SignupSecondStep}
      onSubmit={(values) => {
        formDispatch({ type: "SECOND_STEP", payload: values });
        setSubmitted(true);
      }}
    >
      {({ setFieldValue, setFieldTouched, values, dirty, isValid }) => (
        <Form className="p-4 md:p-0" key="second-step">
          <div className="p-6 bg-white md:p-0 rounded-3xl required-label">
            <h1 className="hidden mb-3 text-4xl font-medium text-center text-black">
              Complete Profile
            </h1>
            <p className="hidden mb-4 text-2xl font-medium text-center md:text-2xl md:text-left text-grey-700 md:py-6 md:mb-0">
              Please fill all the fields to get started
            </p>
            <div className="grid grid-cols-2 grid-rows-1 gap-x-4">
              <Input
                name="first_name"
                label="First Name"
                id="first_name"
                placeholder="John"
              />
              <Input
                name="last_name"
                label="Last Name"
                id="last_name"
                placeholder="Doe"
              />
            </div>
            <Spacer height={16} />
            {values.login_via === login_type.PHONE &&
            values.mobile_number !== null ? (
              <Input
                name="email"
                type="email"
                label="Email"
                id="email"
                placeholder="johndoe@xyz.com"
              />
            ) : (
              <Input
                name="mobile_number"
                type={input_types.PHONE}
                label="Mobile Number"
                id="mobile_number"
                placeholder="(313)-456-7890"
                value={values?.mobile_number}
                onChange={(val) => {
                  setFieldValue("mobile_number", val);
                }}
                onBlur={() => setFieldTouched("mobile_number", true)}
                onFocus={() => {
                  setFormErrorMsg("");
                }}
              />
            )}
            <Spacer height={16} />
            <Input
              type={input_types.WITHLOADER}
              name="zip_code"
              label="Zip Code"
              id="zip_code"
              placeholder="10001"
              maxLength={5}
              onChange={(event) => {
                setFieldValue("zip_code", event.target.value);
                if (event.target.value.length === 5) {
                  setShowZip(event.target.value);
                } else {
                  setShowZip("");
                  setShowConfirm(false);
                }
              }}
              customonblur={() => {
                if (values.zip_code.length === 5) {
                  setShowZip(values.zip_code);
                } else {
                  setShowZip("");
                  setShowConfirm(false);
                }
              }}
              onFocus={() => {
                setShowZip("");
                setShowConfirm(false);
              }}
              loading={gettingZipDetails}
            />
            {showConfirm && !gettingZipDetails ? (
              <p className="mt-2 text-xl font-bold">
                {zipDetails?.geo_data
                  ? `You've entered ${showZip} (${zipDetails?.geo_data?.state_name}) as your zip code.`
                  : "Please enter a valid zip code"}
              </p>
            ) : (
              <div />
            )}
            {providerPayload?.practice?.zip && providers.length > 0 ? (
              <>
                <Spacer height={16} />
                <Input
                  type={input_types.DROPDOWN}
                  label={"Provider"}
                  name={"provider"}
                  options={providers?.map((el) => el?.name)}
                  default={
                    // eslint-disable-next-line eqeqeq
                    providers?.find((el) => el?.id == formValues?.provider)
                      ?.name
                  }
                  customonchange={(val) => setFieldValue("provider", val)}
                />
              </>
            ) : null}
            <Spacer height={16} />
            <div className="flex flex-col items-center justify-center">
              <p className="text-2xl font-light leading-8 text-center md:text-xl md:self-start md:text-left md:mb-4 text-indigo">
                By signing up, you agree that you have read our{" "}
                <a
                  className="text-2xl font-medium underline cursor-pointer md:text-xl text-indigo"
                  href={constants.TERMS_AND_CONDITIONS_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions.
                </a>
              </p>
              <Spacer height={18} />
              {formErrorMsg?.length > 0 ? (
                <div className="flex items-center self-start mb-4 space-x-4">
                  <img
                    src={Images.filledError}
                    alt="error"
                    className="w-14 h-14"
                  />
                  <p className="text-xl font-bold text-red">{formErrorMsg}</p>
                </div>
              ) : null}
              <Button
                type="submit"
                label={"Sign Up"}
                loading={loading || gettingProviders}
                disabled={
                  loading ||
                  gettingProviders ||
                  !showConfirm ||
                  gettingZipDetails ||
                  !zipDetails?.geo_data ||
                  !((dirty || formValues.step === 4) && isValid)
                }
                className="py-4 md:h-18"
                id="GTM_portal_signup"
              />
              <Spacer height={21} />
              <p className="text-2xl font-light leading-8 text-center text-indigo">
                Already have an account?{" "}
                <a
                  className="text-2xl font-medium no-underline cursor-pointer text-orange"
                  href={slotId ? `/login/${slotId}` : "/login"}
                >
                  Login
                </a>
              </p>
            </div>
            <FormikErrorFocus align={"bottom"} ease={"linear"} duration={500} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CompleteProfileForm;
