import React from "react";
import Images from "assets/images";
import Chip from "components/chip/Chip";
import Card from "components/visitCommon/Card";

function SearchInputWithChipCard({
  onClickInput,
  inputLabel,
  selected,
  setSelected,
}) {
  return (
    <Card>
      <div
        onClick={onClickInput}
        className="flex items-center justify-between border border-borderColor rounded-1.6 p-6 cursor-text"
      >
        <p className="text-2xl font-bold opacity-40">{inputLabel}</p>
        <img
          className="w-8 h-8 object-contain"
          src={Images.searchIcon}
          alt={"search"}
        />
      </div>
      {selected?.length ? (
        <div className="mt-5 pt-8 px-12 border-t border-borderColor -mr-10 -ml-10">
          <div className="flex flex-wrap">
            {selected.map((el, index) => (
              <Chip
                key={index}
                text={
                  inputLabel === "Search Medication" ||
                  inputLabel === "Search Allergy"
                    ? el?.name
                    : el
                }
                className="mb-8"
                onClear={() => {
                  setSelected((prev) => prev?.filter((elem) => elem !== el));
                }}
              />
            ))}
          </div>
        </div>
      ) : null}
    </Card>
  );
}

export default SearchInputWithChipCard;
