import moment from "moment";
import TextWithBulletPoint from "../TextWithBulletPoint";

export const getFemaleHistoryView = (payload) => {
  const values = {
    pap_date: payload?.female_history?.pap_date?.length
      ? payload?.female_history?.pap_date
      : null,
    abnormal_pap: payload?.female_history?.abnormal_pap?.length
      ? payload?.female_history?.abnormal_pap
      : null,
    abnormal_pap_date: payload?.female_history?.abnormal_pap_date?.length
      ? payload?.female_history?.abnormal_pap_date
      : null,
    date_of_last_mammogram: payload?.female_history?.date_of_last_mammogram
      ?.length
      ? payload?.female_history?.date_of_last_mammogram
      : null,
    lmp: payload?.female_history?.lmp?.length
      ? payload?.female_history?.lmp
      : null,
    menopause: payload?.female_history?.menopause?.length
      ? payload?.female_history?.menopause
      : null,
    menopause_age: payload?.female_history?.menopause_age?.length
      ? payload?.female_history?.menopause_age
      : null,
    menstrual_problem: payload?.female_history?.menstrual_problem?.length
      ? payload?.female_history?.menstrual_problem
      : null,
    period:
      typeof payload?.female_history?.period === "number"
        ? payload?.female_history?.period
        : null,
    no_of_births:
      typeof payload?.female_history?.no_of_births === "number"
        ? payload?.female_history?.no_of_births
        : null,
    no_of_miscarriages:
      typeof payload?.female_history?.no_of_miscarriages === "number"
        ? payload?.female_history?.no_of_miscarriages
        : null,
    no_of_pregnancies:
      typeof payload?.female_history?.no_of_pregnancies === "number"
        ? payload?.female_history?.no_of_pregnancies
        : null,
    other: payload?.female_history?.other?.length
      ? payload?.female_history?.other
      : null,
  };

  if (Object.keys(values)?.some((el) => values?.[el] !== null)) {
    return (
      <div className="p-8 bg-alternativeWhite border border-borderColor mt-8 rounded-xl">
        {values?.pap_date ? (
          <TextWithBulletPoint
            className="mb-4"
            text={`Last PAP date: ${moment(
              values?.pap_date,
              "YYYY-MM-DD"
            ).format("DD MMM, YYYY")}`}
          />
        ) : null}
        {values?.abnormal_pap ? (
          <TextWithBulletPoint
            className="mb-4"
            text={`Abnormal PAP : ${values?.abnormal_pap}`}
          />
        ) : null}
        {values?.abnormal_pap_date ? (
          <TextWithBulletPoint
            className="mb-4"
            text={`Abnormal PAP date : ${moment(
              values?.abnormal_pap_date,
              "YYYY-MM-DD"
            ).format("DD MMM, YYYY")}`}
          />
        ) : null}

        {values?.date_of_last_mammogram ? (
          <TextWithBulletPoint
            className="mb-4"
            text={`Last mammogram date : ${moment(
              values?.date_of_last_mammogram,
              "YYYY-MM-DD"
            ).format("DD MMM, YYYY")}`}
          />
        ) : null}

        {values?.lmp ? (
          <TextWithBulletPoint
            className="mb-4"
            text={`First day of LMP : ${moment(
              values?.lmp,
              "YYYY-MM-DD"
            ).format("DD MMM, YYYY")}`}
          />
        ) : null}

        {values?.menopause ? (
          <TextWithBulletPoint
            className="mb-4"
            text={`Menopause : ${values?.menopause}`}
          />
        ) : null}

        {values?.menopause_age ? (
          <TextWithBulletPoint
            className="mb-4"
            text={`Menopause age : ${values?.menopause_age}`}
          />
        ) : null}

        {values?.menstrual_problem ? (
          <TextWithBulletPoint
            className="mb-4"
            text={`Menstrual problem : ${values?.menstrual_problem}`}
          />
        ) : null}

        {values?.period ? (
          <TextWithBulletPoint
            className="mb-4"
            text={`Period evey ${values?.period} days`}
          />
        ) : null}

        {values?.no_of_births !== null ? (
          <TextWithBulletPoint
            className="mb-4"
            text={`Number of births : ${values?.no_of_births}`}
          />
        ) : null}

        {values?.no_of_miscarriages !== null ? (
          <TextWithBulletPoint
            className="mb-4"
            text={`Number of miscarriages : ${values?.no_of_miscarriages}`}
          />
        ) : null}

        {values?.no_of_pregnancies !== null ? (
          <TextWithBulletPoint
            className="mb-4"
            text={`Number of pregnancies : ${values?.no_of_pregnancies}`}
          />
        ) : null}

        {values?.other ? (
          <>
            <TextWithBulletPoint className="mb-2" text={`Other`} />
            {values?.other?.map((elem, index) => {
              return (
                <p className="pl-8 pt-2 font-light text-2xl" key={index}>
                  {elem}
                </p>
              );
            })}
          </>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};
