import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment } from "react";

function ButtonDropDown(props) {
  const {
    buttonProps,
    className,
    icon,
    childComponent,
    childClass = "w-27r",
  } = props;

  return (
    <div className={classNames(className, "text-right")}>
      <Menu as="div" className="inline-block text-left ">
        <div>
          <Menu.Button as="button" {...buttonProps}>
            {icon}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div className="relative">
            <Menu.Items
              className={classNames(
                "absolute right-0 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
                childClass
              )}
            >
              {childComponent}
            </Menu.Items>
          </div>
        </Transition>
      </Menu>
    </div>
  );
}

export default ButtonDropDown;
