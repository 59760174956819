import React, { useCallback, useEffect, useState } from "react";
import DropIn from "braintree-web-drop-in-react";

import {
  getBrainTreeInfoAltAction,
  getBrainTreeTokenAltAction,
} from "api/actions/PaymentActions";
import CSSLoader from "components/cssLoader/CSSLoader";
import { useApiQuery } from "hooks/useApiQuery";

import "./CardPayments.scss";

function PendingCardPayments({
  amount,
  cards,
  paymentId,
  setBtnLoading,
  setCards,
  setPaymentNonce,
}) {
  const [brainTreeToken, setBrainTreeToken] = useState(null);
  const [instance, setInstance] = useState(null);

  const { payload: BtTokenPayload } = useApiQuery(
    getBrainTreeTokenAltAction(paymentId),
    true
  );

  const {
    loading: isFetchingBrainTreeInfo,
    payload: braintreeInfo,
  } = useApiQuery(getBrainTreeInfoAltAction(paymentId), true);

  const getValidCards = useCallback(() => {
    if (braintreeInfo?.paypal_accounts && braintreeInfo?.cards) {
      return [...braintreeInfo?.cards, ...braintreeInfo?.paypal_accounts];
    } else if (braintreeInfo?.paypal_accounts) {
      return braintreeInfo?.paypal_accounts;
    } else if (braintreeInfo?.cards) {
      return braintreeInfo?.cards;
    } else {
      return [];
    }
  }, [braintreeInfo?.cards, braintreeInfo?.paypal_accounts]);

  const onCardChange = useCallback(
    (noncePayload) => {
      setCards([...cards, noncePayload]);
      setPaymentNonce(noncePayload?.nonce);
    },
    [cards, setCards, setPaymentNonce]
  );

  const cardAction = useCallback(async () => {
    instance.requestPaymentMethod().then((data) => onCardChange(data));
  }, [instance, onCardChange]);

  useEffect(() => {
    if (braintreeInfo) {
      const result = getValidCards();
      setCards(result);
    }
  }, [setCards, braintreeInfo, getValidCards]);

  useEffect(() => {
    if (BtTokenPayload) {
      setBrainTreeToken(BtTokenPayload?.token);
    }
  }, [BtTokenPayload, setBrainTreeToken]);

  useEffect(() => {
    if (instance) {
      instance.on("paymentMethodRequestable", cardAction);
    }

    return () => {
      if (instance) {
        instance.off("paymentMethodRequestable");
      }
    };
  }, [cardAction, instance]);

  useEffect(() => {
    if (instance) {
      setPaymentNonce(instance?._model?._activePaymentMethod?.nonce);
      setBtnLoading(false);
    } else {
      setBtnLoading(true);
    }
  }, [instance, setBtnLoading, setPaymentNonce]);

  return (
    <div>
      {brainTreeToken && !isFetchingBrainTreeInfo ? (
        <div className="w-full py-6">
          <DropIn
            options={{
              authorization: brainTreeToken,
              paypal: {
                flow: "vault",
              },
              venmo: { allowNewBrowserTab: true },
              applePay: {
                displayName: "SkyMD",
                paymentRequest: {
                  total: {
                    label: "SkyMD",
                    type: "final",
                    amount: `${amount}`,
                  },
                  // We recommend collecting billing address information, at minimum
                  // billing postal code, and passing that billing postal code with all
                  // Apple Pay transactions as a best practice.
                  // requiredBillingContactFields: ["postalAddress"]
                },
              },
              googlePay: {
                googlePayVersion: 2,
                merchantId: 'BCR2DN4TTDXNJPA7',
                transactionInfo: {
                  totalPriceStatus: "FINAL",
                  totalPrice: `${amount}`,
                  currencyCode: "USD",
                },
              },
            }}
            onInstance={(ins) => setInstance(ins)}
          />
        </div>
      ) : (
        <div className="px-4 py-0 md:px-0 md:py-6">
          <div className="w-full bg-alternativeWhite flex items-center justify-center h-44">
            <CSSLoader className="w-8 h-8" color="orange" />
            <p className="font-medium text-2xl">Fetching payment details</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default PendingCardPayments;
