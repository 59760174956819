import React from "react";
import Button from "components/buttons/Button";

function BottomFixedButton({
  onClick,
  label,
  loading,
  containerBgColor,
  bgColor = "bg-veryLightBlue",
  secondButton,
  ...rest
}) {
  return (
    <div className={`fixed z-10 bottom-0 w-full ${bgColor}`}>
      <div
        className={`wrapper border-solid p-6 bg-${containerBgColor ?? "white"}`}
      >
        <div className="flex flex-col space-y-6">
          <Button label={label} onClick={onClick} loading={loading} {...rest} />
          {secondButton}
        </div>
      </div>
    </div>
  );
}

export default BottomFixedButton;
