import React, { useCallback, useEffect, useMemo, useState } from "react";
import Accordion from "components/accordion/Accordion";
import VisitSummaryHeader from "components/visitSummaryAccordion/VisitSummaryHeader";
import VisitSummaryContent from "components/visitSummaryAccordion/VisitSummaryContent";
import VisitSummaryContentHeading from "components/visitSummaryAccordion/VisitSummaryContentHeading";
import VisitSummaryContentParagraph from "components/visitSummaryAccordion/VisitSummaryContentParagraph";
import PharmacyCard from "components/pharmacyCard/PharmacyCard";

function DiagnosisAccordion({ visitDetails }) {
  const [accordionData, setAccordionData] = useState([]);

  let initAccordionData = useMemo(
    () => [
      {
        header: (props) => (
          <VisitSummaryHeader {...props} title={"Diagnosis"} />
        ),
        content:
          visitDetails?.diagnosis?.diagnosis?.length ||
          visitDetails?.diagnosis?.diagnosis_detail_html?.length ? (
            <VisitSummaryContent readOnly>
              <VisitSummaryContentHeading
                text={visitDetails?.diagnosis?.diagnosis}
              />
              <VisitSummaryContentParagraph
                text={visitDetails?.diagnosis?.diagnosis_detail_html}
              />
            </VisitSummaryContent>
          ) : null,
      },
      {
        header: (props) => (
          <VisitSummaryHeader {...props} title={"Prescription Medicines"} />
        ),
        content: visitDetails?.diagnosis?.medications?.length ? (
          <VisitSummaryContent readOnly>
            <table className="w-full table table-auto border-collapse border border-black-500">
              <thead>
                <tr>
                  <th className="p-2 font-bold text-left text-2xl border border-black-500 md:p-4">
                    Name of Medication
                  </th>
                  <th className="p-2 font-bold text-left text-2xl border border-black-500 md:p-4">
                    Instruction
                  </th>
                </tr>
              </thead>
              <tbody>
                {visitDetails?.diagnosis?.medications?.map((el, index) => {
                  return (
                    <tr key={index}>
                      <td className="p-2 font-medium text-2xl border border-black-500 md:p-4">
                        {el?.name}
                      </td>
                      <td className="p-2 font-medium text-2xl border border-black-500 md:p-4">
                        {el?.detail}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="mt-5">
              <VisitSummaryContentHeading
                text={
                  "The above presciptions have been made available for pick up at"
                }
              />
              <div className="mt-5 border border-borderColor p-6 rounded-xl">
                <PharmacyCard
                  removeEdit
                  pharmacyAddess={visitDetails?.pharmacy_address}
                />
              </div>
            </div>
          </VisitSummaryContent>
        ) : null,
      },
      {
        header: (props) => (
          <VisitSummaryHeader
            {...props}
            title={"Over-the-Counter Recommendations"}
          />
        ),
        content: visitDetails?.diagnosis?.otc_medications?.length ? (
          <VisitSummaryContent readOnly>
            <table className="w-full table table-auto border-collapse border border-black-500">
              <thead>
                <tr>
                  <th className="p-2 font-bold text-left text-2xl border border-black-500 md:p-4">
                    Name of Medication
                  </th>
                  <th className="p-2 font-bold text-left text-2xl border border-black-500 md:p-4">
                    Instruction
                  </th>
                </tr>
              </thead>
              <tbody>
                {visitDetails?.diagnosis?.otc_medications?.map((el, index) => {
                  return (
                    <tr key={index}>
                      <td className="p-2 font-medium text-2xl border border-black-500 md:p-4">
                        {el?.name}
                      </td>
                      <td className="p-2 font-medium text-2xl border border-black-500 md:p-4">
                        {el?.instruction}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </VisitSummaryContent>
        ) : null,
      },
      {
        header: (props) => (
          <VisitSummaryHeader {...props} title={"Other Instructions"} />
        ),
        content: visitDetails?.diagnosis?.otherInstructionsHtml?.length ? (
          <VisitSummaryContent readOnly>
            <div
              className="text-3xl font-medium "
              dangerouslySetInnerHTML={{
                __html: visitDetails?.diagnosis?.otherInstructionsHtml,
              }}
            />
          </VisitSummaryContent>
        ) : null,
      },
      {
        header: (props) => (
          <VisitSummaryHeader {...props} title={"Follow Up"} />
        ),
        content: visitDetails?.diagnosis?.followUpInstruction?.length ? (
          <VisitSummaryContent readOnly>
            <div
              className="text-3xl font-medium "
              dangerouslySetInnerHTML={{
                __html: visitDetails?.diagnosis?.followUpInstruction,
              }}
            />
          </VisitSummaryContent>
        ) : null,
      },
    ],
    [
      visitDetails?.diagnosis?.diagnosis,
      visitDetails?.diagnosis?.diagnosis_detail_html,
      visitDetails?.diagnosis?.medications,
      visitDetails?.diagnosis?.otc_medications,
      visitDetails?.pharmacy_address,
      visitDetails?.diagnosis?.otherInstructionsHtml,
      visitDetails?.diagnosis?.followUpInstruction,
    ]
  );

  const getAttachmentView = useCallback(() => {
    return {
      header: (props) => (
        <VisitSummaryHeader {...props} title={"Attachments"} />
      ),
      content: (
        <VisitSummaryContent readOnly>
          {visitDetails?.attachments?.length ? (
            visitDetails?.attachments?.map((el, index) => {
              return (
                <div key={index}>
                  <a
                    className="underline"
                    href={el?.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <VisitSummaryContentParagraph text={el?.name} />
                  </a>
                </div>
              );
            })
          ) : (
            <VisitSummaryContentHeading text={"None"} />
          )}
        </VisitSummaryContent>
      ),
    };
  }, [visitDetails?.attachments]);

  const getDermaDryView = useCallback(() => {
    return {
      header: (props) => (
        <VisitSummaryHeader {...props} title={"Dermadry Prescription"} />
      ),
      content: (
        <VisitSummaryContent readOnly>
          <a
            className="underline"
            href={visitDetails?.prescription_download}
            target="_blank"
            rel="noreferrer"
          >
            <p className="text-3xl font-medium">Prescription Link</p>
          </a>
        </VisitSummaryContent>
      ),
    };
  }, [visitDetails?.prescription_download]);

  useEffect(() => {
    if (visitDetails && !visitDetails?.error) {
      if (
        visitDetails?.attachments?.length ||
        visitDetails?.prescription_download?.length
      ) {
        if (visitDetails?.prescription_download?.length) {
          setAccordionData([...initAccordionData, getDermaDryView()]);
        }
        if (visitDetails?.attachments?.length) {
          setAccordionData([...initAccordionData, getAttachmentView()]);
        }
      } else {
        setAccordionData(initAccordionData);
      }
    }
  }, [initAccordionData, visitDetails, getAttachmentView, getDermaDryView]);

  return <Accordion data={accordionData?.filter((el) => el?.content)} />;
}

export default DiagnosisAccordion;
