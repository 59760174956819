import React from "react";

function TimeSlotChip({ slot, isLast, disabled, selected, onClick }) {
  return (
    <div
      onClick={disabled ? null : onClick}
      className={`transition duration-300 relative border rounded-lg flex flex-col items-center justify-center py-4 px-8 ${
        disabled ? "bg-borderColor cursor-not-allowed" : "cursor-pointer "
      } ${!isLast ? "mr-1" : ""} ${
        selected ? "bg-orange text-white border-orange" : "border-borderColor"
      } mb-1`}
    >
      <p
        className={`${
          disabled ? "opacity-50" : "opacity-100"
        } text-xl whitespace-nowrap ${selected ? "font-bold" : "font-medium"}`}
      >
        {slot}
      </p>
    </div>
  );
}

export default TimeSlotChip;
