import React from "react";
import Images from "assets/images";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import Header from "components/header/Header";
import Input from "components/inputs/Input";
import Spacer from "components/Spacer/Spacer";
import { Form, Formik } from "formik";
import { SurgeriesHospitalisationSchema } from "helpers/validationSchema";
import moment from "moment";
import FormikErrorFocus from "formik-error-focus";
import { input_types } from "helpers/enum";

function AddSurgeriesHospitalisationsModal({
  onClose,
  setFieldValue,
  values,
  edit,
}) {
  return (
    <div className="h-screen bg-veryLightBlue">
      <Header
        title={
          edit && Object.keys(edit)?.length
            ? "Update Surgeries / Hospitalisations"
            : "Add Surgeries / Hospitalisations"
        }
        hideBackBtn
        rightActionView={
          <img
            onClick={onClose}
            className="cursor-pointer w-10"
            src={Images.closeIcon}
            alt={"close_icons"}
          />
        }
      />
      <Formik
        onSubmit={(val) => {
          if (edit && Object.values(edit)?.length) {
            setFieldValue(
              "surgeriesHospitalisations",
              values?.map((el, idx) => {
                if (edit?.index_no === idx) {
                  return val;
                } else {
                  return el;
                }
              })
            );
          } else {
            setFieldValue("surgeriesHospitalisations", [...values, val]);
          }

          onClose();
        }}
        validationSchema={SurgeriesHospitalisationSchema}
        initialValues={{
          surgery_or_reason_for_hopitalisation:
            edit?.surgery_or_reason_for_hopitalisation ?? "",
          date: edit?.date ?? "",
        }}
      >
        {() => (
          <Form className="h-full">
            <div className="h-eliminateHeaderWithFooter bg-white wrapper overflow-y-auto p-12">
              <Input
                label="Surgery or reason for hospitalisation"
                id="surgery_or_reason_for_hopitalisation"
                name="surgery_or_reason_for_hopitalisation"
              />
              <Spacer height={32} />
              <Input
                type={input_types.DATEPICKER}
                label="Date (Approximate)"
                id="date"
                name="date"
                max={moment().format("YYYY-MM-DD")}
              />
            </div>
            <BottomFixedButton
              type="submit"
              label={edit && Object.keys(edit)?.length ? "Update" : "Add"}
            />
            <FormikErrorFocus align={"bottom"} ease={"linear"} duration={500} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddSurgeriesHospitalisationsModal;
