import MultiLineInput from "components/inputs/MultiLineInput";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import VisitSubQuestionSlideUpContainer from "components/visitSubQuestionSlideUp/VisitSubQuestionSlideUpContainer";
import { Form, Formik } from "formik";
import { dermatology_sub_steps } from "helpers/enum";
import scrollToBottom from "helpers/scrollToBottom";
import { SkinCareProductsSchema } from "helpers/validationSchema";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import useVisitStepChange from "hooks/useVisitStepChange";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";

function SkinCareProducts() {
  const formRef = useRef();
  const nextAnimationRef = useRef();

  const [selected, setSelected] = useState("No");
  const [selectedProducts, setSelectedProducts] = useState([]);

  const dispatch = useDispatch();
  const { incompleteData, subSteps } = useDermatologyVisitFlow();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore =
    subSteps[dermatology_sub_steps.skin_care_products].value;

  const { updateVisit } = useUpdateDermatologyVisit();

  useEffect(() => {
    if (currentScreenValueFromStore?.length) {
      if (
        currentScreenValueFromStore?.includes("None") &&
        currentScreenValueFromStore?.length === 1
      ) {
        setSelected("No");
        setSelectedProducts([]);
      } else {
        setSelected("Yes");
        setSelectedProducts(currentScreenValueFromStore);
      }
    }
  }, [currentScreenValueFromStore]);

  useEffect(() => {
    if (selected) {
      if (selected === "No") {
        dispatch(
          dermaVisitFlowReducers.skin_care_products({
            isValid: true,
          })
        );
      } else {
        scrollToBottom();
        if (formRef?.current?.isValid) {
          dispatch(
            dermaVisitFlowReducers.skin_care_products({
              isValid: true,
            })
          );
        }
      }
    } else {
      dispatch(
        dermaVisitFlowReducers.skin_care_products({
          isValid: false,
        })
      );
    }
  }, [dispatch, selected]);

  function updateVisitApi(skin_care_products) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          skin_care_products:
            selected === "Yes"
              ? skin_care_products
                  ?.split(",")
                  ?.map((element) => element?.trim())
              : ["None"],
          dermatology_steps: incompleteData?.dermatology_steps,
        },
      },
      change_current_screen: true,
      screen_to_complete: dermatology_sub_steps.skin_care_products,
      callBack: () => {
        dispatch(
          dermaVisitFlowReducers.skin_care_products({
            value:
              selected === "Yes"
                ? skin_care_products
                    ?.split(",")
                    ?.map((element) => element?.trim())
                : ["None"],
          })
        );
        nextAnimationRef?.current();
      },
    });
  }

  function onRightButtonClick(_, nextAnimation) {
    nextAnimationRef.current = nextAnimation;
    if (selected === "Yes") {
      if (formRef?.current) {
        formRef?.current?.handleSubmit();
      }
    } else {
      updateVisitApi("None");
    }
  }

  function onFormSubmit(values) {
    updateVisitApi(values?.skin_care_products);
  }

  return (
    <StepScreenContainer align={"start"}>
      <MedicalAssistant
        content={<p>Are you using any skin care products?</p>}
      />
      <Card>
        <RadioButtonCard
          options={["Yes", "No"]}
          selected={selected}
          onSelect={setSelected}
        />
      </Card>
      {selected === "Yes" ? (
        <VisitSubQuestionSlideUpContainer>
          <Spacer height={40} />
          <Card>
            <Formik
              innerRef={formRef}
              onSubmit={onFormSubmit}
              initialValues={{
                skin_care_products: selectedProducts?.join(", ") ?? "",
              }}
              validationSchema={SkinCareProductsSchema}
            >
              {() => (
                <Form>
                  <MultiLineInput
                    label={
                      "List any skin care product(s) in use, separated by comma"
                    }
                    id={"skin_care_products"}
                    name={"skin_care_products"}
                  />
                </Form>
              )}
            </Formik>
          </Card>
        </VisitSubQuestionSlideUpContainer>
      ) : null}
    </StepScreenContainer>
  );
}

export default SkinCareProducts;
