import React, { useEffect, useState } from "react";
import { useApiQuery } from "hooks/useApiQuery";
import DropIn from "braintree-web-drop-in-react";
import {
  createBrainTreeCustomerAction,
  getBrainTreeInfoAction,
  getBrainTreeTokenAction,
} from "api/actions/PaymentActions";
import { useApiMutation } from "hooks/useApiMutation";
import "./CardPayments.scss";
import CSSLoader from "components/cssLoader/CSSLoader";
import useToastify from "hooks/useToastify";
import { useCallback } from "react";
import { Icon } from "stories/Icons";
import { disclaimer } from "helpers/statics";
import useModal from "hooks/useModal";
import { useHistory } from "react-router-dom";

function CardPayments({
  amount = 0.0,
  cards,
  caseId,
  setCards,
  setAddingCard,
  refreshPage,
}) {
  const [brainTreeToken, setBrainTreeToken] = useState(null);
  const [instance, setInstance] = useState(null);
  const { setShowModal } = useModal();
  const history = useHistory();

  const { showAlert } = useToastify();
  const { query: getBtToken, payload: BtTokenPayload } = useApiQuery(
    getBrainTreeTokenAction,
    false
  );
  const {
    query: getBrainTreeInfo,
    loading: isFetchingBrainTreeInfo,
    payload: braintreeInfo,
  } = useApiQuery(getBrainTreeInfoAction, false);

  const {
    mutate: createBrainTreeCustomer,
    loading: isAddingCard,
    payload: createdPayload,
  } = useApiMutation(createBrainTreeCustomerAction);

  const getValidCards = useCallback(() => {
    if (braintreeInfo?.paypal_accounts && braintreeInfo?.cards) {
      return [...braintreeInfo?.cards, ...braintreeInfo?.paypal_accounts];
    } else if (braintreeInfo?.paypal_accounts) {
      return braintreeInfo?.paypal_accounts;
    } else if (braintreeInfo?.cards) {
      return braintreeInfo?.cards;
    } else {
      return [];
    }
  }, [braintreeInfo?.cards, braintreeInfo?.paypal_accounts]);

  useEffect(() => {
    if (setAddingCard) {
      if (isAddingCard) {
        setAddingCard(true);
      } else {
        setAddingCard(false);
      }
    }
  }, [isAddingCard, setAddingCard]);

  useEffect(() => {
    if (braintreeInfo && setCards) {
      const result = getValidCards();
      setCards(result);
    }
  }, [setCards, braintreeInfo, getValidCards]);

  useEffect(() => {
    if (BtTokenPayload) {
      setBrainTreeToken(BtTokenPayload?.token);
    }
  }, [amount, BtTokenPayload, setBrainTreeToken]);

  useEffect(() => {
    getBtToken();
    getBrainTreeInfo();
  }, [amount, getBtToken, getBrainTreeInfo]);

  const onCardChange = useCallback(
    async (noncePayload) => {
      try {
        const result = await createBrainTreeCustomer({
          case_id: caseId,
          payload: { ...noncePayload },
        });
        if (result && result?.error === false) {
          if (refreshPage) {
            refreshPage();
          }
          if (setCards) {
            setCards([...cards, noncePayload]);
          }
        }
      } catch (error) {
        showAlert(error?.message ?? "Something went wrong", "error");
      }
    },
    [cards, caseId, setCards, createBrainTreeCustomer, showAlert, refreshPage]
  );

  const cardAction = useCallback(async () => {
    instance.requestPaymentMethod().then((data) => {
      onCardChange(data);
    });
  }, [instance, onCardChange]);

  useEffect(() => {
    if (instance) {
      instance.on("paymentMethodRequestable", cardAction);
    }
    return () => {
      if (instance) {
        instance.off("paymentMethodRequestable");
      }
    };
  }, [instance, cardAction]);

  const PriceRevisionModal = useCallback(
    ({ onClose }) => {
      return (
        <div className="flex items-center justify-center w-full h-full py-20 overflow-auto bg-black bg-opacity-50">
          <div className="h-full max-w-4xl md:w-2/5 rounded-xl flex items-center">
            <div className="p-6 py-8 bg-white rounded-xl space-y-16">
              <h3 className="text-4xl font-medium text-center">
                Would you like to return to your previous price revision page ?
              </h3>

              <div className="flex justify-center gap-4">
                <button
                  onClick={() => {
                    history.push(
                      localStorage.getItem("last_revision"),
                      JSON.parse(localStorage.getItem("last_revision_data"))
                    );
                  }}
                  className="border text-2xl p-3 px-10 rounded-md bg-green text-white font-semibold"
                >
                  Yes
                </button>
                <button
                  onClick={() => onClose()}
                  className="border text-2xl p-3 px-10 rounded-md bg-red text-white font-semibold"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    },
    [history]
  );

  useEffect(() => {
    if (
      (braintreeInfo?.cards?.length > 0 || createdPayload?.message) &&
      localStorage.getItem("last_revision") &&
      localStorage.getItem("last_revision_data")
    ) {
      setShowModal({
        state: true,
        children: (_, onClose) => <PriceRevisionModal onClose={onClose} />,
        data: null,
      });
    }
  }, [braintreeInfo?.cards?.length, createdPayload?.message, setShowModal]);

  return (
    <>
      <div>
        {setCards ? (
          <div>
            <h2 className="uppercase font-black text-2xl pt-8">
              Payment Method
            </h2>
            <div className="flex flex-col space-y-2 py-4">
              {disclaimer.map((e) => (
                <div
                  className="flex space-x-2 text-xl text-black font-medium"
                  key={e}
                >
                  <Icon name={"green-check"} />
                  <p>{e}</p>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        {brainTreeToken && !isFetchingBrainTreeInfo ? (
          <div className="w-full py-6">
            <DropIn
              options={{
                authorization: brainTreeToken,
                paypal: {
                  flow: "vault",
                },
                venmo: { allowNewBrowserTab: true },
                applePay: {
                  displayName: "SkyMD Test",
                  paymentRequest: {
                    total: {
                      label: "SkyMD Test",
                      type: "final",
                      amount: `${amount}`,
                    },
                  },
                },
                googlePay: {
                  googlePayVersion: 2,
                  merchantId: process.env.GOOGLE_PAY_MERCHANT_ID,
                  transactionInfo: {
                    totalPriceStatus: "FINAL",
                    totalPrice: `${amount}`,
                    currencyCode: "USD",
                  },
                },
              }}
              onInstance={(ins) => setInstance(ins)}
            />
          </div>
        ) : (
          <div className="py-6">
            <div className="w-full bg-alternativeWhite flex items-center justify-center h-44">
              <CSSLoader className="w-8 h-8" color="orange" />
              <p className="font-medium text-2xl">Fetching payment details</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CardPayments;
