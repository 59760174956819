import moment from "moment";
import TextWithBulletPoint from "../../TextWithBulletPoint";

export const getTobaccoView = (payload) => {
  const values = {
    exposed_to_second_hand_smoke: payload?.tobacco_usage
      ?.exposed_to_second_hand_smoke?.length
      ? payload?.tobacco_usage?.exposed_to_second_hand_smoke
      : null,
    form_of_tobacco: payload?.tobacco_usage?.form_of_tobacco?.length
      ? payload?.tobacco_usage?.form_of_tobacco
      : null,
    frequency: payload?.tobacco_usage?.frequency?.length
      ? payload?.tobacco_usage?.frequency
      : null,
    no_of_tobacco:
      typeof payload?.tobacco_usage?.no_of_tobacco === "number"
        ? payload?.tobacco_usage?.no_of_tobacco
        : null,
    no_of_years:
      typeof payload?.tobacco_usage?.no_of_years === "number"
        ? payload?.tobacco_usage?.no_of_years
        : null,
    quit_date: payload?.tobacco_usage?.quit_date?.length
      ? payload?.tobacco_usage?.quit_date
      : null,
    smokers_in_household: payload?.tobacco_usage?.smokers_in_household?.length
      ? payload?.tobacco_usage?.smokers_in_household
      : null,
    tobacco_usage: payload?.tobacco_usage?.tobacco_usage?.length
      ? payload?.tobacco_usage?.tobacco_usage
      : null,
  };
  if (Object.keys(values)?.some((el) => values?.[el] !== null)) {
    return (
      <div className="p-8 bg-alternativeWhite border border-borderColor mt-8 rounded-xl">
        {values?.tobacco_usage ? (
          <TextWithBulletPoint
            text={`Tobacco usage : ${values?.tobacco_usage}`}
          />
        ) : null}
        {values?.form_of_tobacco ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Tobacco form : ${values?.form_of_tobacco?.join(", ")}`}
          />
        ) : null}
        {values?.no_of_tobacco ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Number : ${values?.no_of_tobacco}`}
          />
        ) : null}
        {values?.frequency ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Per : ${values?.frequency}`}
          />
        ) : null}
        {values?.no_of_years ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Years : ${values?.no_of_years}`}
          />
        ) : null}
        {values?.quit_date ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Quit date : ${moment(values?.quit_date, "YYYY-MM-DD").format(
              "DD MMM, YYYY"
            )}`}
          />
        ) : null}
        {values?.smokers_in_household ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Smokers in  household : ${values?.smokers_in_household}`}
          />
        ) : null}
        {values?.smokers_in_household ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Exposed to second hand smoke : ${values?.exposed_to_second_hand_smoke}`}
          />
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};
