import TextWithBulletPoint from "../../TextWithBulletPoint";

export const getRecreationalDrugsView = (payload) => {
  const values = {
    drug_usage: payload?.drug_usage?.drug_usage?.length
      ? payload?.drug_usage?.drug_usage
      : null,
    drug_type: payload?.drug_usage?.drug_type?.length
      ? payload?.drug_usage?.drug_type
      : null,
    usage_in_number:
      typeof payload?.drug_usage?.usage_in_number === "number"
        ? payload?.drug_usage?.usage_in_number
        : null,
    frequency: payload?.drug_usage?.frequency?.length
      ? payload?.drug_usage?.frequency
      : null,
  };

  if (Object.keys(values)?.some((el) => values?.[el] !== null)) {
    return (
      <div className="p-8 bg-alternativeWhite border border-borderColor mt-8 rounded-xl">
        {values?.drug_usage ? (
          <TextWithBulletPoint text={`Drug usage : ${values?.drug_usage}`} />
        ) : null}
        {values?.drug_type ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Drug type : ${values?.drug_type}`}
          />
        ) : null}
        {values?.usage_in_number ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Usage in number : ${values?.usage_in_number}`}
          />
        ) : null}
        {values?.frequency ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Per : ${values?.frequency}`}
          />
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};
