import React from "react";
import useModal from "hooks/useModal";
import AddSurgeriesHospitalisationsModal from "./AddSurgeriesHospitalisationsModal";
import Images from "assets/images";
import moment from "moment";
import Spacer from "components/Spacer/Spacer";

function AddSurgeriesHospitalisations({ values, setFieldValue }) {
  const { setShowModal } = useModal();
  return (
    <div>
      {values?.length ? null : (
        <p
          onClick={() =>
            setShowModal({
              state: true,
              children: (_, onClose) => (
                <AddSurgeriesHospitalisationsModal
                  onClose={onClose}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              ),
            })
          }
          className="text-2xl font-light underline text-orange my-10 cursor-pointer"
        >
          Add Surgeries / Hospitalisations
        </p>
      )}
      <Spacer height={16} />
      {values?.map((el, index) => {
        return (
          <div
            key={index}
            className="flex items-center justify-between border border-borderColor rounded-3xl p-8 mt-8"
          >
            <div>
              <h3 className="font-bold text-2xl">
                {el?.surgery_or_reason_for_hopitalisation}
              </h3>
              <p className="font-light text-xl mt-3">
                {moment(el?.date).format("DD MMM, YYYY")}
              </p>
            </div>
            <img
              onClick={() =>
                setShowModal({
                  state: true,
                  children: (_, onClose) => (
                    <AddSurgeriesHospitalisationsModal
                      edit={{
                        ...el,
                        index_no: index,
                      }}
                      onClose={onClose}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  ),
                })
              }
              src={Images.editIconInverted}
              alt="edit"
              className="w-16 h-16 cursor-pointer"
            />
          </div>
        );
      })}
      {values?.length ? (
        <p
          onClick={() =>
            setShowModal({
              state: true,
              children: (_, onClose) => (
                <AddSurgeriesHospitalisationsModal
                  onClose={onClose}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              ),
            })
          }
          className="text-2xl font-light underline text-orange my-20 cursor-pointer text-center"
        >
          Add More
        </p>
      ) : null}
    </div>
  );
}

export default AddSurgeriesHospitalisations;
