import Images from "assets/images";
import classNames from "classnames";
import { trackEvent } from "helpers/analytics";
import React, { useEffect, useState } from "react";

function Accordion({
  containerClassName,
  accordionContainerClassName,
  data,
  activeIndexFromData,
  disabledIndicesFromData,
  multiple,
  enableTransition = false,
  onExpand = () => {},
  loading = false,
}) {
  const [activeIndex, setActiveIndex] = useState(null);
  const [disabledIndices, setDisabledIndices] = useState([]);

  useEffect(() => {
    setActiveIndex(activeIndexFromData);
  }, [activeIndexFromData]);
  useEffect(() => {
    setDisabledIndices(disabledIndicesFromData);
  }, [disabledIndicesFromData]);

  function isActive(index) {
    if (multiple) {
      if (activeIndex) {
        if (activeIndex?.length) {
          if (activeIndex?.includes(index)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return activeIndex === index;
    }
  }

  return (
    <div className={containerClassName}>
      {data?.length
        ? data.map((ele, index) => {
            if (ele) {
              return (
                <div key={index} className={accordionContainerClassName}>
                  <button
                    type="button"
                    disabled={disabledIndices?.includes(index) || loading}
                    className={classNames(
                      "w-full focus:outline-none",
                      ele.className
                    )}
                    onClick={() => {
                      onExpand(!isActive(index));
                      if (!loading) {
                        if (multiple) {
                          if (activeIndex) {
                            if (activeIndex.includes(index)) {
                              setActiveIndex((prev) =>
                                prev.filter((el) => el !== index)
                              );
                            } else {
                              setActiveIndex((prev) => [...prev, index]);
                            }
                          } else {
                            setActiveIndex([index]);
                          }
                        } else {
                          if (activeIndex === index) {
                            setActiveIndex(null);
                          } else {
                            if (ele.name) {
                              trackEvent(`${ele.name}_toggle`);
                            }
                            setActiveIndex(index);
                          }
                        }
                      }
                    }}
                  >
                    {ele?.header({
                      isActive: isActive(index),
                      isLast: index === data?.length - 1,
                    })}
                  </button>
                  <div
                    className={`overflow-hidden ${
                      isActive(index)
                        ? `max-h-auto transition-all ease-in duration-500${
                            enableTransition
                              ? " opacity-100 transform translate-y-0"
                              : ""
                          }`
                        : `max-h-0 transition-all ease-out duration-500${
                            enableTransition
                              ? " opacity-0 transform -translate-y-8"
                              : ""
                          }`
                    } ${ele?.contentClass}`}
                  >
                    {loading ? (
                      <div className="flex items-center self-center justify-center w-full">
                        <img
                          className="w-40"
                          src={Images.appLoader}
                          alt={"Loading"}
                        />
                      </div>
                    ) : isActive(index) ? (
                      ele?.content
                    ) : null}
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })
        : null}
    </div>
  );
}

export default Accordion;
