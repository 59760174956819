import {
  availableProvidersAction,
  recommendProvidersAction,
  selectProvider,
} from "api/actions/UserActions";
import Images from "assets/images";
import LoaderComponent from "components/loader/LoaderComponent";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { speciality } from "helpers/enum";
import { useApiMutation } from "hooks/useApiMutation";
import { useApiQuery } from "hooks/useApiQuery";
import { useCurrentUser } from "hooks/useCurrentUser";
import useModal from "hooks/useModal";
import useToastify from "hooks/useToastify";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setUserDetails } from "reducers/currentUserReducer";
import Placeholder from "components/placeholders/Placeholder";
import { trackEvent } from "helpers/analytics";
import ProviderProfileDetails from "pages/home/waitingRoom/profile/ProviderProfile";
import PracticeUserCard from "pages/home/waitingRoom/components/PracticeUserCard";
import SimpleHeader from "components/header/SimpleHeader";
import HelpButton from "pages/home/waitingRoom/visitFlow/common/HelpButton";

function SelectDermaProvider() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showAlert } = useToastify();
  const { setShowModal } = useModal();
  const { userDetails } = useCurrentUser();

  const {
    payload: recommendedDermaProviders,
    loading: gettingRDP,
    query: queryRecommendedDermaProviders,
  } = useApiQuery(recommendProvidersAction(speciality.dermatology), false);
  const {
    payload: availableDermaProviders,
    loading: gettingADP,
    query: queryAvailableDermaProviders,
  } = useApiQuery(availableProvidersAction(speciality.dermatology), false);

  const {
    mutate: callSelectProvider,
    loading: selectingProvider,
  } = useApiMutation(selectProvider);

  const [selectedDermaProvider, setSelectedDermaProvider] = useState(null);

  useEffect(() => {
    if (userDetails?.patient_insurance) {
      queryRecommendedDermaProviders();
    } else {
      queryAvailableDermaProviders();
    }
  }, [
    userDetails?.patient_insurance,
    queryAvailableDermaProviders,
    queryRecommendedDermaProviders,
  ]);

  useEffect(() => {
    if (recommendedDermaProviders) {
      setSelectedDermaProvider(recommendedDermaProviders?.[0]);
    }
    if (availableDermaProviders) {
      setSelectedDermaProvider(availableDermaProviders?.[0]);
    }
  }, [recommendedDermaProviders, availableDermaProviders]);
  async function onSubmit(e) {
    let payload = {
      postbody: {
        id: userDetails?.id,
        user: {},
      },
      userId: userDetails?.id,
    };

    if (e?.id) {
      trackEvent("select_dermatologist", {
        uid: userDetails?.id,
      });
      payload.postbody.user = {
        ...payload.postbody.user,
        dermatology_provider_id: e?.id,
      };
    }

    try {
      const result = await callSelectProvider(payload);
      if (result && result?.error === false) {
        dispatch(
          setUserDetails({
            userDetails: {
              ...userDetails,
              derm_physician: {
                provider_id: e?.id,
                provider_photo_url: e?.avatar,
                provider_name: e?.name,
                first_name: e?.name?.split(" ")?.[0],
                last_name: e?.name?.split(" ")?.[1],
                licensed_states: e?.licensed_states ?? [],
                active: true,
                license: e?.license,
              },
            },
          })
        );
        history.replace({
          pathname: "/visit/dermatology",
        });
      } else {
        showAlert("Something went wrong", "error");
      }
    } catch (error) {
      console.log(error);
      showAlert("Something went wrong", "error");
    }
  }

  // const doctorDetails = (data, onClose) => {
  //   return <DoctorProfileDetails details={data} onClose={onClose} />;
  // };

  if (gettingRDP || gettingADP) {
    return (
      <LoaderComponent
        showLoader={{
          state: true,
          message: "Retrieving providers based on your information",
        }}
      />
    );
  }
  if (!(gettingRDP || gettingADP) && selectedDermaProvider?.name) {
    return (
      <div className="h-screen overflow-hidden bg-gradient-to-b from-blue-300 to-blue-500">
        <SimpleHeader
          title={"My SkyMD Care Team"}
          bgColor="bg-white"
          wrapper={true}
          showBackButton={userDetails?.patient_insurance ? false : true}
        />
        <div className="h-full overflow-y-auto">
          <div className="px-6 pt-8 md:px-12 wrapper p-72 pb-72">
            <MedicalAssistant
              title=""
              content={
                <span className="text-xl font-medium">
                  Browse our top-rated dermatologists to find the one who best
                  fits your needs. Review their profiles and select the provider
                  you feel most comfortable with.
                </span>
              }
            />
            <div className="bg-white border-2 border-grey-600 rounded-xl mt-14">
              <div>
                <div className="flex px-4 py-6 text-4xl font-bold border-b">
                  Recommended for you
                </div>
                <div className="p-4">
                  <PracticeUserCard
                    className="py-8"
                    showNetwork={false}
                    picture={selectedDermaProvider?.avatar}
                    name={selectedDermaProvider?.name}
                    speciality={selectedDermaProvider?.speciality}
                    showLicense={selectedDermaProvider?.license}
                    licenseState={selectedDermaProvider?.license?.state}
                    licenseNumber={selectedDermaProvider?.license?.license}
                    licenseActive={selectedDermaProvider?.license?.status}
                    videoMeet={selectedDermaProvider?.speciality.includes(
                      "Cosmetic"
                    )}
                    size="fit"
                    ratingValue={selectedDermaProvider?.review?.rating}
                    ratingUserCount={
                      selectedDermaProvider?.review?.ratings_count
                    }
                    inNetwork={true}
                    latestReview={selectedDermaProvider?.review?.latest_review}
                    viewProfile={() => {
                      setShowModal({
                        state: true,
                        children: (_, onClose) => (
                          <ProviderProfileDetails
                            details={selectedDermaProvider}
                            onClose={onClose}
                          />
                        ),
                      });
                    }}
                    cta={
                      <div className="w-full md:w-min">
                        <button
                          className="w-full px-8 py-4 text-2xl font-semibold text-white border rounded-lg opacity-100 md:w-max bg-orange"
                          onClick={() => onSubmit(selectedDermaProvider)}
                          disabled={
                            selectingProvider || gettingRDP || gettingADP
                          }
                        >
                          Proceed with consultation
                        </button>
                      </div>
                    }
                  />
                  {/* <div
                    className="flex items-center justify-between px-0 py-8 border-t border-b cursor-pointer border-borderColor"
                    onClick={() => {
                      trackEvent("view_dermatologist_profile", {
                        uid: userDetails?.id,
                        selectedDermaProvider: selectedDermaProvider?.id,
                      });
                      setShowModal({
                        state: true,
                        children: doctorDetails,
                        data: selectedDermaProvider,
                      });
                    }}
                  >
                    <p className="flex-grow pr-4 text-2xl font-light text-indigo">
                      View {selectedDermaProvider?.name}'s Profile
                    </p>
                    <img
                      className="w-8"
                      src={Images.arrowRight}
                      alt="right_arrow"
                    />
                  </div> */}
                  {/* {recommendedDermaProviders?.length > 1 ||
                  availableDermaProviders?.length > 1 ? (
                    <div
                      className="flex items-center justify-between px-0 py-8 border-b cursor-pointer border-borderColor"
                      onClick={() => {
                        setShowModal({
                          state: true,
                          children: (_, onClose) => (
                            <ProvidersList
                              onClose={onClose}
                              selectedProvider={selectedDermaProvider}
                              providers={
                                recommendedDermaProviders ||
                                availableDermaProviders
                              }
                              onProviderChoose={(provider) => {
                                trackEvent("change_dermatologist", {
                                  uid: userDetails?.id,
                                  selectedDermaProvider:
                                    selectedDermaProvider?.id,
                                  recommendedDerma:
                                    recommendedDermaProviders?.[0].id,
                                });
                                setSelectedDermaProvider(provider);
                                onClose();
                              }}
                            />
                          ),
                        });
                      }}
                    >
                      <p className="flex-grow pr-4 text-2xl font-light text-indigo">
                        Change Dermatologist
                      </p>
                      <img
                        className="w-8"
                        src={Images.arrowRight}
                        alt="right_arrow"
                      />
                    </div>
                  ) : (
                    <div />
                  )} */}
                </div>
                <div>
                  {(recommendedDermaProviders?.length > 1 ||
                    availableDermaProviders?.length > 1) && (
                    <div className="flex px-4 py-6 text-4xl font-bold border-t border-b">
                      Other Doctors
                    </div>
                  )}
                  <div className="p-4">
                    {(recommendedDermaProviders?.length > 1 ||
                      availableDermaProviders?.length > 1) &&
                    userDetails?.patient_insurance
                      ? recommendedDermaProviders?.slice(1).map((e) => (
                          <PracticeUserCard
                            className="py-8"
                            showNetwork={false}
                            picture={e?.avatar}
                            name={e?.name}
                            speciality={e?.speciality}
                            showLicense={e?.license}
                            licenseState={e?.license?.state}
                            licenseNumber={e?.license?.license}
                            licenseActive={e?.license?.status}
                            videoMeet={e?.speciality.includes("Cosmetic")}
                            size="fit"
                            ratingValue={e?.review?.rating}
                            ratingUserCount={e?.review?.ratings_count}
                            inNetwork={true}
                            latestReview={e?.review?.latest_review}
                            viewProfile={() => {
                              setShowModal({
                                state: true,
                                children: (_, onClose) => (
                                  <ProviderProfileDetails
                                    details={e}
                                    // caseId={visit?.id}
                                    onClose={onClose}
                                  />
                                ),
                              });
                            }}
                            cta={
                              <div className="w-full md:w-min">
                                <button
                                  className="w-full px-8 py-4 text-2xl font-semibold text-white border rounded-lg opacity-100 md:w-max bg-orange"
                                  onClick={() => onSubmit(e)}
                                  disabled={
                                    selectingProvider ||
                                    gettingRDP ||
                                    gettingADP
                                  }
                                >
                                  Proceed with consultation
                                </button>
                              </div>
                            }
                          />
                        ))
                      : availableDermaProviders?.slice(1).map((e) => (
                          <PracticeUserCard
                            showNetwork={false}
                            className="py-8"
                            picture={e?.avatar}
                            name={e?.name}
                            speciality={e?.speciality}
                            showLicense={e?.license}
                            licenseState={e?.license?.state}
                            licenseNumber={e?.license?.license}
                            licenseActive={e?.license?.status}
                            videoMeet={e?.speciality.includes("Cosmetic")}
                            size="fit"
                            ratingValue={e?.review?.rating}
                            ratingUserCount={e?.review?.ratings_count}
                            inNetwork={true}
                            latestReview={e?.review?.latest_review}
                            viewProfile={() => {
                              setShowModal({
                                state: true,
                                children: (_, onClose) => (
                                  <ProviderProfileDetails
                                    details={e}
                                    // caseId={visit?.id}
                                    onClose={onClose}
                                  />
                                ),
                              });
                            }}
                            cta={
                              <div className="w-full md:w-min">
                                <button
                                  className="w-full px-8 py-4 text-2xl font-semibold text-white border rounded-lg opacity-100 md:w-max bg-orange"
                                  onClick={() => onSubmit(e)}
                                  disabled={
                                    selectingProvider ||
                                    gettingRDP ||
                                    gettingADP
                                  }
                                >
                                  Proceed with consultation
                                </button>
                              </div>
                            }
                          />
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HelpButton bottomClass={"bottom-40"} />
      </div>
    );
  } else {
    return (
      <div className="h-screen bg-white">
        <SimpleHeader title={"My SkyMD Care Team"} />
        <div className="overflow-y-auto bg-white wrapper h-eliminateHeader">
          <Placeholder
            heading={`We are working hard to find a 
        Dermatologist who is licensed to provide services in ${userDetails?.city}.`}
            paragraph={
              "We will notify you when we have a provider available to service in your zipcode"
            }
            placeholderImage={Images.searchDoctor}
          />
        </div>
      </div>
    );
  }
}

export default SelectDermaProvider;
