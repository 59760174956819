import Button from "components/buttons/Button";
import { trackEvent } from "helpers/analytics";
import { Icon } from "stories/Icons";

const HelpButton = ({ bottomClass = "bottom-8", isPricing }) => {
  return (
    <div
      className={`fixed z-50 flex flex-col items-end self-end space-y-4 font-bold text-blue-500  md:right-14 right-9 ${bottomClass}`}
    >
      <div className="hidden w-3/5 pointer-events-none px-3 py-4 text-2xl bg-white md:block md:w-1/2 rounded-t-xl rounded-bl-xl">
        {isPricing
          ? "Need help with insurance? Talk to our friendly medical assistants directly!"
          : "Need help? Call our friendly medical assistant"}
      </div>
      <a
        href={`tel:+18589002766`}
        className="no-underline"
        onClick={() =>
          trackEvent(isPricing ? "insurance_help_button" : "help_button")
        }
      >
        <Button
          className={
            "w-max float-right h-11 pointer-events-auto hover:opacity-90"
          }
          textSize="text-2xl"
          label={
            <div className="flex items-center justify-center space-x-2">
              <Icon name={"call"} />
              <span className="hidden font-bold md:block"> 858-900-2766</span>
            </div>
          }
          style={{
            borderRadius: "50px",
          }}
        />
      </a>
    </div>
  );
};
export default HelpButton;
