import Images from "assets/images";
import React from "react";

function HealthProfileRadio({ text, selected, onClick, className, disabled }) {
  return (
    <div
      className={`flex min-w-max items-center justify-center cursor-${
        disabled ? "not-allowed" : "pointer"
      } ${className}`}
      onClick={disabled ? null : onClick}
    >
      <img
        className="w-8 h-8"
        src={selected ? Images.checkedOrange : Images.uncheckedCircle}
        alt="checked"
      />
      <p className="text-2xl font-light pl-6">{text}</p>
    </div>
  );
}

export default HealthProfileRadio;
