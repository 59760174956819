import { health_profile_status } from "helpers/enum";

export const getHealthProfileSectionStatus = ({ healthPayload, section }) => {
  const completed_sections = healthPayload?.health_profile?.completed_sections;
  if (completed_sections?.includes(section)) {
    return health_profile_status.completed;
  } else {
    return health_profile_status.incomplete;
  }
};
