import classNames from "classnames";
import CSSLoader from "components/cssLoader/CSSLoader";
import React from "react";

function Placeholder({
  heading,
  paragraph,
  placeholderImage,
  placeholderImageClassName,
  buttonText,
  onPressButton,
  buttonLoading,
  renderCustomButton,
  className,
}) {
  return (
    <div
      className={classNames(
        "flex flex-col items-center justify-center h-full",
        className
      )}
    >
      {placeholderImage?.length ? (
        <img
          className={`object-contain ${placeholderImageClassName} max-w-full w-96`}
          src={placeholderImage}
          alt="placeholder"
        />
      ) : null}
      <h3 className="w-4/5 pt-12 text-3xl font-bold leading-9 text-center">
        {heading}
      </h3>
      <p className="w-4/5 pt-4 text-xl font-light leading-7 text-center">
        {paragraph}
      </p>
      {renderCustomButton ? (
        renderCustomButton
      ) : onPressButton ? (
        <div className="mt-10">
          <button
            disabled={buttonLoading}
            className="flex items-center justify-center py-4 text-2xl font-bold bg-transparent border rounded-lg w-72 border-orange hover:bg-orange hover:text-white px-11 text-orange"
            onClick={onPressButton}
          >
            {buttonLoading ? (
              <CSSLoader color={"orange"} className="w-8" />
            ) : null}
            {buttonText}
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default Placeholder;
