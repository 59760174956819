import React from "react";
import { updatePharmacy } from "api/actions/UserActions";
import Images from "assets/images";
import Accordion from "components/accordion/Accordion";
import DoctorCard from "components/doctorCard/DoctorCard";
import PharmacyMapView from "components/mapView/PharmacyMapView";
import PharmacyCard from "components/pharmacyCard/PharmacyCard";
import Spacer from "components/Spacer/Spacer";
import { useApiMutation } from "hooks/useApiMutation";
import useModal from "hooks/useModal";
import moment from "moment";
import { capitalize } from "helpers/lodashPolyfills";
import AddChildModal from "../waitingRoom/visitFlow/common/AddChildModal";
import { isAdult } from "helpers/validationSchema";
import { decimalRestriction } from "helpers/metricConversion";

function ProfileBottomTab({ profileDetails, refetch, loading }) {
  const { setShowModal } = useModal();
  const { mutate: callUpdatePharmacy, loading: updatePharmacyLoading } =
    useApiMutation(updatePharmacy);

  const providers =
    profileDetails?.dermatology_provider?.provider_id &&
    profileDetails?.primary_care_provider?.provider_id
      ? [
          {
            ...profileDetails?.dermatology_provider,
          },
          {
            ...profileDetails?.primary_care_provider,
          },
        ]
      : profileDetails?.dermatology_provider?.provider_id
      ? [
          {
            ...profileDetails?.dermatology_provider,
          },
        ]
      : profileDetails?.primary_care_provider?.provider_id
      ? [
          {
            ...profileDetails?.primary_care_provider,
          },
        ]
      : [];

  const children = profileDetails?.children?.filter(
    (el) => isAdult(moment(el?.dob)) === false
  );

  const ProfileAccordionHeader = ({ headerTitle, isActive }) => (
    <div className="flex justify-between items-start py-10 px-10 border-t border-borderColor">
      <h3 className="text-3xl font-medium">{headerTitle}</h3>
      <div>
        <img
          src={Images.dropdown}
          alt="chev_down"
          className={`transition duration-500 ease-in-out w-10 h-10 transform ${
            isActive ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>
    </div>
  );

  const ProfileAccordionContent = ({ children }) => (
    <div className="px-10 pb-8">{children}</div>
  );

  const accordionData = [
    {
      header: ({ isActive }) => (
        <ProfileAccordionHeader
          headerTitle="Provider Info"
          isActive={isActive}
        />
      ),
      content: (
        <ProfileAccordionContent>
          <div>
            {providers?.length ? (
              providers?.map((el, idx) => {
                if (el?.provider_id) {
                  return (
                    <div
                      key={idx}
                      className={`rounded-xl p-5 border-1/4 border-borderColor bg-white ${
                        idx ===
                        [
                          profileDetails?.dermatology_provider,
                          profileDetails?.primary_care_provider,
                        ]?.length -
                          1
                          ? ""
                          : "mb-4"
                      }`}
                    >
                      <DoctorCard
                        imageUrl={el?.provider_photo_url}
                        name={el?.provider_name}
                        speciality={el?.speciality}
                        isActive={el?.active}
                        license={el?.license}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })
            ) : (
              <div className="rounded-xl p-8 border-1/4 border-borderColor bg-white flex flex-col items-center">
                <p className="text-2xl font-light">
                  You didn’t add any provider.
                </p>
              </div>
            )}
          </div>
        </ProfileAccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <ProfileAccordionHeader headerTitle="Pharmacy" isActive={isActive} />
      ),
      content: (
        <ProfileAccordionContent>
          {profileDetails?.basic_info?.pharmacy?.length === 0 ? (
            <div className="rounded-xl p-8 border-1/4 border-borderColor bg-white flex flex-col items-center">
              <p className="text-2xl font-light">You didn’t add pharmacy.</p>
              <Spacer height={22} />
              <button
                onClick={() => {
                  setShowModal({
                    state: true,
                    children: (_, onClose) => (
                      <PharmacyMapView
                        onClose={onClose}
                        onSelectPharmacy={async (pharmacy) => {
                          const result = await callUpdatePharmacy({
                            pharmacy_id: pharmacy?.pharmacy_id,
                            address: pharmacy?.address,
                          });
                          if (result && result.error === false) {
                            refetch();
                          }
                        }}
                      />
                    ),
                    data: null,
                  });
                }}
                className="border border-orange rounded-xl"
              >
                <p className="text-2xl font-bold py-2 px-12 text-orange">
                  Add Pharmacy
                </p>
              </button>
            </div>
          ) : (
            <div className="rounded-xl p-5 border-1/4 border-borderColor bg-white">
              <PharmacyCard
                loading={loading || updatePharmacyLoading}
                onClickEdit={() => {
                  if (loading || updatePharmacyLoading) {
                    return null;
                  } else {
                    setShowModal({
                      state: true,
                      children: (_, onClose) => (
                        <PharmacyMapView
                          onClose={onClose}
                          onSelectPharmacy={async (pharmacy) => {
                            const result = await callUpdatePharmacy({
                              pharmacy_id: pharmacy?.pharmacy_id,
                              address: pharmacy?.address,
                            });
                            if (result && result.error === false) {
                              refetch();
                            }
                          }}
                        />
                      ),
                      data: null,
                    });
                  }
                }}
                pharmacyAddess={profileDetails?.basic_info?.pharmacy}
              />
            </div>
          )}
        </ProfileAccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <ProfileAccordionHeader
          headerTitle={`Children ${
            children?.length ? `(${children?.length})` : ""
          }`}
          isActive={isActive}
        />
      ),
      content: (
        <ProfileAccordionContent>
          {children?.length ? (
            <button
              onClick={() => {
                setShowModal({
                  state: true,
                  children: (_, onClose) => (
                    <AddChildModal
                      onClose={onClose}
                      onComplete={() => {
                        refetch();
                      }}
                    />
                  ),
                  data: null,
                });
              }}
              className="border border-orange rounded-xl"
            >
              <p className="text-2xl font-bold py-2 px-12 text-orange">
                Add Child
              </p>
            </button>
          ) : null}
          <Spacer height={20} />
          {children?.length ? (
            children?.map((child, index) => {
              return (
                <div
                  key={index}
                  className={`rounded-xl p-8 border-1/4 border-borderColor bg-white ${
                    index === children?.length - 1 ? "" : "mb-4"
                  }`}
                >
                  <h3 className="text-2xl font-bold opacity-50">Name</h3>
                  <p className="text-3xl font-medium pt-2">
                    {child?.first_name + " " + child?.last_name}
                  </p>
                  <Spacer height={22} />
                  <div className="flex">
                    <div className="flex-1">
                      <h3 className="text-2xl font-bold opacity-50">
                        Gender at Birth
                      </h3>
                      <p className="text-3xl font-medium pt-2">
                        {child?.gender ? capitalize(child?.gender) : "-"}
                      </p>
                    </div>
                    <div className="flex-1">
                      <h3 className="text-2xl font-bold opacity-50">DOB</h3>
                      <p className="text-3xl font-medium pt-2">
                        {moment(child?.dob, "YYYY MM DD").format(
                          "DD MMM, YYYY"
                        )}
                      </p>
                    </div>
                  </div>
                  {child?.height && child?.weight ? (
                    <>
                      <Spacer height={22} />
                      <div className="flex">
                        <div className="flex-1">
                          <h3 className="text-2xl font-bold opacity-50">
                            Height
                          </h3>
                          <p className="text-3xl font-medium pt-2">
                            {child?.height_metric === 0
                              ? child?.height
                              : decimalRestriction({
                                  number: child?.height / 12,
                                })}{" "}
                            {child?.height_metric === 0 ? "cm" : "ft"}
                          </p>
                        </div>
                        <div className="flex-1">
                          <h3 className="text-2xl font-bold opacity-50">
                            Weight
                          </h3>
                          <p className="text-3xl font-medium pt-2">
                            {child?.weight}{" "}
                            {child?.weight_metric === 1 ? "kgs" : "lbs"}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {child?.allergies?.length ? (
                    <>
                      <Spacer height={22} />
                      <h3 className="text-2xl font-bold opacity-50">
                        Allergies
                      </h3>
                      <p className="text-3xl font-medium pt-2">
                        {child?.allergies?.toString()?.split(",")?.join(", ")}
                      </p>
                    </>
                  ) : null}
                  {child?.medications?.length ? (
                    <>
                      <Spacer height={22} />
                      <h3 className="text-2xl font-bold opacity-50">
                        Medications
                      </h3>
                      <p className="text-3xl font-medium pt-2">
                        {child?.medications?.toString()?.split(",")?.join(", ")}
                      </p>
                    </>
                  ) : null}
                </div>
              );
            })
          ) : (
            <div className="rounded-xl p-8 border-1/4 border-borderColor bg-white flex flex-col items-center">
              <p className="text-2xl font-light">
                You didn’t add any children.
              </p>
              <Spacer height={22} />
              <button
                onClick={() => {
                  setShowModal({
                    state: true,
                    children: (_, onClose) => (
                      <AddChildModal
                        onClose={onClose}
                        onComplete={() => {
                          refetch();
                        }}
                      />
                    ),
                    data: null,
                  });
                }}
                className="border border-orange rounded-xl"
              >
                <p className="text-2xl font-bold py-2 px-12 text-orange">
                  Add Child
                </p>
              </button>
            </div>
          )}
        </ProfileAccordionContent>
      ),
    },
  ];
  return <Accordion data={accordionData} />;
}

export default ProfileBottomTab;
