import React from "react";
import Images from "assets/images";

function VisitSummaryHeader({ isActive, title, isLast }) {
  return (
    <div
      className={`flex items-center justify-between py-8 ${
        !(isActive || isLast) && "border-b border-borderColor"
      }`}
    >
      <div className="flex items-center">
        <h2 className="text-2.5xl font-medium text-left">{title}</h2>
      </div>
      <div>
        <img
          src={Images.dropdown}
          alt="chev_down"
          className={`transition duration-500 ease-in-out w-10 h-10 transform ${
            isActive ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>
    </div>
  );
}

export default VisitSummaryHeader;
