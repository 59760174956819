import React, { useEffect, useState } from "react";
import VisitSummaryAccordion from "components/visitSummaryAccordion/VisitSummaryAccordion";
import BasicDetailsForDermSummary from "./BasicDetailsForDermSummary";
import VisitDetailsForDermSummary from "./VisitDetailsForDermSummary";
import PhotosSummary from "./PhotosSummary";
import SymptomsSummary from "./SymptomsSummary";
import MedicalHistorySummary from "./MedicalHistorySummary";
import Images from "assets/images";
import DOMPurify from "dompurify";
function SubHeader({ title, isActive }) {
  return (
    <div className={`flex items-center justify-between py-6`}>
      <div className="flex items-center">
        <h2 className="text-2xl font-medium ">{title}</h2>
      </div>
      <div className="block md:hidden">
        <img
          src={Images.dropdown}
          alt="chev_down"
          className={`transition duration-500 ease-in-out w-10 h-10 transform ${
            isActive ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>
    </div>
  );
}
function DermatologyVisitSummary({
  readOnly,
  data,
  className = "bg-alternativeWhite",
}) {
  const [terms, setTerms] = useState([]);

  useEffect(() => {
    setTerms(data?.code_of_conduct);
  }, [data]);

  const accordionData = [
    readOnly
      ? null
      : {
          header: (props) => (
            <SubHeader
              {...props}
              title={"1. Basic Details"}
              readOnly={readOnly}
            />
          ),
          className: "hover:bg-gray-100 px-4 my-2",
          content: (
            <BasicDetailsForDermSummary readOnly={readOnly} data={data} />
          ),
          contentClass: "px-4",
          name: "basic_details",
        },
    {
      header: (props) => <SubHeader {...props} title={"2. Visit Details"} />,
      className: "hover:bg-gray-100 px-4 my-2",
      contentClass: "px-4",

      content: <VisitDetailsForDermSummary readOnly={readOnly} data={data} />,
      name: "visit_details",
    },
    {
      header: (props) => <SubHeader {...props} title={"3. Photos"} />,
      className: "hover:bg-gray-100 px-4 my-2",
      contentClass: "px-4",

      content: <PhotosSummary readOnly={readOnly} data={data} />,
      name: "photos",
    },
    {
      header: (props) => <SubHeader {...props} title={"4. Symptoms"} />,
      className: "hover:bg-gray-100 px-4 my-2",
      contentClass: "px-4",

      content: <SymptomsSummary readOnly={readOnly} data={data} />,
      name: "symptoms",
    },
    {
      header: (props) => <SubHeader {...props} title={"5. Medical History"} />,
      className: "hover:bg-gray-100 px-4 my-2",

      content: <MedicalHistorySummary readOnly={readOnly} data={data} />,
      contentClass: "px-4",

      name: "medical_history",
    },
    {
      header: (props) => (
        <SubHeader
          {...props}
          title={"6. SkyMD's Code of Conduct"}
          showBorder={false}
        />
      ),
      className: "hover:bg-gray-100 px-4 my-2",
      contentClass: "px-4",
      content: (
        <div>
          {terms?.map((term, index) => {
            return (
              <div className="py-10" key={"terms" + index}>
                <div className="flex">
                  <img
                    className="w-8 h-8 mt-2"
                    src={Images.checkedOrange}
                    alt="checked"
                  />
                  <p
                    className="pt-1 px-5 text-2xl font-light leading-8"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(term),
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ),
      name: "coc",
    },
  ];
  return (
    <div className={className}>
      <div className="wrapper">
        {readOnly ? null : (
          <h2 className="pt-8 px-4 md:px-12 pb-4 text-3xl font-bold">
            Here is your Visit Summary
          </h2>
        )}
        <VisitSummaryAccordion accordionData={accordionData} />
      </div>
    </div>
  );
}

export default DermatologyVisitSummary;
