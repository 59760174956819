import React from "react";
import Images from "assets/images";

function ImagePickerTile({ imageUrl, handleChange, id }) {
  return (
    <>
      <input
        id={`case_image_picker${id ?? ""}`}
        type="file"
        accept="image/*"
        onChange={handleChange}
        className="hidden"
      />
      <label
        htmlFor={`case_image_picker${id ?? ""}`}
        className="relative flex items-center justify-center w-40 h-40 overflow-hidden bg-white border-2 cursor-pointer md:w-56 md:h-56 rounded-3xl border-orange"
      >
        {imageUrl?.length ? (
          <>
            <img
              className="object-contain w-40 h-40 md:w-56 md:h-56 rounded-3xl"
              src={imageUrl}
              alt="case_image"
            />
            <div className="absolute w-full h-full bg-black bg-opacity-30" />
          </>
        ) : null}
        {imageUrl?.length ? null : (
          <img
            className="absolute w-16 h-16"
            src={Images.addIcon}
            alt="add_image"
          />
        )}
        {imageUrl?.length ? (
          <img
            className="absolute w-16 h-16 rounded-full shadow-2xl"
            src={Images.editIcon}
            alt="add_image"
          />
        ) : null}
      </label>
    </>
  );
}

export default ImagePickerTile;
