import ApiConfig from "api/config";

const getPresignedPhotoUrlAction = ({ payload }) => ({
  method: "POST",
  endpoint: ApiConfig.urls.imageUpload.getPresignedPhotoUrl,
  body: payload,
});

const getCaseImagesPresignedPhotoUrlAction = ({
  caseId,
  imageType,
  extension,
}) => ({
  method: "GET",
  endpoint: ApiConfig.urls.imageUpload.getCaseImagesPresignedUrl(
    caseId,
    imageType,
    extension
  ),
});

const uploadPhotoIdToS3Action = ({ endpoint, payload, headers }) => ({
  method: "PUT",
  body: payload,
  endpoint,
  headers: headers ?? {
    "Content-Type": "image/jpeg",
  },
});

const uploadAudioToS3Action = ({ endpoint, payload }) => ({
  method: "PUT",
  body: payload,
  endpoint,
  headers: {
    "Content-Type": "audio/mp4",
  },
});

const updatePhotoIdAction = ({ payload }) => ({
  method: "POST",
  endpoint: ApiConfig.urls.imageUpload.uploadPhotoId,
  body: payload,
});

const imageUploadErrorReport = ({ patientId, payload }) => ({
  method: "POST",
  endpoint: ApiConfig.urls.imageUpload.imageUploadErrorReport(patientId),
  body: payload,
});
const successImageUploadReport = ({ id, payload }) => ({
  method: "PUT",
  endpoint: ApiConfig.urls.imageUpload.successImageTrack(id),
  body: payload,
});

export {
  getPresignedPhotoUrlAction,
  uploadPhotoIdToS3Action,
  updatePhotoIdAction,
  getCaseImagesPresignedPhotoUrlAction,
  uploadAudioToS3Action,
  imageUploadErrorReport,
  successImageUploadReport,
};
