import React, { createContext, useState, useMemo, useRef } from "react";

export const VisitStepChangeContext = createContext({});

function VisitStepChangeProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [refetchIncomplete, setRefetchIncomplete] = useState(false);
  const [translateX, setTranslateX] = useState();
  const rightButtonClickRef = useRef();

  const value = useMemo(
    () => ({
      loading,
      setLoading,
      refetchIncomplete,
      setRefetchIncomplete,
      rightButtonClickRef,
      translateX,
      setTranslateX,
    }),
    [
      loading,
      setLoading,
      refetchIncomplete,
      setRefetchIncomplete,
      rightButtonClickRef,
      translateX,
      setTranslateX,
    ]
  );

  return (
    <VisitStepChangeContext.Provider value={value}>
      {children}
    </VisitStepChangeContext.Provider>
  );
}

export default VisitStepChangeProvider;
