import React from "react";
import Images from "assets/images";
import "./CheckBoxCard.scss";

function CheckBoxCard({ options, infos, showInfo, selected, onSelect }) {
  return (
    <div className="check_box_card_container">
      {options?.map((el, index) => {
        return (
          <div
            key={index}
            className={`check_box_card rounded-3xl cursor-pointer flex items-center justify-between p-8 border ${
              selected?.includes(el) ? "border-orange" : "border-borderColor"
            } ${index !== options?.length - 1 ? "mb-8" : ""}`}
            onClick={() => onSelect(el)}
          >
            <p className="font-bold text-2xl text-indigo">
              {el}
              {infos?.length && infos?.[index]?.length ? (
                <span
                  onClick={() =>
                    showInfo({ label: options?.[index], value: infos?.[index] })
                  }
                  className="bg-indigo text-white font-semibold rounded-full w-8 h-8 inline-block text-center bg-opacity-40 ml-3 cursor-pointer"
                >
                  ?
                </span>
              ) : null}
            </p>
            <img
              className="h-10 w-10"
              src={
                selected?.includes(el)
                  ? Images.checkedOrange
                  : Images.uncheckedCircle
              }
              alt="unchecked"
            />
          </div>
        );
      })}
    </div>
  );
}

export default CheckBoxCard;
