import classNames from "classnames";
import React from "react";
import { Icon } from "stories/Icons";

function SelectProvider({
  className,
  providerType,
  onSelect,
  selected,
  disabled = false,
}) {
  return (
    <div
      className={classNames(
        "bg-white rounded-xl p-6 flex flex-col  overflow-x-hidden",
        "border-2 border-t-8  ring-0 focus:ring-0 focus:shadow-none active:shadow-none",
        // selected ? "text-grey-500 cursor-not-allowed" : "text-black",
        selected ? "border-orange" : "border-grey-600",
        disabled ? "text-grey-500 cursor-not-allowed" : "text-black",
        className
      )}
      onClick={onSelect}
    >
      <div className="flex items-start space-x-4">
        <div>
          <Icon
            className="cursor-pointer"
            name={selected ? "radioActive" : "radioInactive"}
          />
        </div>
        <div>
          <h2 className="text-3xl font-bold">{`Select a ${providerType} Provider`}</h2>
          <p
            className={classNames(
              "font-light text-2xl  leading-8 mt-2 text-themeBlack"
            )}
          >
            See doctors in your area
          </p>
        </div>
      </div>
    </div>
  );
}

export default SelectProvider;
