export const medicalHistoryData = [
  "Excessive Sun Exposure",
  "High Carb Diet",
  "Indoor Tanning",
  "Smoker",
  "Stress",
  "Travel Abroad",
  "None",
];

export const femaleMedicalHistoryData = [
  "Breastfeeding",
  "Irregular Periods",
  "Oral Contraception",
  "Postmenopausal",
  "Pregnant",
  "Trying to Become Pregnant",
  "None",
];

export const femaleChildMedicalHistoryData = [
  "Breastfeeding",
  "Irregular Periods",
  "Oral Contraception",
  "Pregnant",
  "None",
];