import React, { useEffect, useState } from "react";
import Images from "assets/images";
import Header from "components/header/Header";
import SearchInput from "components/inputs/SearchInput";
import { searchMedicalConditionAction } from "api/actions/UserActions";
import { useApiQuery } from "hooks/useApiQuery";
import useDebounce from "hooks/useDebounce";
import Spacer from "components/Spacer/Spacer";
import useToastify from "hooks/useToastify";

function HealthProfileHistoryDebounceModal({
  onClose,
  title,
  keyToSearch,
  selected,
  onSelect,
}) {
  const [searchText, setSearchText] = useState("");
  const debouncedSearchTerm = useDebounce(searchText, 500);

  const [dataFromApi, setDataFromApi] = useState([]);

  const { showAlert } = useToastify();

  const {
    loading,
    payload: result,
    query: callSearch,
    error: searchResultError,
  } = useApiQuery(
    searchMedicalConditionAction({
      condition: keyToSearch,
      searchText: debouncedSearchTerm,
      type: "profile",
    }),
    false
  );

  useEffect(() => {
    if (debouncedSearchTerm && keyToSearch) {
      callSearch();
    } else {
      setDataFromApi([]);
    }
  }, [debouncedSearchTerm, callSearch, keyToSearch]);

  useEffect(() => {
    if (searchResultError) {
      showAlert("Something went wrong", "error");
      setDataFromApi([]);
    } else {
      if (result && result?.length) {
        setDataFromApi(result?.map((el) => el?.name));
      } else {
        setDataFromApi([]);
      }
    }
  }, [result, searchResultError, showAlert]);

  useEffect(() => {
    if (selected?.length) {
      setSearchText(selected);
    }
  }, [selected]);

  return (
    <div className="bg-veryLightBlue">
      <Header
        title={title}
        leftActionView={
          <img
            onClick={onClose}
            className="w-8 cursor-pointer"
            src={Images.closeIcon}
            alt={"close_icons"}
          />
        }
      />
      <div className="wrapper bg-white">
        <div className="border-b border-borderColor">
          <div className="py-6 px-12">
            <SearchInput
              onKeyPress={(e) => {
                if (e?.key === "Enter") {
                  if (searchText?.trim()?.length) {
                    onSelect(searchText);
                    onClose();
                  }
                }
              }}
              value={searchText}
              loading={loading}
              onChange={(event) => setSearchText(event?.target?.value?.trim())}
            />
            {searchText?.trim()?.length ? (
              <>
                <Spacer height={20} />
                <h3
                  onClick={() => {
                    if (searchText?.trim()?.length) {
                      onSelect(searchText);
                      onClose();
                    }
                  }}
                  className={"text-2xl font-bold pl-2 cursor-pointer"}
                >
                  Select "{searchText}"
                </h3>
              </>
            ) : null}
          </div>
        </div>
        <div className="overflow-y-scroll px-0 py-8 pb-64 h-eliminateHeaderWithChipAndAddContainer bg-alternativeWhite">
          <div className="px-12">
            {dataFromApi?.length
              ? dataFromApi?.map((part, index) => {
                  return (
                    <div
                      onClick={() => {
                        onSelect(part);
                        onClose();
                      }}
                      key={index}
                      className="bg-white border border-borderColor rounded-1.6 p-8 px-12 mb-5 cursor-pointer"
                    >
                      <p className="text-2xl font-bold">{part}</p>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HealthProfileHistoryDebounceModal;
