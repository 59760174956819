import React from "react";
import Images from "assets/images";
import classNames from "classnames";

function SmallLoader({ showLoader, className }) {
  if (showLoader?.state) {
    return (
      <div
        className={classNames(
          "flex flex-col items-center justify-center w-full p-10 bg-white",
          className
        )}
      >
        <h2 className="text-4xl font-medium text-center">
          {showLoader?.message ?? "Please wait"}
        </h2>
        <img className="w-40" src={Images.appLoader} alt={"Loading"} />
      </div>
    );
  } else {
    return null;
  }
}

export default SmallLoader;
