import React, { useEffect, useState } from "react";
import useModal from "hooks/useModal";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import Card from "components/visitCommon/Card";
import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import Button from "components/buttons/Button";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useVisitStepChange from "hooks/useVisitStepChange";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import { dermatology_sub_steps, speciality } from "helpers/enum";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import useCreateDermatologyVisit from "hooks/visit/useCreateDermatologyVisit";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import AddSomeoneElseModalDerm from "../../common/AddSomeoneElseModalDerm";

function SelectSomeoneElse() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const { setShowModal } = useModal();
  const [selected, setSelected] = useState(null);
  const { patientsList, incompleteData, subSteps } = useDermatologyVisitFlow();
  const currentScreenValueFromStore =
    subSteps[dermatology_sub_steps.select_someone_else].value;

  const { createNewVisit, creatingVisit } = useCreateDermatologyVisit();
  const { updateVisit, updatingVisit } = useUpdateDermatologyVisit();

  useEffect(() => {
    if (currentScreenValueFromStore) {
      setSelected(currentScreenValueFromStore);
    }
  }, [currentScreenValueFromStore, patientsList]);

  useEffect(() => {
    if (selected?.length) {
      dispatch(
        dermaVisitFlowReducers.select_someone_else({
          isValid: true,
        })
      );
    } else {
      dispatch(
        dermaVisitFlowReducers.select_someone_else({
          isValid: false,
        })
      );
    }
  }, [dispatch, selected]);

  function onRightButtonClick() {
    if (incompleteData && Object.keys(incompleteData)?.length) {
      updateVisit({
        payload: {
          case: {
            id: incompleteData?.id,
            patient_id: patientsList?.find((el) => el?.full_name === selected)
              ?.id,
            dermatology_steps: incompleteData?.dermatology_steps,
          },
        },
        change_current_screen: false,
        screen_to_complete: null,
        callBack: () => {
          dispatch(
            dermaVisitFlowReducers.select_someone_else({
              value: selected,
            })
          );
          history.push("/visit/dermatology/pricing");
        },
      });
    } else {
      createNewVisit({
        patient_id: patientsList?.find((el) => el?.full_name === selected)?.id,
        change_current_screen: false,
        screen_to_complete: null,
        callBack: () => {
          dispatch(
            dermaVisitFlowReducers.select_someone_else({
              value: selected,
            })
          );
          history.push("/visit/dermatology/pricing");
        },
      });
    }
  }

  return (
    <StepScreenContainer align="start">
      <MedicalAssistant content={<p>Please, select patient.</p>} />
      <Card className={"space-y-8"}>
        <RadioButtonCard
          loading={creatingVisit}
          options={patientsList
            ?.filter(
              (patient) =>
                patient?.relationship_with_account &&
                patient.relationship_with_account !== "myself" &&
                patient.relationship_with_account !== "pappa"
            )
            ?.map((el) => el?.full_name)}
          onSelect={setSelected}
          selected={selected}
        />
        <Button
          disabled={updatingVisit}
          onClick={() => {
            setShowModal({
              state: true,
              children: (_, onClose) => (
                <AddSomeoneElseModalDerm
                  onClose={(e) => {
                    if (e !== null) {
                      setSelected(e);
                    }
                    onClose();
                  }}
                  currentSpeciality={speciality.dermatology}
                />
              ),
              data: null,
            });
          }}
          label="Add Patient"
          outlined
        />
      </Card>
    </StepScreenContainer>
  );
}

export default SelectSomeoneElse;
