/**
 * Converts the first character of string to upper case and the remaining to lower case.
 * @param {string} string - The string to capitalize
 * @returns {string} Returns the capitalized string
 */
const capitalize = (string) => {
  return string
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : "";
};

/**
 * Checks if value is empty. A value is considered empty unless it’s an arguments object, array, string, or
 * jQuery-like collection with a length greater than 0 or an object with own enumerable properties.
 *
 * @param value The value to inspect.
 * @return Returns true if value is empty, else false.
 */
const isEmpty = (value) =>
  [Object, Array].includes(value.constructor) && !Object.entries(value).length;

export { capitalize, isEmpty };
