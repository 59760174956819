import React, { useCallback, useEffect, useState } from "react";
import useTimer from "hooks/useTimer";
import RecordBlock from "./RecordBlock";
import Images from "assets/images";

let mediaRecorder;

function AudioRecorder({ audioResult, setAudioResult, onClickDelete }) {
  const [status, setStatus] = useState(null);

  const {
    timer,
    handleStart,
    handlePause,
    handleResume,
    handleReset,
  } = useTimer(0);

  function startRecord() {
    if (status === "recording") {
      mediaRecorder.pause();
      handlePause();
      setStatus("paused");
    } else {
      let dataArray = [];
      if (status === "paused") {
        mediaRecorder.resume();
        handleResume();
        setStatus("recording");
      } else {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((mediaStreamObj) => {
            mediaRecorder = new MediaRecorder(mediaStreamObj);
            mediaRecorder.start();
            handleStart();
            setStatus("recording");
            mediaRecorder.ondataavailable = (ev) => {
              dataArray.push(ev.data);
            };
            mediaRecorder.onstop = () => {
              let audioData = new Blob(dataArray, { type: "audio/wav;" });
              dataArray = [];
              setAudioResult(window.URL.createObjectURL(audioData));
            };
          })
          .catch(function (err) {
            window.alert(err?.message);
          });
      }
    }
  }

  const stopRecording = useCallback(() => {
    mediaRecorder.stop();
    handleReset();
    setStatus("stopped");
  }, [handleReset]);

  useEffect(() => {
    if (timer && Number(timer) > 179) {
      stopRecording();
    }
  }, [timer, stopRecording]);

  return (
    <div className="audio_recorder_player_container">
      {audioResult ? (
        <div className="w-full flex flex-wrap items-center">
          <audio
            className="flex-1 md:flex-none"
            controls
            src={audioResult}
            controlsList="nodownload"
          />
          <img
            onClick={onClickDelete}
            className="w-16 h-16 ml-2 cursor-pointer"
            src={Images.deleteIcon}
            alt="delete"
          />
        </div>
      ) : (
        <>
          <RecordBlock
            onClickRecordPause={startRecord}
            onClickStopRecord={stopRecording}
            status={status}
            timer={timer}
          />
          <p className="text-red text-lg mt-8 font-medium">
            *Maximum 3 minutes
          </p>
        </>
      )}
    </div>
  );
}

export default AudioRecorder;
