import classNames from "classnames";
import CircularImageComponent from "components/imageComponent/CircularImageComponent";
import { Icon } from "stories/Icons";
import startCase from "lodash.startcase";

const DermaProvider = ({ details }) => {
  const isExpired =
    !details.active || !details.license || !details.license.license;
  const reviewCount = details?.rating?.value ?? 0;
  return (
    <div
      className={classNames(
        "grid w-full p-6 bg-white border gap-y-8 xl:gap-y-0 xl:gap-x-20 xl:grid-cols-2 rounded-xl"
      )}
    >
      <div className="w-full p-4 border border-gray-200 rounded-lg md:p-10">
        <div className="flex">
          <div className="flex flex-col items-center space-y-4 md:justify-center">
            <CircularImageComponent
              url={details?.provider_photo_url}
              size="responsive"
            />
            {/* <button className="text-2xl font-medium text-secondaryBlue-500">
            View Profile
          </button> */}
          </div>
          <div className="flex flex-col items-start justify-center pl-4 space-y-12 md:p-8">
            <div className="flex flex-col items-start space-y-4">
              <h2 className="text-2.5xl font-bold">{details?.provider_name}</h2>
              <p className="text-xl font-medium">{details?.speciality}</p>
            </div>
            <div className="flex-col items-start hidden space-y-4 md:flex">
              {details.gender && (
                <p className="text-xl font-medium text-themeBlack">
                  {"Gender: "}
                  <span className="text-black">
                    {startCase(details.gender)}
                  </span>
                </p>
              )}
              <div className="flex items-center space-x-3">
                {details.license && (
                  <p className="text-xl font-medium text-themeBlack">
                    <span className="text-black">
                      {details.license?.state} License :{" "}
                    </span>
                    {details.license?.license}
                  </p>
                )}
                {isExpired && (
                  <p
                    className="p-2 text-xl font-medium rounded-md text-errorRed bg-red"
                    style={{ backgroundColor: "#FFEBEB" }}
                  >
                    License Expired
                  </p>
                )}
              </div>
              {/* <p
              className={classNames(
                "flex items-center space-x-2 text-xl font-medium text-green",
                inNetwork ? "text-green" : "text-errorRed"
              )}
            >
              <Icon name="shield" />{" "}
              <span className={!inNetwork ? "text-errorRed" : "text-green"}>
                {inNetwork ? "In-network •" : "Out-of-Network •"}
              </span>
              <span className="text-themeBlack">{"Health Insurance"}</span>
            </p> */}
              {reviewCount > 0 && (
                <div className="flex items-center space-x-2">
                  <span className="flex mr-2">
                    {[...Array(5)].map((_, index) => (
                      <Icon
                        key={`overall-ratings-${index}`}
                        className={classNames(
                          "w-5 h-5",
                          Math.round(reviewCount) > index
                            ? "text-yellow-450"
                            : "text-grey-600"
                        )}
                        name="star"
                      />
                    ))}
                  </span>
                  <span className="text-xl font-medium ">
                    {Number(reviewCount)?.toFixed(1)}
                  </span>

                  <span className="text-xl font-medium text-themeBlack">
                    {`(${details?.rating.user_count} verified ${
                      details?.rating.user_count > 1 ? "reviews" : "review"
                    })`}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start pt-4 space-y-4 md:hidden">
          {details.gender && (
            <p className="text-xl font-medium text-themeBlack">
              {"Gender: "}
              <span className="text-black">{startCase(details.gender)}</span>
            </p>
          )}
          <div className="flex items-center space-x-3">
            {details.license && (
              <p className="text-xl font-medium text-themeBlack">
                <span className="text-black">
                  {details.license?.state} License :{" "}
                </span>
                {details.license?.license}
              </p>
            )}
            {isExpired && (
              <p
                className="p-2 text-xl font-medium rounded-md text-errorRed bg-red"
                style={{ backgroundColor: "#FFEBEB" }}
              >
                License Expired
              </p>
            )}
          </div>
          {details?.rating?.value && (
            <div className="flex items-center space-x-2">
              <span className="flex mr-2">
                {[...Array(5)].map((_, index) => (
                  <Icon
                    key={`overall-ratings-${index}`}
                    className={classNames(
                      "w-5 h-5",
                      Math.round(details?.rating.value) > index
                        ? "text-yellow-450"
                        : "text-grey-600"
                    )}
                    name="star"
                  />
                ))}
              </span>
              <span className="text-xl font-medium ">
                {details?.rating.value}
              </span>

              <span className="text-xl font-medium text-themeBlack">
                {`(${details?.rating.user_count} verified ${
                  details?.rating.user_count > 1 ? "reviews" : "review"
                })`}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col justify-between w-full p-6 space-y-6 border border-gray-200 rounded-md md:p-10">
        <div className="flex flex-col w-full space-y-2 md:space-y-6">
          <h3 className="text-2.5xl font-bold">Your Dermatology provider</h3>
          <p className="text-2xl font-medium text-themeBlack">
            Hi, I'm your skin doctor. Feel free to contact me if you need any
            help with your skin health.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DermaProvider;
