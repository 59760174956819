const HomeIcon = ({ className }) => (
  <svg
    width="20"
    height="20"
    className={className}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_242_1289)">
      <path
        d="M10 12.4938C8.6193 12.4938 7.5 13.6131 7.5 14.9938V19.9938H12.5V14.9938C12.5 13.6131 11.3807 12.4938 10 12.4938Z"
        fill="currentColor"
      />
      <path
        d="M14.1667 14.9938V19.9938H17.5C18.8807 19.9938 20 18.8745 20 17.4938V9.89295C20.0002 9.46002 19.832 9.04401 19.5308 8.73295L12.4492 1.07709C11.1996 -0.27486 9.09074 -0.357868 7.73879 0.891663C7.67457 0.951038 7.6127 1.01287 7.55336 1.07709L0.48418 8.73045C0.173945 9.0428 -0.000117128 9.46522 5.9134e-08 9.90545V17.4938C5.9134e-08 18.8745 1.1193 19.9938 2.5 19.9938H5.83332V14.9938C5.84891 12.7214 7.68355 10.8658 9.89867 10.8124C12.1879 10.7571 14.1492 12.6444 14.1667 14.9938Z"
        fill="currentColor"
      />
      <path
        d="M10 12.4938C8.6193 12.4938 7.5 13.6131 7.5 14.9938V19.9938H12.5V14.9938C12.5 13.6131 11.3807 12.4938 10 12.4938Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_242_1289">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const MyVisitsIcon = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_242_1298)">
      <path
        d="M6.66667 0C5.562 0.00132321 4.50296 0.440735 3.72185 1.22185C2.94073 2.00296 2.50132 3.062 2.5 4.16667V19.1667C2.50012 19.3182 2.54157 19.4669 2.6199 19.5967C2.69823 19.7264 2.81047 19.8324 2.94453 19.9031C3.07859 19.9738 3.22941 20.0066 3.38073 19.998C3.53206 19.9894 3.67817 19.9397 3.80333 19.8542L5.55833 18.655L7.31333 19.8542C7.45199 19.9491 7.61612 19.9999 7.78417 19.9999C7.95222 19.9999 8.11634 19.9491 8.255 19.8542L10.005 18.655L11.755 19.8542C11.8937 19.9493 12.058 20.0002 12.2262 20.0002C12.3945 20.0002 12.5588 19.9493 12.6975 19.8542L14.4475 18.6558L16.1975 19.8533C16.3226 19.9386 16.4685 19.9881 16.6197 19.9966C16.7708 20.0052 16.9214 19.9724 17.0553 19.9018C17.1892 19.8312 17.3013 19.7255 17.3797 19.596C17.458 19.4664 17.4996 19.318 17.5 19.1667V4.16667C17.4987 3.062 17.0593 2.00296 16.2782 1.22185C15.497 0.440735 14.438 0.00132321 13.3333 0L6.66667 0ZM11.6667 11.6667H6.66667C6.44565 11.6667 6.23369 11.5789 6.07741 11.4226C5.92113 11.2663 5.83333 11.0543 5.83333 10.8333C5.83333 10.6123 5.92113 10.4004 6.07741 10.2441C6.23369 10.0878 6.44565 10 6.66667 10H11.6667C11.8877 10 12.0996 10.0878 12.2559 10.2441C12.4122 10.4004 12.5 10.6123 12.5 10.8333C12.5 11.0543 12.4122 11.2663 12.2559 11.4226C12.0996 11.5789 11.8877 11.6667 11.6667 11.6667ZM14.1667 7.5C14.1667 7.72101 14.0789 7.93297 13.9226 8.08926C13.7663 8.24554 13.5543 8.33333 13.3333 8.33333H6.66667C6.44565 8.33333 6.23369 8.24554 6.07741 8.08926C5.92113 7.93297 5.83333 7.72101 5.83333 7.5C5.83333 7.27899 5.92113 7.06702 6.07741 6.91074C6.23369 6.75446 6.44565 6.66667 6.66667 6.66667H13.3333C13.5543 6.66667 13.7663 6.75446 13.9226 6.91074C14.0789 7.06702 14.1667 7.27899 14.1667 7.5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_242_1298">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const ReferralIcon = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.1668 9.16675H17.5001V7.50007C17.5001 7.03983 17.127 6.66675 16.6668 6.66675C16.2066 6.66675 15.8335 7.03983 15.8335 7.50007V9.16675H14.1668C13.7066 9.16675 13.3335 9.53983 13.3335 10.0001C13.3335 10.4603 13.7066 10.8334 14.1668 10.8334H15.8335V12.5001C15.8335 12.9603 16.2066 13.3334 16.6668 13.3334C17.127 13.3334 17.5001 12.9603 17.5001 12.5001V10.8334H19.1668C19.627 10.8334 20.0001 10.4603 20.0001 10.0001C20.0001 9.53983 19.627 9.16675 19.1668 9.16675Z"
      fill="currentColor"
    />
    <path
      d="M7.5 10C10.2614 10 12.5 7.76142 12.5 5C12.5 2.23858 10.2614 0 7.5 0C4.73858 0 2.5 2.23858 2.5 5C2.5 7.76142 4.73858 10 7.5 10Z"
      fill="currentColor"
    />
    <path
      d="M7.5 11.6667C3.35977 11.6714 0.00460937 15.0265 0 19.1667C0 19.627 0.373086 20.0001 0.83332 20.0001H14.1666C14.6269 20.0001 15 19.627 15 19.1667C14.9954 15.0265 11.6402 11.6713 7.5 11.6667Z"
      fill="currentColor"
    />
  </svg>
);
const MyProfileIcon = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 10C12.7614 10 15 7.76142 15 5C15 2.23858 12.7614 0 10 0C7.23858 0 5 2.23858 5 5C5 7.76142 7.23858 10 10 10Z"
      fill="currentColor"
    />
    <path
      d="M10 11.6667C5.85977 11.6714 2.50461 15.0265 2.5 19.1667C2.5 19.627 2.87309 20.0001 3.33332 20.0001H16.6666C17.1269 20.0001 17.5 19.627 17.5 19.1667C17.4954 15.0265 14.1402 11.6713 10 11.6667Z"
      fill="currentColor"
    />
  </svg>
);
const GoogleIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="48"
    height="48"
    className={className}
  >
    <path
      fill="#FFC107"
      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
    />
    <path
      fill="#FF3D00"
      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
    />
    <path
      fill="#4CAF50"
      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
    />
    <path
      fill="#1976D2"
      d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
    />
  </svg>
);
const StarIcon = ({ className = "text-yellow-450" }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#FFC107"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9775 15.544L7.99952 11.8853L3.02152 15.544L4.93285 9.6353L-0.0424805 5.99997H6.10019L7.99952 0.0812988L9.89885 5.99997H16.0409L11.0662 9.6353L12.9775 15.544Z"
      fill="currentColor"
    />
  </svg>
);
const RadioInactiveIcon = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="11.5" fill="white" stroke="#B5B5B5" />
    <circle cx="12" cy="12" r="8" fill="#F0F0F0" />
  </svg>
);
const RadioActiveIcon = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="11.5" fill="white" stroke="#FF8648" />
    <circle cx="12" cy="12" r="8" fill="#FF8648" />
  </svg>
);
const CalenderIcon = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_248_2272)">
      <path
        d="M24 7V8H0V7C0 4.239 2.239 2 5 2H6V1C6 0.448 6.448 0 7 0C7.552 0 8 0.448 8 1V2H16V1C16 0.448 16.448 0 17 0C17.552 0 18 0.448 18 1V2H19C21.761 2 24 4.239 24 7ZM24 17C24 20.86 20.859 24 17 24C13.141 24 10 20.86 10 17C10 13.14 13.141 10 17 10C20.859 10 24 13.14 24 17ZM19 17.586L18 16.586V15C18 14.448 17.552 14 17 14C16.448 14 16 14.448 16 15V17C16 17.265 16.105 17.52 16.293 17.707L17.586 19C17.976 19.39 18.61 19.39 19 19C19.39 18.61 19.39 17.976 19 17.586ZM8 17C8 14.171 9.308 11.65 11.349 10H0V19C0 21.761 2.239 24 5 24H11.349C9.308 22.35 8 19.829 8 17Z"
        fill="#141414"
      />
    </g>
    <defs>
      <clipPath id="clip0_248_2272">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const CloseIcon = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9998 5.99982C17.8123 5.81235 17.558 5.70703 17.2928 5.70703C17.0277 5.70703 16.7733 5.81235 16.5858 5.99982L11.9998 10.5858L7.41382 5.99982C7.22629 5.81235 6.97198 5.70703 6.70682 5.70703C6.44165 5.70703 6.18735 5.81235 5.99982 5.99982C5.81235 6.18735 5.70703 6.44165 5.70703 6.70682C5.70703 6.97198 5.81235 7.22629 5.99982 7.41382L10.5858 11.9998L5.99982 16.5858C5.81235 16.7733 5.70703 17.0277 5.70703 17.2928C5.70703 17.558 5.81235 17.8123 5.99982 17.9998C6.18735 18.1873 6.44165 18.2926 6.70682 18.2926C6.97198 18.2926 7.22629 18.1873 7.41382 17.9998L11.9998 13.4138L16.5858 17.9998C16.7733 18.1873 17.0277 18.2926 17.2928 18.2926C17.558 18.2926 17.8123 18.1873 17.9998 17.9998C18.1873 17.8123 18.2926 17.558 18.2926 17.2928C18.2926 17.0277 18.1873 16.7733 17.9998 16.5858L13.4138 11.9998L17.9998 7.41382C18.1873 7.22629 18.2926 6.97198 18.2926 6.70682C18.2926 6.44165 18.1873 6.18735 17.9998 5.99982Z"
      fill="#141414"
    />
  </svg>
);
const CloseCircleIcon = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1249_5054)">
      <path
        d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C15.9952 3.58369 12.4163 0.00478125 8 0ZM10.6667 9.72466C10.9376 9.974 10.9551 10.3958 10.7057 10.6667C10.4564 10.9376 10.0346 10.9551 9.76372 10.7057C9.75016 10.6933 9.73716 10.6802 9.72466 10.6667L8 8.94266L6.276 10.6667C6.01116 10.9224 5.58913 10.9151 5.33334 10.6503C5.08384 10.3919 5.08384 9.98234 5.33334 9.724L7.05734 8L5.33334 6.276C5.07756 6.01116 5.08491 5.58913 5.34975 5.33334C5.60809 5.08384 6.01766 5.08384 6.276 5.33334L8 7.05734L9.72466 5.33334C9.974 5.06244 10.3958 5.04494 10.6667 5.29428C10.9376 5.54363 10.9551 5.96538 10.7057 6.23628C10.6933 6.24984 10.6802 6.26284 10.6667 6.27534L8.94266 8L10.6667 9.72466Z"
        fill="#6E6E6E"
      />
    </g>
    <defs>
      <clipPath id="clip0_1249_5054">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const CameraIcon = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1124_10304)">
      <path
        d="M17.7193 3L16.3063 1.168C16.0249 0.805806 15.6647 0.512417 15.253 0.310054C14.8414 0.107691 14.389 0.00166304 13.9303 0L10.0663 0C9.60764 0.00166304 9.15533 0.107691 8.74368 0.310054C8.33202 0.512417 7.97182 0.805806 7.69034 1.168L6.27734 3H17.7193Z"
        fill="#337AB7"
      />
      <path
        d="M12 18C14.2091 18 16 16.2091 16 14C16 11.7909 14.2091 10 12 10C9.79086 10 8 11.7909 8 14C8 16.2091 9.79086 18 12 18Z"
        fill="#337AB7"
      />
      <path
        d="M19 5H5C3.67441 5.00159 2.40356 5.52888 1.46622 6.46622C0.528882 7.40356 0.00158786 8.67441 0 10L0 19C0.00158786 20.3256 0.528882 21.5964 1.46622 22.5338C2.40356 23.4711 3.67441 23.9984 5 24H19C20.3256 23.9984 21.5964 23.4711 22.5338 22.5338C23.4711 21.5964 23.9984 20.3256 24 19V10C23.9984 8.67441 23.4711 7.40356 22.5338 6.46622C21.5964 5.52888 20.3256 5.00159 19 5ZM12 20C10.8133 20 9.65327 19.6481 8.66658 18.9888C7.67988 18.3295 6.91085 17.3925 6.45672 16.2961C6.0026 15.1997 5.88378 13.9933 6.11529 12.8295C6.3468 11.6656 6.91824 10.5965 7.75736 9.75736C8.59647 8.91824 9.66557 8.3468 10.8295 8.11529C11.9933 7.88378 13.1997 8.0026 14.2961 8.45672C15.3925 8.91085 16.3295 9.67988 16.9888 10.6666C17.6481 11.6533 18 12.8133 18 14C17.9984 15.5908 17.3658 17.116 16.2409 18.2409C15.116 19.3658 13.5908 19.9984 12 20Z"
        fill="#337AB7"
      />
    </g>
    <defs>
      <clipPath id="clip0_1124_10304">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const NotificationIcon = ({ className }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="19.5" fill="white" stroke="#D2D2D2" />
    <g clipPath="url(#clip0_1446_7864)">
      <path
        d="M16.9492 26C17.2077 26.5943 17.634 27.1001 18.176 27.4554C18.7179 27.8107 19.3519 28 19.9999 28C20.6479 28 21.2818 27.8107 21.8238 27.4554C22.3657 27.1001 22.7921 26.5943 23.0506 26H16.9492Z"
        fill="#141414"
      />
      <path
        d="M26.9279 20.3664L25.7705 16.5511C25.3999 15.2167 24.5941 14.0443 23.4811 13.2201C22.3681 12.3959 21.0117 11.9672 19.6271 12.002C18.2426 12.0367 16.9094 12.533 15.8392 13.412C14.7689 14.2911 14.023 15.5024 13.7199 16.8538L12.8212 20.5451C12.7018 21.0356 12.6953 21.5468 12.8023 22.0402C12.9093 22.5336 13.1269 22.9962 13.4388 23.3932C13.7507 23.7901 14.1487 24.1111 14.6027 24.3317C15.0568 24.5524 15.555 24.6671 16.0599 24.6671H23.7379C24.2584 24.6671 24.7716 24.5453 25.2365 24.3113C25.7015 24.0773 26.1051 23.7376 26.4152 23.3196C26.7253 22.9016 26.9332 22.4167 27.0222 21.9039C27.1112 21.3911 27.0789 20.8645 26.9279 20.3664Z"
        fill="#141414"
      />
    </g>
    <defs>
      <clipPath id="clip0_1446_7864">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(12 12)"
        />
      </clipPath>
    </defs>
  </svg>
);
const RightArrow = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9987 10.0001C14.9946 9.56172 14.818 9.14255 14.507 8.83347L10.932 5.25014C10.7759 5.09493 10.5647 5.00781 10.3445 5.00781C10.1244 5.00781 9.91317 5.09493 9.75703 5.25014C9.67892 5.32761 9.61693 5.41978 9.57462 5.52133C9.53232 5.62288 9.51053 5.7318 9.51053 5.84181C9.51053 5.95182 9.53232 6.06074 9.57462 6.16229C9.61693 6.26384 9.67892 6.356 9.75703 6.43347L12.4987 9.16681H4.16536C3.94435 9.16681 3.73239 9.2546 3.57611 9.41088C3.41983 9.56716 3.33203 9.77913 3.33203 10.0001C3.33203 10.2212 3.41983 10.4331 3.57611 10.5894C3.73239 10.7457 3.94435 10.8335 4.16536 10.8335H12.4987L9.75703 13.5751C9.60011 13.731 9.51152 13.9427 9.51074 14.1639C9.50995 14.385 9.59705 14.5974 9.75287 14.7543C9.90868 14.9112 10.1204 14.9998 10.3416 15.0006C10.5627 15.0014 10.7751 14.9143 10.932 14.7585L14.507 11.1751C14.82 10.864 14.9968 10.4415 14.9987 10.0001Z"
      fill="#FF8648"
    />
  </svg>
);
const RightArrowSmall = ({ className }) => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.9974 3.99972C9.99415 3.64898 9.85283 3.31365 9.60406 3.06639L6.74406 0.199721C6.61915 0.075554 6.45019 0.00585938 6.27406 0.00585938C6.09794 0.00585938 5.92897 0.075554 5.80406 0.199721C5.74158 0.261697 5.69198 0.335431 5.65814 0.41667C5.62429 0.49791 5.60686 0.585047 5.60686 0.673055C5.60686 0.761063 5.62429 0.848199 5.65814 0.929439C5.69198 1.01068 5.74158 1.08441 5.80406 1.14639L7.9974 3.33305H1.33073C1.15392 3.33305 0.984349 3.40329 0.859325 3.52832C0.734301 3.65334 0.664063 3.82291 0.664062 3.99972C0.664063 4.17653 0.734301 4.3461 0.859325 4.47113C0.984349 4.59615 1.15392 4.66639 1.33073 4.66639H7.9974L5.80406 6.85972C5.67853 6.98437 5.60765 7.15379 5.60703 7.3307C5.6064 7.50761 5.67608 7.67752 5.80073 7.80305C5.92538 7.92859 6.0948 7.99947 6.27171 8.00009C6.44862 8.00072 6.61853 7.93104 6.74406 7.80639L9.60406 4.93972C9.85445 4.69082 9.99591 4.35277 9.9974 3.99972Z"
      fill="currentColor"
    />
  </svg>
);
const LeftArrowSmall = ({ className }) => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.66667 3.33386H2L4.19333 1.14053C4.25582 1.07855 4.30542 1.00482 4.33926 0.92358C4.37311 0.84234 4.39053 0.755203 4.39053 0.667195C4.39053 0.579187 4.37311 0.49205 4.33926 0.41081C4.30542 0.329571 4.25582 0.255837 4.19333 0.193862C4.06843 0.0696944 3.89946 0 3.72333 0C3.54721 0 3.37824 0.0696944 3.25333 0.193862L0.393333 3.06053C0.142942 3.30943 0.00148853 3.64748 0 4.00053C0.00324439 4.35126 0.144563 4.6866 0.393333 4.93386L3.25333 7.80053C3.31549 7.86225 3.3892 7.91112 3.47025 7.94436C3.55129 7.9776 3.63809 7.99454 3.72569 7.99423C3.81329 7.99392 3.89997 7.97636 3.98078 7.94256C4.06159 7.90875 4.13495 7.85935 4.19667 7.79719C4.25839 7.73504 4.30726 7.66133 4.3405 7.58028C4.37373 7.49923 4.39068 7.41243 4.39037 7.32484C4.39006 7.23724 4.3725 7.15056 4.33869 7.06975C4.30489 6.98894 4.25549 6.91558 4.19333 6.85386L2 4.6672H8.66667C8.84348 4.6672 9.01305 4.59696 9.13807 4.47193C9.2631 4.34691 9.33333 4.17734 9.33333 4.00053C9.33333 3.82372 9.2631 3.65415 9.13807 3.52912C9.01305 3.4041 8.84348 3.33386 8.66667 3.33386Z"
      fill="currentColor"
    />
  </svg>
);
const Shield = ({ className }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1711_6013)">
      <path
        d="M10.8363 1.24847L7.18173 0.0298905C7.06207 -0.00996349 6.93272 -0.00996349 6.81306 0.0298905L3.15848 1.24847C2.57742 1.44149 2.07197 1.81271 1.71394 2.30939C1.35591 2.80607 1.1635 3.40295 1.16406 4.01522V7.00014C1.16406 11.4119 6.53073 13.8485 6.76056 13.95C6.83513 13.9831 6.91581 14.0002 6.9974 14.0002C7.07899 14.0002 7.15967 13.9831 7.23423 13.95C7.46406 13.8485 12.8307 11.4119 12.8307 7.00014V4.01522C12.8313 3.40295 12.6389 2.80607 12.2809 2.30939C11.9228 1.81271 11.4174 1.44149 10.8363 1.24847ZM8.7474 7.00014H7.58073V8.16681C7.58073 8.32152 7.51927 8.46989 7.40988 8.57929C7.30048 8.68868 7.15211 8.75014 6.9974 8.75014C6.84269 8.75014 6.69431 8.68868 6.58492 8.57929C6.47552 8.46989 6.41406 8.32152 6.41406 8.16681V7.00014H5.2474C5.09269 7.00014 4.94431 6.93868 4.83492 6.82929C4.72552 6.71989 4.66406 6.57152 4.66406 6.41681C4.66406 6.2621 4.72552 6.11372 4.83492 6.00433C4.94431 5.89493 5.09269 5.83347 5.2474 5.83347H6.41406V4.66681C6.41406 4.5121 6.47552 4.36372 6.58492 4.25433C6.69431 4.14493 6.84269 4.08347 6.9974 4.08347C7.15211 4.08347 7.30048 4.14493 7.40988 4.25433C7.51927 4.36372 7.58073 4.5121 7.58073 4.66681V5.83347H8.7474C8.90211 5.83347 9.05048 5.89493 9.15988 6.00433C9.26927 6.11372 9.33073 6.2621 9.33073 6.41681C9.33073 6.57152 9.26927 6.71989 9.15988 6.82929C9.05048 6.93868 8.90211 7.00014 8.7474 7.00014Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1711_6013">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Message = ({ className }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2260_8061)">
      <path
        d="M7.58333 -0.00195312H2.91667C1.30842 -0.00195312 0 1.30646 0 2.91471V10.4129C0 10.8755 0.25375 11.299 0.6615 11.5177C0.847 11.6175 1.05058 11.6665 1.25358 11.6665C1.49625 11.6665 1.73833 11.5959 1.94833 11.4565L4.26008 9.91471H7.58333C9.19158 9.91471 10.5 8.6063 10.5 6.99805V2.91471C10.5 1.30646 9.19158 -0.00195312 7.58333 -0.00195312ZM14 5.24805V12.7462C14 13.2088 13.7462 13.6323 13.3385 13.851C13.153 13.9508 12.9494 13.9998 12.7464 14.0004C12.5037 14.0004 12.2617 13.9298 12.0522 13.7904L9.73992 12.248H6.41667C5.57083 12.248 4.81425 11.88 4.28108 11.303L4.613 11.0814H7.58333C9.83442 11.0814 11.6667 9.24913 11.6667 6.99805V2.91471C11.6667 2.73505 11.6509 2.56005 11.6287 2.38621C12.9768 2.64288 14 3.82646 14 5.24805Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2260_8061">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Info = ({ className }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3688_7226)">
      <path
        d="M7 14C8.38447 14 9.73785 13.5895 10.889 12.8203C12.0401 12.0511 12.9373 10.9579 13.4672 9.67879C13.997 8.3997 14.1356 6.99224 13.8655 5.63437C13.5954 4.2765 12.9287 3.02922 11.9497 2.05026C10.9708 1.07129 9.7235 0.404603 8.36563 0.134506C7.00777 -0.13559 5.6003 0.003033 4.32122 0.532846C3.04213 1.06266 1.94888 1.95987 1.17971 3.11101C0.410543 4.26216 0 5.61553 0 7C0.0020073 8.8559 0.74015 10.6352 2.05247 11.9475C3.36479 13.2599 5.1441 13.998 7 14ZM7 2.91667C7.17306 2.91667 7.34223 2.96799 7.48612 3.06413C7.63002 3.16028 7.74217 3.29694 7.8084 3.45682C7.87462 3.61671 7.89195 3.79264 7.85819 3.96237C7.82443 4.13211 7.74109 4.28802 7.61872 4.41039C7.49635 4.53276 7.34044 4.6161 7.1707 4.64986C7.00097 4.68362 6.82504 4.66629 6.66515 4.60006C6.50527 4.53384 6.36861 4.42169 6.27246 4.27779C6.17632 4.1339 6.125 3.96473 6.125 3.79167C6.125 3.55961 6.21719 3.33705 6.38128 3.17295C6.54538 3.00886 6.76794 2.91667 7 2.91667ZM6.41667 5.83334H7C7.30942 5.83334 7.60617 5.95625 7.82496 6.17505C8.04375 6.39384 8.16667 6.69058 8.16667 7V10.5C8.16667 10.6547 8.10521 10.8031 7.99581 10.9125C7.88642 11.0219 7.73804 11.0833 7.58333 11.0833C7.42862 11.0833 7.28025 11.0219 7.17086 10.9125C7.06146 10.8031 7 10.6547 7 10.5V7H6.41667C6.26196 7 6.11358 6.93855 6.00419 6.82915C5.89479 6.71975 5.83333 6.57138 5.83333 6.41667C5.83333 6.26196 5.89479 6.11359 6.00419 6.00419C6.11358 5.8948 6.26196 5.83334 6.41667 5.83334Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3688_7226">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const VideoCamera = ({ className }) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="14" r="14" fill="#D6E4F1" />
    <g clipPath="url(#clip0_1117_7795)">
      <path
        d="M10.5 18H14.5C15.8785 18 17 16.8785 17 15.5V12.5C17 11.1215 15.8785 10 14.5 10H10.5C9.1215 10 8 11.1215 8 12.5V15.5C8 16.8785 9.1215 18 10.5 18ZM20 12.1565V15.816C20 16.1945 19.79 16.5345 19.4515 16.7035C19.31 16.774 19.158 16.809 19.007 16.809C18.7965 16.809 18.5885 16.7415 18.4125 16.6095C18.394 16.5955 18 16.2045 18 16.2045V11.772C18 11.772 18.393 11.3775 18.4125 11.363C18.7145 11.136 19.112 11.0995 19.4515 11.269C19.791 11.4385 20 11.778 20 12.1565Z"
        fill="#337AB7"
      />
    </g>
    <defs>
      <clipPath id="clip0_1117_7795">
        <rect width="12" height="12" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);
const Call = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3897_2055)">
      <path
        d="M21 14.93V18.23C21 19.03 20.65 19.79 20.05 20.32C19.54 20.76 18.89 21 18.22 21C18.09 21 17.96 20.99 17.84 20.97C10.18 19.91 4.08999 13.82 3.02999 6.16C2.91999 5.36 3.15999 4.56 3.67999 3.95C4.20999 3.35 4.96999 3 5.76999 3H9.06999C9.91999 3 10.65 3.61 10.8 4.45L11.19 6.65C11.37 7.7 10.93 8.77 10.06 9.39L8.75999 10.51C9.97999 12.39 11.6 14.01 13.51 15.23L14.66 13.89C15.23 13.07 16.31 12.63 17.35 12.81L19.55 13.2C20.39 13.35 21 14.08 21 14.93Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3897_2055">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const RefreshIcon = ({ className, loading }) => {
  return (
    <svg
      className={`w-8 h-8 ${loading ? "animate-spin" : ""} ${className}`}
      viewBox="0 0 118.04 122.88"
    >
      <path d="M16.08,59.26A8,8,0,0,1,0,59.26a59,59,0,0,1,97.13-45V8a8,8,0,1,1,16.08,0V33.35a8,8,0,0,1-8,8L80.82,43.62a8,8,0,1,1-1.44-15.95l8-.73A43,43,0,0,0,16.08,59.26Zm22.77,19.6a8,8,0,0,1,1.44,16l-10.08.91A42.95,42.95,0,0,0,102,63.86a8,8,0,0,1,16.08,0A59,59,0,0,1,22.3,110v4.18a8,8,0,0,1-16.08,0V89.14h0a8,8,0,0,1,7.29-8l25.31-2.3Z" />
    </svg>
  );
};
const GreenCheck = ({ className }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            id="Vector"
            d="M4.52036 12.0517C4.12296 12.0519 3.74183 11.8939 3.46106 11.6127L0.258452 8.41125C-0.0861507 8.06654 -0.0861507 7.50776 0.258452 7.16304C0.603165 6.81844 1.16194 6.81844 1.50666 7.16304L4.52036 10.1767L12.4933 2.20376C12.8381 1.85916 13.3968 1.85916 13.7415 2.20376C14.0862 2.54848 14.0862 3.10726 13.7415 3.45197L5.57966 11.6127C5.29889 11.8939 4.91776 12.0519 4.52036 12.0517Z"
            fill="#10B580"
          />
        </g>
      </g>
    </svg>
  );
};
const SecureUser = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="user-lock 1" clipPath="url(#clip0_5670_14314)">
        <path
          id="Vector"
          d="M22 14.537V13C22 10.794 20.206 9 18 9C15.794 9 14 10.794 14 13V14.537C12.805 15.23 12 16.523 12 18V20C12 22.206 13.794 24 16 24H20C22.206 24 24 22.206 24 20V18C24 16.523 23.195 15.23 22 14.537ZM18 20.5C17.172 20.5 16.5 19.828 16.5 19C16.5 18.172 17.172 17.5 18 17.5C18.828 17.5 19.5 18.172 19.5 19C19.5 19.828 18.828 20.5 18 20.5ZM20 14H16V13C16 11.897 16.897 11 18 11C19.103 11 20 11.897 20 13V14ZM8 12C11.309 12 14 9.309 14 6C14 2.691 11.309 0 8 0C4.691 0 2 2.691 2 6C2 9.309 4.691 12 8 12ZM10 20C10 21.535 10.58 22.938 11.532 24H1C0.448 24 0 23.552 0 23V22.271C0 18.156 2.993 14.511 7.083 14.052C8.043 13.944 8.974 14.008 9.849 14.216C10.465 14.362 10.736 15.055 10.486 15.636C10.174 16.362 10 17.161 10 18V20Z"
          fill="#6E6E6E"
        />
      </g>
      <defs>
        <clipPath id="clip0_5670_14314">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
const SecureShield = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="shield-check (1) 2" clipPath="url(#clip0_5670_14326)">
        <path
          id="Vector"
          d="M18.581 2.14024L12.316 0.0512408C12.1109 -0.0170803 11.8891 -0.0170803 11.684 0.0512408L5.419 2.14024C4.42291 2.47112 3.55642 3.1075 2.94265 3.95895C2.32887 4.8104 1.99904 5.83363 2 6.88324V12.0002C2 19.5632 11.2 23.7402 11.594 23.9142C11.7218 23.971 11.8601 24.0004 12 24.0004C12.1399 24.0004 12.2782 23.971 12.406 23.9142C12.8 23.7402 22 19.5632 22 12.0002V6.88324C22.001 5.83363 21.6711 4.8104 21.0574 3.95895C20.4436 3.1075 19.5771 2.47112 18.581 2.14024ZM16.718 9.71724L12.446 13.9892C12.2712 14.1652 12.0632 14.3047 11.834 14.3997C11.6048 14.4946 11.3591 14.5431 11.111 14.5422H11.078C10.8249 14.5384 10.5752 14.4832 10.344 14.3801C10.1128 14.2769 9.90498 14.128 9.733 13.9422L7.427 11.5422C7.32713 11.4496 7.24725 11.3375 7.19226 11.2129C7.13726 11.0882 7.10831 10.9537 7.10718 10.8174C7.10606 10.6812 7.13278 10.5462 7.1857 10.4207C7.23863 10.2951 7.31665 10.1817 7.41497 10.0874C7.51329 9.99314 7.62985 9.91993 7.75748 9.87229C7.88511 9.82466 8.02113 9.8036 8.15719 9.81042C8.29325 9.81724 8.42648 9.85178 8.54872 9.91194C8.67095 9.97209 8.7796 10.0566 8.868 10.1602L11.112 12.5002L15.3 8.30024C15.4886 8.11808 15.7412 8.01729 16.0034 8.01957C16.2656 8.02185 16.5164 8.12702 16.7018 8.31242C16.8872 8.49783 16.9924 8.74864 16.9947 9.01084C16.997 9.27304 16.8962 9.52564 16.714 9.71424L16.718 9.71724Z"
          fill="#6E6E6E"
        />
      </g>
      <defs>
        <clipPath id="clip0_5670_14326">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
const SecureConsultation = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="doctor (1) 2" clipPath="url(#clip0_5670_14320)">
        <path
          id="Vector"
          d="M19 5H16V4C16 3.20435 15.6839 2.44129 15.1213 1.87868C14.5587 1.31607 13.7956 1 13 1H11C10.2044 1 9.44129 1.31607 8.87868 1.87868C8.31607 2.44129 8 3.20435 8 4V5H5C3.67441 5.00159 2.40356 5.52888 1.46622 6.46622C0.528882 7.40356 0.00158786 8.67441 0 10L0 18C0.00158786 19.3256 0.528882 20.5964 1.46622 21.5338C2.40356 22.4711 3.67441 22.9984 5 23H19C20.3256 22.9984 21.5964 22.4711 22.5338 21.5338C23.4711 20.5964 23.9984 19.3256 24 18V10C23.9984 8.67441 23.4711 7.40356 22.5338 6.46622C21.5964 5.52888 20.3256 5.00159 19 5ZM10 4C10 3.73478 10.1054 3.48043 10.2929 3.29289C10.4804 3.10536 10.7348 3 11 3H13C13.2652 3 13.5196 3.10536 13.7071 3.29289C13.8946 3.48043 14 3.73478 14 4V5H10V4ZM15 15H13V17C13 17.2652 12.8946 17.5196 12.7071 17.7071C12.5196 17.8946 12.2652 18 12 18C11.7348 18 11.4804 17.8946 11.2929 17.7071C11.1054 17.5196 11 17.2652 11 17V15H9C8.73478 15 8.48043 14.8946 8.29289 14.7071C8.10536 14.5196 8 14.2652 8 14C8 13.7348 8.10536 13.4804 8.29289 13.2929C8.48043 13.1054 8.73478 13 9 13H11V11C11 10.7348 11.1054 10.4804 11.2929 10.2929C11.4804 10.1054 11.7348 10 12 10C12.2652 10 12.5196 10.1054 12.7071 10.2929C12.8946 10.4804 13 10.7348 13 11V13H15C15.2652 13 15.5196 13.1054 15.7071 13.2929C15.8946 13.4804 16 13.7348 16 14C16 14.2652 15.8946 14.5196 15.7071 14.7071C15.5196 14.8946 15.2652 15 15 15Z"
          fill="#6E6E6E"
        />
      </g>
      <defs>
        <clipPath id="clip0_5670_14320">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
const Dropdown = ({ className }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 32 32"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6128_4254)">
        <path
          d="M22.3449 17.7122C23.2654 17.7122 24.0116 16.966 24.0116 16.0456C24.0116 15.1251 23.2654 14.3789 22.3449 14.3789C21.4244 14.3789 20.6782 15.1251 20.6782 16.0456C20.6782 16.966 21.4244 17.7122 22.3449 17.7122Z"
          fill="#010101"
        />
        <path
          d="M16.0002 17.6654C16.9206 17.6654 17.6668 16.9192 17.6668 15.9987C17.6668 15.0782 16.9206 14.332 16.0002 14.332C15.0797 14.332 14.3335 15.0782 14.3335 15.9987C14.3335 16.9192 15.0797 17.6654 16.0002 17.6654Z"
          fill="#010101"
        />
        <path
          d="M9.66667 17.6654C10.5871 17.6654 11.3333 16.9192 11.3333 15.9987C11.3333 15.0782 10.5871 14.332 9.66667 14.332C8.74619 14.332 8 15.0782 8 15.9987C8 16.9192 8.74619 17.6654 9.66667 17.6654Z"
          fill="#010101"
        />
      </g>
      <circle cx="16" cy="16" r="15.5" stroke="#D2D2D2" />
      <defs>
        <clipPath id="clip0_6128_4254">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

// export { HomeIcon, MyVisitsIcon, ReferralIcon, MyProfileIcon };

export const Icon = ({ name, ...props }) => {
  switch (name) {
    case "home":
      return <HomeIcon {...props} />;
    case "myVisits":
      return <MyVisitsIcon {...props} />;
    case "referral":
      return <ReferralIcon {...props} />;
    case "myProfile":
      return <MyProfileIcon {...props} />;
    case "google":
      return <GoogleIcon {...props} />;
    case "star":
      return <StarIcon {...props} />;
    case "radioInactive":
      return <RadioInactiveIcon {...props} />;
    case "radioActive":
      return <RadioActiveIcon {...props} />;
    case "calender":
      return <CalenderIcon {...props} />;
    case "close":
      return <CloseIcon {...props} />;
    case "close-circle":
      return <CloseCircleIcon {...props} />;
    case "camera":
      return <CameraIcon {...props} />;
    case "notification":
      return <NotificationIcon {...props} />;
    case "arrow-right":
      return <RightArrow {...props} />;
    case "arrow-right-small":
      return <RightArrowSmall {...props} />;
    case "arrow-left-small":
      return <LeftArrowSmall {...props} />;
    case "shield":
      return <Shield {...props} />;
    case "message":
      return <Message {...props} />;
    case "info":
      return <Info {...props} />;
    case "video":
      return <VideoCamera {...props} />;
    case "call":
      return <Call {...props} />;
    case "refresh":
      return <RefreshIcon {...props} />;
    case "green-check":
      return <GreenCheck {...props} />;
    case "secure-user":
      return <SecureUser {...props} />;
    case "secure-shield":
      return <SecureShield {...props} />;
    case "secure-consultation":
      return <SecureConsultation {...props} />;
    case "dropdown":
      return <Dropdown {...props} />;
    default:
      return null;
  }
};
