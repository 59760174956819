import React from "react";
import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import useModal from "hooks/useModal";
import { ped_sub_steps } from "helpers/enum";
import Button from "components/buttons/Button";
import useToastify from "hooks/useToastify";
import Spacer from "components/Spacer/Spacer";
import SearchInputWithChipCard from "components/searchInputWithChip/SearchInputWithChipCard";
import HistoryDebounceSearchModal from "../../dermatology/medicalHistory/HistoryDebounceSearchModal";

const Allergies = ({
  incompleteData,
  updateVisit,
  updatingVisit,
  setActiveIndex,
  selected,
  setSelected,
  selectedAllergies,
  setSelectedAllergies,
}) => {
  const { showAlert } = useToastify();
  const { setShowModal } = useModal();

  function onRightButtonClick(_, nextAnimation) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          allergies:
            selected === "None" ? [{ name: "None" }] : selectedAllergies,
          medications:
            typeof incompleteData?.medications[0] === "string"
              ? incompleteData?.medications.map((item) => ({
                  name: item,
                }))
              : incompleteData?.medications,
          pediatric_second_phase_steps: {
            ...incompleteData?.pediatric_second_phase_steps,
            [ped_sub_steps.allergies]: "completed",
          },
        },
      },
      change_current_screen: false,
      screen_to_complete: null,
      callBack: () => {
        showAlert("Progress Saved Successfully", "success");
        setActiveIndex(8);
      },
    });
  }

  return (
    <div className="bg-white shadow-card rounded-b-4xl px-10 pb-10 relative overflow-x-hidden">
      <p className="mb-5 font-medium text-2xl">
        (For example, any reaction to medications, food, insects, pets etc.)
      </p>
      <Spacer height={20} />
      <RadioButtonCard
        options={["None"]}
        selected={selected}
        onSelect={setSelected}
      />
      <Spacer height={20} />
      <SearchInputWithChipCard
        inputLabel={"Search Allergy"}
        onClickInput={() => {
          if (updatingVisit) {
            return;
          } else {
            setSelected(null);
            setShowModal({
              state: true,
              children: (_, onClose) => {
                return (
                  <HistoryDebounceSearchModal
                    keyToSearch={"allergies"}
                    title={"Select Allergies"}
                    onClose={onClose}
                    selected={selectedAllergies}
                    setSelected={setSelectedAllergies}
                  />
                );
              },
              data: null,
            });
          }
        }}
        selected={selectedAllergies}
        setSelected={setSelectedAllergies}
      />
      <Button
        className="mt-10 py-4"
        disabled={selected === null ? selectedAllergies.length === 0 : false}
        loading={updatingVisit}
        label="Next"
        onClick={onRightButtonClick}
      />
    </div>
  );
};

export default Allergies;
