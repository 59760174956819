import React from "react";

function SymptomChip({ symptomText, selected, onSelect }) {
  return (
    <div
      onClick={onSelect}
      className={`border rounded-lg mr-4 px-4 py-2 cursor-pointer${
        selected ? " bg-orange text-white border-orange" : " border-borderColor"
      }`}
    >
      <p className="font-medium text-2xl">{symptomText}</p>
    </div>
  );
}

export default SymptomChip;
