import React, { useEffect, useRef, useState } from "react";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import { Form, Formik } from "formik";
import ImagePickerTile from "components/imagePickerTile/ImagePickerTile";
import { UploadPhotosSchema } from "helpers/validationSchema";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import useVisitStepChange from "hooks/useVisitStepChange";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import {
  dermatology_main_steps,
  dermatology_sub_steps,
  step_status,
} from "helpers/enum";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import { useParameterizedQuery } from "react-fetching-library";
import { useApiMutation } from "hooks/useApiMutation";
import {
  getCaseImagesPresignedPhotoUrlAction,
  uploadPhotoIdToS3Action,
  imageUploadErrorReport,
  successImageUploadReport,
} from "api/actions/ImageUploadActions";
import { checkAlreadyCompleted } from "helpers/setDermatologyStepStatus";
import CSSLoader from "components/cssLoader/CSSLoader";
import FormikErrorFocus from "formik-error-focus";
import CasePhotosImagePickerTile from "components/imagePickerTile/CasePhotosImagePickerTile";
import { isDesktop } from "react-device-detect";
import useTextCompleteVisitToMobile from "hooks/useTextCompleteVisitToMobile";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { trackEvent } from "helpers/analytics";
import useToastify from "hooks/useToastify";

export function getUpdatedValues(values, updatedPayload) {
  let copy = { ...values };
  if (updatedPayload && Object.keys(updatedPayload)?.length) {
    for (let key in copy) {
      if (updatedPayload?.[`${key}_url`]?.length) {
        copy[key] = {
          data: "",
          file: "",
          uri: updatedPayload?.[`${key}_url`],
        };
      }
    }
    return copy;
  } else {
    return values;
  }
}

export function handleImageChange(event, callback, name) {
  const { target } = event;
  const { files } = target;

  if (files && files[0]) {
    let reader = new FileReader();
    reader.onload = (event) => {
      let file_object;
      file_object = {
        uri: files?.[0]?.name?.toString(),
        file: files[0],
        data: event?.target?.result,
        size: (files[0].size / 1000000).toFixed(2),
      };
      trackEvent(`image_upload_${name}`, {
        size: file_object.size + "mb",
      });
      callback(file_object);
    };
    reader.readAsDataURL(files[0]);
  }
}

function UploadPhotos() {
  const formRef = useRef();
  const nextAnimationRef = useRef();

  const dispatch = useDispatch();
  const [addMore, setAddMore] = useState(false);

  // const [imageUploadError, setImageUploadError] = useState(false);

  const { showAlert } = useToastify();

  const { alertPatient } = useTextCompleteVisitToMobile();
  const { incompleteData, subSteps } = useDermatologyVisitFlow();
  const { rightButtonClickRef, setLoading } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore =
    subSteps[dermatology_sub_steps.upload_photos].value;

  const {
    query: getPresignedUrl,
    loading: gettingPreSignedUrl,
  } = useParameterizedQuery(getCaseImagesPresignedPhotoUrlAction);
  const {
    mutate: uploadPhotoIdToS3,
    loading: uploadingPhotoIdToS3,
  } = useApiMutation(uploadPhotoIdToS3Action);
  const { updateVisit } = useUpdateDermatologyVisit();

  const { query: reportImageUploadFailure } = useParameterizedQuery(
    imageUploadErrorReport
  );

  const { query: successImageTracker } = useParameterizedQuery(
    successImageUploadReport
  );

  const userAgent = window.navigator
    ? window.navigator.userAgent
    : navigator.userAgent;

  const userPlatform = userAgent.includes("iPhone")
    ? "ios-web"
    : userAgent.includes("Android")
    ? "android-web"
    : "web";

  useEffect(() => {
    if (isDesktop) {
      alertPatient({
        message:
          "It looks like you're on a device that does not have camera access. You will be asked to upload photos of your condition now. Do you want to use your smartphone to take these photos? We'll text you a link to complete your visit on your smartphone.",
        heading: "Use your smartphone to take photos?",
        openImagePicker: () => void 0,
      });
    }
  }, [alertPatient]);

  useEffect(() => {
    if (currentScreenValueFromStore) {
      if (
        currentScreenValueFromStore?.close_up_photo_2?.uri?.length ||
        currentScreenValueFromStore?.close_up_photo_3?.uri?.length
      ) {
        setAddMore(true);
      }
      formRef?.current?.setValues(currentScreenValueFromStore);
    }
  }, [currentScreenValueFromStore]);

  useEffect(() => {
    dispatch(
      dermaVisitFlowReducers.upload_photos({
        isValid: true,
      })
    );
  }, [dispatch]);

  function onRightButtonClick(_, nextAnimation) {
    if (formRef?.current) {
      formRef?.current?.handleSubmit();
      nextAnimationRef.current = nextAnimation;
    }
  }

  function updateVisitFuncion(values, afterUploadPayload) {
    if (values) {
      updateVisit({
        payload: {
          case: {
            dermatology_steps: {
              main_steps: {
                ...incompleteData?.dermatology_steps?.main_steps,
                [dermatology_main_steps.photos]: step_status.completed,
                [dermatology_main_steps.main_symptoms]: checkAlreadyCompleted({
                  screen_name: dermatology_main_steps.main_symptoms,
                  dermatology_steps: incompleteData?.dermatology_steps,
                  step_placement: "main_steps",
                }),
              },
              sub_steps: {
                ...incompleteData?.dermatology_steps?.sub_steps,
              },
            },
          },
          id: incompleteData?.id,
          case_images: afterUploadPayload,
        },
        change_current_screen: true,
        screen_to_complete: dermatology_sub_steps.upload_photos,
        callBack: (updatedPayload) => {
          dispatch(
            dermaVisitFlowReducers.upload_photos({
              value: getUpdatedValues(values, updatedPayload),
            })
          );
          nextAnimationRef?.current();
        },
      });
    }
  }

  async function getPresignedUrlFunction({
    uri,
    file,
    imageType,
    extension,
    successCallback,
  }) {
    if (uri?.startsWith("http")) {
      successCallback(null);
      return true; // Returning true as the process is considered successful
    } else {
      try {
        setLoading(true);
        const presignedResult = await getPresignedUrl({
          caseId: incompleteData?.id,
          imageType,
          extension: extension?.split("/")?.[1],
        });

        if (presignedResult) {
          try {
            const uploadPhotoIdToS3Result = await uploadPhotoIdToS3({
              endpoint: presignedResult?.payload?.presigned_url,
              payload: file,
            });

            if (uploadPhotoIdToS3Result) {
              if (uploadPhotoIdToS3Result.error) {
                // setImageUploadError(true);
                // error callback
                await reportImageUploadFailure({
                  patientId: incompleteData?.patient_id,
                  payload: {
                    upload_issue: {
                      case_id: incompleteData?.id,
                      image_type: imageType,
                      filename: presignedResult?.payload?.file_key,
                      file_size: file.size,
                      platform: userPlatform,
                      s3_response: uploadPhotoIdToS3Result.error,
                    },
                  },
                });
              } else {
                await successImageTracker({
                  id: incompleteData?.id,
                  payload: {
                    uploaded_image: imageType,
                  },
                });
              }
              // setImageUploadError(false);
              successCallback({
                kind: imageType,
                filename: presignedResult?.payload?.file_key,
                preSignedImageUrl: presignedResult?.payload?.presigned_url?.split(
                  "?"
                )?.[0],
              });
              setLoading(false);
              return true;
            } else {
              setLoading(false);
              return false;
            }
          } catch (error) {
            setLoading(false);
            showAlert("Image Upload Failed - Please try again", "error");
            await reportImageUploadFailure({
              patientId: incompleteData?.patient_id,
              payload: {
                upload_issue: {
                  image_type: imageType,
                  filename: presignedResult?.payload?.file_key,
                  file_size: file.size,
                  platform: userPlatform,
                  s3_response: error,
                },
              },
            });
            return false;
          }
        } else {
          setLoading(false);
          return false;
        }
      } catch (error) {
        setLoading(false);
        return false;
      }
    }
  }

  async function onFormSubmit(values) {
    let afterUploadPayload = [];
    function checkThirdSetPhotos() {
      if (values?.close_up_photo_3?.uri?.length) {
        getPresignedUrlFunction({
          uri: values?.close_up_photo_3?.uri,
          file: values?.close_up_photo_3?.file,
          imageType: "close_up_photo_3",
          extension: values?.close_up_photo_3?.file?.type,
          successCallback: (cp3) => {
            if (cp3) {
              afterUploadPayload.push(cp3);
            }
            getPresignedUrlFunction({
              uri: values?.far_away_photo_3?.uri,
              file: values?.far_away_photo_3?.file,
              imageType: "far_away_photo_3",
              extension: values?.far_away_photo_3?.file?.type,
              successCallback: (fp3) => {
                if (fp3) {
                  afterUploadPayload.push(fp3);
                }
                updateVisitFuncion(values, afterUploadPayload);
              },
            });
          },
        });
      } else {
        updateVisitFuncion(values, afterUploadPayload);
      }
    }

    function checkSecondSetPhotos() {
      console.log("checkSecondSetPhotos");
      if (values?.close_up_photo_2?.uri?.length) {
        getPresignedUrlFunction({
          uri: values?.close_up_photo_2?.uri,
          file: values?.close_up_photo_2?.file,
          imageType: "close_up_photo_2",
          extension: values?.close_up_photo_2?.file?.type,
          successCallback: (cp2) => {
            if (cp2) {
              afterUploadPayload.push(cp2);
            }
            getPresignedUrlFunction({
              uri: values?.far_away_photo_2?.uri,
              file: values?.far_away_photo_2?.file,
              imageType: "far_away_photo_2",
              extension: values?.far_away_photo_2?.file?.type,
              successCallback: (fp2) => {
                if (fp2) {
                  afterUploadPayload.push(fp2);
                }
                checkThirdSetPhotos();
              },
            });
          },
        });
      } else {
        updateVisitFuncion(values, afterUploadPayload);
      }
    }

    if (values?.close_up_photo_1?.uri?.length) {
      getPresignedUrlFunction({
        uri: values?.close_up_photo_1?.uri,
        file: values?.close_up_photo_1?.file,
        imageType: "close_up_photo_1",
        extension: values?.close_up_photo_1?.file?.type,

        successCallback: (cp1) => {
          if (cp1) {
            afterUploadPayload.push(cp1);
          }
          getPresignedUrlFunction({
            uri: values?.far_away_photo_1?.uri,
            file: values?.far_away_photo_1?.file,
            imageType: "far_away_photo_1",
            extension: values?.far_away_photo_1?.file?.type,
            successCallback: (fp1) => {
              if (fp1) {
                afterUploadPayload.push(fp1);
              }
              checkSecondSetPhotos();
            },
          });
        },
      });
    }
  }

  return (
    <StepScreenContainer align="start">
      <MedicalAssistant
        content={<p>Now, please take at least two photos of the issue.</p>}
      />
      <Card>
        <Formik
          innerRef={formRef}
          validationSchema={UploadPhotosSchema}
          initialValues={{
            close_up_photo_1: { uri: "", file: "", data: "" },
            close_up_photo_2: { uri: "", file: "", data: "" },
            close_up_photo_3: { uri: "", file: "", data: "" },
            far_away_photo_1: { uri: "", file: "", data: "" },
            far_away_photo_2: { uri: "", file: "", data: "" },
            far_away_photo_3: { uri: "", file: "", data: "" },
          }}
          onSubmit={(values, props) => {
            const case1 =
              values?.close_up_photo_2?.uri?.length &&
              values?.far_away_photo_2?.uri?.length === 0;
            const case2 =
              values?.far_away_photo_2?.uri?.length &&
              values?.close_up_photo_2?.uri?.length === 0;
            const case3 =
              values?.close_up_photo_3?.uri?.length &&
              values?.far_away_photo_3?.uri?.length === 0;
            const case4 =
              values?.far_away_photo_3?.uri?.length &&
              values?.close_up_photo_3?.uri?.length === 0;

            if (case1 || case2 || case3 || case4) {
              if (case1) {
                props?.setFieldError("far_away_photo_2", {
                  uri: "Please add far away photo",
                });
              } else if (case2) {
                props?.setFieldError("close_up_photo_2", {
                  uri: "Please add close up photo",
                });
              }
              if (case3) {
                props?.setFieldError("far_away_photo_3", {
                  uri: "Please add far away photo",
                });
              } else if (case4) {
                props?.setFieldError("close_up_photo_3", {
                  uri: "Please add close up photo",
                });
              }
            } else {
              onFormSubmit(values);
            }
          }}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form>
              <div className="flex justify-evenly">
                <div>
                  <CasePhotosImagePickerTile
                    width={40}
                    height={40}
                    radius={1.4}
                    imageClassName={"md:h-56 md:w-56"}
                    imageUrl={
                      values?.close_up_photo_1?.data?.length
                        ? values?.close_up_photo_1?.data
                        : values?.close_up_photo_1?.uri
                    }
                    handleChange={(e) => {
                      handleImageChange(
                        e,
                        (val) => {
                          setFieldValue("close_up_photo_1", val);
                        },
                        "close_up_photo_1"
                      );
                    }}
                    keyType="close_up"
                  />
                  <h3 className="mt-5 text-3xl font-medium text-center opacity-50">
                    Close Up
                  </h3>
                  <p className="pt-1 text-base font-light text-center text-red">
                    {touched?.close_up_photo_1?.uri &&
                    errors?.close_up_photo_1?.uri
                      ? errors?.close_up_photo_1?.uri
                      : null}
                  </p>
                </div>
                <Spacer width={22} />
                <div>
                  <CasePhotosImagePickerTile
                    width={40}
                    height={40}
                    radius={1.4}
                    imageClassName={"md:h-56 md:w-56"}
                    imageUrl={
                      values?.far_away_photo_1?.data?.length
                        ? values?.far_away_photo_1?.data
                        : values?.far_away_photo_1?.uri
                    }
                    handleChange={(e) => {
                      handleImageChange(
                        e,
                        (val) => {
                          setFieldValue("far_away_photo_1", val);
                        },
                        "far_away_photo_1"
                      );
                    }}
                    keyType="far_away"
                  />
                  <h3 className="mt-5 text-3xl font-medium text-center opacity-50">
                    Far Away
                  </h3>
                  <p className="pt-1 text-base font-light text-center text-red">
                    {touched?.far_away_photo_1?.uri &&
                    errors?.far_away_photo_1?.uri
                      ? errors?.far_away_photo_1?.uri
                      : null}
                  </p>
                </div>
              </div>
              {addMore ? (
                <>
                  <Spacer height={24} />
                  <div className="flex justify-evenly">
                    <div>
                      <ImagePickerTile
                        id={"close_up_photo_2"}
                        imageUrl={
                          values?.close_up_photo_2?.data?.length
                            ? values?.close_up_photo_2?.data
                            : values?.close_up_photo_2?.uri
                        }
                        handleChange={(e) => {
                          handleImageChange(
                            e,
                            (val) => {
                              setFieldValue("close_up_photo_2", val);
                            },
                            "close_up_photo_2"
                          );
                        }}
                      />
                      <h3 className="mt-5 text-3xl font-medium text-center opacity-50">
                        Close Up
                      </h3>
                      <p className="pt-1 text-base font-light text-center text-red">
                        {errors?.close_up_photo_2?.uri ?? null}
                      </p>
                    </div>
                    <Spacer width={22} />
                    <div>
                      <ImagePickerTile
                        id={"far_away_photo_2"}
                        imageUrl={
                          values?.far_away_photo_2?.data?.length
                            ? values?.far_away_photo_2?.data
                            : values?.far_away_photo_2?.uri
                        }
                        handleChange={(e) => {
                          handleImageChange(
                            e,
                            (val) => {
                              setFieldValue("far_away_photo_2", val);
                            },
                            "far_away_photo_2"
                          );
                        }}
                      />
                      <h3 className="mt-5 text-3xl font-medium text-center opacity-50">
                        Far Away
                      </h3>
                      <p className="pt-1 text-base font-light text-center text-red">
                        {errors?.far_away_photo_2?.uri ?? null}
                      </p>
                    </div>
                  </div>
                  <Spacer height={24} />
                  <div className="flex justify-evenly">
                    <div>
                      <ImagePickerTile
                        id={"close_up_photo_3"}
                        imageUrl={
                          values?.close_up_photo_3?.data?.length
                            ? values?.close_up_photo_3?.data
                            : values?.close_up_photo_3?.uri
                        }
                        handleChange={(e) => {
                          handleImageChange(
                            e,
                            (val) => {
                              setFieldValue("close_up_photo_3", val);
                            },
                            "close_up_photo_3"
                          );
                        }}
                      />
                      <h3 className="mt-5 text-3xl font-medium text-center opacity-50">
                        Close Up
                      </h3>
                      <p className="pt-1 text-base font-light text-center text-red">
                        {errors?.close_up_photo_3?.uri ?? null}
                      </p>
                    </div>
                    <Spacer width={22} />
                    <div>
                      <ImagePickerTile
                        id={"far_away_photo_3"}
                        imageUrl={
                          values?.far_away_photo_3?.data?.length
                            ? values?.far_away_photo_3?.data
                            : values?.far_away_photo_3?.uri
                        }
                        handleChange={(e) => {
                          handleImageChange(
                            e,
                            (val) => {
                              setFieldValue("far_away_photo_3", val);
                            },
                            "far_away_photo_3"
                          );
                        }}
                      />
                      <h3 className="mt-5 text-3xl font-medium text-center opacity-50">
                        Far Away
                      </h3>
                      <p className="pt-1 text-base font-light text-center text-red">
                        {errors?.far_away_photo_3?.uri ?? null}
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Spacer height={30} />
                  <div className="flex items-center justify-center">
                    <button
                      disabled={
                        !values?.close_up_photo_1?.uri?.length ||
                        !values?.far_away_photo_1?.uri?.length
                      }
                      onClick={() => setAddMore(true)}
                      className={`${
                        !values?.close_up_photo_1?.uri?.length ||
                        !values?.far_away_photo_1?.uri?.length
                          ? "opacity-50 cursor-not-allowed"
                          : "opacity-100"
                      } px-10 py-4 bg-orange rounded-2/7 text-white text-2xl font-bold`}
                    >
                      Add More Photos
                    </button>
                  </div>
                </>
              )}
              <FormikErrorFocus
                align={"bottom"}
                ease={"linear"}
                duration={500}
              />
            </Form>
          )}
        </Formik>
        {gettingPreSignedUrl || uploadingPhotoIdToS3 ? (
          <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full px-4 bg-alternativeWhite bg-opacity-80">
            <CSSLoader className="w-12 h-12" color="orange" />
            <p className="text-3xl font-bold">
              Please wait while we upload your images.
            </p>
          </div>
        ) : null}
      </Card>
    </StepScreenContainer>
  );
}

export default UploadPhotos;
