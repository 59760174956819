import { createSlice } from "@reduxjs/toolkit";
import { cosmetic_steps } from "helpers/enum";
import { comseticConsultationInitialState } from "./comseticConsultationInitialState";

const initialState = comseticConsultationInitialState;

const primaryCareVisitFlowSlice = createSlice({
  name: "cosmeticConsultationFlow",
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    currentScreen(state, action) {
      const { value } = action.payload;
      state["currentScreen"] = value;
    },
    incompleteData(state, action) {
      const { value } = action.payload;
      state["incompleteData"] = value;
    },
    completedProgress(state, action) {
      const { value } = action.payload;
      if (value && value > state.completedProgress) {
        state["completedProgress"] = value;
      }
    },
    setPaymentConfirmed(state, action) {
      const { value } = action.payload;
      if (value) {
        state["paymentConfirmed"] = value;
      }
    },
    select_condition(state, action) {
      const { value, nextScreen, status, isValid } = action.payload;
      if (nextScreen) {
        state.steps[cosmetic_steps.select_condition].nextScreen = nextScreen;
      }
      if (value) {
        state.steps[cosmetic_steps.select_condition].value = value;
      }
      if (isValid !== undefined) {
        state.steps[cosmetic_steps.select_condition].isValid = isValid;
      }
      if (status) {
        state.steps[cosmetic_steps.select_condition].status = status;
      }
    },
    upload_photos(state, action) {
      const { value, nextScreen, status, isValid } = action.payload;
      if (nextScreen) {
        state.steps[cosmetic_steps.upload_photos].nextScreen = nextScreen;
      }
      if (value) {
        state.steps[cosmetic_steps.upload_photos].value = value;
      }
      if (isValid !== undefined) {
        state.steps[cosmetic_steps.upload_photos].isValid = isValid;
      }
      if (status) {
        state.steps[cosmetic_steps.upload_photos].status = status;
      }
    },
    allergies(state, action) {
      const { value, nextScreen, status, isValid } = action.payload;
      if (nextScreen) {
        state.steps[cosmetic_steps.allergies].nextScreen = nextScreen;
      }
      if (value) {
        state.steps[cosmetic_steps.allergies].value = value;
      }
      if (isValid !== undefined) {
        state.steps[cosmetic_steps.allergies].isValid = isValid;
      }
      if (status) {
        state.steps[cosmetic_steps.allergies].status = status;
      }
    },
    select_slot(state, action) {
      const { value, nextScreen, status, isValid } = action.payload;
      if (nextScreen) {
        state.steps[cosmetic_steps.select_slot].nextScreen = nextScreen;
      }
      if (value) {
        state.steps[cosmetic_steps.select_slot].value = value;
      }
      if (isValid !== undefined) {
        state.steps[cosmetic_steps.select_slot].isValid = isValid;
      }
      if (status) {
        state.steps[cosmetic_steps.select_slot].status = status;
      }
    },
    previous_treatment(state, action) {
      const { value, nextScreen, status, isValid } = action.payload;
      if (nextScreen) {
        state.steps[cosmetic_steps.previous_treatment].nextScreen = nextScreen;
      }
      if (value) {
        state.steps[cosmetic_steps.previous_treatment].value = value;
      }
      if (isValid !== undefined) {
        state.steps[cosmetic_steps.previous_treatment].isValid = isValid;
      }
      if (status) {
        state.steps[cosmetic_steps.previous_treatment].status = status;
      }
    },
  },
});

export const cosmeticConsultationReducers = primaryCareVisitFlowSlice.actions;

export default primaryCareVisitFlowSlice.reducer;
