import { speciality } from "./enum";

export const getInactiveMessage = ({ provider_name }) => {
  return `${provider_name} is no longer providing online care through SkyMD. Please select another provider or try changing your zip code.`;
};

export const getLicenseExpiredMessage = ({ provider_name }) => {
  return `${provider_name} is no longer providing online care in your state. Please select another provider who licensed in your state or try changing your zip code.`;
};

export const getServiceNotAvailableMessage = ({ specialityNumber }) => {
  return `We are launching ${
    specialityNumber === speciality.dermatology
      ? "Dermatology"
      : "Family Practice: Primary care"
  } service soon in your state.`;
};

export const getNoProviderAvailableMessage = ({ specialityNumber }) => {
  return `No ${
    specialityNumber === speciality.dermatology
      ? "Dermatology"
      : "Family Practice: Primary care"
  } provider has been assigned to you.`;
};
