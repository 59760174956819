import React from "react";
import Images from "assets/images";
import useModal from "hooks/useModal";
import "./chatbubble.scss";
import moment from "moment";

const SENDER_TYPE = {
  provider: 1,
  user: 2,
};

const getImagesView = (images, fullResImages, onClickImage) => {
  if (images?.length) {
    return (
      <div className="flex flex-wrap">
        {images?.map((image, index) => {
          const isPDF = image?.includes(".pdf");
          return (
            <div
              key={index}
              className="relative flex items-center justify-center w-32 h-32 mb-4 mr-4 overflow-hidden bg-black min-w-32 min-h-32 rounded-3xl"
            >
              <img
                onClick={() => onClickImage(fullResImages?.[index])}
                className={`object-contain cursor-pointer absolute ${
                  isPDF ? "w-3/4 h-3/4" : "w-full h-full"
                }`}
                key={index}
                src={isPDF ? Images.pdfIcon : image}
                alt="img"
              />
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};

function ChatBubble({ message, onClickEdit, visit_id, showDateBanner }) {
  const { setShowModal } = useModal();

  function getChatText() {
    let message_edited = message?.web_message;
    message_edited = message_edited?.replace(/(\r\n|\n|\r)/gm, "<br/>");

    if (message_edited?.includes(`ng-click='showVisit()'`)) {
      message_edited = message_edited?.replace(
        `href=''`,
        // `href='/visit-details/${visit_id}'`
        `href='/home/my-visits'`
      );
    }

    if (message_edited?.includes(`ui-sref='app.new-visit.sub'`)) {
      message_edited = message_edited?.replace(
        `ui-sref='app.new-visit.sub'`,
        `href='/dl/new-visit'`
      );
    }

    return message_edited;
  }

  function onClickImage(imageUrl) {
    setShowModal({
      state: true,
      children: (_, onClose) => (
        <div className="flex items-center justify-center w-full h-screen bg-black bg-opacity-20">
          <div className="relative h-full wrapper">
            <img
              src={imageUrl}
              className="absolute object-contain w-full h-full"
              alt="img"
            />
            <div
              className="absolute flex items-center justify-center w-16 h-16 bg-opacity-50 rounded-full shadow-2xl cursor-pointer bg-alternativeWhite top-4 right-4"
              onClick={onClose}
            >
              <img src={Images.closeIcon} alt="close" className="w-8 h-8" />
            </div>
          </div>
        </div>
      ),
    });
  }

  const dateBanner = () => (
    <p className="w-full text-xl font-medium text-center pt-7">
      {moment(message?.created_at).format("DD MMMM YYYY")}
    </p>
  );

  if (message?.user_type === SENDER_TYPE.provider) {
    return (
      <div>
        {showDateBanner ? dateBanner() : null}
        <div className="flex items-end mt-8 mb-4">
          <img
            src={message?.gravatar}
            alt="dp"
            className="w-16 h-16 rounded-2xl"
          />
          <div className="w-full px-4 py-4 ml-3 break-words bg-blue rounded-xl min-w-1/10 md:w-10/12">
            <p
              className="bubble_content_provider"
              dangerouslySetInnerHTML={{
                __html: getChatText(),
              }}
            />
          </div>
        </div>
        <div className="flex items-center ml-20">
          <img
            src={Images.clockIcon}
            alt="time"
            className="object-contain w-4 h-4"
          />
          <p className="ml-2 text-base font-medium">
            {moment(message?.created_at).format("LT")}
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col items-end">
        {showDateBanner ? dateBanner() : null}
        <div className="flex items-end justify-end mt-8 mb-4">
          <div className="relative w-full px-4 py-4 break-words border bg-whiteTwo rounded-xl border-cloudyBlue min-w-1/10 md:w-10/12">
            {Number(message?.can_edit) === 1 ? (
              <div className="flex items-center justify-end">
                <p
                  className="pb-4 text-xl font-medium underline cursor-pointer"
                  onClick={onClickEdit}
                >
                  Edit
                </p>
              </div>
            ) : null}
            {getImagesView(
              message?.images,
              message?.react_app_images,
              onClickImage
            )}
            <p
              className="bubble_content_user"
              dangerouslySetInnerHTML={{ __html: getChatText() }}
            />
          </div>
          <img
            src={message?.gravatar}
            alt="dp"
            className="w-16 h-16 ml-3 rounded-2xl"
          />
        </div>
        <div className="flex items-center mr-20">
          <img
            src={Images.clockIcon}
            alt="time"
            className="object-contain w-4 h-4"
          />
          <p className="ml-2 text-base font-medium">
            {moment(message?.created_at).format("LT")}
          </p>
        </div>
      </div>
    );
  }
}

export default ChatBubble;
