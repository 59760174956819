import React from "react";

function VisitSummaryContentParagraph({ text }) {
  return (
    <div
      className="text-2xl font-medium pt-5 text-themeBlack"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
}

export default VisitSummaryContentParagraph;
