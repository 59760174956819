import { cosmetic_steps, step_status } from "helpers/enum";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { cosmeticConsultationReducers } from "reducers/cosmetic/cosmeticConsultationReducer";

function usePopulateCosmeticInitialData({ incompleteData }) {
  const dispatch = useDispatch();

  const setProgress = useCallback(() => {
    if (incompleteData?.meta?.progress_percentage) {
      dispatch(
        cosmeticConsultationReducers.completedProgress({
          value: incompleteData?.meta?.progress_percentage,
        })
      );
    }
  }, [dispatch, incompleteData?.meta?.progress_percentage]);

  const setCurrentScreen = useCallback(() => {
    if (
      incompleteData?.cosmetic_steps &&
      Object.keys(incompleteData?.cosmetic_steps)?.length
    ) {
      let current_screen;
      for (let key in incompleteData?.cosmetic_steps) {
        if (incompleteData?.cosmetic_steps?.[key] === step_status.current) {
          current_screen = key;
          break;
        }
      }
      if (current_screen) {
        dispatch(
          cosmeticConsultationReducers.currentScreen({
            value: current_screen,
          })
        );
      }
    }
  }, [dispatch, incompleteData?.cosmetic_steps]);

  const setPaymentMethod = useCallback(() => {
    if (incompleteData?.payment_method === "cash") {
      dispatch(
        cosmeticConsultationReducers.setPaymentConfirmed({ value: true })
      );
    }
  }, [dispatch, incompleteData?.payment_method]);

  const setConsultationDetails = useCallback(() => {
    if (incompleteData?.cosmetic_concern?.length) {
      dispatch(
        cosmeticConsultationReducers.select_condition({
          value: incompleteData?.cosmetic_concern,
        })
      );
    }
  }, [dispatch, incompleteData?.cosmetic_concern]);

  const setPhotos = useCallback(() => {
    dispatch(
      cosmeticConsultationReducers.upload_photos({
        value: {
          close_up_photo_1: {
            uri: incompleteData?.detail_close_up_photo_1_url ?? "",
            data: "",
            file: "",
          },
          far_away_photo_1: {
            uri: incompleteData?.detail_far_away_photo_1_url ?? "",
            data: "",
            file: "",
          },
          close_up_photo_2: {
            uri: incompleteData?.detail_close_up_photo_2_url ?? "",
            data: "",
            file: "",
          },
          far_away_photo_2: {
            uri: incompleteData?.detail_far_away_photo_2_url ?? "",
            data: "",
            file: "",
          },
          close_up_photo_3: {
            uri: incompleteData?.detail_close_up_photo_3_url ?? "",
            data: "",
            file: "",
          },
          far_away_photo_3: {
            uri: incompleteData?.detail_far_away_photo_3_url ?? "",
            data: "",
            file: "",
          },
        },
        isValid:
          incompleteData?.detail_close_up_photo_1_url?.length &&
          incompleteData?.detail_far_away_photo_1_url?.length
            ? true
            : false,
        status:
          incompleteData?.dermatology_steps?.sub_steps?.[
            cosmetic_steps.upload_photos
          ] ?? "upcoming",
      })
    );
  }, [dispatch, incompleteData]);

  const setSlotDetails = useCallback(() => {
    if (incompleteData?.meta?.booking_info) {
      dispatch(
        cosmeticConsultationReducers.select_slot({
          value: {
            selectedDate: incompleteData?.meta?.booking_info?.selectedDate,
            slotInfo: incompleteData?.meta?.booking_info?.slotInfo,
            selectedProvider:
              incompleteData?.meta?.booking_info?.selectedProvider,
          },
        })
      );
    }
  }, [dispatch, incompleteData?.meta?.booking_info]);

  const setMedicalHistory = useCallback(() => {
    dispatch(
      cosmeticConsultationReducers.previous_treatment({
        value: incompleteData?.previous_diagnosis,
      })
    );

    if (incompleteData?.allergies?.length) {
      dispatch(
        cosmeticConsultationReducers.allergies({
          value: incompleteData?.allergies,
        })
      );
    }
  }, [dispatch, incompleteData?.previous_diagnosis, incompleteData?.allergies]);

  useEffect(() => {
    if (incompleteData && Object.keys(incompleteData)?.length) {
      setPaymentMethod();
      setCurrentScreen();
      setProgress();
      setPaymentMethod();
      setConsultationDetails();
      setPhotos();
      setSlotDetails();
      setMedicalHistory();
    }
  }, [
    incompleteData,
    dispatch,
    setCurrentScreen,
    setProgress,
    setPaymentMethod,
    setConsultationDetails,
    setPhotos,
    setSlotDetails,
    setMedicalHistory,
  ]);
}

export default usePopulateCosmeticInitialData;
