import React, { useEffect, useState } from "react";
import Spacer from "components/Spacer/Spacer";
import {
  viewProviderDetailsAction,
  viewProviderProfileAction,
} from "api/actions/UserActions";
import { useApiQuery } from "hooks/useApiQuery";
import LoaderComponent from "components/loader/LoaderComponent";
import CircularImageComponent from "components/imageComponent/CircularImageComponent";
import { Icon } from "stories/Icons";
import classNames from "classnames";
import SimpleHeader from "components/header/SimpleHeader";

function DoctorCard({
  imageUrl,
  name,
  speciality,
  isActive,
  license,
  ratingValue,
  ratingUserCount,
}) {
  const isLicenseAvailable = license && Object.keys(license)?.length;
  return (
    <div
      className={`flex justify-between bg-white ${
        isActive && isLicenseAvailable ? "opacity-100" : "opacity-50"
      }`}
    >
      <div className="flex">
        <CircularImageComponent
          url={imageUrl}
          size={"medium"}
          className={"self-center"}
        />
        <div className="flex flex-col self-center pl-6 space-y-4">
          <h3 className="mt-2 text-4xl font-bold">{name}</h3>
          <p className="text-2xl font-medium">{speciality}</p>
          {isLicenseAvailable ? (
            <p className="mt-2 text-2xl font-medium ">
              <span className="font-semibold text-black">
                {license?.state} License :
              </span>{" "}
              <span className="font-medium text-themeBlack">
                {license?.license}
              </span>
            </p>
          ) : (
            <p className="mt-2 text-xl font-light opacity-50">
              Not licensed in your state
            </p>
          )}
          {ratingValue !== undefined && ratingValue > 0 && (
            <div className="flex items-center space-x-2">
              <span className="flex mr-2">
                {[...Array(5)].map((_, index) => (
                  <Icon
                    key={`overall-ratings-${index}`}
                    className={classNames(
                      "w-5 h-5",
                      Math.round(ratingValue) > index
                        ? "text-yellow-450"
                        : "text-grey-600"
                    )}
                    name="star"
                  />
                ))}
              </span>
              <span className="text-xl font-medium ">
                {Number(ratingValue)?.toFixed(1)}
              </span>

              <span className="text-xl font-medium text-themeBlack">
                {`(${ratingUserCount} verified ${
                  ratingUserCount > 1 ? "reviews" : "review"
                })`}
              </span>
            </div>
          )}
        </div>
      </div>
      {/* <div className="flex flex-col space-y-4 ">
        <h3 className="mt-2 text-xl font-bold">{practiceName}</h3>
        <p className="text-2xl font-medium">{speciality}</p>
      </div> */}
    </div>
  );
}

function ProviderProfileDetails({ details, caseId, onClose }) {
  const { loading: gettingProviderProfile, query: profileQuery } = useApiQuery(
    viewProviderProfileAction(caseId, details?.id),
    false
  );

  const { loading: gettingProviderDetails, query: detailsQuery } = useApiQuery(
    viewProviderDetailsAction(details?.id),
    false
  );

  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    if (caseId) {
      profileQuery().then((data) => {
        setProfileData(data?.payload?.provider);
      });
    } else {
      detailsQuery().then((data) => {
        setProfileData(data?.payload);
      });
    }
  }, [caseId, details, detailsQuery, profileQuery]);

  if (gettingProviderProfile || gettingProviderDetails) {
    return <LoaderComponent showLoader={{ state: true }} />;
  }
  return (
    <div className="h-full overflow-y-auto bg-backgroundWhite">
      <SimpleHeader
        wrapper={true}
        title="Provider Profile"
        onBackButtonClick={onClose}
      />
      <Profile
        picture={details?.picture ?? details?.avatar}
        name={details?.name}
        speciality={details?.speciality ?? ""}
        license={details?.license}
        practiceName={profileData?.practice?.name}
        ratingValue={details?.review?.rating}
        ratingUserCount={details?.review?.ratings_count}
        practice={profileData?.practice}
        licensed_states={profileData?.licensed_states}
        about={profileData?.practice_user_info?.about}
        conditionsTreated={profileData?.practice_user_info?.conditions_treated}
        qualificationsAndEducation={
          profileData?.practice_user_info?.qualifications_and_education
        }
        specialties={profileData?.practice_user_info?.specialties}
        hobbies={profileData?.practice_user_info?.hobbies}
      />
    </div>
  );
}

const Profile = ({
  picture,
  name,
  speciality,
  license,
  practiceName,
  practice,
  licensed_states,
  ratingValue,
  ratingUserCount,
  about,
  conditionsTreated,
  qualificationsAndEducation,
  specialties,
  hobbies,
}) => {
  return (
    <div className="py-24 wrapper-lg">
      <div className="bg-white">
        <div>
          <div className="px-6 py-8 md:px-12">
            <DoctorCard
              imageUrl={picture}
              name={name}
              speciality={speciality ?? ""}
              isActive={true}
              license={license}
              practiceName={practiceName}
              ratingValue={ratingValue}
              ratingUserCount={ratingUserCount}
            />
            <Spacer height={22} />
            <div className="h-px bg-borderColor" />
            <div className="hidden">
              <h2 className="hidden text-2xl font-bold leading-9 ">
                {practiceName}
              </h2>
              <p className="hidden text-2xl font-light leading-9">
                {practice?.add_1.trim().length ? practice?.add_1 + "," : ""}
                {practice?.add_2.trim().length ? practice?.add_2 + "," : ""}
              </p>
              <p className="hidden text-2xl font-light leading-9">
                {practice?.city} {practice?.zip}
              </p>
              {licensed_states?.length ? (
                <div className="hidden ">
                  <p className="text-2xl font-medium leading-9 ">
                    Licensed States:{" "}
                    <span className="text-2xl font-light leading-9">
                      {licensed_states?.join(", ")}
                    </span>
                  </p>
                </div>
              ) : null}
            </div>
          </div>
          <div className="px-6 pb-12 space-y-8 bg-white divide-y md:px-12">
            {about && (
              <div>
                <h3 className="mb-8 text-3xl font-bold">About {name}</h3>
                <p className="text-2xl font-light leading-9">{about}</p>
              </div>
            )}
            {conditionsTreated && (
              <div className="pb-8">
                <h3 className="my-8 mb-8 text-3xl font-bold">
                  Conditions Treated
                </h3>
                <p className="text-2xl font-light leading-9">
                  {conditionsTreated}
                </p>
              </div>
            )}
            {qualificationsAndEducation && (
              <div>
                <h3 className="my-8 mb-8 text-3xl font-bold">
                  Qualifications and Education
                </h3>
                <p
                  className="text-2xl font-light leading-9"
                  dangerouslySetInnerHTML={{
                    __html: qualificationsAndEducation,
                  }}
                ></p>
              </div>
            )}
            {specialties && (
              <div className="pb-8">
                <h3 className="my-8 mb-8 text-3xl font-bold">Specialties</h3>
                <p
                  className="text-2xl font-light leading-9"
                  dangerouslySetInnerHTML={{ __html: specialties }}
                ></p>
              </div>
            )}
            {hobbies && (
              <div className="pb-8">
                <h3 className="my-8 mb-8 text-3xl font-bold">Hobbies</h3>
                <p
                  className="text-2xl font-light leading-9"
                  dangerouslySetInnerHTML={{ __html: hobbies }}
                ></p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderProfileDetails;
