import React, { useEffect, useRef, useState } from "react";

import Images from "assets/images";
import StepContainer from "components/stepContainer/StepContainer";

import { signupAction } from "api/actions/AuthActions";

import { useApiMutation } from "hooks/useApiMutation";
import { useDispatch } from "react-redux";
import { useParameterizedQuery } from "react-fetching-library";
import { useHistory, useParams } from "react-router-dom";
import { getProvidersFromPracticeIdAction } from "api/actions/UserActions";
import useToastify from "hooks/useToastify";
import { signUpUser } from "reducers/currentUserReducer";
import LoaderComponent from "components/loader/LoaderComponent";
import { CURRENT_SOURCE } from "helpers/currentSource";

import CreateAccountForm from "./CreateAccountForm";
import CompleteProfileForm from "./CompleteProfileForm";
import { setUserProp } from "helpers/analytics";
import { SignupContext } from "providers/SignupProvider";

function SignupPage() {
  const firstFormRef = useRef(null);
  const secondFormRef = useRef(null);

  const history = useHistory();
  const { pid, puid, insuranceId, zipCode } = useParams();
  const urlSearchParams = new URLSearchParams(history.location.search);
  const { landing, source } = Object.fromEntries(urlSearchParams.entries());

  const dispatch = useDispatch();
  const { showAlert } = useToastify();
  const { mutate: callSignupApi, loading } = useApiMutation(signupAction);

  const {
    payload: providerPayload,
    query: getProviders,
    loading: gettingProviders,
  } = useParameterizedQuery(getProvidersFromPracticeIdAction);
  const { formValues, formDispatch } = React.useContext(SignupContext);
  const [providers, setProviders] = useState([]);
  const [formErrorMsg, setFormErrorMsg] = useState("");

  const initValues = {
    ...formValues,
    zip_code: zipCode ? zipCode : formValues.zip_code,
    type: providers?.length ? "referral" : formValues.type,
    provider: puid ? puid : formValues.puid,
  };

  useEffect(() => {
    if (pid && puid) {
      getProviders(pid);
    }
  }, [pid, puid, getProviders]);

  useEffect(() => {
    const showProviders = providerPayload?.practice?.practice_users.some(
      (item) => item.id === Number(puid) && item.speciality === "dermatology"
    );
    if (
      providerPayload &&
      Object.keys(providerPayload)?.length &&
      puid &&
      showProviders
    ) {
      const formedArray = providerPayload?.practice?.practice_users?.filter(
        (provider) => provider.speciality === "dermatology"
      );
      setProviders(formedArray);
      secondFormRef?.current?.setFieldValue(
        "provider",
        formedArray?.find((el) => el?.id === Number(puid))?.name
      );
    }
  }, [providerPayload, puid]);

  async function onFormSubmit(values) {
    let payload = {
      user: {
        first_name: values.first_name?.trim(),
        last_name: values.last_name?.trim(),
        email: values.email,
        phone: values.mobile_number?.substring(1),
        zip: values.zip_code,
        password: values.password,
        password_confirmation: values.confirm_password,
        agree: "1",
        signup_source: CURRENT_SOURCE,
        login_via: values.login_via,
      },
    };
    if (pid && puid) {
      payload.user = {
        ...payload.user,
        practice_user_id:
          providers.find((item) => item.name === values.provider)?.id ??
          Number(puid),
      };
    }
    if (insuranceId) {
      payload.user = {
        ...payload.user,
        practice_insurance_id: insuranceId,
      };
    }
    if (landing && source) {
      payload.user = {
        ...payload.user,
        website_landing_page: landing,
        website_signup_page: source,
      };
    } else {
      payload.user = {
        ...payload.user,
        website_landing_page: window?.location?.host ?? "",
        website_signup_page: window?.location?.host ?? "",
      };
    }

    try {
      const result = await callSignupApi(payload);
      if (result.error || result.payload.error || result.payload.error_msg) {
        errorHandler(result.payload.error || result.payload.error_msg);
        setFormErrorMsg(result.payload.error || result.payload.error_msg);
      } else {
        successHandler(result);
      }
    } catch (err) {
      errorHandler(err);
    }
  }

  function successHandler(result) {
    dispatch(
      signUpUser({
        userToken: result.payload.auth_token,
        userId: result.payload.id,
      })
    );
    setUserProp({ user_id: result.payload.id });
    history.replace("/home");
  }

  function errorHandler(message) {
    showAlert(message, "error");
  }

  if (gettingProviders) {
    return (
      <LoaderComponent showLoader={{ state: true, message: "Please wait" }} />
    );
  } else {
    return (
      <div className="flex flex-col justify-center h-full bg-veryLightBlue md:bg-gradient-to-b from-blue-300 to-blue-500">
        <div className="h-full overflow-y-auto md:h-auto bg-blue md:bg-white md:p-10 login-card md:rounded-xl">
          <div className="bg-alternativeWhite md:bg-transparent">
            <div className="flex items-center px-0 py-10 justify-center md:py-0">
              <div className="w-12 h-12">
                {initValues.step % 2 === 0 && (
                  <button
                    className="hidden"
                    onClick={() => {
                      formDispatch({ type: "PREV_STEP" });
                    }}
                  >
                    <img
                      className="object-contain w-full h-full"
                      src={Images.arrowBack}
                      alt="Go back"
                    />
                  </button>
                )}
              </div>
              <div className="flex flex-col items-center justify-center ">
                <img
                  className="object-contain h-44 w-44 "
                  src={Images.logoVerticalNaming}
                  alt="logo"
                />
                <p className="flex font-medium text-4xl leading-8 pt-0 mt-6 md:pt-6 md:mt-4 md:font-bold">
                  {initValues.step % 2 === 0
                    ? "Complete Profile"
                    : "Create Your Profile"}
                </p>
                <p className="px-10 pt-4 pb-0 text-3xl font-medium text-themeBlack text-center md:px-0 md:pb-8 md:text-xl">
                  {initValues.step % 2 === 0
                    ? "Please fill all the fields to get started"
                    : `Please enter your email ${
                        formValues.showAllFields
                          ? "and password to sign up"
                          : ""
                      }`}
                </p>
              </div>
              <div className="w-12 h-12 md:h-14 md:w-14" />
            </div>
          </div>
          <StepContainer
            second={initValues.step % 2 === 0 ? true : false}
            allowPrev={initValues.login_via !== 3}
            prev={() => formDispatch({ type: "PREV_STEP" })}
          />
          {initValues.step % 2 === 0 ? (
            <CompleteProfileForm
              formRef={secondFormRef}
              formValues={initValues}
              formDispatch={formDispatch}
              loading={loading}
              gettingProviders={gettingProviders}
              providers={providers}
              providerPayload={providerPayload}
              formSubmit={onFormSubmit}
              formErrorMsg={formErrorMsg}
              setFormErrorMsg={setFormErrorMsg}
            />
          ) : (
            <>
              <CreateAccountForm
                formRef={firstFormRef}
                formValues={initValues}
                formDispatch={formDispatch}
                loading={loading}
                gettingProviders={gettingProviders}
              />
              <div id="image-experiment" />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default SignupPage;
