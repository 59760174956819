import { textUploadPhotoToMobileAction } from "api/actions/VisitActions";
import Alert from "components/alerts/Alert";
import { useCallback } from "react";
import { useApiMutation } from "./useApiMutation";
import useModal from "./useModal";
import useToastify from "./useToastify";

function useTextCompleteVisitToMobile() {
  const { setShowModal } = useModal();
  const { showAlert } = useToastify();
  const { mutate: callTextToMobile, loading } = useApiMutation(
    textUploadPhotoToMobileAction
  );

  const apiCall = useCallback(
    async (onClose) => {
      try {
        const result = await callTextToMobile({
          body: {},
        });
        if (result && result?.error === false) {
          showAlert(
            `Text message sent to ${result?.payload?.phone}`,
            "success"
          );
          onClose();
        } else {
          showAlert("Something went wrong.", "error");
        }
      } catch (error) {
        showAlert("Something went wrong.", "error");
        onClose();
      }
    },
    [callTextToMobile, showAlert]
  );

  const alertPatient = useCallback(
    ({ message, heading, openImagePicker }) => {
      setShowModal({
        state: true,
        children: (_, onClose) => (
          <Alert
            onPrimaryButtonClick={() => apiCall(onClose)}
            onSecondaryButtonClick={() => {
              onClose();
              setTimeout(() => openImagePicker(), 500);
            }}
            secondaryButtonText="No"
            primaryButtonText="Yes"
            heading={heading}
            message={message}
            primaryButtonLoading={loading}
          />
        ),
      });
    },
    [apiCall, loading, setShowModal]
  );

  return { alertPatient };
}

export default useTextCompleteVisitToMobile;
