import { cosmetic_steps, step_status } from "helpers/enum";

export const comseticConsultationInitialState = {
  currentScreen: cosmetic_steps.select_condition,
  incompleteData: null,
  completedProgress: 0,
  paymentConfirmed: null,
  steps: {
    [cosmetic_steps.select_condition]: {
      value: null,
      isValid: false,
      status: step_status.current,
      prevScreen: null,
      nextScreen: cosmetic_steps.upload_photos,
      headerTitle: "Cosmetic Concern",
      progress: 20,
    },
    [cosmetic_steps.upload_photos]: {
      value: null,
      isValid: false,
      status: step_status.upcoming,
      prevScreen: cosmetic_steps.select_condition,
      nextScreen: cosmetic_steps.allergies,
      headerTitle: "Photos",
      progress: 40,
    },
    [cosmetic_steps.allergies]: {
      value: null,
      isValid: false,
      status: step_status.upcoming,
      prevScreen: cosmetic_steps.upload_photos,
      nextScreen: cosmetic_steps.select_slot,
      headerTitle: "Medical History",
      progress: 60,
    },
    [cosmetic_steps.select_slot]: {
      value: null,
      isValid: false,
      status: step_status.upcoming,
      prevScreen: cosmetic_steps.allergies,
      nextScreen: cosmetic_steps.previous_treatment,
      headerTitle: "Book an Appointment",
      progress: 80,
    },
    [cosmetic_steps.previous_treatment]: {
      value: null,
      isValid: false,
      status: step_status.upcoming,
      prevScreen: cosmetic_steps.select_slot,
      nextScreen: null,
      headerTitle: "Additional Details",
      progress: 100,
    },
  },
};
