import useToastify from "hooks/useToastify";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import ChangeShippingAddress from "./ChangeShippingAddress";
import CSSLoader from "components/cssLoader/CSSLoader";
import { useApiMutation } from "hooks/useApiMutation";
import { placeOtcOrder } from "api/actions/VisitActions";
import "../tabs.css";
const WarehouseMedicinePurchase = ({ data, index }) => {
  const history = useHistory();
  const { showAlert } = useToastify();
  const [showForm, setShowForm] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(
    data.available.ship_to
  );

  const {
    mutate: callPlaceOtcOrder,
    loading: placingOtcOrder,
  } = useApiMutation(placeOtcOrder);
  const available = data.available;
  const completeOrder = async () => {
    const payload = {
      visit_id: data?.id,
      shipping_address: `${shippingAddress?.line_1}${
        shippingAddress?.line_2 === "" ? "" : `, ${shippingAddress?.line_2}`
      }, ${shippingAddress?.city}, ${shippingAddress?.state}, ${
        shippingAddress?.zip
      }`,
    };

    const result = await callPlaceOtcOrder(payload);

    if (result && result.error === false) {
      history.push(`/visit-details/${data?.id}/success`, {
        patientName: data?.patient_first_name,
      });
      showAlert(
        result?.payload?.message ?? "Order placed successfully",
        "success"
      );
    } else {
      if (result.status === 402) {
        history.push(`/visit-details/${data?.id}/error`, {
          patientName: data?.patient_first_name,
          payload,
        });
        showAlert(result?.payload?.message ?? "Payment failed", "error");
      } else {
        showAlert(result?.payload?.message ?? "Something went wrong", "error");
      }
    }
  };
  return (
    <div>
      {showForm && (
        <ChangeShippingAddress
          shippingAddress={shippingAddress}
          setShippingAddress={setShippingAddress}
          setShowForm={setShowForm}
          patientId={available.patient_id}
        />
      )}
      <div className="w-full">
        <div className="flex items-center px-4 py-2">
          <h2 className="pl-4 text-2xl font-medium">
            <li>{"Shipping from SkyMD Warehouse"}</li>
          </h2>
        </div>
        <div className="w-full p-4 overflow-x-auto bg-backgroundWhite">
          <table className="m-4 border rounded-lg table-fixed md:w-full border-grey-600 ">
            <thead>
              <tr>
                <th className="w-full md:w-1/3 px-8 py-8 text-2.5xl font-semibold text-left bg-lightGrey text-indigo min-w-25r">
                  Product and Instructions
                </th>
                <th className="w-1/3 px-8 py-8 text-2.5xl font-semibold text-left bg-lightGrey text-indigo">
                  Qty.
                </th>
                <th className="w-1/3 px-8 py-8 text-2.5xl font-semibold text-left text-white bg-secondaryBlue-500 min-w-200">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              {available.medicines?.map((el, index) => (
                <tr key={index}>
                  <td className="w-full px-8 py-8 space-y-1 text-2xl border-b border-grey-600 bg-gray-altTwoH text-indigo ">
                    <p>{el?.name}</p>
                    <p className="text-xl">{el?.instruction}</p>
                  </td>
                  <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-gray-altTwoH text-indigo">
                    {el?.quantity}
                  </td>
                  <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-secondaryBlue-100 text-indigo">
                    ${el?.price}
                  </td>
                </tr>
              ))}
              <tr>
                <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-gray-altTwoH text-indigo">
                  {"Subtotal"}
                </td>
                <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-gray-altTwoH text-indigo"></td>
                <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-secondaryBlue-100 text-indigo">
                  ${available.subtotal}
                </td>
              </tr>
              <tr>
                <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-gray-altTwoH text-indigo">
                  {` Tax (${available?.tax_state} -
            ${available.tax_number})`}
                </td>
                <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-gray-altTwoH text-indigo"></td>
                <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-secondaryBlue-100 text-indigo">
                  ${available.tax_amount}
                </td>
              </tr>
              <tr>
                <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-gray-altTwoH text-indigo">
                  {"Shipping"}
                </td>
                <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-gray-altTwoH text-indigo"></td>
                <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-secondaryBlue-100 text-indigo">
                  ${available.shipping_fee}
                </td>
              </tr>
              <tr>
                <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-gray-altTwoH text-indigo">
                  {"Total"}
                </td>
                <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-gray-altTwoH text-indigo"></td>
                <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-secondaryBlue-100 text-indigo">
                  ${available.otc_med_total_price}
                </td>
              </tr>
              {available.order && (
                <tr>
                  <td className="px-8 py-8 space-y-1 text-2xl border-b border-grey-600 bg-gray-altTwoH text-indigo">
                    <p>{"Tracking ID"}</p>
                    <p className="text-xl">{available.shipping_id}</p>
                  </td>
                  <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-gray-altTwoH text-indigo"></td>
                  <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-secondaryBlue-100 text-indigo">
                    {available.otc_order?.shipping ? (
                      <a
                        className="text-2xl font-light no-underline text-orange"
                        href={available.tracking_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Track your order
                      </a>
                    ) : (
                      <p className="text-2xl font-light cursor-not-allowed text-orange text-opacity-80">
                        Track your order
                      </p>
                    )}
                  </td>
                </tr>
              )}
              {available.receipt_url && (
                <tr>
                  <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-gray-altTwoH text-indigo">
                    {"Receipt"}
                  </td>
                  <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-gray-altTwoH text-indigo"></td>
                  <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-secondaryBlue-100 text-indigo">
                    <a
                      className="text-2xl font-light no-underline text-orange"
                      href={available.receipt_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Copy of your purchase receipt
                    </a>
                  </td>
                </tr>
              )}
              {!available.order && (
                <tr>
                  <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-gray-altTwoH text-indigo">
                    <p className="text-2xl">SHIP TO</p>
                    <p className="mt-2 text-xl font-medium text-indigo">
                      {shippingAddress?.line_1}
                      {shippingAddress.line_2
                        ? `, ${shippingAddress?.line_2}`
                        : ""}
                      , {shippingAddress?.city}, {shippingAddress?.state},{" "}
                      {shippingAddress?.zip}
                    </p>
                  </td>
                  <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-gray-altTwoH text-indigo"></td>
                  <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-secondaryBlue-100 text-indigo">
                    <button
                      className="text-2xl font-bold no-underline uppercase text-orange"
                      type="button"
                      onClick={() => setShowForm(true)}
                    >
                      Change Address
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {!available.order && (
            <button
              disabled={placingOtcOrder}
              className={`mt-6 flex w-full items-center justify-center bg-orange p-4 font-bold text-white text-2xl rounded-lg
       ${placingOtcOrder ? "opacity-60 cursor-not-allowed" : ""}`}
              onClick={completeOrder}
            >
              Confirm Purchase
              {placingOtcOrder ? (
                <CSSLoader className={"ml-3 w-8 h-8"} />
              ) : null}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default WarehouseMedicinePurchase;
