export const FAMILY_HISTORY_DISEASES = [
  "ADD / ADHD",
  "Allergies",
  "Arthritis",
  "Asthma",
  "Blood Diseases",
  "Cancer",
  "COPD / Emphysema",
  "Developmental Delay",
  "Dementia / Alzheimer’s Disease",
  "Diabetes",
  "Drug / Alcohol Abuse",
  "Genetic Disorder",
  "Gastrointestinal Problems",
  "Headaches / Migraines",
  "Heart Problems",
  "Hypertension / High Blood Pressure",
  "Learning Disability",
  "Lipid Disorder",
  "Obesity",
  "Osteoporosis / Osteopenia",
  "Peripheral Vascular Disease",
  "Psychiatric Disorder / Mental Illness",
  "Skin Diagnosis / Issues",
  "Stroke",
  "Tuberculosis",
  "Thyroid Disorder",
  "Other",
];

export const FAMILY_MEMBERS = [
  "Mother",
  "Father",
  "Sister",
  "Brother",
  "Maternal Grandmother",
  "Maternal Grandfather",
  "Paternal Grandmother",
  "Paternal Grandfather",
  "Daughter",
  "Son",
  "Other",
];
