import { createSlice } from "@reduxjs/toolkit";
import { dermaVisitFlowInitialState } from "./dermaVisitFlowInitialState";
import { dermaBasicInfoReducer } from "./steps/dermaBasicInfoReducer";
import { dermaMainStepsReducer } from "./steps/dermaMainStepsReducer";
import { dermaMedicalHistoryReducer } from "./steps/dermaMedicalHistoryReducer";
import { dermaPhotosReducer } from "./steps/dermaPhotosReducer";
import { dermaSymptomsReducer } from "./steps/dermaSymptomsReducer";
import { dermaVisitDetailsReducer } from "./steps/dermaVisitDetailsReducer";

const initialState = dermaVisitFlowInitialState;

const dermaVisitFlowSlice = createSlice({
  name: "dermaVisitFlow",
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    currentScreen(state, action) {
      const { value } = action.payload;
      state["currentScreen"] = value;
    },
    incompleteData(state, action) {
      const { value } = action.payload;
      state["incompleteData"] = value;
    },
    patientsList(state, action) {
      const { value } = action.payload;
      state["patientsList"] = value;
    },
    completedProgress(state, action) {
      const { value } = action.payload;
      if (value && value > state.completedProgress) {
        state["completedProgress"] = value;
      }
    },
    setPaymentMethod(state, action) {
      const { value } = action.payload;
      if (value) {
        state["paymentMethod"] = value;
      }
    },
    ...dermaMainStepsReducer,
    ...dermaBasicInfoReducer,
    ...dermaVisitDetailsReducer,
    ...dermaPhotosReducer,
    ...dermaSymptomsReducer,
    ...dermaMedicalHistoryReducer,
  },
});

export const dermaVisitFlowReducers = dermaVisitFlowSlice.actions;

export default dermaVisitFlowSlice.reducer;
