import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import AlcoholUse from "./alcoholUse/AlcoholUse";
import CaffeineUse from "./caffeineUse/CaffeineUse";
import Exercise from "./exercise/Exercise";
import HPSocialHistoryView from "./HPSocialHistoryView";
import MaritalStatus from "./maritalStatus/MaritalStatus";
import RecreationalDrugs from "./recreationalDrugs/RecreationalDrugs";
import SexualActivity from "./sexualActivity/SexualActivity";
import SpecialDiet from "./specialDiet/SpecialDiet";
import TobaccoUse from "./tobaccoUse/TobaccoUse";
import Vaccinations from "./vaccinations/Vaccinations";

function HPSocialHistory() {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${url}/marital-status`}>
        <MaritalStatus />
      </Route>
      <Route path={`${url}/alcohol-use`}>
        <AlcoholUse />
      </Route>
      <Route path={`${url}/tobacco-use`}>
        <TobaccoUse />
      </Route>
      <Route path={`${url}/exercise`}>
        <Exercise />
      </Route>
      <Route path={`${url}/recreational-drugs`}>
        <RecreationalDrugs />
      </Route>
      <Route path={`${url}/sexual-activity`}>
        <SexualActivity />
      </Route>
      <Route path={`${url}/caffeine-use`}>
        <CaffeineUse />
      </Route>
      <Route path={`${url}/special-diet`}>
        <SpecialDiet />
      </Route>
      <Route path={`${url}/vaccinations`}>
        <Vaccinations />
      </Route>
      <Route path={`${url}`}>
        <HPSocialHistoryView />
      </Route>
    </Switch>
  );
}

export default HPSocialHistory;
