import { createSlice } from "@reduxjs/toolkit";

const currentUserInitialState = {
  userToken: null,
  userId: null,
  userDetails: null,
  currentSpeciality: null,
  visitMessagePayload: null,
  visitMessageCount: null,
  isInsuranceAvailable: null,
  isCosmeticIncomplete: null,
};

const currentUserSlice = createSlice({
  name: "currentUser",
  initialState: currentUserInitialState,
  reducers: {
    signUpUser(state, action) {
      const { userToken, userId } = action.payload;
      state["userToken"] = userToken;
      state["userId"] = userId;
    },

    signinUser(state, action) {
      const { userToken, userId } = action.payload;
      state["userToken"] = userToken;
      state["userId"] = userId;
    },

    setUserDetails(state, action) {
      state["userDetails"] = action?.payload?.userDetails;
    },

    currentSpeciality(state, action) {
      state["currentSpeciality"] = action.payload.currentSpeciality;
    },

    setCosmeticIncomplete(state, action) {
      state["isCosmeticIncomplete"] = action.payload.isCosmeticIncomplete;
    },

    updateVisitMessagePayload(state, action) {
      state["visitMessagePayload"] = action.payload;
    },

    updateVisitMessageCount(state, action) {
      state["visitMessageCount"] = action.payload.visitMessageCount;
    },

    updateInsuranceAvailability(state, action) {
      state["isInsuranceAvailable"] = action.payload.isInsuranceAvailable;
    },

    logoutUser() {
      return currentUserInitialState;
    },
  },
});

export const {
  signinUser,
  signUpUser,
  logoutUser,
  updateIncompleteVisitData,
  currentSpeciality,
  setUserDetails,
  updateVisitMessagePayload,
  updateVisitMessageCount,
  updateInsuranceAvailability,
} = currentUserSlice.actions;

export default currentUserSlice.reducer;
