import { useContext } from "react";
import { VisitStepChangeContext } from "providers/VisitStepChangeProvider";

function useVisitStepChange() {
  const context = useContext(VisitStepChangeContext);
  if (context === undefined) {
    throw new Error(
      "useVisitStepChange must be used within a VisitStepChangeProvider"
    );
  }
  return context;
}

export default useVisitStepChange;
