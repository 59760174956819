import classNames from "classnames";
import { trackEvent } from "helpers/analytics";
import { useCurrentUser } from "hooks/useCurrentUser";
import ButtonDropDown from "stories/ButtonDropdown";
import { Icon } from "stories/Icons";
const options = (count) => [
  count > 1
    ? {
        value: (
          <span>
            You have {count} new {count > 1 ? "messages" : "message"} from your
            doctor waiting for you. Click on{" "}
            <a
              className="text-orange"
              href="/home/my-visits"
              onClick={() => trackEvent("notification_my_visits_trigger")}
            >
              My Visits
            </a>{" "}
            to know more
          </span>
        ),
        time: "Now",
      }
    : {
        value: "No new notifications",
      },
];
function NotificationBar({ leftActionView, bottomActionView }) {
  const { userDetails } = useCurrentUser();

  const buttonProps = {
    onClick: () => {
      trackEvent("notification_icon_trigger");
    },
  };

  const props = {
    buttonProps: buttonProps,
    icon: (
      <div className="relative">
        <Icon name={"notification"} className="w-16 h-16" />
        {userDetails?.unread_messages > 1 && (
          <div className="absolute top-0 right-0 w-5 h-5 -mt-0.5 -mr-1 rounded-full bg-errorRed" />
        )}
      </div>
    ),
    childComponent: (
      <div>
        <h2 className="p-6 text-2xl font-bold">Notification</h2>
        <p className="px-6 py-3 text-xl bg-gray-150 ">Today</p>
        <div
          value={options(userDetails?.unread_messages)[0].value}
          className={classNames(
            "font-medium text-themeBlack p-6 flex w-full items-center justify-start rounded-sm text-xl"
          )}
        >
          <div className="w-full pr-4 text-left">
            {options(userDetails?.unread_messages)[0].value}
          </div>
          <div>{options(userDetails?.unread_messages)[0].time}</div>
        </div>
      </div>
    ),
  };

  return (
    <div className={"sticky top-0 z-30 flex flex-col w-full mb-6"}>
      <div
        className={
          "sticky top-0 z-30 border-b border-grey-600 flex flex-row items-center justify-between w-full px-10 py-6 bg-white border-l"
        }
      >
        {leftActionView}
        <div>
          <ButtonDropDown {...props} />
        </div>
      </div>
      {bottomActionView && bottomActionView}
    </div>
  );
}
export default NotificationBar;
