import React from "react";

function Card({ children, className = "" }) {
  return (
    <div
      className={`relative p-8 overflow-x-hidden bg-white shadow-card rounded-2xl ${className}`}
    >
      {children}
    </div>
  );
}

export default Card;
