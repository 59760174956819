import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Spacer from "components/Spacer/Spacer";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import { useCosmeticConsultationFlow } from "hooks/useConsultationFlow";
import useVisitStepChange from "hooks/useVisitStepChange";
import { cosmetic_steps } from "helpers/enum";
import { cosmeticConsultationReducers } from "reducers/cosmetic/cosmeticConsultationReducer";
import { useCurrentUser } from "hooks/useCurrentUser";
import useUpdateCosmeticConsultation from "hooks/consultation/useUpdateCosmeticConsultation";
import { useApiQuery } from "hooks/useApiQuery";
import scrollToBottom from "helpers/scrollToBottom";
import Card from "components/visitCommon/Card";
import CheckBoxCard from "components/checkBoxCard/CheckBoxCard";
import { OtherConditionSchema } from "helpers/validationSchema";
import { Form, Formik } from "formik";
import Input from "components/inputs/Input";
import FormikErrorFocus from "formik-error-focus";
import VisitSubQuestionSlideUpContainer from "components/visitSubQuestionSlideUp/VisitSubQuestionSlideUpContainer";
import CSSLoader from "components/cssLoader/CSSLoader";
import Images from "assets/images";
import useModal from "hooks/useModal";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { getCosmeticConcernAction } from "api/actions/VisitActions";

function CosmeticSelectCondition() {
  const formRef = useRef();
  const nextAnimationRef = useRef();

  const dispatch = useDispatch();
  const { setShowModal } = useModal();
  const { userDetails } = useCurrentUser();

  const { steps, incompleteData } = useCosmeticConsultationFlow();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore =
    steps[cosmetic_steps.select_condition].value;

  const [selected, setSelected] = useState([]);
  const [otherValue, setOtherValue] = useState("");
  const [cosmeticConcernsList, setCosmeticConcernsList] = useState([]);

  const { updateConsultation } = useUpdateCosmeticConsultation();
  const {
    payload: concernsPayload,
    loading: gettingConcerns,
    query: queryConcerns,
  } = useApiQuery(getCosmeticConcernAction(incompleteData?.id), false);

  useEffect(() => {
    if (currentScreenValueFromStore?.length && cosmeticConcernsList?.length) {
      let formedConcerns = [];
      const concernsResult = currentScreenValueFromStore.filter((el) => {
        return cosmeticConcernsList?.find((elem) => {
          return el === elem?.label;
        });
      });
      const otherResult = currentScreenValueFromStore.find((el) => {
        return !cosmeticConcernsList?.find((elem) => {
          return el === elem?.label;
        });
      });

      formedConcerns = concernsResult;

      if (otherResult) {
        formedConcerns.push("Others");
        setOtherValue(otherResult);
        scrollToBottom();
      }
      setSelected(formedConcerns);
    }
  }, [currentScreenValueFromStore, cosmeticConcernsList]);

  useEffect(() => {
    if (cosmeticConcernsList?.length) {
      if (selected?.length) {
        dispatch(
          cosmeticConsultationReducers.select_condition({
            isValid: true,
          })
        );
      } else {
        dispatch(
          cosmeticConsultationReducers.select_condition({
            isValid: false,
          })
        );
      }
    }
  }, [dispatch, selected, cosmeticConcernsList]);

  useEffect(() => {
    queryConcerns();
  }, [queryConcerns]);

  useEffect(() => {
    if (concernsPayload?.conditions?.length) {
      setCosmeticConcernsList([
        ...concernsPayload?.conditions?.map((el) => ({
          label: el?.name,
          value: el?.description,
        })),
        {
          label: "Others",
          value: null,
        },
      ]);
    }
  }, [concernsPayload]);

  function updateConsultationApi(concernData) {
    updateConsultation({
      payload: {
        case: {
          id: incompleteData?.id,
          cosmetic_concern: concernData,
          cosmetic_steps: incompleteData?.cosmetic_steps,
        },
      },
      change_current_screen: true,
      screen_to_complete: cosmetic_steps.select_condition,
      callBack: () => {
        dispatch(
          cosmeticConsultationReducers.select_condition({
            value: concernData,
          })
        );
        nextAnimationRef?.current();
      },
    });
  }

  function onRightButtonClick(_, nextAnimation) {
    nextAnimationRef.current = nextAnimation;
    if (selected?.includes("Others")) {
      formRef?.current?.handleSubmit();
    } else {
      updateConsultationApi(selected);
    }
  }

  function onFormSubmit(values) {
    updateConsultationApi([
      ...selected?.filter((el) => el !== "Others"),
      values?.other_condition,
    ]);
  }
  function handleOnSelect(el) {
    if (el === "Others") {
      scrollToBottom();
    }
    let final = [...selected];
    if (final?.includes(el)) {
      setSelected(final.filter((elem) => elem !== el));
    } else {
      setSelected([...final, el]);
    }
  }

  function infoView(data, onClose) {
    return (
      <div className="flex items-center justify-center w-screen h-screen p-16 wrapper">
        <div className="relative p-8 bg-white rounded-1/4">
          <img
            className="absolute w-8 h-8 cursor-pointer right-4 top-4"
            onClick={onClose}
            src={Images.closeIconFilled}
            alt="close"
          />
          <h3 className="text-2xl font-bold text-center">{data?.label}</h3>
          <Spacer height={16} />
          <p
            className="text-xl font-light leading-8 text-center"
            dangerouslySetInnerHTML={{ __html: data?.value }}
          ></p>
        </div>
      </div>
    );
  }

  return (
    <StepScreenContainer align="start">
      <MedicalAssistant
        content={
          <p>
            Thank you, {userDetails?.first_name}. Now, what is your main concern
            today? You may also select any of the procedures that may interest
            you.
          </p>
        }
      />
      <Card>
        {gettingConcerns ? (
          <div className={"h-40 flex items-center justify-center"}>
            <CSSLoader className={"w-16 h-16"} color={"orange"} />
          </div>
        ) : (
          <CheckBoxCard
            infos={cosmeticConcernsList?.map((el) => el?.value)}
            showInfo={(info) => {
              setShowModal({
                state: true,
                children: infoView,
                data: info,
              });
            }}
            options={cosmeticConcernsList?.map((el) => el?.label)}
            selected={selected}
            onSelect={handleOnSelect}
          />
        )}
      </Card>
      {selected?.includes("Others") ? (
        <VisitSubQuestionSlideUpContainer>
          <Spacer height={40} />
          <Card>
            <Formik
              onSubmit={onFormSubmit}
              innerRef={formRef}
              validationSchema={OtherConditionSchema}
              initialValues={{
                other_condition: otherValue ?? "",
              }}
            >
              {() => (
                <Form>
                  <Input
                    id={"other_condition"}
                    name={"other_condition"}
                    label="Are you interested in discussing anything specific, or are you already planning to have one or more cosmetic procedures?"
                  />
                  <FormikErrorFocus
                    align={"bottom"}
                    ease={"linear"}
                    duration={500}
                  />
                </Form>
              )}
            </Formik>
          </Card>
        </VisitSubQuestionSlideUpContainer>
      ) : null}
    </StepScreenContainer>
  );
}

export default CosmeticSelectCondition;
