import React from "react";
import { MEDICAL_HISTORY_DATA } from "./medicalHistoryData";
import Accordion from "components/accordion/Accordion";
import Images from "assets/images";
import HealthProfileRadio from "../HealthProfileRadio";
import uniqBy from "lodash.uniqby";
import moment from "moment";
import Input from "components/inputs/Input";
import HealthProfileQuestion from "../HealthProfileQuestion";

const options = ["Current", "Past", "Never"];
const YESNO = ["Yes", "No"];

function HPMedicalHistoryType({
  setFieldValue,
  current_medical_history,
  past_medical_history,
  never_medical_history,
  isColonoscopy,
  colonoscopyDate,
}) {
  const AccordionHeader = ({ headerTitle, isActive }) => (
    <div className="flex justify-between items-start py-6 px-6 border-t border-borderColor">
      <h3 className="text-2xl font-medium">{headerTitle}</h3>
      <div>
        <img
          src={Images.dropdown}
          alt="chev_down"
          className={`transition duration-500 ease-in-out w-10 h-10 transform ${
            isActive ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>
    </div>
  );

  function onOptionClick({ data, disease, category, key }) {
    if (data?.length) {
      const findCategory = data?.find((his) => his?.category === category);
      if (findCategory) {
        if (findCategory?.diseases?.includes(disease)) {
          if (findCategory?.diseases?.length === 1) {
            setFieldValue(
              key,
              data?.filter((el) => el?.category !== category)
            );
          } else {
            setFieldValue(
              key,
              data?.map((el) => {
                if (el?.category === category) {
                  return {
                    ...el,
                    diseases: el?.diseases?.filter((elem) => elem !== disease),
                  };
                } else {
                  return el;
                }
              })
            );
          }
        } else {
          setFieldValue(
            key,
            data?.map((el) => {
              if (el?.category === category) {
                return { ...el, diseases: [...el?.diseases, disease] };
              } else {
                return el;
              }
            })
          );
        }
      } else {
        setFieldValue(key, [...data, { category, diseases: [disease] }]);
      }
    } else {
      setFieldValue(key, [{ category, diseases: [disease] }]);
    }
  }

  function removeNever({ disease, category }) {
    if (
      never_medical_history
        ?.find((his) => his?.category === category)
        ?.diseases?.includes(disease)
    ) {
      setFieldValue(
        "never_medical_history",
        never_medical_history?.map((his) => {
          if (his?.category === category) {
            return {
              ...his,
              diseases: his?.diseases?.filter((dis) => dis !== disease),
            };
          } else {
            return his;
          }
        })
      );
    }
  }

  function onNeverOptionClick({ disease, category }) {
    if (
      current_medical_history
        ?.find((his) => his?.category === category)
        ?.diseases?.includes(disease)
    ) {
      setFieldValue(
        "current_medical_history",
        current_medical_history?.map((his) => {
          if (his?.category === category) {
            return {
              ...his,
              diseases: his?.diseases?.filter((dis) => dis !== disease),
            };
          } else {
            return his;
          }
        })
      );
    }

    if (
      past_medical_history
        ?.find((his) => his?.category === category)
        ?.diseases?.includes(disease)
    ) {
      setFieldValue(
        "past_medical_history",
        past_medical_history?.map((his) => {
          if (his?.category === category) {
            return {
              ...his,
              diseases: his?.diseases?.filter((dis) => dis !== disease),
            };
          } else {
            return his;
          }
        })
      );
    }
  }

  const getSelected = ({ option, disease, category }) => {
    switch (option) {
      case "Current":
        if (
          current_medical_history
            ?.find((el) => el?.category === category)
            ?.diseases?.includes(disease)
        ) {
          return true;
        } else {
          return false;
        }
      case "Past":
        if (
          past_medical_history
            ?.find((el) => el?.category === category)
            ?.diseases?.includes(disease)
        ) {
          return true;
        } else {
          return false;
        }
      case "Never":
        if (
          never_medical_history
            ?.find((el) => el?.category === category)
            ?.diseases?.includes(disease)
        ) {
          return true;
        } else {
          return false;
        }
      default:
        break;
    }
  };

  function getSelectedLength({ category }) {
    let selectedLength = 0;
    let currentDiseases = [];
    const currentCategory = [
      ...current_medical_history,
      ...past_medical_history,
      ...never_medical_history,
    ]?.filter((el) => el?.category === category);
    currentCategory.forEach((el) => {
      currentDiseases.push(...el?.diseases);
    });
    selectedLength = uniqBy(currentDiseases)?.length;
    return selectedLength;
  }

  function accordionData() {
    return Object.keys(MEDICAL_HISTORY_DATA)?.map((category, index) => {
      const selectedLength = getSelectedLength({ category });
      return {
        header: ({ isActive }) => {
          return (
            <AccordionHeader
              key={index}
              isActive={isActive}
              headerTitle={`${category} ${
                selectedLength ? `( ${selectedLength} selected )` : ""
              } ${
                category === "GI / STOMACH" && isColonoscopy?.length
                  ? `(Colonoscopy : ${
                      isColonoscopy === "Yes" && colonoscopyDate
                        ? moment(colonoscopyDate, "YYYY-MM-DD").format(
                            "DD MMM, YYYY"
                          )
                        : isColonoscopy
                    })`
                  : ""
              }`}
            />
          );
        },
        content: (
          <div className="bg-alternativeWhite p-6">
            {category === "GI / STOMACH" ? (
              <div className="mb-6">
                <HealthProfileQuestion
                  text={"Have you ever had a colonoscopy?"}
                />
                <div className="flex flex-wrap mr-6 mb-6">
                  {YESNO?.map((option, optionIndex) => {
                    return (
                      <div className="mr-6 mt-6" key={optionIndex}>
                        <HealthProfileRadio
                          text={option}
                          onClick={() => setFieldValue("isColonoscopy", option)}
                          selected={isColonoscopy === option ? true : false}
                        />
                      </div>
                    );
                  })}
                </div>
                {isColonoscopy === "Yes" ? (
                  <Input
                    labelstyles={{
                      fontWeight: "500",
                      textOpacity: 1,
                      color: "rgba(16, 19, 71, 1)",
                    }}
                    label={"Colonoscopy Date (Required*)"}
                    value={colonoscopyDate}
                    type={"date"}
                    id="colonoscopyDate"
                    name="colonoscopyDate"
                    max={moment().format("YYYY-MM-DD")}
                  />
                ) : null}
              </div>
            ) : null}
            {MEDICAL_HISTORY_DATA?.[category]?.sort()?.map((disease, idx) => {
              return (
                <div key={idx}>
                  <p className="text-2xl font-medium">{disease}</p>
                  <div className="flex flex-wrap mr-6 mb-6">
                    {options?.map((option, optionIdx) => {
                      return (
                        <div className="mr-6 mt-6" key={optionIdx}>
                          <HealthProfileRadio
                            text={option}
                            selected={getSelected({
                              option,
                              disease,
                              category,
                            })}
                            onClick={() => {
                              if (option === "Current") {
                                onOptionClick({
                                  disease,
                                  category,
                                  data: current_medical_history,
                                  key: "current_medical_history",
                                });
                                removeNever({ disease, category });
                              } else if (option === "Past") {
                                onOptionClick({
                                  disease,
                                  category,
                                  data: past_medical_history,
                                  key: "past_medical_history",
                                });
                                removeNever({ disease, category });
                              } else {
                                onOptionClick({
                                  disease,
                                  category,
                                  data: never_medical_history,
                                  key: "never_medical_history",
                                });
                                onNeverOptionClick({
                                  disease,
                                  category,
                                });
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        ),
      };
    });
  }

  return <Accordion multiple data={accordionData()} />;
}

export default HPMedicalHistoryType;
