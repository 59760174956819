import { dermatology_sub_steps } from "helpers/enum";
import ConfirmResidenceForDerm from "./basicDetails/ConfirmResidenceForDerm";
import IdentityConfirmationForDerm from "./basicDetails/IdentityConfirmationForDerm";
import SelectChildForDerm from "./basicDetails/SelectChildForDerm";
import SelectPatientForDerm from "./basicDetails/SelectPatientForDerm";
import SelectSomeoneElse from "./basicDetails/SelectSomeoneElseForDerm";
import AllergiesForDerm from "./medicalHistory/AllergiesForDerm";
import FemaleMedicalHistory from "./medicalHistory/FemaleMedicalHistory";
import MedicalHistory from "./medicalHistory/MedicalHistory";
import Medications from "./medicalHistory/Medications";
import OtherFeedbacks from "./medicalHistory/OtherFeedbacks";
import SelectPharmacy from "./medicalHistory/SelectPharmacy";
import SkinCareProducts from "./medicalHistory/SkinCareProducts";
import UploadPhotos from "./photos/UploadPhotos";
import MoreAboutSymptoms from "./symptoms/MoreAboutSymptoms";
import SymptomsForDerm from "./symptoms/SymptomsForDerm";
import ConditionDetails from "./visitDetails/ConditionDetails";
import SelectAffectedPart from "./visitDetails/SelectAffectedPart";
import SelectConditionForDerm from "./visitDetails/SelectConditionForDerm";
import PatientBioForDerm from "./basicDetails/PatientBio";

export const getDermatologyStepScreen = (currentScreen) => {
  switch (currentScreen) {
    case dermatology_sub_steps.select_patient:
      return <SelectPatientForDerm />;
    case dermatology_sub_steps.select_child:
      return <SelectChildForDerm />;
    case dermatology_sub_steps.select_someone_else:
      return <SelectSomeoneElse />;
    case dermatology_sub_steps.confirm_residence:
      return <ConfirmResidenceForDerm />;
    case dermatology_sub_steps.patient_bio:
      return <PatientBioForDerm />;
    case dermatology_sub_steps.identity_confirmation:
      return <IdentityConfirmationForDerm />;
    case dermatology_sub_steps.select_condition:
      return <SelectConditionForDerm />;
    case dermatology_sub_steps.select_affected_part:
      return <SelectAffectedPart />;
    case dermatology_sub_steps.condition_details:
      return <ConditionDetails />;
    case dermatology_sub_steps.upload_photos:
      return <UploadPhotos />;
    case dermatology_sub_steps.symptoms:
      return <SymptomsForDerm />;
    case dermatology_sub_steps.more_about_symptoms:
      return <MoreAboutSymptoms />;
    case dermatology_sub_steps.medical_history:
      return <MedicalHistory />;
    case dermatology_sub_steps.female_medical_history:
      return <FemaleMedicalHistory />;
    case dermatology_sub_steps.allergies:
      return <AllergiesForDerm />;
    case dermatology_sub_steps.medications:
      return <Medications />;
    case dermatology_sub_steps.skin_care_products:
      return <SkinCareProducts />;
    case dermatology_sub_steps.select_pharmacy:
      return <SelectPharmacy />;
    case dermatology_sub_steps.other_feedbacks:
      return <OtherFeedbacks />;
    default:
      break;
  }
};
