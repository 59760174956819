import React, { useState } from "react";
import NewInsuranceBlock from "components/addInsuranceBlock/AddInsuranceBlock";
import Header from "components/header/Header";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Spacer from "components/Spacer/Spacer";
import { useCurrentUser } from "hooks/useCurrentUser";
import useDataFromUserDetails from "hooks/useDataFromUserDetails";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { speciality } from "helpers/enum";
import HelpButton from "../visitFlow/common/HelpButton";
import SelectProvider from "components/visitCommon/SelectProvider";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import useModal from "hooks/useModal";
import InsuranceDetails from "./insuranceDetails/InsuranceDetails";

function InsuranceAndProviderBlock() {
  const history = useHistory();
  const { userDetails, currentSpeciality } = useCurrentUser();
  const { hasNoPcProvider, hasNoDermaProvider } = useDataFromUserDetails();
  const [selectOption, setSelectedOption] = useState(
    currentSpeciality === 2 ? "select-provider" : "add-insurance"
  );
  const { setShowModal } = useModal();

  const handleRadioChange = (name) => {
    setSelectedOption(name);
  };
  const openInsuranceModal = () => {
    setShowModal({
      state: true,
      children: (_, onClose) => (
        <InsuranceDetails
          onClose={onClose}
          onComplete={() => {
            history.push("/visit/medical-intake/select-provider");
          }}
        />
      ),
      data: null,
    });
  };
  function onNext() {
    if (selectOption === "add-insurance") {
      openInsuranceModal();
    } else {
      return history.push("/visit/medical-intake/select-provider");
    }
  }
  useEffect(() => {
    if (
      (currentSpeciality === speciality.dermatology && !hasNoDermaProvider) ||
      (currentSpeciality === speciality.primary_care && !hasNoPcProvider)
    ) {
      history.goBack();
    }
  }, [history, currentSpeciality, hasNoDermaProvider, hasNoPcProvider]);

  return (
    <div className="h-full md:h-auto bg-veryLightBlue md:bg-gradient-to-b from-blue-300 to-blue-500">
      <div className="md:hidden">
        <Header title={"Medical Intake"} />
      </div>
      <div className="hidden md:block">
        <Header
          title={"Welcome to Medical Intake - Let's Get Started!"}
          className=" md:bg-white"
          bgColor="bg-white"
        />
      </div>
      <div className="overflow-y-auto bg-veryLightBlue md:bg-transparent wrapper">
        <div className="min-h-full pt-8 pb-48 bg-blue md:bg-transparent">
          <MedicalAssistant
            title="We're glad you've chosen SkyMD for you dermatology needs!"
            className={"mx-5 md:mx-0"}
            label="We're glad you've chosen SkyMD for your dermatology needs!"
            labelClass={"text-secondaryBlue-600"}
            content={
              userDetails?.patient_insurance ? (
                <p>
                  Let's connect you to the providers that can serve you best.
                </p>
              ) : (
                <p className="md:py-6 md:mr-24">
                  To ensure that we can provide you with{" "}
                  <strong className="text-secondaryBlue-600">
                    in-network coverage
                  </strong>
                  , please upload your health insurance information on the next
                  page. We accept most major health insurances, so you can have
                  peace of mind knowing that your care is covered.
                  <p>
                    Thank you for entrusting us with your health - we're here to
                    assist you every step of the way.
                  </p>
                </p>
              )
            }
          />
          <Spacer height={12} />
          <div className="py-0">
            <div className="mt-14 md:mt-8 mx-6">
              {currentSpeciality !== 2 ? (
                <>
                  <NewInsuranceBlock
                    isActive={selectOption === "add-insurance"}
                    insuranceDetails={userDetails?.patient_insurance}
                    onInsuranceClick={() => handleRadioChange("add-insurance")}
                    onEditInsuranceClick={() => {
                      history.push("/visit/medical-intake/insurance-details");
                    }}
                    onUpdateInsurance={openInsuranceModal}
                  />

                  <Spacer height="12" />
                </>
              ) : null}
              <Spacer height="12" />
              <SelectProvider
                providerType={"Dermatology"}
                onSelect={() => handleRadioChange("select-provider")}
                selected={selectOption === "select-provider"}
              />
            </div>
          </div>
        </div>
      </div>
      <HelpButton bottomClass="bottom-40" />
      <BottomFixedButton
        label="Continue"
        onClick={selectOption === "" ? null : onNext}
        bgColor="bg-white"
      />
    </div>
  );
}

export default InsuranceAndProviderBlock;
