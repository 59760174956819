import React from "react";
import { sendMailAction } from "api/actions/UserActions";
import { useApiMutation } from "hooks/useApiMutation";
import useToastify from "hooks/useToastify";
import { Form, Formik } from "formik";
import Input from "components/inputs/Input";
import MultiLineInput from "components/inputs/MultiLineInput";
import Button from "components/buttons/Button";
import { ContactUsSchema } from "helpers/validationSchema";
import Spacer from "components/Spacer/Spacer";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { useHistory } from "react-router-dom";
import FormikErrorFocus from "formik-error-focus";
import SimpleHeader from "components/header/SimpleHeader";

function ContactUs() {
  const history = useHistory();
  const { showAlert } = useToastify();
  const { mutate: callSendMailApi, loading } = useApiMutation(sendMailAction);

  async function onFormSubmit(values) {
    try {
      const result = await callSendMailApi({
        mail: {
          subject: values?.subject,
          body: values?.message,
        },
      });
      if (result.error || result.payload.error || result.payload.error_msg) {
        errorHandler(result.payload.error || result.payload.error_msg);
      } else {
        successHandler(result);
      }
    } catch (err) {
      errorHandler(err);
    }
  }

  function successHandler(result) {
    showAlert(result?.payload?.message, "success");
    history.goBack();
  }

  function errorHandler(message) {
    showAlert(message, "error");
  }

  return (
    <div className="h-screen bg-alternativeWhite">
      <SimpleHeader
        onBackButtonClick={() => {
          history.goBack();
        }}
        className="mx-2"
        title="Contact Us"
      />
      <div className="flex flex-col overflow-y-auto bg-white md:bg-transparent h-eliminateHeader wrapper">
        <div className="flex flex-col md:mt-32">
          <div className="p-10 bg-white">
            <Spacer height={20} />
            <MedicalAssistant
              content={<p>Please email us with any comments or questions.</p>}
            />
            <Formik
              onSubmit={onFormSubmit}
              validationSchema={ContactUsSchema}
              initialValues={{ subject: "", message: "" }}
            >
              {() => (
                <Form>
                  <Spacer height={32} />
                  <Input label={"Subject"} id="subject" name="subject" />
                  <Spacer height={32} />
                  <MultiLineInput
                    label={"Message"}
                    id="message"
                    name="message"
                  />
                  <Spacer height={32} />
                  <Button
                    type="submit"
                    loading={loading}
                    label={loading ? "Sending mail" : "Send mail"}
                  />
                  <FormikErrorFocus
                    align={"bottom"}
                    ease={"linear"}
                    duration={500}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
