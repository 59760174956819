import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Spacer from "components/Spacer/Spacer";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import useHealthProfile from "hooks/useHealthProfile";
import Accordion from "components/accordion/Accordion";
import Images from "assets/images";
import SimpleHeader from "components/header/SimpleHeader";

const PrescriptionHistory = () => {
  const history = useHistory();
  const { healthPayload } = useHealthProfile();

  const ProfileAccordionHeader = ({ headerTitle, isActive, show }) =>
    show ? (
      <div className="flex items-start justify-between px-0 py-10 border-t border-borderColor md:px-10">
        <h3 className="text-3xl font-medium text-left">{headerTitle}</h3>
        <div>
          <img
            src={Images.dropdown}
            alt="chev_down"
            className={`transition duration-500 ease-in-out w-10 h-10 transform ${
              isActive ? "rotate-180" : "rotate-0"
            }`}
          />
        </div>
      </div>
    ) : (
      <div />
    );

  const ProfileAccordionContent = ({ children }) => (
    <div className="px-0 pb-8 md:px-10">{children}</div>
  );

  const accordionData = [
    {
      header: ({ isActive }) => (
        <ProfileAccordionHeader
          headerTitle="Medication History"
          isActive={isActive}
          show={
            healthPayload?.prescription_history?.dosespot_medications.length >
              0 ||
            healthPayload?.prescription_history?.not_eprescribed_medications
              .length > 0
          }
        />
      ),
      content: (
        <ProfileAccordionContent>
          <table className="table w-full border border-collapse table-auto border-black-500">
            <thead>
              <tr>
                <th className="p-2 text-2xl font-bold text-left border border-black-500 md:p-4">
                  Case ID
                </th>
                <th className="p-2 text-2xl font-bold text-left border border-black-500 md:p-4">
                  Date
                </th>
                <th className="p-2 text-2xl font-bold text-left border border-black-500 md:p-4">
                  Channel
                </th>
                <th className="p-2 text-2xl font-bold text-left border border-black-500 md:p-4">
                  Name of Medication
                </th>
                <th className="p-2 text-2xl font-bold text-left border border-black-500 md:p-4">
                  Instruction
                </th>
              </tr>
            </thead>
            <tbody>
              {healthPayload?.prescription_history?.dosespot_medications
                .concat(
                  healthPayload?.prescription_history
                    ?.not_eprescribed_medications
                )
                .map((med, index) => (
                  <tr key={`first-${index}`}>
                    <td className="p-2 text-2xl font-medium border border-black-500 md:p-4">
                      <p
                        className="cursor-pointer text-blue"
                        onClick={() =>
                          history.push(`/visit-details/${med?.case_id}`)
                        }
                      >
                        {med?.case_id}
                      </p>
                    </td>
                    <td className="p-2 text-2xl font-medium border border-black-500 md:p-4">
                      {moment(med?.updated_at).format("MM/DD/YYYY")}
                    </td>
                    <td className="p-2 text-2xl font-medium border border-black-500 md:p-4">
                      {med?.dosespot_medication_id
                        ? "Dosespot"
                        : "Non-Dosespot"}
                    </td>
                    <td className="p-2 text-2xl font-medium border border-black-500 md:p-4">
                      {med?.name}
                    </td>
                    <td className="p-2 text-2xl font-medium border border-black-500 md:p-4">
                      {med?.detail}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </ProfileAccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <ProfileAccordionHeader
          headerTitle="Over-the-Counter Medication History"
          isActive={isActive}
          show={healthPayload?.prescription_history?.otc_medications.length > 0}
        />
      ),
      content: (
        <ProfileAccordionContent>
          <table className="table w-full border border-collapse table-auto border-black-500">
            <thead>
              <tr>
                <th className="p-2 text-2xl font-bold text-left border border-black-500 md:p-4">
                  Case ID
                </th>
                <th className="p-2 text-2xl font-bold text-left border border-black-500 md:p-4">
                  Date
                </th>
                <th className="p-2 text-2xl font-bold text-left border border-black-500 md:p-4">
                  Name of Medication
                </th>
                <th className="p-2 text-2xl font-bold text-left border border-black-500 md:p-4">
                  Instruction
                </th>
              </tr>
            </thead>
            <tbody>
              {healthPayload?.prescription_history?.otc_medications.map(
                (med, index) => (
                  <tr key={`third-${index}`}>
                    <td className="p-2 text-2xl font-medium border border-black-500 md:p-4">
                      <p
                        className="cursor-pointer text-blue"
                        onClick={() =>
                          history.push(`/visit-details/${med?.case_id}`)
                        }
                      >
                        {med?.case_id}
                      </p>
                    </td>
                    <td className="p-2 text-2xl font-medium border border-black-500 md:p-4">
                      {med?.date}
                    </td>
                    <td className="p-2 text-2xl font-medium border border-black-500 md:p-4">
                      {med?.name}
                    </td>
                    <td className="p-2 text-2xl font-medium border border-black-500 md:p-4">
                      {med?.instruction}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </ProfileAccordionContent>
      ),
    },
  ];

  return (
    <div className="h-screen bg-veryLightBlue">
      <SimpleHeader showBackButton title="Prescription History" />
      <div className="overflow-y-auto bg-white h-eliminateHeader">
        <div className="px-10">
          <Spacer height={20} />
          <MedicalAssistant
            content={<p>Here is the prescription history for this patient.</p>}
          />
          <Spacer height={40} />
          <Accordion data={accordionData} />
          <Spacer height={80} />
        </div>
      </div>
    </div>
  );
};

export default PrescriptionHistory;
