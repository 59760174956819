import { useContext } from "react";
import { HealthProviderContext } from "providers/HealthProfileProvider";

function useHealthProfile() {
  const context = useContext(HealthProviderContext);
  if (context === undefined) {
    throw new Error(
      "useHealthProfile must be used within a HealthProfileProvider"
    );
  }
  return context;
}

export default useHealthProfile;
