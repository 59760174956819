import React, { useEffect, useRef, useState } from "react";
import Images from "assets/images";

const ScrollableTabs = ({
  tabs,
  orderPending,
  selectedIndex,
  setSelectedIndex,
}) => {
  const scrollRef = useRef(null);

  const [scrollX, setScrollX] = useState(0);
  const [scrollEnd, setScrollEnd] = useState(false);

  useEffect(() => {
    //Check width of the scollings
    if (
      scrollRef.current &&
      scrollRef?.current?.scrollWidth === scrollRef?.current?.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
    return () => {};
  }, [scrollRef?.current?.scrollWidth, scrollRef?.current?.offsetWidth]);

  const slide = (shift) => {
    scrollRef.current.scrollLeft += shift;
    setScrollX(scrollX + shift); // Updates the latest scrolled postion

    //For checking if the scroll has ended
    if (
      Math.floor(
        scrollRef.current.scrollWidth - scrollRef.current.scrollLeft
      ) <= scrollRef.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const scrollCheck = () => {
    setScrollX(scrollRef.current.scrollLeft);
    if (
      Math.floor(
        scrollRef.current.scrollWidth - scrollRef.current.scrollLeft
      ) <= scrollRef.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  return (
    <div className="relative">
      <div
        ref={scrollRef}
        onScroll={scrollCheck}
        className="mt-6 px-12 overflow-scroll whitespace-nowrap no-scrollbar smooth-scroll"
      >
        {tabs.map((tab, index) => (
          <div
            key={`tab-${index}`}
            className={`inline-block ${
              selectedIndex.index === index
                ? "border-b-2 border-orange rounded-b-sm text-orange text-opacity-100 font-semibold "
                : ""
            }${
              index > 0 ? "ml-6 " : ""
            }leading-loose font-light text-2xl text-indigo text-opacity-60 cursor-pointer`}
            onClick={() => setSelectedIndex({ index: index, screen: tab })}
          >
            {tab === "Over-the-Counter Recommendations" && orderPending ? (
              <div className="flex space-x-4">
                <p>{tab}</p>
                <span className="bg-red flex items-center justify-center rounded-full w-6 h-6">
                  <p className="text-white text-sm font-semibold">1</p>
                </span>
              </div>
            ) : (
              <p>{tab}</p>
            )}
          </div>
        ))}
      </div>
      {scrollX !== 0 && (
        <button
          className="absolute top-0 bottom-0 left-0 mx-0 my-auto ml-6"
          onClick={() => slide(-200)}
        >
          <img
            className="transform rotate-180"
            src={Images.chevronRight}
            alt="scroll left"
          />
        </button>
      )}
      {!scrollEnd && (
        <button
          className="absolute top-0 right-0 bottom-0 mx-0 my-auto mr-6"
          onClick={() => slide(+200)}
        >
          <img src={Images.chevronRight} alt="scroll right" />
        </button>
      )}
    </div>
  );
};

export default ScrollableTabs;
