import TextWithBulletPoint from "../../TextWithBulletPoint";

export const getExerciseView = (payload) => {
  const values = {
    regular_exercise: payload?.physical_activity?.regular_exercise?.length
      ? payload?.physical_activity?.regular_exercise
      : null,
    exercise_type: payload?.physical_activity?.exercise_type?.length
      ? payload?.physical_activity?.exercise_type
      : null,
    no_of_minutes:
      typeof payload?.physical_activity?.no_of_minutes === "number"
        ? payload?.physical_activity?.no_of_minutes
        : null,
    frequency: payload?.physical_activity?.frequency?.length
      ? payload?.physical_activity?.frequency
      : null,
  };
  if (Object.keys(values)?.some((el) => values?.[el] !== null)) {
    return (
      <div className="p-8 bg-alternativeWhite border border-borderColor mt-8 rounded-xl">
        {values?.regular_exercise ? (
          <TextWithBulletPoint
            text={`Regular exercise : ${values?.regular_exercise}`}
          />
        ) : null}
        {values?.exercise_type ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Exercise type : ${values?.exercise_type}`}
          />
        ) : null}
        {values?.no_of_minutes ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Number of minutes : ${values?.no_of_minutes}`}
          />
        ) : null}
        {values?.frequency ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Per : ${values?.frequency}`}
          />
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};
