import { createClient } from "react-fetching-library";
import { cosmeticConsultationReducers } from "reducers/cosmetic/cosmeticConsultationReducer";
import { logoutUser } from "reducers/currentUserReducer";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { pedVisitFlowReducers } from "reducers/ped/pedVisitFlowReducer";
import { primaryCareVisitFlowReducers } from "reducers/primary_care/primaryCareVisitFlowReducer";
import ApiConfig from "./config";

const requestHostInterceptor = (host, userToken) => (_) => async (action) => {
  if (action?.config?.skipAuth) {
    return {
      ...action,
      endpoint: action?.endpoint?.startsWith("http")
        ? action?.endpoint
        : `${host}${action.endpoint}`,
    };
  }

  return {
    ...action,
    headers: {
      ...action.headers,
      "Access-Token": userToken,
      "Device-Platform": "Web",
    },
    endpoint: action?.endpoint?.startsWith("http")
      ? action?.endpoint
      : `${host}${action.endpoint}`,
  };
};

const responseInterceptor = (client, dispatch) => async (action, response) => {
  if (response?.status === 401) {
    dispatch(dermaVisitFlowReducers.reset());
    dispatch(primaryCareVisitFlowReducers.reset());
    dispatch(cosmeticConsultationReducers.reset());
    dispatch(pedVisitFlowReducers.reset());
    dispatch(logoutUser());
  }
  return response;
};

export function getApiClient(token, dispatch) {
  return createClient({
    //None of the options is required
    requestInterceptors: [requestHostInterceptor(ApiConfig.baseUrl, token)],
    responseInterceptors: [(client) => responseInterceptor(client, dispatch)],
  });
}
