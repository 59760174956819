import { step_status, visit_status } from "./enum";

export const getStepperData = ({ visitDetails }) => {
  const status = visitDetails?.current_status;
  const dataMap = new Map();

  dataMap.set(visit_status.complete_awaiting_ma, {
    status: step_status.upcoming,
    heading: "Review of Medical Intake",
    timeStamp: "",
    statusText: "Please wait while your visit details are being verified.",
  });
  dataMap.set(visit_status.pending, {
    status: step_status.upcoming,
    heading: "Doctor reviewing your case",
    timeStamp: "",
    statusText: "Please wait while your doctor reviewing your case.",
  });

  dataMap.set(visit_status.evaluated, {
    status: step_status.upcoming,
    heading: "Treatment plan ready",
    timeStamp: "",
    statusText: "Your treatment plan is ready.",
  });

  switch (status) {
    case visit_status.complete_awaiting_ma:
      dataMap.set(visit_status.complete_awaiting_ma, {
        ...dataMap.get(visit_status.complete_awaiting_ma),
        status: step_status.current,
        timeStamp: visitDetails?.submitted_to_ma_on,
      });
      return [...dataMap.values()];

    case visit_status.pending:
      dataMap.set(visit_status.complete_awaiting_ma, {
        ...dataMap.get(visit_status.complete_awaiting_ma),
        status: step_status.completed,
        statusText: "Your visit details are verified.",
        timeStamp: visitDetails?.submitted_to_ma_on,
      });
      dataMap.set(visit_status.pending, {
        ...dataMap.get(visit_status.pending),
        status: step_status.current,
        timeStamp: visitDetails?.submitted_at,
      });
      return [...dataMap.values()];

    case visit_status.evaluated:
      dataMap.set(visit_status.complete_awaiting_ma, {
        ...dataMap.get(visit_status.complete_awaiting_ma),
        status: step_status.completed,
        timeStamp: visitDetails?.submitted_to_ma_on,
        statusText: "Your visit details are verified.",
      });
      dataMap.set(visit_status.pending, {
        ...dataMap.get(visit_status.pending),
        status: step_status.completed,
        timeStamp: visitDetails?.submitted_at,
        statusText: "Doctor reviewed your case.",
      });
      dataMap.set(visit_status.evaluated, {
        ...dataMap.get(visit_status.evaluated),
        status: step_status.completed,
        timeStamp: visitDetails?.diagnosis?.diagnosed_at,
      });
      return [...dataMap.values()];

    case visit_status.rejected:
      dataMap.set(visit_status.complete_awaiting_ma, {
        ...dataMap.get(visit_status.complete_awaiting_ma),
        status: step_status.completed,
        timeStamp: visitDetails?.submitted_to_ma_on,
        statusText: "Your visit details are verified.",
      });
      dataMap.set(visit_status.pending, {
        ...dataMap.get(visit_status.pending),
        status: step_status.completed,
        timeStamp: visitDetails?.submitted_at,
        statusText: "Doctor reviewed your case.",
      });
      dataMap.set(visit_status.rejected, {
        rejected: true,
        status: step_status.current,
        heading: "Rejected",
        timeStamp: visitDetails?.rejected_at,
        statusText: "Your visit has been rejected.",
      });
      dataMap.delete(visit_status.evaluated);
      return [...dataMap.values()];

    default:
      dataMap.set(visit_status.complete_awaiting_ma, {
        ...dataMap.get(visit_status.complete_awaiting_ma),
        status: step_status.current,
        timeStamp: new Date(),
      });
      return [...dataMap.values()];
  }
};
