import React from "react";
import CSSLoader from "components/cssLoader/CSSLoader";

const AvailableOnSkySection = ({
  availableMeds,
  visitDetails,
  completeOrder,
  completingOrder,
  shippingAddress,
  changeShippingAddress,
}) => {
  return (
    <>
      <table
        className="px-0 w-full table table-auto border-separate bg-transparent md:px-6"
        style={{ borderSpacing: "0 10px" }}
      >
        <thead>
          <tr>
            <th className="p-2 font-bold text-left text-2xl md:p-4">
              Product and Instructions
            </th>
            <th className="p-2 font-bold text-center text-2xl md:p-4">Qty.</th>
            <th className="p-2 font-bold text-right text-2xl md:p-4">Price</th>
          </tr>
        </thead>
        <tbody>
          {availableMeds?.map((el, index) => {
            return (
              <tr key={index} className="bg-white">
                <td className="p-2 font-medium text-2xl md:p-4">
                  <div className="flex flex-col">
                    <p className="font-medium text-2xl text-indigo">
                      {el?.name}
                    </p>
                    <p className="mt-2 font-light text-xl text-indigo text-opacity-90">
                      {el?.instruction}
                    </p>
                  </div>
                </td>
                <td className="p-2 font-medium text-2xl text-center md:p-4">
                  {el?.quantity}
                </td>
                <td className="p-2 font-medium text-2xl text-right md:p-4">
                  ${el?.price}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex flex-col mt-6 px-2 md:px-10">
        <div className="flex justify-between items-center">
          <p className="font-medium text-2xl text-indigo">Subtotal</p>
          <p className="font-medium text-2xl text-indigo">
            ${visitDetails?.diagnosis?.otc_med_sub_total_amt}
          </p>
        </div>
        <div className="flex justify-between items-center mt-6">
          <p className="font-medium text-2xl text-indigo">
            Tax ({visitDetails?.diagnosis?.tax?.state} -{" "}
            {visitDetails?.diagnosis?.tax?.number})
          </p>
          <p className="font-medium text-2xl text-indigo">
            ${visitDetails?.diagnosis?.tax?.amount}
          </p>
        </div>
        <div className="flex justify-between items-center mt-6">
          <p className="font-medium text-2xl text-indigo">Shipping</p>
          <p className="font-medium text-2xl text-indigo">
            ${visitDetails?.diagnosis?.shipping_fee}
          </p>
        </div>
        <div className="w-full h-px bg-lightGrey mt-6" />
        <div className="flex justify-between items-center mt-6">
          <p className="font-medium text-2xl text-indigo">Total</p>
          <p className="font-bold text-2xl text-indigo">
            ${visitDetails?.diagnosis?.otc_med_total_price}
          </p>
        </div>
        {visitDetails?.diagnosis?.order ? (
          <>
            <div className="w-full h-px bg-lightGrey my-6" />
            <div className="flex justify-between items-center">
              <div className="flex space-x-3">
                <p className="font-light text-2xl">Tracking ID</p>
                <p className="font-bold text-2xl text-black">
                  {visitDetails?.diagnosis?.otc_order?.shipping
                    ? visitDetails?.diagnosis?.order?.shipping_id
                    : "Pending"}
                </p>
              </div>
              {visitDetails?.diagnosis?.otc_order?.shipping ? (
                <a
                  className="font-light text-2xl text-orange no-underline"
                  href={
                    visitDetails?.diagnosis?.otc_order?.shipping?.tracking_link
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  Track your order
                </a>
              ) : (
                <p className="font-light text-2xl text-orange text-opacity-40 cursor-not-allowed">
                  Track your order
                </p>
              )}
            </div>
            {visitDetails?.diagnosis?.order?.receipt_url ? (
              <>
                <div className="w-full h-px bg-lightGrey my-6" />
                <a
                  href={visitDetails?.diagnosis?.order?.receipt_url}
                  target="_blank"
                  rel="noreferrer"
                  className="font-medium text-2xl underline cursor-pointer"
                >
                  Copy of your purchase receipt
                </a>
              </>
            ) : (
              <div />
            )}
          </>
        ) : (
          <div />
        )}
        {!visitDetails?.diagnosis?.order ? (
          <>
            <div className="w-full h-px bg-lightGrey my-6" />
            <div className="flex justify-between items-start mb-6">
              <div className="flex flex-col">
                <p className="font-bold text-2xl">SHIP TO</p>
                <p className="text-3xl font-medium text-indigo mt-2">
                  {shippingAddress?.line_1}
                  {shippingAddress.line_2 ? `, ${shippingAddress?.line_2}` : ""}
                  , {shippingAddress?.city}, {shippingAddress?.state},{" "}
                  {shippingAddress?.zip}
                </p>
              </div>
              <button
                className="block bg-transparent border-none font-bold text-2xl text-orange md:hidden"
                onClick={changeShippingAddress}
              >
                CHANGE
              </button>
              <button
                className="hidden bg-transparent border-none font-bold text-2xl text-orange md:block"
                onClick={changeShippingAddress}
              >
                CHANGE ADDRESS
              </button>
            </div>
            <div className="w-full h-px bg-lightGrey mb-6" />
            <button
              disabled={completingOrder}
              className={`mt-6 flex items-center justify-center bg-orange p-4 font-bold text-white text-2xl rounded-lg${
                completingOrder ? " opacity-60 cursor-not-allowed" : ""
              }`}
              onClick={completeOrder}
            >
              Confirm Purchase
              {completingOrder ? (
                <CSSLoader className={"ml-3 w-8 h-8"} />
              ) : null}
            </button>
          </>
        ) : (
          <div className="mt-6" />
        )}
      </div>
    </>
  );
};

export default AvailableOnSkySection;
