import React, { useState } from "react";
import CardPayments from "components/payment/CardPayments";
// import useDataFromUserDetails from "hooks/useDataFromUserDetails";
// import Placeholder from "components/placeholders/Placeholder";
import CSSLoader from "components/cssLoader/CSSLoader";
import SimpleHeader from "components/header/SimpleHeader";

function Payments() {
  const [updatingPayment, setUpdatingPayment] = useState(false);
  // const { dermaDoctorDetails, pcDoctorDetails } = useDataFromUserDetails();
  return (
    <div className="relative h-screen bg-veryLightBlue">
      <SimpleHeader showBackButton title="Payments" />
      <div className="h-eliminateHeaderWithBottomTab md:h-eliminateHeader">
        <div className="h-full px-10 bg-white ">
          {/* {dermaDoctorDetails?.provider_id || pcDoctorDetails?.provider_id ? ( */}
          <CardPayments setAddingCard={setUpdatingPayment} />
          {/* ) : (
            <Placeholder
              heading={"No provider selected."}
              paragraph={
                "Cannot access payment details while you are not connected to a provider"
              }
            />
          )} */}
        </div>
      </div>
      {updatingPayment ? (
        <div className="absolute top-0 left-0 z-20 flex items-center justify-center w-full h-full bg-opacity-50 bg-alternativeWhite">
          <CSSLoader color={"orange"} className="w-12 h-12" />
        </div>
      ) : null}
    </div>
  );
}

export default Payments;
