export const bodyParts = [
  "Abdomen",
  "Ankle - Left ",
  "Ankle - Right",
  "Anus",
  "Arm - Lower Left",
  "Arm - Lower Right",
  "Arm - Upper Left",
  "Arm - Upper Right",
  "Back - Lower",
  "Back - Upper",
  "Belly Button",
  "Buttocks",
  "Cheek - Left",
  "Cheek - Right",
  "Chest",
  "Chin",
  "Ear - Left",
  "Ear - Right",
  "Elbow - Back Left ",
  "Elbow - Back Right",
  "Elbow - Front Left",
  "Elbow - Front Right",
  "Eyelid - Left",
  "Eyelid - Right",
  "Face",
  "Finger - Index (2nd) Left",
  "Finger - Index (2nd) Right",
  "Finger - Middle (3rd) Left",
  "Finger - Middle (3rd) Right",
  "Finger - Pinky (5th) Left",
  "Finger - Pinky (5th) Right",
  "Finger - Ring (4th) Left",
  "Finger - Ring (4th) Right",
  "Finger - Thumb Left ",
  "Finger - Thumb Right",
  "Fingernail - Index (2nd) Left",
  "Fingernail - Index (2nd) Right",
  "Fingernail - Middle (3rd) Left",
  "Fingernail - Middle (3rd) Right",
  "Fingernail - Pinky (5th) Left",
  "Fingernail - Pinky (5th) Right",
  "Fingernail - Ring (4th) Left",
  "Fingernail - Ring (4th) Right",
  "Fingernail - Thumb Left",
  "Fingernail - Thumb Right",
  "Foot - Between Toes Left",
  "Foot - Between Toes Right",
  "Foot - Sole Left",
  "Foot - Sole Right",
  "Foot - Top Left",
  "Foot - Top Right",
  "Forehead",
  "Groin",
  "Hand - Back Left",
  "Hand - Back Right",
  "Hand - Palm Left",
  "Hand - Palm Right",
  "Knee - Back Left",
  "Knee - Back Right",
  "Knee - Front Left",
  "Knee - Front Right",
  "Leg - Lower Left",
  "Leg - Lower Right",
  "Leg - Upper Left",
  "Leg - Upper Right",
  "Lips",
  "Mouth ",
  "Neck - Back",
  "Neck - Front",
  "Nipple - Left",
  "Nipple - Right",
  "Nose",
  "Pubic Area",
  "Scalp",
  "Shoulder - Left",
  "Shoulder - Right",
  "Toe 2nd - Left",
  "Toe 2nd - Right",
  "Toe 3rd - Left",
  "Toe 3rd - Right",
  "Toe 4th - Left",
  "Toe 4th - Right",
  "Toe 5th - Left",
  "Toe 5th - Right",
  "Toe Big - Left",
  "Toe Big - Right",
  "Toenail 2nd - Left",
  "Toenail 2nd - Right",
  "Toenail 3rd - Left",
  "Toenail 3rd - Right",
  "Toenail 4th - Left",
  "Toenail 4th - Right",
  "Toenail 5th - Left",
  "Toenail 5th - Right",
  "Toenail Great - Left",
  "Toenail Great - Right",
];
