import React from "react";

import Images from "assets/images";
import { useHistory } from "react-router-dom";

function RevisedPaymentBlock({
  visitDetails,
  practiceInsurance,
  revisedInsuranceDetails,
}) {
  const history = useHistory();

  return (
    <div
      onClick={() => {
        if (visitDetails?.speciality === "pediatric") {
          history.push("/visit/pediatrics/accept-price-revision", {
            practiceInsurance,
            revisedInsuranceDetails,
            providerName: visitDetails?.provider_name,
            patientName: visitDetails?.patient_first_name,
          });
        } else if (visitDetails?.speciality === "dermatology") {
          history.push("/visit/dermatology/accept-price-revision", {
            practiceInsurance,
            revisedInsuranceDetails,
            providerName: visitDetails?.provider_name,
            patientName: visitDetails?.patient_first_name,
          });
        } else {
          history.push("/visit/primary-care/accept-price-revision", {
            practiceInsurance,
            revisedInsuranceDetails,
            providerName: visitDetails?.provider_name,
            patientName: visitDetails?.patient_first_name,
          });
        }
      }}
      className="mt-10 cursor-pointer flex items-center p-6 rounded-4xl bg-veryLightBlue border border-orange"
    >
      <img className="h-24 w-24" src={Images.aboutIcon} alt="attention" />
      <div className="ml-6">
        <h2 className="font-bold text-3xl">
          Click Here to Review Revised Payment Details
        </h2>
        <p className="font-light text-2xl leading-8 mt-2">
          {revisedInsuranceDetails?.price_change_type ===
          "unsupported_insurance"
            ? "Your unsupported insurance price revision is awaiting confirmation."
            : "Your updated SkyMD price estimate is awaiting confirmation."}
        </p>
      </div>
    </div>
  );
}

export default RevisedPaymentBlock;
