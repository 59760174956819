import React, { useCallback } from "react";
import VisitStepContainer from "components/visitStepContainer/VisitStepContainer";
import { useCosmeticConsultationFlow } from "hooks/useConsultationFlow";
import { useDispatch } from "react-redux";
import { cosmeticConsultationReducers } from "reducers/cosmetic/cosmeticConsultationReducer";
import { getCosmeticStepScreen } from "./getCosmeticStepScreen";
import { speciality } from "helpers/enum";
import useModal from "hooks/useModal";
import Alert from "components/alerts/Alert";
import { useApiMutation } from "hooks/useApiMutation";
import { discardVisit } from "api/actions/VisitActions";
import { setUserDetails } from "reducers/currentUserReducer";
import { useCurrentUser } from "hooks/useCurrentUser";

function CosmeticFlow() {
  const dispatch = useDispatch();
  const {
    incompleteData,
    currentScreen,
    steps,
  } = useCosmeticConsultationFlow();
  const { setShowModal } = useModal();
  const { mutate: callDiscardVisit } = useApiMutation(discardVisit);
  const { userDetails } = useCurrentUser();

  const confirmDeleteVisit = useCallback(async () => {
    try {
      const response = await callDiscardVisit(incompleteData?.id);
      if (response?.error === false) {
        dispatch(cosmeticConsultationReducers.reset());
        dispatch(
          setUserDetails({
            userDetails: { ...userDetails, incomplete_visit_id: null },
          })
        );
        window.location.replace("/home");
      }
    } catch (error) {}
  }, [callDiscardVisit, dispatch, incompleteData?.id, userDetails]);

  const deleteVisit = () => {
    setShowModal({
      state: true,
      children: (_, onClose) => (
        <Alert
          heading="Delete visit"
          message={"Are you sure you want to delete your visit?"}
          primaryButtonText="Confirm"
          onSecondaryButtonClick={onClose}
          onPrimaryButtonClick={() => {
            onClose();
            confirmDeleteVisit();
          }}
        />
      ),
      data: null,
    });
  };
  return (
    <VisitStepContainer
      speciality="cosmetic_care"
      incompleteData={incompleteData}
      doctorDetails={
        incompleteData?.meta?.booking_info
          ? {
              provider_id:
                incompleteData?.meta?.booking_info?.selectedProvider?.id,
              provider_photo_url:
                incompleteData?.meta?.booking_info?.selectedProvider?.avatar,
              provider_name:
                incompleteData?.meta?.booking_info?.selectedProvider?.name,
              speciality: speciality.cosmetic,
            }
          : null
      }
      currentScreen={currentScreen}
      subSteps={steps}
      onClickBack={(backAnimation) => {
        backAnimation();
        setTimeout(() => {
          dispatch(
            cosmeticConsultationReducers.currentScreen({
              value: steps?.[currentScreen]?.prevScreen,
            })
          );
        }, 300);
      }}
      rightActionView={
        <button
          className="px-6 py-4 text-xl font-medium rounded-md cursor-pointer text-red bg-red bg-opacity-10"
          onClick={deleteVisit}
        >
          Delete
        </button>
      }
    >
      {getCosmeticStepScreen(currentScreen)}
    </VisitStepContainer>
  );
}

export default CosmeticFlow;
