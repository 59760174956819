import React, { useEffect, useState } from "react";

import Images from "assets/images";
import Input from "components/inputs/Input";
import Spacer from "components/Spacer/Spacer";
import Button from "components/buttons/Button";

import {
  phoneAction,
  resendOTPAction,
  verifyCaptchaAction,
  verifyOTPAction,
} from "api/actions/AuthActions";

import { useApiMutation } from "hooks/useApiMutation";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { OTPInputSchema } from "helpers/validationSchema";
import { signinUser } from "reducers/currentUserReducer";
import { useHistory } from "react-router-dom";
import useToastify from "hooks/useToastify";
import FormikErrorFocus from "formik-error-focus";
import { input_types, login_type } from "helpers/enum";
import { SignupContext } from "providers/SignupProvider";
import TimerButton from "stories/TimerButton";
import { trackEvent } from "helpers/analytics";

function OTPVerifyPage() {
  const SITE_KEY = "6LeyRqAlAAAAAHsdvf5uhwk0fPk4QBKkDeS43mFN";
  const history = useHistory();
  const { showAlert } = useToastify();

  const { mutate: callGenerateOTPApi } = useApiMutation(phoneAction);
  const { mutate: callResendOTPApi, loading: resendOTPLoader } = useApiMutation(
    resendOTPAction
  );
  const state = history?.location?.state;
  let number = state?.number ?? "+1";
  const dispatch = useDispatch();
  const { mutate: callOTPVerify, loading: submitting } = useApiMutation(
    verifyOTPAction
  );
  const {
    mutate: callCaptchaVerify,
    loading: captchaVerifyLoading,
  } = useApiMutation(verifyCaptchaAction);
  const initValues = {
    mobile_number: number,
    otp_input: "",
  };
  const { formDispatch } = React.useContext(SignupContext);
  const [recaptchaLoader, setRecaptchaLoader] = useState(false);
  const [wrongOTP, setWrongOTP] = useState(false);
  const [wrongOTPMessage, setWrongOTPMessage] = useState("");

  useEffect(() => {
    if (number === "+1") {
      history.replace("/phone");
    }
  });

  async function onSubmit(values, setErrors) {
    try {
      const result = await callOTPVerify({
        mobile_number: values.mobile_number?.substring(1),
        otp: values.otp_input,
      });
      if (result.error || result.payload.error || result.payload.error_msg) {
        if (result.payload.error_code === "invalid_otp") {
          setErrors({ otp_input: "OTP you entered is invalid" });
        }
        setWrongOTP(true);
        setWrongOTPMessage(result.payload.error || result.payload.message);
        errorHandler(result.payload.error || result.payload.message);
      } else {
        if (result.payload.auth_token && result.payload.id) {
          dispatch(
            signinUser({
              userToken: result.payload.auth_token,
              userId: result.payload.id,
            })
          );
          history.replace("/home");
        } else {
          showAlert(result.payload.message, "success");
          formDispatch({
            type: "FIRST_STEP",
            payload: { ...values, login_via: login_type.PHONE },
          });
          history.replace("/signup");
        }
      }
    } catch (err) {
      errorHandler(err);
    }
  }
  const handleSubmit = async (values, { setErrors }) => {
    setRecaptchaLoader(true);
    window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute(SITE_KEY, {
          action: "verifyotp",
        })
        .then(
          async (token) => {
            const result = await callCaptchaVerify({
              token: token,
            });
            if (result.error) {
              showAlert(result.payload?.message, "error");
            } else {
              onSubmit(values, setErrors);
            }
            setRecaptchaLoader(false);
          },
          (error) => {
            setRecaptchaLoader(false);
            showAlert(error, "error");
            trackEvent("recaptcha_error", {
              number: initValues?.mobile_number,
            });
          }
        );
    });
  };

  function errorHandler(message) {
    showAlert(message, "error");
  }
  function onEdit() {
    let currentUrl = window.location.href;
    setTimeout(() => {
      if (currentUrl === window.location.href) {
        history.replace(`/phone/${number}`);
      }
    }, 500);
  }

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {
        console.log("Script loaded!");
      }
    );
  }, []);

  async function generateOTP(resend = true) {
    try {
      const result = !resend
        ? await callGenerateOTPApi({
            mobile_number: number?.substring(1),
          })
        : await callResendOTPApi({
            mobile_number: number?.substring(1),
          });
      if (result.error || result.payload.error || result.payload.error_msg) {
        if (result.payload.error_code === "otp_limit_exceed") {
          const otpError = document.getElementById("otp-error");
          otpError.innerText = "OTP limit exceeded for the day";
        }
        errorHandler(result.payload.message);
      } else {
        showAlert(result.payload.message, "success");
      }
    } catch (err) {
      errorHandler(err);
    }
  }

  const formattedNumber = number.replace(
    /(\d{1})(\d{3})(\d{3})(\d{4})/,
    "+$1 ($2) $3-$4"
  );

  return (
    <div className="flex flex-col justify-center h-screen bg-veryLightBlue md:bg-gradient-to-b from-blue-300 to-blue-500">
      <div className="w-full h-full mx-auto overflow-y-auto md:h-auto md:max-w-2xl bg-blue md:bg-white md:p-10 login-card md:rounded-xl">
        <div className="bg-alternativeWhite md:bg-transparent">
          <div className="flex flex-col items-center justify-center px-0 py-10 md:py-0">
            <img
              className="object-contain h-44 w-44"
              src={Images.logoVerticalNaming}
              alt="logo"
            />
            <p className="mt-6 text-4xl font-medium leading-8 md:mt-8 md:font-bold">
              OTP Verfication
            </p>
            <p className="px-10 pt-4 pb-0 text-3xl font-medium text-center text-themeBlack md:px-0 md:pb-8 md:text-xl">
              Enter the OTP sent to {formattedNumber}{" "}
              <span
                className="underline cursor-pointer text-secondaryBlue-600"
                onClick={() => onEdit()}
              >
                Edit
              </span>
            </p>
          </div>
        </div>
        <Formik
          initialValues={initValues}
          validationSchema={OTPInputSchema}
          validateOnMount={true}
          onSubmit={handleSubmit}
        >
          {({ isValid, values, setValues }) => (
            <Form className="px-12 py-12 pb-32 md:px-0 md:py-0">
              <div className="p-12 bg-white md:p-0 rounded-3xl">
                <div className="md:px-24">
                  <Input
                    name="otp_input"
                    type={input_types.OTP}
                    label="OTP*"
                    id="otp_input"
                    placeholder="1234"
                    value={values?.otp_input}
                    length={4}
                    onChangeOTP={(e) => {
                      setWrongOTP(false);
                      setWrongOTPMessage("");
                      setValues({ ...values, otp_input: e });
                    }}
                    error={wrongOTP}
                  />
                  {wrongOTP ? (
                    <p className="mt-2 text-base font-bold text-red">
                      {wrongOTPMessage}
                    </p>
                  ) : null}
                </div>
                <Spacer height={25} />
                <Button
                  type="submit"
                  label={"Verify"}
                  loading={
                    submitting ||
                    captchaVerifyLoading ||
                    recaptchaLoader ||
                    resendOTPLoader
                  }
                  disabled={
                    submitting ||
                    !isValid ||
                    captchaVerifyLoading ||
                    recaptchaLoader ||
                    resendOTPLoader
                  }
                  className={"h-18"}
                />

                <div className="flex justify-center mt-10 space-x-2">
                  <TimerButton
                    buttonLabel={"Resend OTP"}
                    onClick={() => generateOTP(true)}
                    className={"text-2xl"}
                    disabledText="Request a new OTP in  "
                    activeText="Didn't receive the OTP?"
                  />
                </div>
              </div>
              <FormikErrorFocus
                align={"bottom"}
                ease={"linear"}
                duration={500}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default OTPVerifyPage;
