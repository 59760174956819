import { step_status } from "./enum";

export function setCosmeticStepStatus({
  result_payload,
  screen_to_complete,
  current_screen_state,
}) {
  let resultDuplicated;

  if (current_screen_state?.nextScreen) {
    resultDuplicated = {
      ...result_payload,
      case: {
        ...result_payload?.case,
        cosmetic_steps: {
          ...result_payload?.case?.cosmetic_steps,
          [screen_to_complete]: step_status.completed,
          [current_screen_state?.nextScreen]: checkAlreadyCompleted({
            screen_name: current_screen_state?.nextScreen,
            cosmetic_steps: result_payload?.case?.cosmetic_steps,
          }),
        },
      },
    };
  } else {
    resultDuplicated = {
      ...result_payload,
      case: {
        ...result_payload?.case,
        cosmetic_steps: {
          ...result_payload?.case?.cosmetic_steps,
          [screen_to_complete]: step_status.completed,
        },
      },
    };
  }
  return resultDuplicated;
}

export function checkAlreadyCompleted({ screen_name, cosmetic_steps }) {
  if (
    cosmetic_steps?.[screen_name] &&
    cosmetic_steps?.[screen_name] === step_status.completed
  ) {
    return step_status.completed;
  } else {
    return step_status.current;
  }
}
