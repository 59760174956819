import React from "react";
import ApiConfig from "api/config";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import DoctorCard from "components/doctorCard/DoctorCard";
import Header from "components/header/Header";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Spacer from "components/Spacer/Spacer";
import { speciality, step_status } from "helpers/enum";
import { useCurrentUser } from "hooks/useCurrentUser";
import useDataFromUserDetails from "hooks/useDataFromUserDetails";
import useToastify from "hooks/useToastify";
import { useHistory } from "react-router-dom";
import VerticalStepper from "components/verticalStepper/VerticalStepper";
import moment from "moment";

function VisitSubmitted() {
  const history = useHistory();
  const state = history?.location?.state;
  const { dermaDoctorDetails } = useDataFromUserDetails();
  const { userDetails } = useCurrentUser();
  const { showAlert } = useToastify();
  const referralUrl = `${ApiConfig.baseUrl}r/${userDetails.invite_code}`;

  const share = async () => {
    if (navigator.share) {
      await navigator.share({
        title: "Try out SkyMD - online medical care application",
        text: "Use my code below to get exclusive offers",
        url: referralUrl,
      });
    } else {
      await navigator.clipboard.writeText(referralUrl);
      showAlert("Referral link copied to clipboard", "info");
    }
  };

  return (
    <div className="h-screen overflow-hidden bg-veryLightBlue">
      <Header hideBackBtn title="Visit Submitted" />
      <div className="overflow-y-auto bg-white h-eliminateHeaderWithFooter wrapper">
        <div className="px-12 pt-8">
          <MedicalAssistant
            content={
              state?.speciality === speciality?.dermatology ? (
                <p>
                  Congrats, {state?.currentPatient?.first_name}! Your visit has
                  been submitted.
                </p>
              ) : (
                <p>
                  Congrats, {state?.currentPatient?.first_name}! Your video
                  appointment has been scheduled for{" "}
                  {moment(
                    state?.bookingInfo?.selectedDate,
                    "YYYY-MM-DD"
                  ).format("MMMM DD, YYYY")}{" "}
                  at{" "}
                  {moment(
                    state?.bookingInfo?.slotInfo?.start_time,
                    "hh:mma"
                  ).format("LT")}
                  .
                </p>
              )
            }
          />
          <Spacer height={20} />
          <h3 className="pb-4 text-2xl font-black tracking-wide">
            YOUR VISIT WITH
          </h3>
          <div className="p-5 rounded-4xl border-1/4 border-borderColor">
            <DoctorCard
              imageUrl={
                state?.speciality === speciality?.dermatology
                  ? dermaDoctorDetails?.provider_photo_url
                  : state?.bookingInfo?.selectedProvider?.avatar
              }
              name={
                state?.speciality === speciality?.dermatology
                  ? dermaDoctorDetails?.provider_name
                  : state?.bookingInfo?.selectedProvider?.name
              }
              speciality={
                state?.speciality === speciality?.dermatology
                  ? dermaDoctorDetails?.speciality
                  : state?.bookingInfo?.selectedProvider?.speciality
              }
              isActive={true}
              license={
                state?.speciality === speciality?.dermatology
                  ? dermaDoctorDetails?.license
                  : state?.bookingInfo?.selectedProvider?.license
              }
            />
          </div>
        </div>
        <div className="px-12">
          <Spacer height={20} />
          <h3 className="pb-4 text-2xl font-black tracking-wide">STATUS</h3>
          {state?.speciality === speciality?.primary_care ||
          state?.speciality === speciality?.ped ? (
            <VerticalStepper
              data={[
                {
                  status: step_status.completed,
                  heading: "Appointment booked",
                  timeStamp: new Date(),
                  statusText: "",
                },
                {
                  status: step_status.completed,
                  heading: "Completed your medical intake",
                  timeStamp: new Date(),
                  statusText: "",
                },
                {
                  status: step_status.upcoming,
                  heading: "Review of your medical intake by the doctor",
                },
                {
                  status: step_status.upcoming,
                  heading: `Meet with your doctor on ${moment(
                    state?.bookingInfo?.selectedDate,
                    "YYYY-MM-DD"
                  ).format("MMMM DD, YYYY")} at ${moment(
                    state?.bookingInfo?.slotInfo?.start_time,
                    "hh:mma"
                  ).format("LT")}
                  `,
                },
                {
                  status: step_status.upcoming,
                  subtitle: "Visit completed",
                  heading: "Receive Treatment plan",
                },
              ]}
            />
          ) : (
            <VerticalStepper
              data={[
                {
                  status: step_status.current,
                  heading: "Review of Medical Intake",
                  timeStamp: new Date(),
                  statusText:
                    "Please wait while your visit details are being verified.",
                },
                {
                  status: step_status.upcoming,
                  heading: "Doctor reviewing your case",
                },
                {
                  status: step_status.upcoming,
                  heading: "Treatment plan ready",
                },
              ]}
            />
          )}
        </div>
        <Spacer height={20} />
        <div className="bg-alternativeWhite py-7">
          <div className="px-12">
            {state?.speciality === speciality?.dermatology ? (
              <p className="text-2xl font-medium leading-10 text-center">
                Visits are usually evaluated within 24 hours but it can take up
                to 2 business days to receive your evaluation. You will receive
                an email when your personalized treatment plan is ready.
              </p>
            ) : (
              <p className="text-2xl font-medium leading-10 text-center">
                While waiting for your appointment, please complete your{" "}
                <span
                  className="font-bold underline cursor-pointer"
                  onClick={() => {
                    history.replace({
                      pathname: `/home/profile/health-profile/${state?.currentPatient?.id}`,
                    });
                  }}
                >
                  health profile
                </span>
                . Your doctor needs a full medical history from every new
                patient before seeing them.
              </p>
            )}
          </div>
        </div>
        <Spacer height={20} />
        <div className="flex flex-col items-center px-12 py-8">
          <h2 className="text-3xl font-medium leading-10 text-center">
            Meanwhile do you mind letting your friends know about SkyMD?
          </h2>
          <Spacer height={30} />
          <div
            onClick={share}
            className="relative flex items-center justify-center p-8 cursor-pointer bg-blue rounded-2xl"
          >
            <p className="text-3xl font-bold leading-10 text-center text-white break-all">
              {referralUrl}
            </p>
            <div className="absolute w-6 h-6 bg-white -left-3 rounded-2xl"></div>
            <div className="absolute w-6 h-6 bg-white -right-3 rounded-2xl"></div>
          </div>
          <Spacer height={25} />
          <p className="text-2xl font-light leading-10">
            Tap above to copy link.
          </p>
        </div>
      </div>
      <BottomFixedButton
        onClick={() => {
          sessionStorage.setItem("selectedTabIndex", 1);
          history.push("/home/my-visits");
        }}
        label="Go to My Visits"
      />
    </div>
  );
}

export default VisitSubmitted;
