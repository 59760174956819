import React, { createContext, useMemo } from "react";
import {
  getUserHealthProfileAction,
  updateUserHealthProfileAction,
} from "api/actions/UserActions";
import { useParameterizedQuery } from "react-fetching-library";
import { useApiMutation } from "hooks/useApiMutation";
import useToastify from "hooks/useToastify";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";

export const HealthProviderContext = createContext({});

function HealthProfileProvider({ children }) {
  const { showAlert } = useToastify();
  const [healthPayload, setHealthPayload] = useState(null);

  const {
    loading: gettingUserHealthProfile,
    payload: healthPayloadFromGet,
    query: getHealthProfile,
  } = useParameterizedQuery(getUserHealthProfileAction);

  const {
    mutate: updateUserHealth,
    loading: postingHealthProfile,
  } = useApiMutation(updateUserHealthProfileAction);

  useEffect(() => {
    if (healthPayloadFromGet) {
      setHealthPayload({ ...healthPayloadFromGet });
    }
  }, [healthPayloadFromGet]);

  const postHealthProfile = useCallback(
    async ({ patientId, payload, completed }) => {
      try {
        const result = await updateUserHealth({
          patientId,
          payload,
        });
        if (result?.error === false) {
          setHealthPayload(result?.payload);
          completed();
        } else {
          showAlert("Something went wrong. Please try again.", "error");
        }
      } catch (error) {
        showAlert("Something went wrong. Please try again.", "error");
      }
    },
    [showAlert, updateUserHealth]
  );

  const value = useMemo(
    () => ({
      gettingUserHealthProfile,
      postingHealthProfile,
      healthPayload,
      getHealthProfile,
      postHealthProfile,
    }),
    [
      gettingUserHealthProfile,
      healthPayload,
      postingHealthProfile,
      getHealthProfile,
      postHealthProfile,
    ]
  );

  return (
    <HealthProviderContext.Provider value={value}>
      {children}
    </HealthProviderContext.Provider>
  );
}

export default HealthProfileProvider;
