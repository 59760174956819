import Images from "assets/images";
import React from "react";

import "./RadioButtonCard.scss";

function RadioButtonCard({
  options,
  infoTexts,
  infos,
  showInfo,
  selected,
  onSelect,
}) {
  return (
    <div className="radio_button_card_container">
      {options?.map((el, index) => {
        return (
          <div
            key={index}
            className={`radio_button_card rounded-xl cursor-pointer flex items-center justify-between p-6 border ${
              selected === el ? "border-orange" : "border-borderColor"
            } ${index !== options?.length - 1 ? "mb-8" : ""}`}
            onClick={() => onSelect(el)}
          >
            <div>
              <p className="text-2xl font-bold text-indigo">
                {el}
                {infos?.length && infos?.[index]?.length ? (
                  <span
                    onClick={() =>
                      showInfo({
                        label: options?.[index],
                        value: infos?.[index],
                      })
                    }
                    className="inline-block w-8 h-8 ml-3 font-semibold text-center text-white rounded-full cursor-pointer bg-indigo bg-opacity-40"
                  >
                    ?
                  </span>
                ) : null}
              </p>
              {infoTexts?.length ? (
                <p className="w-4/5 pt-1 text-2xl font-medium opacity-40 md:w-full">
                  {infoTexts?.[index]}
                </p>
              ) : null}
            </div>
            <img
              className="w-10 h-10"
              src={
                selected === el ? Images.radioChecked : Images.uncheckedCircle
              }
              alt="unchecked"
            />
          </div>
        );
      })}
    </div>
  );
}

export default RadioButtonCard;
