import { useEffect } from "react";
import { createCase } from "api/actions/VisitActions";
import { CURRENT_SOURCE } from "helpers/currentSource";
import { cosmetic_steps, speciality, step_status } from "helpers/enum";
import { useApiMutation } from "hooks/useApiMutation";
import { useCosmeticConsultationFlow } from "hooks/useConsultationFlow";
import useToastify from "hooks/useToastify";
import useVisitStepChange from "hooks/useVisitStepChange";
import { useDispatch } from "react-redux";
import { cosmeticConsultationReducers } from "reducers/cosmetic/cosmeticConsultationReducer";
import useIncompleteData from "hooks/visit/useIncompleteData";

function useCreateCosmeticConsultation() {
  const dispatch = useDispatch();
  const { showAlert } = useToastify();
  const { setLoading } = useVisitStepChange();
  const { currentScreen, steps } = useCosmeticConsultationFlow();
  const {
    mutate: callCreateCase,
    loading: creatingConsultation,
  } = useApiMutation(createCase);
  const { fetchIncompleteData, fetchingIncompleteData } = useIncompleteData();

  useEffect(() => {
    if (creatingConsultation || fetchingIncompleteData) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [creatingConsultation, setLoading, fetchingIncompleteData]);

  async function createNewConsultation({
    patient_id,
    change_current_screen,
    screen_to_complete,
    callBack,
  }) {
    const payload = {
      case: {
        patient_id: patient_id,
        payment_method: 0,
        speciality: speciality.cosmetic,
        cosmetic_steps: {
          [cosmetic_steps.select_condition]: step_status.current,
        },
        allergies: [],
        visit_start_source: CURRENT_SOURCE,
      },
    };
    try {
      const result = await callCreateCase(payload);
      if (result && result.error === false) {
        const incompleteDataResponse = await fetchIncompleteData(
          speciality.cosmetic
        );
        if (incompleteDataResponse) {
          if (screen_to_complete) {
            dispatch(
              cosmeticConsultationReducers?.[screen_to_complete]({
                status: step_status.completed,
              })
            );
          }
          callBack();
          if (change_current_screen) {
            dispatch(
              cosmeticConsultationReducers.currentScreen({
                value: steps?.[currentScreen]?.nextScreen,
              })
            );
          }
        }
      } else {
        showAlert(
          result?.payload?.error_message ?? "Something went wrong",
          "error"
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return { createNewConsultation, creatingConsultation };
}

export default useCreateCosmeticConsultation;
