import React, { useState, useRef } from "react";
import BottomFixedStepButtons from "components/bottomFixedStepButtons/BottomFixedStepButtons";
import "./VisitStepContainer.scss";
import useVisitStepChange from "hooks/useVisitStepChange";
import { useEffect } from "react";
import { trackDropOffRate, trackEvent, trackPageView } from "helpers/analytics";
import { useCurrentUser } from "hooks/useCurrentUser";
import snakeCase from "lodash.snakecase";
import SimpleHeader from "components/header/SimpleHeader";
import NewProviderWithPercentage from "components/providerWithPercentage/NewProviderWithPercentage";
function VisitStepContainer({
  children,
  currentScreen,
  subSteps,
  onClickBack,
  incompleteData,
  doctorDetails,
  infoPopup = false,
  rightActionView = <div />,
  speciality = "",
}) {
  const visitStepContainerRef = useRef();
  const { rightButtonClickRef, loading } = useVisitStepChange();
  const [animationType, setAnimationType] = useState(
    "visit_step_container_animate_from_right"
  );
  const { userId } = useCurrentUser();

  useEffect(() => {
    const animate_container = document.getElementById("step_screen_container");
    if (animationType) {
      animate_container?.classList?.toggle(animationType);
    }
  }, [animationType]);

  useEffect(() => {
    if (userId) {
      trackPageView(`${speciality}_${currentScreen}`, userId);
      trackDropOffRate(`${speciality}_${currentScreen}`, userId);
    }
  }, [userId, currentScreen, speciality]);

  function nextAnimation() {
    setAnimationType("visit_step_container_animate_to_left");
    setTimeout(() => {
      if (visitStepContainerRef?.current) {
        visitStepContainerRef?.current?.scrollTo({ top: 0 });
      }
      setAnimationType("visit_step_container_animate_from_right");
    }, 500);
  }

  function backAnimation() {
    setAnimationType("visit_step_container_animate_to_right");
    setTimeout(() => {
      if (visitStepContainerRef?.current) {
        visitStepContainerRef?.current?.scrollTo({ top: 0 });
      }
      setAnimationType("visit_step_container_animate_from_left");
    }, 500);
  }

  return (
    <div className="relative h-screen overflow-hidden bg-white">
      <SimpleHeader
        title={subSteps?.[currentScreen]?.headerTitle}
        titleClassName={"capitalize"}
        showBackButton={!loading}
        wrapper
        rightActionView={rightActionView}
      />
      <div>
        <NewProviderWithPercentage
          blueBg={true}
          doctorDetails={doctorDetails}
          percentage={incompleteData?.meta?.progress_percentage ?? 0}
          visitSpeciality={speciality}
          id={incompleteData?.id}
        />
      </div>
      <div
        ref={visitStepContainerRef}
        id="visit_step_container"
        className={`overflow-y-auto h-eliminateHeaderWithPercentageViewAndFooter`}
      >
        {children}
      </div>
      <BottomFixedStepButtons
        currentScreen={currentScreen}
        onBackClick={() => {
          onClickBack(backAnimation);
          trackEvent(`back_button_${snakeCase(currentScreen)}`);
        }}
        nextLoading={loading ? true : false}
        hideBackButton={!subSteps?.[currentScreen]?.prevScreen}
        backDisabled={!subSteps?.[currentScreen]?.prevScreen || loading}
        nextDisabled={subSteps?.[currentScreen]?.isValid ? false : true}
        onNextClick={() => {
          rightButtonClickRef?.current(backAnimation, nextAnimation);
          trackEvent(`next_button_${snakeCase(currentScreen)}`);
        }}
      />
    </div>
  );
}

export default VisitStepContainer;
