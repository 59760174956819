import React from "react";
import PharmacyCard from "components/pharmacyCard/PharmacyCard";
import Spacer from "components/Spacer/Spacer";
import VisitSummaryContent from "components/visitSummaryAccordion/VisitSummaryContent";
import VisitSummaryContentHeading from "components/visitSummaryAccordion/VisitSummaryContentHeading";
import VisitSummaryContentParagraph from "components/visitSummaryAccordion/VisitSummaryContentParagraph";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { dermatology_sub_steps } from "helpers/enum";

function MedicalHistorySummary({ readOnly, data }) {
  const { incompleteData } = useDermatologyVisitFlow();
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <VisitSummaryContent
      readOnly={readOnly}
      onEditClick={() => {
        dispatch(
          dermaVisitFlowReducers.currentScreen({
            value: dermatology_sub_steps.medical_history,
          })
        );
        history.push("/visit/dermatology/new-visit");
      }}
    >
      <VisitSummaryContentHeading text="Medical History" />
      <VisitSummaryContentParagraph
        text={
          data?.history
            ? data?.history?.join(", ")
            : incompleteData?.history?.join(", ")
        }
      />
      <Spacer height={20} />
      <VisitSummaryContentHeading text="Allergies" />
      <VisitSummaryContentParagraph
        text={
          data?.allergies
            ? typeof data?.allergies[0] === "string"
              ? data?.allergies?.join(", ")
              : data?.allergies?.map((item) => item?.name)?.join(", ")
            : typeof incompleteData?.allergies[0] === "string"
            ? incompleteData?.allergies?.join(", ")
            : incompleteData?.allergies?.map((item) => item?.name)?.join(", ")
        }
      />
      <Spacer height={20} />
      <VisitSummaryContentHeading text="Medications" />
      <VisitSummaryContentParagraph
        text={
          data?.medications
            ? typeof data?.medications[0] === "string"
              ? data?.medications?.join(", ")
              : data?.medications?.map((item) => item?.name)?.join(", ")
            : typeof incompleteData?.medications[0] === "string"
            ? incompleteData?.medications?.join(", ")
            : incompleteData?.medications?.map((item) => item?.name)?.join(", ")
        }
      />
      <Spacer height={20} />
      <VisitSummaryContentHeading text="Skin Care Products" />
      <VisitSummaryContentParagraph
        text={
          data?.skin_care_products
            ? data?.skin_care_products?.join(", ")
            : incompleteData?.skin_care_products?.join(", ")
        }
      />
      <Spacer height={20} />
      <VisitSummaryContentHeading text="Pharmacy" />
      <div className="pt-8">
        <PharmacyCard
          removeEdit
          pharmacyAddess={
            data?.pharmacy_details?.address ??
            incompleteData?.pharmacy_details?.address
          }
        />
      </div>
      {data?.additional_info?.length ||
      incompleteData?.additional_info?.length ? (
        <>
          <Spacer height={20} />
          <VisitSummaryContentHeading text="Additional Details" />
          <VisitSummaryContentParagraph
            text={
              data?.additional_info?.length
                ? data?.additional_info
                : incompleteData?.additional_info
            }
          />
        </>
      ) : null}
    </VisitSummaryContent>
  );
}

export default MedicalHistorySummary;
