import TextWithBulletPoint from "../../TextWithBulletPoint";

export const getSexualActivityView = (payload) => {
  const values = {
    sexual_activity: payload?.sexual_activity?.sexual_activity?.length
      ? payload?.sexual_activity?.sexual_activity
      : null,
    partner: payload?.sexual_activity?.partner?.length
      ? payload?.sexual_activity?.partner
      : null,
    safety: payload?.sexual_activity?.safety?.length
      ? payload?.sexual_activity?.safety
      : null,
    birth_control_type: payload?.sexual_activity?.birth_control_type?.length
      ? payload?.sexual_activity?.birth_control_type
      : null,
  };

  if (Object.keys(values)?.some((el) => values?.[el] !== null)) {
    return (
      <div className="p-8 bg-alternativeWhite border border-borderColor mt-8 rounded-xl">
        {values?.sexual_activity ? (
          <TextWithBulletPoint
            text={`Sexual activity : ${values?.sexual_activity}`}
          />
        ) : null}
        {values?.partner ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Partner : ${values?.partner}`}
          />
        ) : null}
        {values?.safety ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Safe sex practice : ${values?.safety}`}
          />
        ) : null}
        {values?.birth_control_type ? (
          <TextWithBulletPoint
            className="mt-4"
            text={`Birth control type : ${values?.birth_control_type}`}
          />
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};
