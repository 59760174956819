import { Form, Formik } from "formik";
import Input from "components/inputs/Input";
import RegularRadioSelect from "components/radioButtonCard/RegularRadioSelect";
import { PCBasicDetails, ChildProfileSchema } from "helpers/validationSchema";
import FormikErrorFocus from "formik-error-focus";
import { primary_care_sub_steps } from "helpers/enum";
import {
  decimalRestriction,
  getFeetInInches,
  getInchesInFeet,
} from "helpers/metricConversion";
import BirthInput from "components/inputs/BirthInput";
import { useRef, useState } from "react";
import useToastify from "hooks/useToastify";
import { updateBasicInfoAction } from "api/actions/UserActions";
import { useApiMutation } from "hooks/useApiMutation";
import { capitalize } from "helpers/lodashPolyfills";
import moment from "moment";
import Spacer from "components/Spacer/Spacer";
import Button from "components/buttons/Button";
import { primaryCareVisitFlowReducers } from "reducers/primary_care/primaryCareVisitFlowReducer";
import { useDispatch } from "react-redux";
import { useCurrentUser } from "hooks/useCurrentUser";

const BasicDetails = ({
  incompleteData,
  basicDetails,
  refetch,
  updateVisit,
  updatingVisit,
  zipDetails,
  setUpdatedZip,
  setActiveIndex,
}) => {
  const formRef = useRef();
  const dispatch = useDispatch();

  const { mutate: updateBasicIfo, loading: updatingBasicDetails } =
    useApiMutation(updateBasicInfoAction);

  const { userId } = useCurrentUser();

  // DOB Confirm Changes
  const dobInputRef = useRef();
  const [showDate, setShowDate] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);

  // Weight Confirm Changes
  const [showWeight, setShowWeight] = useState("");
  const [showWeightConfirm, setShowWeightConfirm] = useState(false);

  // Height Confirm Changes
  const [showHeight, setShowHeight] = useState("");
  const [showHeightConfirm, setShowHeightConfirm] = useState(false);

  const initialCity = basicDetails?.city
    ? basicDetails?.state
      ? `${basicDetails?.city}, ${basicDetails?.state}`
      : basicDetails?.city
    : "";

  const { showAlert } = useToastify();

  async function onFormSubmit(values) {
    try {
      let payload = {
        id: userId,
        user: {
          patients_attributes: [
            {
              ...basicDetails,
              first_name: values.first_name,
              last_name: values.last_name,
              dob: moment(values.dob, "YYYY-MM-DD").format("YYYY/MM/DD"),
              city: values.city.split(",")[0],
              street_add_1: values.address_1,
              street_add_2: values.address_2,
              zip: values.zipcode,
              gender: values.gender.toLowerCase(),
              height: getFeetInInches({
                feet: values.heightFeet,
                inches: values.heightInches,
              }),
              height_metric: 1,
              weight: values.weight ? Number(values?.weight) : null,
              weight_metric: 0,
            },
          ],
        },
      };

      const updateProfileResponse = await updateBasicIfo({ userId, payload });
      if (
        updateProfileResponse.error ||
        updateProfileResponse?.payload.error ||
        updateProfileResponse?.payload?.error_msg
      ) {
        showAlert(
          updateProfileResponse?.payload?.error || "Failed to update profile",
          "error"
        );
      } else {
        updateVisit({
          payload: {
            case: {
              id: incompleteData?.id,
              state: zipDetails?.geo_data?.state_code,
              allergies: incompleteData?.allergies_json,
              medications:
                typeof incompleteData?.medications[0] === "string"
                  ? incompleteData?.medications.map((item) => ({
                      name: item,
                    }))
                  : incompleteData?.medications,
              pc_second_phase_steps: {
                ...incompleteData?.pc_second_phase_steps,
                [primary_care_sub_steps.confirm_residence]: "completed",
              },
            },
          },
          change_current_screen: false,
          screen_to_complete: null,
          callBack: () => {
            dispatch(
              primaryCareVisitFlowReducers.confirm_residence({
                value: zipDetails?.geo_data?.state_code,
              })
            );
            refetch();
            showAlert("Progress Saved Successfully", "success");
            setActiveIndex(4);
          },
        });
      }
    } catch (error) {}
  }

  return (
    <>
      <Formik
        innerRef={formRef}
        onSubmit={onFormSubmit}
        validationSchema={
          !basicDetails?.account_holder &&
          basicDetails.relationship_with_account === "pappa"
            ? ChildProfileSchema
            : PCBasicDetails
        }
        initialValues={{
          profile_pic: {
            uri: basicDetails?.photo_url ?? "",
            file: "",
            data: "",
          },
          first_name: basicDetails?.first_name ?? "",
          last_name: basicDetails?.last_name ?? "",
          dob: basicDetails?.dob ?? "",
          address_1: basicDetails?.street_add_1 ?? "",
          address_2: basicDetails?.street_add_2 ?? "",
          city: initialCity,
          zipcode: basicDetails?.zip ?? "",
          gender: basicDetails?.gender ? capitalize(basicDetails?.gender) : "",
          heightFeet: basicDetails?.height
            ? getInchesInFeet({ height: basicDetails?.height }).feet
            : "",
          heightInches: basicDetails?.height
            ? getInchesInFeet({ height: basicDetails?.height }).inches
            : "",
          weight: basicDetails?.weight ?? "",
        }}
      >
        {({
          values,
          setFieldValue,
          setFieldTouched,
          touched,
          dirty,
          errors,
        }) => (
          <Form className={"bg-white p-12 rounded-b-4xl"}>
            <Input
              disabled={basicDetails?.first_name}
              name="first_name"
              label="First Name*"
              id="first_name"
            />
            <Spacer height={32} />
            <Input
              disabled={basicDetails?.last_name}
              name="last_name"
              label="Last Name*"
              id="last_name"
            />
            <Spacer height={32} />
            {/* DOB Confirm Changes */}
            <BirthInput
              ref={dobInputRef}
              disabled={basicDetails?.dob}
              name="dob"
              label="DOB*"
              id="dob"
              isOpen={openCalendar}
              open={() => setOpenCalendar(true)}
              close={() => setOpenCalendar(false)}
              max={moment().format("YYYY-MM-DD")}
              onBlur={() => {
                if (dirty) {
                  setFieldTouched("dob", true);
                  setShowConfirm(true);
                }
              }}
              onChange={(event) => {
                const date = event.target.value
                  ? moment(event.target.value).format("YYYY-MM-DD")
                  : "";
                setFieldValue("dob", date);

                if (date !== showDate) {
                  setShowDate(event.target.value ?? "");
                }
              }}
            />
            <Spacer height={32} />
            <Input
              name="address_1"
              label="Address 1*"
              id="address_1"
              maxLength="35"
            />
            <Spacer height={32} />
            <Input
              name="address_2"
              label="Address 2"
              id="address_2"
              maxLength="35"
            />
            <Spacer height={32} />

            <div className="flex flex-col space-x-0 space-y-12 md:flex-row md:space-x-4 md:space-y-0">
              <div className="flex-1">
                <Input name="city" label="City*" id="city" />
              </div>
              <div className="flex-1">
                <Input
                  name="zipcode"
                  label="Zip Code*"
                  id="zipcode"
                  maxLength={5}
                  customonblur={() => setUpdatedZip(values?.zipcode)}
                />
              </div>
            </div>
            <Spacer height={32} />
            <div className="flex flex-wrap items-start">
              <div className="flex-1 flex items-end space-x-4 md:flex-initial">
                <div className="flex-1 md:flex-initial">
                  <Input
                    style={{ width: "10ch", paddingRight: 4 }}
                    type="unit"
                    label="Height*"
                    id="heightFeet"
                    name="heightFeet"
                    unit="ft"
                    min="1"
                    max="9"
                    customonblur={() => {
                      if (values?.heightFeet) {
                        setShowHeight(
                          `${
                            values.heightInches !== undefined &&
                            values.heightInches > 0
                              ? `${parseInt(values?.heightFeet)}ft ${parseInt(
                                  values?.heightInches
                                )}in`
                              : `${parseInt(values?.heightFeet)}ft`
                          }`
                        );
                        // setShowHeightConfirm(true);
                        setFieldValue(
                          "heightFeet",
                          parseInt(values?.heightFeet)
                        );
                      }
                    }}
                  />
                </div>
                <div className="flex-1 md:flex-initial">
                  <Input
                    style={{ width: "10ch", paddingRight: 4 }}
                    type="unit"
                    id="heightInches"
                    name="heightInches"
                    unit="in"
                    min="0"
                    max="11"
                    onFocus={() => setShowHeightConfirm(false)}
                    customonblur={() => {
                      if (values.heightInches !== "") {
                        setShowHeight(
                          `${
                            values.heightInches !== undefined &&
                            values.heightInches > 0
                              ? `${parseInt(values?.heightFeet)}ft ${parseInt(
                                  values?.heightInches
                                )}in`
                              : `${parseInt(values?.heightFeet)}ft`
                          }`
                        );
                        // setShowHeightConfirm(true);
                        setFieldValue(
                          "heightInches",
                          parseInt(
                            values.heightInches !== undefined
                              ? values.heightInches
                              : "0"
                          )
                        );
                      }
                    }}
                  />
                </div>
              </div>
              <div className="ml-4 flex-1 md:flex-initial">
                <Input
                  style={{ width: "10ch", paddingRight: 4 }}
                  type="unit"
                  label="Weight*"
                  id="weight"
                  name="weight"
                  min="1"
                  unit="lbs"
                  customonblur={() => {
                    if (values?.weight) {
                      setShowWeight(
                        `${decimalRestriction({
                          number: Number(values?.weight),
                        })} lbs`
                      );
                      // setShowWeightConfirm(true);
                      setFieldValue(
                        "weight",
                        decimalRestriction({
                          number: Number(values?.weight),
                        })
                      );
                    }
                  }}
                />
              </div>
              <div className="ml-0 flex justify-between items-baseline md:ml-4">
                <div className="flex-1 flex flex-col space-y-2">
                  <RegularRadioSelect
                    options={["Male", "Female"]}
                    selected={values?.gender}
                    setSelected={(el) => setFieldValue("gender", el)}
                  />
                  {touched?.gender && errors?.gender ? (
                    <p className="font-bold text-base mt-2 text-red">
                      {errors?.gender}
                    </p>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
            </div>
            <Spacer height={20} />
            <FormikErrorFocus align={"bottom"} ease={"linear"} duration={500} />
            <Button
              className="py-4"
              type="submit"
              loading={updatingBasicDetails || updatingVisit}
              label="Next"
            />
          </Form>
        )}
      </Formik>
      {/* DOB Confirm Changes */}
      {showConfirm ? (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-60">
          <div className="w-11/12 max-w-2xl p-8 flex flex-col space-y-8 bg-white">
            <p className="font-bold text-3xl">Confirm Date of Birth</p>
            <p className="font-medium text-2xl leading-9">
              You've entered {moment(showDate).format("MM-DD-YYYY")} as date of
              birth. Click CONFIRM to proceed or EDIT to change the date.
            </p>
            <div className="flex justify-end items-center space-x-4">
              <button
                className="text-xl font-bold p-4 uppercase bg-opacity-10 rounded text-indigo min-w-32"
                onClick={() => {
                  if (dobInputRef.current) {
                    dobInputRef.current.focus();
                  }
                  setOpenCalendar(true);
                  setShowConfirm(false);
                }}
              >
                Edit
              </button>
              <button
                className="flex items-center justify-center text-xl font-bold p-4 uppercase bg-indigo bg-opacity-20 rounded text-indigo min-w-32"
                onClick={() => {
                  setShowConfirm(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
      {/* Weight Confirm Changes */}
      {showWeightConfirm ? (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-60">
          <div className="w-11/12 max-w-2xl p-8 flex flex-col space-y-8 bg-white">
            <p className="font-bold text-3xl">Confirm Weight</p>
            <p className="font-medium text-2xl leading-9">
              You've entered <span className="font-bold">{showWeight}</span> as
              weight. Click CONFIRM to proceed or EDIT to change the weight.
            </p>
            <div className="flex justify-end items-center space-x-4">
              <button
                className="text-xl font-bold p-4 uppercase bg-opacity-10 rounded text-indigo min-w-32"
                onClick={() => {
                  setShowWeightConfirm(false);
                }}
              >
                Edit
              </button>
              <button
                className="flex items-center justify-center text-xl font-bold p-4 uppercase bg-indigo bg-opacity-20 rounded text-indigo min-w-32"
                onClick={() => {
                  setShowWeightConfirm(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
      {/* Height Confirm Changes */}
      {showHeightConfirm ? (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-60">
          <div className="w-11/12 max-w-2xl p-8 flex flex-col space-y-8 bg-white">
            <p className="font-bold text-3xl">Confirm Height</p>
            <p className="font-medium text-2xl leading-9">
              You've entered <span className="font-bold">{showHeight}</span> as
              height. Click CONFIRM to proceed or EDIT to change the height.
            </p>
            <div className="flex justify-end items-center space-x-4">
              <button
                className="text-xl font-bold p-4 uppercase bg-opacity-10 rounded text-indigo min-w-32"
                onClick={() => {
                  setShowHeightConfirm(false);
                }}
              >
                Edit
              </button>
              <button
                className="flex items-center justify-center text-xl font-bold p-4 uppercase bg-indigo bg-opacity-20 rounded text-indigo min-w-32"
                onClick={() => {
                  setShowHeightConfirm(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

export default BasicDetails;
