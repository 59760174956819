import { linkInsuranceAction } from "api/actions/VisitActions";
import Images from "assets/images";
import Accordion from "components/accordion/Accordion";
import Button from "components/buttons/Button";
import CSSLoader from "components/cssLoader/CSSLoader";
import RegularRadioSelect from "components/radioButtonCard/RegularRadioSelect";
import Spacer from "components/Spacer/Spacer";
import { trackEvent } from "helpers/analytics";
import { useApiMutation } from "hooks/useApiMutation";
import { useCurrentUser } from "hooks/useCurrentUser";
import useModal from "hooks/useModal";
import useToastify from "hooks/useToastify";
import { usePrimaryCareVisitFlow } from "hooks/useVisitFlow";
import useIncompleteData from "hooks/visit/useIncompleteData";
import InsuranceUpdateModal from "pages/home/waitingRoom/visitFlow/common/InsuranceUpdateModal";
import React, { useEffect, useRef, useState } from "react";

function PCInsuranceOption({
  speciality,
  currentPatient,
  isInsuranceExpired,
  isActive,
  insurance_details,
  onClick,
  selectCash,
  onClickAddInsurance,
  onClickUpdateInsurance,
  disabled,
  loading,
  showConfirm = () => {},
  checkingInsuranceInNetwork,
  insuranceInNetwork,
}) {
  const { setShowModal } = useModal();
  const { userDetails } = useCurrentUser();
  const [outOfNet, setOutOfNet] = useState(false);
  const [authCode, setAuthCode] = useState("");
  const [showSecondary, setShowSecondary] = useState("");
  const [incompleteData, setIncompleteData] = useState(null);
  const firstTime = useRef(true);

  const { userId } = useCurrentUser();
  const {
    fetchIncompleteData,
    incompleteData: fetchedIncompleteData,
  } = useIncompleteData();
  const { incompleteData: pcIncompleteData } = usePrimaryCareVisitFlow();

  const { showAlert } = useToastify();

  const {
    mutate: callLinkInsuranceApi,
    loading: submittingAuthCode,
  } = useApiMutation(linkInsuranceAction);

  function successHandler(message) {
    showAlert(message, "success");
  }

  function errorHandler(message) {
    showAlert(message, "error");
  }

  async function submitAuthcode() {
    const params = {
      unsupported_insurance: "0",
      insurance_company: insurance_details?.insurance_company,
      member_dob: insurance_details?.member_dob,
      member_name: insurance_details?.member_name,
      member_id: insurance_details?.member_id,
      relationship_to_patient: insurance_details?.relationship_to_patient,
      patient_id: incompleteData?.patient_id,
      insurance_type: 1,
      account_id: userId,
      insurance_authorisations_attributes: [
        {
          case_id: incompleteData?.id,
          auth_id: authCode,
        },
      ],
    };

    try {
      const result = await callLinkInsuranceApi({
        formData: params,
        accountId: userId,
      });
      if (result.error || result.payload.error || result.payload.error_msg) {
        errorHandler(result.payload.error || result.payload.error_msg);
      } else {
        fetchIncompleteData(speciality);
        successHandler(result?.payload?.message);
        // await getPatients();
      }
    } catch (error) {
      errorHandler(error?.message);
    }
  }

  useEffect(() => {
    setIncompleteData(pcIncompleteData);
  }, [pcIncompleteData]);

  useEffect(() => {
    if (fetchedIncompleteData) {
      setIncompleteData(fetchedIncompleteData);
    }
  }, [fetchedIncompleteData]);

  useEffect(() => {
    if (checkingInsuranceInNetwork) {
      firstTime.current = true;
    }
  }, [checkingInsuranceInNetwork]);

  useEffect(() => {
    if (!checkingInsuranceInNetwork && firstTime.current) {
      firstTime.current = false;
      if (insuranceInNetwork?.in_network) {
        setOutOfNet(false);
        if (isActive) {
          onClick();
        }
      } else {
        setOutOfNet(true);
        selectCash();
      }
    }
  }, [
    isActive,
    checkingInsuranceInNetwork,
    insuranceInNetwork,
    insurance_details,
    selectCash,
    onClick,
  ]);

  if (insurance_details?.insurance_company) {
    return (
      <div
        onClick={() => {
          if (
            isInsuranceExpired ||
            disabled ||
            insurance_details?.out_of_network ||
            outOfNet
          ) {
            return null;
          } else {
            onClick();
            showConfirm();
          }
        }}
        className={`${
          disabled ? "opacity-50 cursor-not-allowed" : "opacity-100"
        } border border-${
          isActive ? "orange" : "borderColor"
        }  rounded-1.6 py-8 px-5 ${
          isInsuranceExpired || insurance_details?.out_of_network || outOfNet
            ? "cursor-not-allowed"
            : "cursor-pointer"
        } bg-white`}
      >
        <div
          className={`flex items-center justify-between ${
            isInsuranceExpired || insurance_details?.out_of_network || outOfNet
              ? "opacity-50"
              : "opacity-100"
          }`}
        >
          <div className="flex items-start">
            <img
              src={isActive ? Images.radioChecked : Images.uncheckedCircle}
              className="w-10"
              alt="un_checked"
            />
            <div className="pl-4">
              <h2 className="text-2xl font-bold">Insurance Price Estimate</h2>
            </div>
          </div>
          {isInsuranceExpired ? null : (
            <div>
              {loading ? (
                <CSSLoader className="w-8 h-8" color="orange" />
              ) : (
                <>
                  <p className="flex text-xl font-black md:text-2xl">
                    TO BE PROVIDED
                  </p>
                </>
              )}
            </div>
          )}
        </div>
        {insurance_details?.out_of_network || outOfNet ? (
          <div className="pt-1 pl-14">
            <p className="mt-2 text-2xl font-medium leading-7 text-red">
              We unfortunately do not have an in-network provider in{" "}
              {userDetails?.state} that accepts your insurance. Please choose
              the self-pay option below to continue with your booking.
            </p>
          </div>
        ) : (
          <div>
            <div className="pt-1 pl-14">
              <p className="mt-2 text-xl font-medium leading-5 text-cloudyBlue">
                Insurance
              </p>
              <p className="mt-2 text-2xl font-medium leading-7">
                {insurance_details?.insurance_company}
              </p>
              <Spacer height={10} />
              <p className="text-xl font-medium leading-5 text-cloudyBlue">
                Member ID
              </p>
              <p className="mt-2 text-2xl font-medium leading-7">
                {insurance_details?.member_id}
              </p>
            </div>
            <Spacer height={10} />
            <Accordion
              data={[
                {
                  header: ({ isActive }) => (
                    <div className="flex items-center justify-start pl-14">
                      <p className="text-xl font-medium underline">
                        {isActive
                          ? "Hide Itemized Breakdown"
                          : "Itemized Breakdown"}
                      </p>
                    </div>
                  ),
                  content: (
                    <div>
                      <Spacer height={21} />
                      <div className="p-6 mx-4 bg-alternativeWhite rounded-t-2xl">
                        <div className="flex items-baseline justify-between space-x-4">
                          <p className="text-2xl font-medium">
                            SkyMD Medical Visit Charge
                          </p>
                          <p className="font-medium text-right text-md">
                            TO BE PROVIDED
                          </p>
                        </div>
                        <Spacer height="18" />
                        <div className="flex items-center justify-between">
                          <p className="text-2xl font-medium">
                            Established care with a board certified doctor
                          </p>
                          <p className="text-2xl font-medium text-blue">
                            Included
                          </p>
                        </div>
                        <div className="flex items-center justify-between">
                          <p className="text-2xl font-medium">
                            Evaluation of 1 medical condition
                          </p>
                          <p className="text-2xl font-medium text-blue">
                            Included
                          </p>
                        </div>
                        <div className="flex items-center justify-between">
                          <p className="text-2xl font-medium">
                            Diagnosis, advice and treatment plan
                          </p>
                          <p className="text-2xl font-medium text-blue">
                            Included
                          </p>
                        </div>
                        <div className="flex items-center justify-between">
                          <p className="text-2xl font-medium">
                            Prescription ordering, as approved by MD
                          </p>
                          <p className="text-2xl font-medium text-blue">
                            Included
                          </p>
                        </div>
                        <div className="flex items-center justify-between">
                          <p className="text-2xl font-medium">
                            Unlimited portal / app support
                          </p>
                          <p className="text-2xl font-medium text-blue">
                            Included
                          </p>
                        </div>

                        <div className="border-0.5 border-borderColor my-7" />
                        <div className="flex items-baseline justify-between space-x-4">
                          <p className="text-2xl font-medium">
                            Total Patient Responsibility
                          </p>
                          <p className="font-medium text-right text-md">
                            TO BE PROVIDED
                          </p>
                        </div>
                      </div>
                    </div>
                  ),
                },
              ]}
              onExpand={(isExpanded) =>
                trackEvent("pc_insurance_itemized_breakdown_trigger", {
                  expand: isExpanded,
                })
              }
            />
          </div>
        )}
        <div className="flex items-center justify-center">
          <div
            onClick={onClickUpdateInsurance}
            className="flex items-center justify-center pt-4 pb-4 cursor-pointer"
          >
            <p className="text-2xl font-bold">Update Insurance</p>
            <img
              className="w-8 ml-4"
              src={Images.arrowRight}
              alt="arrow_right"
            />
          </div>
        </div>

        {isInsuranceExpired || !insurance_details?.auth_id_required ? (
          <div />
        ) : incompleteData?.insurance_authorisation ? (
          <div className="px-14 py-8 mt-8 border-t border-b border-grey-600 ml-0">
            <p className="text-xl font-medium leading-5 text-themeBlack">
              Authorization Approval Number
            </p>
            <p className="mt-2 text-2xl font-medium leading-7">
              {incompleteData?.insurance_authorisation?.auth_id}
            </p>
          </div>
        ) : (
          <div className="px-14 py-8 mt-8 border-t border-b border-grey-600 ml-0">
            <div className="flex flex-col space-x-0 space-y-4 md:flex-row md:items-end md:space-x-4 md:space-y-0">
              <div className="flex-1">
                <label
                  className="font-bold text-2xl text-indigo text-opacity-50"
                  htmlFor="auth_id"
                >
                  <span className="text-red">*</span>Please enter your NAPHCARE
                  authorization approval number
                </label>
                <div className="flex items-center custom_pass_field mt-2">
                  <input
                    id="auth_id"
                    placeholder="Enter Auth ID..."
                    value={authCode}
                    onChange={(e) => setAuthCode(e.target.value)}
                    type="text"
                    autoFocus={true}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key) || authCode.length > 5) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
              <div>
                <Button
                  type="submit"
                  label="Submit"
                  disabled={authCode.length !== 6}
                  onClick={submitAuthcode}
                  loading={submittingAuthCode}
                  className="h-10"
                />
              </div>
            </div>
          </div>
        )}

        {insurance_details?.out_of_network || outOfNet ? (
          <div />
        ) : insurance_details?.can_add_secondary_insurance ? (
          insurance_details?.secondary_insurance?.insurance_company ? (
            <div className="mt-4">
              <div className="px-14">
                <div className="w-full h-px mb-4 bg-indigo" />

                <div className="py-1">
                  <p className="mt-2 text-xl font-medium leading-5 text-cloudyBlue">
                    Secondary Insurance
                  </p>
                  <p className="mt-2 text-2xl font-medium leading-7">
                    {insurance_details?.secondary_insurance?.insurance_company}
                  </p>
                  <Spacer height={10} />
                  <p className="text-xl font-medium leading-5 text-cloudyBlue">
                    Secondary Member ID
                  </p>
                  <p className="mt-2 text-2xl font-medium leading-7">
                    {insurance_details?.secondary_insurance?.member_id}
                  </p>
                </div>
                <div className="flex items-center justify-center">
                  <div
                    onClick={() => {
                      setShowModal({
                        state: true,
                        children: (_, onClose) => (
                          <InsuranceUpdateModal
                            onClose={onClose}
                            insuranceDetails={
                              insurance_details?.secondary_insurance?.member_id
                                ? insurance_details?.secondary_insurance
                                : null
                            }
                            currentPatient={currentPatient}
                            currentSpeciality={speciality}
                            insuranceType={2}
                          />
                        ),
                        data: null,
                      });
                    }}
                    className="flex items-center justify-center pt-4 pb-4 cursor-pointer"
                  >
                    <p className="text-2xl font-bold">
                      Update Secondary Insurance
                    </p>
                    <img
                      className="w-8 ml-4"
                      src={Images.arrowRight}
                      alt="arrow_right"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-4">
              <div className="px-14">
                <div className="w-full h-px mb-4 bg-indigo" />
                <RegularRadioSelect
                  label="Do you want to add a secondary insurance?"
                  options={["Yes", "No"]}
                  selected={showSecondary}
                  setSelected={(el) => setShowSecondary(el)}
                />
                {showSecondary === "Yes" ? (
                  <div className="flex items-center justify-center">
                    <div
                      onClick={() => {
                        setShowModal({
                          state: true,
                          children: (_, onClose) => (
                            <InsuranceUpdateModal
                              onClose={onClose}
                              insuranceDetails={
                                insurance_details?.secondary_insurance
                                  ?.member_id
                                  ? insurance_details?.secondary_insurance
                                  : null
                              }
                              currentPatient={currentPatient}
                              currentSpeciality={speciality}
                              insuranceType={2}
                            />
                          ),
                          data: null,
                        });
                      }}
                      className="flex items-center justify-center pt-4 pb-4 cursor-pointer"
                    >
                      <p className="text-2xl font-bold">
                        Add Secondary Insurance
                      </p>
                      <img
                        className="w-8 ml-4"
                        src={Images.arrowRight}
                        alt="arrow_right"
                      />
                    </div>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
          )
        ) : (
          <div />
        )}
      </div>
    );
  } else {
    return (
      <div
        onClick={onClickAddInsurance}
        className="border border-borderColor rounded-1.6 py-8 px-5 cursor-pointer bg-white"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-start">
            <img
              src={Images.uncheckedCircle}
              className="w-10"
              alt="un_checked"
            />
            <div className="pl-4">
              <h2 className="text-2xl font-bold">Insurance Price Estimate</h2>
            </div>
          </div>
          <p className="flex text-xl font-black md:text-2xl">TO BE PROVIDED</p>
        </div>
        <p className="pt-1 text-2xl pl-14">
          Please enter your health insurance details so we can confirm your
          out-of-pocket estimate. Please keep your insurance card handy as you
          are required to upload a picture of it.
        </p>
      </div>
    );
  }
}

export default PCInsuranceOption;
