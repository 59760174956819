import React from "react";
// import "./CSSLoader.scss";

function CSSLoader({ className, color }) {
  return (
    <svg
      className={`animate-spin  mr-3 text-${color ?? "white"} ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
}

export default CSSLoader;

// import React from "react";
// // import "./CSSLoader.scss";

// function CSSLoader({ width, height }) {
//   return (
//     <div
//       className="border-solid border-16 rounded-1/2 border-borderColor  w-32 h-32 animate-spin-slow"
//       style={{
//         width: width && `${width}rem`,
//         height: height && `${height}rem`,
//         borderWidth: `${width / 8}rem`,
//         borderTopWidth: `${width / 8}rem`,
//       }}
//     ></div>
//   );
// }
