import { postVisitSurveyAction } from "api/actions/VisitActions";
import Images from "assets/images";
import { useApiMutation } from "hooks/useApiMutation";
import useToastify from "hooks/useToastify";
import { useState } from "react";

const {
  default: PracticeUserCard,
} = require("../waitingRoom/components/PracticeUserCard");

const EvalutatedSummary = ({ practiceUserDetails }) => {
  const {
    name,
    picture,
    speciality,
    reviewedOn,
    evaluationFeedbackGiven,
    evaluationFeedbackToken,
    evaluationFeedbackRating,
  } = practiceUserDetails;
  const [selectedStarRating, setSelectedStarRating] = useState(
    evaluationFeedbackRating ? evaluationFeedbackRating / 2 : null
  );
  const [hoverState, setHoverState] = useState(null);
  const { mutate: postVisitSurvey, loading: postingSurvey } = useApiMutation(
    postVisitSurveyAction
  );
  const [submittedFeedback, setSubmittedFeedback] = useState(
    evaluationFeedbackGiven
  );

  const { showAlert } = useToastify();
  async function submitSurvey() {
    try {
      const response = await postVisitSurvey({
        feedback: {
          recommend_rating: selectedStarRating * 2,
        },
        token: evaluationFeedbackToken,
      });
      if (
        response?.error ||
        response?.payload?.error ||
        response?.payload?.error_msg
      ) {
        showAlert(
          response?.payload?.error ||
            response?.payload?.error_msg ||
            "Something went wrong.",
          "error"
        );
      } else {
        setSubmittedFeedback(true);
        if (response?.payload?.message) {
          showAlert(response?.payload?.message, "success");
        }
      }
    } catch (error) {
      showAlert(error?.message ?? "Something went wrong.", "error");
    }
  }

  return (
    <div className="grid w-full bg-white gap-y-8 xl:gap-y-0 xl:gap-x-10 xl:grid-cols-2 rounded-xl">
      <PracticeUserCard
        picture={picture}
        name={name}
        speciality={speciality}
        showLicense={false}
        showNetwork={false}
        reviewedOn={reviewedOn}
        online={false}
        size="small"
      />
      <div className="flex flex-col flex-1 p-4 ml-0 space-y-4 border rounded-lg xl:ml-4 justify-evenly">
        <div className="flex flex-col space-y-2 text-2xl font-bold text-indigo">
          <p>How would you rate your experience with</p>
          <p>{name}?</p>
        </div>
        <div className="flex flex-col items-center justify-between mt-1 space-y-4 md:flex-row">
          <div className="flex items-center">
            {["Bad", "Poor", "Fair", "Good", "Great"].map((e, index) => {
              return (
                <div
                  className="flex flex-col items-center m-2"
                  key={`ratings-${index}`}
                >
                  <img
                    onMouseEnter={() =>
                      submittedFeedback ? null : setHoverState(index + 1)
                    }
                    onMouseLeave={() =>
                      submittedFeedback ? null : setHoverState(null)
                    }
                    key={index}
                    onClick={() =>
                      submittedFeedback
                        ? null
                        : setSelectedStarRating(index + 1)
                    }
                    src={
                      (typeof selectedStarRating === "number" &&
                        selectedStarRating > index) ||
                      (typeof hoverState === "number" && hoverState > index)
                        ? Images.starFilled
                        : Images.starUnfilled
                    }
                    className={`object-contain h-10 w-10 cursor-pointer md:${
                      index + 1 <= hoverState && selectedStarRating < hoverState
                        ? "opacity-50"
                        : "opacity-100"
                    }`}
                    alt="rating"
                  />
                  <p className="font-thin text-md text-indigo">{e}</p>
                </div>
              );
            })}
          </div>
          {!submittedFeedback && (
            <button
              disabled={postingSurvey || !selectedStarRating}
              onClick={submitSurvey}
              className={`bg-transparent text-orange font-bold underline text-2xl ${
                postingSurvey || !selectedStarRating
                  ? "opacity-60 cursor-not-allowed"
                  : ""
              }`}
            >
              {postingSurvey ? "Please wait" : "Submit"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default EvalutatedSummary;
