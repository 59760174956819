import React, { useEffect, useState } from "react";
import { getCashPrice } from "api/actions/VisitActions";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import ChoosePaymentMethod from "components/choosePaymentMethod/ChoosePaymentMethod";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { useApiQuery } from "hooks/useApiQuery";
import { useCurrentUser } from "hooks/useCurrentUser";
import usePatients from "hooks/visit/usePatients";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import { dermatology_sub_steps, speciality } from "helpers/enum";
import useModal from "hooks/useModal";
import InsuranceUpdateModal from "../../common/InsuranceUpdateModal";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { useDispatch } from "react-redux";
import LoaderComponent from "components/loader/LoaderComponent";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import { useHistory } from "react-router-dom";
import useVisitStepChange from "hooks/useVisitStepChange";
import { checkInsuranceNetworkActionForDerm } from "api/actions/UserActions";
import { trackDropOffRate, trackEvent, trackPageView } from "helpers/analytics";
import HelpButton from "../../common/HelpButton";
import Button from "components/buttons/Button";
import SimpleHeader from "components/header/SimpleHeader";
import classNames from "classnames";

function DermatologyVisitPricing() {
  const [selected, setSelected] = useState(null);
  const [insurance_details, setInsuranceDetails] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const { setShowModal } = useModal();
  const { userDetails } = useCurrentUser();
  const { loading } = useVisitStepChange();
  const {
    patientsList,
    incompleteData,
    paymentMethod,
  } = useDermatologyVisitFlow();
  const { getCurrentPatient, getAccountHolder } = usePatients();
  const currentPatient = getCurrentPatient(
    incompleteData?.patient_id,
    patientsList
  );
  const { updateVisit } = useUpdateDermatologyVisit();
  const {
    payload: cashPriceResponse,
    loading: fetchingCashPrice,
    query: fetchCashPrice,
  } = useApiQuery(
    getCashPrice(incompleteData?.patient_id, speciality.dermatology),
    false
  );

  const {
    loading: checkingInsuranceInNetwork,
    payload: insuranceInNetwork,
  } = useApiQuery(
    checkInsuranceNetworkActionForDerm(
      speciality.dermatology,
      incompleteData?.patient_id,
      userDetails?.derm_physician?.provider_id
    ),
    true
  );
  function hasInsurance(patient) {
    if (patient?.insurance && patient?.insurance?.insurance_company) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (paymentMethod) {
      setSelected(paymentMethod);
    }
  }, [paymentMethod, setSelected]);

  useEffect(() => {
    if (insurance_details?.member_id) {
      if (insurance_details?.out_of_network) {
        setSelected("cash");
      } else {
        setSelected("insurance");
      }
    }
  }, [insurance_details]);

  useEffect(() => {
    if (patientsList) {
      if (hasInsurance(currentPatient)) {
        setInsuranceDetails(currentPatient?.insurance);
      } else if (hasInsurance(getAccountHolder(patientsList))) {
        setInsuranceDetails(getAccountHolder(patientsList)?.insurance);
      } else {
        setInsuranceDetails(null);
      }
    }
  }, [currentPatient, patientsList, getAccountHolder]);
  useEffect(() => {
    fetchCashPrice();
  }, [fetchCashPrice]);
  useEffect(() => {
    if (userDetails.id) {
      trackPageView("dermatology-visit-pricing", userDetails.id);
      trackDropOffRate("dermatology-visit-pricing", userDetails.id);
    }
  }, [userDetails]);

  async function AcceptPricing() {
    trackEvent("book_appointment_derma_visit", {
      uid: userDetails.id,
      paymentMethod: selected,
    });
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          payment_method: selected === "cash" ? 0 : 1,
          dermatology_steps: incompleteData?.dermatology_steps,
        },
      },
      change_current_screen: true,
      screen_to_complete: dermatology_sub_steps.select_patient,
      callBack: () => {
        dispatch(
          dermaVisitFlowReducers.setPaymentMethod({
            value: selected,
          })
        );
        setTimeout(() => {
          history.goBack();
        }, 500);
      },
    });
  }

  if (fetchingCashPrice) {
    return (
      <LoaderComponent
        showLoader={{
          state: true,
          message: "Please wait",
        }}
      />
    );
  }

  return (
    <div className="h-screen bg-veryLightBlue bg-gradient-to-b from-blue-300 to-blue-500">
      <SimpleHeader
        title="Pricing Estimate / Payment Options"
        showBackButton={!loading}
        wrapper
        bgColor="bg-white"
      />
      <div
        className={classNames(
          "px-8  overflow-y-auto pb-56 md:pb-20 md:px-0  ",
          hasInsurance(currentPatient)
            ? "h-eliminateHeaderWithFooter"
            : "h-eliminateHeaderWithPercentageViewAndFooter"
        )}
      >
        <div className="pt-8 space-y-12 wrapper">
          <MedicalAssistant
            title="Experience Hassle-Free Dermatology Care with SkyMD"
            titleClassName="text-2xl font-bold md:text-3xl text-secondaryBlue-600"
            content={
              <div className="pb-2 text-md md:pt-4 md:mr-4">
                <p>
                  We won't charge you until appointment is completed with one of
                  our{" "}
                  <strong className="text-secondaryBlue-600">
                    amazing doctors.
                  </strong>{" "}
                  We'll let you know if{" "}
                  <strong className="text-secondaryBlue-600">
                    your insurance is in-network,
                  </strong>{" "}
                  give you an{" "}
                  <strong className="text-secondaryBlue-600">
                    estimate of costs,
                  </strong>{" "}
                  and{" "}
                  <strong className="text-secondaryBlue-600">
                    verify your coverage to maximize your benefits.
                  </strong>
                </p>
                <p>
                  If you have any questions or concerns, call our number and our
                  medical assistant team will promptly answer your queries
                </p>
              </div>
            }
          />
          <ChoosePaymentMethod
            acceptInsurance={incompleteData?.accept_insurance}
            speciality={speciality.dermatology}
            currentPatient={getCurrentPatient(
              incompleteData?.patient_id,
              patientsList
            )}
            isInsuranceExpired={false}
            cashPriceResponse={cashPriceResponse}
            insuranceDetails={{
              ...insurance_details,
            }}
            onClickAddInsurance={() => {
              setShowModal({
                state: true,
                children: (_, onClose) => (
                  <InsuranceUpdateModal
                    onClose={onClose}
                    insuranceDetails={insurance_details}
                    currentPatient={getCurrentPatient(
                      incompleteData?.patient_id,
                      patientsList
                    )}
                    currentSpeciality={speciality.dermatology}
                  />
                ),
                data: null,
              });
              trackEvent("add_insurance_pricing_est");
            }}
            onClickUpdateInsurance={() => {
              setShowModal({
                state: true,
                children: (_, onClose) => (
                  <InsuranceUpdateModal
                    onClose={onClose}
                    insuranceDetails={insurance_details}
                    currentPatient={getCurrentPatient(
                      incompleteData?.patient_id,
                      patientsList
                    )}
                    currentSpeciality={speciality.dermatology}
                  />
                ),
                data: null,
              });
              trackEvent("update_insurance_pricing_est");
            }}
            onSelect={setSelected}
            selected={selected}
            checkingInsuranceInNetwork={checkingInsuranceInNetwork}
            insuranceInNetwork={insuranceInNetwork}
          />
        </div>
        <HelpButton bottomClass={"bottom-40"} />
      </div>
      <BottomFixedButton
        label="Continue"
        bgColor="bg-white"
        onClick={!selected ? null : AcceptPricing}
        loading={loading}
        disabled={
          loading ||
          !selected ||
          checkingInsuranceInNetwork ||
          (selected === "insurance"
            ? insurance_details.auth_id_required &&
              !incompleteData?.insurance_authorisation?.auth_id
            : false)
        }
        secondButton={
          <Button
            label={"No insurance, Skip and provide later"}
            outlined
            onClick={AcceptPricing}
            loading={loading}
            className={hasInsurance(currentPatient) && "hidden"}
          />
        }
      />
    </div>
  );
}

export default DermatologyVisitPricing;
