import React, { useEffect, useState } from "react";
import Images from "assets/images";
import Header from "components/header/Header";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import { Form, Formik } from "formik";
import Input from "components/inputs/Input";
import Spacer from "components/Spacer/Spacer";
import RegularRadioSelect from "components/radioButtonCard/RegularRadioSelect";
import { capitalize } from "helpers/lodashPolyfills";
import { useRef } from "react";
import { useApiMutation } from "hooks/useApiMutation";
import { updateProfileAction } from "api/actions/UserActions";
import { ProfileSchema } from "helpers/validationSchema";
import useToastify from "hooks/useToastify";
import FormikErrorFocus from "formik-error-focus";
import moment from "moment";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { input_types } from "helpers/enum";
import BirthInput from "components/inputs/BirthInput";
import {
  decimalRestriction,
  getFeetInInches,
  getInchesInFeet,
} from "helpers/metricConversion";

function UpdateProfileModal({ onClose, basicDetails, refetch }) {
  const formRef = useRef();
  const fileInputRef = useRef();

  const { showAlert } = useToastify();

  // DOB Confirm Changes
  const dobInputRef = useRef();
  const [showDate, setShowDate] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);

  // Weight Confirm Changes
  const [showWeight, setShowWeight] = useState("");
  const [showWeightConfirm, setShowWeightConfirm] = useState(false);

  // Height Confirm Changes
  const [showHeight, setShowHeight] = useState("");
  const [showHeightConfirm, setShowHeightConfirm] = useState(false);

  const { mutate: updateProfile, loading: isProfileUpdating } =
    useApiMutation(updateProfileAction);

  useEffect(() => {
    const elem = document.getElementById("update_profile_container");
    setTimeout(() => {
      elem.scrollTo({ top: 0 });
    }, 100);
  }, []);

  async function onFormSubmit(values) {
    try {
      const profileFormData = new FormData();
      if (
        !values?.profile_pic?.uri?.startsWith("http") &&
        values?.profile_pic?.data?.length
      ) {
        profileFormData.append("photo_url", values?.profile_pic?.file);
      }
      profileFormData.append("first_name", values?.first_name);
      profileFormData.append("last_name", values?.last_name);
      profileFormData.append("email", values?.email);
      profileFormData.append("dob", values?.dob);
      profileFormData.append("phone", values?.phone?.substring(1));
      profileFormData.append("city", values?.city);
      profileFormData.append("street_add_1", values?.address_1);
      profileFormData.append("street_add_2", values?.address_2);
      profileFormData.append("zip", values?.zipcode);
      profileFormData.append("gender", values?.gender?.toLowerCase());
      profileFormData.append(
        "height",
        getFeetInInches({
          feet: values.heightFeet,
          inches: values.heightInches,
        })
      );
      profileFormData.append("height_metric", 1);
      profileFormData.append(
        "weight",
        values.weight ? Number(values?.weight) : null
      );
      profileFormData.append("weight_metric", 0);

      const updateProfileResponse = await updateProfile(profileFormData);
      if (
        updateProfileResponse.error ||
        updateProfileResponse?.payload.error ||
        updateProfileResponse?.payload?.error_msg
      ) {
        showAlert(
          updateProfileResponse?.payload?.error || "Failed to update profile",
          "error"
        );
      } else {
        refetch();
        showAlert("Profile Updated", "success");
        onClose();
      }
    } catch (error) {}
  }

  return (
    <div className="h-screen bg-veryLightBlue">
      <Header
        hideBackBtn
        title="Update Profile"
        rightActionView={
          <img
            onClick={onClose}
            className="cursor-pointer w-10"
            src={Images.closeIcon}
            alt={"close_icons"}
          />
        }
      />
      <div
        id="update_profile_container"
        className="wrapper h-eliminateHeaderWithFooter overflow-y-auto"
      >
        <div className="p-12 min-h-full bg-blue">
          <MedicalAssistant
            content={
              <p>
                Please provide the below information for your medical chart with
                our practice.
              </p>
            }
          />
          <Spacer height={35} />
          <Formik
            innerRef={formRef}
            onSubmit={onFormSubmit}
            validationSchema={ProfileSchema}
            initialValues={{
              profile_pic: {
                uri: basicDetails?.photo_url ?? "",
                file: "",
                data: "",
              },
              first_name: basicDetails?.first_name ?? "",
              last_name: basicDetails?.last_name ?? "",
              email: basicDetails?.email ?? "",
              dob: basicDetails?.dob ?? "",
              phone: basicDetails?.mobile ? "1" + basicDetails?.mobile : "",
              address_1: basicDetails?.street_add_1 ?? "",
              address_2: basicDetails?.street_add_2 ?? "",
              city: basicDetails?.city ?? "",
              zipcode: basicDetails?.zip ?? "",
              gender: basicDetails?.gender
                ? capitalize(basicDetails?.gender)
                : "",
              heightFeet: basicDetails?.height
                ? getInchesInFeet({ height: basicDetails?.height }).feet
                : "",
              heightInches: basicDetails?.height
                ? getInchesInFeet({ height: basicDetails?.height }).inches
                : "",
              weight: basicDetails?.weight ?? "",
            }}
          >
            {({
              values,
              setFieldValue,
              setFieldTouched,
              dirty,
              touched,
              errors,
            }) => (
              <Form className={"bg-white p-12 rounded-3xl"}>
                <div className="flex items-center justify-center">
                  <div className="relative">
                    <div className="w-40 h-40 border border-borderColor rounded-4xl overflow-hidden">
                      <img
                        src={
                          values?.profile_pic?.data?.length
                            ? values?.profile_pic?.data
                            : values?.profile_pic?.uri?.length
                            ? values?.profile_pic?.uri
                            : ""
                        }
                        alt="dp"
                        className="w-full h-full object-contain"
                      />
                      <img
                        onClick={() => fileInputRef?.current?.click()}
                        className="w-16 h-16 absolute -bottom-4 -right-4 cursor-pointer"
                        src={Images.editIcon}
                        alt="edit"
                      />
                    </div>
                    <input
                      ref={fileInputRef}
                      onChange={(event) => {
                        const { target } = event;
                        const { files } = target;

                        if (files && files[0]) {
                          let reader = new FileReader();
                          reader.onload = (event) => {
                            let file_object;
                            file_object = {
                              uri: files?.[0]?.name?.toString(),
                              file: files[0],
                              data: event?.target?.result,
                            };
                            setFieldValue("profile_pic", file_object);
                          };
                          reader.readAsDataURL(files[0]);
                        }
                      }}
                      accept="image/*"
                      type="file"
                      className="opacity-0 w-0 h-0 absolute"
                    />
                  </div>
                </div>
                <Spacer height={32} />
                <Input
                  name="first_name"
                  label="First Name"
                  id="first_name"
                  disabled={basicDetails.first_name}
                />
                <Spacer height={32} />
                <Input
                  name="last_name"
                  label="Last Name"
                  id="last_name"
                  disabled={basicDetails.last_name}
                />
                <Spacer height={32} />
                <Input disabled name="email" label="Email" id="email" />
                <Spacer height={32} />
                {/* DOB Confirm Changes */}
                <BirthInput
                  ref={dobInputRef}
                  disabled={basicDetails.dob}
                  name="dob"
                  label="DOB"
                  id="dob"
                  isOpen={openCalendar}
                  open={() => setOpenCalendar(true)}
                  close={() => setOpenCalendar(false)}
                  max={moment().format("YYYY-MM-DD")}
                  onBlur={() => {
                    if (dirty) {
                      setFieldTouched("dob", true);
                      setShowConfirm(true);
                    }
                  }}
                  onChange={(event) => {
                    const date = event.target.value
                      ? moment(event.target.value).format("YYYY-MM-DD")
                      : "";
                    setFieldValue("dob", date);

                    if (date !== showDate) {
                      setShowDate(event.target.value ?? "");
                    }
                  }}
                />
                <Spacer height={32} />
                <Input
                  type={input_types.PHONE}
                  name="phone"
                  label="Phone"
                  id="phone"
                  value={values?.phone}
                  onChange={(val) => {
                    setFieldValue("phone", val);
                  }}
                  onBlur={() => setFieldTouched("phone", true)}
                />
                <Spacer height={32} />
                <Input
                  name="address_1"
                  label="Address 1"
                  id="address_1"
                  maxLength="35"
                />
                <Spacer height={32} />
                <Input
                  name="address_2"
                  label="Address 2"
                  id="address_2"
                  maxLength="35"
                />
                <Spacer height={32} />
                <div className="flex flex-col space-x-0 space-y-12 md:flex-row md:space-x-4 md:space-y-0">
                  <div className="flex-1">
                    <Input name="city" label="City" id="city" />
                  </div>
                  <div className="flex-1">
                    <Input
                      name="zipcode"
                      label="Zip Code"
                      id="zipcode"
                      maxLength={5}
                    />
                  </div>
                </div>
                <Spacer height={32} />
                <div className="flex flex-wrap items-start">
                  <div className="flex-1 flex items-end space-x-4 md:flex-initial">
                    <div className="flex-1 md:flex-initial">
                      <Input
                        type="unit"
                        label="Height"
                        id="heightFeet"
                        name="heightFeet"
                        unit="ft"
                        min="1"
                        max="9"
                        customonblur={() => {
                          if (values?.heightFeet) {
                            setShowHeight(
                              `${decimalRestriction({
                                number: Number(
                                  values.heightInches !== undefined &&
                                    values.heightInches > 0
                                    ? `${parseInt(
                                        values?.heightFeet
                                      )}.${parseInt(values?.heightInches)}`
                                    : parseInt(values?.heightFeet)
                                ),
                              })} ft`
                            );
                            // setShowHeightConfirm(true);
                            setFieldValue(
                              "heightFeet",
                              parseInt(values?.heightFeet)
                            );
                          }
                        }}
                      />
                    </div>
                    <div className="flex-1 md:flex-initial">
                      <Input
                        type="unit"
                        id="heightInches"
                        name="heightInches"
                        unit="in"
                        min="0"
                        max="11"
                        onFocus={() => setShowHeightConfirm(false)}
                        customonblur={() => {
                          if (values.heightInches !== "") {
                            setShowHeight(
                              `${decimalRestriction({
                                number: Number(
                                  values.heightInches !== undefined &&
                                    values.heightInches > 0
                                    ? `${parseInt(
                                        values?.heightFeet
                                      )}.${parseInt(values?.heightInches)}`
                                    : parseInt(values?.heightFeet)
                                ),
                              })} ft`
                            );
                            // setShowHeightConfirm(true);
                            setFieldValue(
                              "heightInches",
                              parseInt(
                                values.heightInches !== undefined
                                  ? values.heightInches
                                  : "0"
                              )
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="ml-4 flex-1 md:flex-initial">
                    <Input
                      style={{ width: "6ch" }}
                      type="unit"
                      label="Weight"
                      id="weight"
                      name="weight"
                      min="1"
                      unit="lbs"
                      customonblur={() => {
                        if (values?.weight) {
                          setShowWeight(
                            `${decimalRestriction({
                              number: Number(values?.weight),
                            })} lbs`
                          );
                          // setShowWeightConfirm(true);
                          setFieldValue(
                            "weight",
                            decimalRestriction({
                              number: Number(values?.weight),
                            })
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="ml-0 flex justify-between items-baseline md:ml-4">
                    <div className="flex-1 flex flex-col space-y-2">
                      <RegularRadioSelect
                        options={["Male", "Female"]}
                        selected={values?.gender}
                        setSelected={(el) => setFieldValue("gender", el)}
                      />
                      {touched?.gender && errors?.gender ? (
                        <p className="font-bold text-base text-red">
                          {errors?.gender}
                        </p>
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                </div>
                <Spacer height={64} />
                <FormikErrorFocus
                  align={"bottom"}
                  ease={"linear"}
                  duration={500}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <BottomFixedButton
        type="submit"
        loading={isProfileUpdating}
        label="Save Changes"
        onClick={() => formRef?.current?.handleSubmit()}
      />
      {/* DOB Confirm Changes */}
      {showConfirm ? (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-60">
          <div className="w-11/12 max-w-2xl p-8 flex flex-col space-y-8 bg-white">
            <p className="font-bold text-3xl">Confirm Date of Birth</p>
            <p className="font-medium text-2xl leading-9">
              You've entered {moment(showDate).format("MM-DD-YYYY")} as date of
              birth. Click CONFIRM to proceed or EDIT to change the date.
            </p>
            <div className="flex justify-end items-center space-x-4">
              <button
                className="text-xl font-bold p-4 uppercase bg-opacity-10 rounded text-indigo min-w-32"
                onClick={() => {
                  if (dobInputRef.current) {
                    dobInputRef.current.focus();
                  }
                  setOpenCalendar(true);
                  setShowConfirm(false);
                }}
              >
                Edit
              </button>
              <button
                className="flex items-center justify-center text-xl font-bold p-4 uppercase bg-indigo bg-opacity-20 rounded text-indigo min-w-32"
                onClick={() => {
                  setShowConfirm(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
      {/* Weight Confirm Changes */}
      {showWeightConfirm ? (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-60">
          <div className="w-11/12 max-w-2xl p-8 flex flex-col space-y-8 bg-white">
            <p className="font-bold text-3xl">Confirm Weight</p>
            <p className="font-medium text-2xl leading-9">
              You've entered <span className="font-bold">{showWeight}</span> as
              weight. Click CONFIRM to proceed or EDIT to change the weight.
            </p>
            <div className="flex justify-end items-center space-x-4">
              <button
                className="text-xl font-bold p-4 uppercase bg-opacity-10 rounded text-indigo min-w-32"
                onClick={() => {
                  setShowWeightConfirm(false);
                }}
              >
                Edit
              </button>
              <button
                className="flex items-center justify-center text-xl font-bold p-4 uppercase bg-indigo bg-opacity-20 rounded text-indigo min-w-32"
                onClick={() => {
                  setShowWeightConfirm(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
      {/* Height Confirm Changes */}
      {showHeightConfirm ? (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-60">
          <div className="w-11/12 max-w-2xl p-8 flex flex-col space-y-8 bg-white">
            <p className="font-bold text-3xl">Confirm Height</p>
            <p className="font-medium text-2xl leading-9">
              You've entered <span className="font-bold">{showHeight}</span> as
              height. Click CONFIRM to proceed or EDIT to change the height.
            </p>
            <div className="flex justify-end items-center space-x-4">
              <button
                className="text-xl font-bold p-4 uppercase bg-opacity-10 rounded text-indigo min-w-32"
                onClick={() => {
                  setShowHeightConfirm(false);
                }}
              >
                Edit
              </button>
              <button
                className="flex items-center justify-center text-xl font-bold p-4 uppercase bg-indigo bg-opacity-20 rounded text-indigo min-w-32"
                onClick={() => {
                  setShowHeightConfirm(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

export default UpdateProfileModal;
