import React from "react";

function VisitSummaryContent({ children, onEditClick, readOnly }) {
  return (
    <div className="pb-6">
      <div className="w-full bg-backgroundWhite rounded-xl p-6 relative">
        {children}
        {
          readOnly
            ? null
            : children && (
                <button
                  className="absolute px-8 top-6 right-6 bg-white border border-orange rounded-md text-orange text-2xl font-semibold py-2"
                  onClick={onEditClick}
                >
                  Edit
                </button>
              )
          // <img
          //   onClick={onEditClick}
          //   src={Images.editIconInverted}
          //   alt="edit"
          //   className="absolute w-16 h-16 top-8 right-8 cursor-pointer"
          // />
        }
      </div>
    </div>
  );
}

export default VisitSummaryContent;
