import React, { useState } from "react";
import CheckBoxCard from "components/checkBoxCard/CheckBoxCard";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import { medicalHistoryData } from "./medicalHistoryData";
import { useDispatch } from "react-redux";
import useVisitStepChange from "hooks/useVisitStepChange";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import { dermatology_sub_steps } from "helpers/enum";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { useEffect } from "react";
import usePatients from "hooks/visit/usePatients";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";

function MedicalHistory() {
  const dispatch = useDispatch();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const [selected, setSelected] = useState([]);
  const { patientsList, incompleteData, subSteps } = useDermatologyVisitFlow();
  const { getCurrentPatient } = usePatients();
  const currentPatient = getCurrentPatient(
    incompleteData?.patient_id,
    patientsList
  );
  const currentScreenValueFromStore =
    subSteps[dermatology_sub_steps.medical_history].value;
  const { updateVisit } = useUpdateDermatologyVisit();

  useEffect(() => {
    if (currentScreenValueFromStore?.length) {
      const result = currentScreenValueFromStore?.filter((el) => {
        return medicalHistoryData.some((elem) => {
          return el === elem;
        });
      });
      setSelected(result);
    }
  }, [currentScreenValueFromStore]);

  useEffect(() => {
    if (selected?.length) {
      dispatch(
        dermaVisitFlowReducers.medical_history({
          isValid: true,
        })
      );
    } else {
      dispatch(
        dermaVisitFlowReducers.medical_history({
          isValid: false,
        })
      );
    }
  }, [dispatch, selected]);
  useEffect(() => {
    if (currentPatient && currentPatient?.gender) {
      if (currentPatient?.gender === "female") {
        dispatch(
          dermaVisitFlowReducers.medical_history({
            nextScreen: dermatology_sub_steps.female_medical_history,
          })
        );
      } else {
        dispatch(
          dermaVisitFlowReducers.medical_history({
            nextScreen: dermatology_sub_steps.allergies,
          })
        );
      }
    } else {
      dispatch(
        dermaVisitFlowReducers.medical_history({
          nextScreen: dermatology_sub_steps.allergies,
        })
      );
    }
  }, [dispatch, currentPatient]);

  function onRightButtonClick(_, nextAnimation) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          fhistory:
            subSteps?.[dermatology_sub_steps.female_medical_history]?.value ??
            [],
          ghistory: selected,
          dermatology_steps: incompleteData?.dermatology_steps,
        },
      },
      change_current_screen: true,
      screen_to_complete:
        currentPatient?.gender === "female"
          ? null
          : dermatology_sub_steps.medical_history,
      callBack: () => {
        dispatch(
          dermaVisitFlowReducers.medical_history({
            value: selected,
          })
        );
        nextAnimation();
      },
    });
  }

  function handleOnSelect(el) {
    if (el === "None") {
      setSelected([el]);
    } else {
      let final = [...selected];

      if (final?.includes("None")) {
        final = final.filter((elem) => elem !== "None");
      }
      if (final?.includes(el)) {
        setSelected(final.filter((elem) => elem !== el));
      } else {
        setSelected([...final, el]);
      }
    }
  }

  return (
    <StepScreenContainer>
      <MedicalAssistant
        content={<p>And, do any of the below lifestyle items apply to you?</p>}
      />
      <Card>
        <CheckBoxCard
          options={medicalHistoryData}
          selected={selected}
          onSelect={handleOnSelect}
        />
      </Card>
    </StepScreenContainer>
  );
}

export default MedicalHistory;
