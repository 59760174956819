import { primary_care_main_steps } from "helpers/enum";

export const primaryCareMainStepsReducer = {
  basic_info(state, action) {
    const { currentScreen, status } = action?.payload;
    if (status) {
      state.mainSteps[primary_care_main_steps.basic_info].status = status;
    }
    if (currentScreen) {
      state.mainSteps[
        primary_care_main_steps.basic_info
      ].currentScreen = currentScreen;
    }
  },
  visit_details(state, action) {
    const { status } = action?.payload;
    state.mainSteps[primary_care_main_steps.visit_details].status = status;
  },
  main_select_slot(state, action) {
    const { status } = action?.payload;
    state.mainSteps[primary_care_main_steps.main_select_slot].status = status;
  },
  additional_details(state, action) {
    const { status } = action?.payload;
    state.mainSteps[primary_care_main_steps.additional_details].status = status;
  },
};
