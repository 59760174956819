import {
  dermatology_main_steps,
  dermatology_sub_steps,
  step_status,
} from "helpers/enum";
import { setNextScreenToUpcoming } from "helpers/setDermatologyStepStatus";

export const dermaPhotosReducer = {
  upload_photos(state, action) {
    const { status, value, isValid } = action.payload;
    if (status) {
      state.subSteps[dermatology_sub_steps.upload_photos].status = status;
      if (status === step_status.completed) {
        state.mainSteps[dermatology_main_steps.photos].status =
          step_status.completed;
        if (
          state.mainSteps[dermatology_main_steps.main_symptoms].status ===
          step_status.upcoming
        ) {
          state.mainSteps[dermatology_main_steps.main_symptoms].status =
            step_status.current;
        }
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: dermatology_sub_steps.symptoms,
        });
      }
    }
    if (value !== undefined) {
      state.subSteps[dermatology_sub_steps.upload_photos].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[dermatology_sub_steps.upload_photos].isValid = isValid;
    }
  },
};
