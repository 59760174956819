import React from "react";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Spacer from "components/Spacer/Spacer";
import { useCurrentUser } from "hooks/useCurrentUser";
import DermatologyVisitSummary from "pages/home/waitingRoom/visitFlow/dermatology/checkout/DermatologyVisitSummary";
import PrimaryCareVisitSummary from "pages/home/waitingRoom/visitFlow/primaryCare/checkout/PrimaryCareVisitSummary";
import { useLocation } from "react-router-dom";
import Header from "components/header/Header";

function VisitSummary() {
  const { userDetails } = useCurrentUser();
  const location = useLocation();
  const visitDetails = location?.state?.visitDetails;

  return (
    <div className="h-screen bg-veryLightBlue overflow-hidden">
      <Header title="Visit Summary" />
      {!visitDetails?.error ? (
        <div className="overflow-y-auto wrapper pt-10 bg-white h-eliminateHeader relative">
          <div className="px-12">
            <MedicalAssistant
              content={
                <p>
                  {visitDetails?.patient_name?.replace(/\s+/g, " ")}, here are
                  your visit details.
                </p>
              }
            />
          </div>
          {visitDetails?.zoom_meeting && (
            <div className="p-12">
              <p className="text-2xl font-light leading-9">
                {visitDetails?.provider_name} has invited you to join a secure
                video call. To join the call, please click on the button below.
              </p>
              <a
                href={visitDetails?.zoom_meeting?.join_url}
                className={
                  "inline-block font-bold text-2xl py-4 px-16 focus:ring-2 bg-orange text-white rounded-lg cursor-pointer no-underline mt-6"
                }
              >
                Join
              </a>
            </div>
          )}
          <div className="px-12">
            <h2 className="font-black text-xl tracking-wide mt-10">
              VISIT SUMMARY
            </h2>
          </div>
          <Spacer height={23} />
          {visitDetails?.speciality === "primary_care" ? (
            <PrimaryCareVisitSummary
              readOnly
              data={{
                ...visitDetails,
                patient_photo_id: userDetails?.photo_id,
              }}
            />
          ) : (
            <DermatologyVisitSummary
              readOnly
              data={{
                ...visitDetails,
                patient_photo_id: userDetails?.photo_id,
              }}
            />
          )}
          <Spacer height={80} />
        </div>
      ) : null}
    </div>
  );
}

export default VisitSummary;
