import React from "react";
import { useField } from "formik";
import Spacer from "components/Spacer/Spacer";
import "./FullBorderSelect.scss";
import Images from "assets/images";

function FullBorderSelect({ type, label, containerClassName, ...rest }) {
  const [field, meta] = useField(rest);
  return (
    <div className={`flex flex-col ${containerClassName ?? ""}`}>
      <label
        className="font-bold text-2xl text-indigo text-opacity-50"
        htmlFor={rest?.id}
      >
        {label}
      </label>
      <Spacer height={8} />
      <div className="relative rounded-1.6 h-24 bg-white border-solid border-2 w-full border-borderColor pl-6 pr-12">
        <select
          className={`h-full bg-transparent font-medium text-3xl ${
            !field?.value?.length ? "opacity-50" : "opacity-100"
          } text-indigo w-full outline-none cursor-pointer placeholder-cloudyBlue rounded-none`}
          {...field}
          {...rest}
        >
          {rest?.default ? <option value="">{rest?.default}</option> : null}
          {rest?.options?.map((el, index) => (
            <option key={index} value={el}>
              {el}
            </option>
          ))}
        </select>
        <img
          src={Images.dropdown}
          alt="chev_down"
          className={`absolute right-2 transition duration-500 ease-in-out w-10 h-10 transform rotate-0 top-1/2 -translate-y-1/2`}
        />
      </div>
      {meta.touched && meta.error ? (
        <p className="font-bold text-base mt-2 text-red">{meta.error}</p>
      ) : null}
    </div>
  );
}

export default FullBorderSelect;
