import React, { useCallback, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import Header from "components/header/Header";
import Images from "assets/images";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useApiMutation } from "hooks/useApiMutation";
import { getPharmacyList } from "api/actions/UserActions";
import Marker from "./Marker";
import LoaderComponent from "components/loader/LoaderComponent";
import Spacer from "components/Spacer/Spacer";
import "./PharmacyMapView.scss";
import PharmacyListView from "./PharmacyListView";

function PharmacyMapView({ onClose, onSelectPharmacy }) {
  const { userDetails } = useCurrentUser();
  const { mutate: callGetPharmacyList, loading: gettingPharmacyList } =
    useApiMutation(getPharmacyList);
  const [pharmacyList, setPharmacyList] = useState([]);
  const [currentLatLong, setCurrentLatLong] = useState(null);
  const [activePharmacy, setActivePharmacy] = useState(null);
  const [zipcode, setZipcode] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isListView, setIsListView] = useState(false);

  const getPharmacyListAPICallback = useCallback(getPharmacyListAPI, [
    callGetPharmacyList,
    zipcode,
  ]);

  useEffect(() => {
    if (userDetails?.zip) {
      setZipcode(userDetails?.zip);
    }
  }, [userDetails?.zip]);

  useEffect(() => {
    if (zipcode) {
      getPharmacyListAPICallback();
    }
  }, [getPharmacyListAPICallback, zipcode]);

  async function getPharmacyListAPI() {
    try {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode}&key=${process.env.REACT_APP_GOOGLE_API}`
      )
        .then((res) => {
          res
            .json()
            .then(async (data) => {
              const lat = data?.results[0]?.geometry?.location?.lat;
              const lng = data?.results[0]?.geometry?.location?.lng;
              setCurrentLatLong(data?.results[0]?.geometry?.location);
              const result = await callGetPharmacyList({
                q: null,
                lat,
                lng,
              });
              if (result && result.error === false) {
                setPharmacyList(result.payload);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className="bg-veryLightBlue">
      <Header
        hideBackBtn
        title={"Select Pharmacy"}
        rightActionView={
          <img
            onClick={onClose}
            className="cursor-pointer w-10"
            src={Images.closeIcon}
            alt={"close_icons"}
          />
        }
      />
      <div className={"wrapper bg-white"} style={{ height: "5.9rem" }}>
        <div className="h-full flex items-center justify-center px-12">
          <input
            onChange={(event) => setSearchText(event?.target?.value)}
            maxLength={5}
            type={"text"}
            placeholder="Search by Zipcode"
            className="h-16 w-full bg-alternativeWhite p-8 text-2xl font-medium focus:outline-none"
          />
          <Spacer width={10} />
          <button
            onClick={() => setZipcode(searchText)}
            className={
              "bg-orange w-32 rounded-xl h-16 flex items-center justify-center"
            }
          >
            <img className="w-6" src={Images.searchWhite} alt="search" />
          </button>
        </div>
      </div>
      <div
        className={
          "w-full bg-white h-eliminateHeaderWithSearchInput relative wrapper"
        }
      >
        {gettingPharmacyList || !currentLatLong ? (
          <LoaderComponent showLoader={{ state: true }} />
        ) : (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_API,
            }}
            center={{
              lat: currentLatLong?.lat,
              lng: currentLatLong?.lng,
            }}
            defaultZoom={13}
            zoom={activePharmacy ? 16 : 13}
          >
            {pharmacyList?.map((pharmacy, index) => {
              return (
                <Marker
                  key={index}
                  lat={pharmacy?.coordinates?.[0]}
                  lng={pharmacy?.coordinates?.[1]}
                  onClick={() => {
                    setActivePharmacy(pharmacy);
                    setCurrentLatLong({
                      lat: pharmacy?.coordinates?.[0],
                      lng: pharmacy?.coordinates?.[1],
                    });
                  }}
                  isActive={
                    activePharmacy?.pharmacy_id === pharmacy?.pharmacy_id
                  }
                  activePharmacy={activePharmacy}
                  onClose={() => {
                    setActivePharmacy(null);
                  }}
                  onSelectPharmacy={() => {
                    onSelectPharmacy(pharmacy);
                    onClose();
                  }}
                >
                  My Marker
                </Marker>
              );
            })}
          </GoogleMapReact>
        )}
        <div
          className={`absolute h-eliminateHeaderWithSearchInput w-full bg-white z-10 bottom-0 left-0 pharmacy_list_view-${
            isListView ? "up" : "down"
          } overflow-y-auto`}
        >
          {gettingPharmacyList ? (
            <LoaderComponent showLoader={{ state: true }} />
          ) : (
            <PharmacyListView
              pharmacyList={pharmacyList}
              onClickPharmacy={(pharmacy) => {
                onSelectPharmacy(pharmacy);
                onClose();
              }}
            />
          )}
        </div>
        <div
          onClick={() =>
            gettingPharmacyList ? null : setIsListView(!isListView)
          }
          className={`bg-white absolute bottom-52 right-4 flex items-center py-6 px-10 rounded-2xl border border-orange z-20 md:bottom-8 md:right-20 ${
            gettingPharmacyList
              ? "opacity-50 cursor-not-allowed "
              : "opacity-100 cursor-pointer "
          } `}
        >
          <img
            src={isListView ? Images.mapView : Images.listView}
            alt="list"
            className={"w-8"}
          />
          <p className="pl-4 text-2xl font-bold">
            Switch To {isListView ? "Map" : "List"} View
          </p>
        </div>
      </div>
    </div>
  );
}

export default PharmacyMapView;
