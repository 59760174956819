import React, { useCallback, useEffect, useState } from "react";

import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import { useCurrentUser } from "hooks/useCurrentUser";
import useVisitStepChange from "hooks/useVisitStepChange";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import useCreateDermatologyVisit from "hooks/visit/useCreateDermatologyVisit";
import usePatients from "hooks/visit/usePatients";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import { useHistory } from "react-router-dom";
import { dermatology_sub_steps } from "helpers/enum";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";

const options = ["Me", "My Child", "Someone else"];

function SelectPatientForDerm() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const { userDetails } = useCurrentUser();
  const { getAccountHolder } = usePatients();
  const { incompleteData, patientsList, subSteps } = useDermatologyVisitFlow();
  const currentScreenValueFromStore =
    subSteps[dermatology_sub_steps.select_patient].value;

  const { createNewVisit, creatingVisit } = useCreateDermatologyVisit();
  const { updateVisit } = useUpdateDermatologyVisit();

  const [selected, setSelected] = useState(null);

  const getNextScreen = useCallback(() => {
    if (selected === options?.[0]) {
      return dermatology_sub_steps.confirm_residence;
    } else if (selected === options?.[1]) {
      return dermatology_sub_steps.select_child;
    } else {
      return dermatology_sub_steps.select_someone_else;
    }
  }, [selected]);

  useEffect(() => {
    if (currentScreenValueFromStore) {
      setSelected(currentScreenValueFromStore);
    }
  }, [currentScreenValueFromStore, patientsList]);

  useEffect(() => {
    if (selected?.length) {
      dispatch(
        dermaVisitFlowReducers.select_patient({
          isValid: true,
          nextScreen: getNextScreen(),
        })
      );
    } else {
      dispatch(
        dermaVisitFlowReducers.select_patient({
          isValid: false,
        })
      );
    }
  }, [dispatch, selected, getNextScreen]);

  function onRightButtonClick(_, nextAnimation) {
    if (selected === options?.[0]) {
      if (incompleteData && Object.keys(incompleteData)?.length) {
        updateVisit({
          payload: {
            case: {
              id: incompleteData?.id,
              patient_id: getAccountHolder(patientsList)?.id,
              dermatology_steps: incompleteData?.dermatology_steps,
            },
          },
          change_current_screen: false,
          screen_to_complete: null,
          callBack: () => {
            dispatch(
              dermaVisitFlowReducers.select_patient({
                value: "Me",
              })
            );
            history.push("/visit/dermatology/pricing");
          },
        });
      } else {
        createNewVisit({
          patient_id: getAccountHolder(patientsList)?.id,
          change_current_screen: false,
          screen_to_complete: null,
          callBack: () => {
            dispatch(
              dermaVisitFlowReducers.select_patient({
                value: "Me",
              })
            );
            history.push("/visit/dermatology/pricing");
          },
        });
      }
    } else {
      dispatch(
        dermaVisitFlowReducers.select_patient({
          value: selected,
        })
      );
      nextAnimation();
      setTimeout(() => {
        dispatch(
          dermaVisitFlowReducers.currentScreen({
            value: getNextScreen(),
          })
        );
      }, 300);
    }
  }

  return (
    <StepScreenContainer align="start" childClass={"wrapper"}>
      <MedicalAssistant
        content={
          <p>
            Hi {userDetails?.first_name ? userDetails?.first_name : " "}! Let's
            get started. Please tell me who the patient is?
          </p>
        }
      />
      <Card>
        <RadioButtonCard
          loading={creatingVisit}
          options={options}
          onSelect={setSelected}
          selected={selected}
        />
      </Card>
    </StepScreenContainer>
  );
}

export default SelectPatientForDerm;
