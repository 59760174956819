import React from "react";
import { useCosmeticConsultationFlow } from "hooks/useConsultationFlow";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import VisitSummaryContent from "components/visitSummaryAccordion/VisitSummaryContent";
import { cosmeticConsultationReducers } from "reducers/cosmetic/cosmeticConsultationReducer";
import { cosmetic_steps } from "helpers/enum";
import VisitSummaryContentHeading from "components/visitSummaryAccordion/VisitSummaryContentHeading";
import VisitSummaryContentParagraph from "components/visitSummaryAccordion/VisitSummaryContentParagraph";
import Spacer from "components/Spacer/Spacer";

function CosmeticConsultationDetailsSummary({ data, readOnly }) {
  const { incompleteData } = useCosmeticConsultationFlow();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <VisitSummaryContent
      readOnly={readOnly}
      onEditClick={() => {
        dispatch(
          cosmeticConsultationReducers.currentScreen({
            value: cosmetic_steps.select_condition,
          })
        );
        history.push("/consultation/cosmetic");
      }}
    >
      {data?.cosmetic_concern?.length ||
      incompleteData?.cosmetic_concern?.length ? (
        <>
          <Spacer height={20} />
          <VisitSummaryContentHeading text="Condition" />
          <VisitSummaryContentParagraph
            text={
              data?.cosmetic_concern
                ? data?.cosmetic_concern?.toString()?.split(",")?.join(", ")
                : incompleteData?.cosmetic_concern
                ? incompleteData?.cosmetic_concern
                    ?.toString()
                    ?.split(",")
                    .join(", ")
                : ""
            }
          />
        </>
      ) : null}
    </VisitSummaryContent>
  );
}

export default CosmeticConsultationDetailsSummary;
