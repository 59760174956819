import {
  dermatology_main_steps,
  dermatology_sub_steps,
  step_status,
} from "helpers/enum";

export const dermaVisitFlowInitialState = {
  currentScreen: dermatology_sub_steps.select_patient,
  incompleteData: null,
  patientsList: null,
  completedProgress: 0,
  paymentMethod: null,
  mainSteps: {
    [dermatology_main_steps.basic_info]: {
      status: step_status.current,
      currentScreen: null,
    },
    [dermatology_main_steps.visit_details]: {
      status: step_status.upcoming,
    },
    [dermatology_main_steps.photos]: {
      status: step_status.upcoming,
    },
    [dermatology_main_steps.main_symptoms]: {
      status: step_status.upcoming,
    },
    [dermatology_main_steps.main_medical_history]: {
      status: step_status.upcoming,
    },
  },
  subSteps: {
    [dermatology_sub_steps.select_patient]: {
      value: null,
      isValid: false,
      prevScreen: null,
      nextScreen: null,
      status: step_status.current,
      headerTitle: "Basic Info",
      progress: 15,
    },
    [dermatology_sub_steps.select_child]: {
      value: null,
      isValid: false,
      prevScreen: dermatology_sub_steps.select_patient,
      nextScreen: dermatology_sub_steps.confirm_residence,
      headerTitle: "Basic Info",
    },
    [dermatology_sub_steps.select_someone_else]: {
      value: null,
      isValid: false,
      prevScreen: dermatology_sub_steps.select_patient,
      nextScreen: dermatology_sub_steps.confirm_residence,
      headerTitle: "Basic Info",
    },
    [dermatology_sub_steps.confirm_residence]: {
      value: null,
      isValid: false,
      prevScreen: null,
      nextScreen: dermatology_sub_steps.patient_bio,
      status: step_status.upcoming,
      headerTitle: "Basic Info - Confirm Residence",
      progress: 30,
      rightButtonText: "Confirm",
    },
    [dermatology_sub_steps.patient_bio]: {
      value: null,
      isValid: false,
      prevScreen: dermatology_sub_steps.confirm_residence,
      nextScreen: dermatology_sub_steps.identity_confirmation,
      status: step_status.upcoming,
      headerTitle: "Basic Info - Patient Bio",
      progress: 40,
    },
    [dermatology_sub_steps.identity_confirmation]: {
      value: null,
      isValid: false,
      prevScreen: dermatology_sub_steps.patient_bio,
      nextScreen: dermatology_sub_steps.select_condition,
      status: step_status.upcoming,
      headerTitle: "Basic Info - Identity Confirmation",
      progress: 50,
    },
    [dermatology_sub_steps.select_condition]: {
      value: null,
      isValid: false,
      prevScreen: dermatology_sub_steps.identity_confirmation,
      nextScreen: dermatology_sub_steps.select_affected_part,
      status: step_status.upcoming,
      headerTitle: "Visit Details",
      progress: 53,
    },
    [dermatology_sub_steps.select_affected_part]: {
      value: null,
      isValid: false,
      status: step_status.upcoming,
      prevScreen: dermatology_sub_steps.select_condition,
      nextScreen: dermatology_sub_steps.condition_details,
      headerTitle: "Visit Details",
      progress: 56,
    },
    [dermatology_sub_steps.condition_details]: {
      value: null,
      isValid: false,
      prevScreen: dermatology_sub_steps.select_affected_part,
      nextScreen: dermatology_sub_steps.upload_photos,
      headerTitle: "Visit Details",
      status: step_status.upcoming,
      progress: 59,
    },
    [dermatology_sub_steps.upload_photos]: {
      value: null,
      isValid: false,
      status: step_status.upcoming,
      prevScreen: dermatology_sub_steps.condition_details,
      nextScreen: dermatology_sub_steps.symptoms,
      headerTitle: "Photos",
      progress: 62,
    },
    [dermatology_sub_steps.symptoms]: {
      value: null,
      isValid: false,
      prevScreen: dermatology_sub_steps.upload_photos,
      nextScreen: dermatology_sub_steps.more_about_symptoms,
      status: step_status.upcoming,
      headerTitle: "Symptoms",
      progress: 65,
    },
    [dermatology_sub_steps.more_about_symptoms]: {
      value: null,
      formValues: null,
      isValid: false,
      prevScreen: dermatology_sub_steps.symptoms,
      nextScreen: dermatology_sub_steps.medical_history,
      status: step_status.upcoming,
      headerTitle: "Symptoms",
      progress: 80,
    },
    [dermatology_sub_steps.medical_history]: {
      value: null,
      isValid: false,
      prevScreen: dermatology_sub_steps.more_about_symptoms,
      nextScreen: null,
      status: step_status.upcoming,
      headerTitle: "Medical History",
      progress: 83,
    },
    [dermatology_sub_steps.female_medical_history]: {
      value: null,
      isValid: false,
      prevScreen: dermatology_sub_steps.medical_history,
      nextScreen: dermatology_sub_steps.allergies,
      status: step_status.upcoming,
      headerTitle: "Medical History",
    },
    [dermatology_sub_steps.allergies]: {
      isValid: false,
      prevScreen: dermatology_sub_steps.medical_history,
      nextScreen: dermatology_sub_steps.medications,
      status: step_status.upcoming,
      headerTitle: "Medical History",
      formValues: null,
      progress: 86,
    },
    [dermatology_sub_steps.medications]: {
      value: null,
      isValid: false,
      prevScreen: dermatology_sub_steps.allergies,
      nextScreen: dermatology_sub_steps.skin_care_products,
      status: step_status.upcoming,
      headerTitle: "PRESCRIPTION & OTC MEDICATIONS",
      formValues: null,
      progress: 89,
    },
    [dermatology_sub_steps.skin_care_products]: {
      value: null,
      isValid: false,
      prevScreen: dermatology_sub_steps.medications,
      nextScreen: dermatology_sub_steps.select_pharmacy,
      status: step_status.upcoming,
      headerTitle: "Medical History",
      formValues: null,
      progress: 92,
    },
    [dermatology_sub_steps.select_pharmacy]: {
      value: null,
      isValid: false,
      prevScreen: dermatology_sub_steps.skin_care_products,
      nextScreen: dermatology_sub_steps.other_feedbacks,
      status: step_status.upcoming,
      headerTitle: "Pharmacy",
      progress: 100,
    },
    [dermatology_sub_steps.other_feedbacks]: {
      value: null,
      formValues: null,
      isValid: false,
      prevScreen: dermatology_sub_steps.select_pharmacy,
      nextScreen: null,
      status: step_status.upcoming,
      headerTitle: "Medical History",
    },
  },
};
