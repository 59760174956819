import { speciality } from "helpers/enum";
import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function ProviderWithPercentage({ doctorDetails, percentage, blueBg }) {
  return (
    <div
      className={`wrapper flex items-center justify-between px-12 py-6 ${
        blueBg ? "bg-blue bg-opacity-80" : "bg-alternativeWhite"
      }`}
    >
      {doctorDetails?.provider_id ? (
        <div className="flex items-center">
          <img
            className={"w-24 h-24 rounded-full object-center"}
            src={doctorDetails?.provider_photo_url}
            alt="provider_image"
          />
          <div className="pl-6">
            <p className={`font-medium text-xl ${blueBg ? "text-white" : ""}`}>
              Your{" "}
              {doctorDetails?.speciality &&
              doctorDetails?.speciality === speciality.cosmetic
                ? "consultation"
                : "visit"}{" "}
              with
            </p>
            <h3
              className={`text-3xl font-bold pt-2 ${
                blueBg ? "text-white" : ""
              }`}
            >
              {doctorDetails?.provider_name}
            </h3>
          </div>
        </div>
      ) : (
        <div className="flex items-center" />
      )}
      <div className="self-end w-24 h-24">
        <CircularProgressbar
          value={percentage}
          text={`${percentage}%`}
          className={"font-bold"}
          strokeWidth={11}
          styles={buildStyles({
            rotation: 0,
            pathTransitionDuration: 2,
            pathColor: "#ff8548",
            textColor: blueBg ? "white" : "#101347",
            trailColor: "#d6d6d6",
            backgroundColor: "#3e98c7",
          })}
        />
      </div>
    </div>
  );
}

export default ProviderWithPercentage;
