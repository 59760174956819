import React, { useRef } from "react";
import Input from "components/inputs/Input";
import { Form, Formik } from "formik";
import { addressSchema } from "helpers/validationSchema";
import CSSLoader from "components/cssLoader/CSSLoader";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useApiMutation } from "hooks/useApiMutation";
import { changeShippingAddress } from "api/actions/VisitActions";
import useToastify from "hooks/useToastify";
import { Icon } from "stories/Icons";

const ChangeShippingAddress = ({
  patientId,
  shippingAddress,
  setShippingAddress,
  setShowForm,
}) => {
  const formRef = useRef();
  const currentUser = useCurrentUser();
  const { showAlert } = useToastify();

  const {
    mutate: callChangeShippingAddress,
    loading: updatingShippingAddress,
  } = useApiMutation(changeShippingAddress);

  const onFormSubmit = async () => {
    let payload = {
      patient: {
        shipping_address_attributes: {
          line_1: formRef?.current?.values?.address_1,
          line_2: formRef?.current?.values?.address_2,
          city: formRef?.current?.values?.city,
          zip: formRef?.current?.values?.zipcode,
        },
      },
    };

    if (shippingAddress?.id) {
      payload.patient.shipping_address_attributes.id = shippingAddress?.id;
    }

    const result = await callChangeShippingAddress({
      account_id: currentUser?.userId,
      patient_id: patientId,
      payload,
    });
    if (result && result.error === false) {
      setShippingAddress(result?.payload?.shipping_address);
      setShowForm(false);
      showAlert("Changed shipping address", "success");
    } else {
      showAlert(
        result?.payload?.error_message ?? "Something went wrong",
        "error"
      );
    }
  };

  return (
    <div className="fixed top-0 left-0 z-40 flex items-center justify-center w-full h-full bg-black bg-opacity-60">
      <div className="flex flex-col w-full p-6 mx-4 space-y-12 bg-white rounded-lg md:w-1/3 md:mx-0">
        <div className="flex items-center justify-between pb-8 border-b">
          <p className="text-3xl font-bold">Add shipping address</p>
          <button onClick={() => setShowForm(false)}>
            <Icon name="close" />
          </button>
        </div>
        <Formik
          innerRef={formRef}
          onSubmit={onFormSubmit}
          validationSchema={addressSchema}
          initialValues={{
            address_1: "",
            address_2: "",
            city: "",
            zipcode: "",
          }}
        >
          {({ dirty, isValid }) => (
            <Form className="flex flex-col space-y-8">
              <Input
                name="address_1"
                label="Street address line 1*"
                placeholder="Enter street address line 1..."
                id="address_1"
                maxLength="35"
              />
              <Input
                name="address_2"
                label="Street address line 2"
                placeholder="Enter street address line 2..."
                id="address_2"
                maxLength="35"
              />
              <Input
                name="city"
                label="City*"
                placeholder="Enter city..."
                id="city"
              />
              <Input
                name="zipcode"
                label="Zip Code*"
                placeholder="Enter zip code..."
                id="zipcode"
                maxLength={5}
              />
              <div className="flex">
                <button
                  disabled={!(dirty && isValid) && updatingShippingAddress}
                  className={`flex-1 flex items-center justify-center text-2xl font-bold p-4 rounded-lg bg-orange text-white${
                    dirty && isValid && !updatingShippingAddress
                      ? ""
                      : " opacity-60 cursor-not-allowed"
                  }`}
                  type="submit"
                >
                  Save address
                  {updatingShippingAddress ? (
                    <CSSLoader className={"ml-3 w-8 h-8"} />
                  ) : null}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangeShippingAddress;
