import Images from "assets/images";
import Spacer from "components/Spacer/Spacer";
import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

const OTCError = () => {
  const history = useHistory();
  const location = useLocation();
  const { visit_id } = useParams();

  const changePayment = () => {
    history.push(`/visit-details/${visit_id}/payments`, {
      patientName: location?.state?.patientName,
      payload: location?.state?.payload,
    });
  };

  return (
    <div className="h-screen bg-veryLightBlue">
      <div className="wrapper h-full overflow-hidden bg-white">
        <div className="h-full flex flex-col justify-center items-center">
          <img src={Images.filledError} alt="success" className="w-36 h-36" />
          <Spacer height={42} />
          <h3 className="font-bold text-4xl text-indigo">Error</h3>
          <Spacer height={12} />
          <p className="font-semibold text-3xl text-indigo">
            Payment has failed
          </p>
          <Spacer height={32} />
          <p className="w-full px-8 font-light text-3xl text-indigo text-center md:w-3/4 md:px-0">
            Payment declined: try another bank account or try another payment
            method
          </p>
          <Spacer height={32} />
          <button
            onClick={changePayment}
            className="px-10 py-2 bg-orange rounded-full font-bold text-2xl text-white"
          >
            Try different payment
          </button>
        </div>
      </div>
    </div>
  );
};

export default OTCError;
