import moment from "moment";
import TextWithBulletPoint from "../TextWithBulletPoint";

export const getSurgeriesHospitalisationsView = (payload) => {
  if (payload?.hospitalizations?.length) {
    return (
      <div className="p-8 bg-alternativeWhite border border-borderColor mt-8 rounded-xl">
        {payload?.hospitalizations?.map((el, idx) => {
          return (
            <div className="mb-4" key={idx}>
              <TextWithBulletPoint
                text={`${el?.surgeries_or_hospitalisations}, ${moment(
                  el?.date,
                  "YYYY-MM-DD"
                ).format("DD MMM, YYYY")}`}
              />
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};
