import React, { useEffect, useState } from "react";
import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import Button from "components/buttons/Button";
import useModal from "hooks/useModal";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { useDispatch } from "react-redux";
import useVisitStepChange from "hooks/useVisitStepChange";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import { useHistory } from "react-router-dom";
import { dermatology_sub_steps } from "helpers/enum";
import useCreateDermatologyVisit from "hooks/visit/useCreateDermatologyVisit";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import AddChildModalDerm from "../../common/AddChildModalDerm";

function SelectChildForDerm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const { setShowModal } = useModal();
  const [selected, setSelected] = useState(null);
  const { patientsList, incompleteData, subSteps } = useDermatologyVisitFlow();
  const currentScreenValueFromStore =
    subSteps[dermatology_sub_steps.select_child].value;

  const { createNewVisit, creatingVisit } = useCreateDermatologyVisit();
  const { updateVisit, updatingVisit } = useUpdateDermatologyVisit();

  useEffect(() => {
    if (currentScreenValueFromStore) {
      setSelected(currentScreenValueFromStore);
    } else {
      if (
        patientsList?.filter(
          (patient) =>
            patient?.relationship_with_account &&
            patient.relationship_with_account === "pappa"
        ).length === 1
      ) {
        setSelected(
          patientsList?.filter(
            (patient) =>
              patient?.relationship_with_account &&
              patient.relationship_with_account === "pappa"
          )[0].full_name
        );
      }
    }
  }, [currentScreenValueFromStore, patientsList]);

  useEffect(() => {
    if (selected?.length) {
      dispatch(
        dermaVisitFlowReducers.select_child({
          isValid: true,
        })
      );
    } else {
      dispatch(
        dermaVisitFlowReducers.select_child({
          isValid: false,
        })
      );
    }
  }, [dispatch, selected]);

  function onRightButtonClick() {
    if (incompleteData && Object.keys(incompleteData)?.length) {
      updateVisit({
        payload: {
          case: {
            id: incompleteData?.id,
            patient_id: patientsList?.find((el) => el?.full_name === selected)
              ?.id,
            dermatology_steps: incompleteData?.dermatology_steps,
          },
        },
        change_current_screen: false,
        screen_to_complete: null,
        callBack: () => {
          dispatch(
            dermaVisitFlowReducers.select_child({
              value: selected,
            })
          );
          history.push("/visit/dermatology/pricing");
        },
      });
    } else {
      createNewVisit({
        patient_id: patientsList?.find((el) => el?.full_name === selected)?.id,
        change_current_screen: false,
        screen_to_complete: null,
        callBack: () => {
          dispatch(
            dermaVisitFlowReducers.select_child({
              value: selected,
            })
          );
          history.push("/visit/dermatology/pricing");
        },
      });
    }
  }
  return (
    <StepScreenContainer align="start" childClass={"wrapper"}>
      <MedicalAssistant content={<p>Please, select child.</p>} />
      <Card>
        <RadioButtonCard
          loading={creatingVisit}
          options={patientsList
            ?.filter(
              (patient) =>
                patient?.relationship_with_account &&
                patient.relationship_with_account === "pappa"
            )
            ?.map((el) => el?.full_name)}
          onSelect={setSelected}
          selected={selected}
        />
        <Spacer height="38" />
        <Button
          disabled={updatingVisit}
          onClick={() => {
            setShowModal({
              state: true,
              children: (_, onClose) => (
                <AddChildModalDerm
                  onClose={(e) => {
                    if (e !== null) {
                      setSelected(e);
                    }
                    onClose();
                  }}
                />
              ),
              data: null,
            });
          }}
          label="Add Child"
          outlined
        />
      </Card>
    </StepScreenContainer>
  );
}

export default SelectChildForDerm;
