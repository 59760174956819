export function weightConversion({ metric, value }) {
  if (value) {
    if (metric === "kgs") {
      // lbs -> kgs
      return `${decimalRestriction({ number: Number(value) / 2.205 })}`;
    } else if (metric === "lbs") {
      // kgs -> lbs
      return `${decimalRestriction({ number: Number(value) * 2.205 })}`;
    }
  } else {
    return "";
  }
}

export function heightConversion({ metric, value }) {
  if (value) {
    if (metric === "ft") {
      // cm -> ft
      return `${decimalRestriction({ number: Number(value / 30.48) })}`;
    } else if (metric === "cm") {
      // ft -> cm
      return `${decimalRestriction({ number: Number(value * 30.48) })}`;
    }
  } else {
    return "";
  }
}

export function getFeetInInches({ feet, inches }) {
  return (Math.trunc(feet) * 12)  + Math.trunc(inches);
}

export function getInchesInFeet({ height }) {
  // inches to ft
  return {
    feet: Math.trunc(height / 12),
    inches: height % 12,
  };
}

export function decimalRestriction({ number }) {
  // round and restrict decimal number to two
  return Math.round((number + Number.EPSILON) * 100) / 100;
}
