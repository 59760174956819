import React, { useEffect, useRef, useState } from "react";
import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import useModal from "hooks/useModal";
import Images from "assets/images";
import VisitSubQuestionSlideUpContainer from "components/visitSubQuestionSlideUp/VisitSubQuestionSlideUpContainer";
import { Form, Formik } from "formik";
import Input from "components/inputs/Input";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import useVisitStepChange from "hooks/useVisitStepChange";
import { dermatology_sub_steps } from "helpers/enum";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import { OtherConditionSchema } from "helpers/validationSchema";
import scrollToBottom from "helpers/scrollToBottom";
import usePatients from "hooks/visit/usePatients";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { useApiQuery } from "hooks/useApiQuery";
import { patientListDescriptionsAction } from "api/actions/VisitActions";
import CSSLoader from "components/cssLoader/CSSLoader";

function SelectConditionForDerm() {
  const formRef = useRef();
  const nextAnimationRef = useRef();

  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const { setShowModal } = useModal();
  const { incompleteData, subSteps, patientsList } = useDermatologyVisitFlow();
  const { getCurrentPatient } = usePatients();
  const currentPatient = getCurrentPatient(
    incompleteData?.patient_id,
    patientsList
  );
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore =
    subSteps[dermatology_sub_steps.select_condition].value;

  const { updateVisit } = useUpdateDermatologyVisit();
  const {
    payload: conditionsList,
    loading: gettingConditionsList,
    query: getConditionsList,
  } = useApiQuery(
    patientListDescriptionsAction({ listType: "dermatology_conditions" }),
    false
  );

  useEffect(() => {
    getConditionsList();
  }, [getConditionsList]);

  useEffect(() => {
    if (currentScreenValueFromStore?.length && conditionsList?.length) {
      if (
        conditionsList?.find(
          (el) =>
            el?.label?.toLowerCase() ===
            currentScreenValueFromStore?.toLowerCase()
        )
      ) {
        setSelected(currentScreenValueFromStore);
      } else {
        setSelected("Other");
        scrollToBottom();
      }
    }
  }, [conditionsList, currentScreenValueFromStore]);

  useEffect(() => {
    if (selected?.length) {
      if (selected === "Other") {
        scrollToBottom();
      }
      dispatch(
        dermaVisitFlowReducers.select_condition({
          isValid: true,
        })
      );
    } else {
      dispatch(
        dermaVisitFlowReducers.select_condition({
          isValid: false,
        })
      );
    }
  }, [dispatch, selected]);

  function onRightButtonClick(_, nextAnimation) {
    if (formRef.current && selected === "Other") {
      formRef?.current?.handleSubmit();
      nextAnimationRef.current = nextAnimation;
    } else {
      updateVisit({
        payload: {
          case: {
            id: incompleteData?.id,
            skin_issue: selected,
            dermatology_steps: incompleteData?.dermatology_steps,
          },
        },
        change_current_screen: true,
        screen_to_complete: dermatology_sub_steps.select_condition,
        callBack: () => {
          dispatch(
            dermaVisitFlowReducers.select_condition({
              value: selected,
            })
          );
          nextAnimation();
        },
      });
    }
  }

  function infoView(data, onClose) {
    return (
      <div className="flex items-center justify-center w-screen h-screen p-16 wrapper">
        <div className="relative p-8 bg-white rounded-1/4">
          <img
            className="absolute w-8 h-8 cursor-pointer right-4 top-4"
            onClick={onClose}
            src={Images.closeIconFilled}
            alt="close"
          />
          <h3 className="text-2xl font-bold text-center">{data?.label}</h3>
          <Spacer height={16} />
          <p
            className="text-xl font-light leading-8 text-center"
            dangerouslySetInnerHTML={{ __html: data?.value }}
          ></p>
        </div>
      </div>
    );
  }

  function onFormSubmit(values) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          skin_issue: values?.other_condition,
          dermatology_steps: incompleteData?.dermatology_steps,
        },
      },
      change_current_screen: true,
      screen_to_complete: dermatology_sub_steps.select_condition,
      callBack: () => {
        dispatch(
          dermaVisitFlowReducers.select_condition({
            value: selected,
          })
        );
        nextAnimationRef?.current();
      },
    });
  }

  return (
    <StepScreenContainer align={"start"}>
      <MedicalAssistant
        content={
          <p>
            Thank you, {currentPatient?.first_name}. Now, what is your main
            dermatological concern today?
          </p>
        }
      />
      <Card>
        {gettingConditionsList ? (
          <CSSLoader className="w-8 h-8 ml-auto mr-auto" color="orange" />
        ) : (
          <RadioButtonCard
            options={
              conditionsList
                ? [
                    ...conditionsList,
                    {
                      label: "Other",
                      value: "",
                    },
                  ]?.map((el) => el?.label)
                : []
            }
            infos={
              conditionsList
                ? [
                    ...conditionsList,
                    {
                      label: "Other",
                      value: "",
                    },
                  ]?.map((el) => el?.value)
                : []
            }
            showInfo={(info) => {
              setShowModal({
                state: true,
                children: infoView,
                data: info,
              });
            }}
            onSelect={setSelected}
            selected={selected}
          />
        )}
      </Card>
      {selected === "Other" ? (
        <VisitSubQuestionSlideUpContainer>
          <Card>
            <Formik
              innerRef={formRef}
              onSubmit={onFormSubmit}
              initialValues={{
                other_condition:
                  incompleteData?.skin_issue ??
                  (currentScreenValueFromStore &&
                  conditionsList?.find(
                    (el) =>
                      el?.label?.toLowerCase() !==
                      currentScreenValueFromStore?.toLowerCase()
                  )
                    ? currentScreenValueFromStore
                    : ""),
              }}
              validationSchema={OtherConditionSchema}
            >
              {() => (
                <Form>
                  <Input
                    id={"other_condition"}
                    name={"other_condition"}
                    label="Type your concern"
                  />
                </Form>
              )}
            </Formik>
          </Card>
        </VisitSubQuestionSlideUpContainer>
      ) : null}
    </StepScreenContainer>
  );
}

export default SelectConditionForDerm;
