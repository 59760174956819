import { createSlice } from "@reduxjs/toolkit";
import { pedVisitFlowInitialState } from "./pedVisitFlowInitialState";
import { pedAdditionalDetailsReducer } from "./steps/pedAdditionalDetailsReducer";
import { pedBasicInfoReducer } from "./steps/pedBasicInfoReducer";
import { pedMainStepsReducer } from "./steps/pedMainStepsReducer";
import { pedSelectSlotReducer } from "./steps/pedSelectSlotReducer";
import { pedVisitDetailsReducer } from "./steps/pedVisitDetailsReducer";

const initialState = pedVisitFlowInitialState;

const pedVisitFlowSlice = createSlice({
  name: "pedVisitFlow",
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    currentScreen(state, action) {
      const { value } = action.payload;
      state["currentScreen"] = value;
    },
    incompleteData(state, action) {
      const { value } = action.payload;
      state["incompleteData"] = value;
    },
    patientsList(state, action) {
      const { value } = action.payload;
      state["patientsList"] = value;
    },
    completedProgress(state, action) {
      const { value } = action.payload;
      if (value && value > state.completedProgress) {
        state["completedProgress"] = value;
      }
    },
    setPaymentMethod(state, action) {
      const { value } = action.payload;
      if (value) {
        state["paymentMethod"] = value;
      }
    },
    ...pedMainStepsReducer,
    ...pedBasicInfoReducer,
    ...pedVisitDetailsReducer,
    ...pedSelectSlotReducer,
    ...pedAdditionalDetailsReducer,
  },
});

export const pedVisitFlowReducers = pedVisitFlowSlice.actions;

export default pedVisitFlowSlice.reducer;
