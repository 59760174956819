import React from "react";
import Input from "components/inputs/Input";
import Spacer from "components/Spacer/Spacer";
import useModal from "hooks/useModal";
import HealthProfileHistoryDebounceModal from "../HealthProfileHistoryDebounceModal";

function AddHealthProfileAllergies({
  drug_medication_allergies,
  environmental_food_allergies,
  setFieldValue,
}) {
  const { setShowModal } = useModal();
  return (
    <div>
      <h3 className="text-3xl font-medium mt-14">Drug / Medication Allergy</h3>
      {drug_medication_allergies?.map((el, idx) => {
        return (
          <div
            key={idx}
            className="pt-7 pb-9 px-9 bg-alternativeWhite rounded-2xl mt-10"
          >
            <Input
              onFocus={() => {
                setShowModal({
                  state: true,
                  children: (_, onClose) => (
                    <HealthProfileHistoryDebounceModal
                      keyToSearch={"allergies"}
                      title="Search Allergies"
                      onClose={onClose}
                      selected={el?.drug_name}
                      onSelect={(val) =>
                        setFieldValue(
                          `drug_medication_allergies[${idx}].drug_name`,
                          val
                        )
                      }
                    />
                  ),
                });
              }}
              label="Drug Name"
              id={`drug_medication_allergies[${idx}].drug_name`}
              name={`drug_medication_allergies[${idx}].drug_name`}
            />
            <Spacer height={23} />
            <Input
              label="Reaction"
              id={`drug_medication_allergies[${idx}].reaction`}
              name={`drug_medication_allergies[${idx}].reaction`}
            />
            <p
              onClick={() => {
                setFieldValue(
                  "drug_medication_allergies",
                  drug_medication_allergies?.filter((_, index) => index !== idx)
                );
              }}
              className="text-red text-center font-light text-2xl underline mt-12 cursor-pointer"
            >
              Remove
            </p>
          </div>
        );
      })}
      <p
        onClick={() => {
          setFieldValue("drug_medication_allergies", [
            ...drug_medication_allergies,
            { drug_name: "", reaction: "" },
          ]);
        }}
        className="text-2xl font-light underline text-orange mt-14 cursor-pointer"
      >
        Add Drug / Medication Allergy
      </p>
      <h3 className="text-3xl font-medium mt-14">
        Environmental / Food Allergy
      </h3>
      {environmental_food_allergies?.map((el, idx) => {
        return (
          <div
            key={idx}
            className="pt-7 pb-9 px-9 bg-alternativeWhite rounded-2xl mt-10"
          >
            <Input
              onFocus={() => {
                setShowModal({
                  state: true,
                  children: (_, onClose) => (
                    <HealthProfileHistoryDebounceModal
                      keyToSearch={"allergies"}
                      title="Search Allergies"
                      onClose={onClose}
                      selected={el?.allergic_to}
                      onSelect={(val) =>
                        setFieldValue(
                          `environmental_food_allergies[${idx}].allergic_to`,
                          val
                        )
                      }
                    />
                  ),
                });
              }}
              label="Allergic to"
              id={`environmental_food_allergies[${idx}].allergic_to`}
              name={`environmental_food_allergies[${idx}].allergic_to`}
            />
            <Spacer height={23} />
            <Input
              label="Reaction"
              id={`environmental_food_allergies[${idx}].reaction`}
              name={`environmental_food_allergies[${idx}].reaction`}
            />
            <p
              onClick={() => {
                setFieldValue(
                  "environmental_food_allergies",
                  environmental_food_allergies?.filter(
                    (_, index) => index !== idx
                  )
                );
              }}
              className="text-red text-center font-light text-2xl underline mt-12 cursor-pointer"
            >
              Remove
            </p>
          </div>
        );
      })}
      <p
        onClick={() => {
          setFieldValue("environmental_food_allergies", [
            ...environmental_food_allergies,
            { allergic_to: "", reaction: "" },
          ]);
        }}
        className="text-2xl font-light underline text-orange mt-14 cursor-pointer"
      >
        Add Environmental / Food Allergy
      </p>
    </div>
  );
}

export default AddHealthProfileAllergies;
