import {
  primary_care_main_steps,
  primary_care_sub_steps,
  step_status,
} from "helpers/enum";
import { setNextScreenToUpcoming } from "helpers/setPrimaryCareStepStatus";

export const primaryCareVisitDetailsReducer = {
  select_patient(state, action) {
    const { value, prevScreen, nextScreen, status, isValid } = action.payload;
    if (prevScreen) {
      state.subSteps[
        primary_care_sub_steps.select_patient
      ].prevScreen = prevScreen;
    }
    if (nextScreen) {
      state.subSteps[
        primary_care_sub_steps.select_patient
      ].nextScreen = nextScreen;
    }
    if (value) {
      state.subSteps[primary_care_sub_steps.select_patient].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[primary_care_sub_steps.select_patient].isValid = isValid;
    }
    if (status) {
      state.subSteps[primary_care_sub_steps.select_patient].status = status;
      if (status === step_status.completed) {
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: primary_care_sub_steps.select_condition,
        });
      }
    }
  },
  select_child(state, action) {
    const { value, isValid } = action.payload;
    if (value) {
      state.subSteps[primary_care_sub_steps.select_child].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[primary_care_sub_steps.select_child].isValid = isValid;
    }
  },
  select_someone_else(state, action) {
    const { value, isValid } = action.payload;
    if (value) {
      state.subSteps[primary_care_sub_steps.select_someone_else].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[
        primary_care_sub_steps.select_someone_else
      ].isValid = isValid;
    }
  },
  select_condition(state, action) {
    const { status, value, prevScreen, isValid } = action.payload;
    // Allow updating prevScreen using useEffect in SelelctConditionforPC
    if (prevScreen) {
      state.subSteps[
        primary_care_sub_steps.select_condition
      ].prevScreen = prevScreen;
    }
    if (status) {
      state.subSteps[primary_care_sub_steps.select_condition].status = status;
      if (status === step_status.completed) {
        state.mainSteps[primary_care_main_steps.visit_details].status =
          step_status.completed;
        if (
          state.mainSteps[primary_care_main_steps.main_select_slot].status ===
          step_status.upcoming
        ) {
          state.mainSteps[primary_care_main_steps.main_select_slot].status =
            step_status.current;
        }
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: primary_care_sub_steps.select_slot,
        });
      }
    }
    if (value) {
      state.subSteps[primary_care_sub_steps.select_condition].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[primary_care_sub_steps.select_condition].isValid = isValid;
    }
  },
};
