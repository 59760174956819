import React, { useRef } from "react";
import Images from "assets/images";
import CSSLoader from "components/cssLoader/CSSLoader";
import ContentEditable from "react-contenteditable";
import useToastify from "hooks/useToastify";
import emojiRegex from "emoji-regex";

function ChatActions({
  onChangeText,
  value,
  onSend,
  sendingMessage,
  fileArray,
  setFileArray,
  MAX_FILES,
  canUserReply,
}) {
  const emojiRegexValue = emojiRegex();
  const isEmojiIncluded =
    String(value)?.length && String(value)?.match(emojiRegexValue);
  const inputRef = useRef();
  const { showAlert } = useToastify();

  const isPDF = (string) =>
    string?.includes(".pdf") || string?.includes(".PDF");
  const isIMAGE = (string) =>
    !string?.length || string?.includes(".pdf") || string?.includes(".PDF")
      ? false
      : true;

  const getPDFLength = () =>
    fileArray?.filter((el) => isPDF(el?.uri) === true)?.length;
  const getIMAGELength = () =>
    fileArray?.filter((el) => isIMAGE(el?.uri) === true)?.length;

  const isFileDataAvailable = fileArray?.some((el) => el?.data?.length);
  const isButtonEnabled =
    (value?.trim()?.length || isFileDataAvailable) && !sendingMessage;

  function handleChange(event, index) {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      if (
        isPDF(files?.[0]?.name) &&
        getPDFLength() > 1 &&
        (isIMAGE(fileArray?.[index]?.uri) ||
          fileArray?.[index]?.uri?.length === 0)
      ) {
        showAlert("Maximum 2 pdf files are allowed.", "error");
      } else if (
        isIMAGE(files?.[0]?.name) &&
        getIMAGELength() > 1 &&
        (isPDF(fileArray?.[index]?.uri) ||
          fileArray?.[index]?.uri?.length === 0)
      ) {
        showAlert("Maximum 2 image files are allowed.", "error");
      } else {
        let reader = new FileReader();
        reader.onload = (event) => {
          const obj = {
            uri: files?.[0]?.name,
            file: files[0],
            data: event?.target?.result,
          };

          setFileArray(
            fileArray.map((el, idx) => {
              if (idx === index) {
                return obj;
              } else {
                return el;
              }
            })
          );
        };

        reader.readAsDataURL(files[0]);
      }
    }
  }

  return (
    <div className="fixed bottom-0 w-full bg-black border wrapper bg-opacity-10">
      {/* documents container */}
      {isFileDataAvailable ? (
        <div className="flex flex-wrap items-center justify-center w-full md:px-28">
          {Array(MAX_FILES)
            .fill("file")
            ?.map((_, idx) => {
              return (
                <div
                  key={idx}
                  className="relative flex items-center justify-center m-2 bg-white border border-dashed cursor-pointer border-indigo w-28 h-28 md:w-40 md:h-40 md:m-4"
                >
                  <img
                    src={Images.addIcon}
                    alt="add"
                    className="w-8 h-8 md:w-16 md:h-16"
                  />
                  {fileArray?.[idx]?.uri?.length ? (
                    <img
                      src={
                        isPDF(fileArray?.[idx]?.uri)
                          ? Images.pdfIcon
                          : fileArray?.[idx]?.data?.length
                          ? fileArray?.[idx]?.data
                          : fileArray?.[idx]?.uri
                      }
                      alt="doc"
                      className={`absolute object-contain ${
                        isPDF(fileArray?.[idx]?.uri)
                          ? "w-1/2 h-1/2"
                          : "w-full h-full"
                      }`}
                    />
                  ) : null}
                  {isPDF(fileArray?.[idx]?.uri) ? (
                    <p className="absolute text-sm font-medium bottom-1 md:text-lg md:bottom-2">
                      {fileArray?.[idx]?.uri?.length > 10
                        ? fileArray?.[idx]?.uri
                            ?.replace(".pdf", "")
                            ?.substring(0, 6) + "...png"
                        : fileArray?.[idx]?.uri}
                    </p>
                  ) : null}
                  <input
                    type="file"
                    accept="image/*, application/pdf"
                    className="absolute top-0 w-full h-full opacity-0"
                    onChange={(e) => {
                      handleChange(e, idx);
                    }}
                  />
                  {fileArray?.[idx]?.data?.length ? (
                    <img
                      onClick={() => {
                        setFileArray(
                          fileArray?.map((el, flIndex) =>
                            idx === flIndex
                              ? { uri: "", file: "", data: "" }
                              : el
                          )
                        );
                      }}
                      src={Images.deleteIcon}
                      className="absolute w-10 h-10 rounded-full shadow-xl -top-2 -right-2 md:w-16 md:h-16 md:-top-4 md:-right-4"
                      alt="delete"
                    />
                  ) : null}
                </div>
              );
            })}
        </div>
      ) : null}
      {/* action container */}
      {canUserReply ? (
        <div className="flex items-center justify-between px-6 pt-6 pb-6 bg-white md:px-12 md:pb-12">
          <div className="relative w-9 h-9">
            <img
              src={Images.attachmentIcon}
              alt="attach"
              className="object-contain w-9"
            />
            <input
              type="file"
              accept="image/*,application/pdf"
              className="absolute top-0 w-full h-full opacity-0 cursor-pointer"
              name="fileImg"
              id="fileImg"
              onChange={(e) => {
                handleChange(
                  e,
                  fileArray?.findIndex((el) => el?.uri?.length === 0)
                );
              }}
            />
          </div>
          <div className="flex flex-col flex-1">
            <ContentEditable
              className="flex-1 max-w-full px-4 py-6 mx-8 text-2xl font-medium break-words border outline-none bg-alternativeWhite rounded-xl"
              innerRef={inputRef}
              html={value}
              onChange={(e) => onChangeText(e?.target?.value)}
              tagName="span"
            />
            {isEmojiIncluded ? (
              <p className="mt-4 ml-12 text-xl font-medium text-red">
                Emojis are not allowed.
              </p>
            ) : null}
          </div>
          <div
            className={`${
              isButtonEnabled
                ? "opacity-100 cursor-pointer"
                : "opacity-50 cursor-not-allowed"
            }`}
            onClick={() => {
              if (isEmojiIncluded) {
                showAlert("Emojis are not allowed.", "error");
              } else if (isButtonEnabled) {
                onSend(
                  isFileDataAvailable &&
                    fileArray?.filter((el) => el?.data?.length)
                );
              }
            }}
          >
            {sendingMessage ? (
              <CSSLoader color={"orange"} className="w-12 h-12" />
            ) : (
              <img src={Images.sendArrowIcon} alt="send" className={`w-12`} />
            )}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-between px-6 pt-6 pb-6 bg-white md:px-12 md:py-12">
          <p className="w-full text-2xl font-medium text-center md:text-3xl">
            You cannot send a new message at this moment.
          </p>
        </div>
      )}
    </div>
  );
}

export default ChatActions;
