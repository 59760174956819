import Spacer from "components/Spacer/Spacer";
import { useField } from "formik";
import React from "react";

function MultiLineInput({ type, label, containerClassName, ...rest }) {
  const [field, meta] = useField(rest);
  return (
    <div className={`flex flex-col ${containerClassName ?? ""}`}>
      <label
        className="font-bold text-2xl text-indigo text-opacity-50"
        htmlFor={rest?.id}
      >
        {label}
      </label>
      <Spacer height={8} />
      <div className="rounded-lg h-52 bg-white border-solid border-2 w-full border-borderColor focus:border-indigo">
        <textarea
          className="p-6 h-full bg-transparent font-medium text-3xl text-indigo w-full outline-none placeholder-cloudyBlue resize-none"
          {...field}
          {...rest}
        />
      </div>
      {meta.touched && meta.error ? (
        <p className="font-bold text-base mt-2 text-red">{meta.error}</p>
      ) : null}
    </div>
  );
}

export default MultiLineInput;
