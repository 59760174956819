import React, { useState, useEffect } from "react";
import Header from "components/header/Header";
import { useHistory, useParams } from "react-router-dom";
import Images from "assets/images";
import {
  patientVisittedVisitDetailsScreenAction,
  visitDetailsAction,
} from "api/actions/VisitActions";
import { useApiQuery } from "hooks/useApiQuery";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Spacer from "components/Spacer/Spacer";
import LoaderComponent from "components/loader/LoaderComponent";
import { visit_status } from "helpers/enum";
import { useApiMutation } from "hooks/useApiMutation";
import { useDispatch } from "react-redux";
import { updateVisitMessagePayload } from "reducers/currentUserReducer";
import CardPayments from "components/payment/CardPayments";
import CSSLoader from "components/cssLoader/CSSLoader";
import ConsultationDetails from "./ConsultationDetails";
import MedicalVisitDetails from "./MedicalVisitDetails";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";

function VisitDetails() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { visit_id } = useParams();
  const [changingPayment, setChangingPayment] = useState(false);

  const {
    loading: fetchingVisitDetails,
    payload: visitDetails,
    query: queryVisitDetails,
  } = useApiQuery(visitDetailsAction(visit_id), false);

  const { mutate: recordPatientVisitted } = useApiMutation(
    patientVisittedVisitDetailsScreenAction
  );

  useEffect(() => {
    if (visit_id) {
      queryVisitDetails();
    }
  }, [history, visit_id, queryVisitDetails]);

  useEffect(() => {
    if (visitDetails && !visitDetails?.error) {
      if (
        visitDetails?.status !== null &&
        (visitDetails?.status === visit_status.pending ||
          visit_status === visit_status.complete_awaiting_ma)
      ) {
        recordPatientVisitted(visitDetails?.id);
      }
    }
  }, [visitDetails, recordPatientVisitted]);

  if (fetchingVisitDetails) {
    return (
      <LoaderComponent
        showLoader={{ state: true, message: "Fetching details" }}
      />
    );
  }

  return (
    <div className="h-[100svh] overflow-hidden bg-veryLightBlue">
      {visitDetails?.error ? (
        <>
          <Header title="Visit Not Found" />
          <div className="flex flex-col items-center h-eliminateHeader px-12 pt-12 wrapper">
            <MedicalAssistant
              content={
                <p>
                  This visit could not be found in this account. In case you
                  were sent an email with a link to this visit, please sign in
                  using the account associated with that email address. Or
                  please go to My Visits and try again.
                </p>
              }
            />
            <BottomFixedButton
              onClick={() => {
                sessionStorage.setItem("selectedTabIndex", 1);
                history.push("/home/my-visits");
              }}
              label="Go to My Visits"
            />
          </div>
        </>
      ) : (
        <>
          <Header
            title={
              visitDetails?.speciality === "cosmetic"
                ? `Consultation #${visit_id}`
                : visitDetails?.speciality === "primary_care"
                ? `Primary Care Visit #${visit_id}`
                : visitDetails?.speciality === "pediatric"
                ? `Pediatric Visit #${visit_id}`
                : `Dermatology Visit #${visit_id}`
            }
            rightActionView={
              !visitDetails?.error && (
                <div
                  className={`relative cursor-pointer ${
                    !visitDetails || visitDetails?.messages?.length === 0
                      ? "opacity-50 cursor-not-allowed"
                      : "opacity-100"
                  }`}
                  onClick={() => {
                    if (!visitDetails || visitDetails?.messages?.length === 0) {
                      return;
                    } else {
                      dispatch(
                        updateVisitMessagePayload({
                          can_message: visitDetails?.follow_up,
                          unread_provider_messages:
                            visitDetails?.unread_provider_messages,
                          messages: visitDetails?.messages,
                        })
                      );

                      history.push(`/chat/${visit_id}`, {
                        speciality: visitDetails?.speciality,
                      });
                    }
                  }}
                >
                  <img className={`w-10`} src={Images.chatIcon} alt="chat" />
                  {visitDetails?.unread_provider_messages > 0 ? (
                    <span className="absolute flex items-center justify-center w-8 h-8 rounded-full -top-2 -right-4 bg-red">
                      <p className="font-semibold text-white text-md">
                        {visitDetails?.unread_provider_messages > 99
                          ? "99+"
                          : visitDetails?.unread_provider_messages}
                      </p>
                    </span>
                  ) : null}
                </div>
              )
            }
          />
          {!visitDetails?.error ? (
            visitDetails?.speciality === "cosmetic" ? (
              <ConsultationDetails
                visitDetails={visitDetails}
                queryVisitDetails={queryVisitDetails}
              />
            ) : (
              <MedicalVisitDetails
                visitDetails={visitDetails}
                queryVisitDetails={queryVisitDetails}
              />
            )
          ) : visitDetails?.card_invalid ? (
            <div className="h-full p-12 overflow-y-auto bg-white wrapper">
              <MedicalAssistant
                content={
                  <p>
                    We are not able to charge you for the visit due to technical
                    issues, please choose another payment method or card.
                  </p>
                }
              />
              <Spacer height={23} />
              <CardPayments
                refreshPage={() => window.location.reload()}
                setAddingCard={setChangingPayment}
              />
              {changingPayment ? (
                <div className="absolute top-0 left-0 z-20 flex items-center justify-center w-full h-full bg-opacity-50 bg-alternativeWhite">
                  <CSSLoader color={"orange"} className="w-12 h-12" />
                </div>
              ) : null}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}

export default VisitDetails;
