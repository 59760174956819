import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { primaryCareVisitFlowReducers } from "reducers/primary_care/primaryCareVisitFlowReducer";

import usePatients from "hooks/visit/usePatients";
import { usePrimaryCareVisitFlow } from "hooks/useVisitFlow";

import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import Button from "components/buttons/Button";

import { primary_care_sub_steps } from "helpers/enum";

import SelectChild from "./SelectChild";
import SelectSomeoneElse from "./SelectSomeoneElse";

const options = ["Me", "My Child", "Someone else"];

const SelectPatient = ({
  incompleteData,
  createNewVisit,
  creatingVisit,
  updateVisit,
  updatingVisit,
  setActiveIndex,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { getAccountHolder } = usePatients();
  const { patientsList, subSteps } = usePrimaryCareVisitFlow();
  const currentScreenValueFromStore =
    subSteps[primary_care_sub_steps.select_patient].value;

  const [selected, setSelected] = useState(null);
  const [selectedScreen, setSelectedScreen] = useState(null);

  useEffect(() => {
    if (currentScreenValueFromStore) {
      setSelected(currentScreenValueFromStore);
      setSelectedScreen(currentScreenValueFromStore);
    }
  }, [currentScreenValueFromStore, patientsList]);

  const selectSelf = () => {
    dispatch(
      primaryCareVisitFlowReducers.select_patient({
        value: options[0],
      })
    );
    setSelectedScreen(options[0]);
  };

  function onRightButtonClick() {
    if (selected === options?.[0]) {
      if (incompleteData && Object.keys(incompleteData)?.length) {
        updateVisit({
          payload: {
            case: {
              id: incompleteData?.id,
              patient_id: getAccountHolder(patientsList)?.id,
              allergies: incompleteData?.allergies_json,
              medications:
                typeof incompleteData?.medications[0] === "string"
                  ? incompleteData?.medications.map((item) => ({
                      name: item,
                    }))
                  : incompleteData?.medications,
              primary_care_steps: incompleteData?.primary_care_steps,
            },
          },
          change_current_screen: false,
          screen_to_complete: null,
          callBack: () => {
            dispatch(
              primaryCareVisitFlowReducers.select_patient({
                value: "Me",
              })
            );
            setActiveIndex(1);
            history.push("/visit/primary-care/pricing");
          },
        });
      } else {
        createNewVisit({
          patient_id: getAccountHolder(patientsList)?.id,
          change_current_screen: false,
          screen_to_complete: null,
          callBack: () => {
            dispatch(
              primaryCareVisitFlowReducers.select_patient({
                value: "Me",
              })
            );
            setActiveIndex(1);
            history.push("/visit/primary-care/pricing");
          },
        });
      }
    } else {
      dispatch(
        primaryCareVisitFlowReducers.select_patient({
          value: selected,
        })
      );
      setSelectedScreen(selected);
    }
  }

  return (
    <div className="bg-white rounded-b-4xl p-10 relative overflow-x-hidden">
      {selectedScreen === options[0] ? (
        <>
          <RadioButtonCard
            loading={creatingVisit}
            options={options}
            onSelect={setSelected}
            selected={selected}
          />
          <Button
            disabled={!selected}
            className="py-4 mt-10"
            loading={updatingVisit || creatingVisit}
            label="Next"
            onClick={onRightButtonClick}
          />
        </>
      ) : selectedScreen === options[1] ? (
        <SelectChild
          incompleteData={incompleteData}
          createNewVisit={createNewVisit}
          creatingVisit={creatingVisit}
          updateVisit={updateVisit}
          updatingVisit={updatingVisit}
          setActiveIndex={setActiveIndex}
          goBackToSelf={selectSelf}
        />
      ) : selectedScreen === options[2] ? (
        <SelectSomeoneElse
          incompleteData={incompleteData}
          createNewVisit={createNewVisit}
          creatingVisit={creatingVisit}
          updateVisit={updateVisit}
          updatingVisit={updatingVisit}
          setActiveIndex={setActiveIndex}
          goBackToSelf={selectSelf}
        />
      ) : (
        <>
          <RadioButtonCard
            loading={creatingVisit}
            options={options}
            onSelect={setSelected}
            selected={selected}
          />
          <Button
            disabled={!selected}
            className="py-4 mt-10"
            loading={updatingVisit || creatingVisit}
            label="Next"
            onClick={onRightButtonClick}
          />
        </>
      )}
    </div>
  );
};

export default SelectPatient;
