import Images from "assets/images";
import { step_status } from "helpers/enum";
import React from "react";

function StepHeader({ data, isActive, index, status }) {
  return (
    <div
      className={`flex items-center justify-between px-10 py-6 ${
        isActive ? "" : "border-b border-borderColor"
      } ${
        status === step_status.upcoming
          ? "opacity-25 cursor-not-allowed"
          : "opacity-100"
      }`}
    >
      <div className="flex items-center">
        {status === step_status.completed ? (
          <img
            src={Images.stepCompleted}
            alt="step_status"
            className="w-24 h-24"
          />
        ) : (
          <div className="w-24 h-24 rounded-full bg-gradient-to-r from-lightOrange  to-orange flex items-center justify-center">
            <p className="text-white text-3xl font-bold">{index + 1}</p>
          </div>
        )}
        <h2 className="text-3xl font-medium pl-10">{data?.name}</h2>
      </div>
      <div>
        <img
          src={Images.dropdown}
          alt="chev_down"
          className={`transition duration-500 ease-in-out w-10 h-10 transform ${
            isActive ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>
    </div>
  );
}

export default StepHeader;
