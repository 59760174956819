import Images from "assets/images";
import PharmacyCard from "components/pharmacyCard/PharmacyCard";
import React from "react";
import "./Marker.scss";

function Marker({
  onClick,
  isActive,
  activePharmacy,
  onClose,
  onSelectPharmacy,
}) {
  return (
    <div className="cursor-pointer relative bg-red flex items-center justify-center">
      <img
        onClick={onClick}
        className={`transition transform scale-${
          isActive ? "150" : "100"
        } origin-center w-10 ${isActive ? "z-10" : ""}`}
        src={isActive ? Images.markerActive : Images.marker}
        alt="marker"
      />
      {isActive ? (
        <div className="bg-white absolute p-5 w-screen md:w-35.3 bottom-20 z-10 rounded-md pharmacy_details_container">
          <PharmacyCard
            onClickPharmacy={onSelectPharmacy}
            removeEdit
            pharmacyAddess={activePharmacy?.address}
          />
          <img
            src={Images.closeIconFilled}
            alt="close"
            className="absolute w-8 h-8 right-4 top-4 z-10"
            onClick={onClose}
          />
          <div className="absolute w-full -bottom-4 flex items-center justify-center left-0">
            <div className="w-8 h-8 bg-white transform rotate-45" />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Marker;
