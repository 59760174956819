import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";

export function trackDropOffRate(screenName, uid) {
  const analytics = getAnalytics();
  window.addEventListener("beforeunload", function (event) {
    window.addEventListener("visibilitychange", function (event) {
      if (
        !event.currentTarget.performance
          .getEntriesByType("navigation")
          .map((nav) => nav.type)
          .includes("reload")
      ) {
        logEvent(analytics, `drop_off_${screenName}`, {
          screen_name: screenName,
          uid: uid,
          navigation: event.currentTarget.performance
            .getEntriesByType("navigation")
            .map((nav) => nav.type)
            ?.toString(),
        });
      }
    });
  });
}

export function trackPageView(screenName, uid) {
  const analytics = getAnalytics();

  logEvent(analytics, `page_view_${screenName}`, {
    screen_name: screenName,
    uid: uid,
  });
}

export function trackEvent(eventName, props) {
  const analytics = getAnalytics();
  logEvent(analytics, eventName, props);
}

export function setUserProp(prop) {
  const analytics = getAnalytics();
  //Prop should be an Object, Say {uid: uid}
  setUserProperties(analytics, prop);
}
// export function trackScrollRate(event, ref) {
//   const analytics = getAnalytics();
//   const scroll = Math.abs(
//     ref.current.getBoundingClientRect().top - ref.current.offsetTop
//   );

//   const scrollTop =
//     window.pageYOffset ||
//     document.documentElement.scrollTop ||
//     document.body.scrollTop;

//   // Allow window to scroll
//   if (scrollTop > 0) {
//     console.log("Scroll rate");
//     logEvent(analytics, event);
//   }

//   // Remove the event listener after it has been triggered
//   window.removeEventListener("scroll", trackScrollRate);
// }
