import Spacer from "components/Spacer/Spacer";
import VisitSummaryContent from "components/visitSummaryAccordion/VisitSummaryContent";
import VisitSummaryContentHeading from "components/visitSummaryAccordion/VisitSummaryContentHeading";
import VisitSummaryContentParagraph from "components/visitSummaryAccordion/VisitSummaryContentParagraph";
import { dermatology_sub_steps } from "helpers/enum";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";

function SymptomsSummary({ readOnly, data }) {
  const { incompleteData } = useDermatologyVisitFlow();
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <VisitSummaryContent
      readOnly={readOnly}
      onEditClick={() => {
        dispatch(
          dermaVisitFlowReducers.currentScreen({
            value: dermatology_sub_steps.symptoms,
          })
        );
        history.push("/visit/dermatology/new-visit");
      }}
    >
      <VisitSummaryContentHeading text="Symptoms" />
      <VisitSummaryContentParagraph
        text={
          data?.symptoms
            ? data?.symptoms?.join(", ")
            : incompleteData?.symptoms?.join(", ")
        }
      />
      <Spacer height={20} />
      <VisitSummaryContentHeading text="What makes it worse?" />
      <VisitSummaryContentParagraph
        text={data?.worse ?? incompleteData?.worse}
      />
      <Spacer height={20} />
      <VisitSummaryContentHeading text="What makes it better?" />
      <VisitSummaryContentParagraph
        text={data?.better ?? incompleteData?.better}
      />
    </VisitSummaryContent>
  );
}

export default SymptomsSummary;
