import React, { useState } from "react";

const ReadMoreText = ({ text, limit }) => {
  const [showAll, setShowAll] = useState(false);
  return (
    <p className="pt-3 pb-5 text-xl font-normal text-left text-black">
      {text.length > limit ? (
        <div>
          {showAll ? (
            <div>
              {text}
              <br />
              <button className="underline" onClick={() => setShowAll(false)}>
                Read Less
              </button>
            </div>
          ) : (
            <div>
              {text.substring(0, limit).concat("...")}
              <br />
              <button
                className="font-semibold underline"
                onClick={() => setShowAll(true)}
              >
                Read More
              </button>
            </div>
          )}
        </div>
      ) : (
        text
      )}
    </p>
  );
};

export default ReadMoreText;
